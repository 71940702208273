import { takeLatest, put, call } from 'redux-saga/effects';

import { fetchRevGroupsSuccess, fetchRevGroupsFail } from '../actions';
import { coreAPI } from 'utils/request';
import { FETCH_REVENUE_GROUPS } from '../constants';
import NProgress from 'nprogress';
import { showErrorNotification } from '@aha/utils';

function* doFetch({ hid }) {
  yield call(NProgress.start);
  try {
    const { data } = yield call(coreAPI.get, `/v1/pms/service-groups`);
    yield put(fetchRevGroupsSuccess(data));
  } catch (err) {
    showErrorNotification('Fall to fetch', err);
    yield put(fetchRevGroupsFail(err));
  }
  yield call(NProgress.done);
}

export function* watchFetchRevGroups() {
  yield takeLatest(FETCH_REVENUE_GROUPS, doFetch);
}
