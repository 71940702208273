import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IntlProvider } from 'react-intl';
import { makeSelectLocale } from './selectors';
import { useInjectSaga } from 'utils/injectSaga';
import saga from './saga';
import { getTranslationMessage } from 'i18n.async';
import { Language } from 'i18n';
import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import viVN from 'antd/es/locale/vi_VN';
import { Locale } from 'antd/lib/locale-provider';
import IntlGlobal from 'utils/IntlGlobal';

const key = 'language';
export function LanguageProvider({
  locale,
  children,
}: {
  locale: Language;
  children: any;
}) {
  useInjectSaga({ key, saga });

  const [message, setMessage] = useState({});
  const [configLocale, setConfigLocale] = useState<Locale>(
    locale === 'en' ? enUS : viVN,
  );

  useEffect(() => {
    setConfigLocale(locale === 'en' ? enUS : viVN);

    getTranslationMessage(locale).then(setMessage).catch(console.error);
  }, [locale]);

  return (
    <IntlProvider locale={locale} key={locale} messages={message}>
      <IntlGlobal>
        <ConfigProvider locale={configLocale} key={locale}>
          {React.Children.only(children)}
        </ConfigProvider>
      </IntlGlobal>
    </IntlProvider>
  );
}

const mapStateToProps = createSelector(makeSelectLocale(), (locale) => ({
  locale,
}));

export default connect(mapStateToProps)(LanguageProvider);
