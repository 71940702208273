import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { makeSelectRoomTypeList } from './selectors';
import { Dispatch } from 'redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRoomTypes } from './actions';
import { useEffect } from 'react';
import reducer from './reducer';
import saga from './saga';
import { useInjectSaga } from 'utils/injectSaga';
import { RoomType } from 'types/schema';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    roomTypes: RoomType[] | null;
  }
>({
  roomTypes: makeSelectRoomTypeList(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doFetchRoomTypes: () => dispatch(fetchRoomTypes()),
});

export default function useSelectRoomTypes(hotelId?: number) {
  useInjectReducer({ key: 'roomTypeSettings', reducer });
  useInjectSaga({ key: 'roomTypeSettings', saga });

  const dispatch = useDispatch();
  const { doFetchRoomTypes } = mapDispatchToProps(dispatch);
  const { roomTypes } = useSelector(mapStateToProps);

  useEffect(() => {
    if (!roomTypes) {
      doFetchRoomTypes();
    }
  }, [hotelId]); // eslint-disable-line

  return useSelector(mapStateToProps);
}
