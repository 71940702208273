import React from 'react';
import { Alert } from 'antd';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
export interface AlertHotelFeatureProps {
  className?: string;
}

export const AlertHotelFeature = ({ className }: AlertHotelFeatureProps) => {
  return (
    <Alert
      type="error"
      className={className}
      showIcon
      message={
        <FormattedMessage
          defaultMessage="This feature is not available for your hotel"
          id="alert.featureNotAvailable"
        />
      }
      description={
        <FormattedHTMLMessage
          defaultMessage={`Please contact to <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/aharooms.support">AHA ROOMS Support Team</a> for registering. Thank you!`}
          id="alert.requestEnableFeature"
        />
      }
    />
  );
};
export default AlertHotelFeature;
