import ActionTypes from './constants';
import { action } from 'typesafe-actions';
import { Hotel, Feature, HotelCurrency } from 'types/schema';
import { InjectPromise } from 'types/actions';
import { CMSConfigProps } from 'containers/EditHotelPage/components/ConfigCMSForm';

export const fetchHotels = () => {
  return action(ActionTypes.FETCH_HOTELS);
};

export const fetchHotelsSuccess = (hotels: Hotel[]) =>
  action(ActionTypes.FETCH_HOTELS_SUCCESS, { hotels });

export const fetchHotelsFail = (error: Error) => {
  return action(ActionTypes.FETCH_HOTELS_FAIL, { error });
};

export const fetchCurrentHotel = (hotelId: number) => {
  return action(ActionTypes.FETCH_CURRENT_HOTEL, { hotelId });
};

export const fetchCurrentHotelSuccess = (hotel: Hotel) => {
  return action(ActionTypes.FETCH_CURRENT_HOTEL_SUCCESS, { hotel });
};

export const fetchCurrentHotelFail = (error: Error) => {
  return action(ActionTypes.FETCH_CURRENT_HOTEL_FAIL, { error });
};

export const toggleHotelStatus = (hotel: Hotel) => {
  return action(ActionTypes.UPDATE_HOTEL_STATUS, { hotel });
};

export const toggleHotelStatusSuccess = (hotel: Hotel) => {
  return action(ActionTypes.UPDATE_HOTEL_STATUS_SUCCESS, { hotel });
};

export const toggleHotelStatusFail = (hotel: Hotel, error: Error) => {
  return action(ActionTypes.UPDATE_HOTEL_STATUS_FAIL, { hotel, error });
};

export const createHotel = (hotel: Hotel) => {
  return action(ActionTypes.CREATE_HOTEL, { hotel });
};

export const createHotelSuccess = (hotel: Hotel) => {
  return action(ActionTypes.CREATE_HOTEL_SUCCESS, { hotel });
};

export const createHotelFail = (error: Error) => {
  return action(ActionTypes.CREATE_HOTEL_FAIL, { error });
};

export const editHotel = (hotelId: number, hotel: Hotel) => {
  return action(ActionTypes.EDIT_HOTEL, { hotelId, hotel });
};

export const editHotelSuccess = (hotel: Hotel) => {
  return action(ActionTypes.EDIT_HOTEL_SUCCESS, { hotel });
};

export const editHotelFail = (error: Error) => {
  return action(ActionTypes.EDIT_HOTEL_FAIL, { error });
};

export const fetchFeatures = () => {
  return action(ActionTypes.FETCH_FEATURES);
};

export const fetchFeaturesSuccess = (features: Feature[]) => {
  return action(ActionTypes.FETCH_FEATURES_SUCCESS, { features });
};

export const fetchHotelDetails = (hotelId: number) => {
  return action(ActionTypes.FETCH_HOTEL_DETAILS, { hotelId });
};

export const fetchHotelDetailsSuccess = (
  editHotel: Hotel,
  editCurrencies: HotelCurrency[],
) => {
  return action(ActionTypes.FETCH_HOTEL_DETAILS_SUCCESS, {
    editHotel,
    editCurrencies,
  });
};

export const fetchHotelDetailsFail = (error: Error) => {
  return action(ActionTypes.FETCH_HOTEL_DETAILS_FAIL, { error });
};

export const addCms = (data: CMSConfigProps) => {
  return action(ActionTypes.ADD_CMS, { data });
};

export const addCmsSuccess = (CMS: unknown) => {
  return action(ActionTypes.ADD_CMS_SUCCESS, { CMS });
};

export const addExtranet = (hotelId: number, data: unknown) => {
  return action(ActionTypes.ADD_EXTRANET, { hotelId, data });
};

export const addExtranetSuccess = (hotel: Hotel) => {
  return action(ActionTypes.ADD_EXTRANET_SUCCESS, { hotel });
};

export const activeFeatureHotel = (hotelId: number, featureId: number) => {
  return action(ActionTypes.ACTIVE_FEATURE, { hotelId, featureId });
};

export const deactiveFeatureHotel = (hotelId: number, featureId: number) => {
  return action(ActionTypes.DEACTIVE_FEATURE, { hotelId, featureId });
};

export const toggleFeatureHotel = (
  hotelId: number,
  data: {
    disableFeatures: number[];
    enableFeatures: number[];
  },
) => {
  return action(ActionTypes.TOGGLE_FEATURE, { hotelId, data });
};

export const uploadImage = (
  params: Required<
    InjectPromise<
      any,
      {
        image: File;
      }
    >
  >,
) => {
  return action(ActionTypes.UPLOAD_IMAGE, params);
};

export const uploadImageSuccess = (image: unknown) => {
  return action(ActionTypes.UPLOAD_IMAGE_SUCCESS, { image });
};
