import React from 'react';
import { SkeletonTable } from '@aha/ui';
import { Skeleton } from '@uxui/skeleton-loader';
import classnames from 'classnames';

interface TableSkeletonScreenProps {
  className?: string;
}

export default function TableSkeletonScreen({
  className,
}: TableSkeletonScreenProps) {
  return (
    <div className={classnames(['bg-white', className])}>
      <div className="flex justify-between py-5 px-6 border-b border-grey-lighter">
        <Skeleton width="100px" height="16px" />
        <div className="flex items-center">
          <Skeleton width="40px" height="16px" className="mr-2" />
          <Skeleton width="80px" height="16px" />
        </div>
      </div>
      <div className="px-4 pb-2">
        <SkeletonTable />
      </div>
    </div>
  );
}
