import produce from 'immer';
import DashboardActionTypes from './constants';
import CurrencyActionTypes from 'containers/CurrencySettings/constants';
import AuthActionTypes from 'containers/Auth/constants';
import initialStateHolder from 'utils/initialStateHolder';
import { DashboardState, DashboardActions } from './type';
import { CurrencySettingsActions } from 'containers/CurrencySettings/type';
import { AuthActions } from 'containers/Auth/types';
import { HotelCurrency } from 'types/schema';

const hotelId = Number(window.localStorage.getItem('hid'));

export const initialState = initialStateHolder.setState<DashboardState>(
  'dashboard',
  {
    global: true,
    collapsed: false,
    hotelId: hotelId ? Number(hotelId) : null,
    hotelLoading: false,
    hotelSwitching: false,
    currencies: null,
    colors: null,
    cities: null,
    districts: null,
    hotel: null,
    facilities: null,
    amenities: null,
    error: null,
  },
);

/* eslint-disable default-case, no-param-reassign */
export default (
  state: DashboardState = initialState,
  action: DashboardActions | CurrencySettingsActions | AuthActions,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case DashboardActionTypes.TOGGLE_COLLAPSE:
        draft.collapsed = !draft.collapsed;
        break;
      case DashboardActionTypes.FETCH_CURRENT_HOTEL:
        draft.hotelLoading = true;
        break;
      case DashboardActionTypes.FETCH_CURRENT_HOTEL_FAIL:
        draft.hotelId = null;
        draft.hotelLoading = false;
        break;
      case DashboardActionTypes.FETCH_CURRENT_HOTEL_SUCCESS:
        draft.hotelLoading = false;
        draft.hotel = action.payload.hotel;
        draft.currencies = action.payload.currencies;
        break;
      case DashboardActionTypes.FETCH_COLORS:
        draft.colors = null;
        break;

      case DashboardActionTypes.FETCH_COLORS_SUCCESS:
        draft.colors = action.payload.colors;
        break;

      case DashboardActionTypes.FETCH_CITIES:
        draft.cities = null;
        break;

      case DashboardActionTypes.FETCH_CITIES_SUCCESS:
        draft.cities = action.payload.cities;
        break;

      case DashboardActionTypes.FETCH_FACILITIES:
        draft.facilities = null;
        break;

      case DashboardActionTypes.FETCH_FACILITIES_SUCCESS:
        draft.facilities = action.payload.facilities;
        break;

      case DashboardActionTypes.FETCH_AMENITIES:
        draft.amenities = null;
        break;

      case DashboardActionTypes.FETCH_AMENITIES_SUCCESS:
        draft.amenities = action.payload.amenities;
        break;

      case DashboardActionTypes.FETCH_DISTRICTS:
        draft.colors = null;
        break;

      case DashboardActionTypes.FETCH_DISTRICTS_SUCCESS:
        draft.districts = action.payload.districts;
        break;

      case DashboardActionTypes.SWITCH_CURRENT_HOTEL:
        draft.hotelSwitching = true;
        break;

      case DashboardActionTypes.SWITCH_CURRENT_HOTEL_SUCCESS:
        draft.hotelSwitching = false;
        draft.hotel = action.payload.hotel;
        if (action.payload.currencies) {
          draft.currencies = action.payload.currencies.filter(
            (currency: HotelCurrency) => currency,
          );
        }
        draft.hotelId = action.payload.hotelId;
        break;
      case DashboardActionTypes.SWITCH_CURRENT_HOTEL_FAIL:
        draft.hotelSwitching = false;
        break;

      case DashboardActionTypes.SET_CURRENT_HOTEL_ID:
        draft.hotelSwitching = true;
        draft.error = null;
        break;
      case DashboardActionTypes.SET_CURRENT_HOTEL_ID_SUCCESS:
        draft.hotelId = action.payload.hotelId;
        draft.hotelSwitching = false;
        break;
      case DashboardActionTypes.SET_CURRENT_HOTEL_ID_FAIL:
        draft.hotelSwitching = false;
        draft.error = action.payload.error;
        break;

      case CurrencyActionTypes.CREATE_CURRENCY_SUCCESS:
        draft.currencies?.push(action.payload);
        break;
      // FIXME: remove this once migration success
      case CurrencyActionTypes.EDIT_CURRENCY_SUCCESS:
        if (draft.currencies) {
          const index = draft.currencies.findIndex(
            ({ hoteId }) => hotelId === action.payload.hoteId,
          );
          draft.currencies[index] = action.payload;
        }
        break;
      // FIXME: remove this once migration success, change it assign
      case CurrencyActionTypes.DELETE_CURRENCY_SUCCESS:
        if (draft.currencies) {
          draft.currencies = draft.currencies.filter(
            ({ hoteId }) => hotelId !== action.payload,
          );
        }
        break;

      case AuthActionTypes.LOG_OUT:
        draft.hotelId = null;
    }
  });
};
