import { put, call, takeLatest } from 'redux-saga/effects';
import { coreAPI } from 'utils/request';
import { editUserSuccess } from '../actions';
import ActionTypes from '../constants';
import { ExtractAction } from 'types/actions';
import { AuthActions } from '../types';
import { showErrorNotification } from '@aha/utils';
import { UserUpdateUserResponse } from 'types/v3-schema';

export function* doEditUser(
  action: ExtractAction<AuthActions, ActionTypes.EDIT_USER>,
) {
  const { data: body, reject, resolve } = action.payload;
  try {
    const { data }: UserUpdateUserResponse = yield call(
      coreAPI.put,
      'v1/pms/users',
      body,
    );
    if (data) {
      yield put(editUserSuccess(data));
      resolve(data);
    }
  } catch (err) {
    showErrorNotification('Can not edit profile', err);
    reject(err);
  }
}

export default function* watchEditUser() {
  yield takeLatest(ActionTypes.EDIT_USER, doEditUser);
}
