import React, { useEffect, useState, useMemo, useRef, ReactText } from 'react';
import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Form, Tooltip, AutoComplete, Switch } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { makeSelectCompanyID } from 'containers/CreateBooking/selectors';
import { currencyFormater } from 'utils/currencyFormater';

import GuestSelect, { GuestOption } from 'components/GuestSelect';
import { Select, InputQuantity, Button, DateRangePicker } from '@aha/ui';
import { calcRatePrices } from 'containers/CreateBooking/helpers/calcTotalPrice';
import {
  makeSelectCurrentHotel,
  makeSelectCurrencies,
  ModelHotelWithFeatures,
} from 'containers/Dashboard/selectors';
import { makeSelectCompanies } from 'containers/BookingProvider/selector';
import { GuestForm } from 'containers/CreateBooking/types';
import { BookingengineCreateBookingForm } from 'types/v3-schema';
import {
  MarketSegment,
  HotelCurrency,
  RatePlan,
  Room,
  Guest,
  Company,
} from 'types/schema';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { ApplicationRootState } from 'types/app';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { DataSourceItemType } from 'antd/lib/auto-complete';
import { SelectValue } from 'antd/lib/select';
import LMembershipBadge from 'components/LMembershipBadge';
import { VIP_MEMBER, MEMBER } from 'constants/membership';
import { MembershipType } from '@aha/ui/src/components/MembershipBadge';
import { debounce } from 'lodash';
import { coreAPI } from 'utils/request';
import NProgress from 'nprogress';
import {
  showErrorNotification,
  showSuccessNotification,
  formatRoute,
} from '@aha/utils';
import { convertVie } from 'utils/app-location';
import CreateCompanyModal from 'containers/SettingCompaniesPage/components/CreateCompanyModal';
import useSelectCountries from 'containers/CreateBooking/useSelectCountries';
import { makeSelectMarketingSourcesItems } from 'containers/SegmentCodeSettings/selectors';
import { Link } from '@reach/router';
import ROUTES from 'constants/routes';
import { makeSelectRatePlans } from 'containers/RatePlansPage/selector';
import { LOYALTY_SEGMENTS } from 'constants/loyalty';
import { DD_MMM_YYYY } from '@aha/constants';
import { IconQuestionCircle, IconCoin, IconPlus } from '@aha/icons';

const StyledForm = styled(Form)`
  background: var(--white) !important;
  .ant-form-item-label {
    overflow: unset;
  }
`;

function memoRatePlanOptions(rates: RatePlan[] = []) {
  return rates.map((r) => ({
    text: `${r.name} - ${currencyFormater(r.price, r.currency?.name || 'VND')}`,
    value: r.id,
    isRatePlan: true,
  }));
}

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    hotel: ModelHotelWithFeatures | null;
    rates: RatePlan[];
    companies: Company[];
    currencies: HotelCurrency[];
    companyId?: string;
    segmentCodes: MarketSegment[] | null;
  }
>({
  hotel: makeSelectCurrentHotel(),
  rates: makeSelectRatePlans(),
  companies: makeSelectCompanies(),
  currencies: makeSelectCurrencies(),
  companyId: makeSelectCompanyID(),
  segmentCodes: makeSelectMarketingSourcesItems(),
});

export interface SimpleBookingFormProps {
  form: WrappedFormUtils;
  room: Room;
  onClose: (f5?: boolean) => void;
}

export type SubmitValues = {
  guestName: string;
  passport: string;
  rate: string;
  companyId: string;
  marketingSourceId: string;
};

export interface Booker extends Guest {
  phoneNumber?: string;
}

function SimpleBookingForm(props: SimpleBookingFormProps) {
  const {
    hotel,
    rates,
    companies,
    currencies,
    companyId: sourceCompanyID,
    segmentCodes,
  } = useSelector(mapStateToProps);

  const { form, room, onClose } = props;
  const {
    setFieldsValue,
    validateFields,
    resetFields,
    getFieldDecorator,
    getFieldValue,
  } = form;

  const initialDateRange = {
    arrival: moment(),
    departure: moment().add(1, 'day'),
  };

  const [isCreating, setIsCreating] = useState(false);
  const [booker, setBooker] = useState<Booker | null>(null);
  const [rateId, setRateId] = useState<string | undefined>(undefined);
  const [currency, setCurrency] = useState<string>();
  const [tempArrival, setTempArrival] = useState<
    moment.Moment | string | undefined
  >(undefined);

  const [adults, setAdults] = useState<number>(1);
  const [children, setChildren] = useState<number>(0);
  const [maxAdults, setMaxAdult] = useState<number | undefined>();
  const [maxChildren, setMaxChildren] = useState<number | undefined>();
  const [{ arrival, departure }, setDateRange] = useState<{
    arrival: moment.Moment | undefined;
    departure: moment.Moment | undefined;
  }>(initialDateRange);

  const [isTotalPrice, setIsTotalPrice] = useState<boolean>(false);
  const [showAddCompany, setShowAddCompany] = useState(false);
  const [price, setPrice] = useState<ReactText>(0);
  const [segmentCodeForm, setSegmentCodeForm] = useState<
    MarketSegment[] | null
  >(null);
  const [earnedCoins, setEarnedCoins] = useState<number>(0);
  const earnedCoinsDebounce = useRef(debounce(fetchCoinWillBeEarned, 700));
  const intl = useIntl();
  const { countries } = useSelectCountries();
  const companyId = getFieldValue('companyId');
  const segmentID = getFieldValue('marketingSourceId');

  async function fetchCoinWillBeEarned(priceRaw: number) {
    try {
      const { totalCoin } = await coreAPI.post(
        `v1/pms/memberships/earning-preview`,
        {
          isHourlyBooking: false,
          roomCharge: priceRaw,
          serviceCharge: 0,
        },
      );
      setEarnedCoins(totalCoin);
    } catch (e) {
      showErrorNotification(e);
      setEarnedCoins(0);
    }
  }

  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    async function fetchSegmentCodeForm(companyId: string) {
      NProgress.start();
      try {
        const { data } = await coreAPI.get(
          `v1/pms/companies/${companyId}/market-segments`,
        );

        setSegmentCodeForm(data);
        setFieldsValue({
          companyId,
          marketingSourceId: data?.[0]?.id,
        });
      } catch (err) {
        showErrorNotification('Fetch segment codes fail', err);
        setSegmentCodeForm(null);
      } finally {
        NProgress.done();
      }
    }

    if (companyId) {
      fetchSegmentCodeForm(companyId);
    }

    return () => setSegmentCodeForm(null);
  }, [companyId]); // eslint-disable-line

  useEffect(() => {
    if (sourceCompanyID) {
      setFieldsValue({
        companyId: sourceCompanyID,
      });
    }
  }, [sourceCompanyID]); // eslint-disable-line

  function onCreateBooking() {
    validateFields(async (err, values: SubmitValues) => {
      if (err || !hotel) {
        return;
      }

      try {
        const { companyId, marketingSourceId } = values;

        setIsCreating(true);
        const booking: BookingengineCreateBookingForm = {
          arrival: moment(arrival).toISOString(),
          departure: moment(departure).toISOString(),
          guests: booker ? [booker] : [],
          booker: booker as GuestForm,
          source: {
            companyId,
            marketingSourceId,
          },
          rooms: [
            {
              roomId: Number(room.id),
              roomTypeId: Number(room.roomType?.id),
              adults,
              children,
              currency: currency || hotel.currency || 'VND',
              // clear prefix 'rID' before submit
              stayRuleId: Number(rateId?.substr(3)),
              price: +price,
            },
          ],
          type: 'daily',
        };

        const { data: bk } = await coreAPI.post('v1/pms/bookings', booking);
        showSuccessNotification(
          'Created new booking',
          <div>
            ID Booking:
            <Link
              to={formatRoute(ROUTES.BOOKINGS_DETAIL, bk?.refCode)}
              className="ml-1"
            >
              {bk?.refCode}
            </Link>
          </div>,
        );
        setRateId(undefined);
        setBooker({});
        setDateRange(initialDateRange);
        setAdults(1);
        setChildren(0);

        resetFields();
      } catch (err) {
        console.error(err);
      } finally {
        setIsCreating(false);
      }
    });
  }

  function onSelectGuest(
    fname: string,
    value: string,
    returnValue: GuestOption,
  ) {
    if (returnValue?.user) {
      const {
        fullName,
        firstName,
        lastName,
        passport,
        phone,
      } = returnValue.user;

      setFieldsValue({
        guestName: fullName || [firstName, lastName].filter(Boolean).join(' '),
        passport,
        phone,
      });

      setBooker({ ...returnValue.user, phoneNumber: phone });
    } else {
      setBooker((g) => ({
        ...g,
        [fname]: value,
        membership: fname === 'firstName' && !value ? undefined : g?.membership,
        phoneNumber: g?.phone,
      }));
    }
  }

  function onSelectRoomRates(rid: SelectValue) {
    const selectedRate: RatePlan | undefined = rates?.find(
      (r) => r.id === +rid,
    );

    if (selectedRate) {
      //create unique id
      setRateId(`rID${rid}`);
    }

    const { maxAdult, maxChild } = selectedRate || {};

    setMaxAdult(maxAdult);
    setMaxChildren(maxChild);
    setCurrency(selectedRate?.currency?.name || hotel?.currency || 'VND');
  }

  function onSelectDateOnDateRangePicker([arr, dep]: RangePickerValue) {
    if (dep) {
      setTempArrival(undefined);
    } else {
      setTempArrival(arr);
    }
  }

  function onSelectDateRange([arr, dep]: RangePickerValue) {
    if (!dep) {
      arr = moment(arr);
      dep = moment(arr).add(1, 'day');
    }
    setDateRange({ arrival: arr, departure: dep });
  }

  const bkList = room?.bookings || [];

  function disabledDate(d?: moment.Moment) {
    // disable the booked days
    for (let i = 0; i < bkList.length || 0; i += 1) {
      const { arrival: arv, departure: dep } = bkList[i];

      if (moment(d).isBetween(arv, dep, 'day', '[)')) {
        return true;
      }
    }

    // disable the past days
    if (moment(d).diff(moment(), 'day') < 0) {
      return true;
    }

    const disableSelectedDate =
      moment(tempArrival).startOf('day').diff(d?.startOf('day'), 'day') === 0;

    return (
      disableSelectedDate ||
      moment(d).startOf('day').diff(moment().startOf('day'), 'days', false) < -1
    );
  }

  function changeAdults(value: number) {
    if (value < 1 || (maxAdults && value > maxAdults)) return;
    setAdults(value);
  }

  function changeChildren(value: number) {
    if (value < 0 || (maxChildren && value > maxChildren)) return;
    setChildren(value);
  }

  const currencyRates = useMemo(() => {
    return (currencies || []).reduce(
      (fin: { [k: string]: number }, c) => ({
        ...fin,
        [c.name as string]: c?.rate?.rate || 0,
      }),
      {},
    );
  }, [currencies]);

  const priceDetail = calcRatePrices(
    {
      arrival,
      departure,
      rateId,
      adults,
      children,
    },
    rates,
    { isTotalPrice, price: +price },
  );

  const selectedRate = priceDetail.currency
    ? currencyRates[priceDetail.currency] || 1
    : 1;

  const priceDetailFormatted = {
    ...priceDetail,
    total: priceDetail.total * selectedRate,
    price: priceDetail.price * selectedRate,
    extraPersonPrice: priceDetail.extraPersonPrice * selectedRate,
    extraChildPrice: priceDetail.extraChildPrice * selectedRate,
  };

  const dataSourceRateRoom = useMemo(
    () =>
      memoRatePlanOptions(
        rates.filter((r) => r.roomTypeId === room.roomTypeId && !r.closed),
      ),
    [rates, room],
  );

  // Loyalty
  const { balance = 0, rank } = booker?.membership || {};

  const showLoyalty =
    hotel?.isEnableLoyalty &&
    LOYALTY_SEGMENTS.includes(
      segmentCodes
        ?.find((s) => s.id === segmentID)
        ?.name?.toLowerCase() as string,
    );

  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    if (showLoyalty && rank === VIP_MEMBER) {
      earnedCoinsDebounce.current(priceDetailFormatted.total);
    }
  }, [rank, showLoyalty, priceDetailFormatted.total]);

  return (
    <>
      <StyledForm layout="vertical" className="p-6">
        <div className="flex flex-wrap -mx-2">
          <div className="col w-full px-2 md:w-1/2 lg:w-1/3">
            <Form.Item
              required
              label={
                <FormattedMessage
                  defaultMessage="Guest name"
                  id="common.label.guestFullName"
                />
              }
            >
              {getFieldDecorator('guestName', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
              })(
                <GuestSelect
                  onResponse={onSelectGuest}
                  hotelID={hotel?.id}
                  searchBy="firstName"
                  placeholder={intl.formatMessage({
                    id: 'common.label.enterGuestName',
                    defaultMessage: 'Enter name',
                  })}
                />,
              )}
            </Form.Item>
          </div>
          <div className="col w-full px-2 md:w-1/2 lg:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Passport/ID"
                  id="common.label.passport"
                />
              }
            >
              {getFieldDecorator(
                'passport',
                {},
              )(
                <GuestSelect
                  onResponse={onSelectGuest}
                  hotelID={hotel?.id}
                  searchBy="passport"
                  placeholder={intl.formatMessage({
                    id: 'common.label.searchGuestByPassport',
                    defaultMessage: 'Enter passport or ID',
                  })}
                />,
              )}
            </Form.Item>
          </div>
          <div className="col w-full px-2 md:w-1/2 lg:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Phone number"
                  id="newBooking.phoneNumber"
                />
              }
            >
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
              })(
                <GuestSelect
                  onResponse={onSelectGuest}
                  hotelID={hotel?.id}
                  searchBy="phone"
                  placeholder={intl.formatMessage({
                    id: 'form.label.phone',
                    defaultMessage: 'Enter phone number',
                  })}
                />,
              )}
            </Form.Item>
          </div>
          {showLoyalty && (
            <div className="w-full px-2">
              <Form.Item>
                <LMembershipBadge type={rank as MembershipType} />
                {rank === VIP_MEMBER && (
                  <>
                    <span className="font-medium ml-2 mr-1">
                      <FormattedMessage
                        defaultMessage="Available xu:"
                        id="bookings.label.availableCoins"
                      />
                    </span>
                    {balance}
                    <span className="ml-1">
                      <FormattedMessage
                        defaultMessage="xu"
                        id="common.label.coins"
                      />
                    </span>
                  </>
                )}
              </Form.Item>
            </div>
          )}
        </div>

        <div className="flex flex-wrap -mx-2">
          <div className="col w-full px-2 md:w-1/2">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Company"
                  id="common.label.company"
                />
              }
            >
              {getFieldDecorator('companyId', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
              })(
                <Select<string>
                  showSearch
                  placeholder={
                    <FormattedMessage
                      defaultMessage="Select"
                      id="common.label.select_company"
                    />
                  }
                  filterOption={(input, option) =>
                    convertVie(
                      (option.props.children || '').toString().toLowerCase(),
                    ).indexOf(convertVie(input.toLowerCase())) >= 0
                  }
                  optionFilterProp="children"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Button
                        type="icon"
                        icon={IconPlus}
                        className="w-full flex justify-center items-center text-sm font-medium"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => setShowAddCompany(true)}
                      >
                        <FormattedMessage
                          defaultMessage="Add New Company"
                          id="bookings.label.addNewCompany"
                        />
                      </Button>
                    </div>
                  )}
                >
                  {(companies || []).map((c) => (
                    <Select.Option key={c.id} value={c.id}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </div>
          <div className="col px-2 w-full md:w-1/2">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Segment Code"
                  id="settings.label.marketing_sources"
                />
              }
            >
              {getFieldDecorator('marketingSourceId', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({
                    id: 'reports.label.selectSegmet_code',
                    defaultMessage: 'Select market segment code',
                  })}
                  disabled={!segmentCodeForm}
                >
                  {segmentCodeForm?.map((c) => (
                    <Select.Option key={c.id} value={c.id}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </div>
          <div className="col w-full px-2 md:w-1/2 lg:w-1/2">
            <Form.Item
              label={
                <>
                  <FormattedMessage
                    defaultMessage="Room Rate"
                    id="common.label.roomRate"
                  />
                  <Tooltip
                    title={
                      isTotalPrice ? (
                        <FormattedMessage
                          defaultMessage="Total Price Per Room"
                          id="newBookingPage.totalPrice"
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage="Price per night"
                          id="newBookingPage.pricePerNight"
                        />
                      )
                    }
                  >
                    <Switch
                      size="small"
                      className="float-right"
                      onChange={setIsTotalPrice}
                      checked={isTotalPrice}
                    />
                  </Tooltip>
                </>
              }
            >
              {getFieldDecorator('rate', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                  {
                    validator: (rule, value, cb) =>
                      value &&
                      isNaN(value) &&
                      (dataSourceRateRoom || []).findIndex(
                        (i) => i.value === value,
                      ) < 0
                        ? cb(
                            <FormattedMessage
                              id="common.message.invalid"
                              defaultMessage="Invalid"
                            />,
                          )
                        : cb(),
                  },
                ],
              })(
                <AutoComplete
                  placeholder={
                    <FormattedMessage
                      defaultMessage="Enter price"
                      id="common.label.enter_price"
                    />
                  }
                  dataSource={
                    (dataSourceRateRoom as unknown) as DataSourceItemType[]
                  }
                  onSelect={onSelectRoomRates}
                  onSearch={setPrice}
                />,
              )}
            </Form.Item>
          </div>

          <div className="col w-full px-2 md:w-1/2 lg:w-1/4">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Adults"
                  id="common.label.numberOfAdult"
                />
              }
            >
              <InputQuantity
                name="numberOfAdult"
                className="w-full"
                value={adults}
                onChange={(value) => changeAdults(value)}
              />
            </Form.Item>
          </div>
          <div className="col w-full px-2 md:w-1/2 lg:w-1/4">
            <Form.Item
              label={
                <div className="inline-flex">
                  <FormattedMessage
                    defaultMessage="Children"
                    id="common.label.numberOfChild"
                  />
                  <Tooltip
                    title={
                      <FormattedMessage
                        defaultMessage="Under 7 years olds"
                        id="bookings.label.under_seven_years_olds"
                      />
                    }
                    placement="topRight"
                    arrowPointAtCenter
                  >
                    <IconQuestionCircle className="text-xs ml-1" />
                  </Tooltip>
                </div>
              }
            >
              <InputQuantity
                name="numberOfChild"
                className="w-full"
                value={children}
                onChange={(value) => changeChildren(value)}
              />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-wrap -mx-2">
          <div className="col w-full px-2 lg:w-1/2">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Stay"
                  id="common.label.stay"
                />
              }
            >
              <DateRangePicker
                className="w-full"
                format={DD_MMM_YYYY}
                disabledDate={disabledDate}
                onChange={onSelectDateRange}
                value={[arrival, departure] as RangePickerValue}
                onCalendarChange={onSelectDateOnDateRangePicker}
              />
            </Form.Item>
          </div>
        </div>
      </StyledForm>

      <div className="mt-4 bg-white p-6 py-4">
        <div className="flex justify-end items-center text-right">
          <div className="leading-none">
            <Tooltip
              placement="topRight"
              title={
                <div className="w-40">
                  <div className="flex justify-between">
                    <FormattedMessage
                      defaultMessage="Price"
                      id="bookings.label.price"
                    />
                    <div className="w-1/2 truncate text-right">
                      {currencyFormater(priceDetailFormatted.price)}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <FormattedMessage
                      defaultMessage="Extra adult price"
                      id="bookings.label.extra_adult_price"
                    />
                    <div className="w-1/2 truncate text-right">
                      {currencyFormater(priceDetailFormatted.extraPersonPrice)}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <FormattedMessage
                      defaultMessage="Extra child price"
                      id="bookings.label.extra_child_price"
                    />
                    <div className="w-1/2 truncate text-right">
                      {currencyFormater(priceDetailFormatted.extraChildPrice)}
                    </div>
                  </div>
                </div>
              }
            >
              <div>
                <span className="font-medium text-base">
                  <FormattedMessage
                    defaultMessage="Total Charges:"
                    id="common.label.totalCharge"
                  />
                </span>
                <span className="ml-2 text-sm">
                  {currencyFormater(priceDetailFormatted.total)}
                </span>
              </div>
            </Tooltip>

            {showLoyalty && (
              <div
                className="inline-flex items-center mt-2"
                style={{ height: 26 }}
              >
                {rank === VIP_MEMBER ? (
                  <>
                    <IconCoin className="text-xl mr-1" />
                    <span className="font-medium">
                      <FormattedMessage
                        defaultMessage="Xu will be earned:"
                        id="bookings.label.CoinsWillBeEarned"
                      />
                    </span>
                    <span className="ml-2 mr-1">{earnedCoins || 0}</span>
                    <FormattedMessage
                      defaultMessage="xu"
                      id="common.label.coins"
                    />
                  </>
                ) : rank === MEMBER ? (
                  <span className="italic">
                    <FormattedMessage
                      defaultMessage="Non-VIP user can't earn xu"
                      id="bookings.label.cannotEarnCoins"
                    />
                  </span>
                ) : (
                  <span className="italic">
                    <FormattedMessage
                      defaultMessage="Membership is not applicable"
                      id="bookings.label.membershipNotApplicable"
                    />
                  </span>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-between pt-3 mt-3 border-t">
          <Button
            type="line"
            color="primary"
            size="large"
            className="mr-2"
            onClick={() => onClose(false)}
          >
            <FormattedMessage
              defaultMessage="Cancel"
              id="common.action.cancel"
            />
          </Button>
          <Button
            className="px-10"
            size="large"
            onClick={onCreateBooking}
            disabled={isCreating}
          >
            <FormattedMessage
              defaultMessage="Create"
              id="common.label.create"
            />
          </Button>
        </div>
      </div>
      <CreateCompanyModal
        visible={showAddCompany}
        onClose={() => setShowAddCompany(false)}
        currentHotelId={hotel?.id}
        mkSourceItems={segmentCodes || []}
        countries={countries || []}
      />
    </>
  );
}

export default Form.create<SimpleBookingFormProps>({
  name: 'create_booking_simple_form',
})(SimpleBookingForm);
