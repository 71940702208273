import { call, put, takeLatest } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { fetchEditHistorySuccess, fetchEditHistoryFail } from '../actions';
import ActionTypes from '../constants';

function* doFetchHistory() {
  try {
    const { data } = yield call(
      coreAPI.get,
      `v1/pms/operation/logs/rate-plans/edit-history`,
    );
    yield put(fetchEditHistorySuccess(data));
  } catch (err) {
    yield put(fetchEditHistoryFail(err));
  }
}

export default function* watchFetchEditHistory() {
  yield takeLatest(ActionTypes.FETCH_EDIT_HISTORY, doFetchHistory);
}
