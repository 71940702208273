import React from 'react';
import Routes from './Routes';
import { Provider } from 'react-redux';
import LanguageProvider from 'containers/LanguageProvider';
import configureStore from './configureStore';
import MaintenancePage from 'containers/MaintenancePage';

// set up translation for moment on first load
const langCode = window.localStorage.getItem('lan') || 'en';
if (langCode === 'vi') {
  // @ts-ignore: module not found
  import('moment/locale/vi')
    .then(() => import('moment'))
    .then(({ default: moment }) => moment.locale('vi'))
    .catch(console.error);
}

const initialState = {};
export const store = configureStore(initialState);

export default function App() {
  const innerRender =
    process.env.REACT_APP_MAINTENANCE === 'true' ? (
      <MaintenancePage />
    ) : (
      <Routes />
    );

  return (
    <Provider store={store}>
      <LanguageProvider>{innerRender}</LanguageProvider>
    </Provider>
  );
}
