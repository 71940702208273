import { BookingRoomDetail, Folio } from 'types/schema';

export const isGroupFolio = (folio?: Folio) => {
  return (
    !!folio?.charges?.some(
      (c) =>
        c.bookingRoomId &&
        c.bookingRoomId !== folio.bookingRoomId &&
        c.status !== 'voided',
    ) && folio.status === 'active'
  );
};

export const validRooms = (rooms?: BookingRoomDetail[]) =>
  (rooms || []).filter(
    (r) => !['cancelled', 'checked_out'].includes(r.status as string),
  );
