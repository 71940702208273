const ROUTES = {
  DASHBOARD: '/',
  SELECT_HOTEL: '/select-hotel',
  HOTELS: '/hotels',
  NEW_HOTEL: '/hotels/new',
  EDIT_HOTEL: '/hotels/:hotelId',
  ROOMS: '/rooms',
  LOCK_DOOR: '/integrations/lock-door',
  CALENDAR: '/calendar',
  RATES: '/rates',
  ROOM_INVENTORY: '/room-inventory',
  CREATE_BOOKING: '/new-booking',
  NOT_AUTHORIZE: '/403',
  SERVER_ERROR: '/500',
  NOT_FOUND: '/404',
  INVALID_PATH: '*',

  START: '/start',
  LOGIN: '/start/login',
  FORGOT_PASSWORD: '/start/forgot-password',
  RESET_PASSWORD: '/start/reset-password',

  BOOKINGS: '/bookings',
  BOOKINGS_DETAIL: '/bookings/:fid',
  ARRIVAL_BOOKING: '/bookings/arrival',
  ARRIVAL_DAILY_BOOKING: '/bookings/arrival/daily',
  ARRIVAL_HOURLY_BOOKING: '/bookings/hourly',
  ARRIVAL_NIGHT_BOOKING: '/bookings/arrival/nightly',
  DEPARTURE_BOOKING: '/bookings/departure',
  DEPARTURE_DAILY_BOOKING: '/bookings/departure/daily',
  DEPARTURE_NIGHTLY_BOOKING: '/bookings/departure/nightly',
  INHOUSE_BOOKING: '/bookings/inhouse',
  INHOUSE_DAILY_BOOKING: '/bookings/inhouse/daily',
  INHOUSE_NIGHTLY_BOOKING: '/bookings/inhouse/nightly',
  FOLIO_BOOKING: '/bookings/:bid/folios/:fid',
  DETAIL_BOOKING: '/bookings/:bid',

  SEARCH: '/search',
  DAILY_BOOKING_SEARCH: '/search/daily-bookings',
  HOURLY_BOOKING_SEARCH: '/search/hourly-bookings',

  COMPANIES_AND_GUESTS: '/companies-n-guests',
  COMPANIES_SETTING: '/companies-n-guests/companies',
  GUESTS_SETTING: '/companies-n-guests/guests',
  GUESTS_DETAIL: '/companies-n-guests/guests/:gid',

  REPORTS: '/reports',
  HOURLY_REPORT: '/reports/hourly-report',
  HOURLY_CHARGE_REPORT: '/reports/hourly-report/charge',
  HOURLY_PAYMENT_REPORT: '/reports/hourly-report/payment',
  WAREHOUSE_REPORT: '/reports/warehouse-report',
  PAYMENT_REPORT: '/reports/payment-report',
  OCCUPANCY_AND_CHARGE_REPORT: '/reports/occupancy-charge',
  COMPANIES_REPORT: '/reports/companies-report',
  STATISTICAL_REPORT: '/reports/statistical-report',
  BUDGET_REPORT: '/reports/budget-report',
  SEGMENT_REPORT: '/reports/segment-report',
  LOYALTY_REPORT: '/reports/loyalty',

  SETTINGS: '/settings',
  CURRENCY_SETTING: '/settings/currencies',
  USER_SETTING: '/settings/users',
  PAYMENT_TYPES_SETTING: '/settings/payment-types',
  SERVICE_GROUPS_SETTING: '/settings/service-groups',
  ROOM_TYPE_SETTING: '/settings/rooms',
  ROOM_TYPE_DETAIL_SETTING: '/settings/rooms/:id',
  HOURLY_SERVICES_SETTING: '/settings/hourly-services',
  CHARGE_TEMPLATES_SETTING: '/settings/charge-templates',
  MARKET_SEGMENTS_SETTING: '/settings/market-segments',
  BUDGET_SETTING: '/settings/budget',

  PRINTING: '/printing',
  REG_CARD_PRINTING: '/printing/reg-card/:bid',
  INVOICE_PRINTING: '/printing/invoice/:fid',

  PROFILE: '/profile',
};

export default ROUTES;
