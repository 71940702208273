enum ActionTypes {
  MARKETING_SOURCES_KEY = 'marketingSources',
  FETCH_MARKETING_SOURCES = 'marketingSources/FETCH_MARKETING_SOURCES',
  FETCH_MARKETING_SOURCES_SUCCESS = 'marketingSources/FETCH_MARKETING_SOURCES_SUCCESS',
  FETCH_MARKETING_SOURCES_FAIL = 'marketingSources/FETCH_MARKETING_SOURCES_FAIL',
  RESET_MARKETING_SOURCES_STATE = 'marketingSources/RESET_MARKETING_SOURCES_STATE',
  DELETE_MARKETING_SOURCE = 'marketingSources/DELETE_MARKETING_SOURCE',
  DELETE_MARKETING_SOURCE_SUCCESS = 'marketingSources/DELETE_MARKETING_SOURCE_SUCCESS',
  DELETE_MARKETING_SOURCE_FAIL = 'marketingSources/DELETE_MARKETING_SOURCE_FAIL',
  CREATE_MARKETING_SOURCE = 'marketingSources/CREATE_MARKETING_SOURCE',
  CREATE_MARKETING_SOURCE_SUCCESS = 'marketingSources/CREATE_MARKETING_SOURCE_SUCCESS',
  CREATE_MARKETING_SOURCE_FAIL = 'marketingSources/CREATE_MARKETING_SOURCE_FAIL',
  EDIT_MARKETING_SOURCE = 'marketingSources/EDIT_MARKETING_SOURCE',
  EDIT_MARKETING_SOURCE_SUCCESS = 'marketingSources/EDIT_MARKETING_SOURCE_SUCCESS',
  EDIT_MARKETING_SOURCE_FAIL = 'marketingSources/EDIT_MARKETING_SOURCE_FAIL',
}

export default ActionTypes;
