import ActionTypes from './constants';
import { AdelConfig } from './type';
import { action } from 'typesafe-actions';

export function startService(config: AdelConfig) {
  return action(ActionTypes.START_SERVICE, { config });
}

export function startServiceSuccess(config: AdelConfig) {
  return action(ActionTypes.START_SERVICE_SUCCESS, { config });
}

export function startServiceError(error: Error) {
  return action(ActionTypes.START_SERVICE_ERROR, { error });
}

export function stopService() {
  return action(ActionTypes.STOP_SERVICE);
}

export function stopServiceSuccess() {
  return action(ActionTypes.STOP_SERVICE_SUCCESS);
}

export function stopServiceError(error: Error) {
  return action(ActionTypes.STOP_SERVICE_ERROR, { error });
}

export function checkInLockDoor(data: {}) {
  return action(ActionTypes.CHECKIN_LOCK_DOOR, { data });
}

export function checkinLockDoorSuccess() {
  return action(ActionTypes.CHECKIN_LOCK_DOOR_SUCCESS);
}

export function checkinLockDoorError(error: Error) {
  return action(ActionTypes.CHECKIN_LOCK_DOOR_ERROR, { error });
}

export function dupKeyLockDoor(data: {}) {
  return action(ActionTypes.DUPKEY_LOCK_DOOR, { data });
}

export function dupKeyLockDoorSuccess() {
  return action(ActionTypes.DUPKEY_LOCK_DOOR_SUCCESS);
}

export function dupKeyLockDoorError(error: Error) {
  return action(ActionTypes.DUPKEY_LOCK_DOOR_ERROR, { error });
}

export function readCardLockDoor(hotelId: number, searchType: string) {
  return action(ActionTypes.READ_CARD_LOCK_DOOR, {
    hotelId,
    searchType,
  });
}

export function readCardLockDoorSuccess(cardInfo = {}, bookingInfo = {}) {
  return action(ActionTypes.READ_CARD_LOCK_DOOR_SUCCESS, {
    cardInfo,
    bookingInfo,
  });
}

export function readCardLockDoorError(error: Error) {
  return action(ActionTypes.READ_CARD_LOCK_DOOR_ERROR, { error });
}

export function checkoutLockDoor(data: {}) {
  return action(ActionTypes.CHECKOUT_LOCK_DOOR, { data });
}

export function checkoutLockDoorSuccess(cardInfo = {}, bookingInfo = {}) {
  return action(ActionTypes.CHECKOUT_LOCK_DOOR_SUCCESS, {
    cardInfo,
    bookingInfo,
  });
}

export function checkoutLockDoorError(error: Error) {
  return action(ActionTypes.CHECKOUT_LOCK_DOOR_ERROR, { error });
}

export function lockDoorServiceIsAvailable() {
  return action(ActionTypes.LOCK_DOOR_SERVICE_IS_AVAILABLE);
}

export function lockDoorServiceIsNotAvailable() {
  return action(ActionTypes.LOCK_DOOR_SERVICE_IS_NOT_AVAILABLE);
}

export function reportLostCardLockDoorSuccess() {
  return action(ActionTypes.REPORT_LOST_CARD_LOCK_DOOR_SUCCESS);
}

export function reportLostCardLockDoorError(error: Error) {
  return action(ActionTypes.REPORT_LOST_CARD_LOCK_DOOR_ERROR, { error });
}

export function changeLockSystem(lockSystem: number) {
  return action(ActionTypes.CHANGE_LOCK_SYSTEM, { lockSystem });
}

export function reportLostCardLockDoor(roomNumber = '') {
  return action(ActionTypes.REPORT_LOST_CARD_LOCK_DOOR, { roomNumber });
}

export function setLocalStartService(config: AdelConfig) {
  return action(ActionTypes.SET_LOCAL_START_SERVICE, { config });
}

export function checkLockDoorServiceStatus() {
  return action(ActionTypes.LOCK_DOOR_SERVICE_STATUS);
}

export function lockDoorResetReadState(cardInfo = {}, bookingInfo = {}) {
  return action(ActionTypes.LOCK_DOOR_RESET_READ_STATE, {
    cardInfo,
    bookingInfo,
  });
}
