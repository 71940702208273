import { all, put, call, takeEvery } from 'redux-saga/effects';
import { lockDoorAPI, coreAPI } from 'utils/request';
import { showErrorNotification, showSuccessNotification } from '@aha/utils';
import loGet from 'lodash/get';
import NProgress from 'nprogress';
import ActionTypes from './constants';
import {
  startServiceSuccess,
  startServiceError,
  stopServiceSuccess,
  stopServiceError,
  checkinLockDoorSuccess,
  checkinLockDoorError,
  dupKeyLockDoorSuccess,
  dupKeyLockDoorError,
  readCardLockDoorSuccess,
  readCardLockDoorError,
  checkoutLockDoorSuccess,
  checkoutLockDoorError,
  lockDoorServiceIsAvailable,
  lockDoorServiceIsNotAvailable,
  reportLostCardLockDoorSuccess,
  reportLostCardLockDoorError,
} from './actions';
import { ExtractAction } from 'types/actions';
import { LockDoorActions } from './type';
import { intl } from 'utils/IntlGlobal';
import { Booking } from 'types/schema';

function* doStartService(
  action: ExtractAction<LockDoorActions, ActionTypes.START_SERVICE>,
) {
  const {
    payload: { config },
  } = action;
  try {
    yield call(lockDoorAPI.post, '/api/v1/start-service', config);
    yield put(startServiceSuccess(config));
    showSuccessNotification('Start service successful!');
  } catch (e) {
    showErrorNotification(`Start service error ${e.message}`);
    yield put(startServiceError(e));
  }
}

function* watchStartService() {
  yield takeEvery(ActionTypes.START_SERVICE, doStartService);
}

function* doStopService(
  action: ExtractAction<LockDoorActions, ActionTypes.STOP_SERVICE>,
) {
  try {
    yield call(lockDoorAPI.post, '/api/v1/end-service');
    yield put(stopServiceSuccess());
    showSuccessNotification('Lockdoor connector service stopped!');
  } catch (e) {
    showErrorNotification(`Stop service err: ${e.message}`);
    yield put(stopServiceError(e));
  }
}

function* watchStopService() {
  yield takeEvery(ActionTypes.STOP_SERVICE, doStopService);
}

function* doRequestCheckinLockDoor(
  action: ExtractAction<LockDoorActions, ActionTypes.CHECKIN_LOCK_DOOR>,
) {
  const {
    payload: { data },
  } = action;
  try {
    yield call(lockDoorAPI.post, '/api/v1/checkin', data);
    yield put(checkinLockDoorSuccess());
    showSuccessNotification('Issued new card!');
  } catch (e) {
    showErrorNotification(`Issue card ${e.message}`);
    yield put(checkinLockDoorError(e));
  }
}

function* watchRequestCheckinLockDoor() {
  yield takeEvery(ActionTypes.CHECKIN_LOCK_DOOR, doRequestCheckinLockDoor);
}

function* doRequestDupKeyLockDoor(
  action: ExtractAction<LockDoorActions, ActionTypes.DUPKEY_LOCK_DOOR>,
) {
  const {
    payload: { data },
  } = action;
  try {
    yield call(lockDoorAPI.post, '/api/v1/dupkey', data);
    yield put(dupKeyLockDoorSuccess());
    showSuccessNotification('Duplicated new card!');
  } catch (e) {
    yield put(dupKeyLockDoorError(e));
    showErrorNotification(`DupKey lock door err: ${e.message}`);
  }
}

function* watchRequestDupKeyLockDoor() {
  yield takeEvery(ActionTypes.DUPKEY_LOCK_DOOR, doRequestDupKeyLockDoor);
}

function* doReadCardLockDoor(
  action: ExtractAction<LockDoorActions, ActionTypes.READ_CARD_LOCK_DOOR>,
) {
  const {
    payload: { hotelId, searchType },
  } = action;
  yield call(NProgress.start);

  try {
    const res = yield call(lockDoorAPI.get, '/api/v1/read-card');
    // const res = { data: { roomNumber: '010101' } };
    if (hotelId && searchType === 'booking') {
      // TODO: Add search booking
      // const { data: bkInfos } = searchBooking(hid, {
      //   lockDoorRoomNo: loGet(res, 'data.roomNumber'),
      // });
      const bkInfos: Booking[] = [];
      yield put(readCardLockDoorSuccess(loGet(res, 'data', {}), bkInfos[0]));

      return {
        cardInfo: loGet(res, 'data', {}),
        bookingInfo: bkInfos[0],
      };
    } else if (hotelId && searchType === 'hourly_booking') {
      const { data } = yield call(
        coreAPI.get,
        `v1/pms/additional-services?lockDoorRoomNo=${loGet(
          res,
          'data.roomNumber',
        )}`,
      );

      yield put(readCardLockDoorSuccess(loGet(res, 'data', {}), data));
      return {
        cardInfo: loGet(res, 'data', {}),
        bookingInfo: data,
      };
    }
    yield put(readCardLockDoorSuccess(loGet(res, 'data', {})));
    showSuccessNotification('Read card info!');
    return { cardInfo: loGet(res, 'data', {}) };
  } catch (e) {
    showErrorNotification(`Read card: ${e.message}`);
    yield put(readCardLockDoorError(e));
  }
  yield call(NProgress.done);
}

function* watcReadCardLockDoor() {
  yield takeEvery(ActionTypes.READ_CARD_LOCK_DOOR, doReadCardLockDoor);
}

// FIXME: fix this function. if anyone need using.
// this function will erase card
function* doRequestCheckOutWithLockDoor(
  action: ExtractAction<LockDoorActions, ActionTypes.CHECKOUT_LOCK_DOOR>,
) {
  const {
    payload: { data },
  } = action;

  try {
    yield call(lockDoorAPI.post, '/api/v1/checkout', data);
    yield put(checkoutLockDoorSuccess());
    showSuccessNotification('Lock card erased!');
  } catch (e) {
    yield put(checkoutLockDoorError(e));
    showErrorNotification(`Erase card ${e.message}`);
  }
}

function* watchRequestCheckOutWithLockDoor() {
  yield takeEvery(
    ActionTypes.CHECKOUT_LOCK_DOOR,
    doRequestCheckOutWithLockDoor,
  );
}

function* doCheckLockDoorServiceStatus() {
  yield call(NProgress.start);
  try {
    const { Status } = yield call(lockDoorAPI.get, '/api/v1/status');
    if (Status) {
      yield put(lockDoorServiceIsAvailable());
    } else {
      yield put(lockDoorServiceIsNotAvailable());
    }
  } catch (e) {
    yield put(lockDoorServiceIsNotAvailable());
    showErrorNotification(
      intl.formatMessage({
        id: 'common.label.guideLockdoor',
        defaultMessage: 'Please start AHARooms Lockdoor connector to continue',
      }),
      '',
    );
  }
  yield call(NProgress.done);
}

function* watchCheckLockDoorServiceStatus() {
  yield takeEvery(
    ActionTypes.LOCK_DOOR_SERVICE_STATUS,
    doCheckLockDoorServiceStatus,
  );
}

function* doReportLostLockDoorCard(
  action: ExtractAction<
    LockDoorActions,
    ActionTypes.REPORT_LOST_CARD_LOCK_DOOR
  >,
) {
  const {
    payload: { roomNumber },
  } = action;
  try {
    yield call(lockDoorAPI.post, '/api/v1/lost-card', roomNumber);
    yield put(reportLostCardLockDoorSuccess());
    showSuccessNotification('Mark card was lost!');
  } catch (e) {
    showErrorNotification(`Cant report lost card: ${e.message}`);
    yield put(reportLostCardLockDoorError(e));
  }
}

function* watchReportLostLockDoorCard() {
  yield takeEvery(
    ActionTypes.REPORT_LOST_CARD_LOCK_DOOR,
    doReportLostLockDoorCard,
  );
}

export default function* watchAll() {
  yield all([
    watcReadCardLockDoor(),
    watchStartService(),
    watchStopService(),
    watchRequestCheckinLockDoor(),
    watchRequestDupKeyLockDoor(),
    watchRequestCheckOutWithLockDoor(),
    watchCheckLockDoorServiceStatus(),
    watchReportLostLockDoorCard(),
  ]);
}
