import ActionTypes from './constants';
import { action } from 'typesafe-actions';
import { InjectPromise } from 'types/actions';
import { Company } from 'types/schema';

export function fetchCompanies(hotelId?: number) {
  return action(ActionTypes.FETCH_COMPANIES, hotelId);
}

export function fetchCompaniesSuccess(companies: Company[]) {
  return action(ActionTypes.FETCH_COMPANIES_SUCCESS, companies);
}

export function fetchCompaniesFail(error: Error) {
  return action(ActionTypes.FETCH_COMPANIES_FAIL, error);
}

export function deleteCompany(companyId: number) {
  return action(ActionTypes.DELETE_COMPANY, companyId);
}

export function deleteCompanySuccess(companyId: number) {
  return action(ActionTypes.DELETE_COMPANY_SUCCESS, companyId);
}

export function deleteCompanyFail(error: Error) {
  return action(ActionTypes.DELETE_COMPANY_FAIL, error);
}

export function createCompany(
  params: Required<InjectPromise<any, { hotelId: number; company: Company }>>,
) {
  return action(ActionTypes.CREATE_COMPANY, params);
}

export function createCompanySuccess(company: Company) {
  return action(ActionTypes.CREATE_COMPANY_SUCCESS, company);
}

export function createCompanyFail(error: Error) {
  return action(ActionTypes.CREATE_COMPANY_FAIL, error);
}

export function editCompany(
  params: Required<
    InjectPromise<Company[], { companyId: number; company: Company }>
  >,
) {
  return action(ActionTypes.EDIT_COMPANY, params);
}

export function editCompanySuccess(company: Company) {
  return action(ActionTypes.EDIT_COMPANY_SUCCESS, company);
}

export function editCompanyFail(error: Error) {
  return action(ActionTypes.EDIT_COMPANY_FAIL, error);
}
