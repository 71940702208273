import * as React from 'react';

class ErrorBoundary extends React.Component<
  {
    fallback?: React.ReactNode;
    callBack?: (err: Error, errorInfo: React.ErrorInfo) => void;
  },
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error);
    if (this.props.callBack) {
      this.props.callBack(error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback ? this.props.fallback : null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
