import { uniqBy } from 'lodash';

import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { ApplicationRootState } from 'types/app';
import { RoomType } from 'types/schema';

export const selectRoomPlan = (state: ApplicationRootState) =>
  state.roomPlan || initialState;

export const makeSelectLoading = () =>
  createSelector(selectRoomPlan, (state) => state.loading);

export const makeSelectLoadingRefresh = () =>
  createSelector(selectRoomPlan, (state) => state.loadingRefresh);

export const makeSelectIsRefreshRoom = () =>
  createSelector(selectRoomPlan, (state) => state.isRefreshRoom);

export const makeSelectLoadingActions = () =>
  createSelector(selectRoomPlan, (state) => state.loadingActions);

export const makeSelectError = () =>
  createSelector(selectRoomPlan, (state) => state.error);

export const makeSelectOpenCreateLog = () =>
  createSelector(selectRoomPlan, (state) => state.openCreateLog);

export const makeSelectRooms = () =>
  createSelector(selectRoomPlan, ({ rooms }) =>
    !rooms
      ? rooms
      : rooms?.filter(
          (r) => r.roomType?.status !== 'inactive' && r.status !== 'disabled',
        ),
  );

export const makeSelectRoomTypes = () =>
  createSelector(selectRoomPlan, ({ rooms }) =>
    rooms
      ? (uniqBy(
          (rooms || []).map((r) => r.roomType),
          'id',
        )
          .filter(Boolean)
          .filter((rt) => rt?.status !== 'disabled') as RoomType[])
      : rooms,
  );
