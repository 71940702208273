import produce from 'immer';
import ActionTypes from './constants';
import { AuthState, AuthActions } from './types';

export const initialState: AuthState = {
  global: true,
  isLogin: !!window.localStorage.getItem('token'),
  user: null,
  userIsLoading: false,
  userLoadError: null,
  permissions: [],
  resetSuccess: false,
  emailResetPassword: '',
  changePasswordSuccess: false,
};

/* eslint-disable default-case, no-param-reassign */
const authReducer = (state = initialState, action: AuthActions): AuthState =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.LOG_IN_SUCCESS:
        draft.isLogin = true;
        break;
      case ActionTypes.LOG_OUT:
        draft.isLogin = false;
        break;
      case ActionTypes.FETCH_CURRENT_USER:
        draft.userIsLoading = true;
        draft.userLoadError = null;
        break;
      case ActionTypes.FETCH_CURRENT_USER_SUCCESS:
        draft.userIsLoading = false;
        draft.userLoadError = null;
        draft.permissions = action.payload.permissions;
        draft.user = action.payload.user;
        break;
      case ActionTypes.FETCH_CURRENT_USER_FAIL:
        draft.userIsLoading = false;
        draft.userLoadError = action.payload;
        break;
      case ActionTypes.EDIT_USER_SUCCESS:
        // FIXME: v4-schema migration
        // @ts-ignore
        draft.user = action.payload.user;
        break;
      case ActionTypes.RESET_PASSWORD_SUCCESS:
        draft.resetSuccess = action.payload.success;
        draft.emailResetPassword = action.payload.email;
        break;
      case ActionTypes.RESET_CHANGE_PASSWORD_SUCCESS:
        draft.changePasswordSuccess = action.payload.success;
        break;
    }
  });
export default authReducer;
