import React from 'react';
import { Collapse } from 'antd';
import styled from 'styled-components';
import tw from 'twin.macro';
import { CollapsePanelProps } from 'antd/lib/collapse/CollapsePanel';
import { CollapseProps } from 'antd/lib/collapse';
import { IconSortDown } from '@aha/icons';
const { Panel } = Collapse;

const StyledIcon = styled(IconSortDown)<{ rotate: number }>`
  color: var(--white) !important;
  font-size: 10px !important;
  transform: ${(props) =>
    `translateY(-50%) rotate(${props.rotate}deg)`} !important;
`;

const StyledCollapse = styled(Collapse)`
  background: var(--grey-lighter) !important;

  .ant-collapse-arrow {
    left: 24px !important;
  }

  .ant-collapse-header {
    ${tw`bg-secondary text-white h-14 flex items-center`}
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
    padding-left: 24px !important;
  }

  .ant-collapse-item {
    ${tw`bg-grey-lightest mt-4 rounded-sm`}
    border-width: 0 !important;
  }

  .ant-collapse-content-box {
    ${tw`mt-0`}
    padding: 0 !important;
  }
  .ant-collapse-content {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
`;

export type RoomTypeGroupCollapseProps = CollapseProps & { className?: string };

export const RoomTypeGroupCollapse: React.FC<RoomTypeGroupCollapseProps> & {
  Panel: React.FunctionComponent<RoomTypeGroupPanel>;
} = ({ className, children, ...rest }) => (
  <StyledCollapse
    className={className}
    bordered={false}
    expandIcon={({ isActive }) => <StyledIcon rotate={isActive ? 0 : -90} />}
    {...rest}
  >
    {children}
  </StyledCollapse>
);

export type RoomTypeGroupPanel = Omit<CollapsePanelProps, 'header'> & {
  className?: string;
  title?: string;
};

export const RoomTypeGroupPanel: React.FC<RoomTypeGroupPanel> = ({
  className,
  title,
  children,
  ...rest
}) => (
  <Panel
    {...rest}
    className={className}
    header={
      <div className="ml-6 font-medium text-white test-base">{title}</div>
    }
  >
    {children}
  </Panel>
);

RoomTypeGroupCollapse.Panel = RoomTypeGroupPanel;

export default RoomTypeGroupCollapse;
