import { put, takeLatest, call } from 'redux-saga/effects';
import { fetchColorsSuccess } from '../actions';
import ActionTypes from '../constants';
import { coreAPI } from 'utils/request';
import { showErrorNotification } from '@aha/utils';
import { DashboardActions } from '../type';
import { ExtractAction } from 'types/actions';

export function* doFetchColors(
  action: ExtractAction<DashboardActions, ActionTypes.FETCH_COLORS>,
) {
  try {
    const { data } = yield call(coreAPI.get, 'v1/pms/operation/colors');
    yield put(fetchColorsSuccess(data));
  } catch (err) {
    showErrorNotification('Fail to fetch', err);
  }
}

export default function* watchFetchColors() {
  yield takeLatest(ActionTypes.FETCH_COLORS, doFetchColors);
}
