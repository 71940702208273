import React from 'react';
import { Skeleton } from '@uxui/skeleton-loader';
import RoomBlockSkeletonLoader from '../RoomBlockSkeletonLoader';

type AvaillableRoomsModalSkeletonLoaderProps = {
  hideRoomTypes?: boolean;
};

export default function AvaillableRoomsModalSkeletonLoader({
  hideRoomTypes = false,
}: AvaillableRoomsModalSkeletonLoaderProps) {
  return (
    <>
      {!hideRoomTypes && (
        <div className="mb-4">
          <div>
            <Skeleton height="14px" width="100px" />
          </div>
          <Skeleton width="250px" height="36px" />
        </div>
      )}

      <div className="flex flex-wrap -mx-2 -mt-2">
        {new Array(10).fill(1).map((_, index) => (
          <div
            key={index}
            style={{ opacity: 1 - index * 0.06 }}
            className="col p-2 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5"
          >
            <RoomBlockSkeletonLoader />
          </div>
        ))}
      </div>
    </>
  );
}
