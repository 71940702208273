import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { makeSelectCountries } from './selectors';
import { Dispatch } from 'redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountries } from './actions';
import { useEffect } from 'react';
import reducer from './reducer';
import saga from './saga';
import { useInjectSaga } from 'utils/injectSaga';
import { CountryDetail } from 'types/v3-schema';

export type Country = {
  code?: string;
  name?: string;
};

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    countries: CountryDetail[] | null;
  }
>({
  countries: makeSelectCountries(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doFetchCountries: () => dispatch(fetchCountries()),
});

let callOnce = false;
export default function useSelectCountries() {
  useInjectReducer({ key: 'createBooking', reducer });
  useInjectSaga({ key: 'createBooking', saga });

  const dispatch = useDispatch();
  const { doFetchCountries } = mapDispatchToProps(dispatch);
  const { countries } = useSelector(mapStateToProps);

  useEffect(() => {
    if (!countries && !callOnce) {
      doFetchCountries();
    }
    callOnce = true;
  }, []); // eslint-disable-line

  return useSelector(mapStateToProps);
}
