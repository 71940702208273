import { takeLatest, all, call } from 'redux-saga/effects';
import ActionTypes from '../constants';
import { removeToken, removeSelectedHotel } from 'utils/request';

export function* doLogout() {
  try {
    yield all([call(removeSelectedHotel), call(removeToken)]);
  } catch (err) {
    console.log(err);
  }
}

export default function* watchLogout() {
  yield takeLatest(ActionTypes.LOG_OUT, doLogout);
}
