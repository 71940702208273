import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';

import {
  makeSelectCurrentHotel,
  makeSelectCurrencies,
} from 'containers/Dashboard/selectors';
import { makeSelectRevGroups } from 'containers/BookingProvider/selector';
import { fetchRevGroups } from 'containers/BookingProvider/actions';
import { useInjectReducer } from 'utils/injectReducer';
import { BOOKING_KEY } from 'containers/BookingProvider/constants';
import bookingsReducer from 'containers/BookingProvider/reducer';
import bookingsSaga from 'containers/BookingProvider/saga';
import { useInjectSaga } from 'utils/injectSaga';
import { addHourlyBookingDiscount } from '../../actions';
import { Modal, Select } from '@aha/ui';
import { Form, Input } from 'antd';
import InputNumberFormat from 'components/InputNumberFormat';

export const InnerModal = ({
  balance,
  currencies,
  revenueGroups,
  getFieldDecorator,
}) => (
  <Form layout="vertical">
    <div className="flex flex-wrap -mx-2">
      <div className="px-2 w-1/2">
        <Form.Item
          label={
            <FormattedMessage
              defaultMessage="Discount amount"
              id="common.label.discountAmount"
            />
          }
        >
          {getFieldDecorator('amount', {
            rules: [
              {
                required: true,
                validator(rule, value, cb) {
                  if (isNaN(value)) {
                    return cb(
                      <FormattedMessage
                        defaultMessage="Invalid value"
                        id="common.label.invalidValue"
                      />,
                    );
                  }
                  if (Math.abs(+value) > Math.abs(balance)) {
                    return cb(
                      <FormattedMessage
                        id="common.message.maxValueIs"
                        defaultMessage="Maximum value is {max}"
                        values={{
                          max: new Intl.NumberFormat('en-US').format(
                            Math.abs(balance),
                          ),
                        }}
                      />,
                    );
                  }
                  return cb();
                },
              },
            ],
          })(<InputNumberFormat className="w-full" />)}
        </Form.Item>
      </div>
      <div className="px-2 w-1/2">
        <Form.Item
          label={
            <FormattedMessage
              defaultMessage="Currency"
              id="common.label.currency"
            />
          }
        >
          {getFieldDecorator('currencyId', {
            rules: [{ required: true }],
            initialValue: currencies[0].id,
          })(
            <Select className="w-full" showSearch>
              {currencies.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
      </div>
      <div className="px-2 w-full">
        <Form.Item
          label={
            <FormattedMessage
              defaultMessage="Revenue group"
              id="common.label.revenueGroup"
            />
          }
        >
          {getFieldDecorator('revenueGroup', { rules: [{ required: true }] })(
            <Select className="w-full" showSearch>
              {revenueGroups.map((revg) => (
                <Select.OptGroup key={revg.id} label={revg.name}>
                  {(revg.services || []).map((revc) => (
                    <Select.Option
                      key={revc.id}
                      value={`${revg.id};;;${revc.id}`}
                      title={`${revg.name} - ${revc.name}`}
                    >
                      {revc.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>,
          )}
        </Form.Item>
      </div>
      <div className="px-2 w-full">
        <Form.Item
          label={
            <FormattedMessage defaultMessage="Notes" id="common.label.notes" />
          }
        >
          {getFieldDecorator('notes', { rules: [{ required: false }] })(
            <Input />,
          )}
        </Form.Item>
      </div>
    </div>
  </Form>
);
const mapStateToProps = createStructuredSelector({
  hotel: makeSelectCurrentHotel(),
  currencies: makeSelectCurrencies(),
  revenueGroups: makeSelectRevGroups(),
});

function mapDispatchToProps(dispatch) {
  return {
    doFetchRevenueGroups: (hid) => dispatch(fetchRevGroups(hid)),
    doAddDiscount: (bid, reqData) =>
      new Promise((resolve, reject) =>
        dispatch(addHourlyBookingDiscount(bid, reqData, resolve, reject)),
      ),
  };
}
function AddDiscountModal(props) {
  useInjectReducer({ key: BOOKING_KEY, reducer: bookingsReducer });
  useInjectSaga({ key: BOOKING_KEY, saga: bookingsSaga });
  const dispatch = useDispatch();
  const { hotel, revenueGroups, currencies } = useSelector(mapStateToProps);
  const { doFetchRevenueGroups, doAddDiscount } = mapDispatchToProps(dispatch);

  const {
    bid,
    visible,
    onOk,
    onCancel,
    balance,
    form: { validateFields, getFieldDecorator },
    ...rest
  } = props;

  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    if (visible) {
      doFetchRevenueGroups(hotel.id);
    }
  }, [hotel.id, visible]); // eslint-disable-line

  async function onAddCharge() {
    try {
      const values = await validateFields();
      setIsCreating(true);
      const { revenueGroup } = values;
      const reqForm = {
        ...values,
        serviceGroupId: revenueGroup?.split(';;;')[0],
        serviceId: revenueGroup?.split(';;;')[1],
      };
      await doAddDiscount(bid, reqForm);
      onCancel(true);
    } catch (err) {
      console.error(err);
    } finally {
      setIsCreating(false);
    }
  }

  return (
    <Modal
      {...rest}
      visible={visible}
      title={
        <FormattedMessage
          defaultMessage="Add discount"
          id="bookings.actions.addDiscount"
        />
      }
      width={535}
      onCancel={() => onCancel()}
      onSubmit={onAddCharge}
      submitting={isCreating}
      submitText={
        <FormattedMessage defaultMessage="Add" id="common.action.add" />
      }
    >
      <InnerModal
        balance={balance}
        revenueGroups={revenueGroups}
        currencies={currencies}
        getFieldDecorator={getFieldDecorator}
      />
    </Modal>
  );
}

export default Form.create({ name: 'add_discount_modal' })(AddDiscountModal);
