import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { ApplicationRootState } from 'types/app';

const selectRoomTypes = (state: ApplicationRootState) =>
  state.roomTypeSettings || initialState;

const makeSelectRoomTypes = () =>
  createSelector(selectRoomTypes, (roomTypeSettings) => roomTypeSettings);

const makeSelectRoomTypeList = () =>
  createSelector(
    selectRoomTypes,
    (roomTypeSettings) => roomTypeSettings.roomTypes,
  );

const makeSelectRoomTypesLoading = () =>
  createSelector(
    selectRoomTypes,
    (roomTypeSettings) => roomTypeSettings.loading,
  );

const makeSelectRoomTypesError = () =>
  createSelector(selectRoomTypes, (roomTypeSettings) => roomTypeSettings.error);

const makeSelectSyncLoading = () =>
  createSelector(
    selectRoomTypes,
    (roomTypeSettings) => roomTypeSettings.syncPmsLoading,
  );

export {
  selectRoomTypes,
  makeSelectRoomTypes,
  makeSelectRoomTypeList,
  makeSelectRoomTypesLoading,
  makeSelectRoomTypesError,
  makeSelectSyncLoading,
};
