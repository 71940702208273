import moment from 'moment';
import { takeLatest, put, call } from 'redux-saga/effects';

import {
  fetchAvailableRoomsFail,
  fetchAvailableRoomsSuccess,
} from '../actions';
import { coreAPI } from 'utils/request';
import { FETCH_AVAILABLE_ROOMS } from '../constants';
import { showErrorNotification } from '@aha/utils';
import NProgress from 'nprogress';

function* doFetchRooms({ hid, start, end, roomTypeID }) {
  yield call(NProgress.start);
  try {
    let url = `v1/pms/rooms/availability?start_date=${moment(
      start,
    ).toISOString()}&end_date=${moment(end).toISOString()}`;

    if (roomTypeID !== 'all') {
      url += `&room_type_id=${roomTypeID}`;
    }

    const { data } = yield call(coreAPI.get, url);
    yield put(fetchAvailableRoomsSuccess(data, roomTypeID));
  } catch (err) {
    showErrorNotification('Fetch available rooms failed', err);
    yield put(fetchAvailableRoomsFail(err));
  }
  yield call(NProgress.done);
}

export function* watchFetchAvailableRooms() {
  yield takeLatest(FETCH_AVAILABLE_ROOMS, doFetchRooms);
}
