import React from 'react';
import { navigate } from '@reach/router';
import ROUTES from 'constants/routes';
import PermissionTooltipWrapper from 'components/permission/PermissionTooltipWrapper';
import { FormattedMessage } from 'react-intl';
import { Button } from '@aha/ui';
import { IconPlus } from '@aha/icons';

interface CreateBookingButtonProps {
  isOnMobile: boolean;
}

const CreateBookingButton = ({ isOnMobile }: CreateBookingButtonProps) => {
  if (isOnMobile) {
    return (
      <PermissionTooltipWrapper allow="bookings.create">
        <Button
          icon={IconPlus}
          className="ml-4 mr-1"
          onClick={() => navigate(ROUTES.CREATE_BOOKING)}
        />
      </PermissionTooltipWrapper>
    );
  }

  return (
    <PermissionTooltipWrapper allow="bookings.create">
      <Button
        icon={IconPlus}
        className="ml-4 mr-1"
        onClick={() => navigate(ROUTES.CREATE_BOOKING)}
      >
        <FormattedMessage
          defaultMessage="Create Booking"
          id="common.label.create_booking"
        />
      </Button>
    </PermissionTooltipWrapper>
  );
};

export default CreateBookingButton;
