import * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { IconCheck } from '@aha/icons';

interface StepItem {
  name: string;
  id: string;
}

export interface StepsProps {
  items: StepItem[];
  current: string;
  completed: boolean;
}

interface StepProps {
  isCurrent: boolean;
  isDone: boolean;
  text: string;
}

const StepContainer = styled.div<{ isDone: boolean; isCurrent: boolean }>`
  ${tw`flex items-center relative text-sm`}
  white-space: nowrap;
  color: ${(props) =>
    props.isDone || props.isCurrent ? 'var(--primary)' : 'var(--grey-darker)'};
  font-weight: ${(props) => (props.isDone ? '500' : '400')};
  &:not(:first-child) {
    padding-top: 30px;
    &:after {
      position: absolute;
      content: '';
      width: 1px;
      height: 28px;
      top: 1px;
      left: 6px;
      background-color: ${(props) =>
        props.isCurrent || props.isDone
          ? 'var(--primary)'
          : 'var(--grey-light)'};
    }
  }
`;

const StepCircle = styled.span<{ isDone: boolean; isCurrent: boolean }>`
  ${tw`flex items-center justify-center rounded-full`};
  width: 14px;
  height: 14px;
  border: 1px solid
    ${(props) =>
      props.isDone || props.isCurrent ? 'var(--primary)' : 'var(--grey-light)'};
  margin-right: 10px;
  background-color: ${(props) =>
    props.isDone ? 'var(--primary)' : 'var(--white)'};
  font-size: 10px;
  svg {
    display: ${(props) => (props.isDone ? 'block' : 'none')};
    color: var(--white);
  }
`;

const Step = ({ isDone, isCurrent, text }: StepProps) => {
  return (
    <StepContainer isDone={isDone} isCurrent={isCurrent}>
      <StepCircle isDone={isDone} isCurrent={isCurrent}>
        <IconCheck />
      </StepCircle>
      {text}
    </StepContainer>
  );
};

const Steps = ({ current, items, completed }: StepsProps) => {
  const currentIndex = items.findIndex(({ id }) => id === current);
  return (
    <div>
      {items.map((item, index) => (
        <Step
          key={item.id}
          isDone={currentIndex > index || completed}
          isCurrent={currentIndex === index}
          text={item.name}
        ></Step>
      ))}
    </div>
  );
};

export default React.memo(Steps);
