import produce from 'immer';
import ActionTypes from './constants';
import { RatePlansActions, RatePlansState } from './types';
import initialStateHolder from 'utils/initialStateHolder';

export const initialState = initialStateHolder.setState('ratePlans', {
  rates: [],
  loading: false,
  error: null,
  editHistory: [],
  pushHistory: [],
});

/* eslint-disable default-case, no-param-reassign */
export default function ratePlanReducer(
  state: RatePlansState = initialState,
  action: RatePlansActions,
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.FETCH_RATE_PLANS:
        draft.loading = true;
        draft.error = null;
        break;
      case ActionTypes.FETCH_RATE_PLANS_FAIL:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case ActionTypes.FETCH_RATE_PLANS_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.rates = action.payload;
        break;

      case ActionTypes.FETCH_EDIT_HISTORY:
        draft.loading = true;
        draft.error = null;
        break;
      case ActionTypes.FETCH_EDIT_HISTORY_SUCCESS:
        draft.loading = false;
        draft.editHistory = action.payload;
        break;
      case ActionTypes.FETCH_EDIT_HISTORY_FAIL:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case ActionTypes.FETCH_PUSH_HISTORY:
        draft.loading = true;
        draft.error = null;
        break;
      case ActionTypes.FETCH_PUSH_HISTORY_SUCCESS:
        draft.loading = false;
        draft.pushHistory = action.payload;
        break;
      case ActionTypes.FETCH_PUSH_HISTORY_FAIL:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case ActionTypes.CREATE_PROMOTION_SUCCESS:
        if (!draft.rates) {
          return;
        }

        draft.rates.forEach((r) => {
          if (Array.isArray(r.ratePublishingConfig)) {
            r.ratePublishingConfig[0] = action.payload;
          } else {
            r.ratePublishingConfig = [action.payload];
          }
        });
        break;
    }
  });
}
