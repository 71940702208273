import React, { useState } from 'react';
import { Icon, Tooltip } from 'antd';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { FormattedMessage } from 'react-intl';
import tw from 'twin.macro';
import ROUTES from 'constants/routes';

const Container = styled.div`
  ${tw`min-h-screen flex items-center justify-center`}

  @media print {
    ${tw`items-start mt-20`};
  }
`;

const Layer = styled.div<{ media?: string }>`
  ${tw`bg-white p-6 text-black`};
  width: 1110px;
`;

const ButtonContainer = styled.div`
  ${tw`fixed flex flex-col`}
  right: 24px;
  bottom: 36px;
  z-index: 10;
  @media print {
    display: none;
  }
`;

const PrintButton = styled.button`
  ${tw`bg-secondary text-white rounded-full flex items-center justify-center hover:bg-white hover:border hover:border-secondary hover:text-secondary`}
  width: 48px;
  font-size: 18px;
  height: 48px;
  margin-bottom: 16px;
  outline: none !important;
`;

const PriceButton = styled.button<{ showPrice: boolean }>`
  ${tw`relative bg-yellow font-medium text-xl text-white rounded-full flex items-center justify-center hover:bg-white hover:border hover:border-yellow hover:text-yellow`}
  width: 48px;
  font-size: 18px;
  height: 48px;
  margin-bottom: 16px;
  outline: none !important;
  overflow: hidden;
  :after {
    ${tw`absolute bg-white`}
    content: '';
    width: 18px;
    height: 2px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    visibility: ${(props) => (!props.showPrice ? 'visible' : 'hidden')};
  }

  :hover :after {
    background: var(--yellow) !important;
  }
`;

const BackHomeButton = styled(Link)`
  ${tw`bg-primary text-white rounded-full flex items-center justify-center hover:bg-white hover:border hover:border-primary hover:text-primary`}
  width: 48px;
  height: 48px;
  font-size: 18px;
  outline: none !important;
`;

type PrintingType = 'reg-card' | 'invoice';

interface PrintingLayoutProps {
  onPrint?: () => void;
  type: PrintingType;
  children:
    | ((showPrice?: boolean) => JSX.Element)
    | JSX.Element
    | JSX.Element[];
}

export const PrintingLayout: React.FC<PrintingLayoutProps> = ({
  onPrint,
  type,
  children,
}) => {
  const [showPrice, setShowPrice] = useState(false);
  return (
    <Container>
      <Layer media="print">
        {type === 'reg-card'
          ? (children as (showPrice?: boolean) => JSX.Element)(showPrice)
          : children}
      </Layer>
      <ButtonContainer>
        {type === 'reg-card' && (
          <Tooltip
            title={
              !showPrice ? (
                <FormattedMessage
                  id="regcard.label.showPrice"
                  defaultMessage="Show price"
                />
              ) : (
                <FormattedMessage
                  id="regcard.label.hidePrice"
                  defaultMessage="Hide price"
                />
              )
            }
          >
            <PriceButton
              showPrice={showPrice}
              onClick={() => setShowPrice((p) => !p)}
            >
              $
            </PriceButton>
          </Tooltip>
        )}

        <PrintButton
          onClick={() => {
            window.print && window.print();
            onPrint && onPrint();
          }}
        >
          <Icon type="printer" />
        </PrintButton>
        <BackHomeButton to={ROUTES.DASHBOARD}>
          <Icon type="home" />
        </BackHomeButton>
      </ButtonContainer>
    </Container>
  );
};

export default PrintingLayout;
