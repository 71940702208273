import React, { memo } from 'react';
import { Skeleton } from '@uxui/skeleton-loader';
import styled from 'styled-components';

const Container = styled.div`
  font-size: 0;
`;

export interface SkeletonTextBlockProps {
  blocks?: string[];
}

const SkeletonTextBlock = memo(
  ({
    blocks = ['200px', '300px', '250px', '100px'],
  }: SkeletonTextBlockProps) => {
    return (
      <Container>
        {blocks.map((block, index) => (
          <Skeleton
            width={block}
            height="16px"
            key={index}
            className="mb-3 block"
          />
        ))}
      </Container>
    );
  },
  () => true,
);

export default SkeletonTextBlock;
