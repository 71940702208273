import { call, put, takeLatest } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import {
  updateRatePlanSuccess,
  updateRatePlanFail,
  updateClosedRatePlanSuccess,
  updateClosedRatePlanFail,
} from '../actions';

import ActionTypes from '../constants';
import { ExtractAction } from 'types/actions';
import { RatePlansActions } from '../types';

function* doUpdateRate(
  action: ExtractAction<RatePlansActions, ActionTypes.UPDATE_RATE_PLAN>,
) {
  const {
    payload: {
      data: { rateId, formData },
      resolve,
      reject,
    },
  } = action;

  try {
    const { data } = yield call(
      coreAPI.put,
      `v1/pms/operation/rate-plans/${rateId}`,
      formData,
    );
    yield put(updateRatePlanSuccess(data));
    resolve(data);
  } catch (err) {
    yield put(updateRatePlanFail(err));
    reject(err);
  }
}

function* doUpdateClosedRate(
  action: ExtractAction<RatePlansActions, ActionTypes.UPDATE_CLOSED_RATE_PLAN>,
) {
  const {
    payload: {
      data: { rateId, formData },
      resolve,
      reject,
    },
  } = action;

  try {
    let data = null;
    if (formData === true) {
      data = yield call(
        coreAPI.put,
        `v1/pms/operation/rate-plans/${rateId}/archive`,
      );
    } else {
      data = yield call(
        coreAPI.put,
        `v1/pms/operation/rate-plans/${rateId}/unarchive`,
      );
    }

    yield put(updateClosedRatePlanSuccess(data?.Data));
    resolve(data?.Data);
  } catch (err) {
    yield put(updateClosedRatePlanFail(err));
    reject(err);
  }
}

export default function* watchUpdateRate() {
  yield takeLatest(ActionTypes.UPDATE_RATE_PLAN, doUpdateRate);
  yield takeLatest(ActionTypes.UPDATE_CLOSED_RATE_PLAN, doUpdateClosedRate);
}
