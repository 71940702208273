export function currencyFormater(
  value: number = 0,
  currency: string = 'VND',
  maximumFractionDigits: number = 2,
) {
  try {
    return new Intl.NumberFormat('en-US', {
      currency,
      style: 'currency',
      maximumFractionDigits,
    }).format(value);
  } catch (err) {
    return new Intl.NumberFormat('en-US').format(value);
  }
}
