import React from 'react';
import { Card } from '@aha/ui';
import { Skeleton } from '@uxui/skeleton-loader';
import RoomBlockSkeletonLoader from '../RoomBlockSkeletonLoader';
import { RoomBlockContainer } from 'containers/RoomPlanPage';

export default function RoomPageSkeleton() {
  return (
    <Card
      title={<Skeleton width="71px" height="19px" className="block" />}
      noContentPadding
      extra={<Skeleton width="47px" height="19px" className="block" />}
    >
      <RoomBlockContainer>
        {Array.from({ length: 20 }, (k, i) => i).map((i) => (
          <div key={i} style={{ opacity: 1 - i * 0.04 }}>
            <RoomBlockSkeletonLoader />
          </div>
        ))}
      </RoomBlockContainer>
    </Card>
  );
}
