enum ActionTypes {
  CURRENCIES_KEY = 'currencies',
  FETCH_CURRENCIES = 'currencies/FETCH_CURRENCIES',
  FETCH_CURRENCIES_SUCCESS = 'currencies/FETCH_CURRENCIES_SUCCESS',
  FETCH_CURRENCIES_FAIL = 'currencies/FETCH_CURRENCIES_FAIL',
  RESET_CURRENCIES_STATE = 'currencies/RESET_CURRENCIES_STATE',
  DELETE_CURRENCY = 'currencies/DELETE_CURRENCY',
  DELETE_CURRENCY_SUCCESS = 'currencies/DELETE_CURRENCY_SUCCESS',
  DELETE_CURRENCY_FAIL = 'currencies/DELETE_CURRENCY_FAIL',
  CREATE_CURRENCY = 'currencies/CREATE_CURRENCY',
  CREATE_CURRENCY_SUCCESS = 'currencies/CREATE_CURRENCY_SUCCESS',
  CREATE_CURRENCY_FAIL = 'currencies/CREATE_CURRENCY_FAIL',
  EDIT_CURRENCY = 'currencies/EDIT_CURRENCY',
  EDIT_CURRENCY_SUCCESS = 'currencies/EDIT_CURRENCY_SUCCESS',
  EDIT_CURRENCY_FAIL = 'currencies/EDIT_CURRENCY_FAIL',
}

export default ActionTypes;
