import { put, call, takeLatest } from 'redux-saga/effects';
import { coreAPI } from 'utils/request';
import { fetchUserSuccess, logOut } from '../actions';
import ActionTypes from '../constants';
import { AuthMe } from 'types/schema';

export function* doFetchUser() {
  try {
    const res: { data: AuthMe } = yield call(coreAPI.get, '/v1/pms/auth/me');
    yield put(fetchUserSuccess(res.data, res.data.permissions));
  } catch (err) {
    yield put(logOut());
  }
}

export default function* watchFetchUser() {
  yield takeLatest(ActionTypes.FETCH_CURRENT_USER, doFetchUser);
}
