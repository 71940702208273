export function uniqBy(arr, predicate) {
  const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];
  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item);
        map.has(key) || map.set(key, item);
        return map;
      }, new Map())
      .values(),
  ];
}

export function groupBy(arr, groupByKeyFn) {
  return arr.reduce((acc, c) => {
    const key = groupByKeyFn(c);
    acc[key] = acc[key] || [];
    acc[key].push(c);
    return acc;
  }, {});
}

export const uniq = (arr) => [...new Set(arr)];
