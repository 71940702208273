import React, { useState, useRef } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Select } from '@aha/ui';
import useSelectCountries from 'containers/CreateBooking/useSelectCountries';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { addGuestToBooking } from 'containers/BookingProvider/actions';
import saga from '../saga';
import { useInjectSaga } from 'utils/injectSaga';
import GuestSelect, { GuestOption } from 'components/GuestSelect';
import {
  Guest,
  BookingRoomDetail,
  AddGuestToBookingRequestBody,
} from 'types/schema';

export type AddGuestModalProps = {
  isSearch?: boolean;
  room: BookingRoomDetail;
  visible?: boolean;
  bookingRefCode: string;
  existedGuess?: Array<string>;
  onClose?: (...args: any[]) => any;
} & FormComponentProps;

type Country = {
  code: string;
  name: string;
};

//TODO: bkRoomGuest should have a type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  doAddGuestToBooking: (refCode: string, bkRoomGuest: any) =>
    new Promise((resolve, reject) =>
      dispatch(addGuestToBooking(refCode, bkRoomGuest, resolve, reject)),
    ),
});

const AddGuestModal: React.SFC<AddGuestModalProps> = ({
  room,
  isSearch,
  visible,
  bookingRefCode,
  existedGuess,
  onClose,
  form,
}) => {
  useInjectSaga({ key: 'guestSettings', saga });

  const intl = useIntl();
  const dispatch = useDispatch();
  const { doAddGuestToBooking } = mapDispatchToProps(dispatch);
  const {
    getFieldDecorator,
    resetFields,
    validateFields,
    setFieldsValue,
  } = form;
  const [submitting, setSubmitting] = useState(false);
  const { countries } = useSelectCountries();
  const guestID = useRef<string | null | undefined>(null);

  function onCancel() {
    if (submitting) {
      return;
    }

    onClose && onClose();
    resetFields();
    guestID.current = null;
  }

  function onSelectGuest(fname: string, value: string, data: GuestOption) {
    if (data?.user) {
      const { createdAt, id, updatedAt, ...props } = data.user;

      // remove empty value
      const fields = Object.entries(props).reduce(
        (res, [name, value]) => (value ? { ...res, [name]: value } : res),
        {},
      );

      setFieldsValue(fields);
      guestID.current = id?.toString();
    } else if (!value) {
      resetFields();
      guestID.current = null;
    }
  }

  function onAdd() {
    validateFields(async (err, values: Guest) => {
      const { bookingId, id } = room || {};

      if (err) {
        console.log('Received values of form: ', values);
        return;
      } else if (!bookingId || !id) {
        console.error('Booking room not found!');
        return;
      }

      try {
        setSubmitting(true);
        const { firstName, lastName } = values;
        const fullName = [firstName, lastName].filter(Boolean).join(' ');

        const body: AddGuestToBookingRequestBody = {
          bookingRoomId: id,
          guest: { ...values, fullName },
        };

        if (isSearch && guestID.current) {
          body.guestId = Number(guestID.current);
        }

        await doAddGuestToBooking(bookingRefCode, body);

        onClose && onClose(true);
        resetFields();
        guestID.current = null;
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    });
  }

  return (
    <Modal
      visible={visible}
      title={
        isSearch && guestID.current ? (
          <FormattedMessage
            defaultMessage="Add a guest to the booking"
            id="bookings.label.addGuestToBooking"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Add new guest"
            id="settings.label.addNewGuest"
          />
        )
      }
      submitText={
        isSearch && guestID.current ? (
          <FormattedMessage defaultMessage="Save" id="common.action.save" />
        ) : (
          <FormattedMessage defaultMessage="Create" id="common.action.create" />
        )
      }
      cancelText={
        <FormattedMessage defaultMessage="Cancel" id="common.action.cancel" />
      }
      submitting={submitting}
      onSubmit={onAdd}
      onCancel={onCancel}
      width={942}
    >
      <Form layout="vertical">
        <div className="mb-4">
          <Form.Item>
            {getFieldDecorator('fullName')(
              <GuestSelect
                onResponse={onSelectGuest}
                existedGuess={existedGuess}
                searchBy="firstName"
                placeholder={intl.formatMessage({
                  id: 'common.label.enterGuestName',
                  defaultMessage: 'Enter name',
                })}
              />,
            )}
          </Form.Item>
        </div>
        <div className="flex flex-wrap -mx-2">
          <div className="col px-2 w-full md:w-1/4">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Passport/ID"
                  id="settings.label.password_id"
                />
              }
            >
              {getFieldDecorator('passport')(
                <Input
                  disabled={!!guestID.current}
                  allowClear
                  placeholder="Enter passport/id"
                />,
              )}
            </Form.Item>
          </div>
          <div className="col px-2 w-full md:w-1/4">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Title"
                  id="settings.label.title"
                />
              }
            >
              {getFieldDecorator('title')(
                <Select disabled={!!guestID.current} placeholder="Select title">
                  {[
                    { key: 'mr', label: 'Mr' },
                    { key: 'mrs', label: 'Mrs' },
                    { key: 'ms', label: 'Ms' },
                  ].map((c) => (
                    <Select.Option key={c.key} value={c.key}>
                      {c.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </div>
          <div className="col px-2 w-full md:w-1/4">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="First name"
                  id="settings.label.first_name"
                />
              }
            >
              {getFieldDecorator('firstName', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
              })(
                <Input
                  disabled={!!guestID.current}
                  allowClear
                  placeholder="Enter first name"
                />,
              )}
            </Form.Item>
          </div>
          <div className="col px-2 w-full md:w-1/4">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Last name"
                  id="settings.label.last_name"
                />
              }
            >
              {getFieldDecorator('lastName')(
                <Input
                  disabled={!!guestID.current}
                  allowClear
                  placeholder="Enter last name"
                />,
              )}
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-wrap -mx-2">
          <div className="col px-2 w-full md:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Address"
                  id="settings.label.address"
                />
              }
            >
              {getFieldDecorator('address')(
                <Input
                  disabled={!!guestID.current}
                  allowClear
                  placeholder="Enter address"
                />,
              )}
            </Form.Item>
          </div>
          <div className="col px-2 w-full md:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Phone Number"
                  id="settings.label.phone"
                />
              }
            >
              {getFieldDecorator('phone')(
                <Input
                  disabled={!!guestID.current}
                  allowClear
                  placeholder="Enter phone"
                />,
              )}
            </Form.Item>
          </div>
          <div className="col px-2 w-full md:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Email"
                  id="settings.label.email"
                />
              }
            >
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: (
                      <FormattedMessage
                        id="common.message.invalid"
                        defaultMessage="Invalid"
                      />
                    ),
                  },
                ],
              })(
                <Input
                  disabled={!!guestID.current}
                  allowClear
                  placeholder="Enter email"
                />,
              )}
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-wrap -mx-2">
          <div className="col px-2 w-full md:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Country"
                  id="settings.label.country"
                />
              }
            >
              {getFieldDecorator('countryCode')(
                <Select
                  disabled={!!guestID.current || !countries}
                  placeholder="Select country"
                >
                  {((countries as Country[]) || []).map((c) => (
                    <Select.Option key={c.code} value={c.code}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </div>
          <div className="col px-2 w-full md:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="City"
                  id="settings.label.city"
                />
              }
            >
              {getFieldDecorator('city')(
                <Input
                  disabled={!!guestID.current}
                  allowClear
                  placeholder="Enter city"
                />,
              )}
            </Form.Item>
          </div>
          <div className="col px-2 w-full md:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="State"
                  id="settings.label.state"
                />
              }
            >
              {getFieldDecorator('state')(
                <Input
                  disabled={!!guestID.current}
                  allowClear
                  placeholder="Enter state"
                />,
              )}
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default Form.create<AddGuestModalProps>({
  name: 'add_guest_to_bk_form',
})(AddGuestModal);
