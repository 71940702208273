import React, { memo } from 'react';
import { Card } from '@aha/ui';
import { Skeleton } from '@uxui/skeleton-loader';

const DashboardHomeSkeleton = memo(({ isMobile }: { isMobile?: boolean }) => (
  <>
    <div className="flex flex-wrap -mx-2">
      <div className="w-full px-2 lg:w-1/2" style={{ minHeight: 404 }}>
        <Card
          className="boxed h-full"
          title={<Skeleton width="100px" height="19px" className="block" />}
          noContentPadding
          hasBorder
        >
          <div className="p-6">
            <Skeleton width="30%" height="16px" className="block" />
            <Skeleton width="50%" height="16px" className="block mt-3" />
            <Skeleton width="40%" height="16px" className="block mt-3" />
            <Skeleton width="35%" height="16px" className="block mt-3" />
          </div>
        </Card>
      </div>
      <div className="w-full px-2 lg:w-1/2 mt-4 lg:mt-0">
        <Card
          className="boxed h-full"
          title={<Skeleton width="100px" height="19px" className="block" />}
          contentClassName="h-full"
          noContentPadding
          headerClassName="px-8"
          hasBorder
        >
          <div
            className="p-8 border-b"
            style={{
              height: !isMobile
                ? 'calc(50% - 56px)'
                : 'calc((100% - 56px) / 2)',
            }}
          >
            <Skeleton width="20%" height="16px" className="block" />
            <Skeleton width="40%" height="16px" className="block mt-3" />
            <Skeleton width="30%" height="16px" className="block mt-3" />
          </div>
          <div
            className="p-8"
            style={{
              height: !isMobile ? '50%' : 'calc((100% - 56px) / 2)',
            }}
          >
            <Skeleton width="20%" height="16px" className="block" />
            <Skeleton width="40%" height="16px" className="block mt-3" />
            <Skeleton width="30%" height="16px" className="block mt-3" />
          </div>
        </Card>
      </div>
    </div>
    <Card
      className="mt-4"
      noContentPadding
      title={<Skeleton width="100px" height="19px" className="block" />}
    >
      <div className="flex flex-wrap -mx-2 mb-4">
        {[0, 1].map((value, index) => {
          return (
            <div key={index} className="w-full px-2 sm:w-1/2 xl:w-1/4">
              <div className="flex items-center mx-6 my-4">
                <Skeleton
                  width="56px"
                  height="56px"
                  className="rounded-full mr-3"
                />
                <div className="flex-1">
                  <Skeleton width="50px" height="16px" className="block" />
                  <Skeleton
                    width="120px"
                    height="16px"
                    className="block mt-3"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Card>
  </>
));

export default DashboardHomeSkeleton;
