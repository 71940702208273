import { createGlobalStyle } from 'styled-components';

export const ButtonGlobalStyle = createGlobalStyle`
  .btn {
    color: var(--white);
    font-size: 14px;
    padding: 0 18px;
    border: 1px solid transparent;
    border-radius: 2px;
    font-weight: 500;
    height: 32px;
    line-height: 1em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: 0 !important;
    transition: 0.15s ease;
    white-space: nowrap;
    text-transform: capitalize;

    &:focus,
    &:active {
      opacity: 0.9;
    }

    &:disabled,
    &.btn--loading {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  .btn--primary {
    background-color: var(--primary);
    border-color: var(--primary);

    &:hover {
      background-color: transparent;
      color: var(--primary);
    }

    &.btn--line {
      color: var(--primary);
      &:hover {
        background-color: var(--primary);
        color: var(--white);
      }
    }
  }

  .btn--secondary {
    background-color: var(--secondary);
    border-color: var(--secondary);

    &:hover {
      background-color: transparent;
      color: var(--secondary);
    }
    &.btn--line {
      color: var(--secondary);
      &:hover {
        background-color: var(--secondary);
        color: var(--white);
      }
    }
  }

  .btn--yellow {
    background-color: var(--yellow);
    border-color: var(--yellow);

    &:hover {
      background-color: transparent;
      color: var(--yellow);
    }

    &.btn--line {
      color: var(--yellow);
      &:hover {
        background-color: var(--yellow);
        color: var(--white);
      }
    }
  }

  .btn--red {
    background-color: var(--red);
    border-color: var(--red);

    &:hover {
      background-color: transparent;
      color: var(--red);
    }

    &.btn--line {
      color: var(--red);
      &:hover {
        background-color: var(--red);
        color: var(--white);
      }
    }
  }

  .btn--black {
    background-color: var(--grey-darkest);
    border-color: var(--grey-darkest);

    &:hover {
      background-color: transparent;
      color: var(--grey-darkest);
    }

    &.btn--line {
      color: var(--grey-darkest);
      &:hover {
        background-color: var(--grey-darkest);
        color: var(--white);
      }
    }
  }

  .btn--grey {
    background-color: var(--grey-dark);
    border-color: var(--grey-dark);
    color: var(--black);

    &:hover {
      background-color: transparent;
      color: var(--grey-darkest);
    }

    &.btn--line {
      color: var(--grey-dark);
      &:hover {
        background-color: var(--grey-dark);
        color: var(--black);
      }
    }
  }

  .btn--large {
    height: 36px;
    font-size: 16px;
    padding: 0 20px;
    min-width: 96px;
  }

  .btn--medium {
    min-width: 88px;
  }

  .btn--small {
    height: 24px;
    font-size: 12px;
    min-width: 64px;
    padding: 0 12px;
  }

  .btn--line {
    background-color: transparent;
  }

  .btn--block {
    display: flex;
    width: 100%;
  }

  .btn__icon {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
  }

  .btn__spinner {
    width: 10px;
    height: 10px;
    display: inline-block;
    border: 2px solid var(--white);
    border-top-color: transparent !important;
    box-sizing: border-box;
    border-radius: 9999px;
    margin-top: -1px;

    ~ span {
      margin-right: 3px;
      margin-left: 5px;
    }

    .btn--line.btn--primary & {
      border-color: var(--primary);
    }
    .btn--line.btn--secondary & {
      border-color: var(--secondary);
    }
    .btn--line.btn--yellow & {
      border-color: var(--yellow);
    }
    .btn--line.btn--red & {
      border-color: var(--red);
    }
    animation: spin 1500ms linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .btn--icon {
    min-width: auto;
    font-size: 18px;
    background-color: transparent;
    border: none;
    color: var(--secondary);
    padding: 0;

    &:disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  .btn--line:disabled,
  .btn--normal:disabled {
    opacity: 1;
    // background-color: var(--grey-lightest);
    // border-color: var(--grey-lightest);
    // color: var(--grey);
    opacity: 0.5;
    pointer-events: none;
  }
`;
