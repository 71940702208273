import { all } from 'redux-saga/effects';
import watchFetchRates from './sagas/fetch-rates';
import watchUpdateRate from './sagas/update-rate';
import watchCreateRate from './sagas/create-rate';
import watchUpdateRateRestriction from './sagas/update-rate-restriction';
import watchFetchPushHistory from './sagas/fetch-push-history';
import watchFetchEditHistory from './sagas/fetch-edit-history';
import watchPromotion from './sagas/promotion';
import { watchSyncStahh, watchSyncAHA } from './sagas/sync-restrictions';

export default function* watchAll() {
  yield all([
    watchFetchRates(),
    watchUpdateRate(),
    watchCreateRate(),
    watchUpdateRateRestriction(),
    watchFetchPushHistory(),
    watchFetchEditHistory(),
    watchSyncStahh(),
    watchSyncAHA(),
    watchPromotion(),
  ]);
}
