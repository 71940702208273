import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  .confirmation-popup {
    .ant-popover-inner-content {
      .ant-popover-message {
        display:flex;
        align-items: center;
        .ant-popover-message-title {
          padding-left: 8px!important;
        }
      }
      .ant-popover-buttons {
        display:flex;
        align-items: center;
        justify-content: flex-end;
        .ant-btn-primary.ant-btn-sm{
          height: 24px!important;
          box-shadow: none!important;
          font-size: 12px!important;
          color: var(--secondary)!important;
          background-color: white!important;
          font-weight: normal;
          width: 70px!important;
          text-shadow: none!important;
        }
        .ant-btn-sm {
          border: none;
          font-weight: 400!important;
          background-color: #235833!important;
          color: white!important;
          min-width: 60px;
          font-size: 12px!important;
        }
      }
    }
  }
`;
