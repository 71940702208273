import { combineReducers } from 'redux';
import dashboardReducer from 'containers/Dashboard/reducer';
import authReducer from 'containers/Auth/reducer';
import hotelReducer from 'containers/HotelsPage/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import ActionTypes from 'containers/Auth/constants';
import DashboardActionTypes from 'containers/Dashboard/constants';
import initialStateHolder from 'utils/initialStateHolder';
import { mockReducers } from 'utils/test/mockReducers';
import { ApplicationRootState, ApplicationRootAction } from 'types/app';

// we use useReducer to dynamically inject a reducer in a container
// however, it will give a warning of undefined reducers when doing test
// this code aims to solve the problem by mocking all the reducers in a test env
const initialInjectedReducers =
  process.env.NODE_ENV === 'test' ? mockReducers : {};

export default function createReducer(
  injectedReducers = initialInjectedReducers,
) {
  const appReducer = combineReducers<ApplicationRootState>({
    dashboard: dashboardReducer,
    auth: authReducer,
    language: languageProviderReducer,
    hotels: hotelReducer,
    /* istanbul ignore next */
    ...injectedReducers,
  });

  const rootReducer = (
    state: ApplicationRootState | undefined,
    action: ApplicationRootAction,
  ) => {
    if (action.type === ActionTypes.LOG_OUT) {
      state = { language: state?.language || 'vi' };
    }

    if (action.type === DashboardActionTypes.SWITCH_CURRENT_HOTEL_SUCCESS) {
      const initialState: Partial<ApplicationRootState> = initialStateHolder.getInstance();

      const keys = Object.keys(initialState) as Array<
        keyof ApplicationRootState
      >;

      keys.forEach((key) => {
        if (!state || state[key]?.global) {
          return;
        }

        if (key === 'bookings') {
          const { expiredPagination } = state[key] || {};
          state[key] = { ...initialState[key], expiredPagination };
          return;
        }

        state[key] = initialState[key];
      });
    }

    return appReducer(state, action);
  };

  return rootReducer;
}
