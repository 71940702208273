import { call, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

import { coreAPI } from 'utils/request';
import {} from '../actions';
import ActionTypes from '../constants';

import { showSuccessNotification, showErrorNotification } from '@aha/utils';
import NProgress from 'nprogress';
import { ExtractAction } from 'types/actions';
import { RatePlansActions } from '../types';

function* syncStahh(
  action: ExtractAction<
    RatePlansActions,
    ActionTypes.SYNC_RATE_PLANS_WITH_STAHH
  >,
) {
  try {
    const {
      payload: { start, end },
    } = action;

    yield call(
      coreAPI.post,
      `v1/pms/operation/cms/push-restrictions?fromDate=${moment(
        start,
      ).toISOString()}&toDate=${moment(end).toISOString()}`,
      null,
      { timeout: 3000000 }, // this api requires long run, we must wait for the response
    );
    showSuccessNotification('Pushed');
  } catch (err) {
    showErrorNotification('Push to CMS failed', err);
  }
}

export function* watchSyncStahh() {
  yield takeLatest(ActionTypes.SYNC_RATE_PLANS_WITH_STAHH, syncStahh);
}

function* syncAHA(
  action: ExtractAction<RatePlansActions, ActionTypes.SYNC_RATE_PLANS_WITH_AHA>,
) {
  yield call(NProgress.start);
  try {
    const {
      payload: { hid, start, end },
    } = action;

    yield call(
      coreAPI.get,
      `/extranet/sync-restrictions/${hid}?fromDate=${moment(
        start,
      ).toISOString()}&toDate=${moment(end).toISOString()}`,
    );
    showSuccessNotification('Pushed');
  } catch (err) {
    showErrorNotification('Push to AHA failed', err);
  }
  yield call(NProgress.done);
}

export function* watchSyncAHA() {
  yield takeLatest(ActionTypes.SYNC_RATE_PLANS_WITH_AHA, syncAHA);
}
