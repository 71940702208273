import { all } from 'redux-saga/effects';
import watchFetchCurrency from './sagas/watchFetchCurrency';
import watchDeleteCurrency from './sagas/watchDeleteCurrency';
import watchCreateCurrency from './sagas/watchCreateCurrency';
import watchEditCurrency from './sagas/watchEditCurrency';

export default function* watchAll() {
  yield all([
    watchCreateCurrency(),
    watchFetchCurrency(),
    watchDeleteCurrency(),
    watchEditCurrency(),
  ]);
}
