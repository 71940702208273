export default function currencyFormater(
  value: number = 0,
  currency: string = 'VND',
) {
  try {
    return new Intl.NumberFormat('en-US', {
      currency,
      style: 'currency',
      maximumFractionDigits: 2,
    }).format(value);
  } catch (err) {
    // console.error(err);
    return new Intl.NumberFormat('en-US').format(value);
  }
}
