import produce from 'immer';
import {
  FETCH_HOURLY_BOOKING_SUCCSESS,
  FETCH_HOURLY_BOOKING_FAIL,
  FETCH_HOURLY_BOOKING,
  HOURLY_BOOKING_KEY,
  FETCH_HOURLY_BOOKING_CHANGE_LOGS,
  FETCH_HOURLY_BOOKING_CHANGE_LOGS_FAIL,
  FETCH_HOURLY_BOOKING_CHANGE_LOGS_SUCCSESS,
  REMOVE_BOOKING_DETAIL,
} from './constants';
import initialStateHolder from 'utils/initialStateHolder';

export const initialState = initialStateHolder.setState(HOURLY_BOOKING_KEY, {
  loading: true,
  error: null,
  booking: {},
  changeLogs: [],
  loadingChangeLogs: false,
});

/* eslint-disable default-case, no-param-reassign */
export default function hourlyBookingReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case REMOVE_BOOKING_DETAIL:
        draft.booking = {};
        break;
      case FETCH_HOURLY_BOOKING:
        draft.error = null;
        draft.loading = true;
        break;
      case FETCH_HOURLY_BOOKING_FAIL:
        draft.error = action.error;
        draft.loading = false;
        break;
      case FETCH_HOURLY_BOOKING_SUCCSESS:
        draft.booking = action.booking;
        draft.error = null;
        draft.loading = false;
        break;

      case FETCH_HOURLY_BOOKING_CHANGE_LOGS:
        draft.error = null;
        draft.loadingChangeLogs = true;
        break;
      case FETCH_HOURLY_BOOKING_CHANGE_LOGS_FAIL:
        draft.error = action.error;
        draft.loadingChangeLogs = false;
        break;
      case FETCH_HOURLY_BOOKING_CHANGE_LOGS_SUCCSESS:
        draft.changeLogs = action.changeLogs;
        draft.loadingChangeLogs = false;
        break;
    }
  });
}
