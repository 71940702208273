import React from 'react';
import { useIntl, IntlShape } from 'react-intl';

export let intl: IntlShape;
export const IntlGlobal: React.FC<any> = ({ children }) => {
  const innerIntl = useIntl();
  intl = innerIntl;
  return children;
};

export default IntlGlobal;
