enum ActionTypes {
  FETCH_COMPANIES = 'companySettings/FETCH_COMPANIES',
  FETCH_COMPANIES_SUCCESS = 'companySettings/FETCH_COMPANIES_SUCCESS',
  FETCH_COMPANIES_FAIL = 'companySettings/FETCH_COMPANIES_FAIL',

  DELETE_COMPANY = 'companySettings/DELETE_COMPANY',
  DELETE_COMPANY_SUCCESS = 'companySettings/DELETE_COMPANY_SUCCESS',
  DELETE_COMPANY_FAIL = 'companySettings/DELETE_COMPANY_FAIL',

  CREATE_COMPANY = 'companySettings/CREATE_COMPANY',
  CREATE_COMPANY_SUCCESS = 'companySettings/CREATE_COMPANY_SUCCESS',
  CREATE_COMPANY_FAIL = 'companySettings/CREATE_COMPANY_FAIL',

  EDIT_COMPANY = 'companySettings/EDIT_COMPANY',
  EDIT_COMPANY_SUCCESS = 'companySettings/EDIT_COMPANY_SUCCESS',
  EDIT_COMPANY_FAIL = 'companySettings/EDIT_COMPANY_FAIL',
}

export default ActionTypes;
