import React from 'react';
import { LocationProvider } from '@reach/router';
import SkeletonTable from 'components/skeleton/TableSkeletonScreen';
import { Skeleton } from '@uxui/skeleton-loader';
import { PageHeader } from '@aha/ui';
import RoomPageSkeleton from 'components/skeleton/pages/RoomPageSkeleton';
import CardPageSkeleton from 'components/skeleton/pages/CardPageSkeleton';
import ReportPageSkeleton from 'components/skeleton/pages/ReportPageSkeleton';
import ROUTES from 'constants/routes';
import { matchRelativePath } from 'utils/routes';
import { FormattedMessage } from 'react-intl';
import DashboardHomeSkeleton from 'components/skeleton/pages/DashboardHomeSkeleton';

interface LoadScreenSwitcherProps {
  noXPadding?: boolean;
  pageRoute?: string;
}

export interface PageType {
  title: JSX.Element;
  secondTitle?: JSX.Element;
  Component?: React.MemoExoticComponent<() => JSX.Element>;
  hasExtra?: boolean;
  pageHeaderClassName?: string;
  relative?: boolean;
}

export function extractComponentFromRoute(
  path: string,
): Record<string, PageType> | null {
  const pages = {
    [ROUTES.DASHBOARD]: {
      title: (
        <FormattedMessage
          defaultMessage="Dashboard"
          id="sidebar.menu.dashboard"
        />
      ),
      Component: DashboardHomeSkeleton,
      hasExtra: true,
    },
    [ROUTES.ARRIVAL_BOOKING]: {
      title: (
        <FormattedMessage
          defaultMessage="Bookings"
          id="sidebar.menu.bookings"
        />
      ),
      hasExtra: true,
    },
    [ROUTES.DEPARTURE_BOOKING]: {
      title: (
        <FormattedMessage
          defaultMessage="Bookings"
          id="sidebar.menu.bookings"
        />
      ),
      hasExtra: true,
    },
    [ROUTES.INHOUSE_BOOKING]: {
      title: (
        <FormattedMessage
          defaultMessage="Bookings"
          id="sidebar.menu.bookings"
        />
      ),
      hasExtra: true,
    },
    [ROUTES.FOLIO_BOOKING]: {
      secondTitle: (
        <FormattedMessage defaultMessage="Folio" id="bookings.label.folio" />
      ),
      title: (
        <div>
          <div className="font-semibold">
            <FormattedMessage
              defaultMessage="Folio"
              id="bookings.label.folio"
            />
            <span className="ml-2">
              <Skeleton
                width="60px"
                height="15px"
                baseColor="#ddd"
                highlightColor="#e4e4e4"
              />
            </span>
          </div>
          <div className="mt-1">
            <Skeleton
              width="70px"
              height="24px"
              baseColor="#ddd"
              highlightColor="#e4e4e4"
            />
          </div>
        </div>
      ),
      pageHeaderClassName: 'h-26',
      relative: true,
    },
    [ROUTES.DETAIL_BOOKING]: {
      secondTitle: (
        <FormattedMessage
          defaultMessage="Booking Detail"
          id="bookings.label.bookingDetail"
        />
      ),
      title: (
        <div>
          <Skeleton
            width="140px"
            height="24px"
            baseColor="#ddd"
            highlightColor="#e4e4e4"
          />
          <div className="mt-1">
            <Skeleton
              width="500px"
              height="24px"
              baseColor="#ddd"
              highlightColor="#e4e4e4"
            />
          </div>
          <div className="mt-1">
            <Skeleton
              width="70px"
              height="24px"
              baseColor="#ddd"
              highlightColor="#e4e4e4"
            />
          </div>
        </div>
      ),
      hasExtra: true,
      pageHeaderClassName: 'mt-8 mb-6',
      relative: true,
    },
    [ROUTES.DAILY_BOOKING_SEARCH]: {
      title: (
        <FormattedMessage defaultMessage="Search" id="sidebar.menu.search" />
      ),
      hasExtra: true,
    },
    [ROUTES.HOURLY_BOOKING_SEARCH]: {
      title: (
        <FormattedMessage defaultMessage="Search" id="sidebar.menu.search" />
      ),
      hasExtra: true,
    },
    [ROUTES.CREATE_BOOKING]: {
      title: (
        <FormattedMessage
          defaultMessage="New Booking"
          id="common.label.new_booking"
        />
      ),
      hasExtra: true,
    },
    [ROUTES.ROOMS]: {
      title: (
        <FormattedMessage defaultMessage="Rooms" id="sidebar.menu.rooms" />
      ),
      Component: RoomPageSkeleton,
      hasExtra: true,
    },
    [ROUTES.CALENDAR]: {
      title: (
        <FormattedMessage
          defaultMessage="Calendar"
          id="sidebar.menu.calendar"
        />
      ),
      hasExtra: true,
    },
    [ROUTES.ROOM_INVENTORY]: {
      title: (
        <FormattedMessage
          defaultMessage="Inventory"
          id="sidebar.menu.roomInventory"
        />
      ),
      hasExtra: true,
    },
    [ROUTES.RATES]: {
      title: (
        <FormattedMessage defaultMessage="Rates" id="sidebar.menu.rates" />
      ),
      hasExtra: true,
    },
    [ROUTES.OCCUPANCY_AND_CHARGE_REPORT]: {
      title: (
        <FormattedMessage
          defaultMessage="Occupancy & Charge Report"
          id="reports.label.occupancyAndChargeReport"
        />
      ),
      Component: ReportPageSkeleton,
    },
    [ROUTES.SEGMENT_REPORT]: {
      title: (
        <FormattedMessage
          defaultMessage="Segment Report"
          id="reports.label.segmentReport"
        />
      ),
      hasExtra: true,
    },
    [ROUTES.WAREHOUSE_REPORT]: {
      title: (
        <FormattedMessage
          defaultMessage="Warehouse Report"
          id="reports.label.warehouseReport"
        />
      ),
      Component: ReportPageSkeleton,
    },
    [ROUTES.COMPANIES_REPORT]: {
      title: (
        <FormattedMessage
          defaultMessage="Companies Report"
          id="reports.label.companiesReport"
        />
      ),
      hasExtra: true,
    },
    [ROUTES.PAYMENT_REPORT]: {
      title: (
        <FormattedMessage
          defaultMessage="Payments Report"
          id="reports.label.payments"
        />
      ),
      Component: ReportPageSkeleton,
    },
    [ROUTES.HOURLY_CHARGE_REPORT]: {
      title: (
        <FormattedMessage
          defaultMessage="Hourly Report"
          id="reports.label.hourlyReport"
        />
      ),
      Component: ReportPageSkeleton,
    },
    [ROUTES.HOURLY_PAYMENT_REPORT]: {
      title: (
        <FormattedMessage
          defaultMessage="Hourly Report"
          id="reports.label.hourlyReport"
        />
      ),
      Component: ReportPageSkeleton,
    },
    [ROUTES.BUDGET_REPORT]: {
      title: (
        <FormattedMessage
          defaultMessage="Budget Report"
          id="reports.label.budget_report"
        />
      ),
      hasExtra: true,
    },
    [ROUTES.STATISTICAL_REPORT]: {
      title: (
        <FormattedMessage
          defaultMessage="Statistical Report"
          id="reports.label.statistical_report"
        />
      ),
    },
    [ROUTES.COMPANIES_SETTING]: {
      title: (
        <FormattedMessage
          defaultMessage="Companies"
          id="sidebar.menu.companies"
        />
      ),
      hasExtra: true,
    },
    [ROUTES.GUESTS_SETTING]: {
      title: (
        <FormattedMessage defaultMessage="Guests" id="sidebar.menu.guests" />
      ),
      hasExtra: true,
    },
    [ROUTES.CURRENCY_SETTING]: {
      title: (
        <FormattedMessage
          defaultMessage="Currency setting"
          id="settings.label.currency_setting"
        />
      ),
    },
    [ROUTES.USER_SETTING]: {
      title: (
        <FormattedMessage
          defaultMessage="Users setting"
          id="settings.label.users_setting"
        />
      ),
    },
    [ROUTES.HOURLY_SERVICES_SETTING]: {
      title: (
        <FormattedMessage
          defaultMessage="Hourly services setting"
          id="settings.label.hourly_services_setting"
        />
      ),
    },
    [ROUTES.PAYMENT_TYPES_SETTING]: {
      title: (
        <FormattedMessage
          defaultMessage="Payment types setting"
          id="settings.label.payment_types_setting"
        />
      ),
    },
    [ROUTES.ROOM_TYPE_SETTING]: {
      title: (
        <FormattedMessage
          defaultMessage="Rooms setting"
          id="settings.label.rooms_setting"
        />
      ),
    },
    [ROUTES.SERVICE_GROUPS_SETTING]: {
      title: (
        <FormattedMessage
          defaultMessage="Service groups setting"
          id="settings.label.service_groups_setting"
        />
      ),
    },
    [ROUTES.MARKET_SEGMENTS_SETTING]: {
      title: (
        <FormattedMessage
          defaultMessage="Market segments setting"
          id="settings.label.market_segments_setting"
        />
      ),
    },
    [ROUTES.CHARGE_TEMPLATES_SETTING]: {
      title: (
        <FormattedMessage
          defaultMessage="Charge templates setting"
          id="settings.label.charge_templates_setting"
        />
      ),
    },
    [ROUTES.BUDGET_SETTING]: {
      title: (
        <FormattedMessage
          defaultMessage="Budget setting"
          id="settings.label.budget_setting"
        />
      ),
    },
    [ROUTES.LOCK_DOOR]: {
      title: (
        <FormattedMessage
          defaultMessage="Lock door"
          id="sidebar.menu.lockDoor"
        />
      ),
      Component: CardPageSkeleton,
    },
    [ROUTES.HOTELS]: {
      title: (
        <FormattedMessage
          defaultMessage="Manage hotels"
          id="settings.label.manage_hotels"
        />
      ),
    },
    [ROUTES.NEW_HOTEL]: {
      title: [
        {
          title: (
            <FormattedMessage
              defaultMessage="Hotel List"
              id="settings.label.hotel_list"
            />
          ),
          href: '/hotels',
        },
        <FormattedMessage
          defaultMessage="Create new hotel"
          id="settings.label.create_new_hotel"
        />,
      ],
      Component: CardPageSkeleton,
    },
    [ROUTES.PROFILE]: {
      title: (
        <FormattedMessage defaultMessage="Profile" id="profile.label.header" />
      ),
      Component: CardPageSkeleton,
    },
    [ROUTES.EDIT_HOTEL]: {
      title: [
        {
          title: (
            <FormattedMessage
              defaultMessage="Hotel List"
              id="settings.label.hotel_list"
            />
          ),
          href: '/hotels',
        },
        <FormattedMessage
          defaultMessage="Edit hotel"
          id="common.label.edit_hotel"
        />,
      ],
      Component: CardPageSkeleton,
      relative: true,
    },
  };

  return pages[path] ? pages : matchRelativePath(pages, path);
}

const LoadScreenSwitcher = ({
  noXPadding = false,
  pageRoute,
}: LoadScreenSwitcherProps) => {
  return (
    <LocationProvider>
      {({ location: { pathname } }: any) => {
        const path = pageRoute || pathname,
          page = extractComponentFromRoute(path);
        if (!page) {
          return;
        }

        const { title, hasExtra, pageHeaderClassName, Component } = page[path];

        return (
          <div>
            <PageHeader
              className={pageHeaderClassName}
              title={title}
              extra={
                hasExtra ? (
                  <Skeleton
                    width="120px"
                    height="30px"
                    baseColor="#ddd"
                    highlightColor="#e4e4e4"
                  />
                ) : null
              }
            />
            {Component ? <Component /> : <SkeletonTable />}
          </div>
        );
      }}
    </LocationProvider>
  );
};

export default LoadScreenSwitcher;
