import * as React from 'react';

export function createIcon(
  component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
) {
  return (props: React.SVGProps<SVGSVGElement>) =>
    React.createElement(component, {
      ...props,
      width: '1em',
      height: '1em',
      fill: 'currentColor',
    });
}
