enum ActionTypes {
  RATE_PLAN_KEY = 'ratePlans',
  FETCH_RATE_PLANS = 'ratePlans/FETCH_RATE_PLANS',
  FETCH_RATE_PLANS_SUCCESS = 'ratePlans/FETCH_RATE_PLANS_SUCCESS',
  FETCH_RATE_PLANS_FAIL = 'ratePlans/FETCH_RATE_PLANS_FAIL',

  CREATE_RATE_PLAN = 'ratePlans/CREATE_RATE_PLAN',
  CREATE_RATE_PLAN_SUCCESS = 'ratePlans/CREATE_RATE_PLAN_SUCCESS',
  CREATE_RATE_PLAN_FAIL = 'ratePlans/CREATE_RATE_PLAN_FAIL',

  UPDATE_RATE_PLAN = 'ratePlans/UPDATE_RATE_PLAN',
  UPDATE_RATE_PLAN_SUCCESS = 'ratePlans/UPDATE_RATE_PLAN_SUCCESS',
  UPDATE_RATE_PLAN_FAIL = 'ratePlans/UPDATE_RATE_PLAN_FAIL',
  UPDATE_CLOSED_RATE_PLAN = 'ratePlans/UPDATE_CLOSED_RATE_PLAN',
  UPDATE_CLOSED_RATE_PLAN_SUCCESS = 'ratePlans/UPDATE_CLOSED_RATE_PLAN_SUCCESS',
  UPDATE_CLOSED_RATE_PLAN_FAIL = 'ratePlans/UPDATE_CLOSED_RATE_PLAN_FAIL',

  CLOSE_RATE_PLAN = 'ratePlans/CLOSE_RATE_PLAN',
  CLOSE_RATE_PLAN_SUCCESS = 'ratePlans/CLOSE_RATE_PLAN_SUCCESS',
  CLOSE_RATE_PLAN_FAIL = 'ratePlans/CLOSE_RATE_PLAN_FAIL',

  UPDATE_RATE_RESTRICTION = 'ratePlans/UPDATE_RATE_RESTRICTION',
  UPDATE_RATE_RESTRICTION_SUCCESS = 'ratePlans/UPDATE_RATE_RESTRICTION_SUCCESS',
  UPDATE_RATE_RESTRICTION_FAIL = 'ratePlans/UPDATE_RATE_RESTRICTION_FAIL',

  SYNC_RATE_PLANS_WITH_STAHH = 'ratePlans/SYNC_RATE_PLANS_WITH_STAHH',
  SYNC_RATE_PLANS_WITH_STAHH_SUCCESS = 'ratePlans/SYNC_RATE_PLANS_WITH_STAHH_SUCCESS',
  SYNC_RATE_PLANS_WITH_STAHH_FAIL = 'ratePlans/SYNC_RATE_PLANS_WITH_STAHH_FAIL',

  SYNC_RATE_PLANS_WITH_AHA = 'ratePlans/SYNC_RATE_PLANS_WITH_AHA',
  SYNC_RATE_PLANS_WITH_AHA_SUCCESS = 'ratePlans/SYNC_RATE_PLANS_WITH_AHA_SUCCESS',
  SYNC_RATE_PLANS_WITH_AHA_FAIL = 'ratePlans/SYNC_RATE_PLANS_WITH_AHA_FAIL',

  SYNC_RATE_PLANS_WITH_OTA_PARTNER = 'ratePlans/SYNC_RATE_PLANS_WITH_OTA_PARTNER',
  SYNC_RATE_PLANS_WITH_OTA_PARTNER_SUCCESS = 'ratePlans/SYNC_RATE_PLANS_WITH_OTA_PARTNER_SUCCESS',
  SYNC_RATE_PLANS_WITH_OTA_PARTNER_FAIL = 'ratePlans/SYNC_RATE_PLANS_WITH_OTA_PARTNER_FAIL',

  FETCH_EDIT_HISTORY = 'ratePlans/FETCH_EDIT_HISTORY',
  FETCH_EDIT_HISTORY_SUCCESS = 'ratePlans/FETCH_EDIT_HISTORY_SUCCESS',
  FETCH_EDIT_HISTORY_FAIL = 'ratePlans/FETCH_EDIT_HISTORY_FAIL',

  FETCH_PUSH_HISTORY = 'ratePlans/FETCH_PUSH_HISTORY',
  FETCH_PUSH_HISTORY_SUCCESS = 'ratePlans/FETCH_PUSH_HISTORY_SUCCESS',
  FETCH_PUSH_HISTORY_FAIL = 'ratePlans/FETCH_PUSH_HISTORY_FAIL',

  CREATE_PROMOTION = 'ratePlans/CREATE_PROMOTION',
  CREATE_PROMOTION_SUCCESS = 'ratePlans/CREATE_PROMOTION_SUCCESS',
}

export default ActionTypes;
