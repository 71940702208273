import produce from 'immer';
import moment from 'moment';
import ActionTypes from './constants';
import initialStateHolder from 'utils/initialStateHolder';
import { DEFAULT_DATE } from 'utils/constants';
import {
  CreateBookingActions,
  CreateBookingState,
  NewBookingForm,
} from './types';
import { NOT_AVAILABLE } from '@aha/constants';

export const bookingFormDefault: NewBookingForm = {
  dates: [DEFAULT_DATE, moment(DEFAULT_DATE).add(1, 'day')],
  clientRequestNotes: undefined,
  housekeepingNotes: undefined,
  mealNotes: undefined,
  otherNotes: undefined,
  isNightBooking: false,
  ratePlans: [],
  segmentCodes: undefined,
  booker: undefined,
  source: {
    companyID: '',
    marketingSourceID: '',
    reference: undefined,
  },
  bookerCount: false,
  guests: [],
  roomTypes: [
    {
      privateId: Date.now(),
      count: 1,
      privateIsTotalPrice: false,
      currency: 'VND',
      rooms: [
        {
          privateRoomTypeId: Date.now(),
          privateId: Date.now() + 1,
          roomTitle: NOT_AVAILABLE,
          charges: [],
          adults: 1,
          children: 0,
        },
      ],
    },
  ],
  roomsMap: {},
  ratePlansMap: {},
};

export const initialState = initialStateHolder.setState('createBooking', {
  chargeTemplates: null,
  countries: null,
  companyID: undefined,
});

export default function (
  state: CreateBookingState = initialState,
  action: CreateBookingActions,
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.FETCH_CHARGE_TEMPLATE_LIST:
        draft.chargeTemplates = [];
        break;

      case ActionTypes.FETCH_CHARGE_TEMPLATE_LIST_SUCCESS:
        draft.chargeTemplates = action.payload;
        break;

      case ActionTypes.FETCH_COUNTRIES:
        break;

      case ActionTypes.FETCH_COUNTRIES_SUCCESS:
        draft.countries = action.payload;
        break;

      case ActionTypes.SET_COMPANY_BOOKING_ID:
        draft.companyID = action.payload;
        break;
    }
  });
}
