import { call, put, takeEvery } from 'redux-saga/effects';
import { coreAPI } from 'utils/request';
import { showErrorNotification } from '@aha/utils';
import { createPromotionSuccess } from '../actions';
import ActionTypes from '../constants';
import { RatePlansActions } from '../types';
import { ExtractAction } from 'types/actions';

function* doCreatePromotion(
  action: ExtractAction<RatePlansActions, ActionTypes.CREATE_RATE_PLAN>,
) {
  const {
    payload: { data: promotion, resolve, reject },
  } = action;
  try {
    const data = yield call(
      coreAPI.post,
      `v1/pms/rate-publishing-configs`,
      promotion,
    );
    yield put(createPromotionSuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification(
      'Fail to create',
      `Cannot create the promotion at the moment, please try it later.`,
    );

    reject(err);
  }
}

export default function* watchPromotion() {
  yield takeEvery(ActionTypes.CREATE_PROMOTION, doCreatePromotion);
}
