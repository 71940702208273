import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

export interface BadgeProps extends HTMLAttributes<HTMLSpanElement> {
  color?: BadgeColor;
  className?: string;
  disabled?: boolean;
  size?: 'medium' | 'small';
  children?: any;
}
export type BadgeColor =
  | 'primary'
  | 'blue'
  | 'orange'
  | 'purple'
  | 'green'
  | 'red'
  | 'yellow'
  | 'cyan'
  | 'grey';

const smallCss = css`
  ${tw`text-3xs leading-none`}
  padding: 2px 4px;
`;

const largeCss = css`
  ${tw`text-xs mb-2`}
  padding: 5px 12px;
`;

const StyledBadge = styled.span<BadgeProps>`
  ${tw`inline-flex items-center uppercase font-medium`};
  ${({ size }) => (size !== 'small' ? largeCss : smallCss)}
  border-radius: 2px;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

const PrimaryBadge = styled(StyledBadge)`
  ${tw`bg-green-lighter text-primary`};
`;

const BlueBadge = styled(StyledBadge)`
  ${tw`text-blue bg-blue-lightest`};
`;

const OrangeBadge = styled(StyledBadge)`
  ${tw`text-orange bg-orange-lightest`};
`;

const PurpleBadge = styled(StyledBadge)`
  ${tw`bg-purple-lightest text-purple`};
`;

const GreenBadge = styled(StyledBadge)`
  ${tw`bg-green-lighter text-green`};
`;

const RedBadge = styled(StyledBadge)`
  ${tw`bg-red-lightest text-red`};
`;

const YellowBadge = styled(StyledBadge)`
  ${tw`bg-yellow-lightest text-yellow`};
`;
const CyanBadge = styled(StyledBadge)`
  ${tw`bg-cyan-lightest text-cyan`};
`;

const GreyBadge = styled(StyledBadge)`
  ${tw`bg-grey-light text-secondary`};
`;

const components: { [k in BadgeColor]: React.ElementType } = {
  green: GreenBadge,
  yellow: YellowBadge,
  red: RedBadge,
  grey: GreyBadge,
  primary: PrimaryBadge,
  blue: BlueBadge,
  orange: OrangeBadge,
  purple: PurpleBadge,
  cyan: CyanBadge,
};

export default function Badge({
  color = 'green',
  children,
  className = '',
  size = 'medium',
  disabled = false,
  ...rest
}: BadgeProps) {
  const RenderBadge = components[color];
  return (
    <RenderBadge
      className={className}
      disabled={disabled}
      size={size}
      {...rest}
    >
      {children}
    </RenderBadge>
  );
}
