import { all, put, takeLatest, takeEvery, call } from 'redux-saga/effects';
import { showErrorNotification } from '@aha/utils';
import ActionTypes from './constants';

import {
  ALLOCATE_ROOM_FOR_BOOKING_SUCCESS,
  CHECK_OUT_BOOKING_SUCCESS,
  CREATE_HOURLY_BOOKING_SUCCESS,
  UPDATE_BOOKING_PERIOD_SUCCESS,
} from '../BookingProvider/constants';
import {
  VOID_HOURLY_BOOKING_SUCCSESS,
  CHECKOUT_HOURLY_BOOKING_SUCCESS,
} from '../HourlyBooking/constants';

import {
  fetchRoomsFail,
  fetchRoomsSuccess,
  updateRoomHouseKeepingStatusSuccess,
  updateRoomHouseKeepingStatusFail,
  fetchRefreshRoomsSuccess,
  setActionsRefreshRooms,
  createLogSuccess,
  editLogSuccess,
  deleteLogSuccess,
  fetchLogsSuccess,
} from './actions';
import { coreAPI } from 'utils/request';
import { ExtractAction } from 'types/actions';
import { RoomPlanActions } from './types';
import { RoomRoomListResponse } from 'types/v3-schema';

export function* fetchRooms(
  action: ExtractAction<RoomPlanActions, ActionTypes.FETCH_ROOMS>,
) {
  try {
    const {
      payload: { withBooking = true },
    } = action;

    const { data }: RoomRoomListResponse = yield call(
      coreAPI.get,
      `/v1/pms/rooms?with_bookings=${!!withBooking}`,
    );
    yield put(fetchRoomsSuccess(data || []));
  } catch (err) {
    yield put(fetchRoomsFail(err));
  }
}

export function* fetchRefreshRooms(
  action: ExtractAction<RoomPlanActions, ActionTypes.FETCH_ROOMS>,
) {
  try {
    const {
      payload: { withBooking = true },
    } = action;

    const { data }: RoomRoomListResponse = yield call(
      coreAPI.get,
      `/v1/pms/rooms?with_bookings=${!!withBooking}`,
    );
    yield put(fetchRefreshRoomsSuccess(data || []));
  } catch (err) {
    yield put(fetchRoomsFail(err));
  }
}

export function* setRefreshRooms() {
  yield put(setActionsRefreshRooms(true));
}

export function* updateRoomHkStatus(
  action: ExtractAction<RoomPlanActions, ActionTypes.UPDATE_ROOM_HK_STATUS>,
) {
  try {
    const {
      payload: { rid, status },
    } = action;

    const { data } = yield call(
      coreAPI.put,
      `v1/pms/rooms/${rid}/housekeeping`,
      {
        housekeepingStatus: status,
      },
    );
    yield put(updateRoomHouseKeepingStatusSuccess(data || {}));
  } catch (err) {
    yield put(updateRoomHouseKeepingStatusFail(err));
    showErrorNotification('Housekeeping status failed', err);
  }
}

export function* doFetchLogs(
  action: ExtractAction<RoomPlanActions, ActionTypes.FETCH_LOGS>,
) {
  try {
    const { payload: roomId } = action;
    const { data } = yield call(
      coreAPI.get,
      `v1/pms/room-availability-logs/${roomId}/upcoming`,
    );

    yield put(fetchLogsSuccess(roomId, data));
  } catch (err) {}
}

export function* watchFetchLogs() {
  yield takeEvery(ActionTypes.FETCH_LOGS, doFetchLogs);
}

export function* doCreateLog(
  action: ExtractAction<RoomPlanActions, ActionTypes.CREATE_LOG>,
) {
  const {
    payload: {
      data: { roomId, log },
      resolve,
      reject,
    },
  } = action;
  try {
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/room-availability-logs/${roomId}`,
      log,
    );

    yield put(createLogSuccess(data));
    resolve();
  } catch (err) {
    showErrorNotification('Fail to create', err);
    reject();
  }
}

export function* watchCreateLog() {
  yield takeEvery(ActionTypes.CREATE_LOG, doCreateLog);
}

export function* doEditLog(
  action: ExtractAction<RoomPlanActions, ActionTypes.EDIT_LOG>,
) {
  const {
    payload: {
      data: { roomId, log },
      resolve,
      reject,
    },
  } = action;
  try {
    const { data } = yield call(
      coreAPI.put,
      `v1/pms/room-availability-logs/${roomId}`,
      log,
    );

    yield put(editLogSuccess(data));
    resolve();
  } catch (err) {
    showErrorNotification('Fail to edit', err);
    reject();
  }
}

export function* watchEditLog() {
  yield takeEvery(ActionTypes.EDIT_LOG, doEditLog);
}

export function* doDeleteLog(
  action: ExtractAction<RoomPlanActions, ActionTypes.DELETE_LOG>,
) {
  try {
    const { payload: log } = action;
    yield call(coreAPI.delete, `v1/pms/room-availability-logs/${log.id}`);
    yield put(deleteLogSuccess(log));
  } catch (err) {
    showErrorNotification('Fail to edit', err);
  }
}

export function* watchDeleteLog() {
  yield takeEvery(ActionTypes.DELETE_LOG, doDeleteLog);
}

export function* watchFetchRooms() {
  yield takeLatest(ActionTypes.FETCH_ROOMS, fetchRooms);
}
export function* watchFetchRefreshRooms() {
  yield takeLatest(ActionTypes.FETCH_REFRESH_ROOMS, fetchRefreshRooms);
}
export function* watchUpdateRoomHkStatus() {
  yield takeLatest(ActionTypes.UPDATE_ROOM_HK_STATUS, updateRoomHkStatus);
}

///
export default function* watchAll() {
  yield all([
    watchFetchRooms(),
    watchUpdateRoomHkStatus(),
    watchFetchRefreshRooms(),
    watchFetchLogs(),
    watchCreateLog(),
    watchEditLog(),
    watchDeleteLog(),
    yield takeLatest(ALLOCATE_ROOM_FOR_BOOKING_SUCCESS, setRefreshRooms),
    yield takeLatest(CHECK_OUT_BOOKING_SUCCESS, setRefreshRooms),
    yield takeLatest(CREATE_HOURLY_BOOKING_SUCCESS, setRefreshRooms),
    yield takeLatest(UPDATE_BOOKING_PERIOD_SUCCESS, setRefreshRooms),
    yield takeLatest(VOID_HOURLY_BOOKING_SUCCSESS, setRefreshRooms),
    yield takeLatest(CHECKOUT_HOURLY_BOOKING_SUCCESS, setRefreshRooms),
  ]);
}
