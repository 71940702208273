import React from 'react';
import InputQuantity, { InputQuantityProps } from '../InputQuantity';
import { createAntField } from '../CreateAntField';
import { Field, UseFieldConfig } from 'react-final-form';

// @ts-ignore
const FormInputQuantityWrapper = createAntField<InputQuantityProps>(
  InputQuantity,
);

export interface FormInputQuantityProps extends UseFieldConfig<number> {
  name: string;
  label?: string | React.ReactNode;
  help?: React.ReactNode;
  noTouch?: boolean;
}

const FormInputQuantity = (props: FormInputQuantityProps) => {
  return <Field {...props} component={FormInputQuantityWrapper} />;
};

export default FormInputQuantity;
