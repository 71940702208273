import React, {
  useState,
  useEffect,
  useMemo,
  ReactText,
  SetStateAction,
} from 'react';
import moment from 'moment';
import { Form, Row, Col, AutoComplete } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Modal, Select, InputQuantity } from '@aha/ui';
import { Dispatch } from 'redux';
import {
  Hotel,
  RatePlan,
  HotelCurrency,
  Booking,
  BookingCreateRequestBody,
} from 'types/schema';
import { useDispatch, useSelector } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { DataSourceItemType } from 'antd/lib/auto-complete';
import useSelectRoomTypes from 'containers/SettingRoomTypesPage/useSelectRoomTypes';
import { currencyFormater } from 'utils/currencyFormater';
import { ApplicationRootState } from 'types/app';
import { createStructuredSelector } from 'reselect';
import { makeSelectRatePlans } from 'containers/RatePlansPage/selector';
import { fetchRatePlans } from 'containers/RatePlansPage/actions';
import { SelectValue } from 'antd/lib/select';
import { addChildBooking } from 'containers/BookingProvider/actions';
import { makeSelectCurrencies } from 'containers/Dashboard/selectors';

function ratePlanOptions(rates: RatePlan[] = []) {
  return rates.map((r) => ({
    text: `${r.name} - ${currencyFormater(r.price, r.currency?.name || 'VND')}`,
    value: r.id,
    isRatePlan: true,
  }));
}

function filterRates(rates: RatePlan[] = [], rateFilter?: number) {
  const sortRates = rates.sort(
    (a, b) => Number(a?.roomType?.name) - Number(b?.roomType?.name),
  );

  return (sortRates || []).filter((r) =>
    rateFilter ? r.roomType?.id === rateFilter : false,
  );
}

export type SubmitValues = {
  currency: string;
  quantity: number;
  roomTypeId: string;
};

export type CreateBookingRoomProps = {
  booking: Booking;
  hotel: Hotel;
  setCollapseKeys: React.Dispatch<SetStateAction<string[]>>;
  visible?: boolean;
  bid?: string;
  currencies?: HotelCurrency[];
  onClose?: (...args: any[]) => any;
} & FormComponentProps;

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    rates: RatePlan[];
    currencies: HotelCurrency[];
  }
>({
  rates: makeSelectRatePlans(),
  currencies: makeSelectCurrencies(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doFetchRates: (
    hid: number,
    start?: moment.Moment | string,
    end?: moment.Moment | string,
  ) => dispatch(fetchRatePlans(hid, start, end)),
  doAddChildBooking: (refCode: string, body: BookingCreateRequestBody) =>
    new Promise((resolve, reject) =>
      dispatch(addChildBooking(refCode, body, resolve, reject)),
    ),
});

const CreateBookingRoomModal: React.SFC<CreateBookingRoomProps> = ({
  hotel,
  booking,
  visible,
  setCollapseKeys,
  onClose,
  form,
}) => {
  const dispatch = useDispatch();
  const { doAddChildBooking, doFetchRates } = mapDispatchToProps(dispatch);
  const { rates, currencies } = useSelector(mapStateToProps);

  const {
    getFieldDecorator,
    getFieldValue,
    resetFields,
    validateFields,
    setFieldsValue,
  } = form;
  const [submitting, setSubmitting] = useState(false);
  const [rateId, setRateId] = useState<number | undefined>(undefined);
  const [isDefaultRoomRate, setIsDefaultRoomRate] = useState(false);
  const [price, setPrice] = useState<ReactText>(0);
  const { arrival, departure, refCode } = booking || {};

  const { roomTypes } = useSelectRoomTypes(hotel?.id);
  const curRoomTypeID = getFieldValue('roomTypeId');

  const roomRateSources = useMemo(
    () => ratePlanOptions(filterRates(rates, curRoomTypeID)),
    [rates, curRoomTypeID],
  );

  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    if (hotel?.id && arrival && departure) {
      doFetchRates(hotel?.id, arrival, departure);
    }
  }, [hotel?.id]); // eslint-disable-line

  function onCancel() {
    if (submitting) {
      return;
    }

    onClose && onClose();
    resetFields();
  }

  function onAdd() {
    validateFields(async (err, values: SubmitValues) => {
      if (err) {
        return;
      } else if (!refCode) {
        console.error('Booking not found!');
        return;
      }

      try {
        setSubmitting(true);
        const { roomTypeId, quantity, currency } = values;
        const body: BookingCreateRequestBody = {
          amount: +price,
          // TODO missing swagger definition
          // @ts-ignore
          stayRuleId: rateId,
          currency,
          roomTypeId,
          quantity,
        };

        await doAddChildBooking(refCode, body);

        onClose && onClose(true);
        resetFields();
        setCollapseKeys((prev: string[]) =>
          prev.includes(values.roomTypeId)
            ? prev
            : [...prev, values.roomTypeId],
        );
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    });
  }

  function onSelectRoomRates(rid: SelectValue) {
    const curRate: RatePlan | undefined = rates?.find(
      (r) => r.id === Number(rid),
    );
    if (curRate) {
      setRateId(Number(rid));
      setIsDefaultRoomRate(true);
    }

    setFieldsValue({
      currency: curRate?.currency?.name || hotel?.currency || 'VND',
    });
  }

  return (
    <Modal
      visible={visible}
      title={
        <FormattedMessage
          defaultMessage="Create child booking"
          id="bookings.label.addChildBooking"
        />
      }
      submitText={
        <FormattedMessage defaultMessage="Create" id="common.action.create" />
      }
      onCancel={onCancel}
      submitting={submitting}
      onSubmit={onAdd}
      width={700}
    >
      <Form layout="vertical">
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Room type"
                  id="common.label.roomType"
                />
              }
            >
              {getFieldDecorator(`roomTypeId`, {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
              })(
                <Select<string>
                  showSearch
                  placeholder={
                    <FormattedMessage
                      defaultMessage="Room type"
                      id="common.label.select_room_type"
                    />
                  }
                  optionFilterProp="children"
                  onSelect={() => {
                    setRateId(undefined);
                    setPrice(0);
                    setFieldsValue({ rate: undefined });
                  }}
                >
                  {(roomTypes || []).map((c) => (
                    <Select.Option key={c.id} value={c.id}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Amount"
                  id="common.label.amount"
                />
              }
            >
              {getFieldDecorator('quantity', {
                initialValue: 1,
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                  {
                    validator: (_, value, cb) =>
                      !value || (value && value < 1)
                        ? cb(
                            <FormattedMessage
                              id="common.message.invalid"
                              defaultMessage="Invalid"
                            />,
                          )
                        : cb(),
                  },
                ],
              })(<InputQuantity />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Room Rate"
                  id="common.label.roomRate"
                />
              }
            >
              {getFieldDecorator('rate', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                  {
                    validator: (rule, value, cb) =>
                      value &&
                      isNaN(value) &&
                      (roomRateSources || []).findIndex(
                        (r) => r.value === value,
                      ) < 0
                        ? cb(
                            <FormattedMessage
                              id="common.message.invalid"
                              defaultMessage="Invalid"
                            />,
                          )
                        : cb(),
                  },
                ],
              })(
                <AutoComplete
                  placeholder={
                    <FormattedMessage
                      defaultMessage="Enter price"
                      id="common.label.enter_price"
                    />
                  }
                  dataSource={
                    (roomRateSources as unknown) as DataSourceItemType[]
                  }
                  onSelect={onSelectRoomRates}
                  onSearch={(price) => {
                    setRateId(undefined);
                    setPrice(price);
                  }}
                />,
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="HotelCurrency"
                  id="common.label.currency"
                />
              }
            >
              {getFieldDecorator(`currency`, {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
                initialValue: hotel.currency,
              })(
                <Select<string>
                  showSearch
                  disabled={!!rateId && isDefaultRoomRate}
                  placeholder={
                    <FormattedMessage
                      defaultMessage="HotelCurrency"
                      id="common.label.currency"
                    />
                  }
                  optionFilterProp="children"
                >
                  {(currencies || []).map((c) => (
                    <Select.Option key={c.name} value={c.name}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Form.create<CreateBookingRoomProps>({
  name: 'create_booking_room_form',
})(CreateBookingRoomModal);
