import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { ApplicationRootState } from 'types/app';

const selectMarketingSources = (state: ApplicationRootState) =>
  state.marketingSources || initialState;

const makeSelectMarketingSources = () =>
  createSelector(
    selectMarketingSources,
    (marketingSources) => marketingSources,
  );

const makeSelectMarketingSourcesItems = () =>
  createSelector(
    selectMarketingSources,
    (marketingSources) => marketingSources.items,
  );

const makeSelectMarketingSourcesLoading = () =>
  createSelector(
    selectMarketingSources,
    (marketingSources) => marketingSources.loading,
  );

export {
  makeSelectMarketingSourcesItems,
  selectMarketingSources,
  makeSelectMarketingSources,
  makeSelectMarketingSourcesLoading,
};
