import React from 'react';
import styled from 'styled-components';
import { Button } from '@aha/ui';
import { Link } from '@reach/router';
import { FormattedMessage } from 'react-intl';
import tw from 'twin.macro';
import ROUTES from 'constants/routes';
import ErrorImage from 'img/Error.png';

const Container = styled.div`
  ${tw`flex justify-center py-20 px-10`};
  height: calc(100vh - 80px);
  min-height: 744px;
`;

const Wrapper = styled.div`
  ${tw`flex justify-center flex-col text-center`};
  > div {
    max-width: 600px;
  }
`;

const Desc = styled.p`
  ${tw`my-8 w-full text-secondary mx-auto font-bold`};
  max-width: 500px;
  font-size: 30px;
`;

type ExceptionProps = {
  message?: any;
  image?: any;
  path?: string;
  titleButton?: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const Exception: React.SFC<ExceptionProps> = ({
  message,
  path = ROUTES.DASHBOARD,
  image = ErrorImage,
  className,
  onClick,
  titleButton = (
    <FormattedMessage
      defaultMessage="Go Back"
      id="common.action.back_to_home"
    />
  ),
}) => {
  return (
    <Container>
      <Wrapper>
        <div className="text-center">
          <img src={image} alt="error" className="max-w-full" />
          <Desc>{message}</Desc>
          <Link to={path}>
            <Button className="min-w-lg h-9" onClick={onClick}>
              {titleButton}
            </Button>
          </Link>
        </div>
      </Wrapper>
    </Container>
  );
};

export default Exception;
