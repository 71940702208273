import { useMediaLayout } from 'use-media';

type DeviceSize = 'md' | 'sm' | 'lg' | 'xl' | 'xxl';

export const screens: { [k in DeviceSize]: number } = {
  sm: 575,
  md: 767,
  lg: 991,
  xl: 1199,
  xxl: 1500,
};

function useMedia(deviceSize: DeviceSize = 'md'): boolean {
  return useMediaLayout({ maxWidth: screens[deviceSize] });
}

useMedia.screens = screens;

export default useMedia;
