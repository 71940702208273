import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import { Link } from '@reach/router';
import tw from 'twin.macro';

const Container = styled.div`
  ${tw`flex justify-center py-10 pb-20 px-10 absolute inset-0`}
`;

const Wrapper = styled.div`
  ${tw`flex justify-center flex-col text-center`}
  > div {
    max-width: 600px;
  }
`;

const Desc = styled.p`
  ${tw`w-full text-secondary mx-auto font-semibold`};
  margin-top: 18px;
  margin-bottom: 28px;
  max-width: 500px;
  font-size: 30px;
`;

export interface ExceptionProps {
  message?: string;
  path?: string;
  image?: string;
  className?: string;
  titleButton?: string;
}

export function Exception({
  message,
  path = '/',
  image,
  className,
  titleButton = 'Go Back',
}: ExceptionProps) {
  return (
    <Container className={className}>
      <Wrapper>
        <div className="text-center">
          <img src={image} alt="error" />
          <Desc>{message}</Desc>
          <Link to={path}>
            <Button className="min-w-lg h-9">{titleButton}</Button>
          </Link>
        </div>
      </Wrapper>
    </Container>
  );
}

export default Exception;
