import React from 'react';
import { notification } from 'antd';
import { isObject, get } from 'lodash';
import { IconTimesCircle } from '@aha/icons';

export const defaultErrorMessage = 'Fail to fetch';
export const defaultErrorDescription =
  'Cannot fetch data at the moment, please try it later.';

export default function showErrorNotification(
  message: string = defaultErrorMessage,
  error: any = defaultErrorDescription,
) {
  const mainMessage = isObject(error)
    ? get(error, 'response.data.message', '') || get(error, 'message', '')
    : error;
  const firstDetailMessage = get(error, 'response.data.errors[0].message', '');

  const title: string = firstDetailMessage ? mainMessage : message;
  const desc = firstDetailMessage ? firstDetailMessage : mainMessage;

  notification.open({
    className: 'bg-red-lightest border border-red',
    message: title,
    description: desc,
    icon: <IconTimesCircle className="text-red" />,
  });
}
