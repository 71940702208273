import { takeEvery, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { CANCEL_A_CHILD_BOOKING } from '../constants';
import { showErrorNotification } from '@aha/utils';
import NProgress from 'nprogress';

function* doCancelChildBooking({ bid, bkrid, body, resolve, reject }) {
  yield call(NProgress.start);
  try {
    yield call(
      coreAPI.put,
      `v1/pms/bookings/${bid}/booking-rooms/${bkrid}/cancel`,
      body,
    );
    resolve();
  } catch (err) {
    showErrorNotification('Fail to delete', err);
    reject();
  }
  yield call(NProgress.done);
}

export function* watchCancelChildBooking() {
  yield takeEvery(CANCEL_A_CHILD_BOOKING, doCancelChildBooking);
}
