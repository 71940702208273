import React, { useState } from 'react';
import { Form, Radio } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Modal } from '@aha/ui';
import { Dispatch } from 'redux';
import { BookingCancelBookingRoomGuest } from 'types/v3-schema';
import { Booking, BookingRoomDetail } from 'types/schema';
import { useDispatch } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { cancelAChildBooking } from 'containers/BookingProvider/actions';
import { NOT_AVAILABLE } from '@aha/constants';

export type CancelChildBookingProps = {
  booking: Booking;
  room?: BookingRoomDetail;
  visible?: boolean;
  onClose?: (...args: any[]) => any;
} & FormComponentProps;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doCancelChildBooking: (
    bid: number,
    bkrid: number,
    body: BookingCancelBookingRoomGuest,
  ) =>
    new Promise((resolve, reject) =>
      dispatch(cancelAChildBooking(bid, bkrid, body, resolve, reject)),
    ),
});

const CancelChildBookingModal: React.SFC<CancelChildBookingProps> = ({
  booking,
  room,
  visible,
  onClose,
  form,
}) => {
  const dispatch = useDispatch();
  const { doCancelChildBooking } = mapDispatchToProps(dispatch);

  const [submitting, setSubmitting] = useState(false);
  const { id: bid, rooms } = booking || {};
  const { resetFields, validateFields, getFieldDecorator } = form;

  function onCancel() {
    if (submitting) {
      return;
    }

    onClose && onClose();
    resetFields();
  }

  function onSubmit() {
    validateFields(async (err, values: BookingCancelBookingRoomGuest) => {
      if (err) {
        return;
      } else if (!bid || !room?.id) {
        console.error('Booking not found!');
        return;
      }

      try {
        setSubmitting(true);

        await doCancelChildBooking(bid, room.id, values);
        onClose && onClose(true);
        resetFields();
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    });
  }

  const validRooms = (rooms || []).filter(
    (r) =>
      ['checked_in', 'awaiting'].includes(r.status as string) &&
      r.id !== room?.id,
  );

  return (
    <Modal
      visible={visible}
      title={
        <FormattedMessage
          defaultMessage="Cancel Room: {id} - {title}"
          id="bookings.label.cancelChildBooking"
          values={{
            title: room?.roomName || NOT_AVAILABLE,
            id: room?.refCode,
          }}
        />
      }
      submitText={
        <FormattedMessage defaultMessage="Submit" id="common.label.submit" />
      }
      cancelText={
        <FormattedMessage defaultMessage="Cancel" id="common.action.cancel" />
      }
      submitting={submitting}
      onCancel={onCancel}
      onSubmit={onSubmit}
    >
      <Form layout="vertical">
        <Form.Item
          label={
            <FormattedMessage
              defaultMessage="Select a Room ID to transfer all folio depends on room {title} to"
              id="bookings.label.selectTransferFolio"
              values={{
                title: room?.roomName || NOT_AVAILABLE,
              }}
            />
          }
        >
          {getFieldDecorator('destBookingRoomId', {
            initialValue: validRooms?.[0]?.id,
            rules: [],
          })(
            <Radio.Group className="mt-4">
              {validRooms.map((r) => (
                <Radio
                  className="block leading-normal font-normal h-8"
                  key={r.id}
                  value={r.id}
                >
                  <span className="text-sm">
                    {[r.refCode, r.roomName].filter(Boolean).join(' - ')}
                  </span>
                </Radio>
              ))}
            </Radio.Group>,
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create<CancelChildBookingProps>({
  name: 'cancel_child_booking_form',
})(CancelChildBookingModal);
