import React from 'react';
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import { createAntField } from '../CreateAntField';
import { Field } from 'react-final-form';

// @ts-ignore
const FormInputNumberWrapper = createAntField<InputNumberProps>(InputNumber);

export interface FormInputNumberProps extends InputNumberProps {
  name: string;
  label?: string;
}

const FormInputNumber = (props: FormInputNumberProps) => {
  return <Field {...props} component={FormInputNumberWrapper} />;
};

export default FormInputNumber;
