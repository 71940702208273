import React from 'react';
import { PropsWithAs } from '../Box';
import { formatDatetime } from '@aha/utils';
import { DatetimeFormat } from '@aha/utils/src/formatDatetime';

export interface DateTimeFormatterProps extends Omit<PropsWithAs, 'children'> {
  format: DatetimeFormat;
  value: any;
}

const DateTimeFormatter = ({
  as = React.Fragment,
  value,
  format,
  ...props
}: DateTimeFormatterProps) =>
  React.createElement(as, props, formatDatetime(value, format));

export default DateTimeFormatter;
