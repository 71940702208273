import { takeLatest, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { EDIT_BOOKING_ROOM_TYPE } from '../constants';
import { showErrorNotification } from '@aha/utils';
import NProgress from 'nprogress';

function* doEditBookingRoomType({ refCode, bkrid, body, resolve, reject }) {
  yield call(NProgress.start);
  try {
    yield call(
      coreAPI.put,
      `v1/pms/bookings/${refCode}/booking-rooms/${bkrid}/roomtype`,
      body,
    );
    resolve();
  } catch (err) {
    showErrorNotification('Fail to edit booking room type', err);
    reject();
  }
  yield call(NProgress.done);
}

export function* watchEditBookingRoomType() {
  yield takeLatest(EDIT_BOOKING_ROOM_TYPE, doEditBookingRoomType);
}
