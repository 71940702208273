import { put, takeEvery, call } from 'redux-saga/effects';
import { editCurrencySuccess } from '../actions';
import ActionTypes from '../constants';
import { coreAPI } from 'utils/request';
import { showErrorNotification } from '@aha/utils';
import NProgress from 'nprogress';
import { ExtractAction } from 'types/actions';
import { CurrencySettingsActions } from '../type';

export function* doEditCurrency(
  action: ExtractAction<CurrencySettingsActions, ActionTypes.EDIT_CURRENCY>,
) {
  yield call(NProgress.start);
  const {
    payload: { resolve, reject, data },
  } = action;
  const { currencyId, currency } = data || {
    currencyId: '',
    currency: '',
  };
  try {
    const { data } = yield call(
      coreAPI.put,
      `v1/operation/currencies/${currencyId}`,
      currency,
    );
    yield put(editCurrencySuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification('Fail to delete', err);
    reject(err);
  }
  yield call(NProgress.done);
}

export default function* watchEditCurrency() {
  yield takeEvery(ActionTypes.EDIT_CURRENCY, doEditCurrency);
}
