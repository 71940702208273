import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Row, Col } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { createCompany } from '../actions';
import { Modal, Select } from '@aha/ui';
import { Dispatch } from 'redux';
import { FormComponentProps } from 'antd/lib/form';
import { CountryDetail } from 'types/v3-schema';
import { MarketSegment, Company } from 'types/schema';
import { convertVie } from 'utils/app-location';
import useSelectCities from 'containers/Dashboard/useSelectCities';
import NProgress from 'nprogress';
import { coreAPI } from 'utils/request';
import { showErrorNotification } from '@aha/utils';
import { DistrictProps } from 'containers/Dashboard/type';
import { useInjectSaga } from 'utils/injectSaga';
import saga from '../saga';

interface CreateCompanyModalProps extends FormComponentProps {
  currentHotelId?: number;
  createCompany: (id: number, submit: Company) => void;
  mkSourceItems?: MarketSegment[];
  visible?: boolean;
  onClose: () => void;
  countries?: CountryDetail[];
}

const CreateCompanyModal = ({
  createCompany,
  mkSourceItems,
  currentHotelId,
  visible,
  onClose,
  form,
  countries,
}: CreateCompanyModalProps) => {
  useInjectSaga({ key: 'company', saga });

  const { cities } = useSelectCities();
  const {
    getFieldDecorator,
    validateFields,
    resetFields,
    setFieldsValue,
    getFieldValue,
  } = form;

  const [submitting, setSubmitting] = useState(false);
  const [districts, setDistricts] = useState<DistrictProps[] | null>(null);
  const cityId = getFieldValue('cityId');
  const intl = useIntl();

  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    async function fetchDistricts(cityId: number) {
      NProgress.start();
      try {
        const { data } = await coreAPI.get(
          `/v1/pms/shared/cities/${cityId}/districts`,
        );

        setDistricts(data);
      } catch (err) {
        showErrorNotification('Fetch districts fail', err);
        setDistricts(null);
      } finally {
        NProgress.done();
      }
    }

    if (cityId) {
      fetchDistricts(cityId);
    }

    return () => setDistricts(null);
  }, [cityId]); // eslint-disable-line

  function onCancel() {
    if (submitting) {
      return;
    }
    resetFields();
    onClose();
  }

  function onCreate() {
    validateFields(async (err: Error, values: Company) => {
      if (err) {
        console.log('Received values of form: ', values);
        return;
      }
      const submitValues = { ...values, name: values.name?.trim() };
      try {
        setSubmitting(true);
        await createCompany(currentHotelId as number, submitValues);

        resetFields();
        onClose();
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    });
  }

  return (
    <Modal
      visible={visible}
      title={
        <FormattedMessage
          defaultMessage="Create a new company"
          id="settings.label.create_company"
        />
      }
      submitText={
        <FormattedMessage defaultMessage="Create" id="common.action.create" />
      }
      submitting={submitting}
      onCancel={onCancel}
      onSubmit={onCreate}
      width={700}
    >
      <Form layout="vertical">
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Name"
                  id="settings.label.name"
                />
              }
            >
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                  {
                    whitespace: true,
                    message: (
                      <FormattedMessage
                        id="common.message.invalid"
                        defaultMessage="Invalid"
                      />
                    ),
                  },
                ],
              })(
                <Input
                  allowClear
                  placeholder={intl.formatMessage({
                    id: 'form.label.companyname',
                    defaultMessage: 'Enter company name',
                  })}
                />,
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Email"
                  id="settings.label.email"
                />
              }
            >
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: (
                      <FormattedMessage
                        id="common.message.invalid"
                        defaultMessage="Invalid"
                      />
                    ),
                  },
                ],
              })(
                <Input
                  allowClear
                  placeholder={intl.formatMessage({
                    id: 'form.label.email',
                    defaultMessage: 'Enter email',
                  })}
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Phone Number"
                  id="settings.label.phone"
                />
              }
            >
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                  {
                    validator: (rule: any, value, cb: any) =>
                      value && isNaN(value)
                        ? cb(
                            <FormattedMessage
                              id="common.message.invalid"
                              defaultMessage="Invalid"
                            />,
                          )
                        : cb(),
                  },
                ],
              })(
                <Input
                  allowClear
                  placeholder={intl.formatMessage({
                    id: 'form.label.phone',
                    defaultMessage: 'Enter phone number',
                  })}
                />,
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Contact name"
                  id="settings.label.contact_name"
                />
              }
            >
              {getFieldDecorator('contactName')(
                <Input
                  allowClear
                  placeholder={intl.formatMessage({
                    id: 'form.label.contactname',
                    defaultMessage: 'Enter contact name',
                  })}
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Country"
                  id="settings.label.country"
                />
              }
            >
              {getFieldDecorator('country')(
                <Select
                  allowClear
                  showSearch
                  placeholder={intl.formatMessage({
                    id: 'reports.label.select',
                    defaultMessage: 'Select',
                  })}
                  filterOption={(input, option) =>
                    convertVie(
                      (option.props.children || '').toString().toLowerCase(),
                    ).indexOf(convertVie(input.toLowerCase())) >= 0
                  }
                >
                  {countries?.map((c: CountryDetail) => (
                    <Select.Option value={c.code} key={c.code}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Address"
                  id="settings.label.address"
                />
              }
            >
              {getFieldDecorator('address')(
                <Input
                  allowClear
                  placeholder={intl.formatMessage({
                    id: 'form.label.address',
                    defaultMessage: 'Enter address',
                  })}
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="City"
                  id="settings.label.city"
                />
              }
            >
              {getFieldDecorator('cityId', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
              })(
                <Select
                  placeholder={
                    <FormattedMessage
                      defaultMessage="Select city"
                      id="form.label.select_city"
                    />
                  }
                  showSearch
                  filterOption={(input, option) =>
                    convertVie(
                      (option.props.children || '').toString().toLowerCase(),
                    ).indexOf(convertVie(input.toLowerCase())) >= 0
                  }
                  onChange={() => setFieldsValue({ districtId: undefined })}
                  className="w-full"
                  disabled={!cities}
                >
                  {(cities || []).map((c) => (
                    <Select.Option key={c.id} value={c.id}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="District"
                  id="settings.label.district"
                />
              }
            >
              {getFieldDecorator('districtId', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
              })(
                <Select
                  placeholder={
                    <FormattedMessage
                      defaultMessage="Select district"
                      id="form.label.select_district"
                    />
                  }
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    convertVie(
                      (option.props.children || '').toString().toLowerCase(),
                    ).indexOf(convertVie(input.toLowerCase())) >= 0
                  }
                  className="w-full"
                  disabled={!districts}
                >
                  {districts?.map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Segment Code"
                  id="settings.label.marketing_sources"
                />
              }
            >
              {getFieldDecorator('marketingSources', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
              })(
                <Select
                  mode="multiple"
                  placeholder={intl.formatMessage({
                    id: 'reports.label.selectSegmet_code',
                    defaultMessage: 'Select market segment code',
                  })}
                >
                  {mkSourceItems?.map((c) => (
                    <Select.Option key={c.id} value={c.id}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Tax Identification Number"
                  id="settings.label.taxIdentificationNumber"
                />
              }
            >
              {getFieldDecorator('taxIdNumber', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({
                    id: 'settings.label.enterTaxIdentificationNumber',
                    defaultMessage: 'Enter tax identification number',
                  })}
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    createCompany: (hotelId: number, company: Company) =>
      new Promise((resolve, reject) =>
        dispatch(
          createCompany({ resolve, reject, data: { hotelId, company } }),
        ),
      ),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(
  // FIXME: v4-shema migration
  // @ts-ignore
  Form.create<CreateCompanyModalProps>({ name: 'create_company_form' })(
    CreateCompanyModal,
  ),
);
