import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'antd';
import {
  makeSelectIsLogin,
  makeSelectUser,
  makeSelectUserError,
} from 'containers/Auth/selectors';
import {
  makeSelectCurrentHotelId,
  makeSelectHotelSwitching,
} from 'containers/Dashboard/selectors';
import { setCurrentHotelId } from 'containers/Dashboard/actions';
import authSaga from 'containers/Auth/saga';
import { useInjectSaga } from 'utils/injectSaga';
import { createStructuredSelector } from 'reselect';
import { Redirect } from '@reach/router';
import { fetchUser } from 'containers/Auth/actions';
import dashboardSaga from 'containers/Dashboard/saga';
import DashboardActionTypes from 'containers/Dashboard/constants';
import LoginLayout from 'components/LoginLayout';
import ROUTES from 'constants/routes';
import { InputSearch } from '@aha/ui';
import { Skeleton } from '@uxui/skeleton-loader';
import { fetchHotels } from 'containers/HotelsPage/actions';
import HotelActionTypes from 'containers/HotelsPage/constants';
import hotelSaga from 'containers/HotelsPage/saga';
import hotelsReducer from 'containers/HotelsPage/reducer';
import { useInjectReducer } from 'utils/injectReducer';
import {
  makeSelectActiveHotels,
  makeSelectLoading,
} from 'containers/HotelsPage/selectors';
import { FormattedMessage } from 'react-intl';
import { ApplicationRootState } from 'types/app';
import { Hotel, AuthMe } from 'types/schema';
import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import tw from 'twin.macro';

const SearchContainer = styled.div`
  input[type='text'] {
    font-size: 15px;
  }
`;

const SearchResultContainer = styled.div`
  ${tw`rounded-sm border border-grey bg-white px-3`}

  overflow-x: hidden;
  overflow-y: auto;
  max-height: 260px;

  a:last-of-type {
    border-bottom: none !important;
  }
`;

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    isLogin: boolean;
    user: AuthMe | null;
    error: Error | null;
    currentHotelId: number | null;
    activeHotels: Hotel[];
    isLoadingHotels: boolean;
    isSwitchingHotel: boolean;
  }
>({
  isLogin: makeSelectIsLogin(),
  user: makeSelectUser(),
  error: makeSelectUserError(),
  currentHotelId: makeSelectCurrentHotelId(),
  activeHotels: makeSelectActiveHotels(),
  isLoadingHotels: makeSelectLoading(),
  isSwitchingHotel: makeSelectHotelSwitching(),
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dofetchUser: () => dispatch(fetchUser()),
    doFetchHotels: () => dispatch(fetchHotels()),
    setCurrentHotelId: (hotelId: number) =>
      dispatch(setCurrentHotelId(hotelId)),
  };
};

const SelectHotelPage = () => {
  useInjectSaga({ key: 'auth', saga: authSaga });
  useInjectSaga({
    key: DashboardActionTypes.DASHBOARD_KEY,
    saga: dashboardSaga,
  });
  useInjectReducer({
    key: HotelActionTypes.HOTELS_KEY,
    reducer: hotelsReducer,
  });
  useInjectSaga({ key: HotelActionTypes.HOTELS_KEY, saga: hotelSaga });

  const {
    isLogin,
    user,
    error,
    currentHotelId,
    activeHotels,
    isLoadingHotels,
    isSwitchingHotel,
  } = useSelector(mapStateToProps);

  const dispatch = useDispatch();
  const { doFetchHotels, dofetchUser, setCurrentHotelId } = mapDispatchToProps(
    dispatch,
  );

  const [hotels, setHotels] = useState(activeHotels || []);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    doFetchHotels();
  }, []); // eslint-disable-line

  useEffect(() => {
    setHotels(activeHotels);
    // auto select hotel
    if (activeHotels.length === 1) {
      setCurrentHotelId(activeHotels[0]?.id as number);
    }
  }, [activeHotels]); // eslint-disable-line

  useEffect(() => {
    dofetchUser();
  }, [currentHotelId]); // eslint-disable-line

  if (!isLogin) {
    return <Redirect to={ROUTES.LOGIN} noThrow />;
  }

  if (currentHotelId && user) {
    return <Redirect to={ROUTES.DASHBOARD} noThrow />;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
    setHotels(
      activeHotels.filter(({ name }) =>
        name?.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  };

  const fetching = isLoadingHotels || isSwitchingHotel;

  return (
    <LoginLayout>
      <h3 className="text-lg text-secondary leading-none font-semibold mb-3 mt-16">
        <FormattedMessage
          id="common.label.selectHotel"
          defaultMessage="Choose your hotel"
        />
      </h3>
      <div>
        {fetching ? (
          <div className="py-4 pr-4">
            {[1, 2, 3, 4, 5].map((i) => (
              <Skeleton
                width={`${100 - Math.random() * 40}%`}
                height="16px"
                className="mb-2"
                key={i}
              />
            ))}
          </div>
        ) : error ? (
          <div className="p-10 text-center">
            <Alert
              type="error"
              message="Network error"
              className="mb-4"
              showIcon
            />
            <Button size="large" onClick={() => doFetchHotels()}>
              Retry
            </Button>
          </div>
        ) : (
          <SearchContainer>
            <InputSearch
              placeholder="Search for hotel..."
              autoFocus
              onChange={handleChange}
              size="large"
              value={searchValue}
              className="w-full mb-2"
            />
            <SearchResultContainer>
              {hotels.length === 0 && searchValue ? (
                <div className="py-2">No result was found</div>
              ) : (
                hotels.map(({ id, name }) => (
                  <a
                    href="/"
                    className="block py-2 border-b border-grey-lighter"
                    onClick={(e) => {
                      e.preventDefault();
                      if (id) {
                        setCurrentHotelId(id);
                      }
                    }}
                    key={id}
                  >
                    {name}
                  </a>
                ))
              )}
            </SearchResultContainer>
          </SearchContainer>
        )}
      </div>
    </LoginLayout>
  );
};

export default SelectHotelPage;
