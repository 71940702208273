import React, { useState } from 'react';
import LoginLayout from 'components/LoginLayout';
import { FormInput, Button } from '@aha/ui';
import { Form } from 'react-final-form';
import { isEmail } from 'validator';
import { ReactComponent as Illustration } from '../../svg/registered.svg';
import tw from 'twin.macro';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import ROUTES from 'constants/routes';
import { HttpForgotPasswordRequest } from 'types/v3-schema';
import { showErrorNotification } from '@aha/utils';
import { coreAPI } from 'utils/request';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeSelectEmailResetPassword,
  makeSelectResetSuccess,
} from 'containers/Auth/selectors';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { resetPasswordSuccess } from 'containers/Auth/actions';
import { Dispatch } from 'redux';

const Container = styled.div`
  ${tw`flex flex-col justify-between`}
`;

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    resetSuccess: boolean;
    emailResetPassword: string;
  }
>({
  resetSuccess: makeSelectResetSuccess(),
  emailResetPassword: makeSelectEmailResetPassword(),
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    doResetSuccess: (success: boolean, email: string) =>
      dispatch(resetPasswordSuccess(success, email)),
  };
};

export default function ForgotPassword() {
  const [submiting, setSubmiting] = useState(false);
  const dispatch = useDispatch();
  const { resetSuccess, emailResetPassword } = useSelector(mapStateToProps);
  const { doResetSuccess } = mapDispatchToProps(dispatch);
  const intl = useIntl();
  async function submitForm(values: Partial<HttpForgotPasswordRequest>) {
    setSubmiting(true);
    try {
      await coreAPI.post('/v1/pms/auth/forgot-password', values);
      doResetSuccess(true, values.email as string);
    } catch (err) {
      showErrorNotification('Can not send email to reset password', err);
    } finally {
      setSubmiting(false);
    }
  }
  const validate = (values: { email: string }) => {
    const errors: {
      email?: string;
    } = {};

    if (!values.email) {
      errors.email = intl.formatMessage({
        defaultMessage: 'Required',
        id: 'common.message.required',
      });
    } else if (!isEmail(values.email)) {
      errors.email = intl.formatMessage({
        defaultMessage: 'Invalid',
        id: 'common.message.invalid',
      });
    }
    return errors;
  };

  return (
    <LoginLayout>
      <div className="pt-24 w-full">
        {resetSuccess ? (
          <Container>
            <div className="mb-5 text-center">
              <Illustration />
            </div>

            <p className="text-center text-sm text-secondary mb-4 font-medium mx-5">
              <FormattedMessage
                defaultMessage="We have sent you a reset password link on your registered email address - <a>{email}</a>"
                id="auth.message.resetPasswordConfirm"
                values={{
                  email: emailResetPassword,
                  a: (element: any) => {
                    return <span className="text-primary">{element}</span>;
                  },
                }}
              />
            </p>

            <div className="flex justify-center mt-6">
              <Button
                color="primary"
                size="large"
                className="block w-full font-medium text-base normal-case"
                onClick={() => {
                  doResetSuccess(false, '');
                  navigate(ROUTES.LOGIN);
                }}
              >
                <FormattedMessage
                  defaultMessage="Back to login"
                  id="auth.actions.back_to_login"
                />
              </Button>
            </div>
          </Container>
        ) : (
          <Form
            validate={validate}
            onSubmit={submitForm}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="mt-10">
                <h3 className="text-2xl text-secondary leading-none font-semibold mb-5 ">
                  <FormattedMessage
                    defaultMessage="Reset password"
                    id="auth.resetPassword"
                  />
                </h3>
                <p className="mb-8">
                  <FormattedMessage
                    defaultMessage="Please enter your email address. We will send you an email to reset your password."
                    id="auth.message.resetPasswordRequired"
                  />
                </p>
                <FormInput
                  name="email"
                  placeholder="Email"
                  data-testid="forgot-password-email"
                />
                <div className="flex justify-center mt-6 ">
                  <Button
                    color="primary"
                    htmlType="submit"
                    size="large"
                    className="block font-medium text-base w-full"
                    data-testid="forgot-password-submit"
                    disabled={submiting}
                  >
                    <FormattedMessage
                      defaultMessage="Reset"
                      id="auth.actions.reset"
                    />
                  </Button>
                </div>
              </form>
            )}
          />
        )}
      </div>
    </LoginLayout>
  );
}
