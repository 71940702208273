import moment from 'moment';
import { takeLatest, put, call, all } from 'redux-saga/effects';
import {
  fetchBookingSuccess,
  fetchBookingFail,
  fetchBookingListSuccess,
  fetchBookingListFail,
  fetchCheckedInBookingsSuccess,
  fetchCheckedInBookingsFail,
  fetchCheckedOutBookingsSuccess,
  fetchCheckedOutBookingsFail,
  fetchInHouseGuestListSuccess,
  fetchInHouseGuestListFail,
  fetchBookingChangeLogsSuccess,
  fetchBookingChangeLogsFail,
  fetchArrivalBookingsFail,
  fetchArrivalBookingsSuccess,
  fetchDepartureBookingsSuccess,
  fetchDepartureBookingsFail,
  fetchExpiredBookingsSuccess,
} from '../actions';
import { coreAPI } from 'utils/request';
import {
  FETCH_BOOKING,
  FETCH_EXPECTING_BOOKING_LIST,
  FETCH_CHECKED_IN_BOOKINGS,
  FETCH_CHECKED_OUT_BOOKINGS,
  FETCH_INHOUSE_GUEST_LIST,
  FETCH_BOOKING_HISTORY,
  FETCH_ARRIVAL_BOOKINGS,
  FETCH_DEPARTURE_BOOKINGS,
  FETCH_EXPIRED_BOOKINGS,
} from '../constants';
import { showErrorNotification } from '@aha/utils';
import NProgress from 'nprogress';

export function* doFetchBooking({ bid }) {
  yield call(NProgress.start);
  try {
    const { data: bk } = yield call(coreAPI.get, `v1/pms/bookings/${bid}`);
    yield put(fetchBookingSuccess(bk));
  } catch (err) {
    showErrorNotification('Fetch booking failed!', err);
    yield put(fetchBookingFail(err));
  }
  yield call(NProgress.done);
}

export function* watchFetchBooking() {
  yield takeLatest(FETCH_BOOKING, doFetchBooking);
}

export function* doFetchExpectedCheckinBookings({ hid, time, bkType }) {
  yield call(NProgress.start);
  try {
    const { data: bk } = yield call(
      coreAPI.get,
      `v1/pms/bookings?booking_type=${bkType}&end_date=${moment(
        time,
      ).toISOString()}`,
    );
    yield put(fetchBookingListSuccess(bk));
  } catch (error) {
    yield put(fetchBookingListFail(error));
  }
  yield call(NProgress.done);
}

export function* watchFetchExpectedBookings() {
  yield takeLatest(
    FETCH_EXPECTING_BOOKING_LIST,
    doFetchExpectedCheckinBookings,
  );
}

export function* doFetchCheckedInBookings({ hid, time, bkType = 'daily' }) {
  yield call(NProgress.start);
  try {
    const { data: bk } = yield call(
      coreAPI.get,
      `/v1/pms/bookings?booking_type=${bkType}&type=inhouse&end_date=${moment(
        time,
      ).toISOString()}`,
    );
    yield put(fetchCheckedInBookingsSuccess(bk));
  } catch (error) {
    yield put(fetchCheckedInBookingsFail(error));
  }
  yield call(NProgress.done);
}

export function* watchFetchCheckedInBookings() {
  yield takeLatest(FETCH_CHECKED_IN_BOOKINGS, doFetchCheckedInBookings);
}

export function* doFetchExpiredBookings({
  arrival,
  departure,
  pageNumber = 1,
  pageSize = 10,
}) {
  yield call(NProgress.start);
  try {
    const { data, ...pagination } = yield call(
      coreAPI.get,
      `/v1/pms/bookings?booking_type=daily&type=arrival_expired&start_date=${arrival}&end_date=${departure}&page=${pageNumber}&page_size=${pageSize}`,
    );
    yield put(fetchExpiredBookingsSuccess(data, pagination.metadata));
  } catch (error) {}
  yield call(NProgress.done);
}

export function* watchFetchExpiredBookings() {
  yield takeLatest(FETCH_EXPIRED_BOOKINGS, doFetchExpiredBookings);
}

export function* doFetchCheckedOutBookings({ hid, time, bkType }) {
  yield call(NProgress.start);
  try {
    const { data: bk } = yield call(
      coreAPI.get,
      `v1/pms/bookings?booking_type=checked_out&end_date=${moment(
        time,
      ).toISOString()}`,
    );
    yield put(fetchCheckedOutBookingsSuccess(bk));
  } catch (error) {
    yield put(fetchCheckedOutBookingsFail(error));
  }
  yield call(NProgress.done);
}

export function* watchFetchCheckedOutBookings() {
  yield takeLatest(FETCH_CHECKED_OUT_BOOKINGS, doFetchCheckedOutBookings);
}

export function* doFetchArrivalBooking({
  hid,
  expDate: [expArr, expDep],
  upDate: [upArr, upDep],
  bkType = 'daily',
  pageNumber = 1,
  pageSize = 10,
}) {
  yield call(NProgress.start);
  try {
    const [
      { data: checkinBooking, ...checkinPagination },
      { data: expiredBks, ...expiredPagination },
      { data: upcomingBks, ...upcomingPagination },
      { data: todayCheckin, ...todayExpectedPagination },
    ] = yield all([
      call(
        coreAPI.get,
        `/v1/pms/bookings?booking_type=${bkType}&type=arrival_today_checkedin&end_date=${moment().toISOString()}&page=${pageNumber}&page_size=${pageSize}`,
      ),
      call(
        coreAPI.get,
        `/v1/pms/bookings?booking_type=${bkType}&type=arrival_expired&start_date=${expArr}&end_date=${expDep}&page=${pageNumber}&page_size=${pageSize}`,
      ),
      call(
        coreAPI.get,
        `/v1/pms/bookings?booking_type=${bkType}&type=arrival_upcoming&start_date=${upArr}&end_date=${upDep}&page=${pageNumber}&page_size=${pageSize}`,
      ),
      call(
        coreAPI.get,
        `/v1/pms/bookings?booking_type=${bkType}&type=arrival_today_checkin&end_date=${moment().toISOString()}&page=${pageNumber}&page_size=${pageSize}`,
      ),
    ]);

    yield put(
      fetchArrivalBookingsSuccess(
        checkinBooking,
        checkinPagination.metadata,
        todayCheckin,
        todayExpectedPagination.metadata,
        expiredBks,
        expiredPagination.metadata,
        upcomingBks,
        upcomingPagination.metadata,
      ),
    );
  } catch (error) {
    yield put(fetchArrivalBookingsFail(error));
  }
  yield call(NProgress.done);
}

export function* watchFetchArrivalBooking() {
  yield takeLatest(FETCH_ARRIVAL_BOOKINGS, doFetchArrivalBooking);
}

export function* doFetchDepartureBooking({
  hid,
  bkType = 'daily',
  expDate: [expArr, expDep],
  upDate: [upArr, upDep],
  pageNumber = 1,
  pageSize = 10,
}) {
  yield call(NProgress.start);
  try {
    const [
      { data: checkoutBookings, ...checkoutPagination },
      { data: expiredBks, ...expiredPagination },
      { data: upcomingBks, ...upcomingPagination },
      { data: todayCheckoutBookings, ...todayCheckoutPagination },
    ] = yield all([
      call(
        coreAPI.get,
        `v1/pms/bookings?booking_type=${bkType}&type=departure_checked_out&end_date=${moment().toISOString()}&page=${pageNumber}&page_size=${pageSize}`,
      ),
      call(
        coreAPI.get,
        `v1/pms/bookings?booking_type=${bkType}&type=departure_expired&end_date=${expDep}&page=${pageNumber}&page_size=${pageSize}`,
      ),
      call(
        coreAPI.get,
        `v1/pms/bookings?booking_type=${bkType}&type=departure_upcoming&start_date=${upArr}&end_date=${upDep}&page=${pageNumber}&page_size=${pageSize}`,
      ),
      call(
        coreAPI.get,
        `v1/pms/bookings?booking_type=${bkType}&type=departure_today_checkout&end_date=${moment().toISOString()}&page=${pageNumber}&page_size=${pageSize}`,
      ),
    ]);

    yield put(
      fetchDepartureBookingsSuccess(
        checkoutBookings,
        checkoutPagination.metadata,
        todayCheckoutBookings,
        todayCheckoutPagination.metadata,
        expiredBks,
        expiredPagination.metadata,
        upcomingBks,
        upcomingPagination.metadata,
      ),
    );
  } catch (error) {
    yield put(fetchDepartureBookingsFail(error));
  }
  yield call(NProgress.done);
}

export function* watchFetchDepartureBooking() {
  yield takeLatest(FETCH_DEPARTURE_BOOKINGS, doFetchDepartureBooking);
}

export function* doFetchInhouseGuestList({
  hid,
  bkType = 'daily',
  pageNumber = 1,
  pageSize = 10,
}) {
  yield call(NProgress.start);
  try {
    const { data, ...pagination } = yield call(
      coreAPI.get,
      `v1/pms/bookings?booking_type=${bkType}&type=inhouse&end_date=${moment().toISOString()}&page=${pageNumber}&page_size=${pageSize}`,
    );
    yield put(fetchInHouseGuestListSuccess(data, pagination.metadata));
  } catch (error) {
    yield put(fetchInHouseGuestListFail(error));
  }
  yield call(NProgress.done);
}

export function* watchInHouseBookings() {
  yield takeLatest(FETCH_INHOUSE_GUEST_LIST, doFetchInhouseGuestList);
}

export function* doFetchHistory({ bid }) {
  yield call(NProgress.start);
  try {
    const { data: bk } = yield call(coreAPI.get, `v1/pms/bookings/${bid}/logs`);
    yield put(fetchBookingChangeLogsSuccess(bk));
  } catch (error) {
    yield put(fetchBookingChangeLogsFail(error));
  }
  yield call(NProgress.done);
}

export function* watchFetchBookingChangeLogs() {
  yield takeLatest(FETCH_BOOKING_HISTORY, doFetchHistory);
}
