import { PropsWithAs } from 'components/Box';
import React from 'react';
import { NOT_AVAILABLE } from '@aha/constants';

export interface NAFallbackProps extends Omit<PropsWithAs, 'children'> {
  value?: string;
}

const NAFallback = ({
  as = React.Fragment,
  value,
  ...props
}: NAFallbackProps) => React.createElement(as, props, value || NOT_AVAILABLE);

export default NAFallback;
