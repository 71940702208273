enum ActionTypes {
  FETCH_ROOM_TYPES = 'roomTypeSettings/FETCH_ROOM_TYPES',
  FETCH_ROOM_TYPES_SUCCESS = 'roomTypeSettings/FETCH_ROOM_TYPES_SUCCESS',
  FETCH_ROOM_TYPES_FAIL = 'roomTypeSettings/FETCH_ROOM_TYPES_FAIL',

  RESET_ROOM_TYPES_STATE = 'roomTypeSettings/RESET_ROOM_TYPES_STATE',

  CREATE_SYNC_PMS = 'roomTypeSettings/CREATE_SYNC_PMS',
  CREATE_SYNC_PMS_SUCCESS = 'roomTypeSettings/CREATE_SYNC_PMS_SUCCESS',
  CREATE_SYNC_PMS_FAIL = 'roomTypeSettings/CREATE_SYNC_PMS_FAIL',

  DELETE_ROOM_TYPE = 'roomTypeSettings/DELETE_ROOM_TYPE',
  DELETE_ROOM_TYPE_SUCCESS = 'roomTypeSettings/DELETE_ROOM_TYPE_SUCCESS',
  DELETE_ROOM_TYPE_FAIL = 'roomTypeSettings/DELETE_ROOM_TYPE_FAIL',

  CREATE_ROOM_TYPE = 'roomTypeSettings/CREATE_ROOM_TYPE',
  CREATE_ROOM_TYPE_SUCCESS = 'roomTypeSettings/CREATE_ROOM_TYPE_SUCCESS',
  CREATE_ROOM_TYPE_FAIL = 'roomTypeSettings/CREATE_ROOM_TYPE_FAIL',

  EDIT_ROOM_TYPE = 'roomTypeSettings/EDIT_ROOM_TYPE',
  EDIT_ROOM_TYPE_SUCCESS = 'roomTypeSettings/EDIT_ROOM_TYPE_SUCCESS',
  EDIT_ROOM_TYPE_FAIL = 'roomTypeSettings/EDIT_ROOM_TYPE_FAIL',

  EDIT_ROOM_TYPE_HOURLY_SETTING = 'roomTypeSettings/EDIT_ROOM_TYPE_HOURLY_SETTING',
  EDIT_ROOM_TYPE_HOURLY_SETTING_SUCCESS = 'roomTypeSettings/EDIT_ROOM_TYPE_HOURLY_SETTING_SUCCESS',
  EDIT_ROOM_TYPE_HOURLY_SETTING_FAIL = 'roomTypeSettings/EDIT_ROOM_TYPE_HOURLY_SETTING_FAIL',
}

export default ActionTypes;
