import React from 'react';
import classnames from 'classnames';

export interface TextBlockProps {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  titleClassName?: string;
  subTitleClassName?: string;
  untruncated?: boolean;
}

export function TextBlock({
  title,
  subTitle,
  titleClassName = '',
  subTitleClassName = '',
  untruncated = false,
}: TextBlockProps) {
  return (
    <div
      className={classnames('flex flex-col justify-center h-14', {
        'h-16': !!subTitle,
      })}
    >
      <div
        className={classnames('text-sm text-secondary', {
          [titleClassName]: titleClassName,
          truncate: !untruncated,
        })}
      >
        {title}
      </div>
      <div
        className={classnames(
          'text-grey-darker font-medium uppercase truncate text-2xs',
          {
            [subTitleClassName]: subTitleClassName,
          },
        )}
      >
        {subTitle}
      </div>
    </div>
  );
}

export default TextBlock;
