import { takeLatest, put, call } from 'redux-saga/effects';

import {
  fetchChargeTemplatesSuccess,
  fetchChargeTemplatesFail,
} from '../actions';
import { coreAPI } from 'utils/request';
import { FETCH_CHARGE_TEMPLATES } from '../constants';
import NProgress from 'nprogress';
import { showErrorNotification } from '@aha/utils';

function* doFetch({ hid }) {
  yield call(NProgress.start);
  try {
    const { data } = yield call(coreAPI.get, `v1/pms/charge-templates`);

    yield put(fetchChargeTemplatesSuccess(data));
  } catch (err) {
    showErrorNotification('Fail to fetch', err);
    yield put(fetchChargeTemplatesFail(err));
  }
  yield call(NProgress.done);
}

export function* watchFetchChargeTemplates() {
  yield takeLatest(FETCH_CHARGE_TEMPLATES, doFetch);
}
