export default function removeAccents(str: string) {
  let returnStr = str;
  // remove accents
  const from =
    'àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçỳýỵỹỷ';

  const to =
    'aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy';

  for (let i = 0, l = from.length; i < l; i++) {
    returnStr = returnStr.replace(RegExp(from[i], 'gi'), to[i]);
  }

  returnStr = returnStr.toLowerCase().trim();

  return returnStr;
}
