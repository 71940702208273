import React from 'react';
import { List as AndList } from 'antd';
import { NoDataBlock } from '@aha/ui';
import { ListProps } from 'antd/lib/list';

const locale = { emptyText: <NoDataBlock className="p-20" /> };

export function List<T>({ children, ...props }: ListProps<T>) {
  return (
    <AndList<T> {...props} locale={locale}>
      {children}
    </AndList>
  );
}

export default List;

List.Item = AndList.Item;
