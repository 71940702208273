import React from 'react';
import Exception from 'components/Exception';
import { FormattedMessage } from 'react-intl';

export default function MaintenancePage() {
  return (
    <Exception
      message={
        <FormattedMessage
          defaultMessage="Server has been undergoing maintenance"
          id="exception.maintenance.message"
        />
      }
      titleButton={
        <FormattedMessage
          defaultMessage="Contact support"
          id="common.action.contactSupport"
        />
      }
      onClick={() => {
        const win = window.open(
          'https://www.facebook.com/AHARooms-PMS-Support-115537940354295',
          '_blank',
        );
        win && win.focus();
      }}
    />
  );
}
