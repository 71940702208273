import { takeLatest, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { GROUP_FOLIOS } from '../constants';
import { showErrorNotification } from '@aha/utils';
import NProgress from 'nprogress';

function* doGroupFolios({ bid, fid, body, resolve, reject }) {
  yield call(NProgress.start);
  try {
    yield call(
      coreAPI.post,
      `v1/pms/bookings/${bid}/folios/${fid}/group`,
      body,
    );
    resolve();
  } catch (err) {
    showErrorNotification('Fail to group folios', err);
    reject();
  }
  yield call(NProgress.done);
}

export function* watchGroupFolios() {
  yield takeLatest(GROUP_FOLIOS, doGroupFolios);
}
