import ActionTypes from './constants';
import { action } from 'typesafe-actions';
import { CountryDetail } from 'types/v3-schema';
import { Warehousing } from 'types/schema';

export const fetchCountries = () => action(ActionTypes.FETCH_COUNTRIES);

export const fetchCountriesSuccess = (countries: CountryDetail[]) =>
  action(ActionTypes.FETCH_COUNTRIES_SUCCESS, countries);

export const fetchChargeTemplates = (hid?: string) =>
  action(ActionTypes.FETCH_CHARGE_TEMPLATE_LIST, hid);

export const fetchChargeTemplatesSuccess = (chargeTemplates: Warehousing[]) =>
  action(ActionTypes.FETCH_CHARGE_TEMPLATE_LIST_SUCCESS, chargeTemplates);

export const setCompanyBookingID = (companyID: string) => {
  return action(ActionTypes.SET_COMPANY_BOOKING_ID, companyID);
};
