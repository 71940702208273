import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { createAntField } from '../CreateAntField';
import { Field } from 'react-final-form';

// @ts-ignore
const FormInputWrapper = createAntField<InputProps>(Input);

export interface FormInputProps extends InputProps {
  name: string;
  label?: string | React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

const FormInput = (props: FormInputProps) => {
  return <Field {...props} component={FormInputWrapper} typing />;
};

export default FormInput;
