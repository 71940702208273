import { put, takeLatest, call, all } from 'redux-saga/effects';
import { switchCurrentHotelSuccess, switchCurrentHotelFail } from '../actions';
import ActionTypes from '../constants';
import { coreAPI, setToken, setSelectedHotel } from 'utils/request';
import { showErrorNotification } from '@aha/utils';
import NProgress from 'nprogress';
import { DashboardActions } from '../type';
import { ExtractAction } from 'types/actions';

export function* doSwitchCurrentHotel(
  action: ExtractAction<DashboardActions, ActionTypes.SWITCH_CURRENT_HOTEL>,
) {
  const { hotelId } = action.payload;

  yield call(NProgress.start);
  try {
    const { token } = yield call(coreAPI.post, '/v1/pms/auth/select-hotel', {
      hotelId,
    });
    yield call(setToken, token);

    const [{ data: hotel }, { data: currencies }] = yield all([
      call(coreAPI.get, `v1/pms/hotels/${hotelId}`),
      call(coreAPI.get, `v1/pms/hotels/${hotelId}/currencies`),
    ]);

    if (hotelId) {
      yield call(setSelectedHotel, hotelId);
      yield put(switchCurrentHotelSuccess({ hotel, currencies, hotelId }));
    }
  } catch (err) {
    showErrorNotification('Fail to switch hotel', err);
    yield put(switchCurrentHotelFail());
  }
  yield call(NProgress.done);
}

export default function* watchFetchCurrentHotel() {
  yield takeLatest(ActionTypes.SWITCH_CURRENT_HOTEL, doSwitchCurrentHotel);
}
