import { put, takeLatest, call } from 'redux-saga/effects';
import { fetchDistrictsSuccess } from '../actions';
import ActionTypes from '../constants';
import { coreAPI } from 'utils/request';
import { showErrorNotification } from '@aha/utils';
import { DashboardActions } from '../type';
import { ExtractAction } from 'types/actions';

export function* doFetchDistricts(
  action: ExtractAction<DashboardActions, ActionTypes.FETCH_DISTRICTS>,
) {
  const { cityId } = action.payload;

  try {
    const { data } = yield call(
      coreAPI.get,
      `/v1/pms/shared/cities/${cityId}/districts`,
    );
    yield put(fetchDistrictsSuccess(data));
  } catch (err) {
    showErrorNotification('Fail to fetch', err);
  }
}

export default function* watchFetchDistricts() {
  yield takeLatest(ActionTypes.FETCH_DISTRICTS, doFetchDistricts);
}
