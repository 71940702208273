import { HOURLY_CHARGE_REPORTS_KEY } from 'containers/HourlyChargeReportPage/constants';
import { HOURLY_PAYMENT_REPORTS_KEY } from 'containers/HourlyPaymentReportPage/constants';
import { BOOKING_KEY } from 'containers/BookingProvider/constants';
import { HOURLY_BOOKING_KEY } from 'containers/HourlyBooking/constants';

export const mockReducers = [
  'currencies',
  'settingChargeTemplate',
  'dashboardHome',
  'lockDoor',
  'users',
  'company',
  'guestSettings',
  'paymentTypes',
  'serviceGroups',
  'marketingSources',
  'marketingChannels',
  HOURLY_CHARGE_REPORTS_KEY,
  HOURLY_CHARGE_REPORTS_KEY,
  HOURLY_PAYMENT_REPORTS_KEY,
  BOOKING_KEY,
  'roomInventory',
  'ratePlans',
  'search',
  HOURLY_BOOKING_KEY,
  'roomTypeSettings',
  'roomSettings',
  'companySettings',
  'createBooking',
].reduce((a, c) => ({ ...a, [c]: (state = {}) => state }), {});
