import React from 'react';
import RangeInput, { RangeInputProps } from '../RangeInput';
import { createAntField } from '../CreateAntField';
import { Field } from 'react-final-form';

// @ts-ignore
const FormRangeInputWrapper = createAntField<RangeInputProps>(RangeInput);

export interface FormInputProps extends RangeInputProps {
  name: string;
  label?: string;
}

const FormRangeInput = (props: FormInputProps) => {
  return <Field {...props} component={FormRangeInputWrapper} />;
};

export default FormRangeInput;
