import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const Container = styled.main<{ hasAlert: boolean; alertHeight: number }>`
  ${tw`font-sans px-4`};
  padding-bottom: 56px;
  min-height: calc(
    100vh -
      ${(props) => (props.hasAlert ? `${props.alertHeight + 56}px` : '56px')}
  );
`;

export interface LayoutHeaderProps {
  className?: string;
  hasAlert?: boolean;
  alertHeight?: number;
}

export const Content: React.FC<LayoutHeaderProps> = ({
  hasAlert = false,
  alertHeight = 55,
  className = '',
  children,
}) => {
  return (
    <Container
      className={className}
      hasAlert={hasAlert}
      alertHeight={alertHeight}
    >
      {children}
    </Container>
  );
};

export default Content;
