import { call, put, takeLatest } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import ActionTypes from '../constants';
import {
  updateRateRestrictionSuccess,
  updateRateRestrictionFail,
} from '../actions';
import { RatePlansActions } from '../types';
import { ExtractAction } from 'types/actions';

function* doUpdateRateRestriction(
  action: ExtractAction<RatePlansActions, ActionTypes.UPDATE_RATE_RESTRICTION>,
) {
  const {
    payload: {
      data: { rateId, formData },
      resolve,
      reject,
    },
  } = action;
  try {
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/operation/rate-plans/${rateId}/restrictions`,
      formData,
    );
    yield put(updateRateRestrictionSuccess(data));
    resolve(data);
  } catch (err) {
    yield put(updateRateRestrictionFail(err));
    reject(err);
  }
}

export default function* watchUpdateRateRestriction() {
  yield takeLatest(
    ActionTypes.UPDATE_RATE_RESTRICTION,
    doUpdateRateRestriction,
  );
}
