import { makeSelectCurrentHotel, ModelHotelWithFeatures } from './selectors';
import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { ApplicationRootState } from 'types/app';

export default function useSelectCurrentHotel() {
  const { hotel } = useSelector<
    ApplicationRootState,
    { hotel: ModelHotelWithFeatures | null }
  >(createStructuredSelector({ hotel: makeSelectCurrentHotel() }));

  return hotel as ModelHotelWithFeatures;
}
