import produce from 'immer';
import ActionTypes from './constants';
import initialStateHolder from 'utils/initialStateHolder';
import { RoomTypesActions, RoomTypesState, ModelRoomTypeSetting } from './type';
import { HourlySetting } from 'types/schema';
import {
  mappingKeys,
  AdditionalHoursType,
} from 'containers/SettingHourlyServicesPage/components/EditHourlyServiceModal';

export const initialState = initialStateHolder.setState('roomTypeSettings', {
  loading: false,
  error: null,
  roomTypes: null,
  syncPmsLoading: false,
});

const roomTypesReducer = (
  state: RoomTypesState = initialState,
  action: RoomTypesActions,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.FETCH_ROOM_TYPES:
        draft.loading = true;
        draft.error = null;
        draft.roomTypes = null;
        break;

      case ActionTypes.FETCH_ROOM_TYPES_SUCCESS:
        draft.loading = false;
        draft.roomTypes = action.payload.filter(
          (roomType) => roomType.status === 'active',
        );
        draft.error = null;
        break;

      case ActionTypes.FETCH_ROOM_TYPES_FAIL:
        draft.error = action.payload;
        break;

      case ActionTypes.RESET_ROOM_TYPES_STATE:
        draft.error = null;
        draft.loading = false;
        draft.roomTypes = null;
        break;

      case ActionTypes.DELETE_ROOM_TYPE:
        break;

      case ActionTypes.DELETE_ROOM_TYPE_SUCCESS:
        draft.roomTypes = (draft.roomTypes || []).filter(
          ({ id }) => id !== action.payload,
        );
        break;

      case ActionTypes.DELETE_ROOM_TYPE_FAIL:
        draft.error = action.payload;
        draft.loading = false;
        break;

      case ActionTypes.CREATE_ROOM_TYPE:
        break;

      case ActionTypes.CREATE_ROOM_TYPE_SUCCESS:
        if (draft.roomTypes) {
          draft.roomTypes.push(action.payload);
        } else {
          draft.roomTypes = [action.payload];
        }
        break;

      case ActionTypes.CREATE_ROOM_TYPE_FAIL:
        draft.error = action.payload;
        draft.loading = false;
        break;

      case ActionTypes.EDIT_ROOM_TYPE:
        break;

      case ActionTypes.EDIT_ROOM_TYPE_SUCCESS:
        const index = (draft.roomTypes || []).findIndex(
          ({ id }) => id === action.payload.id,
        );

        if (Array.isArray(draft.roomTypes) && index !== -1) {
          draft.roomTypes[index] = action.payload;
        }
        break;

      case ActionTypes.EDIT_ROOM_TYPE_FAIL:
        draft.error = action.payload;
        draft.loading = false;
        break;

      case ActionTypes.CREATE_SYNC_PMS:
        draft.syncPmsLoading = true;
        break;

      case ActionTypes.CREATE_SYNC_PMS_SUCCESS:
        const roomTypeMap = new Map<string | undefined, ModelRoomTypeSetting>();
        (draft.roomTypes || []).forEach((item) =>
          roomTypeMap.set(item.id.toString(), { ...item }),
        );

        (action.payload || []).forEach((item) =>
          roomTypeMap.set(item.id.toString(), item),
        );

        let newroomTypes = [];
        for (let value of roomTypeMap.values()) newroomTypes.push(value);
        draft.roomTypes = newroomTypes;
        draft.syncPmsLoading = false;
        break;

      case ActionTypes.CREATE_SYNC_PMS_FAIL:
        draft.syncPmsLoading = false;
        break;

      case ActionTypes.EDIT_ROOM_TYPE_HOURLY_SETTING:
        break;

      case ActionTypes.EDIT_ROOM_TYPE_HOURLY_SETTING_SUCCESS:
        const hourlyIndex = (draft.roomTypes || []).findIndex(
          ({ id }) => id === action.payload.roomTypeId,
        );

        const initialValue = ([
          'secondHour',
          'thirdHour',
          'fourthHour',
          'fifthHour',
          'sixthHour',
          'seventhHour',
        ] as (keyof HourlySetting)[]).reduce((prev, key, index) => {
          const price = action.payload[key];
          if (price) {
            prev[mappingKeys[key]] = +price;
          }
          return prev;
        }, {} as AdditionalHoursType);

        const sortedAdditionalHourPrices = Object.entries(initialValue || [])
          .sort(
            (hour1, hour2) =>
              +hour1[0].replace(/[a-z]/gi, '') -
              +hour2[0].replace(/[a-z]/gi, ''),
          )
          .reduce((res, [k, v]) => ({ ...res, [k]: v }), {});

        if (Array.isArray(draft.roomTypes) && hourlyIndex !== -1) {
          draft.roomTypes[hourlyIndex].hourlySettings = {
            ...action.payload,
            ...sortedAdditionalHourPrices,
          };
        }
        break;

      case ActionTypes.EDIT_ROOM_TYPE_HOURLY_SETTING_FAIL:
        draft.error = action.payload;
        draft.loading = false;
        break;
    }
  });

export default roomTypesReducer;
