export function formatRoute(route, ...args) {
  let index = 0;
  return route
    .split('/')
    .map((r) => (r.includes(':') ? (args[index++] ? args[index - 1] : r) : r))
    .join('/');
}

export function matchRelativePath(pages, pathname) {
  const relativePages = Object.entries(pages).filter(
    ([key, value]) => value.relative,
  );

  const pathnameItems = pathname.split('/');
  for (let [path, value] of relativePages) {
    const pathItems = path.split('/');
    if (
      pathnameItems[1] === pathItems[1] &&
      pathItems.length === pathnameItems.length
    ) {
      return { [pathname]: value };
    }
  }

  return null;
}
