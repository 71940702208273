import React from 'react';
import styled from 'styled-components';
import { Skeleton } from '@uxui/skeleton-loader';
import tw from 'twin.macro';

const Container = styled.div`
  ${tw`p-6 bg-white`}
`;

export default function CardPageSkeleton() {
  return (
    <Container className="boxed">
      <div className="flex items-center justify-between">
        <Skeleton width="71px" height="19px" className="block" />
        <Skeleton width="47px" height="19px" className="block" />
      </div>
      <div className="mt-8" style={{ minHeight: '360px' }}>
        <Skeleton width="180px" height="16px" className="block mb-3" />
        <Skeleton width="220px" height="16px" className="block mb-3" />
        <Skeleton width="200px" height="16px" className="block mb-3" />
        <Skeleton width="120px" height="16px" className="block mb-3" />
      </div>
    </Container>
  );
}
