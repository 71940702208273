import React from 'react';
import Permission from './Permission';
import { Redirect } from '@reach/router';
import ROUTES from 'constants/routes';
import ComponentRoute from 'components/ComponentRoute';

const PrivateRoute = ({ allow, Component, ...rest }) => (
  <Permission allow={allow}>
    {(isAllowed) =>
      isAllowed ? (
        <ComponentRoute Component={Component} {...rest} />
      ) : (
        <Redirect to={ROUTES.NOT_AUTHORIZE} noThrow />
      )
    }
  </Permission>
);

export default PrivateRoute;
