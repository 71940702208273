import _ from 'lodash';

// TODO: considor move this to /utils
export function calcTotalBalance(balance, currencies) {
  let rootCCode = 'VND';
  const objBalance = {
    [balance?.currency]: balance?.amount,
  };
  const crs = _.reduce(
    currencies,
    (fin, value) => {
      if (value.rate?.rate === 1) {
        rootCCode = value.name;
      }
      return { ...fin, [value.name]: value.rate?.rate || 1 };
    },
    {},
  );
  return _.reduce(
    objBalance,
    (fin, total, key) => ({
      ...fin,
      total: fin.total + total * (crs[key] || 1),
    }),
    { total: 0, currency: rootCCode },
  );
}

export function calcTotalAmount(prices = {}, currencies = {}) {
  return _.reduce(
    prices,
    (fin, price, c) => {
      const rate = currencies[c] || 1;
      return {
        total: fin.total + price * rate,
        currency: fin.currency ? fin.currency : c,
      };
    },
    { total: 0, currency: '' },
  );
}
