import { takeEvery, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { ADD_CHILD_BOOKING } from '../constants';
import { showErrorNotification } from '@aha/utils';
import NProgress from 'nprogress';

function* doAddChildBooking({ bid, body, resolve, reject }) {
  yield call(NProgress.start);
  try {
    yield call(coreAPI.post, `v1/pms/bookings/${bid}/booking-rooms`, body);
    resolve();
  } catch (err) {
    showErrorNotification('Fail to create', err);
    reject();
  }
  yield call(NProgress.done);
}

export function* watchAddChildBooking() {
  yield takeEvery(ADD_CHILD_BOOKING, doAddChildBooking);
}
