import React from 'react';
import { Skeleton } from '@uxui/skeleton-loader';
import styled from 'styled-components';
import tw from 'twin.macro';

const Container = styled.div`
  ${tw`rounded-sm border-grey-dark border relative`}
  position: relative;
  padding-bottom: 71.47%;
  height: 0;
`;

interface RoomBlockSkeletonLoaderProps {
  className?: string;
}

export default function RoomBlockSkeletonLoader({
  className = '',
}: RoomBlockSkeletonLoaderProps) {
  return (
    <Container>
      <div className="inset-0 absolute rounded-sm flex items-center justify-center bg-white">
        <Skeleton
          width="37px"
          height="15px"
          className="absolute top-0 left-0"
          baseColor="#ccc"
          highlightColor="#d9d9d9"
          customStyle={{ margin: '6px 0 0 6px' }}
        />
        <div className="flex flex-col items-center">
          <Skeleton
            width="66px"
            height="15px"
            className="mb-2"
            baseColor="#ccc"
            highlightColor="#d9d9d9"
          />
          <Skeleton
            width="106px"
            height="15px"
            baseColor="#eee"
            highlightColor="#f3f3f3"
          />
        </div>
      </div>
    </Container>
  );
}
