export default function formatRoute(
  route: string,
  ...args: (string | number)[]
) {
  let index = 0;
  return route
    .split('/')
    .map((r) => (r.includes(':') ? (args[index++] ? args[index - 1] : r) : r))
    .join('/');
}
