import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, AutoComplete, DatePicker } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectCurrencies,
  makeSelectCurrentHotel,
} from 'containers/Dashboard/selectors';
import {
  fetchChargeTemplates,
  fetchRevGroups,
  addFolioCharge,
} from 'containers/BookingProvider/actions';
import {
  makeSelectChargeTemplates,
  makeSelectRevGroups,
} from 'containers/BookingProvider/selector';
import { useInjectReducer } from 'utils/injectReducer';
import { BOOKING_KEY } from 'containers/BookingProvider/constants';
import { useInjectSaga } from 'utils/injectSaga';
import bookingsReducer from 'containers/BookingProvider/reducer';
import saga from 'containers/BookingProvider/saga';
import { FormattedMessage } from 'react-intl';
import InputNumberFormat from 'components/InputNumberFormat';
import { validRooms } from 'containers/BookingProvider/helpers/folios';
import { Modal, Select } from '@aha/ui';
import { IconCalendarAlt } from '@aha/icons';

export const InnerModal = ({
  onAddCharge,
  getFieldDecorator,
  onSelectChargeTemplate,
  setChargeTemplateFilter,
  filtedCT,
  mode,
  currencies,
  hotel,
  onRevenueGroupChange,
  revGroups,
  revCategories,
  rooms,
  bookingRoomId,
}) => {
  return (
    <Form layout="vertical" onSubmit={onAddCharge}>
      <div className="flex flex-wrap -mx-2">
        <div className="col px-2 w-full md:w-1/3">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="Service Name"
                id="bookings.label.serviceName"
              />
            }
          >
            {getFieldDecorator('chargeTemplate', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="common.message.required"
                      defaultMessage="Required"
                    />
                  ),
                },
                {
                  whitespace: true,
                  message: (
                    <FormattedMessage
                      id="common.message.invalid"
                      defaultMessage="Invalid"
                    />
                  ),
                },
              ],
            })(
              <AutoComplete
                onSelect={onSelectChargeTemplate}
                onSearch={setChargeTemplateFilter}
                dataSource={filtedCT.map((ct) => ({
                  value: ct.id,
                  text: ct.name,
                }))}
              />,
            )}
          </Form.Item>
        </div>
        <div className="col px-2 w-full md:w-1/3">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="Price per unit"
                id="common.label.pricePerUnit"
              />
            }
          >
            {getFieldDecorator('amount', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="common.message.required"
                      defaultMessage="Required"
                    />
                  ),
                },
              ],
            })(<InputNumberFormat min={0} />)}
          </Form.Item>
        </div>
        <div className="col px-2 w-full md:w-1/3">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="Quantity"
                id="common.label.quantity"
              />
            }
          >
            {getFieldDecorator('quantity', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="common.message.required"
                      defaultMessage="Required"
                    />
                  ),
                },
              ],
              initialValue: 1,
            })(<InputNumberFormat disabled={mode === 'discount'} min={1} />)}
          </Form.Item>
        </div>
      </div>
      <div className="flex flex-wrap -mx-2">
        <div className="col px-2 w-full md:w-1/3">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="Currency"
                id="common.label.currency"
              />
            }
          >
            {getFieldDecorator('currency', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="common.message.required"
                      defaultMessage="Required"
                    />
                  ),
                },
              ],
            })(
              <Select
                placeholder={
                  <FormattedMessage
                    id="common.label.selectCurrency"
                    defaultMessage="Select currency"
                  />
                }
              >
                {(currencies || []).map((c) => (
                  <Select.Option key={c.name} value={c.name}>
                    {c.name}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </div>
        <div className="col px-2 w-full md:w-1/3">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="Tax"
                id="common.label.taxPercent"
              />
            }
          >
            {getFieldDecorator('taxPercent', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="common.message.required"
                      defaultMessage="Required"
                    />
                  ),
                },
              ],
              initialValue: mode === 'discount' ? 0 : hotel.taxPercent,
            })(<InputNumberFormat disabled={mode === 'discount'} />)}
          </Form.Item>
        </div>
        <div className="col px-2 w-full md:w-1/3">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="Revenue group"
                id="common.label.revenueGroup"
              />
            }
          >
            {getFieldDecorator('serviceGroupId', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="common.message.required"
                      defaultMessage="Required"
                    />
                  ),
                },
              ],
            })(
              <Select
                placeholder={
                  <FormattedMessage
                    defaultMessage="Select revenue group"
                    id="common.label.selectRevenueGroup"
                  />
                }
                onChange={(value) => onRevenueGroupChange(value)}
              >
                {(revGroups || []).map((c) => (
                  <Select.Option key={c.id} value={c.id}>
                    {c.name}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </div>
      </div>
      <div className="flex flex-wrap -mx-2">
        <div className="col px-2 w-full md:w-1/3">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="Room ID & Room"
                id="bookings.label.bookingRoomId"
              />
            }
          >
            {getFieldDecorator('bookingRoomId', {
              initialValue: validRooms(rooms).some(
                (r) => r.id === bookingRoomId,
              )
                ? bookingRoomId
                : undefined,
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="common.message.required"
                      defaultMessage="Required"
                    />
                  ),
                },
              ],
            })(
              <Select
                placeholder={
                  <FormattedMessage
                    defaultMessage="Select Room ID & Room"
                    id="bookings.label.selectBookingRoomID"
                  />
                }
                dropdownMatchSelectWidth={false}
                optionFilterProp="children"
                showSearch
              >
                {validRooms(rooms).map((r) => (
                  <Select.Option key={r.id} value={r.id}>
                    {`${r.refCode}: ${[r.roomTypeName, r.roomName]
                      .filter(Boolean)
                      .join(' - ')}`}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </div>
        <div className="col px-2 w-full md:w-1/3">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="Charge date"
                id="common.label.chargeDate"
              />
            }
          >
            {getFieldDecorator('serviceDate', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="common.message.required"
                      defaultMessage="Required"
                    />
                  ),
                },
              ],
              initialValue: moment(),
            })(
              <DatePicker
                className="w-full"
                suffixIcon={<IconCalendarAlt className="text-xs text-black" />}
              />,
            )}
          </Form.Item>
        </div>
        <div className="col px-2 w-full md:w-1/3">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="Revenue category"
                id="common.label.revenueCategory"
              />
            }
          >
            {getFieldDecorator('serviceId', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="common.message.required"
                      defaultMessage="Required"
                    />
                  ),
                },
              ],
            })(
              <Select
                placeholder={
                  <FormattedMessage
                    id="common.label.selectRevenueCategory"
                    defaultMessage="Select revenue category"
                  />
                }
              >
                {(revCategories || []).map((c) => (
                  <Select.Option key={c.id} value={c.id}>
                    {c.name}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

const AddChargeModal = Form.create({ name: 'add_charge_form' })(function (
  props,
) {
  useInjectReducer({ key: BOOKING_KEY, reducer: bookingsReducer });
  useInjectSaga({ key: BOOKING_KEY, saga });

  const {
    folio,
    mode,
    hotel,
    rooms,
    form,
    visible,
    onCancel,
    currencies,
    chargeTemplates,
    revGroups,
    doFetchChargeTemplates,
    doFetchRevGroups,
    doAddCharge,
  } = props;
  const {
    setFieldsValue,
    validateFields,
    getFieldDecorator,
    resetFields,
  } = form;

  const [selectedRevenueGroup, setSelectedRevenueGroup] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [chargeTemplateFilter, setChargeTemplateFilter] = useState('');
  const { id: folioID, bookingRoomId } = folio;

  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    doFetchChargeTemplates(hotel.id);
    doFetchRevGroups(hotel.id);
  }, [hotel.id]); // eslint-disable-line

  function onSelectChargeTemplate(chargeTemplateID) {
    // let chargeTemplateID = ctID;
    if (chargeTemplateID) {
      const ct = chargeTemplates.find(
        ({ id }) => id === Number(chargeTemplateID),
      );
      const group = revGroups.find(({ id }) => id === ct?.serviceGroup.id);
      setFieldsValue({
        currency: ct.currency,
        serviceGroupId: ct?.serviceGroup.id,
        serviceId: group.services[0]?.id,
        amount: ct.amount,
      });
      setSelectedRevenueGroup(ct?.serviceGroup.id);
    }
  }

  function onClose() {
    if (isCreating) {
      return;
    }

    onCancel(false);
    resetFields();
  }

  function onAddCharge(e) {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (err) {
        console.error(err);
      } else {
        try {
          setIsCreating(true);
          const { amount, chargeTemplate, serviceDate } = values;
          const reqData = {
            ...values,
            taxPercent: hotel.taxPercent,
            serviceDate: moment(serviceDate).toISOString(),
          };
          const ct = chargeTemplates.find(
            ({ id }) => id === Number(chargeTemplate),
          );
          if (ct) {
            reqData.warehousingId = chargeTemplate;
            reqData.name = ct.name.trim();
            reqData.taxPercent = ct.taxPercent;
            reqData.taxIncluded = ct.taxIncluded;
          } else {
            reqData.name = chargeTemplate;
          }
          if (mode === 'discount') {
            reqData.amount = -amount;
          }
          await doAddCharge(folioID, reqData, mode);
          onCancel(true);
          resetFields();
        } catch (e) {
          console.error(e);
        } finally {
          setIsCreating(false);
        }
      }
    });
  }

  function onRevenueGroupChange(value) {
    if (value === selectedRevenueGroup) return;
    const group = revGroups.find(({ id }) => id === value);
    setFieldsValue({
      serviceGroupId: value,
      serviceId: group.services[0]?.id,
    });
    setSelectedRevenueGroup(value);
  }

  const revGroup = useMemo(
    () => revGroups.find(({ id }) => id === selectedRevenueGroup),
    [revGroups, selectedRevenueGroup],
  );
  const revCategories = revGroup?.services || [];

  const filtedCT = useMemo(() => {
    if (mode === 'discount') {
      return [];
    }
    return chargeTemplates.filter((ct) => {
      if (chargeTemplateFilter) {
        return (
          ct.status === 'active' &&
          ct.name.toLowerCase().includes(chargeTemplateFilter.toLowerCase())
        );
      }
      return ct.status === 'active';
    });
  }, [chargeTemplateFilter, chargeTemplates, mode]);

  return (
    <Modal
      destroyOnClose
      width="1000px"
      visible={visible}
      title={
        mode === 'discount' ? (
          <FormattedMessage
            id="bookings.label.addDiscount"
            defaultMessage="Add discount"
          />
        ) : (
          <FormattedMessage
            id="bookings.label.addCharge"
            defaultMessage="Add charge"
          />
        )
      }
      submitText={
        <FormattedMessage defaultMessage="Add" id="common.action.add" />
      }
      cancelText={
        <FormattedMessage defaultMessage="Cancel" id="common.action.cancel" />
      }
      submitting={isCreating}
      onCancel={onClose}
      onSubmit={onAddCharge}
    >
      <InnerModal
        onAddCharge={onAddCharge}
        getFieldDecorator={getFieldDecorator}
        onSelectChargeTemplate={onSelectChargeTemplate}
        setChargeTemplateFilter={setChargeTemplateFilter}
        filtedCT={filtedCT}
        mode={mode}
        currencies={currencies}
        bookingRoomId={bookingRoomId}
        rooms={rooms}
        hotel={hotel}
        onRevenueGroupChange={onRevenueGroupChange}
        revGroups={revGroups}
        revCategories={revCategories}
      />
    </Modal>
  );
});

AddChargeModal.propTypes = {
  folioID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mode: PropTypes.oneOf(['add', 'discount']),
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

const mapState = createStructuredSelector({
  currencies: makeSelectCurrencies(),
  hotel: makeSelectCurrentHotel(),
  chargeTemplates: makeSelectChargeTemplates(),
  revGroups: makeSelectRevGroups(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchChargeTemplates: (hid) => dispatch(fetchChargeTemplates(hid)),
    doFetchRevGroups: (hid) => dispatch(fetchRevGroups(hid)),
    doAddCharge: (fid, reqData, type) =>
      new Promise((resolve, reject) =>
        dispatch(addFolioCharge(fid, reqData, type, resolve, reject)),
      ),
  };
};

export default connect(mapState, mapDispatchToProps)(AddChargeModal);
