import React from 'react';
import { Skeleton } from '@uxui/skeleton-loader';
import styled from 'styled-components';
import tw from 'twin.macro';

const Container = styled.div`
  height: 68px;
  ${tw`flex items-center justify-between`}
`;

export default function HeaderSkeleton() {
  return (
    <Container>
      <Skeleton
        width="160px"
        height="24px"
        baseColor="#ddd"
        highlightColor="#e4e4e4"
      />
      <div>
        <Skeleton
          width="120px"
          height="30px"
          baseColor="#ddd"
          highlightColor="#e4e4e4"
        />
      </div>
    </Container>
  );
}
