import React, { Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import { Location } from '@reach/router';
import { Radio } from 'antd';
import SkeletonTable from 'components/skeleton/TableSkeletonScreen';
import { PageHeader } from '@aha/ui';
import ROUTES from 'constants/routes';

const CompaniesAndGuestsLayout: React.FC<any> = ({ children }) => {
  return (
    <>
      <Location>
        {({ location: { pathname }, navigate }) => (
          <div className="flex w-full justify-between items-center">
            <PageHeader
              title={
                <FormattedMessage
                  defaultMessage="Companies & Guests"
                  id="settings.label.companies_guests"
                />
              }
            />
            <Radio.Group defaultValue={pathname} className="text-center">
              <Radio.Button
                value={ROUTES.COMPANIES_SETTING}
                className="min-w-md"
                onClick={(e) => navigate('/companies-n-guests/companies')}
              >
                <FormattedMessage
                  defaultMessage="Companies"
                  id="settings.label.companies"
                />
              </Radio.Button>
              <Radio.Button
                value={ROUTES.GUESTS_SETTING}
                className="min-w-md"
                onClick={() => navigate('/companies-n-guests/guests')}
              >
                <FormattedMessage
                  defaultMessage="Guests"
                  id="settings.label.guests"
                />
              </Radio.Button>
            </Radio.Group>
          </div>
        )}
      </Location>
      <Suspense fallback={<SkeletonTable />}>{children}</Suspense>
    </>
  );
};

export default CompaniesAndGuestsLayout;
