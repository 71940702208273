import React, {
  useState,
  useEffect,
  useMemo,
  ReactText,
  SetStateAction,
  useRef,
} from 'react';
import moment from 'moment';
import { Form, AutoComplete } from 'antd';
import { FormattedMessage } from 'react-intl';
import { NoDataBlock, Modal, Select } from '@aha/ui';
import { Dispatch } from 'redux';
import { BookingUpdateRoomTypeRequest } from 'types/v3-schema';
import { Booking, BookingRoomDetail, Charge } from 'types/schema';
import { Hotel, RatePlan, Room } from 'types/schema';
import { useDispatch, useSelector } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { editBookingRoomType } from 'containers/BookingProvider/actions';
import { DataSourceItemType } from 'antd/lib/auto-complete';
import useSelectRoomTypes from 'containers/SettingRoomTypesPage/useSelectRoomTypes';
import { currencyFormater } from 'utils/currencyFormater';
import { ApplicationRootState } from 'types/app';
import { createStructuredSelector } from 'reselect';
import { makeSelectRatePlans } from 'containers/RatePlansPage/selector';
import { fetchRatePlans } from 'containers/RatePlansPage/actions';
import InputNumberFormat from 'components/InputNumberFormat';
import { SelectValue } from 'antd/lib/select';
import { CHARGE_TYPE_KEYS, RoomStatus } from 'utils/constants';
import {
  makeSelectIsLoadingAvailableRooms,
  makeSelectMapAvailableRooms,
} from 'containers/BookingProvider/selector';
import { fetchAvailableRooms } from 'containers/BookingProvider/actions';

import AvaillableRoomsModalSkeletonLoader from 'components/skeleton/pages/AvaillableRoomsModalSkeletonLoader';
import RoomBlock from 'components/RoomBlock';
import { RoomDate } from 'components/AvailableRoomsModal';

function ratePlanOptions(rates: RatePlan[] = []) {
  return rates.map((r) => ({
    text: `${r.name} - ${currencyFormater(r.price, r.currency?.name || 'VND')}`,
    value: r.id,
    isRatePlan: true,
  }));
}

function filterRates(rates: RatePlan[] = [], rateFilter?: number) {
  const sortRates = rates.sort(
    (a, b) => Number(a?.roomType?.name) - Number(b?.roomType?.name),
  );

  return (sortRates || []).filter((r) =>
    rateFilter ? r.roomType?.id === rateFilter : false,
  );
}

export type SubmitValues = {
  taxPercent: number;
  roomTypeId: number;
  rate: number | string;
};

export type EditBookingRoomTypeModalProps = {
  booking: Booking;
  hotel: Hotel;
  arrival: string;
  departure: string;
  setCollapseKeys: React.Dispatch<SetStateAction<number[]>>;
  disabledRooms?: Array<number>;
  room: BookingRoomDetail;
  visible?: boolean;
  onClose?: (...args: any[]) => any;
} & FormComponentProps;

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    rates: RatePlan[];
    roomsLoading: boolean;
    roomsMap: Record<string, Room[]>;
  }
>({
  rates: makeSelectRatePlans(),
  roomsLoading: makeSelectIsLoadingAvailableRooms(),
  roomsMap: makeSelectMapAvailableRooms(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doFetchRates: (
    hid: number,
    start: moment.Moment | string,
    end: moment.Moment | string,
  ) => dispatch(fetchRatePlans(hid, start, end)),
  doEditBookingRoomType: (
    refCode: string,
    bkrid: string,
    body: BookingUpdateRoomTypeRequest,
  ) =>
    new Promise((resolve, reject) =>
      dispatch(editBookingRoomType(refCode, bkrid, body, resolve, reject)),
    ),
  doFetchAvailableRooms: (
    hid: number,
    start: RoomDate,
    end: RoomDate,
    rtID: string = 'all',
  ) => dispatch(fetchAvailableRooms(hid, start, end, rtID)),
});

const EditBookingRoomTypeModal: React.SFC<EditBookingRoomTypeModalProps> = ({
  hotel,
  arrival,
  departure,
  room,
  booking,
  visible,
  setCollapseKeys,
  disabledRooms,
  onClose,
  form,
}) => {
  const dispatch = useDispatch();
  const {
    doEditBookingRoomType,
    doFetchAvailableRooms,
    doFetchRates,
  } = mapDispatchToProps(dispatch);
  const { rates, roomsLoading, roomsMap } = useSelector(mapStateToProps);

  const {
    getFieldDecorator,
    getFieldValue,
    resetFields,
    validateFields,
    setFieldsValue,
  } = form;
  const [submitting, setSubmitting] = useState(false);
  const [currency, setCurrency] = useState<string>(hotel?.currency || 'VND');
  const [price, setPrice] = useState<ReactText>(0);
  const initRate = useRef<ReactText | null>(null);

  const { roomTypes } = useSelectRoomTypes(hotel?.id);
  const [roomNumber, setRoomNumber] = useState(-1);

  const { folios } = booking || {};
  const { id, roomTypeId, refCode: roomRefCode } = room || {};
  const { refCode } = booking || {};
  const curRoomTypeId = getFieldValue('roomTypeId') || roomTypeId;

  const roomsFilter = (roomsMap[curRoomTypeId] || []).filter(
    (r) => r.status !== 'out_of_order',
  );

  const roomRateSources = useMemo(
    () =>
      ratePlanOptions(filterRates(rates, curRoomTypeId)).map((r) => ({
        ...r,
        value: `rId${r.value}`,
      })),
    [rates, curRoomTypeId],
  );

  const initialRoomRate = useMemo(() => {
    let charges: Charge[] | undefined;
    const masterFolio = folios?.find((f) => f.isMaster);

    const isActiveRoomCharge = (c: Charge) =>
      c.chargeType === CHARGE_TYPE_KEYS.ROOM_CHARGE && c.status === 'active';

    if (!!masterFolio) {
      charges = (masterFolio?.charges || []).filter(
        (c) => c.bookingRoomId === room?.id && isActiveRoomCharge(c),
      );
    } else {
      charges = folios
        ?.find((folio) => folio.bookingRoomId === room?.id)
        ?.charges?.filter((c) => isActiveRoomCharge(c));
    }

    // sort from new to old days
    charges = charges?.sort((f1, f2) =>
      moment(f2.serviceDate).diff(f1.serviceDate),
    );

    const { amount, stayRuleId } = charges?.[0] || {};
    const rate = roomRateSources.find(
      (rate) => rate.value === `rId${stayRuleId}`,
    );
    const roomRate = rate?.value || amount;

    if (!initRate.current && roomRate) {
      initRate.current = roomRate;
    }

    return roomRate?.toString();
  }, [folios, room, roomRateSources, submitting]); //eslint-disable-line

  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    if (hotel?.id) {
      doFetchRates(hotel.id, arrival, departure);
    }
  }, [hotel?.id]); // eslint-disable-line

  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    if (hotel?.id && curRoomTypeId && !submitting) {
      doFetchAvailableRooms(hotel?.id, arrival, departure, curRoomTypeId);
    }
  }, [curRoomTypeId, submitting]); // eslint-disable-line

  useEffect(() => {
    if (visible) {
      setFieldsValue({ rate: initialRoomRate });
    }
  }, [visible]); // eslint-disable-line

  function onCancel() {
    if (submitting) {
      return;
    }

    onClose && onClose();
    resetFields();
    setPrice(0);
    setRoomNumber(-1);
    initRate.current = null;
  }

  function onAdd() {
    validateFields(async (err, values: SubmitValues) => {
      if (err || roomNumber === -1) {
        return;
      } else if (!refCode || !id || !roomRefCode) {
        console.error('Booking room not found!');
        return;
      }

      try {
        setSubmitting(true);
        const body: BookingUpdateRoomTypeRequest = {
          amount: +price,
          bookingRoomId: id,
          stayRuleId: values.rate.toString().includes('rId')
            ? +values.rate.toString().slice(3)
            : null,
          roomTypeId: values.roomTypeId,
          taxPercent: values.taxPercent,
          currency,
          roomId: roomNumber,
        };

        await doEditBookingRoomType(refCode, roomRefCode, body);

        onClose && onClose(true);
        resetFields();
        setCollapseKeys((prev: number[]) =>
          prev.includes(values.roomTypeId)
            ? prev
            : [...prev, values.roomTypeId],
        );
      } catch (e) {
        console.error(e);
      } finally {
        setPrice(0);
        setRoomNumber(-1);
        initRate.current = null;
        setSubmitting(false);
      }
    });
  }

  function onSelectRoomRates(rid: SelectValue) {
    const selectedRate: RatePlan | undefined = rates?.find((r) => r.id === rid);

    setCurrency(selectedRate?.currency?.name || hotel?.currency || 'VND');
  }

  const isLoading = submitting || roomNumber === -1;

  return (
    <Modal
      visible={visible}
      title={
        <FormattedMessage
          defaultMessage="Edit Booking Room Type"
          id="bookings.label.editBookingRoomType"
        />
      }
      submitText={
        <FormattedMessage defaultMessage="Save" id="common.action.save" />
      }
      submitting={isLoading}
      onCancel={onCancel}
      onSubmit={onAdd}
      width={957}
    >
      <Form layout="vertical">
        <div className="flex flex-wrap -mx-2">
          <div className="w-full px-2 col md:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Room type"
                  id="common.label.roomType"
                />
              }
            >
              {getFieldDecorator(`roomTypeId`, {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
                initialValue: roomTypeId,
              })(
                <Select<string>
                  showSearch
                  placeholder={
                    <FormattedMessage
                      defaultMessage="Room type"
                      id="common.label.select_room_type"
                    />
                  }
                  optionFilterProp="children"
                  onSelect={() => {
                    setPrice(0);
                    setFieldsValue({ rate: undefined });
                    setRoomNumber(-1);
                  }}
                >
                  {(roomTypes || []).map((c) => (
                    <Select.Option key={c.id} value={c.id}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </div>
          <div className="w-full px-2 col md:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Room Rate"
                  id="common.label.roomRate"
                />
              }
            >
              {getFieldDecorator('rate', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                  {
                    validator: (rule, value, cb) =>
                      value &&
                      isNaN(value) &&
                      (roomRateSources || []).findIndex(
                        (r) => r.value === value,
                      ) < 0
                        ? cb(
                            <FormattedMessage
                              id="common.message.invalid"
                              defaultMessage="Invalid"
                            />,
                          )
                        : cb(),
                  },
                ],
              })(
                <AutoComplete
                  placeholder={
                    <FormattedMessage
                      defaultMessage="Enter price"
                      id="common.label.enter_price"
                    />
                  }
                  dataSource={
                    (roomRateSources as unknown) as DataSourceItemType[]
                  }
                  onSelect={onSelectRoomRates}
                  onSearch={(price) => {
                    setPrice(price);
                  }}
                />,
              )}
            </Form.Item>
          </div>
          <div className="w-full px-2 col md:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Tax"
                  id="common.label.taxPercent"
                />
              }
            >
              {getFieldDecorator('taxPercent', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
                initialValue: hotel?.taxPercent,
              })(<InputNumberFormat min={0} />)}
            </Form.Item>
          </div>
        </div>
        <div>
          {roomsLoading ? (
            <AvaillableRoomsModalSkeletonLoader hideRoomTypes />
          ) : roomsFilter.length <= 0 ? (
            <NoDataBlock className="py-10" />
          ) : (
            <div className="flex flex-wrap -mx-2 -mt-2">
              {roomsFilter.map((r, index) => {
                const log = r?.roomAvailabilityLogs?.[0];
                const housekeepingStatus = (log &&
                moment().isBetween(
                  moment(log.unAvailableFrom),
                  moment(log.availableDate),
                  'minutes',
                  '[]',
                )
                  ? 'out_of_order'
                  : r.housekeepingStatus) as RoomStatus;

                return (
                  <div
                    key={index}
                    className="w-full p-2 col sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5"
                  >
                    <RoomBlock
                      housekeepingStatus={housekeepingStatus}
                      room={{
                        id: r.id,
                        title: r.title,
                        housekeepingStatus: r.housekeepingStatus,
                      }}
                      disabled={
                        (roomNumber !== -1 && r.id !== roomNumber) ||
                        disabledRooms?.includes(Number(r?.id))
                      }
                      onClick={() => {
                        setRoomNumber(
                          roomNumber !== -1 && r.id === roomNumber
                            ? -1
                            : (r.id as number),
                        );
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default Form.create<EditBookingRoomTypeModalProps>({
  name: 'edit_booking_room_form',
})(EditBookingRoomTypeModal);
