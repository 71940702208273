import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { ApplicationRootState } from 'types/app';
import { Warehousing } from 'types/schema';

const selectCreateBooking = (state: ApplicationRootState) =>
  state.createBooking || initialState;

export function makeSelectChargeTemplates() {
  return createSelector(
    (state: ApplicationRootState) =>
      (state.createBooking || initialState).chargeTemplates,
    (chargeTemplates) =>
      chargeTemplates?.filter((r: Warehousing) => r.status === 'active') || [],
  );
}

export const makeSelectCountries = () =>
  createSelector(selectCreateBooking, (state) => state.countries);

export const makeSelectCompanyID = () =>
  createSelector(selectCreateBooking, (state) => state.companyID);
