import { defineMessages } from 'react-intl';

export type SettingMessagesType = keyof typeof settingMessages;

const settingMessages = defineMessages({
  'settings.label.bank': {
    id: 'settings.label.bank',
    defaultMessage: 'Bank',
  },
  'settings.label.card': {
    id: 'settings.label.card',
    defaultMessage: 'Card',
  },
  'settings.label.cash': {
    id: 'settings.label.cash',
    defaultMessage: 'Cash',
  },
  'settings.label.debit': {
    id: 'settings.label.debit',
    defaultMessage: 'Debit',
  },
  'settings.label.bankTransfer': {
    id: 'settings.label.bankTransfer',
    defaultMessage: 'Bank Transfer',
  },
  'settings.label.discount': {
    id: 'settings.label.discount',
    defaultMessage: 'Discount',
  },
  'settings.label.extra': {
    id: 'settings.label.extra',
    defaultMessage: 'Extra',
  },
  'settings.label.other': {
    id: 'settings.label.other',
    defaultMessage: 'Other',
  },
  'settings.label.others': {
    id: 'settings.label.others',
    defaultMessage: 'Others',
  },
  'settings.label.roomCharge': {
    id: 'settings.label.roomCharge',
    defaultMessage: 'Room charge',
  },
});

export default settingMessages;
