import React, { useState } from 'react';
import { FormInput, Button } from '@aha/ui';
import { IconEyeInvisible, IconEye } from '@aha/icons';

export interface PasswordInputProps {
  name: string;
  label?: string;
  placeholder: string;
  buttonTestId?: string;
  inputTestId?: string;
  required?: boolean;
}

const PasswordInput = ({
  name,
  label,
  placeholder,
  buttonTestId,
  inputTestId,
  required = false,
}: PasswordInputProps) => {
  const [visible, setVisible] = useState(false);
  return (
    <FormInput
      data-testid={inputTestId}
      name={name}
      label={label}
      required={required}
      placeholder={placeholder}
      type={visible ? 'text' : 'password'}
      suffix={
        <Button
          className="-mr-1 text-grey-darker"
          type="icon"
          icon={visible ? IconEyeInvisible : IconEye}
          data-testid={buttonTestId}
          onClick={(e) => {
            e.preventDefault();
            setVisible(!visible);
          }}
        />
      }
    />
  );
};

export default PasswordInput;
