import React from 'react';
import classnames from 'classnames';

export type AlertProps = {
  message?: React.ReactNode;
  description?: React.ReactNode;
  className?: string;
};

export function Alert({ className, message, description }: AlertProps) {
  return (
    <div
      className={classnames([
        'px-6 py-5 border rounded border-red bg-red-lightest',
        className,
      ])}
    >
      <div
        className={classnames('font-semibold uppercase text-2xs text-red', {
          'mb-1': !!description,
        })}
      >
        {message}
      </div>
      <div className="text-sm text-secondary">{description}</div>
    </div>
  );
}

export default Alert;
