import React, { useState, useEffect, LegacyRef } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Input, { InputProps } from 'antd/lib/input';

const CustomInput = styled(Input)<{ invalid: boolean }>`
  .ant-input {
    ${tw`px-2`};
  }
  .ant-input:focus {
    box-shadow: none !important;
  }
  .ant-input,
  .ant-input:hover,
  .ant-input:focus {
    ${(props) => (props.invalid ? `border-color: red !important;` : '')};
  }
`;

const Label = styled.span`
  ${tw`z-50 pointer-events-none text-sm text-grey-darker`};
  position: absolute !important;
  top: 12px;
  left: 8px;
  transition: all 100ms ease-in-out;
  line-height: 1em;
  background-color: transparent;
  &:before {
    content: '';
    position: absolute;
    left: 2px;
    right: 2px;
    background-color: white;
    height: 3px;
    bottom: 4px;
    z-index: -1;
  }
`;

interface InputOutlineProps extends InputProps {
  label: string;
  invalid: boolean;
}

export const InputOutline = React.forwardRef<InputOutlineProps, any>(
  (props, ref: LegacyRef<any>) => {
    const { label, value, onChange, invalid, onFocus, onBlur, ...rest } = props;
    const [isInputDirty, setIsInputDirty] = useState<boolean>(false);
    const [iValue, setIValue] = useState(value);
    const [iBlur, setIBlur] = useState<boolean>(false);
    const [iMMove, setIMMove] = useState<boolean>(false);
    useEffect(() => {
      if (iValue === undefined || iValue === null || iValue === '') {
        if (isInputDirty && !iBlur) {
          setIsInputDirty(false);
        }
      } else if (!isInputDirty) {
        setIsInputDirty(true);
      }
    }, [isInputDirty, iValue]); // eslint-disable-line react-hooks/exhaustive-deps

    function onIFocus(e: FocusEvent) {
      setIsInputDirty(true);
      setIBlur(true);
      if (typeof onFocus === 'function') {
        onFocus(e);
      }
    }

    function onIBlur(e: FocusEvent) {
      setIBlur(false);
      if (iValue === undefined || iValue === null || iValue === '') {
        setIsInputDirty(false);
      }
      if (typeof onBlur === 'function') {
        onBlur(e);
      }
    }

    function onIChange(e: React.ChangeEvent<HTMLInputElement>) {
      setIsInputDirty(true);
      setIValue(e.target.value);
      if (typeof onChange === 'function') {
        onChange(e);
      }
    }

    return (
      <div ref={ref} className="relative w-full">
        <Label
          data-testid="custom-label"
          css={`
            ${
              isInputDirty &&
              'top: -4px; left: 4px; font-size:11px; padding:0 5px;'
            }
            ${(iMMove || iBlur) && `color:var(--primary);`}
            ${invalid && `color:var(--red);`}
          `}
        >
          {label}
        </Label>
        <CustomInput
          data-testid="custom-input"
          {...rest}
          invalid={invalid}
          onFocus={onIFocus}
          onBlur={onIBlur}
          value={value || iValue}
          onChange={onIChange}
          onMouseMove={() => iValue && !iBlur && setIMMove(true)}
          onMouseLeave={() => setIMMove(false)}
        />
      </div>
    );
  },
);

export default InputOutline;
