import React, { useRef, useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, NAFallback } from '@aha/ui';
import { ModelNote, NoteTitle, EditNoteType, OtherNote } from './types';
import Comment from './components/Comment';
import classnames from 'classnames';
import { BookingUpdateBookingForm } from 'types/v3-schema';
import { Dispatch } from 'redux';
import {
  fetchBooking,
  updateBookingInfo,
} from 'containers/BookingProvider/actions';
import { useDispatch } from 'react-redux';
import { formatDatetime } from '@aha/utils';
import { Booking, UserDetail } from 'types/schema';

export const noteTitle = {
  clientRequestNotes: (
    <FormattedMessage
      defaultMessage="Guest request"
      id="bookings.actions.guestRequest"
    />
  ),
  mealNotes: (
    <FormattedMessage
      defaultMessage="Meal notes"
      id="bookings.actions.mealRequest"
    />
  ),
  housekeepingNotes: (
    <FormattedMessage
      defaultMessage="Housekeeping notes"
      id="bookings.actions.housekeepingRequest"
    />
  ),
};

export interface BookingNoteProps {
  className?: string;
  booking: Booking;
  user: UserDetail;

  isFuncBtnDisabled: boolean;
  isModifying: boolean;

  onSetIsModifying: (value: React.SetStateAction<any>) => void;
}

const initialEditNotes: EditNoteType = {
  clientRequestNotes: false,
  mealNotes: false,
  housekeepingNotes: false,
  otherNotes: [],
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doFetchBooking: (bid: number) => dispatch(fetchBooking(bid)),
  doUpdateInfo: (bid: number, body: BookingUpdateBookingForm) =>
    new Promise((resolve, reject) =>
      dispatch(updateBookingInfo(bid, body, resolve, reject)),
    ),
});

export const BookingNote: React.FC<BookingNoteProps> = ({
  user,
  booking,
  className,
  isFuncBtnDisabled,
  isModifying,
  onSetIsModifying,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { doFetchBooking, doUpdateInfo } = mapDispatchToProps(dispatch);

  const [newNote, setNewNote] = useState('');
  const [notes, setNotes] = useState<ModelNote>({});
  const [editNotes, setEditNotes] = useState(initialEditNotes);
  const firstLoading = useRef(true);
  const bid = Number(booking?.id);

  const otherNotes = useMemo(() => {
    try {
      const n = JSON.parse(booking?.otherNotes || '');
      return Array.isArray(n) ? n : [];
    } catch (err) {
      // backward compatible for old booking
      if (booking?.otherNotes) {
        return [
          {
            user: 'Other notes',
            date: booking.arrival,
            message: booking.otherNotes,
          },
        ];
      }
      return [];
    }
  }, [booking]);

  useEffect(() => {
    // Prevent useEffect call when running test
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    if (!(firstLoading.current && bid)) {
      return;
    }

    const rawNotes: [keyof ModelNote, undefined | string | string[]][] = [
      ['clientRequestNotes', booking.clientRequestNotes],
      ['mealNotes', booking.mealNotes],
      ['housekeepingNotes', booking.housekeepingNotes],
      ['otherNotes', otherNotes],
    ];

    setNotes(
      rawNotes.reduce(
        (res, [key, value]) => ({
          ...res,
          [key]: value,
        }),
        {},
      ),
    );

    setEditNotes(
      rawNotes.reduce(
        (res, [key, n]) => ({
          ...res,
          [key]:
            key === 'otherNotes'
              ? ((n || []) as string[]).map((_) => false)
              : false,
        }),
        {},
      ) as EditNoteType,
    );

    firstLoading.current = false;
  }, [bid, otherNotes]); // eslint-disable-line

  async function onEditNotes(key: NoteTitle) {
    if (!notes[key]) {
      setEditNotes((prev) => ({ ...prev, [key]: false }));
      return;
    }

    try {
      onSetIsModifying(true);
      await doUpdateInfo(bid, { [key]: notes[key] });
      await doFetchBooking(bid);

      setEditNotes((prev) => ({ ...prev, [key]: false }));
    } catch (err) {
      console.error(err);
    } finally {
      onSetIsModifying(false);
    }
  }

  async function onEditOtherNotes(idx: number) {
    try {
      onSetIsModifying(true);
      await doUpdateInfo(bid, {
        otherNotes: JSON.stringify(
          otherNotes.map((n, oIdx) =>
            idx === oIdx ? notes.otherNotes?.[idx] : n,
          ),
        ),
      });
      await doFetchBooking(bid);

      setEditNotes((prev) => ({
        ...prev,
        otherNotes: prev.otherNotes.map((n, oIdx) =>
          idx === oIdx ? false : n,
        ),
      }));
    } catch (err) {
      console.error(err);
    } finally {
      onSetIsModifying(false);
    }
  }

  async function onAddNote(e: React.FormEvent<HTMLFormElement>) {
    try {
      e.preventDefault();
      const newOtherNote = {
        user: user?.name,
        date: moment().toISOString(),
        message: newNote,
        uid: user?.id,
      };

      const body = {
        noteMessage: newNote,
        otherNotes: JSON.stringify([...otherNotes, newOtherNote]),
      };
      onSetIsModifying(true);
      await doUpdateInfo(bid, body);
      await doFetchBooking(bid);

      setNewNote('');
      setEditNotes((prev) => ({
        ...prev,
        otherNotes: [...prev.otherNotes, false],
      }));
      setNotes((prev) => ({
        ...prev,
        otherNotes: [...prev.otherNotes, newOtherNote],
      }));
    } catch (err) {
      console.error(err);
    } finally {
      onSetIsModifying(false);
    }
  }

  async function onRemoveNote(note: OtherNote) {
    try {
      const removeIdx = otherNotes.findIndex((n) =>
        moment(n.date).isSame(note.date),
      );

      const oNotes = otherNotes.filter((_, idx) => idx !== removeIdx);
      const body = {
        otherNotes: JSON.stringify(oNotes),
      };

      onSetIsModifying(true);
      await doUpdateInfo(bid, body);
      await doFetchBooking(bid);

      setEditNotes((prev) => ({
        ...prev,
        otherNotes: prev.otherNotes.filter((_, idx) => idx !== removeIdx),
      }));
      setNotes((prev) => ({
        ...prev,
        otherNotes: (prev.otherNotes || []).filter(
          (_, idx) => idx !== removeIdx,
        ),
      }));
    } catch (err) {
      console.error(err);
    } finally {
      onSetIsModifying(false);
    }
  }

  const hotelNotes = Object.entries(notes).filter(
    ([key, _]) => key !== 'otherNotes',
  ) as Array<[NoteTitle, string]>;

  return (
    <Card
      title={
        <FormattedMessage
          defaultMessage="Booking Note"
          id="common.label.bookingNote"
        />
      }
      className={classnames(['mt-4', className])}
      hasBorder
      noContentPadding
      contentClassName="py-6"
    >
      {hotelNotes.map(([key, note]) => (
        <Comment
          key={key}
          title={noteTitle[key]}
          isEdit={editNotes[key]}
          disabled={isFuncBtnDisabled}
          onEdit={() => setEditNotes((prev) => ({ ...prev, [key]: true }))}
          onSave={() => onEditNotes(key)}
          onCancel={() => {
            setEditNotes((prev) => ({ ...prev, [key]: false }));
            setNotes((prev) => ({
              ...prev,
              [key]: booking[key],
            }));
          }}
          note={notes[key]}
          content={<NAFallback value={note} />}
          onInputChange={(e) => {
            e.persist();
            setNotes((prev) => ({
              ...prev,
              [key]: e.target.value,
            }));
          }}
        />
      ))}

      {(otherNotes || []).map((n, idx) => (
        <Comment
          key={n.date}
          title={n.user}
          time={formatDatetime(n.date, 'DD MMM YYYY, HH:mm')}
          isEdit={editNotes.otherNotes?.[idx]}
          disabled={isFuncBtnDisabled}
          onEdit={() =>
            setEditNotes((prev) => ({
              ...prev,
              otherNotes: prev.otherNotes.map((note, oIdx) =>
                idx === oIdx ? true : note,
              ),
            }))
          }
          onSave={() => onEditOtherNotes(idx)}
          onRemove={(e) => {
            e?.preventDefault();
            onRemoveNote(n);
          }}
          onCancel={() => {
            setEditNotes((prev) => ({
              ...prev,
              otherNotes: prev.otherNotes.map((note, oIdx) =>
                idx === oIdx ? false : note,
              ),
            }));

            setNotes((prev) => ({
              ...prev,
              otherNotes: prev.otherNotes?.map((note, oIdx) =>
                idx === oIdx ? { ...note, message: n.message } : note,
              ),
            }));
          }}
          note={notes.otherNotes?.[idx]?.message}
          content={<NAFallback value={n.message} />}
          onInputChange={(e) => {
            e.persist();
            setNotes((prev) => ({
              ...prev,
              otherNotes: prev.otherNotes?.map((note, oIdx) =>
                idx === oIdx ? { ...note, message: e.target.value } : note,
              ),
            }));
          }}
        />
      ))}
      <form onSubmit={onAddNote} className="flex px-6 py-4">
        <Input
          disabled={isFuncBtnDisabled}
          className="flex-1"
          placeholder={intl.formatMessage({
            id: 'common.label.enterNote',
            defaultMessage: 'Enter note',
          })}
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
        />
        <Button
          disabled={isFuncBtnDisabled || !newNote || isModifying}
          size="large"
          className="text-sm"
          htmlType="submit"
          style={{ width: 130 }}
        >
          <FormattedMessage
            defaultMessage="Add note"
            id="bookings.actions.addNote"
          />
        </Button>
      </form>
    </Card>
  );
};

export default BookingNote;
