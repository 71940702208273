import { currencyFormater } from 'utils/currencyFormater';
import { Money } from 'types/schema';
export type PriceType = { [k: string]: number };
export type TotalCurrency = { total: number; currency: string };

type CalcResult = string | TotalCurrency;

export function calcTotalAmount(
  price: Money = {},
  currencies: Money = {},
  formated: boolean = true,
): CalcResult {
  const currencyKey = price.currency as keyof Money;
  const rate = (currencies[currencyKey] as number) || 1;

  const total = (price.amount || 0) * rate;
  const currency = currencyKey;

  if (formated) {
    return currencyFormater(total, currency);
  }
  return { total, currency };
}
