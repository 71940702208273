import { takeEvery, put, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { voidBookingChargesSuccess, voidBookingChargesFail } from '../actions';
import { VOID_CHARGES_OF_BOOKING } from '../constants';
import { showErrorNotification } from '@aha/utils';

function* doVoidCharges({ bid, reject, resolve }) {
  try {
    const { data } = yield call(coreAPI.post, `v1/pms/bookings/${bid}/void`);
    yield put(voidBookingChargesSuccess(data));
    resolve(data);
  } catch (err) {
    yield put(voidBookingChargesFail(err));
    showErrorNotification('Void booking charges failed', err);
    reject(err);
  }
}

export function* watchVoidBookingCharges() {
  yield takeEvery(VOID_CHARGES_OF_BOOKING, doVoidCharges);
}
