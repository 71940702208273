import React from 'react';
import usePermission from './usePermission';
import { Tooltip } from 'antd';

export default function PermissionTooltipWrapper({ allow, children }) {
  const isAllowed = usePermission(allow);

  return isAllowed ? (
    children
  ) : (
    <Tooltip title="You don't have permission">
      <div
        className="opacity-50 inline-block relative w-full"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <span className="absolute block inset-0" />
        <span className="pointer-events-none">{children}</span>
      </div>
    </Tooltip>
  );
}
