import React from 'react';
import Autocomplete, { AutoCompleteProps } from 'antd/lib/auto-complete';
import { createAntField } from '../CreateAntField';
import { Field } from 'react-final-form';

// @ts-ignore
const FormAutocompleteWrapper = createAntField<AutoCompleteProps>(Autocomplete);

export interface FormAutocompleteProps extends AutoCompleteProps {
  name: string;
  label?: React.ReactNode;
  options: FormAutocompleteOptions;
  help?: React.ReactNode;
  required?: boolean;
}

export type FormAutocompleteOptions = {
  name: string | React.ReactNode;
  value: string | number;
}[];

const FormAutoComplete = ({ options, ...props }: FormAutocompleteProps) => {
  return (
    <Field
      {...props}
      component={FormAutocompleteWrapper}
      selectOptions={options}
      autoComplete
    />
  );
};

export default FormAutoComplete;
