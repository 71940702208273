import { takeEvery, put, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { noShowBookingSuccess, noShowBookingFail } from '../actions';
import { NO_SHOW_BOOKING } from '../constants';
import { showErrorNotification } from '@aha/utils';

function* doNoShowBooking({ booking, reject, resolve }) {
  try {
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/bookings/${booking.id}/noshow`,
    );
    yield put(noShowBookingSuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification('No show booking failed', err);
    yield put(noShowBookingFail(err));
    reject(err);
  }
}

export function* watchNoShowBooking() {
  yield takeEvery(NO_SHOW_BOOKING, doNoShowBooking);
}
