import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Button } from 'antd';
import navItems from 'components/GlobalSidebar/navItems';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeSelectHotelLoading,
  makeSelectCollapsed,
  makeSelectCurrentHotelId,
  makeSelectCurrentHotel,
  ModelHotelWithFeatures,
} from './selectors';
import { createStructuredSelector } from 'reselect';
import { fetchUser } from 'containers/Auth/actions';
import { fetchCurrentHotel, toggleCollapsed } from './actions';
import {
  makeSelectIsLogin,
  makeSelectUser,
  makeSelectUserIsLoading,
  makeSelectUserError,
} from 'containers/Auth/selectors';
import { Redirect, WindowLocation } from '@reach/router';
import { useInjectSaga } from 'utils/injectSaga';
import authSaga from 'containers/Auth/saga';
import curreniesSaga from 'containers/CurrencySettings/saga';
import DashboardSkeleton from 'components/skeleton/pages/DashboardSkeleton';
import { useMedia } from '@aha/utils';
import dashboardSaga from './saga';
import bkSaga from 'containers/BookingProvider/saga';
import bkReducer from 'containers/BookingProvider/reducer';
import { fetchCurrencies } from '../CurrencySettings/actions';
import CurrencyActionTypes from '../CurrencySettings/constants';
import LoadScreenSwitcher from './LoadScreenSwitcher';
import ROUTES from 'constants/routes';
import { fetchHotels } from 'containers/HotelsPage/actions';
import HotelActionTypes from 'containers/HotelsPage/constants';
import hotelsSaga from 'containers/HotelsPage/saga';
import lockDoorSaga from 'containers/LockDoorPage/saga';
import { logOut } from 'containers/Auth/actions';

import { Layout } from '@aha/ui';
import { useSelectPermissions } from 'components/permission/usePermission';
import ExtraHeader from 'components/GlobalHeader/ExtraHeader';
import { navigate } from '@reach/router';
import {
  makeSelectActiveHotels,
  makeSelectHotels,
} from 'containers/HotelsPage/selectors';
import { ApplicationRootState } from 'types/app';
import { Dispatch } from 'redux';
import { LayoutSidebarMenuItem } from 'components/Layout/Sidebar';
import { PermissionType } from 'components/permission/type';
import { lockDoorAPI } from 'utils/request';
import { AdelConfig } from 'containers/LockDoorPage/type';
import { startService } from 'containers/LockDoorPage/actions';
import { defaultExpiredDates } from 'containers/BookingProvider/helpers/useTab';
import AlertExpiredBookings, { DEFAULT_ALERT_HEIGHT } from './Alert';
import { useInjectReducer } from 'utils/injectReducer';
import { makeSelectExpiredBookingCount } from 'containers/BookingProvider/selector';
import { fetchExpiredBookings } from 'containers/BookingProvider/actions';
import { Hotel, AuthMe } from 'types/schema';

interface DashboardProps {
  children: any;
  location: WindowLocation;
}

type NewNavMenuItem = LayoutSidebarMenuItem<{ allow?: PermissionType }> & {
  parentId?: string;
};

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    collapsed: boolean;
    isLogin: boolean;
    currentHotelId: number | null;
    user: AuthMe | null;
    userIsLoading: boolean;
    userLoadError: Error | null;
    hotelIsLoading: boolean;
    currentHotel: ModelHotelWithFeatures | null;
    activeHotels: Hotel[];
    hotels: Hotel[];
    expiredBkCount: number;
  }
>({
  collapsed: makeSelectCollapsed(),
  isLogin: makeSelectIsLogin(),
  currentHotelId: makeSelectCurrentHotelId(),
  user: makeSelectUser(),
  userIsLoading: makeSelectUserIsLoading(),
  userLoadError: makeSelectUserError(),
  hotelIsLoading: makeSelectHotelLoading(),
  currentHotel: makeSelectCurrentHotel(),
  activeHotels: makeSelectActiveHotels(),
  hotels: makeSelectHotels(),
  expiredBkCount: makeSelectExpiredBookingCount(),
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchCurrentUser: () => dispatch(fetchUser()),
    fetchCurrentHotel: (hotelId: number) =>
      dispatch(fetchCurrentHotel(hotelId)),
    doFetchCurrencies: (hotelId: number) => dispatch(fetchCurrencies(hotelId)),
    toggleCollapsed: () => dispatch(toggleCollapsed()),
    doFetchHotels: () => dispatch(fetchHotels()),
    doStartService: (cfg: AdelConfig) => dispatch(startService(cfg)),
    doLogout: () => dispatch(logOut()),
    doFetchExpiredBookings: (arrival: string, departure: string) =>
      dispatch(fetchExpiredBookings(arrival, departure)),
  };
};

export const isLocationPathContainLink = (
  isExternal: boolean,
  haveParent: boolean,
  pathName = '',
  navLink: string,
) => {
  return (
    isExternal &&
    pathName &&
    (pathName.startsWith(navLink) ||
      (haveParent &&
        pathName.split('/').length === 3 &&
        pathName.split('/')[1] === navLink.split('/')[1]) ||
      (pathName.split('/').length === 4 &&
        pathName.split('/')[1] === navLink.split('/')[1] &&
        pathName.split('/')[3] === navLink.split('/')[3]))
  );
};

export function Dashboard({ children, location }: DashboardProps) {
  useInjectSaga({ key: 'auth', saga: authSaga });
  useInjectSaga({ key: 'dashboard', saga: dashboardSaga });
  useInjectSaga({ key: 'bookings', saga: bkSaga });
  useInjectReducer({ key: 'bookings', reducer: bkReducer });

  useInjectSaga({
    key: CurrencyActionTypes.CURRENCIES_KEY,
    saga: curreniesSaga,
  });
  useInjectSaga({ key: HotelActionTypes.HOTELS_KEY, saga: hotelsSaga });
  useInjectSaga({ key: 'lockDoor', saga: lockDoorSaga });
  // FIXME:  PLEASE DON'T REMOVE THIS, we wait for apis /bookings done to back working on this
  // should revert it back to undefined
  const [expiredBks, setExpiredBks] = useState<number | undefined>(0);
  const [alertHeight, setAlertHeight] = useState(DEFAULT_ALERT_HEIGHT);

  const dispatch = useDispatch();
  const {
    collapsed,
    isLogin,
    currentHotelId,
    user,
    userIsLoading,
    userLoadError,
    hotelIsLoading,
    currentHotel,
    activeHotels,
    hotels,
    expiredBkCount,
  } = useSelector(mapStateToProps);

  const {
    fetchCurrentHotel,
    fetchCurrentUser,
    doStartService,
    toggleCollapsed,
    doFetchHotels,
    doFetchExpiredBookings,
  } = mapDispatchToProps(dispatch);

  const permissions = useSelectPermissions();
  const isOnMobile = useMedia('lg');

  const shouldNotShowFeature = (item: LayoutSidebarMenuItem) => {
    return (
      (item.id === 'sidebar.menu.night_bookings' &&
        !currentHotel?.isEnabledNightlyBooking) ||
      (item.id === 'sidebar.menu.hourly_bookings' &&
        !currentHotel?.isEnabledHourlyBooking) ||
      (item.id === 'sidebar.menu.daily_bookings' &&
        !currentHotel?.isEnabledHourlyBooking &&
        !currentHotel?.isEnabledNightlyBooking)
    );
  };

  const validMenus = useMemo(
    () =>
      navItems
        .filter((item) =>
          !item.allow ? true : permissions.includes(item.allow),
        )
        .reduce((res: typeof navItems, item) => {
          const submenu = item.submenu
            ?.filter((item) =>
              !item.allow ? true : permissions.includes(item.allow),
            )
            .filter((item) => !shouldNotShowFeature(item));

          if (!submenu) {
            return [...res, item];
          }

          if (!submenu.length) {
            const { submenu, ...rest } = item;
            return [...res, rest];
          }

          return [...res, { ...item, submenu }];
        }, []),
    [permissions.length, currentHotel], // eslint-disable-line
  );

  const { childId, parentId } = useMemo(() => {
    let newNavItems = validMenus.reduce(
      (res: NewNavMenuItem[], navItem) => [
        ...res,
        ...(navItem.submenu
          ? navItem.id === 'sidebar.menu.bookings'
            ? navItem.submenu.map((item) => ({ ...item, parentId: navItem.id }))
            : navItem.submenu.map((item) => ({ ...item, parentId: navItem.id }))
          : [navItem]),
      ],
      [],
    );

    const dashboardItem = newNavItems.shift();
    if (dashboardItem) {
      const { link: dashboardLink, id } = dashboardItem;
      const item = newNavItems.find((navItem: any, idx: any) => {
        return isLocationPathContainLink(
          !navItem?.external,
          !navItem.parentId,
          location?.pathname,
          navItem.link,
        );
      });
      return {
        parentId: item?.parentId,
        childId:
          item?.id || (dashboardLink === location?.pathname ? id : undefined),
      };
    }
    return {
      parentId: '',
      childId: '',
    };
  }, [location?.pathname, validMenus]); // eslint-disable-line

  useEffect(() => {
    if (currentHotel?.id) {
      const [arr, dep] = defaultExpiredDates;
      doFetchExpiredBookings(arr, dep);
    }
  }, [currentHotel?.id]); // eslint-disable-line

  useEffect(() => {
    setExpiredBks(expiredBkCount || 0);
  }, [expiredBkCount]);

  useEffect(() => {
    // Prevent useEffect call when running test
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    if (!isLogin || !currentHotelId) {
      return;
    }
    // only fetch user when use selected a hotel
    if (currentHotelId && !user) {
      fetchCurrentUser();
    }

    if (!hotels?.length) {
      doFetchHotels();
    }

    if (!currentHotel) {
      fetchCurrentHotel(currentHotelId);
    }
  }, [user]); // eslint-disable-line

  useEffect(() => {
    async function startLockDoor() {
      try {
        const lockDoorCfgStr = localStorage.getItem('lockDoorCfg');
        const lockDoorCfg = JSON.parse(lockDoorCfgStr || '');

        if (lockDoorCfg?.selectedLockDoorSystem) {
          if (lockDoorCfg.selectedLockDoorSystem === 'AdelLock') {
            const { Status } = await lockDoorAPI.get('/api/v1/status');
            const cfg = {
              ...lockDoorCfg.Adel,
              username: user?.email,
            };

            if (!Status) {
              doStartService(cfg);
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    const isEnabledLockDoor = !!currentHotel?.enabledFeatures?.includes(
      'lock_door_intergration',
    );

    if (user && isEnabledLockDoor) {
      startLockDoor();
    }
  }, [user, currentHotel]); // eslint-disable-line

  if (!isLogin) {
    return <Redirect to={ROUTES.LOGIN} noThrow />;
  }

  if (!currentHotelId) {
    return <Redirect to={ROUTES.SELECT_HOTEL} noThrow />;
  }

  if (
    userIsLoading ||
    hotelIsLoading ||
    !user ||
    !currentHotel ||
    typeof expiredBks !== 'number'
  ) {
    return <DashboardSkeleton />;
  }

  if (userLoadError) {
    return (
      <div className="flex items-center justify-center h-screen">
        {userLoadError.message}
        <Button onClick={() => fetchCurrentUser()}>Try again</Button>
      </div>
    );
  }

  return (
    <Layout>
      {expiredBks > 0 && (
        <AlertExpiredBookings
          expiredBks={expiredBks}
          setExpiredBks={setExpiredBks}
          onSize={(_, h) => setAlertHeight(h)}
        />
      )}

      <Layout.Header
        logoHiddenOnMobile
        toggleCollapsed={toggleCollapsed}
        onClick={() => navigate('/')}
        extraRight={
          <ExtraHeader
            isOnMobile={isOnMobile}
            collapsed={collapsed}
            showSwitchHotel={activeHotels.length > 1}
          />
        }
      />
      <Layout>
        <Layout.Sidebar
          collapsed={collapsed}
          menu={validMenus}
          selectedItem={childId || ''}
          openItemKeys={parentId ? [parentId] : []}
          onItemClick={(item) => {
            if (item.external) {
              // TODO: not recommend
              // more info https://stackoverflow.com/questions/4907843/open-a-url-in-a-new-tab-and-not-a-new-window-using-javascript
              const win = window.open(item.link, '_blank');
              win && win.focus();
              return;
            }

            navigate(item.link);
            if (collapsed && isOnMobile) {
              toggleCollapsed();
            }
          }}
          isOnMobile={isOnMobile}
          onMobileMenuClose={toggleCollapsed}
        />
        <Layout>
          <Layout.Content hasAlert={expiredBks > 0} alertHeight={alertHeight}>
            <Suspense fallback={<LoadScreenSwitcher />}>{children}</Suspense>
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
