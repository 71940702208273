import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import tw from 'twin.macro';
import translate from 'utils/translate';
import messages from 'utils/messages/roomPlan';
import { DateTimeFormatter } from '@aha/ui';
import { RoomAvailabilityLog } from 'types/schema';

const StyledBadge = styled.div`
  ${tw`rounded-bl-sm rounded-br-sm`}
  min-height: 87px;
  figure {
    ${tw`absolute leading-none uppercase top-0 left-0 text-2xs`}
    padding: 2px 4px;
  }
`;

const NowBadge = styled(StyledBadge)`
  ${tw`bg-white`}
  figure {
    ${tw`bg-blue-lighter text-blue`}
  }
`;

const FutureBadge = styled(StyledBadge)`
  ${tw`bg-grey-light`}
  figure {
    ${tw`text-white bg-grey-darker`}
  }
`;

export type OOOType = 'now' | 'future';

const badgeType: Record<OOOType, React.ElementType> = {
  now: NowBadge,
  future: FutureBadge,
};

export type OOOBadgeProps = {
  log: RoomAvailabilityLog;
  className?: string;
  type: OOOType;
  renderButtons?: React.ReactNode;
};

export const OOOBadge: React.FC<OOOBadgeProps> = ({
  className = '',
  log,
  type,
  renderButtons,
}) => {
  const Container = badgeType[type];
  return (
    <Container className={classnames(['relative p-6', className])}>
      <figure>{type}</figure>
      <div className="mb-1 font-medium capitalize text-red text-2xs">
        {translate(log?.reason?.description, messages)}
      </div>
      <div className="flex items-center">
        <span className="mr-1 font-medium">
          <FormattedMessage
            defaultMessage="Out of order time:"
            id="common.action.outOfOrderTime"
          />
        </span>
        <span className="mr-3">
          <DateTimeFormatter
            value={log.unAvailableFrom}
            format="HH:mm, DD MMM YYYY"
          />
          <span className="mx-1">-</span>
          <DateTimeFormatter
            value={log.availableDate}
            format="HH:mm, DD MMM YYYY"
          />
        </span>
        {renderButtons}
      </div>
    </Container>
  );
};

export default OOOBadge;
