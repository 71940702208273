import { all, call, put, takeLatest } from 'redux-saga/effects';
import { coreAPI } from 'utils/request';
import {
  addFolioPaymentSuccess,
  addFolioChargeSuccess,
  voidFolioChargesFail,
  voidFolioChargesSuccess,
  updateFolioChargeSuccess,
  updateFolioChargeFail,
  updateFolioPaymentSuccess,
  updateFolioPaymentFail,
  voidFolioFail,
  addFolioSuccess,
  addFolioFail,
  voidFolioPaymentSuccess,
  voidFolioPaymentFail,
} from '../actions';
import {
  ADD_FOLIO_PAYMENT,
  ADD_FOLIO_CHARGE,
  VOID_FOLIO_CHARGES,
  UPDARE_FOLIO_CHARGE,
  UPDATE_FOLIO_PAYMENT,
  VOID_FOLIO_PAYMENT,
  VOID_FOLIO,
  ADD_FOLIO,
} from '../constants';
import { showErrorNotification, showSuccessNotification } from '@aha/utils';

function* doAddPayment({ bid, fid, reqData, reqType, resolve, reject }) {
  try {
    let url = `v1/pms/bookings/${bid}/folios/${fid}/payments`;
    if (reqType === 'refund') {
      url = `v1/pms/bookings/${bid}/folios/${fid}/refunds`;
    }
    const { data } = yield call(coreAPI.post, url, reqData);
    yield put(addFolioPaymentSuccess(data));
    resolve(data);
    showSuccessNotification('Add payment success');
  } catch (err) {
    showErrorNotification('Add payment failed', err);
    reject(err);
  }
}

export function* watchAddFolioPayment(params) {
  yield takeLatest(ADD_FOLIO_PAYMENT, doAddPayment);
}

function* doAddCharge({ bid, fid, reqData, reqType, resolve, reject }) {
  try {
    let url = `v1/pms/bookings/${bid}/folios/${fid}/charges`;
    if (reqType === 'discount') {
      url = `v1/pms/bookings/${bid}/folios/${fid}/discount`;
    }
    const { data } = yield call(coreAPI.post, url, reqData);
    yield put(addFolioChargeSuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification('Add charge failed', err);
    reject(err);
  }
}

export function* watchAddFolioCharge() {
  yield takeLatest(ADD_FOLIO_CHARGE, doAddCharge);
}

function* doVoidCharges({ bid, fid, chargeIds, resolve, reject }) {
  try {
    const resp = yield all(
      (chargeIds || []).map((id) =>
        call(
          coreAPI.put,
          `v1/pms/bookings/${bid}/folios/${fid}/charges/${id}/void`,
        ),
      ),
    );
    const charges = resp.map(({ data }) => data);
    yield put(voidFolioChargesSuccess(charges));
    resolve(charges);
  } catch (err) {
    showErrorNotification('Void charge failed', err);
    yield put(voidFolioChargesFail(err));
    reject(err);
  }
}

export function* watchVoidFolioCharges() {
  yield takeLatest(VOID_FOLIO_CHARGES, doVoidCharges);
}

function* doUpdateCharge({
  bookingID,
  folioID,
  chargeID,
  reqData,
  resolve,
  reject,
}) {
  try {
    const { data } = yield call(
      coreAPI.put,
      `v1/pms/bookings/${bookingID}/folios/${folioID}/charges/${chargeID}`,
      reqData,
    );
    yield put(updateFolioChargeSuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification('Update charge failed', err);
    yield put(updateFolioChargeFail(err));
    reject(err);
  }
}

export function* watchUpdateFolioCharge() {
  yield takeLatest(UPDARE_FOLIO_CHARGE, doUpdateCharge);
}

function* doUpdatePayment({ bid, fid, paymentId, reqData, resolve, reject }) {
  try {
    const { data } = yield call(
      coreAPI.put,
      `v1/pms/bookings/${bid}/folios/${fid}/payments/${paymentId}`,
      reqData,
    );
    yield put(updateFolioPaymentSuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification('Update payment failed', err);
    yield put(updateFolioPaymentFail(err));
    reject(err);
  }
}

export function* watchUpdateFolioPayment() {
  yield takeLatest(UPDATE_FOLIO_PAYMENT, doUpdatePayment);
}

function* doVoidPayment({ bid, fid, paymentId, resolve, reject }) {
  try {
    const { data } = yield call(
      coreAPI.put,
      `v1/pms/bookings/${bid}/folios/${fid}/payments/${paymentId}/void`,
    );
    yield put(voidFolioPaymentSuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification('Void payment failed', err);
    yield put(voidFolioPaymentFail(err));
    reject(err);
  }
}

export function* watchVoidFolioPayment() {
  yield takeLatest(VOID_FOLIO_PAYMENT, doVoidPayment);
}

function* doVoidFolio({ bid, fid, resolve, reject }) {
  try {
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/bookings/${bid}/folios/${fid}/void`,
    );
    yield put(voidFolioFail(data));
    resolve(data);
  } catch (err) {
    showErrorNotification('Void folio failed', err);
    yield put(voidFolioFail(err));
    reject(err);
  }
}

export function* watchVoidFolio() {
  yield takeLatest(VOID_FOLIO, doVoidFolio);
}

function* doAddFolio({ bid, body, resolve, reject }) {
  try {
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/bookings/${bid}/folios`,
      body,
    );
    yield put(addFolioSuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification('Add new folio failed', err);
    yield put(addFolioFail(err));
    reject(err);
  }
}

export function* watchAddFolio() {
  yield takeLatest(ADD_FOLIO, doAddFolio);
}
