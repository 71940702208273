import React, { useState, useRef, forwardRef } from 'react';
import { AutoComplete } from 'antd';
import _ from 'lodash';
import { coreAPI } from 'utils/request';
import { Guest, GuestResponse } from 'types/schema';

export interface OnLoadUsersProps {
  value: string;
  searchBy: keyof Guest;
  existedGuess: Array<string>;
}

interface TypeProps {
  [key: string]: string;
}

export type GuestOption = {
  key?: string;
  value?: string;
  text: string;
  user: Guest;
};

async function onLoadUsers({
  value,
  searchBy,
  existedGuess,
}: OnLoadUsersProps) {
  const byType: TypeProps = {
    email: 'email',
    passport: 'passport',
    phone: 'phone',
    firstName: 'name',
    fullName: 'name',
    lastName: 'name',
  };

  if (!value || !searchBy || !byType[searchBy]) {
    return [];
  }

  try {
    const dataResponse: GuestResponse = await coreAPI.get(
      `v1/pms/guests?value=${value}&type=${byType[searchBy]}&pageNumber=1&pageSize=20`,
    );
    const data = dataResponse.data?.filter(
      (d) => !existedGuess.includes(d.id?.toString() || ''),
    );
    const options: GuestOption[] = (data || []).map((user, idx) => {
      let text =
        user.fullName ||
        [user.firstName, user.lastName].filter(Boolean).join(' ');
      if (byType[searchBy] !== 'name') {
        text = `${text} - ${user[searchBy]}`;
      }
      return {
        value: user.id?.toString(),
        text,
        user,
      };
    });
    return _.uniqBy(options, 'value');
  } catch (e) {
    return [];
  }
}

function GuestSelect(props: any, ref: any) {
  const { searchBy, onResponse, existedGuess = [], value, ...rest } = props;
  const [dataSource, setDataSource] = useState<GuestOption[]>();
  const autocompleteSearchDebounce = useRef(
    _.debounce(autocompleteSearch, 800),
  );

  async function autocompleteSearch(value: string) {
    const options = await onLoadUsers({ value, searchBy, existedGuess });
    if (options) setDataSource(options);
  }

  function onSearch(value: string) {
    if (typeof onResponse === 'function') {
      onResponse(searchBy, value);
    }
    autocompleteSearchDebounce.current(value);
  }

  function onSelect(value: string) {
    if (typeof onResponse === 'function') {
      onResponse(
        searchBy,
        value,
        _.find(dataSource, (f) => f.value === value),
      );
    }
  }

  return (
    <AutoComplete
      {...rest}
      ref={ref}
      allowClear
      value={value}
      dataSource={dataSource}
      onSelect={onSelect}
      onSearch={onSearch}
      optionFilterProp="children"
    />
  );
}

export default forwardRef(GuestSelect);
