import { initialState } from './reducer';
import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { Hotel } from 'types/schema';

export const selectDashboard = (state: ApplicationRootState) =>
  state.dashboard || initialState;

export const selectDashboardHotel = (state: ApplicationRootState) =>
  (state.dashboard || initialState).hotel;

export const makeSelectDashboard = () =>
  createSelector(selectDashboard, (dashboard) => dashboard);

export const makeSelectCollapsed = () =>
  createSelector(selectDashboard, (dashboard) => dashboard.collapsed);

export const makeSelectCurrentHotelId = () =>
  createSelector(selectDashboard, (dashboard) => dashboard.hotelId);

export type ModelHotelWithFeatures = Hotel & {
  isEnabledLockdoor: boolean;
  isEnabledHourlyBooking: boolean;
  isEnabledNightlyBooking: boolean;
  isEnabledWarehouse: boolean;
  isEnableLoyalty: boolean;
  enabledFeatures?: string[];
};

export const makeSelectCurrentHotel = () =>
  createSelector(selectDashboardHotel, (hotel) => {
    if (!hotel) {
      return null;
    }
    const h = { ...hotel } as ModelHotelWithFeatures;
    // TODO: Specify the type after having migrated lock door
    if (hotel) {
      h.isEnabledLockdoor = (hotel?.enabledFeatures || []).some(
        (f) => f.code === 'lock_door_intergration',
      );

      h.isEnabledHourlyBooking = (hotel?.enabledFeatures || []).some(
        (f) => f.code === 'hourly_booking',
      );

      h.isEnabledNightlyBooking = (hotel?.enabledFeatures || []).some(
        (f) => f.code === 'nightly_booking',
      );

      h.isEnabledWarehouse = (hotel?.enabledFeatures || []).some(
        (f) => f.code === 'warehouse_management',
      );

      h.isEnableLoyalty = (hotel?.enabledFeatures || []).some(
        (f) => f.code === 'loyalty_membership',
      );

      h.enabledFeatures = hotel?.enabledFeatures
        ?.map((f) => f.code || '')
        .filter(Boolean);
    }

    return h;
  });

export const makeSelectHotelLoading = () =>
  createSelector(selectDashboard, (dashboard) => dashboard.hotelLoading);

export const makeSelectHotelSwitching = () =>
  createSelector(selectDashboard, (dashboard) => dashboard.hotelSwitching);

export const makeSelectCurrencies = () =>
  createSelector(selectDashboard, (dashboard) => {
    const [vnd] = (dashboard.currencies || []).filter(
      ({ code }) => code?.toLocaleLowerCase() === 'vnd',
    );
    if (vnd) {
      return [
        vnd,
        ...dashboard.currencies?.filter(
          ({ code }) => code?.toLocaleLowerCase() !== 'vnd',
        ),
      ];
    }
    return dashboard.currencies || [];
  });

export const makeSelectColors = () =>
  createSelector(selectDashboard, (dashboard) => dashboard.colors || []);

export const makeSelectCities = () =>
  createSelector(selectDashboard, (dashboard) => dashboard.cities);

export const makeSelectDistricts = () =>
  createSelector(selectDashboard, (dashboard) => dashboard.districts);

export const makeSelectFacilities = () =>
  createSelector(selectDashboard, (dashboard) => dashboard.facilities);

export const makeSelectAmenities = () =>
  createSelector(selectDashboard, (dashboard) => dashboard.amenities);
