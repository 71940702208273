export const HOURLY_BOOKING_KEY = 'hourlyBooking';

export const FETCH_HOURLY_BOOKING = 'hourlyBooking/FETCH_HOURLY_BOOKING';
export const FETCH_HOURLY_BOOKING_SUCCSESS =
  'hourlyBooking/FETCH_HOURLY_BOOKING_SUCCSESS';
export const FETCH_HOURLY_BOOKING_FAIL =
  'hourlyBooking/FETCH_HOURLY_BOOKING_FAIL';

export const FETCH_HOURLY_BOOKING_CHANGE_LOGS =
  'hourlyBooking/FETCH_HOURLY_BOOKING_CHANGE_LOGS';
export const FETCH_HOURLY_BOOKING_CHANGE_LOGS_SUCCSESS =
  'hourlyBooking/FETCH_HOURLY_BOOKING_CHANGE_LOGS_SUCCSESS';
export const FETCH_HOURLY_BOOKING_CHANGE_LOGS_FAIL =
  'hourlyBooking/FETCH_HOURLY_BOOKING_CHANGE_LOGS_FAIL';

export const VOID_HOURLY_BOOKING = 'hourlyBooking/VOID_HOURLY_BOOKING';
export const VOID_HOURLY_BOOKING_SUCCSESS =
  'hourlyBooking/VOID_HOURLY_BOOKING_SUCCSESS';
export const VOID_HOURLY_BOOKING_FAIL =
  'hourlyBooking/VOID_HOURLY_BOOKING_FAIL';

export const VOID_HOURLY_BOOKING_CHARGE =
  'hourlyBooking/VOID_HOURLY_BOOKING_CHARGE';
export const VOID_HOURLY_BOOKING_CHARGE_SUCCESS =
  'hourlyBooking/VOID_HOURLY_BOOKING_CHARGE_SUCCESS';
export const VOID_HOURLY_BOOKING_CHARGE_FAIL =
  'hourlyBooking/VOID_HOURLY_BOOKING_CHARGE_FAIL';

export const ADD_HOURLY_BOOKING_CHARGE =
  'hourlyBooking/ADD_HOURLY_BOOKING_CHARGE';
export const ADD_HOURLY_BOOKING_CHARGE_SUCCESS =
  'hourlyBooking/ADD_HOURLY_BOOKING_CHARGE_SUCCESS';
export const ADD_HOURLY_BOOKING_CHARGE_FAIL =
  'hourlyBooking/ADD_HOURLY_BOOKING_CHARGE_FAIL';

export const VOID_HOURLY_BOOKING_PAYMENT =
  'hourlyBooking/VOID_HOURLY_BOOKING_PAYMENT';
export const VOID_HOURLY_BOOKING_PAYMENT_SUCCESS =
  'hourlyBooking/VOID_HOURLY_BOOKING_PAYMENT_SUCCESS';
export const VOID_HOURLY_BOOKING_PAYMENT_FAIL =
  'hourlyBooking/VOID_HOURLY_BOOKING_CHARGE_FAIL';

export const ADD_HOURLY_BOOKING_PAYMENT =
  'hourlyBooking/ADD_HOURLY_BOOKING_PAYMENT';
export const ADD_HOURLY_BOOKING_PAYMENT_SUCCESS =
  'hourlyBooking/ADD_HOURLY_BOOKING_PAYMENT_SUCCESS';
export const ADD_HOURLY_BOOKING_PAYMENT_FAIL =
  'hourlyBooking/VOID_HOURLY_BOOKING_CHARGE_FAIL';

export const CHANGE_HOURLY_BOOKING_ROOM =
  'hourlyBooking/CHANGE_HOURLY_BOOKING_ROOM';
export const CHANGE_HOURLY_BOOKING_ROOM_SUCCESS =
  'hourlyBooking/CHANGE_HOURLY_BOOKING_ROOM_SUCCESS';
export const CHANGE_HOURLY_BOOKING_ROOM_FAIL =
  'hourlyBooking/CHANGE_HOURLY_BOOKING_ROOM_FAIL';

export const CHECKOUT_HOURLY_BOOKING = 'hourlyBooking/CHECKOUT_HOURLY_BOOKING';
export const CHECKOUT_HOURLY_BOOKING_SUCCESS =
  'hourlyBooking/CHECKOUT_HOURLY_BOOKING_SUCCESS';
export const CHECKOUT_HOURLY_BOOKING_FAIL =
  'hourlyBooking/CHANGE_HOURLY_BOOKING_ROOM_FAIL_FAIL';

export const ADD_HOURLY_BOOKING_DISCOUNT =
  'hourlyBooking/ADD_HOURLY_BOOKING_DISCOUNT';
export const ADD_HOURLY_BOOKING_DISCOUNT_SUCCESS =
  'hourlyBooking/ADD_HOURLY_BOOKING_DISCOUNT_SUCCESS';
export const ADD_HOURLY_BOOKING_DISCOUNT_FAIL =
  'hourlyBooking/ADD_HOURLY_BOOKING_DISCOUNT_FAIL';

export const ADD_HOURLY_BOOKING_REFUND =
  'hourlyBooking/ADD_HOURLY_BOOKING_REFUND';
export const ADD_HOURLY_BOOKING_REFUND_SUCCESS =
  'hourlyBooking/ADD_HOURLY_BOOKING_REFUND_SUCCESS';
export const ADD_HOURLY_BOOKING_REFUND_FAIL =
  'hourlyBooking/ADD_HOURLY_BOOKING_REFUND_FAIL';

export const REMOVE_BOOKING_DETAIL = 'hourlyBooking/REMOVE_BOOKING_DETAIL';
