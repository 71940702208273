import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { Link } from '@reach/router';
import { Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectCurrentHotel,
  makeSelectCurrentHotelId,
  makeSelectHotelSwitching,
} from 'containers/Dashboard/selectors';
import { switchCurrentHotel } from 'containers/Dashboard/actions';
import tw from 'twin.macro';
import styled from 'styled-components';
import { InputSearch, Button } from '@aha/ui';
import { makeSelectActiveHotels } from 'containers/HotelsPage/selectors';
import { FormattedMessage } from 'react-intl';
import { ApplicationRootState } from 'types/app';
import { Dispatch } from 'redux';
import { Hotel } from 'types/schema';
import {
  IconListUl,
  IconHambuger,
  IconCheck,
  IconCog,
  IconSortDown,
} from '@aha/icons';

const ManageButton = styled.div`
  ${tw`text-secondary h-10 bg-grey-lightest w-full border-t border-grey-lightest flex items-center px-4`}
  height: 44px;
`;

const SwitchDiv = styled.div`
  width: 270px;
  margin: -12px -16px;
`;

const HotelName = styled.div`
  ${tw`text-secondary text-sm truncate ml-2`};
  width: 165px;
`;

const HotelList = styled.div`
  ${tw`pb-2 border-none`};
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 264px;
`;

interface SwitchHotelDropdownProps {
  isOnMobile?: boolean;
  collapsed?: boolean;
}

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    activeHotels: Hotel[];
    currentHotelId: number | null;
    loading: boolean;
    currentHotel: Hotel | null;
  }
>({
  activeHotels: makeSelectActiveHotels(),
  currentHotelId: makeSelectCurrentHotelId(),
  loading: makeSelectHotelSwitching(),
  currentHotel: makeSelectCurrentHotel(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  switchHotel: (id: number) => dispatch(switchCurrentHotel(id)),
});

const SwitchHotelDropdown = ({
  isOnMobile,
  collapsed,
}: SwitchHotelDropdownProps) => {
  const dispatch = useDispatch();
  const { switchHotel } = mapDispatchToProps(dispatch);
  const { activeHotels, currentHotelId, loading, currentHotel } = useSelector(
    mapStateToProps,
  );

  const [searchValue, setSearchValue] = useState<string>('');
  const [hotels, setHotels] = useState(activeHotels || []);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (hotels.length !== activeHotels.length) {
      setHotels(activeHotels);
    }
  }, [activeHotels]); // eslint-disable-line

  useEffect(() => {
    setSearchValue('');
    if (visible) {
      setHotels(activeHotels);
    }
  }, [visible]); // eslint-disable-line

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
    setHotels(
      activeHotels.filter(({ name }) =>
        name?.toLowerCase()?.includes(value.toLowerCase()),
      ),
    );
  };

  const content = useMemo(
    () =>
      !currentHotel ? null : (
        <SwitchDiv>
          <div className="px-4 pt-4 text-base text-secondary mb-1 font-medium truncate">
            {currentHotel.name}
          </div>
          <div className="px-4 text-xs uppercase text-grey-darker truncate">
            {[
              currentHotel.address,
              currentHotel.district,
              currentHotel.city,
              currentHotel.country,
            ]
              .filter(Boolean)
              .join(', ')}
          </div>
          <div className="p-4">
            <InputSearch
              autoFocus
              onChange={handleChange}
              value={searchValue}
              className="w-full h-9"
            />
          </div>
          <HotelList>
            {hotels.length === 0 ? (
              <div className="text-sm mx-4 mb-2">No result was found</div>
            ) : (
              hotels.map(({ id, name }) => (
                <a
                  href="/"
                  className={classnames(
                    'text-secondary py-2 px-4 border-b flex justify-between cursor-pointer hover:bg-grey-lighter',
                    {
                      'bg-grey-lighter pointer-events-none':
                        currentHotelId === id,
                    },
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentHotelId !== id && id) {
                      switchHotel(id);
                    }
                  }}
                  key={id}
                >
                  {name}
                  <span className="flex-none mt-1 ml-2 w-3 text-right">
                    {id === currentHotelId && <IconCheck />}
                  </span>
                </a>
              ))
            )}
          </HotelList>
          <Link
            to="/hotels"
            onClick={() => {
              setVisible(false);
            }}
          >
            <ManageButton>
              <IconCog className="mr-2" />
              <FormattedMessage
                defaultMessage="Manage hotels"
                id="settings.label.manage_hotels"
              />
            </ManageButton>
          </Link>
        </SwitchDiv>
      ),
    [hotels, currentHotelId, loading, searchValue], // eslint-disable-line
  );

  const showBadge = !isOnMobile;

  return (
    <div className={`flex cursor-pointer${isOnMobile ? ' mr-3' : ''}`}>
      {showBadge && (
        <div
          className="flex cursor-pointer items-center"
          onClick={(e) => {
            if (!visible) {
              setVisible(true);
            }
          }}
        >
          <IconHambuger className="text-xs text-secondary" />
          <HotelName>{currentHotel?.name}</HotelName>
        </div>
      )}
      <Popover
        content={content}
        placement={isOnMobile ? 'bottom' : 'bottomRight'}
        trigger="click"
        onVisibleChange={(visible) => {
          setVisible(visible);
        }}
        visible={visible}
      >
        <div className="w-8 flex items-center justify-center cursor-pointer text-3xs h-6">
          {showBadge ? (
            <IconSortDown />
          ) : (
            <Button icon={IconListUl} type="icon" />
          )}
        </div>
      </Popover>
    </div>
  );
};

export default React.memo(SwitchHotelDropdown);
