import moment from 'moment';
import { RatePlan } from 'types/schema';

export type PriceBooking = {
  arrival?: moment.Moment | string;
  departure?: moment.Moment | string;
  rateId?: string | number;
  adults: number;
  children: number;
};

export type CalcRatePricesResult = {
  total: number;
  price: number;
  extraPersonPrice: number;
  extraChildPrice: number;
  currency?: string;
  diffDate: number;
};

export function calcRatePrices(
  booking: PriceBooking,
  rateplans: RatePlan[] = [],
  { isTotalPrice = false, price = 0 } = {},
) {
  const { arrival, departure, rateId, adults, children } = booking;
  const diffDate = moment(departure)
    .startOf('day')
    .diff(moment(arrival).startOf('day'), 'days');

  const rate = rateplans.find((r) => `rID${r.id}` === rateId) || {
    price: isTotalPrice ? price / diffDate : price,
  };

  const blankDates = [...Array(diffDate).fill(0)].map((e, i) =>
    moment(arrival).add(i, 'day'),
  );

  return blankDates.reduce(
    (t: CalcRatePricesResult, d: moment.Moment) => {
      // create rule cell
      let { price } = rate; // default price is rate's price

      // find rate rule by service date
      let rule = (rate.rules || []).find(
        (r) =>
          !moment(d)
            .startOf('day')
            .diff(moment(r.serviceDate).startOf('day'), 'days'),
      );

      // replace the default rule
      if (rule) {
        // eslint-disable-next-line prefer-destructuring
        price = rule.price;
      } else {
        rule = { ...rate };
        if (rate?.weekendDates?.includes(moment(d).format('dddd'))) {
          // change price for weekend dates
          price = rate.weekendPrice;
        }
      }
      let extraPersonPrice = 0;
      const numOfAdult = Number(rule.numOfAdult),
        numOfChild = Number(rule.numOfChild),
        exPersonPrice = Number(rule.extraPersonPrice),
        exChildPrice = Number(rule.extraChildPrice);

      if (numOfAdult > 0 && numOfAdult > 0 && numOfAdult - adults < 0) {
        extraPersonPrice = Math.abs(numOfAdult - adults) * exPersonPrice;
      }

      let extraChildPrice = 0;
      if (numOfChild > 0 && numOfChild > 0 && numOfChild - children < 0) {
        extraChildPrice = Math.abs(numOfChild - children) * exChildPrice;
      }

      return {
        total: t.total + Number(price) + extraChildPrice + extraPersonPrice,
        price: t.price + Number(price),
        extraPersonPrice: t.extraPersonPrice + extraPersonPrice,
        extraChildPrice: t.extraChildPrice + extraChildPrice,
        currency: !t.currency ? rate?.currency?.name : t.currency,
        diffDate: blankDates.length,
      };
    },
    {
      total: 0,
      price: 0,
      extraPersonPrice: 0,
      extraChildPrice: 0,
      currency: undefined,
      diffDate: 1,
    },
  );
}
