import { takeLatest, put, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import {
  allocateRoomForHourlyBookingFail,
  allocateRoomForHourlyBookingSuccess,
} from '../actions';
import { ALLOCATE_ROOM_FOR_HOURLY_BOOKING } from '../constants';
import { showErrorNotification } from '@aha/utils';
import LockDoorAPI from '../loockdoor';

function* doAllocateHourlyBooking({
  booking,
  bookingRoomId,
  roomID,
  reject,
  resolve,
  lockdoor,
  oldLockDoorRoomNo,
  newLockDoorRoomNo,
}) {
  try {
    if (lockdoor && booking.status === 'checked_in') {
      const { roomNumber } = yield call(LockDoorAPI.readCard);
      // Do we need checkout it first
      if (oldLockDoorRoomNo !== roomNumber) {
        throw new Error('Invalid card! Card not belong to this booking');
      }
      yield call(LockDoorAPI.checkout, roomNumber);
      yield call(LockDoorAPI.checkin, booking, newLockDoorRoomNo);
    }
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/hourly-bookings/${booking.id}/allocate`,
      { roomId: roomID, bookingRoomId },
    );
    yield put(allocateRoomForHourlyBookingSuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification('Allocate room failed', err);
    yield put(allocateRoomForHourlyBookingFail(err));
    reject(err);
  }
}

export function* watchAllocateRoomForHourlyBooking() {
  yield takeLatest(ALLOCATE_ROOM_FOR_HOURLY_BOOKING, doAllocateHourlyBooking);
}
