import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { Link, navigate, Redirect } from '@reach/router';
import { Dropdown, Menu, Button as ButtonAnt, Tooltip, Divider } from 'antd';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { currencyFormater } from 'utils/currencyFormater';
import { useDispatch, useSelector } from 'react-redux';
import CopyToClipboard from 'components/CopyToClipboard';

import { makeSelectCurrentHotel } from '../Dashboard/selectors';
import { BOOKING_KEY } from '../BookingProvider/constants';
import { NOT_AVAILABLE } from '@aha/constants';

import {
  makeSelectBooking,
  makeSelectError,
  makeSelectLoading,
  makeSelectConversionRate,
} from '../BookingProvider/selector';

import reducer from '../BookingProvider/reducer';
import saga from '../BookingProvider/saga';
import rateReducer from 'containers/RatePlansPage/reducer';
import rateSaga from 'containers/RatePlansPage/saga';

import {
  fetchBooking,
  allocateRoomForBooking,
  noShowBooking,
  checkinBooking,
  checkoutBooking,
  cancelBooking,
  addFolio,
  voidFolio,
  voidBookingCharges,
  removeRoom,
  unCheckinBooking,
  unAssignGuestBooking,
  assignGuestBooking,
  fetchBookingSuccess,
  cancelAChildBooking,
} from '../BookingProvider/actions';
import AvailableRoomsModal from 'components/AvailableRoomsModal';
import { FormattedMessage, useIntl } from 'react-intl';
import AddPaymentModal from './components/AddPaymentModal';
import AddChargeModal from './components/AddChargeModal';
import ExtendPeriodModal from './components/ExtendPeriodModal';
import BookingChangeLogModal from './components/BookingChangeLogModal';
import TransferFolioModal from './components/TransferFolioModal';
import AddFolioModal from './components/AddFolioModal';
import SeparateFoliosModal from './components/SeparateFoliosModal';
import EditGuestModal from 'containers/SettingGuestsPage/components/EditGuestModal';

import {
  Button,
  TextBlock,
  Card,
  Table,
  PageHeader,
  Badge,
  PopupConfirm,
  NAFallback,
  DateTimeFormatter,
} from '@aha/ui';
import LockDoorAPI from '../BookingProvider/loockdoor';
import { PermissionGate } from 'components/permission';
import { BOOKING_STAUS_COLOR_MAP } from 'utils/constants';
import SkeletonTable from 'components/skeleton/TableSkeletonScreen';
import NotFoundPage from '../404Page';
import { makeSelectUser } from '../Auth/selectors';
import { formatRoute } from 'utils/routes';
import ROUTES from 'constants/routes';
import styled from 'styled-components';
import classnames from 'classnames';
import { getMessage } from 'utils/messages/bookings';
import tw from 'twin.macro';
import UpdateGeneralInfoModal from './components/UpdateGeneralInfoModal';
import LoadScreenSwitcher from 'containers/Dashboard/LoadScreenSwitcher';
import { useMedia } from '@aha/utils';
import EditRoomTypeModal from './components/EditRoomTypeModal';
import { groupBy, sortBy, isEmpty, reduce, size } from 'lodash';
import AddChildBookingModal from './components/AddChildBookingModal ';
import GroupFoliosModal from './components/GroupFoliosModal';
import BookingRoomBlock from './components/BookingRoomBlock';
import BookingNote from './components/BookingNote';

import RoomTypeGroupCollapse from 'components/RoomTypeGroupCollapse';
import CancelChildBookingModal from './components/CancelChildBookingModal';
import AddGuestModal from 'containers/SettingGuestsPage/components/AddGuestModal';
import LMembershipBadge from 'components/LMembershipBadge';
import { isGroupFolio } from 'containers/BookingProvider/helpers/folios';
import { VIP_MEMBER } from 'constants/membership';
import { coreAPI } from 'utils/request';
import { showErrorNotification } from '@aha/utils';
import { CHECKED_OUT } from 'containers/BookingProvider/helpers/useTab';
import { LOYALTY_SEGMENTS } from 'constants/loyalty';
import {
  IconCoin,
  IconEllipsisV,
  IconPrint,
  IconHistory,
  IconGroup,
  IconPlus,
} from '@aha/icons';
import HourlyBookingView from 'containers/HourlyBooking';
import { RoomStatuses } from 'containers/RoomPlanPage/components/RoomStatuses';
const { Panel } = RoomTypeGroupCollapse;

const FolioTable = styled(Table)`
  .ant-table-tbody > tr[class*='opacity']:hover > td {
    background: transparent !important;
  }

  .ant-table-thead > tr > th .ant-table-header-column {
    ${tw`block`}
  }
`;

const MenuItem = styled(Menu.Item)`
  padding: 0 !important;
`;

export const TableBadge = styled(Badge)`
  ${tw`absolute top-0 left-0 leading-none text-3xs`}
  padding: 2px 4px;
`;

const ButtonStyled = styled(ButtonAnt)`
  ${tw`w-full text-left border-0 shadow-none`}
  outline: none !important;
  border: none !important;

  &:disabled {
    pointer-events: none;
  }
`;

export function calcTotalAmount(prices = {}, currencies = {}) {
  return {
    total: (prices.amount || 0) * (currencies[prices.currency] || 1),
    currency: prices.currency,
  };
}

const mapStateToProps = createStructuredSelector({
  booking: makeSelectBooking(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  hotel: makeSelectCurrentHotel(),
  user: makeSelectUser(),
  conversionRate: makeSelectConversionRate(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchBooking: (id) => dispatch(fetchBooking(id)),
    doAllocateRoom: (
      bk,
      brid,
      rid,
      lockdoor,
      oldLockDoorRoomNo,
      newLockDoorRoomNo,
    ) => {
      return new Promise((resolve, reject) =>
        dispatch(
          allocateRoomForBooking(
            bk,
            brid,
            rid,
            resolve,
            reject,
            lockdoor,
            oldLockDoorRoomNo,
            newLockDoorRoomNo,
          ),
        ),
      );
    },
    doNoShowBooking: (bk) =>
      new Promise((resolve, reject) =>
        dispatch(noShowBooking(bk, resolve, reject)),
      ),
    doCheckinBooking: (bk, bookingRoomId, lockdoor) =>
      new Promise((resolve, reject) =>
        dispatch(checkinBooking(bk, bookingRoomId, resolve, reject, lockdoor)),
      ),
    doCheckoutBooking: (bk, bookingRoomId, lockdoor) =>
      new Promise((resolve, reject) =>
        dispatch(checkoutBooking(bk, bookingRoomId, resolve, reject, lockdoor)),
      ),
    doCancelBooking: (bk, lockdoor) =>
      new Promise((resolve, reject) =>
        dispatch(cancelBooking(bk, resolve, reject, lockdoor)),
      ),
    doUndoCheckinBooking: (bk, lockdoor) =>
      new Promise((resolve, reject) =>
        dispatch(unCheckinBooking(bk, resolve, reject, lockdoor)),
      ),
    doAddFolio: (bid) =>
      new Promise((resolve, reject) =>
        dispatch(addFolio(bid, resolve, reject)),
      ),
    doVoidFolio: (fid) =>
      new Promise((resolve, reject) =>
        dispatch(voidFolio(fid, resolve, reject)),
      ),
    doVoidBookingCharges: (bid) =>
      new Promise((resolve, reject) =>
        dispatch(voidBookingCharges(bid, resolve, reject)),
      ),
    doRemoveRoom: (bid, bookingRoomId) =>
      new Promise((resolve, reject) =>
        dispatch(removeRoom(bid, bookingRoomId, resolve, reject)),
      ),
    doAssignGuestBooking: (bid, guest) =>
      dispatch(assignGuestBooking(bid, guest)),

    doUnassignGuestBooking: (bid, guest) =>
      dispatch(unAssignGuestBooking(bid, guest)),

    doCancelAChildBooking: (bid, bkrid, body) =>
      new Promise((resolve, reject) =>
        dispatch(cancelAChildBooking(bid, bkrid, body, resolve, reject)),
      ),

    doResetBooking: () => dispatch(fetchBookingSuccess()),
  };
};

export function BookingDetail({ bid, isDrawer = false, room: roomStatusComp }) {
  useInjectReducer({ key: BOOKING_KEY, reducer });
  useInjectSaga({ key: BOOKING_KEY, saga });

  useInjectReducer({ key: 'ratePlans', reducer: rateReducer });
  useInjectSaga({ key: 'ratePlans', saga: rateSaga });

  const dispatch = useDispatch();
  const { booking, hotel, user, loading, error, conversionRate } = useSelector(
    mapStateToProps,
  );

  const intl = useIntl();
  const {
    doFetchBooking,
    doAllocateRoom,
    doNoShowBooking,
    doCheckinBooking,
    doCancelBooking,
    doCheckoutBooking,
    doVoidFolio,
    doVoidBookingCharges,
    doRemoveRoom,
    doUndoCheckinBooking,
    doUnassignGuestBooking,
    doAssignGuestBooking,
    doCancelAChildBooking,
    doResetBooking,
  } = mapDispatchToProps(dispatch);

  const [fnDropdowVis, setFnDropdowVis] = useState(false);
  const [fnDropdownFolio, setFnDropdownFolio] = useState(null);

  const [allocateRoom, setAllocateRoom] = useState(null);
  const [isModifying, setIsModifying] = useState(false);
  const [showExtendPeriod, setShowExtendPeriod] = useState(false);
  const [showChangeLogs, setShowChangeLogs] = useState(false);
  const [showAddChildBooking, setShowAddChildBooking] = useState(false);
  const [selectedFolio, setSelectedFolio] = useState({});
  const [modalType, setModalType] = useState(null);
  const [editGuest, setEditGuest] = useState(null);
  const [showEditInfo, setShowEditInfo] = useState(false);
  const [showAddGuest, setShowAddGuest] = useState(null);
  const [showEditRoomType, setShowEditRoomType] = useState(null);
  const [showGroupFolios, setShowGroupFolios] = useState(false);
  const [showSeparateFolios, setShowSeparateFolios] = useState(null);
  const [showAddFolio, setShowAddFolio] = useState(false);
  const [roomToEdit, setRoomToEdit] = useState(null);
  const [collapseKeys, setCollapseKeys] = useState([]);
  const firstLoading = useRef(false);
  const isOnMobile = useMedia('md');
  const collapseRef = useRef(false);
  const [earnedCoins, setEarnedCoins] = useState(0);

  const { coinValuation = 0 } = booking?.coinUsage || {};
  let {
    totalCharge,
    totalPaid,
    balance,
    currency: currencyCode,
    roomsFolioBalance,
  } = useMemo(
    () =>
      reduce(
        booking.folios,
        (fin, f) => {
          const totlaBalance = calcTotalAmount(f.balance, conversionRate).total;
          fin.roomsFolioBalance[f.bookingRoomId] =
            (fin.roomsFolioBalance[f.bookingRoomId] || 0) + totlaBalance;

          return {
            totalCharge:
              fin.totalCharge +
              calcTotalAmount(f.totalCharge, conversionRate).total,
            totalPaid:
              fin.totalPaid +
              calcTotalAmount(f.totalCharge, conversionRate).total,
            balance: fin.balance + totlaBalance,
            roomsFolioBalance: fin.roomsFolioBalance,
          };
        },
        {
          totalCharge: 0,
          totalPaid: 0,
          balance: 0,
          currency: 'VND',
          roomsFolioBalance: {},
        },
      ),
    [booking.folios, conversionRate],
  );
  totalPaid = totalPaid - coinValuation - balance;

  const showLoyalty =
    hotel?.isEnableLoyalty &&
    LOYALTY_SEGMENTS.includes(booking?.marketingSource?.name?.toLowerCase());

  const { rank, bookingCount } = booking?.membership || {};
  const loyaltyBk =
    (bookingCount > 2 ||
      (bookingCount === 2 && booking?.status !== CHECKED_OUT)) &&
    showLoyalty;

  const isVIP = rank === VIP_MEMBER;

  useEffect(() => {
    const folioVis = booking?.folios?.reduce(
      (res, f) => ({ ...res, [f.refCode]: false }),
      {},
    );

    setFnDropdownFolio(folioVis);
  }, [booking.folios]); // eslint-disable-line

  // API requests
  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test' || !bid) {
      return;
    }

    let waiting = 0;
    if (isDrawer) {
      waiting = 400;
    }

    const timer = setTimeout(() => {
      if (firstLoading.current) {
        navigate('/bookings/arrival');
        return;
      }

      doFetchBooking(bid);
      firstLoading.current = true;
    }, waiting);

    return () => {
      doResetBooking();
      clearTimeout(timer);
    };
  }, [bid, hotel.id]); // eslint-disable-line

  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    async function fetchCoinWillBeEarned(priceRaw) {
      try {
        const { totalCoin } = await coreAPI.post(
          `v1/pms/memberships/earning-preview`,
          {
            isHourlyBooking: false,
            roomCharge: priceRaw,
            serviceCharge: 0,
          },
        );
        setEarnedCoins(totalCoin);
      } catch (e) {
        showErrorNotification(e);
      }
    }

    if (loyaltyBk) {
      fetchCoinWillBeEarned(totalPaid);
    }
  }, [totalPaid, booking.id, loyaltyBk]);

  const validRooms = booking?.rooms?.filter((r) => r.status !== 'cancelled');

  const { roomTypeKeys, roomTypeGroups } = useMemo(() => {
    const roomTypeGroups = groupBy(validRooms, 'roomTypeId');

    return {
      roomTypeKeys: Object.keys(roomTypeGroups),
      roomTypeGroups: sortBy(Object.entries(roomTypeGroups), [
        ([, rooms]) => rooms?.[0]?.roomTypeName,
      ]),
    };
  }, [validRooms]);

  useMemo(() => {
    if (!collapseRef.current && roomTypeKeys.length !== collapseKeys.length) {
      setCollapseKeys(roomTypeKeys);
      collapseRef.current = true;
    }
  }, [roomTypeKeys]); // eslint-disable-line

  async function onCloseModals(f5) {
    if (f5 === true) {
      await doFetchBooking(bid);
    }
    if (selectedFolio?.id) {
      setSelectedFolio({});
    } else if (showExtendPeriod) {
      setShowExtendPeriod(false);
    } else if (editGuest) {
      setEditGuest(null);
    } else if (showAddGuest) {
      setShowAddGuest(null);
    }

    if (showAddChildBooking) {
      setShowAddChildBooking(false);
    }

    if (showGroupFolios) {
      setShowGroupFolios(false);
    }

    if (showSeparateFolios) {
      setShowSeparateFolios(null);
    }

    if (showEditRoomType) {
      setShowEditRoomType(null);
    }

    if (showAddFolio) {
      setShowAddFolio(false);
    }

    if (!!roomToEdit) {
      setRoomToEdit(null);
    }
  }

  function onToggleModal(f, type) {
    setSelectedFolio(f);
    setModalType(type);
  }

  async function onRequestAllocateRoom(roomID, rtID, room) {
    try {
      setIsModifying(true);
      // return;
      await doAllocateRoom(
        { ...booking, status: allocateRoom.status },
        allocateRoom.bookingId,
        roomID,
        hotel.isEnabledLockdoor,
        allocateRoom.lockDoorRoomNo,
        room.lockDoorRoomNo || room.lockDoorRoomNo,
      );
      await doFetchBooking(bid);
      setAllocateRoom(null);
    } catch (err) {
      console.error(err);
    } finally {
      setIsModifying(false);
    }
  }

  const onRequestRemoveRoom = useCallback(
    async (roomID) => {
      try {
        setIsModifying(true);
        await doRemoveRoom(booking.id, +roomID);
        doFetchBooking(bid);
      } catch (err) {
        console.error(err);
      } finally {
        setIsModifying(false);
      }
    },
    [booking, bid], // eslint-disable-line
  );

  const onRequestCheckin = useCallback(
    async (bookingRoomId) => {
      try {
        setIsModifying(true);
        let brID = null;
        if (typeof bookingRoomId === 'string') {
          brID = bookingRoomId;
        }
        await doCheckinBooking(booking, brID, hotel.isEnabledLockdoor);
        await doFetchBooking(bid);
      } catch (err) {
        console.error(err);
      } finally {
        setIsModifying(false);
      }
    },
    [bid, booking, hotel.isEnabledLockdoor], // eslint-disable-line
  );

  const onRequestCheckout = useCallback(
    async (bookingRoomId) => {
      try {
        setIsModifying(true);
        let brID = null;
        if (typeof bookingRoomId === 'string') {
          brID = bookingRoomId;
        }
        await doCheckoutBooking(booking, brID, hotel.isEnabledLockdoor);
        await doFetchBooking(bid);
      } catch (err) {
        console.error(err);
      } finally {
        setIsModifying(false);
      }
    },
    [bid, booking, hotel.isEnabledLockdoor], // eslint-disable-line
  );

  async function onRequestCancel() {
    try {
      setIsModifying(true);
      await doCancelBooking(booking, hotel.isEnabledLockdoor);
      await doFetchBooking(bid);
    } catch (err) {
      console.error(err);
    } finally {
      setIsModifying(false);
    }
  }
  async function onRequestUndoCheckin() {
    try {
      setIsModifying(true);
      await doUndoCheckinBooking(booking, hotel.isEnabledLockdoor);
      await doFetchBooking(bid);
    } catch (err) {
      console.error(err);
    } finally {
      setIsModifying(false);
    }
  }
  async function onRequestNoShow() {
    try {
      setIsModifying(true);
      await doNoShowBooking(booking);
      await doFetchBooking(bid);
    } catch (err) {
      console.error(err);
    } finally {
      setIsModifying(false);
    }
  }

  async function onRequestVoidFolio(fid) {
    try {
      setIsModifying(true);
      await doVoidFolio(fid);
      doFetchBooking(booking.refCode);
    } catch (err) {
      console.error(err);
    } finally {
      setIsModifying(false);
    }
  }
  async function onRequestVoidCharges() {
    try {
      setIsModifying(true);
      await doVoidBookingCharges(booking.id);
      doFetchBooking(booking.refCode);
    } catch (err) {
      console.error(err);
    } finally {
      setIsModifying(false);
    }
  }

  const onCancelChildBooking = useCallback(
    async (roomID) => {
      try {
        setIsModifying(true);
        await doCancelAChildBooking(booking.refCode, roomID, {});
        await doFetchBooking(booking.refCode);
      } catch (err) {
        console.error(err);
      } finally {
        setIsModifying(false);
      }
    },
    [booking], // eslint-disable-line
  );

  const onAssignGuestBooking = useCallback(
    (body) => {
      try {
        setIsModifying(true);
        doAssignGuestBooking(booking.id, body);
      } catch (err) {
        console.error(err);
      } finally {
        setIsModifying(false);
      }
    },
    [booking], // eslint-disable-line
  );

  const onUnassignGuestBooking = useCallback(
    async (body) => {
      try {
        setIsModifying(true);
        await doUnassignGuestBooking(booking.id, body);
      } catch (err) {
        console.error(err);
      } finally {
        setIsModifying(false);
      }
    },
    [booking], // eslint-disable-line
  );

  async function onRequestDupKey() {
    try {
      setIsModifying(true);
      await LockDoorAPI.dupKey(booking, booking.rooms[0].lockDoorRoomNo);
    } catch (err) {
      console.error(err);
    } finally {
      setIsModifying(false);
    }
  }

  async function onReportLostCard() {
    try {
      // skip group booking
      if (booking.rooms.length > 1) {
        return;
      }
      setIsModifying(true);
      await LockDoorAPI.lostCard(booking.rooms[0].lockDoorRoomNo);
    } catch (err) {
      console.error(err);
    } finally {
      setIsModifying(false);
    }
  }

  const activeFolios = booking?.folios?.filter((f) => f.status === 'active');
  const roomIDs = useMemo(
    () =>
      (activeFolios || []).reduce((res, f) => {
        const room = booking?.rooms?.find((r) => r.id === f.bookingRoomId);

        if (f.refCode) {
          return { ...res, [f.refCode]: room };
        }

        return res;
      }, {}),
    [booking], // eslint-disable-line
  );

  const folioCols = [
    {
      title: (
        <FormattedMessage defaultMessage="Folio ID" id="common.label.folioId" />
      ),
      key: 'refCode',
      dataIndex: 'refCode',
      className: 'relative',
      width: '12%',
      align: 'center',
      render: (v, record) => {
        const isGroupFolio = record?.charges?.some(
          (c) =>
            c.bookingRoomId &&
            c.bookingRoomId !== record.bookingRoomId &&
            c.status !== 'voided',
        );

        const showBadge =
          (!!isGroupFolio || record.isMaster) && record.status === 'active';

        return (
          <>
            {showBadge && (
              <TableBadge color={record.isMaster ? 'orange' : 'green'}>
                {record.isMaster ? (
                  <FormattedMessage
                    defaultMessage="Master"
                    id="common.label.master"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Group"
                    id="bookings.label.group"
                  />
                )}
              </TableBadge>
            )}

            {v}
          </>
        );
      },
    },
    {
      title: (
        <FormattedMessage defaultMessage="Room ID " id="common.label.roomId" />
      ),
      dataIndex: 'refCode',
      align: 'center',
      key: 'roomID',
      width: '12%',
      render: (v) => <NAFallback value={roomIDs[v]?.refCode} />,
    },
    {
      title: (
        <FormattedMessage
          defaultMessage="Total Rooms"
          id="common.label.totalRooms"
        />
      ),
      key: 'No',
      align: 'center',
      render: (_, f) => {
        const totalRooms = (f?.charges || []).reduce((res, c) => {
          const room = booking.rooms?.find((r) => r.id === c.bookingRoomId);
          const key = room?.refCode || 'unknown';

          return { ...res, [key]: room?.roomTitle || NOT_AVAILABLE };
        }, {});

        const totalRoomLen = Object.keys(totalRooms).length;

        return (
          <Tooltip
            title={
              totalRoomLen > 0 && (
                <div className="w-32">
                  <div className="flex justify-between border-b border-grey-darkest">
                    <span>
                      <FormattedMessage
                        id="common.label.roomID"
                        defaultMessage="Room ID"
                      />
                    </span>
                    <FormattedMessage
                      id="common.label.room"
                      defaultMessage="Room"
                    />
                  </div>
                  {Object.entries(totalRooms).map(([key, roomNumber], idx) => (
                    <div key={key} className="flex justify-between mt-2">
                      <span className="capitalize">{key}</span>
                      {roomNumber}
                    </div>
                  ))}
                </div>
              )
            }
          >
            <div className="text-2xs text-grey-darker w-full">
              {totalRoomLen}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: <FormattedMessage defaultMessage="Room" id="common.label.room" />,
      key: 'roomTitle',
      dataIndex: 'refCode',
      align: 'center',
      width: '10%',
      render: (v) => <NAFallback value={roomIDs[v]?.roomName} />,
    },
    {
      title: (
        <FormattedMessage defaultMessage="Charge" id="bookings.label.Charge" />
      ),
      key: 'totalCharge',
      width: '15%',
      dataIndex: 'totalCharge',
      align: 'center',
      render: (v, r) => {
        return (
          <div className="truncate">
            {currencyFormater(calcTotalAmount(v, conversionRate).total)}
          </div>
        );
      },
    },
    {
      title: (
        <FormattedMessage defaultMessage="Paid" id="bookings.label.paid" />
      ),
      key: 'totalPaid',
      width: '15%',
      dataIndex: 'totalPayment',
      align: 'center',
      render: (v) => (
        <div className="truncate">
          {currencyFormater(calcTotalAmount(v, conversionRate).total)}
        </div>
      ),
    },
    {
      title: (
        <FormattedMessage
          defaultMessage="Balance"
          id="bookings.label.balance"
        />
      ),
      key: 'folioBalance',
      dataIndex: 'balance',
      align: 'center',
      render: (v) => (
        <div className="truncate">
          {currencyFormater(calcTotalAmount(v, conversionRate).total)}
        </div>
      ),
    },
    {
      title: (
        <div className="text-center w-full">
          <FormattedMessage defaultMessage="Action" id="common.label.action" />
        </div>
      ),
      key: 'folioActions',
      width: 200,
      className: 'p-0',
      render: (text, f) => {
        const disabledVoid =
          f.isMaster ||
          isModifying ||
          isFuncBtnDisabled ||
          f.status !== 'active' ||
          size(booking.folios) <= 1;

        return (
          <div
            className="w-full h-full py-2 px-6 flex items-center"
            onClick={(e) => e.stopPropagation()}
          >
            <Dropdown
              disabled={f.status !== 'active'}
              key={f.refCode}
              visible={fnDropdownFolio ? fnDropdownFolio[f.refCode] : false}
              onVisibleChange={(visible) =>
                fnDropdownFolio &&
                setFnDropdownFolio((prev) => ({
                  ...prev,
                  [f.refCode]: visible,
                }))
              }
              overlay={
                <Menu className="p-0">
                  <MenuItem key={`addCharge-${f.refCode}`}>
                    <PermissionGate
                      allow="pms.bookings.folios.charges.create"
                      fallback={
                        <ButtonStyled disabled block>
                          <FormattedMessage
                            defaultMessage="Add charge"
                            id="bookings.label.addCharge"
                          />
                        </ButtonStyled>
                      }
                    >
                      <ButtonStyled
                        block
                        disabled={
                          isFuncBtnDisabled ||
                          f.status !== 'active' ||
                          isModifying
                        }
                        onClick={() => onToggleModal(f, 'charge')}
                      >
                        <FormattedMessage
                          defaultMessage="Add charge"
                          id="bookings.label.addCharge"
                        />
                      </ButtonStyled>
                    </PermissionGate>
                  </MenuItem>
                  <MenuItem key={`payment-${f.refCode}`}>
                    <PermissionGate
                      allow="pms.bookings.folios.payments.create"
                      fallback={
                        <ButtonStyled block disabled>
                          <FormattedMessage
                            defaultMessage="Make payment"
                            id="bookings.actions.makePayment"
                          />
                        </ButtonStyled>
                      }
                    >
                      <ButtonStyled
                        block
                        disabled={
                          isFuncBtnDisabled ||
                          f.status !== 'active' ||
                          isModifying
                        }
                        onClick={() => onToggleModal(f, 'payment')}
                      >
                        <FormattedMessage
                          defaultMessage="Make payment"
                          id="bookings.actions.makePayment"
                        />
                      </ButtonStyled>
                    </PermissionGate>
                  </MenuItem>
                  <MenuItem key={`transfer-${f.refCode}`}>
                    <PermissionGate
                      allow="pms.bookings.folios.transfer"
                      fallback={
                        <ButtonStyled block disabled>
                          <FormattedMessage
                            defaultMessage="Transfer"
                            id="bookings.actions.transfer"
                          />
                        </ButtonStyled>
                      }
                    >
                      <ButtonStyled
                        block
                        disabled={
                          isFuncBtnDisabled ||
                          f.status !== 'active' ||
                          isModifying
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          onToggleModal(f, 'transfer');
                        }}
                      >
                        <FormattedMessage
                          defaultMessage="Transfer"
                          id="bookings.actions.transfer"
                        />
                      </ButtonStyled>
                    </PermissionGate>
                  </MenuItem>
                  <MenuItem key={`void-${f.refCode}`}>
                    <PermissionGate
                      allow="pms.bookings.folios.void"
                      fallback={
                        <ButtonStyled disabled block>
                          <FormattedMessage
                            defaultMessage="Delete Folio"
                            id="bookings.actions.deleteFolio"
                          />
                        </ButtonStyled>
                      }
                    >
                      {disabledVoid ? (
                        <ButtonStyled block disabled={disabledVoid}>
                          <FormattedMessage
                            defaultMessage="Delete Folio"
                            id="bookings.actions.deleteFolio"
                          />
                        </ButtonStyled>
                      ) : (
                        <PopupConfirm
                          title={
                            <FormattedMessage
                              defaultMessage="Delete this folio?"
                              id="bookings.actions.voidFolioConfirm"
                            />
                          }
                          placement="left"
                          onConfirm={() => onRequestVoidFolio(f.id)}
                          okButtonProps={{
                            disabled:
                              f.status !== 'active' ||
                              size(booking.folios) <= 1,
                          }}
                          arrowPointAtCenter
                          okText={
                            <FormattedMessage
                              defaultMessage="Remove"
                              id="common.action.remove"
                            />
                          }
                          cancelText={
                            <FormattedMessage
                              defaultMessage="Keep"
                              id="common.action.keep"
                            />
                          }
                        >
                          <ButtonStyled block disabled={disabledVoid}>
                            <FormattedMessage
                              defaultMessage="Delete Folio"
                              id="bookings.actions.deleteFolio"
                            />
                          </ButtonStyled>
                        </PopupConfirm>
                      )}
                    </PermissionGate>
                  </MenuItem>
                </Menu>
              }
            >
              <Button icon={IconEllipsisV} type="icon" size="small" />
            </Dropdown>
            {isGroupFolio(f) && (
              <Button
                color="secondary"
                disabled={isFuncBtnDisabled || isModifying}
                className="ml-3"
                onClick={() => setShowSeparateFolios(f)}
              >
                <FormattedMessage
                  defaultMessage="Separate Folio"
                  id="bookings.actions.separateFolio"
                />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  //  ====== Pre process data =========
  const hidPopcorn = fnDropdowVis ? {} : { visible: false };

  const isCheckinAble =
    (booking.status === 'awaiting' ||
      ('checked_in' === booking.status &&
        booking.rooms?.some((r) => r.status === 'awaiting'))) &&
    booking.rooms.every(
      (r) => r.roomId || (!r.roomId && r.status === 'cancelled'),
    );

  const isCheckoutAble =
    ['checked_in', 'pending_checkout'].includes(booking.status) &&
    booking.rooms?.every((r) =>
      ['checked_in', 'checked_out', 'cancelled'].includes(r.status),
    ) &&
    balance === 0;

  const isFuncBtnDisabled = ![
    'checked_in',
    'awaiting',
    'checking_in',
    'pending_checkout',
  ].includes(booking.status);
  const isNoShowDisabled = !['awaiting', 'voided'].includes(booking.status);
  const isCancelDisabled = ![
    'awaiting',
    'checked_in',
    'checking_in',
    'voided',
  ].includes(booking.status);
  const isLateCheckout =
    moment()
      .startOf('day')
      .diff(moment(booking.departure).startOf('day'), 'day') > 0;

  const validFolios = (booking.folios || []).filter(
    (f) =>
      f.status === 'active' &&
      !booking?.rooms?.some(
        (r) =>
          f.bookingRoomId &&
          r.id === f.bookingRoomId &&
          r.status === 'checked_out',
      ),
  );

  const folioSorter = useMemo(() => {
    let folios = sortBy(booking.folios, ['status', 'bookingRoomId']);
    const masterFolio = folios.find((f) => f.isMaster);

    return [masterFolio, ...folios.filter((f) => !f.isMaster)].filter(Boolean);
  }, [booking]);

  const moreActions = (
    <div
      className={classnames('flex items-center mb-2', {
        'justify-end w-full': isOnMobile,
      })}
    >
      {isCheckinAble && (
        <PermissionGate allow="pms.bookings.checkin">
          <Button
            disabled={isFuncBtnDisabled || isModifying}
            onClick={onRequestCheckin}
          >
            <FormattedMessage
              defaultMessage="Check-in"
              id="bookings.actions.checkin"
            />
          </Button>
        </PermissionGate>
      )}
      {isCheckoutAble &&
        (isLateCheckout ? (
          <PermissionGate allow="pms.bookings.checkout">
            <PopupConfirm
              title={
                <FormattedMessage
                  defaultMessage="This booking is late for checkout, please update booking period or continue to checkout!!!"
                  id="bookings.label.lateCheckout"
                />
              }
              okText={
                <FormattedMessage
                  defaultMessage="Check-out"
                  id="bookings.actions.checkout"
                />
              }
              cancelText={
                <FormattedMessage
                  defaultMessage="Extend"
                  id="bookings.actions.extendDate"
                />
              }
              placement="topRight"
              arrowPointAtCenter
              onConfirm={onRequestCheckout}
              onCancel={() => setShowExtendPeriod(true)}
            >
              <Button disabled={isFuncBtnDisabled || isModifying}>
                <FormattedMessage
                  defaultMessage="Check-out"
                  id="bookings.actions.checkout"
                />
              </Button>
            </PopupConfirm>
          </PermissionGate>
        ) : (
          <PermissionGate allow="pms.bookings.checkout">
            <PopupConfirm
              title={
                <FormattedMessage
                  defaultMessage="Are you sure to checkout?"
                  id="bookings.label.checkout"
                />
              }
              okText={
                <FormattedMessage
                  defaultMessage="Check-out"
                  id="bookings.actions.checkout"
                />
              }
              cancelText={
                <FormattedMessage
                  defaultMessage="Cancel"
                  id="bookings.label.cancelBookingsDetail"
                />
              }
              placement="topRight"
              arrowPointAtCenter
              onConfirm={onRequestCheckout}
            >
              <Button disabled={isFuncBtnDisabled || isModifying}>
                <FormattedMessage
                  defaultMessage="Check-out"
                  id="bookings.actions.checkout"
                />
              </Button>
            </PopupConfirm>
          </PermissionGate>
        ))}
      <Dropdown
        className="ml-2"
        visible={fnDropdowVis}
        onVisibleChange={setFnDropdowVis}
        overlay={
          <Menu className="p-0">
            <MenuItem>
              <PermissionGate
                allow="pms.bookings.updateInfo"
                fallback={
                  <ButtonStyled block disabled>
                    <FormattedMessage
                      defaultMessage="Update info"
                      id="bookings.actions.updateInfo"
                    />
                  </ButtonStyled>
                }
              >
                <ButtonStyled
                  block
                  onClick={() => setShowEditInfo(true)}
                  disabled={isFuncBtnDisabled}
                >
                  <FormattedMessage
                    defaultMessage="Update info"
                    id="bookings.actions.updateInfo"
                  />
                </ButtonStyled>
              </PermissionGate>
            </MenuItem>
            <MenuItem>
              <PermissionGate
                allow="pms.bookings.uncheckin"
                fallback={
                  <ButtonStyled block disabled>
                    <FormattedMessage
                      defaultMessage="Undo checkin"
                      id="bookings.actions.undoAction"
                    />
                  </ButtonStyled>
                }
              >
                <PopupConfirm
                  {...hidPopcorn}
                  placement="left"
                  title={intl.formatMessage({
                    defaultMessage: 'Undo checkin booking status this booking?',
                    id: 'booking.message.undoCheckinStatus',
                  })}
                  okButtonProps={{ disabled: booking.status !== 'checked_in' }}
                  onConfirm={onRequestUndoCheckin}
                >
                  <ButtonStyled
                    block
                    disabled={booking.status !== 'checked_in'}
                  >
                    <FormattedMessage
                      defaultMessage="Undo checkin"
                      id="bookings.actions.undoAction"
                    />
                  </ButtonStyled>
                </PopupConfirm>
              </PermissionGate>
            </MenuItem>
            <MenuItem disabled={isNoShowDisabled || isModifying}>
              <PermissionGate
                allow="pms.bookings.noShow"
                fallback={
                  <ButtonStyled block disabled>
                    <FormattedMessage
                      defaultMessage="No show"
                      id="bookings.actions.noShowBooking"
                    />
                  </ButtonStyled>
                }
              >
                <PopupConfirm
                  {...hidPopcorn}
                  placement="left"
                  title="Mark no show this booking?"
                  onConfirm={onRequestNoShow}
                  okButtonProps={{
                    disabled: isNoShowDisabled || isModifying,
                  }}
                >
                  <ButtonStyled
                    block
                    disabled={isNoShowDisabled || isModifying}
                  >
                    <FormattedMessage
                      defaultMessage="No show"
                      id="bookings.actions.noShowBooking"
                    />
                  </ButtonStyled>
                </PopupConfirm>
              </PermissionGate>
            </MenuItem>
            <MenuItem>
              <PermissionGate
                allow="pms.bookings.voidCharges"
                fallback={
                  <ButtonStyled block disabled>
                    <FormattedMessage
                      defaultMessage="Void charges"
                      id="bookings.actions.voidCharges"
                    />
                  </ButtonStyled>
                }
              >
                <PopupConfirm
                  {...hidPopcorn}
                  placement="left"
                  title="Void all charges of this booking?"
                  okButtonProps={{
                    disabled:
                      isFuncBtnDisabled ||
                      'pending_checkout' === booking.status,
                  }}
                  onConfirm={onRequestVoidCharges}
                >
                  <ButtonStyled
                    block
                    disabled={
                      isFuncBtnDisabled ||
                      isModifying ||
                      'pending_checkout' === booking.status
                    }
                  >
                    <FormattedMessage
                      defaultMessage="Void charges"
                      id="bookings.actions.voidCharges"
                    />
                  </ButtonStyled>
                </PopupConfirm>
              </PermissionGate>
            </MenuItem>
            <MenuItem>
              <PermissionGate
                allow="pms.bookings.cancel"
                fallback={
                  <ButtonStyled block disabled>
                    <FormattedMessage
                      defaultMessage="Cancel"
                      id="bookings.actions.cancel"
                    />
                  </ButtonStyled>
                }
              >
                <PopupConfirm
                  {...hidPopcorn}
                  placement="left"
                  title="Cancel this booking?"
                  onConfirm={onRequestCancel}
                  okButtonProps={{
                    disabled: isCancelDisabled || isModifying,
                  }}
                >
                  <ButtonStyled
                    block
                    disabled={isCancelDisabled || isModifying}
                  >
                    <FormattedMessage
                      defaultMessage="Cancel"
                      id="bookings.actions.cancel"
                    />
                  </ButtonStyled>
                </PopupConfirm>
              </PermissionGate>
            </MenuItem>
            <MenuItem disabled={isFuncBtnDisabled || !hotel.isEnabledLockdoor}>
              <PermissionGate
                allow="pms.bookings.checkin"
                fallback={
                  <ButtonStyled block disabled>
                    <FormattedMessage
                      defaultMessage="Duplicate key"
                      id="lockdoor.actions.dupkey"
                    />
                  </ButtonStyled>
                }
              >
                <ButtonStyled
                  block
                  disabled={isFuncBtnDisabled || !hotel.isEnabledLockdoor}
                  onClick={onRequestDupKey}
                >
                  <FormattedMessage
                    defaultMessage="Duplicate key"
                    id="lockdoor.actions.dupkey"
                  />
                </ButtonStyled>
              </PermissionGate>
            </MenuItem>
            <MenuItem disabled={isFuncBtnDisabled || !hotel.isEnabledLockdoor}>
              <PermissionGate
                allow="pms.bookings.checkin"
                fallback={
                  <ButtonStyled block disabled>
                    <FormattedMessage
                      defaultMessage="Report lost card"
                      id="lockdoor.actions.lostCard"
                    />
                  </ButtonStyled>
                }
              >
                <ButtonStyled
                  block
                  disabled={isFuncBtnDisabled || !hotel.isEnabledLockdoor}
                  onClick={onReportLostCard}
                >
                  <FormattedMessage
                    defaultMessage="Report lost card"
                    id="lockdoor.actions.lostCard"
                  />
                </ButtonStyled>
              </PermissionGate>
            </MenuItem>
          </Menu>
        }
      >
        <Button icon={IconEllipsisV} type="icon" />
      </Dropdown>
    </div>
  );

  if ((loading || !booking.id) && !firstLoading.current) {
    return (
      <div className={classnames({ '-mt-4': isDrawer })}>
        <LoadScreenSwitcher pageRoute={ROUTES.DETAIL_BOOKING} />
      </div>
    );
  }

  if (error || (!loading && !booking?.id)) {
    return <NotFoundPage />;
  }

  if (isEmpty(booking)) {
    return <Redirect to={ROUTES.ARRIVAL_BOOKING} noThrow />;
  }

  if (booking.type === 'hourly') {
    return (
      <HourlyBookingView
        bid={booking?.refCode}
        room={<RoomStatuses room={booking.rooms?.[0]} />}
      />
    );
  }

  return (
    <>
      <PageHeader
        className={classnames('mb-2 pt-0', {
          'mt-8': !isDrawer,
          'mt-3': isDrawer,
        })}
        title={
          <div>
            <div className="font-semibold uppercase">
              <FormattedMessage
                defaultMessage="Booking Detail"
                id="bookings.label.bookingDetail"
              />
            </div>
            <div className="flex flex-col md:flex-row text-sm font-normal capitalize -mx-1 mt-2 mb-3 text-grey-darker">
              <div className="px-1">
                <FormattedMessage
                  defaultMessage="Booking ID"
                  id="bookings.label.booking"
                />
                :{' '}
                <CopyToClipboard content={booking?.refCode}>
                  {booking?.refCode}
                </CopyToClipboard>
              </div>
              {booking?.reference && (
                <>
                  <div className="px-1 hidden md:block"> | </div>
                  <div className="px-1">
                    <FormattedMessage
                      defaultMessage="Reference"
                      id="common.label.reference"
                    />
                    :{' '}
                    <CopyToClipboard content={booking?.reference}>
                      {booking?.reference}
                    </CopyToClipboard>
                  </div>
                </>
              )}
              {booking?.type && (
                <>
                  <div className="px-1 hidden md:block"> | </div>
                  <div className="px-1">
                    <FormattedMessage
                      defaultMessage="Type"
                      id="common.label.type"
                    />
                    :
                    <span className="ml-2">
                      <FormattedMessage
                        defaultMessage="Type"
                        id={`bookings.type.${booking.type.toLowerCase()}`}
                      />
                    </span>
                  </div>
                </>
              )}
              {booking?.company && (
                <>
                  <div className="px-1 hidden md:block"> | </div>
                  <div className="px-1">
                    <FormattedMessage
                      id="common.label.company"
                      defaultMessage="Company"
                    />
                    :<span className="ml-2">{booking?.company}</span>
                  </div>
                </>
              )}
            </div>

            <div className="mt-1 flex items-center w-full">
              {booking?.status && (
                <Badge
                  color={BOOKING_STAUS_COLOR_MAP[booking?.status]}
                  className="mr-2 mb-0 h-6"
                >
                  <FormattedMessage
                    {...getMessage(`bookings.status.${booking?.status}`)}
                  />
                </Badge>
              )}
              <Link
                to={formatRoute(ROUTES.REG_CARD_PRINTING, bid)}
                className="leading-none"
              >
                <Button
                  icon={IconPrint}
                  type="line"
                  size="small"
                  className="bg-white hover:bg-primary mr-2 mb-0 h-6"
                >
                  <FormattedMessage
                    defaultMessage="Print"
                    id="bookings.label.print"
                  />
                </Button>
              </Link>
              <Button
                icon={IconHistory}
                type="line"
                size="small"
                onClick={() => setShowChangeLogs(true)}
                className="bg-white hover:bg-primary"
              >
                <FormattedMessage
                  defaultMessage="View history"
                  id="bookings.actions.viewHistory"
                />
              </Button>
            </div>
          </div>
        }
        extra={!roomStatusComp ? moreActions : null}
        flexExtra={isOnMobile}
      />

      {!booking.id && !firstLoading.current ? (
        <div className="mt-6">
          <SkeletonTable />
        </div>
      ) : (
        <div>
          {/* Room status component */}
          {roomStatusComp ? (
            <div className="mb-2 mt-6 flex flex-wrap md:flex-no-wrap justify-between">
              <div className="mb-2 w-full">{roomStatusComp}</div>
              {moreActions}
            </div>
          ) : null}

          <div
            className={classnames('flex flex-wrap boxed bg-transparent -mx-2', {
              'mt-7': !roomStatusComp,
            })}
          >
            <div className="w-full lg:flex-1 bg-white mx-2">
              <div className="w-full h-full bg-white px-4 pt-3 pb-2">
                <div className="px-2">
                  <TextBlock
                    title={
                      <>
                        <span className="font-semibold mr-1">
                          <FormattedMessage
                            defaultMessage="Delegate"
                            id="bookings.label.delegate"
                          />
                          :
                        </span>
                        <span className="capitalize text-secondary ml-1 font-medium">
                          {booking.guest.fullName ||
                            [booking.guest?.firstName, booking.guest?.lastname]
                              .filter(Boolean)
                              .join(' ')}
                        </span>
                      </>
                    }
                    titleClassName="text-base"
                    subTitle={
                      <div className="text-grey-darker capitalize text-xs">
                        {booking.guest.passport && (
                          <>
                            <span>
                              {`${intl.formatMessage({
                                defaultMessage: 'Passport',
                                id: 'bookings.label.passport',
                              })}: ${booking.guest.passport}`}
                            </span>
                            <Divider className="m-0 mx-1" type="vertical" />
                          </>
                        )}
                        {booking.guest?.phone && (
                          <span>
                            {`${intl.formatMessage({
                              defaultMessage: 'Phone number',
                              id: 'newBooking.phoneNumber',
                            })}: ${booking.guest.phone}`}
                          </span>
                        )}
                      </div>
                    }
                    untruncated={isOnMobile}
                  />
                </div>
                {showLoyalty && rank && (
                  <div className="px-2 mb-1">
                    <LMembershipBadge type={rank} />
                    {isVIP && (
                      <>
                        <span className="font-medium ml-2 mr-1">
                          <FormattedMessage
                            defaultMessage="Available xu:"
                            id="bookings.label.availableCoins"
                          />
                        </span>
                        <span className="mr-1">
                          {booking?.membership?.balance || 0}
                        </span>
                        <FormattedMessage
                          defaultMessage="xu"
                          id="common.label.coins"
                        />
                      </>
                    )}
                  </div>
                )}

                <div className="px-2">
                  <TextBlock
                    title={
                      <>
                        <span className="font-semibold mr-1">
                          <FormattedMessage
                            defaultMessage="Guests"
                            id="common.label.guests"
                          />
                          :
                        </span>
                        <span className="capitalize text-secondary font-medium inline-block md:inline">
                          {`${booking.adults || 0} ${intl.formatMessage({
                            defaultMessage: 'Adults',
                            id: 'common.label.adults',
                          })} - ${booking.children || 0} ${intl.formatMessage({
                            defaultMessage: 'Children',
                            id: 'common.label.children',
                          })}`}
                        </span>
                      </>
                    }
                    titleClassName="text-base"
                    subTitle={
                      <>
                        <span className="mr-1">
                          {booking.adults + booking.children}
                        </span>
                        <FormattedMessage
                          defaultMessage="Guests"
                          id="common.label.guests"
                        />
                      </>
                    }
                    untruncated={isOnMobile}
                  />
                </div>
                <div className="flex justify-between items-center px-2 flex-wrap md:flex-no-wrap">
                  <TextBlock
                    title={
                      <>
                        <span className="font-semibold mr-1">
                          <FormattedMessage
                            defaultMessage="Stay Time"
                            id="bookings.label.stayTime"
                          />
                          :
                        </span>
                        <span className="capitalize text-secondary font-medium inline-block md:inline">
                          <>
                            <DateTimeFormatter
                              value={booking.arrival}
                              format="DD/MM/YYYY"
                            />
                            {' - '}
                            <DateTimeFormatter
                              value={booking.departure}
                              format="DD/MM/YYYY"
                            />
                          </>
                        </span>
                      </>
                    }
                    titleClassName="text-base"
                    subTitle={intl.formatMessage(
                      {
                        defaultMessage:
                          '{days, plural, =0 {no Nights} one {# Night} other {# Nights}}',
                        id: 'bookings.label.nights',
                      },
                      {
                        days: moment(booking.departure)
                          .startOf('day')
                          .diff(moment(booking.arrival).startOf('day'), 'days'),
                      },
                    )}
                    untruncated={isOnMobile}
                  />
                  <div className="mt-2 md:mt-0 flex flex-col flex-end justify-end lg:block">
                    <PermissionGate
                      allow="pms.bookings.updateInfo"
                      fallback={
                        <Button disabled>
                          <FormattedMessage
                            defaultMessage="Add room"
                            id="bookings.actions.addRoom"
                          />
                        </Button>
                      }
                    >
                      <Button
                        disabled={isFuncBtnDisabled || isModifying}
                        onClick={() => setShowAddChildBooking(true)}
                      >
                        <FormattedMessage
                          defaultMessage="Add room"
                          id="bookings.actions.addRoom"
                        />
                      </Button>
                    </PermissionGate>
                    <PermissionGate
                      allow="pms.bookings.updateInfo"
                      fallback={
                        <Button disabled className="mt-2 lg:ml-3 lg:mt-0">
                          <FormattedMessage
                            defaultMessage="Edit"
                            id="bookings.action.edit"
                          />
                        </Button>
                      }
                    >
                      <Button
                        disabled={isFuncBtnDisabled || isModifying}
                        onClick={() => setShowExtendPeriod(true)}
                        className="mt-2 lg:ml-3 lg:mt-0"
                      >
                        <FormattedMessage
                          defaultMessage="Edit"
                          id="bookings.action.edit"
                        />
                      </Button>
                    </PermissionGate>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-col w-full xl:w-1/3 mt-4 xl:mt-0 px-6 lg:py-0 bg-white mx-2 text-base">
              <div className="flex justify-between pt-6">
                <div className="flex flex-col">
                  <span className="font-semibold">
                    <FormattedMessage
                      defaultMessage="Charge"
                      id="bookings.label.charge"
                    />
                  </span>
                  <span className="text-xs text-grey-darker">
                    <FormattedMessage
                      defaultMessage="(Room and service)"
                      id="bookings.label.roomAndService"
                    />
                  </span>
                </div>
                <div className="text-orange font-medium">
                  {currencyFormater(totalCharge, currencyCode)}
                </div>
              </div>
              <div className="flex justify-between py-3">
                <div className="font-semibold">
                  <FormattedMessage
                    defaultMessage="Paid"
                    id="bookings.label.paid"
                  />
                </div>
                <div className="text-primary font-medium">
                  {currencyFormater(totalPaid, currencyCode)}
                </div>
              </div>
              {loyaltyBk && (
                <>
                  <div className="flex justify-between pt-3">
                    <div className="font-semibold">
                      <FormattedMessage
                        defaultMessage="Paid by Xu"
                        id="bookings.label.paidByXu"
                      />
                    </div>
                    <div className="text-primary font-medium">
                      {currencyFormater(coinValuation, currencyCode)}
                    </div>
                  </div>
                  <div className="flex justify-end text-2xs text-grey-darker">
                    (
                    <span className="mr-1">
                      {coinValuation /
                        (booking?.coinConfig?.redeemptionRate || 1)}
                    </span>
                    <FormattedMessage
                      defaultMessage="xu"
                      id="bookings.label.xu"
                    />
                    )
                  </div>
                </>
              )}
              <div
                className={classnames('flex justify-between', {
                  'pt-3 pb-6': !loyaltyBk,
                  'py-3': loyaltyBk,
                })}
              >
                <div className="font-semibold">
                  <FormattedMessage
                    defaultMessage="Balance"
                    id="bookings.label.balance"
                  />
                </div>
                <div className="font-medium">
                  {currencyFormater(balance, currencyCode)}
                </div>
              </div>
              {loyaltyBk && (
                <div className="flex justify-between pt-3 pb-6">
                  <div className="inline-flex items-center">
                    <IconCoin className="text-lg mr-2" />
                    <span className="font-medium">
                      {booking?.status === CHECKED_OUT ? (
                        <FormattedMessage
                          defaultMessage="Xu earned:"
                          id="bookings.label.coinsEarned"
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage="Xu will be earned:"
                          id="bookings.label.coinsWillBeEarned"
                        />
                      )}
                    </span>
                  </div>
                  <div>
                    <span className="mr-1">{earnedCoins}</span>
                    <FormattedMessage
                      defaultMessage="xu"
                      id="common.label.coins"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <Card
            title={
              <FormattedMessage
                defaultMessage="Folio"
                id="bookings.label.folio"
              />
            }
            className="mt-4"
            contentClassName="px-6 pt-4"
            noContentPadding
            hasBorder
            // extra={
            //   false && (
            //     <Button>
            //       <FormattedMessage
            //         defaultMessage="Create Master Folio"
            //         id="bookings.label.createMasterFolio"
            //       />
            //     </Button>
            //   )
            // }
          >
            <FolioTable
              rowKey="id"
              className="font-medium"
              rowClassName={(f) =>
                f.status !== 'active' ||
                booking?.rooms?.some(
                  (r) =>
                    f.bookingRoomId &&
                    r.id === f.bookingRoomId &&
                    r.status === 'checked_out',
                )
                  ? 'opacity-50 cursor-not-allowed'
                  : 'cursor-pointer'
              }
              hoverable
              bordered
              borderBottom
              deviceSize="xxl"
              dataSource={folioSorter}
              columns={folioCols}
              disabled={loading}
              onRow={(f) => ({
                onClick: () =>
                  f.status === 'active' &&
                  navigate(
                    formatRoute(
                      ROUTES.FOLIO_BOOKING,
                      booking.refCode,
                      f.refCode,
                    ),
                  ),
              })}
              pagination={false}
              scroll={booking.folios?.length > 5 ? { y: 281 } : {}}
            />
            <div className="h-14 flex items-center justify-end px-6">
              <PermissionGate allow="pms.bookings.groupFolios">
                <Button
                  disabled={isFuncBtnDisabled || validFolios.length <= 1}
                  type="line"
                  icon={IconGroup}
                  className="border-none bg-transparent hover:text-secondary"
                  color="secondary"
                  onClick={() => setShowGroupFolios(true)}
                >
                  <FormattedMessage
                    defaultMessage="Group Folio"
                    id="bookings.label.groupFolio"
                  />
                </Button>
              </PermissionGate>
              <PermissionGate allow="pms.bookings.folios.create">
                <Button
                  disabled={isFuncBtnDisabled}
                  type="line"
                  icon={IconPlus}
                  className="border-none bg-transparent hover:text-secondary ml-2"
                  color="secondary"
                  onClick={() => setShowAddFolio(true)}
                >
                  <FormattedMessage
                    defaultMessage="Add folio"
                    id="bookings.actions.addFolio"
                  />
                </Button>
              </PermissionGate>
            </div>
          </Card>
          <BookingNote
            booking={booking}
            user={user}
            isFuncBtnDisabled={isFuncBtnDisabled}
            isModifying={isModifying}
            onSetIsModifying={setIsModifying}
          />
          <RoomTypeGroupCollapse
            onChange={(a) => setCollapseKeys(a)}
            activeKey={collapseKeys}
            className="mt-4"
          >
            {roomTypeGroups.map(([roomTypeID, rooms], idx) => {
              const roomTypeName = rooms[0]?.roomTypeName;
              return (
                <Panel
                  title={`${roomTypeName}: ${intl.formatMessage(
                    {
                      defaultMessage:
                        '{count, plural, =0 {no rooms} one {# room} other {# rooms}}',
                      id: 'bookings.label.roomCount',
                    },
                    { count: rooms.length },
                  )}`}
                  key={roomTypeID}
                >
                  {rooms.map((room, idx) => (
                    <BookingRoomBlock
                      key={room.id}
                      booking={booking}
                      room={room}
                      index={idx}
                      roomsFolioBalance={roomsFolioBalance}
                      isLateCheckout={isLateCheckout}
                      isCheckoutAble={isCheckoutAble}
                      isFuncBtnDisabled={isFuncBtnDisabled}
                      isModifying={isModifying}
                      onSetShowEditRoomType={setShowEditRoomType}
                      onSetShowAddGuest={setShowAddGuest}
                      onSetAllocateRoom={setAllocateRoom}
                      onSetEditGuest={setEditGuest}
                      onSetRoomToEdit={setRoomToEdit}
                      onRequestCheckout={onRequestCheckout}
                      onRequestCheckin={onRequestCheckin}
                      onCancelChildBooking={onCancelChildBooking}
                      onRequestRemoveRoom={onRequestRemoveRoom}
                      onAssignGuestBooking={onAssignGuestBooking}
                      onUnassignGuestBooking={onUnassignGuestBooking}
                    />
                  ))}
                </Panel>
              );
            })}
          </RoomTypeGroupCollapse>

          <AvailableRoomsModal
            visible={!!allocateRoom}
            onCancel={() => setAllocateRoom(null)}
            destroyOnClose
            onSelectRoom={onRequestAllocateRoom}
            selectedRoomType={allocateRoom}
            bookingRoomId={booking.bookingRoomId}
            arrival={booking.arrival}
            departure={booking.departure}
            allocating={allocateRoom && isModifying}
            disabledRooms={booking.rooms.map((r) => r.roomID).filter(Boolean)}
            hideRoomTypes
          />
          <AddPaymentModal
            visible={modalType === 'payment' && selectedFolio?.id}
            mode="add"
            folioID={selectedFolio?.id}
            onCancel={onCloseModals}
            membership={booking?.membership}
            booking={booking}
            showLoyalty={showLoyalty}
            balance={calcTotalAmount(selectedFolio?.balance, conversionRate)}
            currencyCode={currencyCode}
          />
          <AddChargeModal
            visible={modalType === 'charge' && !!selectedFolio?.id}
            mode="add"
            folio={selectedFolio || {}}
            rooms={booking?.rooms || []}
            onCancel={onCloseModals}
            balance={calcTotalAmount(selectedFolio?.balance, conversionRate)}
          />
          <ExtendPeriodModal
            bkType={booking.type}
            destroyOnClose
            width={785}
            visible={showExtendPeriod}
            onCancel={onCloseModals}
            booking={booking}
          />
          <BookingChangeLogModal
            bid={booking?.id}
            refCode={booking?.refCode}
            visible={showChangeLogs}
            onCancel={() => setShowChangeLogs(false)}
            footer={false}
          />
          <TransferFolioModal
            visible={modalType === 'transfer' && !!selectedFolio?.id}
            bid={booking?.id}
            booking={booking}
            folioID={selectedFolio?.id}
            onCancel={onCloseModals}
          />
          <UpdateGeneralInfoModal
            bid={booking.id}
            visible={showEditInfo}
            onCancel={() => setShowEditInfo(false)}
            reference={booking.reference}
            company={booking.company}
            marketSegment={booking.marketingChannel}
            marketSegmentCode={booking.marketingSource?.name}
          />
          <EditGuestModal
            visible={!!editGuest}
            data={editGuest}
            onClose={onCloseModals}
          />
          <AddGuestModal
            bookingRefCode={booking?.refCode}
            visible={!!showAddGuest}
            room={showAddGuest}
            onClose={onCloseModals}
            existedGuess={booking?.guests.map((g) => g.id)}
            isSearch
          />
          <EditRoomTypeModal
            hotel={hotel}
            booking={booking}
            arrival={booking.arrival}
            departure={booking.departure}
            visible={!!showEditRoomType}
            room={showEditRoomType}
            setCollapseKeys={setCollapseKeys}
            disabledRooms={booking.rooms.map((r) => r.roomId).filter(Boolean)}
            onClose={onCloseModals}
            destroyOnClose
          />
          <AddChildBookingModal
            hotel={hotel}
            booking={booking}
            visible={!!showAddChildBooking}
            setCollapseKeys={setCollapseKeys}
            onClose={onCloseModals}
            destroyOnClose
          />
          <GroupFoliosModal
            booking={booking}
            folios={folioSorter}
            visible={!!showGroupFolios}
            onClose={onCloseModals}
            conversionRate={conversionRate}
          />
          <SeparateFoliosModal
            booking={booking}
            folios={folioSorter}
            folio={showSeparateFolios}
            visible={!!showSeparateFolios}
            onClose={onCloseModals}
            conversionRate={conversionRate}
          />

          <AddFolioModal
            booking={booking}
            visible={!!showAddFolio}
            onClose={onCloseModals}
            destroyOnClose
          />
          <CancelChildBookingModal
            booking={booking}
            room={roomToEdit}
            visible={!!roomToEdit}
            onClose={onCloseModals}
            destroyOnClose
          />
        </div>
      )}
    </>
  );
}

BookingDetail.propTypes = {
  bid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  room: PropTypes.node,
};

export default BookingDetail;
