import { defineMessages } from 'react-intl';

export type RoomPlanMessagesType = keyof typeof roomPlanMessages;

const roomPlanMessages = defineMessages({
  'roomPlan.label.regularMaintenance': {
    id: 'roomPlan.label.regularMaintenance',
    defaultMessage: 'Regular maintenance',
  },
  'roomPlan.label.repair': {
    id: 'roomPlan.label.repair',
    defaultMessage: 'Repair',
  },
  'roomPlan.label.damage': {
    id: 'roomPlan.label.damage',
    defaultMessage: 'Damage',
  },
  'roomPlan.label.upgradeFacilities': {
    id: 'roomPlan.label.upgradeFacilities',
    defaultMessage: 'Upgrade facilities',
  },
  'roomPlan.label.deepClean': {
    id: 'roomPlan.label.deepClean',
    defaultMessage: 'Deep clean',
  },
  'roomPlan.label.technicalProblem': {
    id: 'roomPlan.label.technicalProblem',
    defaultMessage: 'Technical problem',
  },
  'roomPlan.label.reservedRoom': {
    id: 'roomPlan.label.reservedRoom',
    defaultMessage: 'Reserved room',
  },
  'roomPlan.label.smellyRoom': {
    id: 'roomPlan.label.smellyRoom',
    defaultMessage: 'Smelly room',
  },
  'roomPlan.label.processInsects': {
    id: 'roomPlan.label.processInsects',
    defaultMessage: 'Process insects',
  },
  'roomPlan.label.other': {
    id: 'roomPlan.label.other',
    defaultMessage: 'Other',
  },
});

export default roomPlanMessages;
