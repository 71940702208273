import React, { useState, useEffect } from 'react';

import { RoomStatuses } from '../RoomStatuses';
import AlertHotelFeature from 'components/AlertHotelFeature';
import { TextBlock, Drawer, Tabs, Button, PopupConfirm } from '@aha/ui';
import SimpleBookingForm from '../SimpleBookingForm';
import CreateHourlyBookingForm from '../CreateHourlyBookingForm';
import { Alert } from 'antd';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { makeSelectCompanies } from 'containers/BookingProvider/selector';
import { useSelector, useDispatch } from 'react-redux';
import SkeletonTable from 'components/skeleton/TableSkeletonScreen';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import bookingsReducer from 'containers/BookingProvider/reducer';
import bookingSaga from 'containers/BookingProvider/saga';
import { fetchCompanies } from 'containers/BookingProvider/actions';

import moment from 'moment';
import useSelectCurrentHotel from 'containers/Dashboard/useSelectCurrentHotel';
import { fetchMarketingSources } from 'containers/SegmentCodeSettings/actions';
import mkSourcesReducer from 'containers/SegmentCodeSettings/reducer';
import mkSourcesSaga from 'containers/SegmentCodeSettings/saga';
import CreateOOOModal from '../CreateOOOModal';
import EditOOOModal from '../EditOOOModal';
import ErrorAlert from 'components/ErrorAlert';

import { deleteLog, openCreateLog } from 'containers/RoomPlanPage/actions';
import { makeSelectOpenCreateLog } from 'containers/RoomPlanPage/selector';
import OOOBadge, { OOOType } from '../OOOBadge';
import rateReducer from 'containers/RatePlansPage/reducer';
import rateSaga from 'containers/RatePlansPage/saga';

import { fetchRatePlans } from 'containers/RatePlansPage/actions';
import { makeSelectRatePlans } from 'containers/RatePlansPage/selector';
import { Dispatch } from 'redux';
import { ApplicationRootState } from 'types/app';
import { ModelCompanies } from 'containers/CompaniesReportPage';
import { DrawerProps } from 'antd/lib/drawer';
import { IconEdit, IconTrashAlt } from '@aha/icons';
import { RatePlan, Room, RoomAvailabilityLog } from 'types/schema';

const { TabPane } = Tabs;

interface InnerDrawerProps {
  isLoading: boolean;
  room: Room;
  hourlyTab: React.ReactNode;
  doDeleteLog: (log: RoomAvailabilityLog) => void;
  logToEdit: RoomAvailabilityLog | null;
  setLogToEdit: React.Dispatch<
    React.SetStateAction<RoomAvailabilityLog | null>
  >;
  onClose: () => void;
}

export const InnerDrawer = ({
  isLoading,
  room,
  hourlyTab,
  doDeleteLog,
  logToEdit,
  setLogToEdit,
  onClose,
}: InnerDrawerProps) => {
  const log = room?.roomAvailabilityLogs?.[0];
  let oooType: OOOType | undefined;
  if (log) {
    if (moment().isBefore(log.unAvailableFrom, 'minutes')) {
      oooType = 'future';
    } else if (
      moment().isBetween(
        moment(log.unAvailableFrom),
        moment(log.availableDate),
        'minutes',
        '[]',
      )
    ) {
      oooType = 'now';
    }
  }

  async function deleteOOO() {
    if (!log) {
      return;
    }

    doDeleteLog(log);
  }

  return (
    <React.Fragment>
      <TextBlock
        title={room.title}
        subTitle={room.roomType?.name}
        titleClassName="font-semibold text-base"
        subTitleClassName="font-semibold"
      />
      {!!oooType && (
        <OOOBadge
          className="mb-4"
          log={log || {}}
          type={oooType}
          renderButtons={
            <>
              <Button
                icon={IconEdit}
                type="icon"
                size="small"
                className="mr-2"
                onClick={() => log && setLogToEdit(log)}
              />
              <PopupConfirm
                title={
                  <FormattedMessage
                    defaultMessage="Remove timer"
                    id="common.action.removeTimer"
                  />
                }
                placement="bottom"
                arrowPointAtCenter
                onConfirm={() => deleteOOO()}
                onCancel={(e) => e && e.stopPropagation()}
                okText={
                  <FormattedMessage
                    defaultMessage="Remove"
                    id="common.action.remove"
                  />
                }
                cancelText={
                  <FormattedMessage
                    defaultMessage="Keep"
                    id="common.action.keep"
                  />
                }
              >
                <Button icon={IconTrashAlt} size="small" type="icon" />
              </PopupConfirm>
            </>
          }
        />
      )}
      {oooType !== 'now' && (
        <div className="mb-4">
          <RoomStatuses room={room} />
        </div>
      )}
      {isLoading ? (
        <SkeletonTable />
      ) : oooType === 'now' ? (
        <ErrorAlert
          message={
            <FormattedMessage
              defaultMessage="Out of order"
              id="common.message.outOfOrder"
            />
          }
          description={
            <FormattedMessage
              defaultMessage="This room is temporarily out of order and can not be assigned to any booking."
              id="common.message.roomOurOfOrder"
            />
          }
        />
      ) : (
        <Tabs>
          <TabPane
            tab={
              <span>
                <FormattedMessage
                  defaultMessage="Daily booking"
                  id="bookings.label.daily_booking"
                />
              </span>
            }
            key="daily_booking"
          >
            <SimpleBookingForm room={room} onClose={onClose} />
          </TabPane>
          <TabPane
            tab={
              <span>
                <FormattedMessage
                  defaultMessage="Hourly booking"
                  id="bookings.label.hourly_booking"
                />
              </span>
            }
            key="hourly_booking"
          >
            {hourlyTab}
          </TabPane>
        </Tabs>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    companies: ModelCompanies[];
    rates: RatePlan[];
    showCreateOOO: boolean;
  }
>({
  companies: makeSelectCompanies(),
  rates: makeSelectRatePlans(),
  showCreateOOO: makeSelectOpenCreateLog(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doFetchRates: (hid: number, start: moment.Moment, end: moment.Moment) =>
    dispatch(fetchRatePlans(hid, start, end)),
  doFetchCompanies: (hid: number) => dispatch(fetchCompanies(hid)),
  doFetchSegmentCodes: () => dispatch(fetchMarketingSources()),
  doDeleteLog: (log: RoomAvailabilityLog) => dispatch(deleteLog(log)),
  doOpenCreateLog: (showModal: boolean) => dispatch(openCreateLog(showModal)),
});

interface CreateBookingDrawerProps extends DrawerProps {
  enabledFeatures: string[];
  room: Room;
  onClose: () => void;
}

export default function CreateBookingDrawer({
  enabledFeatures,
  room,
  onClose,
  ...rest
}: CreateBookingDrawerProps) {
  useInjectReducer({ key: 'bookings', reducer: bookingsReducer });
  useInjectSaga({ key: 'bookings', saga: bookingSaga });

  useInjectReducer({ key: 'marketingSources', reducer: mkSourcesReducer });
  useInjectSaga({ key: 'marketingSources', saga: mkSourcesSaga });

  useInjectReducer({ key: 'ratePlans', reducer: rateReducer });
  useInjectSaga({ key: 'ratePlans', saga: rateSaga });

  const dispatch = useDispatch();
  const {
    doFetchRates,
    doFetchCompanies,
    doFetchSegmentCodes,
    doDeleteLog,
    doOpenCreateLog,
  } = mapDispatchToProps(dispatch);
  const hotel = useSelectCurrentHotel();

  const [logToEdit, setLogToEdit] = useState<RoomAvailabilityLog | null>(null);

  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    if (hotel?.id) {
      doFetchRates(hotel.id, moment(), moment().add(1, 'day'));
      doFetchCompanies(hotel.id);
      doFetchSegmentCodes();
    }
  }, [hotel.id]); // eslint-disable-line

  const { rates, companies, showCreateOOO } = useSelector(mapStateToProps);
  const isLoading = !rates?.length || !companies?.length;

  let hourlyTab = <AlertHotelFeature className="m-4" />;
  if (enabledFeatures?.includes('hourly_booking')) {
    if (room?.housekeepingStatus === 'clean') {
      if (room?.roomType?.hourlySettings) {
        hourlyTab = <CreateHourlyBookingForm room={room} onClose={onClose} />;
      } else {
        hourlyTab = (
          <div className="p-6 bg-white">
            <Alert
              type="error"
              className="px-6 py-3 border-none"
              message={
                <span className="font-medium text-red">
                  <FormattedMessage
                    defaultMessage="This room does not support hourly booking"
                    id="common.message.roomNotSupportHourlyBK"
                  />
                </span>
              }
            />
          </div>
        );
      }
    } else {
      hourlyTab = (
        <div className="p-6 bg-white">
          <Alert
            type="warning"
            description={
              <FormattedHTMLMessage
                defaultMessage="Please update clean room to continue"
                id="common.message.updateRoomHkStatus"
              />
            }
            message=""
          />
        </div>
      );
    }
  }

  return (
    <>
      <Drawer onClose={onClose} {...rest}>
        <InnerDrawer
          isLoading={isLoading}
          room={room}
          hourlyTab={hourlyTab}
          doDeleteLog={doDeleteLog}
          logToEdit={logToEdit}
          setLogToEdit={setLogToEdit}
          onClose={onClose}
        />
      </Drawer>
      <CreateOOOModal
        visible={showCreateOOO}
        room={room}
        onClose={() => doOpenCreateLog(false)}
      />
      <EditOOOModal
        visible={!!logToEdit}
        log={logToEdit || {}}
        roomTitle={room?.title || 'N/A'}
        onClose={() => setLogToEdit(null)}
      />
    </>
  );
}
