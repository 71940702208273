import { action } from 'typesafe-actions';
import ActionTypes from './constants';
import { InjectPromise } from 'types/actions';
import { ModelRoomTypeHourly, ModelRoomTypeSetting } from './type';

export const fetchRoomTypes = (hotelId?: string) =>
  action(ActionTypes.FETCH_ROOM_TYPES, hotelId);

export const fetchRoomTypesSuccess = (roomTypes: ModelRoomTypeSetting[]) =>
  action(ActionTypes.FETCH_ROOM_TYPES_SUCCESS, roomTypes);

export const fetchRoomTypesFail = (error: Error) =>
  action(ActionTypes.FETCH_ROOM_TYPES_FAIL, error);

export const resetRoomTypestat = () =>
  action(ActionTypes.RESET_ROOM_TYPES_STATE);

export const createSyncCms = (hotelId: number) =>
  action(ActionTypes.CREATE_SYNC_PMS, hotelId);

export const createSyncCmsSuccess = (roomsSync: ModelRoomTypeSetting[]) =>
  action(ActionTypes.CREATE_SYNC_PMS_SUCCESS, roomsSync);

export const createSyncCmsFail = () => action(ActionTypes.CREATE_SYNC_PMS_FAIL);

export const deleteRoomType = (roomTypeId: number) =>
  action(ActionTypes.DELETE_ROOM_TYPE, roomTypeId);

export const deleteRoomTypeSuccess = (roomTypeId: number) =>
  action(ActionTypes.DELETE_ROOM_TYPE_SUCCESS, roomTypeId);

export const deleteRoomTypeFail = (error: Error) =>
  action(ActionTypes.DELETE_ROOM_TYPE_FAIL, error);

export const createRoomType = (
  params: Required<
    InjectPromise<
      ModelRoomTypeSetting,
      { hotelId: number; roomType: ModelRoomTypeSetting }
    >
  >,
) => action(ActionTypes.CREATE_ROOM_TYPE, params);

export const createRoomTypeSuccess = (roomType: ModelRoomTypeSetting) =>
  action(ActionTypes.CREATE_ROOM_TYPE_SUCCESS, roomType);

export const createRoomTypeFail = (error: Error) =>
  action(ActionTypes.CREATE_ROOM_TYPE_FAIL, error);

export const editRoomType = (
  params: Required<
    InjectPromise<
      ModelRoomTypeSetting,
      { roomTypeId: number; roomType: ModelRoomTypeSetting }
    >
  >,
) => action(ActionTypes.EDIT_ROOM_TYPE, params);

export const editRoomTypeSuccess = (roomType: ModelRoomTypeSetting) =>
  action(ActionTypes.EDIT_ROOM_TYPE_SUCCESS, roomType);

export const editRoomTypeFail = (error: Error) =>
  action(ActionTypes.EDIT_ROOM_TYPE_FAIL, error);

export const editRoomTypeHourlySetting = (
  params: Required<
    InjectPromise<
      ModelRoomTypeSetting,
      { roomTypeId: number; hourlyService: ModelRoomTypeSetting }
    >
  >,
) => action(ActionTypes.EDIT_ROOM_TYPE_HOURLY_SETTING, params);

export const editRoomTypeHourlySettingSuccess = (
  hourlyService: ModelRoomTypeHourly,
) => action(ActionTypes.EDIT_ROOM_TYPE_HOURLY_SETTING_SUCCESS, hourlyService);

export const editRoomTypeHourlySettingFail = (error: Error) =>
  action(ActionTypes.EDIT_ROOM_TYPE_HOURLY_SETTING_FAIL, error);
