import { takeLatest, put, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { ADD_GUEST_TO_BOOKING } from '../constants';
import { showErrorNotification } from '@aha/utils';
import NProgress from 'nprogress';
import { addGuestSuccess } from 'containers/SettingGuestsPage/actions';

function* doAddGuestToBooking({ refCode, bkRoomGuest, resolve, reject }) {
  yield call(NProgress.start);
  try {
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/bookings/${refCode}/guests`,
      bkRoomGuest,
    );

    yield put(addGuestSuccess(bkRoomGuest?.guest));
    resolve(data);
  } catch (err) {
    showErrorNotification('Fail to add', err);
    reject();
  }
  yield call(NProgress.done);
}

export function* watchAddGuestToBooking() {
  yield takeLatest(ADD_GUEST_TO_BOOKING, doAddGuestToBooking);
}
