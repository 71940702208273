import React, { useState, useEffect } from 'react';
import { Input as AntInputQuantity } from 'antd';
import styled from 'styled-components';
import classnames from 'classnames';

export interface RangeInputProps {
  className?: string;
  value?: [number, number];
  onChange?: (value: [number, number]) => void;
  invalid?: boolean;
}

const StyledInput = styled(AntInputQuantity)`
  .ant-input {
    text-align: center !important;
    box-shadow: none !important;
    border: none !important;
  }
  .ant-input-suffix {
    right: 6px;
  }
  .ant-input-disabled:hover {
    border: 1px solid var(--grey-dark) !important;
  }
`;

const InputWrapper = styled.div`
  background-color: var(--white);
  input[type='number']::-webkit-inner-sbottom-0utton,
  input[type='number']::-webkit-outer-sbottom-0utton {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default function InputQuantity({
  className = '',
  value = [0, 0],
  onChange,
  invalid = false,
  ...rest
}: RangeInputProps) {
  const [value0, setValue0] = useState(value[0]);
  const [value1, setValue1] = useState(value[1]);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    onChange && onChange([value0, value1]);
  }, [value0, value1]);

  function focus() {
    setIsFocus(true);
  }

  function onBlur0() {
    setValue0(value1 <= value0 ? value1 - 1 : value0);
    setIsFocus(false);
  }

  function onBlur1() {
    setValue1(value1 <= value0 ? value0 + 1 : value1);
    setIsFocus(false);
  }

  function changeValue0(e: React.ChangeEvent<HTMLInputElement>) {
    setValue0(Number(e.target.value));
  }

  function changeValue1(e: React.ChangeEvent<HTMLInputElement>) {
    setValue1(Number(e.target.value));
  }

  return (
    <InputWrapper
      data-testid="range-input"
      className={classnames(
        'flex items-center justify-between p-1 border rounded-sm hover:border-primary focus:border-primary',
        {
          'border-red': invalid,
          'border-primary': isFocus,
          'border-grey-dark': !isFocus,
          [className]: className,
        },
      )}
    >
      <StyledInput
        type="number"
        min="0"
        onBlur={onBlur0}
        onFocus={focus}
        value={value[0]}
        className="left-input h-full border-none shadow-none text-center"
        onChange={changeValue0}
        data-testid="range-input-min"
      />
      <div className="w-2 h-px bg-secondary mx-3 flex-none"></div>
      <StyledInput
        type="number"
        min="0"
        value={value[1]}
        onBlur={onBlur1}
        onFocus={focus}
        className="right-input h-full border-none shadow-none text-center"
        onChange={changeValue1}
        data-testid="range-input-max"
      />
    </InputWrapper>
  );
}
