import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import Modal from '../Modal';
import Button from '../Button';
import { IconExclamationSquare } from '@aha/icons';

const Container = styled.div`
  ${tw`flex items-center w-full mb-3 ml-6 `}
  margin-top: -1.5rem !important;
`;

const IconStyled = styled.div`
  ${tw`flex items-center justify-center mr-2`}
`;

const TitleStyled = styled.div`
  ${tw`text-base font-semibold text-center uppercase`}
`;

const StyledModal = styled(Modal)`
  .ant-modal-footer > div > button:first-child {
    width: auto !important;
    text-transform: none !important;
  }
  .ant-modal-footer > div > button:last-child {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .ant-modal-content > button:first-child {
    display: none;
  }
`;

export interface ConfirmModalProps {
  title: React.ReactNode;
  description: React.ReactNode;
  submitText: React.ReactNode;
  cancelText: React.ReactNode;
  visible?: boolean;
  submitting?: boolean;
  onSave: () => void;
  onClose: () => void;
}

export interface InnerModalProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
}

export const InnerModal = ({ description, title }: InnerModalProps) => (
  <>
    <Container>
      <IconStyled>
        <IconExclamationSquare className="text-xl text-yellow" />
      </IconStyled>
      <TitleStyled data-testid="title_test">{title}</TitleStyled>
    </Container>
    <div className="mb-6 ml-6">{description}</div>
  </>
);

const ConfirmModal = ({
  onSave,
  onClose,
  title,
  description,
  submitText,
  cancelText,
  visible = false,
  submitting,
}: ConfirmModalProps) => (
  <StyledModal
    footer={
      <div className="flex items-center justify-end h-full">
        <Button
          className="w-32 h-8 px-9/2"
          disabled={submitting}
          onClick={onSave}
        >
          {submitText}
        </Button>
        <Button
          className="flex items-center h-8 ml-6 mr-2 font-medium bg-transparent border-none text-secondary "
          onClick={onClose}
        >
          {cancelText}
        </Button>
      </div>
    }
    visible={visible}
    submitting={submitting}
    width={600}
    maskClosable
    onCancel={onClose}
  >
    <InnerModal title={title} description={description} />
  </StyledModal>
);

export default ConfirmModal;
