import { all, put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { showErrorNotification } from '@aha/utils';
import { coreAPI } from 'utils/request';
import NProgress from 'nprogress';

import ActionTypes from './constants';

import {
  fetchGuestsSuccess,
  fetchGuestsFailure,
  editGuestSuccess,
} from './actions';
import { GuestsActions } from './types';
import { ExtractAction } from 'types/actions';

function* doFetchGuests(
  action: ExtractAction<GuestsActions, ActionTypes.FETCH_GUESTS>,
) {
  yield call(NProgress.start);
  try {
    const {
      payload: { pageNumber = 1, pageSize = 10, type, value },
    } = action;

    const q = [];

    if (value) {
      q.push(`value=${value}`);
      if (type) {
        q.push(`type=${type}`);
      }
    }

    const { data, metadata: pagination } = yield call(
      coreAPI.get,
      `v1/pms/guests?page=${pageNumber}&page_size=${pageSize}&${q.join('&')}`,
    );
    yield put(fetchGuestsSuccess(data, pagination));
  } catch (err) {
    yield put(fetchGuestsFailure(err));
  }
  yield call(NProgress.done);
}

function* watchFetchGuests() {
  yield takeLatest(ActionTypes.FETCH_GUESTS, doFetchGuests);
}

function* doEditGuest(
  action: ExtractAction<GuestsActions, ActionTypes.EDIT_GUEST>,
) {
  const {
    payload: {
      resolve,
      reject,
      data: { guestId, guest },
    },
  } = action;

  try {
    const { data } = yield call(coreAPI.put, `v1/pms/guests/${guestId}`, guest);

    yield put(editGuestSuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification(
      'Fail to edit',
      `Cannot edit the guest id #${guestId} at the moment, please try it later.`,
    );
    reject(err);
  }
}

function* watchEditGuest() {
  yield takeEvery(ActionTypes.EDIT_GUEST, doEditGuest);
}

export default function* watchAll() {
  yield all([watchFetchGuests(), watchEditGuest()]);
}
