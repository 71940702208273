import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { selectDashboard } from 'containers/Dashboard/selectors';

export const selectBookings = (state) => state.bookings || initialState;

export function makeSelectLoading() {
  return createSelector(selectBookings, (state) => state.loading);
}

export function makeSelectArrivalLoading() {
  return createSelector(selectBookings, (state) => state.arrivalLoading);
}

export function makeSelectDepartureLoading() {
  return createSelector(selectBookings, (state) => state.departureLoading);
}

export function makeSelectError() {
  return createSelector(selectBookings, (state) => state.error);
}

export function makeSelectBooking() {
  return createSelector(selectBookings, (state) => state.booking);
}

export function makeSelectDepartureBooking() {
  return createSelector(selectBookings, (state) => state.booking.departure);
}

export function makeSelectFolios() {
  return createSelector(selectBookings, (state) => state.folios);
}

export function makeSelectExpectedBookings() {
  return createSelector(selectBookings, (state) => state.bookings);
}

export function makeSelectCheckedInBookings() {
  return createSelector(selectBookings, (state) => state.checkedInBookings);
}

export function makeSelectCheckedOutBookings() {
  return createSelector(selectBookings, (state) => state.checkedOutBookings);
}

export function makeSelectInHouseGuestList() {
  return createSelector(selectBookings, (state) => state.inHouseGuestList);
}

export function makeSelectMapAvailableRooms() {
  return createSelector(selectBookings, (state) => state.availableRooms || {});
}

export function makeSelectAvailableRooms() {
  return createSelector(selectBookings, (state) =>
    Object.values(state.availableRooms || {}).reduce(
      (res, rooms) => [...res, ...rooms],
      [],
    ),
  );
}

export function makeSelectIsLoadingAvailableRooms() {
  return createSelector(selectBookings, (state) => state.loadingRooms);
}

export function makeSelectIsLoadingFolio() {
  return createSelector(
    selectBookings,
    ({ loading, loadingFolio }) => loading || loadingFolio,
  );
}
export function makeSelectFolio() {
  return createSelector(selectBookings, (state) => state.folio);
}

export function makeSelectConversionRate() {
  return createSelector(selectDashboard, (dashboard) => {
    return dashboard.currencies?.reduce(
      (fin, c) => ({ ...fin, [c.name]: Number(c.rate?.rate) }),
      {},
    );
  });
}

export function makeSelectPaymentTypes() {
  return createSelector(selectBookings, (state) => {
    const [cash] = state.paymentTypes.filter(({ name }) => name === 'Cash');
    return [
      cash,
      ...state.paymentTypes.filter(({ name }) => name !== 'Cash'),
    ].filter(Boolean);
  });
}

export function makeSelectChargeTemplates() {
  return createSelector(selectBookings, (state) =>
    state.chargeTemplates?.filter((c) => c.status === 'active'),
  );
}
export function makeSelectRevGroups() {
  return createSelector(selectBookings, (state) => state.revGroups);
}

export function makeSelectBookingChangeLogs() {
  return createSelector(selectBookings, (state) => state.bookingHistory);
}

export function makeSelectIsFetchingChangeLogs() {
  return createSelector(selectBookings, (state) => state.isLoadingChangeLogs);
}

export function makeSelectCompanies() {
  return createSelector(selectBookings, ({ companies }) =>
    companies?.filter((c) => c.status === 'active'),
  );
}

export function makeSelectCheckinPagination() {
  return createSelector(selectBookings, (state) => state.checkinPagination);
}

export function makeSelectInHousePagination() {
  return createSelector(selectBookings, (state) => state.inHousePagination);
}

export function makeSelectCheckoutPagination() {
  return createSelector(selectBookings, (state) => state.checkoutPagination);
}

export function makeSelectTodayCheckinPagination() {
  return createSelector(
    selectBookings,
    (state) => state.todayCheckinPagination,
  );
}

export function makeSelectTodayCheckinBookings() {
  return createSelector(selectBookings, (state) => state.todayCheckinBookings);
}

export function makeSelectTodayCheckoutPagination() {
  return createSelector(
    selectBookings,
    (state) => state.todayCheckoutPagination,
  );
}

export function makeSelectTodayCheckoutBookings() {
  return createSelector(selectBookings, (state) => state.todayCheckoutBookings);
}

export function makeSelectExpiredBookings() {
  return createSelector(selectBookings, (state) => state.expiredBks);
}

export function makeSelectExpiredBookingsPagination() {
  return createSelector(selectBookings, (state) => state.expiredPagination);
}

export function makeSelectUpcomingBookings() {
  return createSelector(selectBookings, (state) => state.upcomingBks);
}

export function makeSelectUpcomingPagination() {
  return createSelector(selectBookings, (state) => state.upcomingPagination);
}

export function makeSelectCurrentTab() {
  return createSelector(selectBookings, (state) => state.currentTab);
}

export function makeSelectExpiredBookingCount() {
  return createSelector(
    selectBookings,
    (state) => state.expiredPagination?.totalCount,
  );
}
