import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { useMedia } from '@aha/utils';

const Button = styled.button`
  width: 96px;
  height: 32px;
  box-sizing: border-box;
  outline: none !important;
  transition: all 0.2s;
  background: var(--white);

  &:disabled {
    cursor: not-allowed;
    background: #c6c6c6 !important;
    color: var(--white);
  }
`;

const Container = styled.div`
  button:first-of-type {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  button:last-of-type {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  button:not(:first-of-type) {
    border-left: none !important;
  }
`;

export type RoomStatus = 'clean' | 'cleaning' | 'dirty' | 'out_of_order';

export type RoomStatusOption = { label: React.ReactNode; value: RoomStatus };

const defaultOptions: Array<RoomStatusOption> = [
  { label: 'Clean', value: 'clean' },
  { label: 'Cleaning', value: 'cleaning' },
  { label: 'Dirty', value: 'dirty' },
  { label: 'OOO', value: 'out_of_order' },
];

export interface RoomStatusSelectorProps {
  onChange: (status: RoomStatus) => void;
  value: RoomStatus;
  loading?: boolean;
  className?: string;
  options?: Array<RoomStatusOption>;
  disabledOOO?: boolean;
}

function RoomStatusSelector({
  loading,
  className = '',
  value = 'clean',
  onChange,
  options = defaultOptions,
  disabledOOO,
}: RoomStatusSelectorProps) {
  const isOnMobile = useMedia('md');

  return (
    <Container
      className={classnames('flex items-center flex-wrap md:flex-no-wrap', {
        [className]: className,
      })}
    >
      {options.map((opt) => (
        <Button
          disabled={loading || (opt.value === 'out_of_order' && disabledOOO)}
          className={classnames(
            'inline-flex items-center justify-center capitalize text-sm border-grey-light border',
            value === opt.value && {
              'text-white': true,
              'bg-primary': value === 'clean',
              'bg-blue': value === 'cleaning',
              'bg-grey-darker': value === 'dirty',
              'bg-orange': value === 'out_of_order',
            },
            { 'w-1/4': isOnMobile },
          )}
          onClick={(e) => {
            e.preventDefault();
            if (typeof onChange !== 'function') {
              return;
            }

            if (value !== opt.value) {
              onChange(opt.value);
            }
          }}
          key={opt.value}
        >
          {opt.label}
        </Button>
      ))}
    </Container>
  );
}

export default React.memo(RoomStatusSelector);
