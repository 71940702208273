import { takeEvery, put, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { checkoutBookingFail, checkoutBookingSuccess } from '../actions';
import { CHECK_OUT_BOOKING } from '../constants';
import { showErrorNotification } from '@aha/utils';
import LockDoorAPI from '../loockdoor';

function* doCheckout({ booking, bookingRoomId, reject, resolve, lockdoor }) {
  try {
    const r = booking.rooms.find((bkr) => bkr.id === bookingRoomId);
    if (lockdoor) {
      yield call(
        LockDoorAPI.checkout,
        r?.lockDoorRoomNo || booking.rooms[0].lockDoorRoomNo,
      );
    }
    const req = {};
    if (bookingRoomId) {
      req.bookingRoomId = bookingRoomId;
    }
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/bookings/${booking.id}/checkout`,
      req,
    );
    yield put(checkoutBookingSuccess(data, bookingRoomId));

    LockDoorAPI.removeCached();
    resolve(data);
  } catch (err) {
    showErrorNotification('Checkout booking failed', err);
    yield put(checkoutBookingFail(err));
    reject(err);
  }
}

export function* watchCheckoutBooking() {
  yield takeEvery(CHECK_OUT_BOOKING, doCheckout);
}
