import React, { useState, useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import { Modal } from '@aha/ui';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { makeSelectCompanies } from 'containers/BookingProvider/selector';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchCompanies,
  updateBookingInfo,
} from 'containers/BookingProvider/actions';
import { makeSelectCurrentHotel } from 'containers/Dashboard/selectors';
import { removeAccents } from '@aha/utils';

const mapStateToProps = createStructuredSelector({
  companies: makeSelectCompanies(),
  hotel: makeSelectCurrentHotel(),
});

const mapDispatch = (dispatch) => ({
  doFetchCompanies: (hid) => dispatch(fetchCompanies(hid)),
  doUpdateBookingInfo: (bid, reqData) =>
    new Promise((resolve, reject) => {
      dispatch(updateBookingInfo(bid, reqData, resolve, reject));
    }),
});

export const InnerModal = ({
  getFieldDecorator,
  reference,
  company,
  onSelectCompany,
  companies,
  mkSegmentCode,
  mkSegment,
}) => {
  return (
    <Form layout="vertical">
      <div className="flex flex-wrap -mx-2">
        <div className="px-2 w-1/2">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="Reference"
                id="common.label.reference"
              />
            }
          >
            {getFieldDecorator('reference', { initialValue: reference })(
              <Input />,
            )}
          </Form.Item>
        </div>
        <div className="px-2 w-1/2">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="Company"
                id="common.label.company"
              />
            }
          >
            {getFieldDecorator('companyId', {
              initialValue: companies?.find((c) => c.name === company)?.id,
            })(
              <Select
                showSearch
                onSelect={onSelectCompany}
                filterOption={(input, option) =>
                  removeAccents(
                    option.props.children.toString().toLowerCase(),
                  ).indexOf(removeAccents(input.toLowerCase())) >= 0
                }
                placeholder={
                  <FormattedMessage
                    id="booking.label.selectCompany"
                    defaultMessage="Select company"
                  />
                }
              >
                {(companies || []).map((c) => (
                  <Select.Option key={c.id} value={c.id}>
                    {c.name}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </div>
        <div className="px-2 w-1/2">
          <Form.Item label="Segment">
            <Input disabled value={mkSegmentCode} />
          </Form.Item>
        </div>
        <div className="px-2 w-1/2">
          <Form.Item label="Channel">
            <Input disabled value={mkSegment} />
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

function UpdateGeneralInfoModal({
  bid,
  company,
  reference,
  marketSegment,
  marketSegmentCode,
  form,
  onCancel,
  ...rest
}) {
  const [mkSegment, setMarketSegment] = useState(marketSegment);
  const [mkSegmentCode, setMarketSegmentCode] = useState(marketSegmentCode);
  const [isUpdating, setIsUpdating] = useState(false);

  const { getFieldDecorator } = form;
  const { companies, hotel } = useSelector(mapStateToProps);
  const { doFetchCompanies, doUpdateBookingInfo } = mapDispatch(useDispatch());

  useEffect(() => {
    doFetchCompanies(hotel.id);
  }, [hotel.id]); // eslint-disable-line

  useEffect(() => {
    setMarketSegment(marketSegment);
    setMarketSegmentCode(marketSegmentCode);
  }, [marketSegment, marketSegmentCode]);

  function onSelectCompany(id) {
    const company = companies.find((c) => c.ID === id);
    if (company) {
      setMarketSegment(company.MarketingChannel?.name);
      setMarketSegmentCode(
        company.MarketingSources.map((m) => m.name).join(', '),
      );
    }
  }

  async function onUpdateBookingInfo() {
    try {
      setIsUpdating(true);
      const { companyID: cid, reference: ref } = await form.validateFields();
      doUpdateBookingInfo(bid, { companyID: cid, reference: ref });
      onCancel();
    } catch (err) {
    } finally {
      setIsUpdating(false);
    }
  }

  return (
    <Modal
      {...rest}
      destroyOnClose
      submitting={isUpdating}
      onSubmit={onUpdateBookingInfo}
      onCancel={onCancel}
      title={
        <FormattedMessage
          defaultMessage="Update booking's general info"
          id="bookings.label.updateGeneralInfo"
        />
      }
      submitText={
        <FormattedMessage defaultMessage="Update" id="common.action.update" />
      }
      cancelText={
        <FormattedMessage defaultMessage="Cancel" id="common.action.cancel" />
      }
    >
      <InnerModal
        getFieldDecorator={getFieldDecorator}
        reference={reference}
        company={company}
        onSelectCompany={onSelectCompany}
        companies={companies}
        mkSegmentCode={mkSegmentCode}
        mkSegment={mkSegment}
      />
    </Modal>
  );
}

export default Form.create('update_booking_info')(UpdateGeneralInfoModal);
