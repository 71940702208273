import React, { useState, useRef, useEffect } from 'react';
import { Form, Row, Col, InputNumber, Divider } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import List from 'components/List';
import { Modal, Select, Button } from '@aha/ui';
import { editRoomTypeHourlySetting } from 'containers/SettingRoomTypesPage/actions';
import { isEmpty, size } from 'lodash';
import { Dispatch } from 'redux';
import { ModelRoomTypeSetting } from 'containers/SettingRoomTypesPage/type';
import { FormComponentProps } from 'antd/lib/form';
import { useDispatch } from 'react-redux';
import { numberFormatterBack } from '@aha/utils';
import { IconRemoveCircle, IconPlusCircle } from '@aha/icons';
import { HotelCurrency, HourlySetting } from 'types/schema';

export type EditHourlyServicesModalProps = {
  roomType: ModelRoomTypeSetting | null;
  currencies: HotelCurrency[];
  visible?: boolean;
  onClose?: (...args: any[]) => any;
} & FormComponentProps;

export type AdditionalHoursType = { [k: string]: number };

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doEditRoomTypeHourlySetting: (roomTypeId: number, hourlyService: any) =>
    new Promise((resolve, reject) =>
      dispatch(
        editRoomTypeHourlySetting({
          data: { roomTypeId, hourlyService },
          resolve,
          reject,
        }),
      ),
    ),
});

export const mappingKeys: Record<string, string> = {
  secondHour: '2Hours',
  thirdHour: '3Hours',
  fourthHour: '4Hours',
  fifthHour: '5Hours',
  sixthHour: '6Hours',
  seventhHour: '7Hours',
};

const EditHourlyServicesModal = ({
  roomType,
  currencies,
  visible,
  onClose,
  form,
}: EditHourlyServicesModalProps) => {
  const dispatch = useDispatch();
  const { doEditRoomTypeHourlySetting } = mapDispatchToProps(dispatch);

  const { getFieldDecorator, validateFields, resetFields } = form;
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [additionalHours, setAdditionalHours] = useState<
    AdditionalHoursType | null | undefined
  >(null);

  const { id, hourlySettings } = roomType || {};
  const inputHourRef = useRef<InputNumber>(null);
  const { currencyId, timePrecision, price, dailyPrice } = hourlySettings || {};

  const intl = useIntl();

  useEffect(() => {
    if (
      !additionalHours &&
      !size(additionalHours) &&
      hourlySettings?.secondHour
    ) {
      const initialValue = ([
        'secondHour',
        'thirdHour',
        'fourthHour',
        'fifthHour',
        'sixthHour',
        'seventhHour',
      ] as (keyof HourlySetting)[]).reduce((prev, key, index) => {
        const price = hourlySettings[key];
        if (price) {
          prev[mappingKeys[key]] = +price;
        }
        return prev;
      }, {} as AdditionalHoursType);

      setAdditionalHours(initialValue);
    }
    setTimeout(() => inputHourRef.current?.focus());
  }, [hourlySettings, size(additionalHours)]); // eslint-disable-line

  function onCancel() {
    if (submitting) {
      return;
    }

    onClose && onClose();
    setAdditionalHours(null);
    resetFields();
  }

  function onCreate() {
    validateFields(async (err, values) => {
      if (err || !id) {
        return;
      }

      try {
        setSubmitting(true);
        await doEditRoomTypeHourlySetting(id, {
          ...values,
          additionalHourPrices: additionalHours ? additionalHours : {},
        });

        onClose && onClose();
        setAdditionalHours(null);
        resetFields();
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    });
  }

  function onChangeHourlyPrice(value: number, key: string) {
    let updatedKey = null;
    if (!value) {
      updatedKey = { [key]: 0 };
    } else {
      updatedKey = { [key]: value };
    }
    setAdditionalHours({ ...additionalHours, ...updatedKey });
  }

  function onRemoveObjectKey(key: string) {
    let newAdditionalHours: AdditionalHoursType = { ...additionalHours };
    if (newAdditionalHours.hasOwnProperty(key)) {
      delete newAdditionalHours[key];
    }
    setAdditionalHours(newAdditionalHours);
  }

  function onAddNewHour() {
    const numberOfKeys = Object.keys(additionalHours || []).length;
    if (numberOfKeys === 0) {
      setAdditionalHours({ '2Hours': 0 });
    } else {
      const currentHour = `${numberOfKeys + 2}Hours`;
      setAdditionalHours({ ...additionalHours, [currentHour]: 0 });
    }
  }

  return (
    <Modal
      visible={visible}
      title={
        <FormattedMessage
          defaultMessage="Create/Edit Hourly Booking Settings"
          id="settings.label.edit_hourly_settings"
          values={{ id }}
        />
      }
      submitting={submitting}
      onCancel={onCancel}
      onSubmit={onCreate}
      width={560}
      submitText={
        <FormattedMessage defaultMessage="Save" id="common.action.save" />
      }
    >
      <Form layout="vertical">
        <Row gutter={16}>
          <Col xs={24} md={12} sm={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Minutes round up to 1 hour"
                  id="settings.label.minutes_round_up_to_1_hour"
                />
              }
            >
              {getFieldDecorator('timePrecision', {
                initialValue: timePrecision,
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                  {
                    validator: (rule, value, cb) =>
                      value && isNaN(value)
                        ? cb(
                            <FormattedMessage
                              id="common.message.invalid"
                              defaultMessage="Invalid"
                            />,
                          )
                        : cb(),
                  },
                ],
              })(
                <InputNumber
                  min={0}
                  max={59}
                  className="w-full"
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Enter minutes',
                    id: 'common.message.enterMinutes',
                  })}
                />,
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={12} sm={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Currency"
                  id="settings.label.currency"
                />
              }
            >
              {getFieldDecorator('currencyId', {
                initialValue: currencyId,
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder={
                    <FormattedMessage
                      defaultMessage="Select currency"
                      id="common.label.selectCurrency"
                    />
                  }
                >
                  {currencies?.map((c) => (
                    <Select.Option key={c?.id} value={c?.id}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12} sm={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="First hour price"
                  id="settings.label.price_1st_hour"
                />
              }
            >
              {getFieldDecorator('price', {
                initialValue: price,
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
              })(
                <InputNumber
                  className="w-full"
                  min={0}
                  formatter={(value: number | string | undefined = 0) => {
                    const formatNumber = value
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

                    return formatNumber;
                  }}
                  parser={(value?: string) =>
                    numberFormatterBack((value || '0') as string)
                  }
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Enter price',
                    id: 'common.message.enterPrice',
                  })}
                />,
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={12} sm={12}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Room night"
                  id="settings.label.roomNightPrice"
                />
              }
            >
              {getFieldDecorator('dailyPrice', {
                initialValue: dailyPrice,
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
              })(
                <InputNumber
                  className="w-full"
                  min={0}
                  formatter={(value: number | string | undefined = 0) => {
                    const formatNumber = value
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

                    return formatNumber;
                  }}
                  parser={(value?: string) =>
                    numberFormatterBack((value || '0') as string)
                  }
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Enter price',
                    id: 'common.message.enterPrice',
                  })}
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Divider className="mt-2 mb-4" />
        <List<[string, number]>
          dataSource={
            isEmpty(additionalHours)
              ? ([['1Hours', 0]] as Array<[string, number]>)
              : Object.entries(additionalHours || {})
          }
          renderItem={([key, price]) => {
            const hourNumber = key.replace(/[a-z]/gi, '');
            const isLastKey =
              +hourNumber === Object.keys(additionalHours || {}).length + 1;
            return (
              <>
                {!isEmpty(additionalHours) && (
                  <List.Item key={hourNumber + 1} className="pt-0 border-0">
                    <List.Item.Meta
                      title={
                        <div className="font-medium uppercase text-2xs text-secondary">
                          {`Hour ${hourNumber}`}
                        </div>
                      }
                      description={
                        <div className="flex">
                          <InputNumber
                            min={0}
                            ref={isLastKey ? inputHourRef : null}
                            defaultValue={price}
                            className="flex-grow mr-2"
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value?: string) =>
                              numberFormatterBack((value || '0') as string)
                            }
                            onChange={(value) =>
                              onChangeHourlyPrice(Number(value), key)
                            }
                            placeholder={intl.formatMessage({
                              defaultMessage: 'Enter price',
                              id: 'common.message.enterPrice',
                            })}
                          />
                          {isLastKey ? (
                            <Button
                              type="icon"
                              icon={IconRemoveCircle}
                              className="ml-auto text-secondary"
                              onClick={() => onRemoveObjectKey(key)}
                            />
                          ) : (
                            <div className="w-8 h-8" />
                          )}
                        </div>
                      }
                    />
                  </List.Item>
                )}
                {Object.keys(additionalHours || []).length < 6 &&
                  (isLastKey || isEmpty(additionalHours)) && (
                    <List.Item
                      key={+hourNumber + 1}
                      className="pt-0 pb-4 border-0"
                    >
                      <List.Item.Meta
                        title={
                          <div className="font-medium uppercase opacity-50 text-2xs text-secondary">
                            <FormattedMessage
                              defaultMessage="Hour {hour}"
                              id="settings.label.hour"
                              values={{ hour: +hourNumber + 1 }}
                            />
                          </div>
                        }
                        description={
                          <div className="flex">
                            <InputNumber
                              min={0}
                              className="flex-grow mr-2"
                              disabled
                              placeholder={intl.formatMessage({
                                defaultMessage: 'Enter price',
                                id: 'common.message.enterPrice',
                              })}
                            />
                            <Button
                              type="icon"
                              icon={IconPlusCircle}
                              className="ml-auto text-secondary"
                              onClick={() => onAddNewHour()}
                            />
                          </div>
                        }
                      />
                    </List.Item>
                  )}
              </>
            );
          }}
        />
      </Form>
    </Modal>
  );
};

export default Form.create<EditHourlyServicesModalProps>({
  name: 'edit_hourly_service_form',
})(EditHourlyServicesModal);
