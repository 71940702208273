import React, { useState } from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Modal, Select } from '@aha/ui';
import { Dispatch } from 'redux';
import classnames from 'classnames';
import { FolioCreateRequest } from 'types/v3-schema';
import { useDispatch } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { addFolio } from 'containers/BookingProvider/actions';
import { Booking, BookingRoomDetail } from 'types/schema';

export type SubmitValues = {
  bookingRoomId: string;
};

export type AddFolioModalProps = {
  booking: Booking;
  visible?: boolean;
  onClose?: (...args: any[]) => any;
} & FormComponentProps;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doAddFolio: (bid: number, body: FolioCreateRequest) =>
    new Promise((resolve, reject) =>
      dispatch(addFolio(bid, body, resolve, reject)),
    ),
});

const AddFolioModal: React.SFC<AddFolioModalProps> = ({
  booking,
  visible,
  onClose,
  form,
}) => {
  const dispatch = useDispatch();
  const { doAddFolio } = mapDispatchToProps(dispatch);

  const [submitting, setSubmitting] = useState(false);
  const { getFieldDecorator, resetFields, validateFields } = form;
  const { id: bid, rooms, folios } = booking || {};

  const masterRoom: BookingRoomDetail = {
    id: -1,
    roomTypeName: 'Master Folio',
  };

  const validRooms = (rooms || []).filter(
    (r) => !['cancelled', 'checked_out'].includes(r.status as string),
  );
  const isExistMasterFolio = folios?.some(
    (f) => f.isMaster && f.status !== 'voided',
  );

  const roomList = isExistMasterFolio
    ? validRooms
    : [masterRoom, ...validRooms];

  function onCancel() {
    if (submitting) {
      return;
    }

    onClose && onClose();
    resetFields();
  }

  function onAdd() {
    validateFields(async (err, values: SubmitValues) => {
      if (err || !bid) {
        return;
      }

      try {
        setSubmitting(true);

        await doAddFolio(bid, values);
        onClose && onClose(true);
        resetFields();
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    });
  }

  return (
    <Modal
      visible={visible}
      title={
        <FormattedMessage
          defaultMessage="Add Folio"
          id="bookings.actions.addFolio"
        />
      }
      submitText={
        <FormattedMessage defaultMessage="Create" id="common.action.create" />
      }
      cancelText={
        <FormattedMessage defaultMessage="Cancel" id="common.action.cancel" />
      }
      submitting={submitting}
      onCancel={onCancel}
      onSubmit={onAdd}
    >
      <Form layout="vertical">
        <Form.Item
          label={
            <FormattedMessage
              defaultMessage="Room ID & Room"
              id="bookings.label.bookingRoomId"
            />
          }
        >
          {getFieldDecorator('bookingRoomId', {
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="common.message.required"
                    defaultMessage="Required"
                  />
                ),
              },
            ],
          })(
            <Select<string>
              showSearch
              placeholder={
                <FormattedMessage
                  defaultMessage="Select Room ID - Room"
                  id="bookings.label.selectRoomIDRoom"
                />
              }
              filterOption={(input, option) =>
                ((option?.props?.children as any)?.props?.children || '')
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {roomList.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  <span
                    className={classnames({
                      'text-red font-medium': !c.refCode,
                    })}
                  >
                    {[c.refCode, c.roomTypeName].filter(Boolean).join(' - ')}
                  </span>
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create<AddFolioModalProps>({
  name: 'add_folio_form',
})(AddFolioModal);
