import React from 'react';
import { Field } from 'react-final-form';
import Radio, { RadioGroupProps } from 'antd/lib/radio';

export interface FormRadioGroupProps extends RadioGroupProps {
  name: string;
  children?: React.ReactNode;
}

const FormRadioGroup = (props: FormRadioGroupProps) => {
  return (
    <Field
      {...props}
      component={({ input, children }) => (
        <Radio.Group {...input}>{children}</Radio.Group>
      )}
    />
  );
};

export default FormRadioGroup;
