import React, {
  useEffect,
  useState,
  useMemo,
  FormEvent,
  ReactText,
  useRef,
} from 'react';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { map, debounce } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { DatePicker, Form } from 'antd';
import GuestSelect, { GuestOption } from 'components/GuestSelect';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import { currencyFormater } from 'utils/currencyFormater';
import {
  makeSelectCurrencies,
  makeSelectCurrentHotel,
  ModelHotelWithFeatures,
} from 'containers/Dashboard/selectors';
import { BOOKING_KEY } from 'containers/BookingProvider/constants';
import reducer from 'containers/BookingProvider/reducer';
import saga from 'containers/BookingProvider/saga';
import {
  makeSelectChargeTemplates,
  makeSelectPaymentTypes,
} from 'containers/BookingProvider/selector';
import {
  fetchChargeTemplates,
  fetchPaymentTypes,
  createHourlyBooking,
} from 'containers/BookingProvider/actions';
import { Select, InputQuantity, Button } from '@aha/ui';
import InputNumberFormat from 'components/InputNumberFormat';
import { Dispatch } from 'redux';
import { FormComponentProps } from 'antd/lib/form';
import { ApplicationRootState } from 'types/app';
import { HourlybookingNewBookingForm } from 'types/v3-schema';
import { numberFormatterBack, showErrorNotification } from '@aha/utils';
import LMembershipBadge from 'components/LMembershipBadge';
import { VIP_MEMBER, MEMBER } from 'constants/membership';
import { MembershipType } from '@aha/ui/src/components/MembershipBadge';
import { coreAPI } from 'utils/request';
import { HHMMSS_DD_MMM_YYYY } from '@aha/constants';
import {
  IconClock,
  IconCoin,
  IconRemoveCircle,
  IconPlusCircle,
} from '@aha/icons';
import {
  HotelCurrency,
  Room,
  PaymentType,
  Guest,
  Warehousing,
} from 'types/schema';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    hotel: ModelHotelWithFeatures | null;
    chargeTemplates: Warehousing[];
    currencies: HotelCurrency[];
    paymentTypes: PaymentType[];
  }
>({
  hotel: makeSelectCurrentHotel(),
  chargeTemplates: makeSelectChargeTemplates(),
  currencies: makeSelectCurrencies(),
  paymentTypes: makeSelectPaymentTypes(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doFetchChargeTemplates: (hid: number) => dispatch(fetchChargeTemplates(hid)),
  doFetchPaymentTypes: (hid: number) => dispatch(fetchPaymentTypes(hid)),
  doCreateBooking: (hid: number, reqData: any, lockdoor: boolean) =>
    new Promise((resolve, reject) =>
      dispatch(createHourlyBooking(hid, reqData, resolve, reject, lockdoor)),
    ),
});

type AdditionalCharge = {
  id: null | string | undefined;
  quantity: number;
};

type HourlybookingNewBookingAndRoomForm = HourlybookingNewBookingForm & {
  room: Room;
};

export interface HourlyBookingFormProps extends FormComponentProps {
  room: Room;
  onClose: (f5?: boolean) => void;
}

function HourlyBookingForm(props: HourlyBookingFormProps) {
  useInjectReducer({ key: BOOKING_KEY, reducer });
  useInjectSaga({ key: BOOKING_KEY, saga });

  const dispatch = useDispatch();
  const {
    doFetchChargeTemplates,
    doFetchPaymentTypes,
    doCreateBooking,
  } = mapDispatchToProps(dispatch);

  const { hotel, chargeTemplates, currencies, paymentTypes } = useSelector(
    mapStateToProps,
  );

  const { form, room, onClose } = props;
  const { setFieldsValue, getFieldDecorator } = form;
  const intl = useIntl();

  const [prepaidAmount, setPrepaidAmount] = useState<number>(0);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [additionalCharges, setAdditionalCharges] = useState<
    AdditionalCharge[]
  >([]);
  const [booker, setBooker] = useState<Guest | null>(null);
  const [earnedCoins, setEarnedCoins] = useState<number>(0);
  const earnedCoinsDebounce = useRef(debounce(fetchCoinWillBeEarned, 700));

  async function fetchCoinWillBeEarned(priceRaw: number) {
    try {
      const { totalCoin } = await coreAPI.post(
        `v1/pms/memberships/earning-preview`,
        {
          isHourlyBooking: true,
          roomCharge: priceRaw,
          serviceCharge: 0,
        },
      );
      setEarnedCoins(totalCoin);
    } catch (e) {
      showErrorNotification(e);
      setEarnedCoins(0);
    }
  }

  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    if (hotel?.id) {
      doFetchChargeTemplates(hotel.id);
      doFetchPaymentTypes(hotel.id);
    }
  }, [hotel?.id]); // eslint-disable-line

  const { totalPrice, currency } = useMemo(() => {
    let rootCurrency = 'VND';
    // TODO: use ts-ignore due to changes in the swagger definitions after migration to v4 to save development time
    // @ts-ignore
    const { currency, price } = room?.roomType?.hourlySettings || {};

    const conversionRates: { [k: string]: number } = {};
    const currencyName = currency?.name as string;

    const roomCurrency = currencies?.find((c) => c.name === currency?.name);
    conversionRates[currencyName] = roomCurrency?.rate?.rate || 1;

    const roomPrice = conversionRates[currencyName] * (price || 0);
    if (conversionRates[currencyName] === 1) {
      rootCurrency = currency?.name || 'VND';
    }

    // TODO: must calc with same currency, for now just add all value
    const chargeValue = additionalCharges.reduce(
      (sum, { id: tplID, quantity }) => {
        const tpl = chargeTemplates?.find((c) => c.id === tplID);
        if (!tpl) {
          return sum;
        }

        const tplCurrencyName = tpl.currency as string;

        if (conversionRates[tplCurrencyName] === undefined) {
          const cu = currencies?.find((c) => c.name === tplCurrencyName);
          conversionRates[tplCurrencyName] = cu?.rate?.rate || 1;
          if (conversionRates[currencyName] === 1) {
            rootCurrency = currencyName || 'VND';
          }
        }
        return (
          sum + (tpl?.amount || 0) * quantity * conversionRates[tplCurrencyName]
        );
      },
      0,
    );

    return {
      totalPrice: chargeValue + roomPrice,
      currency: rootCurrency,
    };
  }, [additionalCharges, chargeTemplates, currencies, room]);

  function onCreateBooking(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setIsCreating(false);
          const reducedCharge = additionalCharges
            .filter((c) => c.id)
            .reduce((fin: { [k: string]: number }, c) => {
              if (c.id) {
                return {
                  ...fin,
                  [c.id]: (fin[c.id] || 0) + (+c.quantity || 0),
                };
              }

              return fin;
            }, {});

          const reqForm: HourlybookingNewBookingAndRoomForm = {
            room,
            guest: {
              firstName: values.guestName.trim(),
              fullName: values.guestName.trim(),
              passport: values.passport,
              id: booker?.id?.toString(),
              phone: values.phone,
            },
            charges: [
              { roomId: room.id, quantity: 1 },
              ...map(reducedCharge, (q, id) => ({
                warehousingId: id,
                quantity: q,
              })),
            ],
            checkinTime: moment(values.checkinTime).toISOString(),
          };

          if (+values.prepaidAmount) {
            reqForm.prepaid = {
              amount: +values.prepaidAmount,
              currencyId: +values.prepaidCurrency,
              paymentTypeId: Number(values.prepaidPaymentType?.split(';;;')[0]),
              paymentSubtypeId: Number(
                +values.prepaidPaymentType?.split(';;;')[1],
              ),
            };
          }

          if (hotel?.id) {
            await doCreateBooking(hotel.id, reqForm, hotel.isEnabledLockdoor);
            if (typeof onClose === 'function') {
              onClose(true);
            }
          }
        } catch (e) {
          console.error(e);
        } finally {
          setIsCreating(false);
        }
      }
    });
  }

  function onSelectGuest(
    fname: string,
    value: string,
    returnValue: GuestOption,
  ) {
    if (returnValue?.user) {
      setFieldsValue({
        guestName:
          returnValue.user?.fullName ||
          [returnValue.user?.firstName, returnValue.user?.lastName]
            .filter(Boolean)
            .join(' '),
        passport: returnValue.user?.passport,
        phone: returnValue.user?.phone,
      });

      setBooker(returnValue.user);
    }

    if (fname === 'firstName' && !value) {
      setBooker(null);
    }
  }

  function removeAdditionalChargeItem(idx: number) {
    setAdditionalCharges(additionalCharges.filter((v, adx) => adx !== idx));
  }

  const bkList = room?.bookings || [];

  function disabledDates(d?: moment.Moment) {
    const diffDate = moment(d).diff(moment(), 'day');
    if (diffDate < -1 || diffDate >= 1) {
      return true;
    }
    for (let i = 0; i < bkList.length || 0; i += 1) {
      const { arrival: arv, departure: dep } = bkList[i];
      if (moment(d).isBetween(arv, dep, undefined, '[)')) {
        return true;
      }
    }
    return false;
  }

  // Loyalty
  const { balance = 0, rank } = booker?.membership || {};

  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    if (rank === VIP_MEMBER) {
      earnedCoinsDebounce.current(totalPrice);
    }
  }, [rank, totalPrice]);

  return (
    <>
      <Form
        layout="vertical"
        onSubmit={onCreateBooking}
        className="p-6 bg-white"
      >
        <div className="flex flex-wrap -mx-2">
          <div className="col w-full px-2 md:w-1/2 lg:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Guest name"
                  id="common.label.guestFullName"
                />
              }
            >
              {getFieldDecorator('guestName', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                  {
                    whitespace: true,
                    message: (
                      <FormattedMessage
                        id="common.message.invalid"
                        defaultMessage="Invalid"
                      />
                    ),
                  },
                ],
              })(
                <GuestSelect
                  onResponse={onSelectGuest}
                  hotelID={hotel?.id}
                  searchBy="firstName"
                  placeholder={intl.formatMessage({
                    id: 'common.label.enterGuestName',
                    defaultMessage: 'Enter name',
                  })}
                />,
              )}
            </Form.Item>
          </div>
          <div className="col w-full px-2 md:w-1/2 lg:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Passport/ID"
                  id="common.label.passport"
                />
              }
            >
              {getFieldDecorator(
                'passport',
                {},
              )(
                <GuestSelect
                  onResponse={onSelectGuest}
                  hotelID={hotel?.id}
                  searchBy="passport"
                  placeholder={intl.formatMessage({
                    id: 'common.label.searchGuestByPassport',
                    defaultMessage: 'Enter passport or ID',
                  })}
                />,
              )}
            </Form.Item>
          </div>
          <div className="col w-full px-2 md:w-1/2 lg:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Phone number"
                  id="newBooking.phoneNumber"
                />
              }
            >
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
              })(
                <GuestSelect
                  onResponse={onSelectGuest}
                  hotelID={hotel?.id}
                  searchBy="phone"
                  placeholder={intl.formatMessage({
                    id: 'form.label.phone',
                    defaultMessage: 'Enter phone number',
                  })}
                />,
              )}
            </Form.Item>
          </div>
          {!!booker && (
            <div className="w-full px-2">
              <Form.Item>
                <LMembershipBadge type={rank as MembershipType} />
                {rank === VIP_MEMBER && (
                  <>
                    <span className="font-medium ml-2 mr-1">
                      <FormattedMessage
                        defaultMessage="Available xu:"
                        id="bookings.label.availableCoins"
                      />
                    </span>
                    {balance}
                    <span className="ml-1">
                      <FormattedMessage
                        defaultMessage="xu"
                        id="common.label.coins"
                      />
                    </span>
                  </>
                )}
              </Form.Item>
            </div>
          )}
        </div>

        <div className="flex flex-wrap -mx-2">
          <div className="col w-full px-2 lg:w-2/3">
            <Form.Item
              required
              label={
                <FormattedMessage
                  defaultMessage="Checkin time"
                  id="common.label.checkinTime"
                />
              }
            >
              {getFieldDecorator('checkinTime', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
                initialValue: moment(),
              })(
                <DatePicker
                  showTime
                  disabledDate={disabledDates}
                  format={HHMMSS_DD_MMM_YYYY}
                  className="w-full"
                  suffixIcon={<IconClock className="text-xs text-black" />}
                />,
              )}
            </Form.Item>
          </div>
        </div>
        <section id="add_prepaid">
          <div className="text-base text-grey-darker font-medium mt-2 mb-4">
            <FormattedMessage
              defaultMessage="Prepaid"
              id="common.label.prepaid"
            />
          </div>
          <div className="flex flex-wrap -mx-2">
            <div className="col w-full px-2 lg:w-1/3">
              <Form.Item
                label={
                  <FormattedMessage
                    defaultMessage="Prepaid amount"
                    id="common.label.prepaidAnount"
                  />
                }
              >
                {getFieldDecorator('prepaidAmount')(
                  <InputNumberFormat
                    placeholder={intl.formatMessage({
                      id: 'bookings.label.enterPrepaidAmount',
                      defaultMessage: 'Enter prepaid amount',
                    })}
                    min={0}
                    className="w-full"
                    formatter={(v?: ReactText) =>
                      `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={numberFormatterBack}
                    onChange={(v: number) => setPrepaidAmount(v)}
                  />,
                )}
              </Form.Item>
            </div>
            <div className="col w-full px-2 md:w-1/2 lg:w-1/3">
              <Form.Item
                required={!!prepaidAmount}
                label={
                  <FormattedMessage
                    defaultMessage="Currency"
                    id="common.label.currency"
                  />
                }
              >
                {getFieldDecorator('prepaidCurrency', {
                  rules: [
                    {
                      required: !!prepaidAmount,
                      message: 'Please select currency',
                    },
                  ],
                  initialValue: currencies?.find((c) => c.name === 'VND')?.id,
                })(
                  <Select showSearch>
                    {currencies.map((c) => (
                      <Select.Option key={c?.id} value={c?.id}>
                        {c.name}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </div>
            <div className="col w-full px-2 md:w-1/2 lg:w-1/3">
              <Form.Item
                required={!!prepaidAmount}
                label={
                  <FormattedMessage
                    defaultMessage="Payment type"
                    id="common.label.paymentType"
                  />
                }
              >
                {getFieldDecorator('prepaidPaymentType', {
                  rules: [
                    {
                      required: !!prepaidAmount,
                      message: 'Please select payment type',
                    },
                  ],
                })(
                  <Select
                    showSearch
                    placeholder={
                      <FormattedMessage
                        defaultMessage="Select payment type"
                        id="bookings.label.selectPaymentType"
                      />
                    }
                  >
                    {paymentTypes.map((pt) => (
                      <Select.OptGroup key={pt.id} label={pt.name}>
                        {(pt.subTypes || []).map((p) => (
                          <Select.Option
                            key={p.id}
                            value={`${pt.id};;;${p.id}`}
                            title={`${pt.name} - ${p.name}`}
                          >
                            {p.name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </div>
          </div>
        </section>

        <section id="add_charge">
          <div className="text-base text-grey-darker font-medium mt-2 mb-4">
            <FormattedMessage
              defaultMessage="Additional charges"
              id="bookings.label.additional_charges"
            />
          </div>

          <div>
            {additionalCharges.map((c, idx) => (
              <div className="flex flex-no-wrap -mx-2 mb-3">
                <div className="px-2 w-full lg:w-2/3 flex flex-no-wrap flex-1 lg:flex-none">
                  <div className="flex-1 pr-2">
                    <Select
                      showSearch
                      className="w-full"
                      placeholder={
                        <FormattedMessage
                          defaultMessage="Select charge"
                          id="bookings.label.select_charge"
                        />
                      }
                      value={c.id}
                      onChange={(k, v) =>
                        setAdditionalCharges(
                          additionalCharges.map((ch, cidx) =>
                            cidx === idx ? { ...ch, id: k } : ch,
                          ),
                        )
                      }
                    >
                      {chargeTemplates.map((ct) => (
                        <Select.Option key={ct.id}>{`${
                          ct.name
                        } - ${currencyFormater(
                          ct.amount,
                          ct.currency,
                        )}`}</Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div className="w-40 pl-2">
                    <InputQuantity
                      value={c.quantity}
                      onClickSuffix={() =>
                        setAdditionalCharges(
                          additionalCharges.map((ch, cidx) =>
                            cidx === idx
                              ? { ...ch, quantity: ch.quantity + 1 }
                              : ch,
                          ),
                        )
                      }
                      onClickPrefix={() =>
                        setAdditionalCharges(
                          additionalCharges.map((ch, cidx) =>
                            cidx === idx
                              ? {
                                  ...ch,
                                  quantity:
                                    ch.quantity > 1
                                      ? ch.quantity - 1
                                      : ch.quantity,
                                }
                              : ch,
                          ),
                        )
                      }
                      onChange={(k) =>
                        setAdditionalCharges(
                          additionalCharges.map((ch, cidx) =>
                            cidx === idx && k > 0 ? { ...ch, quantity: k } : ch,
                          ),
                        )
                      }
                    />
                  </div>
                </div>

                <div className="px-2 w-10 lg:w-1/3 flex items-center">
                  <Button
                    type="icon"
                    size="small"
                    icon={IconRemoveCircle}
                    className="text-lg"
                    onClick={() => removeAdditionalChargeItem(idx)}
                  />
                </div>
              </div>
            ))}

            <div className="flex flex-no-wrap -mx-2">
              <div className="px-2 w-full lg:w-2/3 flex flex-no-wrap flex-1 lg:flex-none">
                <div className="flex-1 pr-2">
                  <Select
                    className="w-full opacity-25"
                    disabled
                    placeholder={
                      <FormattedMessage
                        defaultMessage="Select charge"
                        id="bookings.label.select_charge"
                      />
                    }
                  />
                </div>
                <div className="w-40 pl-2 opacity-25">
                  <InputQuantity disabled />
                </div>
              </div>
              <div className="px-2 w-10 lg:w-1/3 flex items-center">
                <Button
                  type="icon"
                  size="small"
                  icon={IconPlusCircle}
                  className="text-lg text-primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setAdditionalCharges([
                      ...additionalCharges,
                      { id: undefined, quantity: 1 },
                    ]);
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </Form>

      <div className="mt-4 bg-white p-6 py-4">
        <div className="flex justify-end items-center text-right">
          <div className="leading-none">
            <div>
              <span className="font-medium text-base">
                <FormattedMessage
                  defaultMessage="Total Charges:"
                  id="common.label.totalCharge"
                />
              </span>
              <span className="ml-2 text-sm">
                {currencyFormater(totalPrice, currency)}
              </span>
            </div>
            {!!booker && (
              <div
                className="inline-flex items-center mt-2"
                style={{ height: 26 }}
              >
                {rank === VIP_MEMBER ? (
                  <>
                    <IconCoin className="text-xl mr-1" />
                    <span className="font-medium">
                      <FormattedMessage
                        defaultMessage="Xu will be earned:"
                        id="bookings.label.CoinsWillBeEarned"
                      />
                    </span>
                    <span className="ml-2 mr-1">{earnedCoins || 0}</span>
                    <FormattedMessage
                      defaultMessage="xu"
                      id="common.label.coins"
                    />
                  </>
                ) : rank === MEMBER ? (
                  <span className="italic">
                    <FormattedMessage
                      defaultMessage="Non-VIP user can't earn xu"
                      id="bookings.label.cannotEarnCoins"
                    />
                  </span>
                ) : (
                  <span className="italic">
                    <FormattedMessage
                      defaultMessage="Membership is not applicable"
                      id="bookings.label.membershipNotApplicable"
                    />
                  </span>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-between pt-3 mt-3 border-t">
          <Button
            type="line"
            color="primary"
            size="large"
            className="mr-2"
            onClick={() => onClose(false)}
          >
            <FormattedMessage
              defaultMessage="Cancel"
              id="common.action.cancel"
            />
          </Button>

          <Button
            className="px-10"
            size="large"
            onClick={() => onCreateBooking()}
            disabled={isCreating}
          >
            <FormattedMessage
              defaultMessage="Create"
              id="common.label.create"
            />
          </Button>
        </div>
      </div>
    </>
  );
}

export default Form.create<HourlyBookingFormProps>({
  name: 'create_hourly_booking_form',
})(HourlyBookingForm);
