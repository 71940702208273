import { intl } from './IntlGlobal';
import { MessageDescriptor } from 'react-intl';

export function translate(
  name: string | undefined,
  opts: Record<string, MessageDescriptor>,
): React.ReactNode {
  if (!name) {
    return '';
  }

  try {
    const t = Object.values(opts).find(
      (v) => (v.defaultMessage || '').toLowerCase() === name.toLowerCase(),
    );
    return t ? intl.formatMessage({ ...t }) : name;
  } catch (e) {}

  return name;
}

export default translate;
