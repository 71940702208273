export const BOOKING_KEY = 'bookings';

export const FETCH_BOOKING = 'booking/FETCH_BOOKING';
export const FETCH_BOOKING_SUCCSESS = 'booking/FETCH_BOOKING_SUCCSESS';
export const FETCH_BOOKING_FAIL = 'booking/FETCH_BOOKING_FAIL';

export const FETCH_EXPECTING_BOOKING_LIST =
  'bookings/FETCH_EXPECTING_BOOKING_LIST';
export const FETCH_EXPECTING_BOOKING_LIST_SUCCESS =
  'bookings/FETCH_EXPECTING_BOOKING_LIST_SUCCESS';
export const FETCH_EXPECTING_BOOKING_LIST_FAIL =
  'bookings/FETCH_EXPECTING_BOOKING_LIST_FAIL';

export const FETCH_INHOUSE_GUEST_LIST = 'bookings/FETCH_INHOUSE_GUEST_LIST';
export const FETCH_INHOUSE_GUEST_LIST_SUCCESS =
  'bookings/FETCH_INHOUSE_GUEST_LIST_SUCCESS';
export const FETCH_INHOUSE_GUEST_LIST_FAIL =
  'bookings/FETCH_INHOUSE_GUEST_LIST_FAIL';

export const FETCH_CHECKED_IN_BOOKINGS = 'bookings/FETCH_CHECKED_IN_BOOKINGS';
export const FETCH_CHECKED_IN_BOOKINGS_SUCCESS =
  'bookings/FETCH_CHECKED_IN_BOOKINGS_SUCCESS';
export const FETCH_CHECKED_IN_BOOKINGS_FAIL =
  'bookings/FETCH_CHECKED_IN_BOOKINGS_FAIL';

export const FETCH_CHECKED_OUT_BOOKINGS = 'bookings/FETCH_CHECKED_OUT_BOOKINGS';
export const FETCH_CHECKED_OUT_BOOKINGS_SUCCESS =
  'bookings/FETCH_CHECKED_OUT_BOOKINGS_SUCCESS';
export const FETCH_CHECKED_OUT_BOOKINGS_FAIL =
  'bookings/FETCH_CHECKED_OUT_BOOKINGS_FAIL';

export const FETCH_AVAILABLE_ROOMS = 'bookings/FETCH_AVAILABLE_ROOMS';
export const FETCH_AVAILABLE_ROOMS_SUCCESS =
  'bookings/FETCH_AVAILABLE_ROOMS_SUCCESS';
export const FETCH_AVAILABLE_ROOMS_FAIL = 'bookings/FETCH_AVAILABLE_ROOMS_FAIL';
export const RESET_AVAILABLE_ROOMS = 'bookings/RESET_AVAILABLE_ROOMS';

export const FETCH_ARRIVAL_BOOKINGS = 'bookings/FETCH_ARRIVAL_BOOKINGS';
export const FETCH_ARRIVAL_BOOKINGS_SUCCESS =
  'bookings/FETCH_ARRIVAL_BOOKINGS_SUCCESS';
export const FETCH_ARRIVAL_BOOKINGS_FAIL =
  'bookings/FETCH_ARRIVAL_BOOKINGS_FAIL';

export const FETCH_DEPARTURE_BOOKINGS = 'bookings/FETCH_DEPARTURE_BOOKINGS';
export const FETCH_DEPARTURE_BOOKINGS_SUCCESS =
  'bookings/FETCH_DEPARTURE_BOOKINGS_SUCCESS';
export const FETCH_DEPARTURE_BOOKINGS_FAIL =
  'bookings/FETCH_DEPARTURE_BOOKINGS_FAIL';

export const CHECK_IN_BOOKING = 'bookings/CHECK_IN_BOOKING';
export const CHECK_IN_BOOKING_SUCCESS = 'bookings/CHECK_IN_BOOKING_SUCCESS';
export const CHECK_IN_BOOKING_FAIL = 'bookings/CHECK_IN_BOOKING_FAIL';

export const CHECK_OUT_BOOKING = 'bookings/CHECK_OUT_BOOKING';
export const CHECK_OUT_BOOKING_SUCCESS = 'bookings/CHECK_OUT_BOOKING_SUCCESS';
export const CHECK_OUT_BOOKING_FAIL = 'bookings/CHECK_OUT_BOOKING_FAIL';

export const UNDO_ACTION_ON_BOOKING = 'bookings/UNDO_ACTION_ON_BOOKING';
export const UNDO_ACTION_ON_BOOKING_SUCCESS =
  'bookings/UNDO_ACTION_ON_BOOKING_SUCCESS';
export const UNDO_ACTION_ON_BOOKING_FAIL =
  'bookings/UNDO_ACTION_ON_BOOKING_FAIL';

export const CANCEL_BOOKING = 'bookings/CANCEL_BOOKING';
export const CANCEL_BOOKING_SUCCESS = 'bookings/CANCEL_BOOKING_SUCCESS';
export const CANCEL_BOOKING_FAIL = 'bookings/CANCEL_BOOKING_FAIL';

export const NO_SHOW_BOOKING = 'bookings/NO_SHOW_BOOKING';
export const NO_SHOW_BOOKING_SUCCESS = 'bookings/NO_SHOW_BOOKING_SUCCESS';
export const NO_SHOW_BOOKING_FAIL = 'bookings/NO_SHOW_BOOKING_FAIL';

export const VOID_CHARGES_OF_BOOKING = 'bookings/VOID_CHARGES_OF_BOOKING';
export const VOID_CHARGES_OF_BOOKING_SUCCESS =
  'bookings/VOID_CHARGES_OF_BOOKING_SUCCESS';
export const VOID_CHARGES_OF_BOOKING_FAIL =
  'bookings/VOID_CHARGES_OF_BOOKING_FAIL';

export const ADD_FOLIO = 'bookings/ADD_FOLIO';
export const ADD_FOLIO_SUCCESS = 'bookings/ADD_FOLIO_SUCCESS';
export const ADD_FOLIO_FAIL = 'bookings/ADD_FOLIO_FAIL';

export const VOID_FOLIO = 'bookings/VOID_FOLIO';
export const VOID_FOLIO_SUCCESS = 'bookings/VOID_FOLIO_SUCCESS';
export const VOID_FOLIO_FAIL = 'bookings/VOID_FOLIO_FAIL';

export const ADD_FOLIO_CHARGE = 'bookings/ADD_FOLIO_CHARGE';
export const ADD_FOLIO_CHARGE_SUCCESS = 'bookings/ADD_FOLIO_CHARGE_SUCCESS';
export const ADD_FOLIO_CHARGE_FAIL = 'bookings/ADD_FOLIO_CHARGE_FAIL';

export const VOID_FOLIO_CHARGES = 'bookings/VOID_FOLIO_CHARGES';
export const VOID_FOLIO_CHARGES_SUCCESS = 'bookings/VOID_FOLIO_CHARGES_SUCCESS';
export const VOID_FOLIO_CHARGES_FAIL = 'bookings/VOID_FOLIO_CHARGES_FAIL';

export const UPDARE_FOLIO_CHARGE = 'bookings/UPDARE_FOLIO_CHARGE';
export const UPDARE_FOLIO_CHARGE_SUCCESS =
  'bookings/UPDARE_FOLIO_CHARGE_SUCCESS';
export const UPDARE_FOLIO_CHARGE_FAIL = 'bookings/UPDARE_FOLIO_CHARGE_FAIL';

export const ADD_FOLIO_PAYMENT = 'bookings/ADD_FOLIO_PAYMENT';
export const ADD_FOLIO_PAYMENT_SUCCESS = 'bookings/ADD_FOLIO_PAYMENT_SUCCESS';
export const ADD_FOLIO_PAYMENT_FAIL = 'bookings/ADD_FOLIO_PAYMENT_FAIL';

export const VOID_FOLIO_PAYMENT = 'bookings/VOID_FOLIO_PAYMENT';
export const VOID_FOLIO_PAYMENT_SUCCESS = 'bookings/VOID_FOLIO_PAYMENT_SUCCESS';
export const VOID_FOLIO_PAYMENT_FAIL = 'bookings/VOID_FOLIO_PAYMENT_FAIL';

export const UPDATE_FOLIO_PAYMENT = 'bookings/UPDATE_FOLIO_PAYMENT';
export const UPDATE_FOLIO_PAYMENT_SUCCESS =
  'bookings/UPDATE_FOLIO_PAYMENT_SUCCESS';
export const UPDATE_FOLIO_PAYMENT_FAIL = 'bookings/UPDATE_FOLIO_PAYMENT_FAIL';

export const ALLOCATE_ROOM_FOR_BOOKING = 'bookings/ALLOCATE_ROOM_FOR_BOOKING';
export const ALLOCATE_ROOM_FOR_BOOKING_SUCCESS =
  'bookings/ALLOCATE_ROOM_FOR_BOOKING_SUCCESS';
export const ALLOCATE_ROOM_FOR_BOOKING_FAIL =
  'bookings/ALLOCATE_ROOM_FOR_BOOKING_FAIL';

export const ALLOCATE_ROOM_FOR_HOURLY_BOOKING =
  'bookings/ALLOCATE_ROOM_FOR_HOURLY_BOOKING';
export const ALLOCATE_ROOM_FOR_HOURLY_BOOKING_SUCCESS =
  'bookings/ALLOCATE_ROOM_FOR__HOURLY_BOOKING_SUCCESS';
export const ALLOCATE_ROOM_FOR_HOURLY_BOOKING_FAIL =
  'bookings/ALLOCATE_ROOM_FOR_HOURLY_BOOKING_FAIL';

export const FETCH_FOLIO_DETAIL = 'bookings/FETCH_FOLIO_DETAI';
export const FETCH_FOLIO_DETAIL_SUCCESS = 'bookings/FETCH_FOLIO_DETAIL_SUCCESS';
export const FETCH_FOLIO_DETAIL_FAIL = 'bookings/FETCH_FOLIO_DETAIL_FAIL';

export const FETCH_PAYMENT_TYPES = 'bookings/FETCH_PAYMENT_TYPES';
export const FETCH_PAYMENT_TYPES_SUCCESS =
  'bookings/FETCH_PAYMENT_TYPES_SUCCESS';
export const FETCH_PAYMENT_TYPES_FAIL = 'bookings/FETCH_PAYMENT_TYPES_FAIL';

export const FETCH_CHARGE_TEMPLATES = 'bookings/FETCH_CHARGE_TEMPLATES';
export const FETCH_CHARGE_TEMPLATES_SUCCESS =
  'bookings/FETCH_CHARGE_TEMPLATES_SUCCESS';
export const FETCH_CHARGE_TEMPLATES_FAIL =
  'bookings/FETCH_CHARGE_TEMPLATES_FAIL';

export const FETCH_REVENUE_GROUPS = 'bookings/FETCH_REVENUE_GROUPS';
export const FETCH_REVENUE_GROUPS_SUCCESS =
  'bookings/FETCH_REVENUE_GROUPS_SUCCESS';
export const FETCH_REVENUE_GROUPS_FAIL = 'bookings/FETCH_REVENUE_GROUPS_FAIL';

export const UPDATE_BOOKING_PERIOD = 'bookings/UPDATE_BOOKING_PERIOD';
export const UPDATE_BOOKING_PERIOD_SUCCESS =
  'bookings/UPDATE_BOOKING_PERIOD_SUCCESS';
export const UPDATE_BOOKING_PERIOD_FAIL = 'bookings/UPDATE_BOOKING_PERIOD_FAIL';

export const FETCH_BOOKING_HISTORY = 'bookings/FETCH_BOOKING_HISTORY';
export const FETCH_BOOKING_HISTORY_SUCCESS =
  'bookings/FETCH_BOOKING_HISTORY_SUCCESS';
export const FETCH_BOOKING_HISTORY_FAIL = 'bookings/FETCH_BOOKING_HISTORY_FAIL';

export const CREATE_HOURLY_BOOKING = 'bookings/CREATE_HOURLY_BOOKING';
export const CREATE_HOURLY_BOOKING_SUCCESS =
  'bookings/CREATE_HOURLY_BOOKING_SUCCESS';
export const CREATE_HOURLY_BOOKING_FAIL = 'bookings/CREATE_HOURLY_BOOKING_FAIL';

export const UPDATE_BOOKING_INFO = 'bookings/UPDATE_BOOKING_INFO';
export const UPDATE_BOOKING_INFO_SUCCESS =
  'bookings/UPDATE_BOOKING_INFO_SUCCESS';
export const UPDATE_BOOKING_INFO_FAIL = 'bookings/UPDATE_BOOKING_INFO_FAIL';

export const REMOVE_ROOM_FROM_BOOKING = 'bookings/REMOVE_ROOM_FROM_BOOKING';
export const REMOVE_ROOM_FROM_BOOKING_SUCCESS =
  'bookings/REMOVE_ROOM_FROM_BOOKING_SUCCESS';
export const REMOVE_ROOM_FROM_BOOKING_FAIL =
  'bookings/REMOVE_ROOM_FROM_BOOKING_FAIL';

export const FETCH_COMPANIES = 'bookings/FETCH_COMPANIES';
export const FETCH_COMPANIES_SUCCESS = 'bookings/FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAIL = 'bookings/FETCH_COMPANIES_FAIL';

export const UNDO_CHECKIN_BOOKING = 'bookings/UNDO_CHECKIN_BOOKING';
export const UNDO_CHECKIN_BOOKING_SUCCESS =
  'bookings/UNDO_CHECKIN_BOOKING_SUCCESS';
export const UNDO_CHECKIN_BOOKING_FAIL = 'bookings/UNDO_CHECKIN_BOOKING_FAIL';

export const ASSIGN_GUEST_BOOKING = 'bookings/ASSIGN_GUEST_BOOKING';
export const ASSIGN_GUEST_BOOKING_SUCCESS =
  'bookings/ASSIGN_GUEST_BOOKING_SUCCESS';

export const UNASSIGN_GUEST_BOOKING = 'bookings/UNASSIGN_GUEST_BOOKING';
export const UNASSIGN_GUEST_BOOKING_SUCCESS =
  'bookings/UNASSIGN_GUEST_BOOKING_SUCCESS';

export const TRANSFER_CHARGES_BOOKING = 'bookings/TRANSFER_CHARGES_BOOKING';
export const TRANSFER_CHARGES_BOOKING_SUCCESS =
  'bookings/TRANSFER_CHARGES_BOOKING_SUCCESS';

export const TRANSFER_FOLIO_BOOKING = 'bookings/TRANSFER_FOLIO_BOOKING';
export const TRANSFER_FOLIO_BOOKING_SUCCESS =
  'bookings/TRANSFER_FOLIO_BOOKING_SUCCESS';

export const SET_CURRENT_TAB = 'bookings/SET_CURRENT_TAB';

export const ADD_GUEST_TO_BOOKING = 'bookings/ADD_GUEST_TO_BOOKING';
export const ADD_GUEST_TO_BOOKING_SUCCESS =
  'bookings/ADD_GUEST_TO_BOOKING_SUCCESS';

export const EDIT_BOOKING_ROOM_TYPE = 'bookings/EDIT_BOOKING_ROOM_TYPE';
export const VOID_MULTIPLE_FOLIO_CHARGES =
  'bookings/VOID_MULTIPLE_FOLIO_CHARGES';
export const CANCEL_A_CHILD_BOOKING = 'bookings/CANCEL_A_CHILD_BOOKING';
export const ADD_CHILD_BOOKING = 'bookings/ADD_CHILD_BOOKING';
export const CREATE_COMPANY_SUCCESS = 'bookings/CREATE_COMPANY_SUCCESS';

export const GROUP_FOLIOS = 'bookings/GROUP_FOLIOS';
export const GROUP_FOLIOS_SUCCESS = 'bookings/GROUP_FOLIOS_SUCCESS';

export const SEPARATE_FOLIOS = 'bookings/SEPARATE_FOLIOS';
export const SEPARATE_FOLIOS_SUCCESS = 'bookings/SEPARATE_FOLIOS_SUCCESS';

export const FETCH_EXPIRED_BOOKINGS = 'bookings/FETCH_EXPIRED_BOOKINGS';
export const FETCH_EXPIRED_BOOKINGS_SUCCESS =
  'bookings/FETCH_EXPIRED_BOOKINGS_SUCCESS';
