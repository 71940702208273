import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeSelectPermission } from 'containers/Auth/selectors';
import { createSelector } from 'reselect';
import useSelectCurrentHotel from 'containers/Dashboard/useSelectCurrentHotel';

const mapStateToProps = createSelector(
  makeSelectPermission(),
  (permissions) => ({
    permissions,
  }),
);

const usePermission = (allow) => {
  const permissions = useSelectPermissions();
  const isAllowed = useMemo(() => (permissions || []).includes(allow), [allow]); // eslint-disable-line
  return isAllowed;
};

export const useSelectPermissions = () => {
  const { permissions } = useSelector(mapStateToProps);
  const hotel = useSelectCurrentHotel();
  const permission = (permissions || [])?.filter(
    (p) => p.hotelId === hotel?.id,
  )[0];

  return permission?.permissions || [];
};

export default usePermission;
