import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { createStructuredSelector } from 'reselect';
import { Input } from 'antd';
import moment from 'moment';
import { useMedia } from '@aha/utils';
import loGroupBy from 'lodash/groupBy';
import { navigate, WindowLocation } from '@reach/router';
import saga from './saga';
import reducer from './reducer';
import {
  makeSelectRooms,
  makeSelectLoading,
  makeSelectLoadingRefresh,
  makeSelectIsRefreshRoom,
} from './selector';
import {
  fetchRooms,
  fetchRefreshRooms,
  setActionsRefreshRooms,
} from './actions';

import CreateBookingDrawer from './components/CreateBookingDrawer';
import { PermissionGate } from 'components/permission';
import { FormattedMessage, useIntl } from 'react-intl';
import LockDoorAPI from '../BookingProvider/loockdoor';
import RoomBlock from 'components/RoomBlock';
import { Button, Card, Select, NoDataBlock, Drawer, PageHeader } from '@aha/ui';
import Exception from 'components/Exception';
import BookingDetailPage from '../BookingDetailPage';
import { RoomStatuses } from './components/RoomStatuses';
import HourlyBookingView from '../HourlyBooking';
import RoomPageSkeleton from 'components/skeleton/pages/RoomPageSkeleton';
import styled from 'styled-components';
import tw from 'twin.macro';
import ROUTES from 'constants/routes';
import EmptyRoom from 'img/EmptyRoom.png';
import { makeSelectHourlyBooking } from 'containers/HourlyBooking/selector';
import usePermission from 'components/permission/usePermission';
import queryString from 'querystring';
import { Dispatch } from 'redux';
import { ApplicationRootState } from 'types/app';
import useSelectCurrentHotel from 'containers/Dashboard/useSelectCurrentHotel';
import { IconSearch } from '@aha/icons';
import { Room, HourlyBooking, Booking } from 'types/schema';
import { RoomStatus } from 'utils/constants';

export const RoomBlockContainer = styled.div`
  ${tw`flex flex-wrap p-4`};

  > div {
    ${tw`w-1/2 p-2 sm:w-1/3 lg:w-1/4 xl:w-1/6`};
  }

  @media screen and (min-width: 1600px) {
    > div {
      width: 14.25%;
    }
  }

  @media screen and (min-width: 1800px) {
    > div {
      width: 12.5%;
    }
  }

  @media screen and (min-width: 2000px) {
    > div {
      width: 10%;
    }
  }

  @media screen and (min-width: 2200px) {
    > div {
      width: 192px;
    }
  }
`;

const InputStyled = styled(Input)`
  .ant-input {
    border: none !important;
  }
`;

function filterAndGroupRooms(
  rooms: Room[] | null = [],
  groupBy: string,
  filter: string,
) {
  let filteredRooms = rooms || [];
  if (filter) {
    filteredRooms = filteredRooms.filter((r) =>
      (r?.title || '').toLowerCase().includes(filter.toLowerCase()),
    );
  }
  if (groupBy === 'all') {
    return { All: filteredRooms };
  }
  if (groupBy === 'roomType.name') {
    return loGroupBy(filteredRooms, 'roomType.name');
  }
  if (groupBy === 'floorZone') {
    return loGroupBy(filteredRooms, 'floorZone');
  }
  return loGroupBy(filteredRooms, groupBy);
}

const getHourlyBooking = (r?: Room) => r?.hourlyBookings?.[0];

const getDailyBooking = (r?: Room) => {
  return r?.bookings?.find((bk) => {
    if (
      ['checked_in', 'checking_in', 'pending_checkout', 'awaiting'].includes(
        bk.status || '',
      )
    ) {
      return !!bk.rooms?.find(
        (br) => br.status === 'checked_in' && r.id === br.roomId,
      );
    }
    return false;
  });
};

const totalBookingRoomTypes = (rooms: Room[]) =>
  rooms?.reduce(
    (res, r) => {
      if (!!getHourlyBooking(r)) {
        return { ...res, totalHourly: res.totalHourly + 1 };
      }

      if (!!getDailyBooking(r)) {
        return { ...res, totalDaily: res.totalDaily + 1 };
      }

      return { ...res, totalFree: res.totalFree + 1 };
    },
    { totalDaily: 0, totalHourly: 0, totalFree: 0 },
  ) || {};

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    loading: boolean;
    loadingRefresh: boolean;
    isRefreshRoom: boolean;
    rooms: Room[] | null;
    hourlyBooking: HourlyBooking;
  }
>({
  loading: makeSelectLoading(),
  loadingRefresh: makeSelectLoadingRefresh(),
  isRefreshRoom: makeSelectIsRefreshRoom(),
  rooms: makeSelectRooms(),
  hourlyBooking: makeSelectHourlyBooking(),
});

const mapDispathToProps = (dispatch: Dispatch) => ({
  doFetchRooms: () => dispatch(fetchRooms()),
  doFetchRefreshRooms: () => dispatch(fetchRefreshRooms()),
  setActionsRefreshRooms: (isBool: boolean) =>
    dispatch(setActionsRefreshRooms(isBool)),
});

interface RoomPlanPageProps {
  location: Partial<WindowLocation> & Pick<WindowLocation, 'search'>;
}
export function RoomPlanPage({ location }: RoomPlanPageProps) {
  useInjectReducer({ key: 'roomPlan', reducer });
  useInjectSaga({ key: 'roomPlan', saga });

  const dispatch = useDispatch();
  const {
    loading,
    loadingRefresh,
    isRefreshRoom,
    rooms,
    hourlyBooking,
  } = useSelector(mapStateToProps);

  const {
    doFetchRooms,
    doFetchRefreshRooms,
    setActionsRefreshRooms,
  } = mapDispathToProps(dispatch);

  const { roomID: rawRoomID } = queryString.parse(
    location.search.replace('?', ''),
  );
  const roomID = Number(rawRoomID);
  const [groupBy, setGroupBy] = useState('all'); // FloorZone, BuildingZone, RoomType.ID, all
  const [nameFilter, setNameFilter] = useState<string>('');
  const [selectedRoomId, setSelectedRoomId] = useState<
    number | null | undefined
  >(null);
  const [showNewBookingDrawer, setShowNewBookingDrawer] = useState(false);
  const [showBookingDrawer, setShowBookingDrawer] = useState(false);
  const [showHourlyBookingDrawer, setShowHourlyBookingDrawer] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<
    HourlyBooking | Booking | null
  >(null);
  const [isReadingCard, setIsReadingCard] = useState(false);
  const isOnMobile = useMedia();
  const [currentTime, setCurrentTime] = useState(moment());
  const intl = useIntl();
  const hotel = useSelectCurrentHotel();

  const allowUpdateHousekeeping = usePermission('pms.rooms.updateHousekeeping');

  useEffect(() => {
    if (hotel?.id) {
      doFetchRooms();
    }
  }, [hotel?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hotel && isRefreshRoom) {
      setActionsRefreshRooms(false);
      doFetchRefreshRooms();
    }
  }, [isRefreshRoom]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hourlyBooking) {
      const roomCharge = (hourlyBooking.charges || []).find((c) => c.room?.id);
      if (
        roomCharge &&
        (selectedRoomId !== roomCharge.room?.id ||
          selectedRoomId !== roomCharge.room?.refCode)
      ) {
        setSelectedRoomId(roomCharge.room?.id);
      }
    }
  }, [hourlyBooking, selectedRoomId]);

  useEffect(() => {
    if (!roomID || !rooms) {
      return;
    }
    const room = rooms.find((r) => r.id === roomID);
    onShowDrawer(room, true);
  }, [roomID, rooms]); // eslint-disable-line

  // Trigger update
  const hasHourlyBooking = useMemo(() => {
    return !!(rooms || []).find((r) => (r.hourlyBookings?.length || 0) > 0);
  }, [rooms]);
  const intervalRef = useRef<number>();

  // TODO: should it have deps array?
  useEffect(() => {
    if (hasHourlyBooking) {
      const intervalID = setInterval(() => {
        setCurrentTime(moment());
      }, 60000);
      intervalRef.current = intervalID;
      return () => clearInterval(intervalRef.current);
    }
  }, [hasHourlyBooking]); // eslint-disable-line react-hooks/exhaustive-deps

  const cbOnShowDrawer = useCallback((r) => {
    onShowDrawer(r);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  function onShowDrawer(r?: Room, notNavigate: boolean = false) {
    const rID = r?.id || -1;
    const hourlyBk: HourlyBooking | undefined = getHourlyBooking(r);
    setSelectedRoomId(rID);

    if (hourlyBk) {
      setSelectedBooking(hourlyBk);
      setShowHourlyBookingDrawer(true);
      !notNavigate &&
        navigate(`${ROUTES.ROOMS}?roomID=${rID}`, {
          replace: true,
        });
      return;
    }

    const dailyBk = getDailyBooking(r);
    if (dailyBk) {
      setSelectedBooking(dailyBk);
      setShowBookingDrawer(true);
      !notNavigate &&
        navigate(`${ROUTES.ROOMS}?roomID=${rID}`, {
          replace: true,
        });
      return;
    }
    if (allowUpdateHousekeeping) {
      setShowNewBookingDrawer(true);
    }
  }

  function onCloseBookingDetailsDrawer() {
    if (showBookingDrawer) {
      setShowBookingDrawer(false);
    }
    if (showHourlyBookingDrawer) {
      setShowHourlyBookingDrawer(false);
    }
    if (showNewBookingDrawer) {
      setShowNewBookingDrawer(false);
    }

    navigate(ROUTES.ROOMS, { replace: true });
  }

  async function onRequestReadCard() {
    try {
      setIsReadingCard(true);
      const { roomNumber } = await LockDoorAPI.readCard();
      const room = rooms?.find((r) => r.lockDoorRoomNo === roomNumber);
      onShowDrawer(room);
    } catch (err) {
      console.error(err);
    } finally {
      setIsReadingCard(false);
    }
  }

  let data = useMemo(() => filterAndGroupRooms(rooms, groupBy, nameFilter), [
    groupBy,
    nameFilter,
    rooms,
  ]);

  if (!(Object.keys(data).length > 0)) {
    data = { nodata: [] };
  }
  const selectedRoom =
    rooms?.find(
      (r) => r.refCode === selectedRoomId || r.id === selectedRoomId,
    ) || {};

  let firstRender = null;
  if (loading || rooms === null) {
    firstRender = <RoomPageSkeleton />;
  } else if (!rooms?.length && rooms !== null) {
    firstRender = (
      <div style={{ marginTop: -68 }}>
        <Exception
          message={
            <FormattedMessage
              defaultMessage="Please create new rooms"
              id="common.label.pleaseCreateNewRooms"
            />
          }
          path={ROUTES.ROOM_TYPE_SETTING}
          titleButton={
            <FormattedMessage
              defaultMessage="Room Setting"
              id="common.label.roomSetting"
            />
          }
          image={EmptyRoom}
        />
      </div>
    );
  }

  const renderExtraHeaderContent = () => {
    return (
      <>
        <InputStyled
          suffix={<IconSearch />}
          placeholder={intl.formatMessage({
            defaultMessage: 'Search rooms',
            id: 'roomplan.label.searchRooms',
          })}
          className="w-full mb-2 border-none md:w-64 md:mb-0 md:mr-4"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value)}
        />
        <Select
          showSearch
          noBorder
          className="w-1/2 pr-4 md:w-64 md:mr-4 md:pr-0"
          placeholder={
            <FormattedMessage
              defaultMessage="Group by"
              id="roomplan.label.groupBy"
            />
          }
          value={groupBy}
          onChange={(v) => setGroupBy(v || 'all')}
        >
          <Select.Option key="all">
            <FormattedMessage
              defaultMessage="All rooms"
              id="roomplan.label.allRooms"
            />
          </Select.Option>
          <Select.Option key="roomType.name">
            <FormattedMessage
              defaultMessage="Room types"
              id="roomplan.label.groupByRoomtype"
            />
          </Select.Option>
          <Select.Option key="floorZone">
            <FormattedMessage
              defaultMessage="Floor"
              id="roomplan.label.groupByFloorZone"
            />
          </Select.Option>
        </Select>
        <PermissionGate
          allow="pms.bookings.checkin"
          fallback={
            <Button disabled className="w-1/2 md:w-auto" style={{ height: 36 }}>
              <FormattedMessage
                defaultMessage="Read card"
                id="lockdoor.actions.readCard"
              />
            </Button>
          }
        >
          <Button
            className="w-1/2 md:w-auto"
            disabled={!hotel.isEnabledLockdoor || isReadingCard}
            style={{ height: 36 }}
            onClick={onRequestReadCard}
          >
            <FormattedMessage
              defaultMessage="Read card"
              id="lockdoor.actions.readCard"
            />
          </Button>
        </PermissionGate>
      </>
    );
  };

  return (
    <>
      <PageHeader
        title={
          <FormattedMessage defaultMessage="rooms" id="common.label.rooms" />
        }
        extra={!rooms?.length ? null : renderExtraHeaderContent()}
        flexExtra={isOnMobile}
      />
      {firstRender
        ? firstRender
        : Object.entries(data || {}).map((item) => {
            const [rtName, roomList] = item;
            return (
              <Card
                noContentPadding
                key={`${rtName}-${roomList.length}`}
                className="mb-4"
                title={
                  rtName === 'nodata' ||
                  (rtName === 'All' && !roomList?.length) ? (
                    ''
                  ) : rtName === 'All' ? (
                    <FormattedMessage
                      defaultMessage="All"
                      id="common.label.all"
                    />
                  ) : (
                    rtName || (
                      <FormattedMessage
                        defaultMessage="Unknown"
                        id="common.label.unknown"
                      />
                    )
                  )
                }
                extra={
                  <FormattedMessage
                    defaultMessage="Room Count"
                    id="roomplan.message.roomCount"
                    values={{
                      count: roomList.length,
                      ...totalBookingRoomTypes(roomList),
                    }}
                  />
                }
              >
                <RoomBlockContainer
                  css={`
                    opacity: ${loadingRefresh ? 0.6 : 1};
                  `}
                >
                  {roomList.length > 0 ? (
                    (roomList || []).map((r) => {
                      const log = r?.roomAvailabilityLogs?.[0];
                      const housekeepingStatus = (log &&
                      moment().isBetween(
                        moment(log.unAvailableFrom),
                        moment(log.availableDate),
                        'minutes',
                        '[]',
                      )
                        ? 'out_of_order'
                        : r.housekeepingStatus) as RoomStatus;

                      return (
                        <div key={r.id}>
                          <RoomBlock
                            housekeepingStatus={housekeepingStatus}
                            room={r}
                            onClick={cbOnShowDrawer}
                            currentTime={
                              (r.hourlyBookings || []).length > 0
                                ? currentTime
                                : undefined
                            }
                          />
                        </div>
                      );
                    })
                  ) : (
                    <NoDataBlock className="py-10 m-auto" />
                  )}
                </RoomBlockContainer>
              </Card>
            );
          })}
      <CreateBookingDrawer
        enabledFeatures={hotel?.enabledFeatures || []}
        room={selectedRoom}
        visible={showNewBookingDrawer}
        width={isOnMobile ? '100%' : '75%'}
        placement="right"
        onClose={onCloseBookingDetailsDrawer}
        destroyOnClose
      />
      <Drawer
        width={isOnMobile ? '100%' : '75%'}
        destroyOnClose
        visible={showBookingDrawer}
        onClose={onCloseBookingDetailsDrawer}
      >
        <BookingDetailPage
          isDrawer
          bid={selectedBooking?.refCode}
          room={<RoomStatuses room={selectedRoom} />}
        />
      </Drawer>
      <Drawer
        width={isOnMobile ? '100%' : '75%'}
        destroyOnClose
        visible={showHourlyBookingDrawer}
        onClose={onCloseBookingDetailsDrawer}
      >
        <HourlyBookingView
          bid={
            hourlyBooking?.refCode ||
            selectedRoom?.hourlyBookings?.find(
              (bk) => bk.status === 'checked_in',
            )?.refCode ||
            selectedRoom?.hourlyBookings?.[0].refCode
          }
          room={<RoomStatuses room={selectedRoom} />}
        />
      </Drawer>
    </>
  );
}

export default RoomPlanPage;
