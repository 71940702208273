import {
  put,
  call,
  takeLatest,
  takeEvery,
  delay,
  all,
} from 'redux-saga/effects';
import ROUTES from 'constants/routes';
import { showErrorNotification, showSuccessNotification } from '@aha/utils';
import ActionTypes from './constants';
import {
  fetchHotelsFail,
  fetchHotelsSuccess,
  toggleHotelStatusSuccess,
  toggleHotelStatusFail,
  fetchCurrentHotelSuccess,
  fetchCurrentHotelFail,
  fetchHotelDetailsSuccess,
  fetchFeaturesSuccess,
  createHotelSuccess,
  editHotelSuccess,
  editHotelFail,
  addCmsSuccess,
  uploadImageSuccess,
  createHotelFail,
} from './actions';
import { coreAPI } from 'utils/request';
import { switchCurrentHotelSuccess } from 'containers/Dashboard/actions';
import { navigate } from '@reach/router';
import { ExtractAction } from 'types/actions';
import { HotelsActions } from './type';

export function* doFetchHotels(
  action: ExtractAction<HotelsActions, ActionTypes.FETCH_HOTELS>,
) {
  try {
    const { data } = yield call(coreAPI.get, 'v1/pms/hotels');
    yield put(fetchHotelsSuccess(data));
  } catch (err) {
    yield put(fetchHotelsFail(err));
  }
}

export function* updateHotelStatus(
  action: ExtractAction<HotelsActions, ActionTypes.UPDATE_HOTEL_STATUS>,
) {
  const { hotel } = action.payload;

  try {
    let resp = null;
    if (hotel.status === 'active') {
      resp = yield call(coreAPI.delete, `v1/operation/hotels/${hotel.id}`);
    } else {
      resp = yield call(
        coreAPI.post,
        `v1/operation/hotels/${hotel.id}/reactivate`,
      );
    }
    yield put(toggleHotelStatusSuccess(resp.Data));
  } catch (err) {
    yield put(toggleHotelStatusFail(hotel, err));
  }
}

export function* fetchCurrentHotel(
  action: ExtractAction<HotelsActions, ActionTypes.FETCH_CURRENT_HOTEL>,
) {
  const { hotelId } = action.payload;

  try {
    // const hotelId = window.localStorage.getItem('hotelId');
    const { data } = yield call(coreAPI.get, `v1/pms/hotels/${hotelId}`);
    yield put(fetchCurrentHotelSuccess(data));
  } catch (err) {
    // navigate('/hotels');
    yield put(fetchCurrentHotelFail(err));
  }
}

export function* watchFetchHotels() {
  yield takeLatest(ActionTypes.FETCH_HOTELS, doFetchHotels);
}

export function* watchUpdateHotelStatus() {
  yield takeEvery(ActionTypes.UPDATE_HOTEL_STATUS, updateHotelStatus);
}

export function* watchFetchCurrentHotel() {
  yield takeLatest(ActionTypes.FETCH_CURRENT_HOTEL, fetchCurrentHotel);
}

function* doCreateHotel(
  action: ExtractAction<HotelsActions, ActionTypes.CREATE_HOTEL>,
) {
  const { hotel } = action.payload;

  try {
    const { data } = yield call(coreAPI.post, 'v1/operation/hotels', hotel);
    yield put(createHotelSuccess(data));
    showSuccessNotification('The Hotel has been created successfully ');
    yield delay(600);
    navigate(ROUTES.HOTELS);
  } catch (err) {
    showErrorNotification('Fail to create', err);
    yield put(createHotelFail(err));
  }
}

function* watchCreateHotel() {
  yield takeEvery(ActionTypes.CREATE_HOTEL, doCreateHotel);
}

function* doEditHotel(
  action: ExtractAction<HotelsActions, ActionTypes.EDIT_HOTEL>,
) {
  const { hotelId, hotel } = action.payload;

  try {
    const { data } = yield call(
      coreAPI.put,
      `v1/operation/hotels/${hotelId}`,
      hotel,
    );
    yield put(editHotelSuccess(data));
    // @ts-ignore
    // TODO: Need migration in Dashboard first
    yield put(switchCurrentHotelSuccess({ hotel: Data, hotelId: Data.ID }));
    showSuccessNotification('Update successfully');
  } catch (err) {
    showErrorNotification('Fail to edit', err);
    yield put(editHotelFail(err));
  }
}

function* watchEditHotel() {
  yield takeEvery(ActionTypes.EDIT_HOTEL, doEditHotel);
}

function* doFetchHotelDetails(
  action: ExtractAction<HotelsActions, ActionTypes.FETCH_HOTEL_DETAILS>,
) {
  const { hotelId } = action.payload;

  try {
    const [{ data: editHotel }, { data: editCurrencies }] = yield all([
      call(coreAPI.get, `v1/pms/hotels/${hotelId}`),
      call(coreAPI.get, `v1/pms/hotels/${hotelId}/currencies`),
    ]);

    yield put(fetchHotelDetailsSuccess(editHotel, editCurrencies));
  } catch (err) {}
}

function* watchFetchHotelDetails() {
  yield takeEvery(ActionTypes.FETCH_HOTEL_DETAILS, doFetchHotelDetails);
}

function* doAddCms(action: ExtractAction<HotelsActions, ActionTypes.ADD_CMS>) {
  const { data: body } = action.payload;

  try {
    const { data } = yield call(
      coreAPI.post,
      'v1/operation/cms/integrate',
      body,
    );
    yield put(addCmsSuccess(data));
  } catch (err) {
    showErrorNotification('Fail to inregrate CMS');
  }
}

function* watchAddCms() {
  yield takeEvery(ActionTypes.ADD_CMS, doAddCms);
}

function* doAddExtranet(
  action: ExtractAction<HotelsActions, ActionTypes.ADD_EXTRANET>,
) {
  const { hotelId, data: body } = action.payload;

  try {
    const { data } = yield call(
      coreAPI.post,
      `v1/operation/hotels/${hotelId}/integrate-extranet`,
      body,
    );
    yield put(editHotelSuccess(data));
  } catch (err) {
    showErrorNotification('Fail to inregrate CMS');
  }
}

function* watchAddExtranet() {
  yield takeEvery(ActionTypes.ADD_EXTRANET, doAddExtranet);
}

function* doFetchFeatures(
  action: ExtractAction<HotelsActions, ActionTypes.FETCH_FEATURES>,
) {
  try {
    const { data } = yield call(coreAPI.get, 'v1/pms/modules');
    yield put(fetchFeaturesSuccess(data));
  } catch (err) {}
}

function* watchFetchFeatures() {
  yield takeEvery(ActionTypes.FETCH_FEATURES, doFetchFeatures);
}

export function* doActiveFeatureHotel(
  action: ExtractAction<HotelsActions, ActionTypes.ACTIVE_FEATURE>,
) {
  const { hotelId, featureId } = action.payload;

  try {
    const { data } = yield call(
      coreAPI.post,
      `v1/operation/hotels/${hotelId}/features/${featureId}`,
    );
    yield put(editHotelSuccess(data));
    // @ts-ignore
    yield put(switchCurrentHotelSuccess({ hotel: Data, hotelId: Data.ID }));
  } catch (err) {}
}

function* watchActiveFeatureHotel() {
  yield takeEvery(ActionTypes.ACTIVE_FEATURE, doActiveFeatureHotel);
}

export function* doDeactiveFeatureHotel(
  action: ExtractAction<HotelsActions, ActionTypes.DEACTIVE_FEATURE>,
) {
  const { hotelId, featureId } = action.payload;

  try {
    const { data } = yield call(
      coreAPI.put,
      `v1/operation/hotels/${hotelId}/features/${featureId}/disable`,
    );
    yield put(editHotelSuccess(data));
    // @ts-ignore
    yield put(switchCurrentHotelSuccess({ hotel: Data, hotelId: Data.ID }));
  } catch (err) {}
}

function* watchDeactiveFeatureHotel() {
  yield takeEvery(ActionTypes.DEACTIVE_FEATURE, doDeactiveFeatureHotel);
}

export function* doToggleFeatureHotel(
  action: ExtractAction<HotelsActions, ActionTypes.TOGGLE_FEATURE>,
) {
  const { hotelId, data: body } = action.payload;

  try {
    const { data } = yield call(
      coreAPI.post,
      `v1/operation/hotels/${hotelId}/features`,
      body,
    );
    yield put(editHotelSuccess(data));
    // @ts-ignore
    yield put(switchCurrentHotelSuccess({ hotel: Data, hotelId: Data.ID }));
  } catch (err) {
    showErrorNotification('Fail to edit', err);
  }
}

function* watchToggleFeatureHotel() {
  yield takeLatest(ActionTypes.TOGGLE_FEATURE, doToggleFeatureHotel);
}

function* doUploadImage(
  action: ExtractAction<HotelsActions, ActionTypes.UPLOAD_IMAGE>,
) {
  const {
    payload: {
      resolve,
      reject,
      data: { image },
    },
  } = action;
  try {
    const formData = new FormData();
    formData.append('image', image);
    const { data } = yield call(coreAPI.post, 'files/images', formData);
    yield put(uploadImageSuccess(data));
    resolve(data);
  } catch (err) {
    reject(err);
  }
}

function* watchUploadImage() {
  yield takeEvery(ActionTypes.UPLOAD_IMAGE, doUploadImage);
}

export default function* watchAll() {
  yield all([
    watchFetchHotels(),
    watchUpdateHotelStatus(),
    watchFetchCurrentHotel(),
    watchFetchHotelDetails(),
    watchCreateHotel(),
    watchEditHotel(),
    watchAddCms(),
    watchFetchFeatures(),
    watchActiveFeatureHotel(),
    watchDeactiveFeatureHotel(),
    watchToggleFeatureHotel(),
    watchAddExtranet(),
    watchUploadImage(),
  ]);
}
