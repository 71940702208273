import React from 'react';
import { Skeleton } from '@uxui/skeleton-loader';

export default function ReportPageSkeleton() {
  return (
    <div className="flex flex-wrap" style={{ maxWidth: '1000px' }}>
      <div className="w-full px-2 sm:w-1/2 md:w-1/4 lg:w-1/4">
        <Skeleton
          width="100%"
          height="36px"
          baseColor="#ddd"
          highlightColor="#e4e4e4"
        />
      </div>
      <div className="w-full px-2 sm:w-1/2 md:w-1/4 lg:w-1/4">
        <Skeleton
          width="100%"
          height="36px"
          baseColor="#ddd"
          highlightColor="#e4e4e4"
        />
      </div>
      <div className="w-full px-2 sm:w-1/2 md:w-1/5 lg:w-1/5">
        <Skeleton
          width="89px"
          height="36px"
          baseColor="#ddd"
          highlightColor="#e4e4e4"
        />
      </div>
    </div>
  );
}
