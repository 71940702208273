import { put, takeEvery, call } from 'redux-saga/effects';
import { deleteCurrencySuccess } from '../actions';
import ActionTypes from '../constants';
import { coreAPI } from 'utils/request';
import { showErrorNotification } from '@aha/utils';
import NProgress from 'nprogress';
import { ExtractAction } from 'types/actions';
import { CurrencySettingsActions } from '../type';

export function* doDeleteCurrency(
  action: ExtractAction<CurrencySettingsActions, ActionTypes.DELETE_CURRENCY>,
) {
  yield call(NProgress.start);
  const { payload: id } = action;
  try {
    yield call(coreAPI.put, `v1/operation/currencies/${id}/archive`);
    yield put(deleteCurrencySuccess(id));
  } catch (err) {
    showErrorNotification('Fail to delete', err);
  }
  yield call(NProgress.done);
}

export default function* watchDeleteCurrency() {
  yield takeEvery(ActionTypes.DELETE_CURRENCY, doDeleteCurrency);
}
