import ActionTypes from './constants';
import { action } from 'typesafe-actions';
import { InjectPromise } from 'types/actions';
import { MarketSegment } from 'types/schema';

export const fetchMarketingSources = (hotelId?: number) => {
  return action(ActionTypes.FETCH_MARKETING_SOURCES, hotelId);
};

export const fetchMarketingSourcesSuccess = (items: MarketSegment[]) => {
  return action(ActionTypes.FETCH_MARKETING_SOURCES_SUCCESS, items);
};

export const fetchMarketingSourcesFail = (error: Error) => {
  return action(ActionTypes.FETCH_MARKETING_SOURCES_FAIL, error);
};

export const resetMarketingSourcestate = () => {
  return action(ActionTypes.RESET_MARKETING_SOURCES_STATE);
};

export const deleteMarketingSource = (marketingSourceId: number) => {
  return action(ActionTypes.DELETE_MARKETING_SOURCE, marketingSourceId);
};

export const deleteMarketingSourceSuccess = (marketingSourceId: number) => {
  return action(ActionTypes.DELETE_MARKETING_SOURCE_SUCCESS, marketingSourceId);
};

export const deleteMarketingSourceFail = (error: Error) => {
  return action(ActionTypes.DELETE_MARKETING_SOURCE_FAIL, error);
};

export const createMarketingSource = (
  params: InjectPromise<
    MarketSegment,
    {
      hotelId: number;
      marketingSource: MarketSegment;
    }
  >,
) => {
  return action(ActionTypes.CREATE_MARKETING_SOURCE, params);
};

export const createMarketingSourceSuccess = (
  marketingSource: MarketSegment,
) => {
  return action(ActionTypes.CREATE_MARKETING_SOURCE_SUCCESS, marketingSource);
};

export const createMarketingSourceFail = (error: Error) => {
  return action(ActionTypes.CREATE_MARKETING_SOURCE_FAIL, error);
};

export const editMarketingSource = (
  params: InjectPromise<
    MarketSegment,
    {
      marketingSourceId: number;
      marketingSource: {
        description: string;
        name: string;
        status?: string;
        color?: string;
      };
    }
  >,
) => {
  return action(ActionTypes.EDIT_MARKETING_SOURCE, params);
};

export const editMarketingSourceSuccess = (marketingSource: MarketSegment) => {
  return action(ActionTypes.EDIT_MARKETING_SOURCE_SUCCESS, marketingSource);
};

export const editMarketingSourceFail = (error: Error) => {
  return action(ActionTypes.EDIT_MARKETING_SOURCE_FAIL, error);
};
