import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { makeSelectCities } from './selectors';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCities } from './actions';
import { useEffect } from 'react';
import { CityProps } from './type';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    cities: CityProps[] | null;
  }
>({
  cities: makeSelectCities(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doFetchCities: () => dispatch(fetchCities()),
});

export default function useSelectCities() {
  const dispatch = useDispatch();
  const { doFetchCities } = mapDispatchToProps(dispatch);
  const { cities } = useSelector(mapStateToProps);

  useEffect(() => {
    if (!cities) {
      doFetchCities();
    }
  }, []); // eslint-disable-line

  return useSelector(mapStateToProps);
}
