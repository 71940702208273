import { takeEvery, put, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { transferChargesBookingSuccess } from '../actions';
import { TRANSFER_CHARGES_BOOKING } from '../constants';
import { showErrorNotification } from '@aha/utils';

function* doTransferCharges({ bid, fid, transfer, reject, resolve }) {
  try {
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/bookings/${bid}/folios/${fid}/transfer-charges`,
      transfer,
    );
    yield put(transferChargesBookingSuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification('Transfer booking charges failed', err);
    reject(err);
  }
}

export function* watchTransferCharges() {
  yield takeEvery(TRANSFER_CHARGES_BOOKING, doTransferCharges);
}
