import React from 'react';
import Exception from 'components/Exception';
import { FormattedMessage } from 'react-intl';
import { ErrorBoundary as UIErrorBoundary } from '@aha/ui';
import * as Sentry from '@sentry/browser';

const ErrorBoundary: React.FC<any> = ({ children }) => {
  return (
    <UIErrorBoundary
      callBack={
        process.env.REACT_APP_STAGE === 'production'
          ? (error, errorInfo) => {
              Sentry.captureException(error, { extra: errorInfo });
            }
          : undefined
      }
      fallback={
        <Exception
          message={
            <FormattedMessage
              defaultMessage="Looks like something broke..."
              id="exception.error_boundary.message"
            />
          }
        />
      }
    >
      {children}
    </UIErrorBoundary>
  );
};

export default ErrorBoundary;
