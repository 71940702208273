enum ActionTypes {
  LOG_IN = 'auth/LOG_IN',
  LOG_IN_SUCCESS = 'auth/LOG_IN_SUCCESS',
  LOG_OUT = 'auth/LOG_OUT',
  FETCH_CURRENT_USER = 'auth/FETCH_CURRENT_USER',
  FETCH_CURRENT_USER_SUCCESS = 'auth/FETCH_CURRENT_USER_SUCCESS',
  FETCH_CURRENT_USER_FAIL = 'auth/FETCH_CURRENT_USER_FAIL',
  EDIT_USER = 'auth/EDIT_USER',
  EDIT_USER_SUCCESS = 'auth/EDIT_USER_SUCCESS',
  RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS',
  RESET_CHANGE_PASSWORD_SUCCESS = 'auth/RESET_CHANGE_PASSWORD_SUCCESS',
}

export default ActionTypes;
