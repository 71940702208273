import React, { useCallback, useState } from 'react';
import { updateRoomHouseKeepingStatus, openCreateLog } from '../actions';
import { createStructuredSelector } from 'reselect';
import { makeSelectLoadingActions } from '../selector';
import RoomStatusSelector, {
  RoomStatusOption,
  RoomStatus,
} from 'components/RoomStatusSelector';
import usePermission from 'components/permission/usePermission';
import { FormattedMessage } from 'react-intl';
import { Dispatch } from 'redux';
import { ApplicationRootState } from 'types/app';
import { useDispatch, useSelector } from 'react-redux';
import { OOOType } from './OOOBadge';
import moment from 'moment';
import { Room } from 'types/schema';

const defaultOptions: Array<RoomStatusOption> = [
  {
    label: (
      <FormattedMessage id="bookings.label.clean" defaultMessage="Clean" />
    ),
    value: 'clean',
  },
  {
    label: (
      <FormattedMessage
        id="bookings.label.cleaning"
        defaultMessage="Cleaning"
      />
    ),
    value: 'cleaning',
  },
  {
    label: (
      <FormattedMessage id="bookings.label.dirty" defaultMessage="Dirty" />
    ),
    value: 'dirty',
  },
  {
    label: <FormattedMessage id="bookings.label.ooo" defaultMessage="OOO" />,
    value: 'out_of_order',
  },
];

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    isLoading: boolean;
  }
>({
  isLoading: makeSelectLoadingActions(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doUpdateHkStatus: (id: number, status: string) =>
    dispatch(updateRoomHouseKeepingStatus(id, status)),
  doOpenCreateLog: (showModal: boolean) => dispatch(openCreateLog(showModal)),
});

export type RoomStatusesProps = {
  className?: string;
  room: Room;
  oooType?: OOOType;
};

export const RoomStatuses: React.FC<RoomStatusesProps> = ({
  className,
  room,
}) => {
  const dispatch = useDispatch();
  const { doUpdateHkStatus, doOpenCreateLog } = mapDispatchToProps(dispatch);
  const { isLoading } = useSelector(mapStateToProps);
  const allowUpdateHousekeeping = usePermission('pms.rooms.updateHousekeeping');
  const [updateStatus, setUpdateStatus] = useState<RoomStatus>(
    room.housekeepingStatus as RoomStatus,
  );

  const log = room?.roomAvailabilityLogs?.[0];

  let oooType: OOOType | undefined;
  if (log) {
    if (moment().isBefore(log.unAvailableFrom, 'minutes')) {
      oooType = 'future';
    } else if (
      moment().isBetween(
        moment(log.unAvailableFrom),
        moment(log.availableDate),
        'minutes',
        '[]',
      )
    ) {
      oooType = 'now';
    }
  }

  const onUpdateHkStatus = useCallback(
    (st) => {
      setUpdateStatus(st);

      if (st === 'out_of_order') {
        doOpenCreateLog(true);
        return;
      }

      room.id && doUpdateHkStatus(room.id, st);
    },
    [room.id], // eslint-disable-line
  );

  let hkStatus = isLoading
    ? updateStatus
    : (room.housekeepingStatus as RoomStatus);

  if (oooType === 'now') {
    hkStatus = 'out_of_order';
  }

  return (
    <div className={className}>
      <RoomStatusSelector
        loading={!allowUpdateHousekeeping || isLoading}
        value={hkStatus}
        onChange={onUpdateHkStatus}
        options={defaultOptions}
        disabledOOO={!!oooType}
      />
    </div>
  );
};

export default RoomStatuses;
