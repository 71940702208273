import produce from 'immer';
import moment from 'moment';
import {
  FETCH_EXPECTING_BOOKING_LIST,
  FETCH_EXPECTING_BOOKING_LIST_FAIL,
  FETCH_EXPECTING_BOOKING_LIST_SUCCESS,
  FETCH_CHECKED_IN_BOOKINGS,
  FETCH_CHECKED_IN_BOOKINGS_FAIL,
  FETCH_CHECKED_IN_BOOKINGS_SUCCESS,
  FETCH_INHOUSE_GUEST_LIST,
  FETCH_INHOUSE_GUEST_LIST_FAIL,
  FETCH_INHOUSE_GUEST_LIST_SUCCESS,
  FETCH_CHECKED_OUT_BOOKINGS,
  FETCH_CHECKED_OUT_BOOKINGS_FAIL,
  FETCH_CHECKED_OUT_BOOKINGS_SUCCESS,
  FETCH_BOOKING,
  FETCH_BOOKING_FAIL,
  FETCH_BOOKING_SUCCSESS,
  BOOKING_KEY,
  FETCH_AVAILABLE_ROOMS,
  FETCH_AVAILABLE_ROOMS_SUCCESS,
  FETCH_AVAILABLE_ROOMS_FAIL,
  FETCH_ARRIVAL_BOOKINGS,
  FETCH_ARRIVAL_BOOKINGS_SUCCESS,
  FETCH_ARRIVAL_BOOKINGS_FAIL,
  FETCH_DEPARTURE_BOOKINGS,
  FETCH_DEPARTURE_BOOKINGS_SUCCESS,
  FETCH_DEPARTURE_BOOKINGS_FAIL,
  ALLOCATE_ROOM_FOR_BOOKING_SUCCESS,
  CHECK_IN_BOOKING_SUCCESS,
  NO_SHOW_BOOKING_SUCCESS,
  CANCEL_BOOKING_SUCCESS,
  CHECK_OUT_BOOKING_SUCCESS,
  FETCH_FOLIO_DETAIL,
  FETCH_FOLIO_DETAIL_SUCCESS,
  FETCH_FOLIO_DETAIL_FAIL,
  FETCH_PAYMENT_TYPES_SUCCESS,
  FETCH_CHARGE_TEMPLATES_SUCCESS,
  FETCH_REVENUE_GROUPS_SUCCESS,
  ADD_FOLIO_SUCCESS,
  VOID_FOLIO_SUCCESS,
  FETCH_BOOKING_HISTORY_SUCCESS,
  FETCH_BOOKING_HISTORY,
  FETCH_BOOKING_HISTORY_FAIL,
  UPDATE_BOOKING_INFO_SUCCESS,
  REMOVE_ROOM_FROM_BOOKING_SUCCESS,
  FETCH_COMPANIES,
  FETCH_COMPANIES_FAIL,
  FETCH_COMPANIES_SUCCESS,
  UNASSIGN_GUEST_BOOKING,
  UNASSIGN_GUEST_BOOKING_SUCCESS,
  ASSIGN_GUEST_BOOKING,
  ASSIGN_GUEST_BOOKING_SUCCESS,
  SET_CURRENT_TAB,
  CREATE_COMPANY_SUCCESS,
  RESET_AVAILABLE_ROOMS,
  FETCH_EXPIRED_BOOKINGS,
  FETCH_EXPIRED_BOOKINGS_SUCCESS,
  VOID_CHARGES_OF_BOOKING_SUCCESS,
} from './constants';
import initialStateHolder from 'utils/initialStateHolder';
import {
  UPCOMING_ARRIVAL,
  UPCOMING_DEPARTURE,
  TODAY_ARRIVAL,
  EXPIRED_ARRIVAL,
  EXPIRED_DEPARTURE,
} from './helpers/useTab';

export const initialState = initialStateHolder.setState(BOOKING_KEY, {
  loading: true,
  arrivalLoading: false,
  departureLoading: false,
  loadingRooms: false,
  availableRooms: {},
  error: null,
  booking: {},
  bookingHistory: [],
  isLoadingChangeLogs: false,
  folio: {},
  bookings: [],
  checkedInBookings: [],
  checkinPagination: undefined,
  checkedOutBookings: [],
  checkoutPagination: undefined,
  todayCheckinBookings: [],
  todayCheckinPagination: undefined,
  todayCheckoutBookings: [],
  todayCheckoutPagination: undefined,
  inHouseGuestList: [],
  paymentTypes: [],
  chargeTemplates: [],
  revGroups: [],
  companies: [],
  expiredBks: [],
  upcomingBks: [],
  expiredPagination: undefined,
  upcomingPagination: undefined,
  currentTab: TODAY_ARRIVAL,
});

function extractBooking(tabID) {
  let type = null;
  if ([UPCOMING_ARRIVAL, UPCOMING_DEPARTURE].includes(tabID)) {
    type = 'upcoming';
  } else if ([EXPIRED_ARRIVAL, EXPIRED_DEPARTURE].includes(tabID)) {
    type = 'expired';
  }

  return { bkType: type ? `${type}Bks` : null, bkPagi: `${type}Pagination` };
}

/* eslint-disable default-case, no-param-reassign */
export default function bookingsReducer(state = initialState, action) {
  return produce(state, (draft) => {
    const { bkType, bkPagi } = extractBooking(draft.currentTab);

    switch (action.type) {
      case FETCH_BOOKING:
        draft.error = null;
        draft.loading = true;
        if (
          action.bid !== draft.booking.id &&
          action.bid !== draft.booking.refCode
        ) {
          draft.booking = {};
        }
        break;
      case FETCH_BOOKING_FAIL:
        draft.error = action.error;
        draft.loading = false;
        break;
      case FETCH_BOOKING_SUCCSESS:
        draft.booking = action.booking || {};
        draft.error = null;
        draft.loading = false;
        break;

      case FETCH_ARRIVAL_BOOKINGS:
        draft.error = null;
        draft.arrivalLoading = true;
        break;

      case FETCH_ARRIVAL_BOOKINGS_SUCCESS:
        draft.checkedInBookings = action.checkinBookings;
        draft.checkinPagination = action.checkinPagination;
        draft.todayCheckinPagination = action.todayCheckinPagination;
        draft.todayCheckinBookings = action.todayCheckinBookings;
        draft.expiredBks = action.expiredBks;
        draft.expiredPagination = action.expiredPagination;
        draft.upcomingBks = action.upcomingBks;
        draft.upcomingPagination = action.upcomingPagination;

        draft.arrivalLoading = false;
        break;

      case FETCH_ARRIVAL_BOOKINGS_FAIL:
        draft.error = action.error;
        draft.arrivalLoading = false;
        draft.expiredBks = [];
        draft.upcomingBks = [];
        break;

      case FETCH_DEPARTURE_BOOKINGS:
        draft.error = null;
        draft.departureLoading = true;
        break;

      case FETCH_DEPARTURE_BOOKINGS_SUCCESS:
        draft.checkedOutBookings = action.checkoutBookings;
        draft.checkoutPagination = action.checkoutPagination;
        draft.todayCheckoutBookings = action.todayCheckoutBookings;
        draft.todayCheckoutPagination = action.todayCheckoutPagination;
        draft.expiredBks = action.expiredBks;
        draft.expiredPagination = action.expiredPagination;
        draft.upcomingBks = action.upcomingBks;
        draft.upcomingPagination = action.upcomingPagination;
        draft.departureLoading = false;
        break;

      case FETCH_DEPARTURE_BOOKINGS_FAIL:
        draft.error = action.error;
        draft.departureLoading = false;
        draft.expiredBks = [];
        draft.upcomingBks = [];
        break;

      case FETCH_EXPECTING_BOOKING_LIST:
        draft.error = null;
        draft.loading = true;
        draft.bookings = [];
        break;
      case FETCH_EXPECTING_BOOKING_LIST_FAIL:
        draft.error = action.error;
        draft.loading = false;
        break;
      case FETCH_EXPECTING_BOOKING_LIST_SUCCESS:
        draft.bookings = action.bookings || [];
        draft.error = null;
        draft.loading = false;
        break;

      case FETCH_CHECKED_IN_BOOKINGS:
        draft.error = null;
        draft.loading = true;
        break;
      case FETCH_CHECKED_IN_BOOKINGS_FAIL:
        draft.error = action.error;
        draft.loading = false;
        break;
      case FETCH_CHECKED_IN_BOOKINGS_SUCCESS:
        draft.checkedInBookings = action.bookings || [];
        draft.loading = false;
        break;

      case FETCH_EXPIRED_BOOKINGS:
        break;

      case FETCH_EXPIRED_BOOKINGS_SUCCESS:
        draft.expiredBks = action.bookings || [];
        draft.expiredPagination = action.pagination;
        break;

      case FETCH_INHOUSE_GUEST_LIST:
        draft.error = null;
        draft.loading = true;
        break;
      case FETCH_INHOUSE_GUEST_LIST_FAIL:
        draft.error = action.error;
        draft.loading = false;
        break;
      case FETCH_INHOUSE_GUEST_LIST_SUCCESS:
        draft.inHouseGuestList = action.bookings || [];
        draft.inHousePagination = action.inHousePagination;
        draft.loading = false;
        break;

      case FETCH_CHECKED_OUT_BOOKINGS:
        draft.error = null;
        draft.loading = true;
        break;
      case FETCH_CHECKED_OUT_BOOKINGS_FAIL:
        draft.error = action.error;
        draft.loading = false;
        break;
      case FETCH_CHECKED_OUT_BOOKINGS_SUCCESS:
        draft.checkedOutBookings = action.bookings || [];
        draft.loading = false;
        break;

      case FETCH_AVAILABLE_ROOMS:
        draft.loadingRooms = true;
        draft.error = null;
        break;
      case FETCH_AVAILABLE_ROOMS_SUCCESS:
        draft.availableRooms[action.roomTypeID] = action.rooms;
        draft.loadingRooms = false;
        break;
      case FETCH_AVAILABLE_ROOMS_FAIL:
        draft.loadingRooms = false;
        draft.error = action.error;
        break;

      case RESET_AVAILABLE_ROOMS:
        draft.loadingRooms = true;
        draft.error = null;
        draft.availableRooms = {};
        break;

      // case ALLOCATE_ROOM_FOR_BOOKING:
      //   draft.allocatingRoom = true;
      //   draft.error = null;
      //   break;
      case ALLOCATE_ROOM_FOR_BOOKING_SUCCESS:
        draft.allocatingRoom = false;

        if (draft[bkType] && Array.isArray(draft[bkType])) {
          draft[bkType] = draft[bkType].map((bk) => {
            if (bk.id === action.bookingRoom?.bookingID) {
              bk.rooms = bk.rooms?.map((r) =>
                r.id === action.bookingRoom?.id ? action.bookingRoom : r,
              );
            }
            return bk;
          });
        }

        if (draft.booking?.id) {
          draft.booking.rooms = draft.booking.rooms?.map((r) =>
            r.id === action.bookingRoom?.id ? action.bookingRoom : r,
          );
        }
        break;

      case REMOVE_ROOM_FROM_BOOKING_SUCCESS:
        draft.booking.rooms = draft.booking.rooms?.map((r) =>
          r.id === action.bookingRoom?.id ? action.bookingRoom : r,
        );
        draft.loading = false;
        draft.error = action.error;
        break;

      case CHECK_IN_BOOKING_SUCCESS:
        const { bookingRoomId } = action;
        const checkInBks = action.booking.rooms.reduce((total) => total + 1, 0);
        const shouldRemoveBk =
          !bookingRoomId ||
          (bookingRoomId && action.booking.rooms?.length - 1 === checkInBks);

        if (
          bkType &&
          draft[bkType] &&
          Array.isArray(draft[bkType]) &&
          shouldRemoveBk
        ) {
          draft[bkType] = draft[bkType].filter(
            (bk) => bk.id !== action.booking.id,
          );
        }

        if (moment(action.booking?.arrival).diff(moment(), 'days') === 0) {
          if (shouldRemoveBk) {
            draft.todayCheckinBookings = (
              draft.todayCheckinBookings || []
            ).filter((bk) => bk.id !== action.booking.id);

            draft.checkedInBookings = [
              ...draft.checkedInBookings,
              action.booking,
            ];

            if (draft.checkinPagination) {
              draft.checkinPagination.totalCount += 1;
            }

            if (draft.todayCheckinPagination) {
              draft.todayCheckinPagination.totalCount -= 1;
            }

            if (bkType && draft[bkPagi]?.totalCount > 0) {
              draft[bkPagi].totalCount -= 1;
            }
          } else {
            const bkIndex = draft.todayCheckinBookings?.findIndex(
              (bk) => bk.id === action.booking.id,
            );

            if (
              bkIndex &&
              bkIndex !== -1 &&
              Array.isArray(draft.todayCheckinBookings[bkIndex].rooms)
            ) {
              const bkRoomIndex = draft.todayCheckinBookings[
                bkIndex
              ]?.rooms?.findIndex((room) => room.id === bookingRoomId);

              if (bkRoomIndex && bkRoomIndex !== -1) {
                draft.todayCheckinBookings[bkIndex].rooms[bkRoomIndex].status =
                  'checked_in';
              }
            }
          }
        } else {
          if (shouldRemoveBk) {
            draft.inHouseGuestList = [
              ...draft?.inHouseGuestList,
              action.booking,
            ];

            if (bkType && draft[bkPagi]?.totalCount > 0) {
              draft[bkPagi].totalCount -= 1;
            }
          }
        }

        if (draft.booking?.ID) {
          draft.booking = action.booking;
        }
        break;
      case CHECK_OUT_BOOKING_SUCCESS:
        const coBookingRoomID = action.bookingRoomId;
        const checkOutBks = action.booking.rooms.reduce(
          (total) => total + 1,
          0,
        );

        const shouldRemoveCoBk =
          !coBookingRoomID ||
          (coBookingRoomID && action.booking.rooms?.length - 1 === checkOutBks);

        if (
          bkType &&
          draft[bkType] &&
          Array.isArray(draft[bkType]) &&
          shouldRemoveCoBk
        ) {
          draft[bkType] = draft[bkType].filter(
            (bk) => bk.id !== action.booking.id,
          );

          if (draft[bkPagi]?.totalCount > 0) {
            draft[bkPagi].totalCount -= 1;
          }
        }

        if (
          moment(
            action.booking?.checkedOutAt || action.booking?.departure,
          ).diff(moment(), 'days') === 0
        ) {
          if (shouldRemoveCoBk) {
            draft.todayCheckoutBookings = draft.todayCheckoutBookings?.filter(
              (bk) => bk.id !== action.booking.id,
            );
            draft.checkedOutBookings = [
              ...draft.checkedOutBookings,
              action.booking,
            ];
            if (draft.checkoutPagination) {
              draft.checkoutPagination.totalCount += 1;
            }

            if (draft?.todayCheckoutPagination > 1) {
              draft.todayCheckoutPagination.totalCount -= 1;
            }
          } else {
            const bkCoIndex = draft.todayCheckoutBookings?.findIndex(
              (bk) => bk.id === action.booking.id,
            );

            const bkCoRoomIndex = draft.todayCheckoutBookings[
              bkCoIndex
            ]?.rooms?.findIndex((room) => room.id === bookingRoomId);

            if (bkCoIndex !== -1 && bkCoRoomIndex !== -1) {
              draft.todayCheckoutBookings[bkCoIndex].rooms[
                bkCoRoomIndex
              ].status = 'checked_out';
            }
          }
        }

        if (draft.booking?.ID) {
          draft.booking = action.booking;
        }
        break;

      case NO_SHOW_BOOKING_SUCCESS:
      case CANCEL_BOOKING_SUCCESS:
      case VOID_CHARGES_OF_BOOKING_SUCCESS:
        if (bkType && draft[bkType] && Array.isArray(draft[bkType])) {
          draft[bkType] = draft[bkType].filter(
            (bk) => bk.id !== action.booking.id,
          );

          if (draft[bkPagi]?.totalCount > 0) {
            draft[bkPagi].totalCount -= 1;
          }
        }

        if (draft.booking?.ID) {
          draft.booking = action.booking;
        }
        break;

      case UPDATE_BOOKING_INFO_SUCCESS:
        draft.booking = action.booking;
        break;

      case FETCH_FOLIO_DETAIL:
        draft.loadingFolio = true;
        break;
      case FETCH_FOLIO_DETAIL_SUCCESS:
        draft.loadingFolio = false;
        draft.folio = action.folio;
        break;
      case FETCH_FOLIO_DETAIL_FAIL:
        draft.loadingFolio = false;
        draft.error = action.error;
        break;

      case FETCH_PAYMENT_TYPES_SUCCESS:
        draft.paymentTypes = action.paymentTypes;
        break;

      case FETCH_CHARGE_TEMPLATES_SUCCESS:
        draft.chargeTemplates = action.chargeTemplates;
        break;

      case FETCH_REVENUE_GROUPS_SUCCESS:
        draft.revGroups = action.revGroups;
        break;

      case ADD_FOLIO_SUCCESS:
        draft.booking.folios = [...draft.booking.folios, action.folio];
        break;
      case VOID_FOLIO_SUCCESS:
        draft.booking.folios = draft.booking.folios.map((f) =>
          f.ID === action.folio.ID ? action.folio : f,
        );
        break;

      case FETCH_BOOKING_HISTORY:
        draft.isLoadingChangeLogs = true;
        draft.error = null;
        break;
      case FETCH_BOOKING_HISTORY_SUCCESS:
        draft.bookingHistory = action.history;
        draft.isLoadingChangeLogs = false;
        break;
      case FETCH_BOOKING_HISTORY_FAIL:
        draft.error = action.error;
        draft.isLoadingChangeLogs = false;
        break;

      case FETCH_COMPANIES:
        draft.error = null;
        break;
      case FETCH_COMPANIES_FAIL:
        draft.error = action.error;
        break;
      case FETCH_COMPANIES_SUCCESS:
        draft.companies = action.companies;
        break;

      case CREATE_COMPANY_SUCCESS:
        if (draft.companies) {
          draft.companies.push(action.payload);
        } else {
          draft.companies = [action.payload];
        }
        break;

      case ASSIGN_GUEST_BOOKING:
        break;
      case ASSIGN_GUEST_BOOKING_SUCCESS:
        const roomAssignIndex = (draft.booking.rooms || []).findIndex(
          (r) => r.id === action.guest.bookingRoomId,
        );

        if (roomAssignIndex !== -1) {
          const oldGuest = draft.booking.guests.find(
            (g) => g.id === action.guest.guestID,
          );

          const roomGuests = draft.booking.rooms?.[roomAssignIndex]?.guests;
          if (Array.isArray(roomGuests)) {
            draft.booking.rooms[roomAssignIndex].guests.push(oldGuest);
          } else {
            draft.booking.rooms[roomAssignIndex].guests = [oldGuest];
          }
        }
        break;

      case UNASSIGN_GUEST_BOOKING:
        break;
      case UNASSIGN_GUEST_BOOKING_SUCCESS:
        const roomUnassignIndex = draft.booking.rooms?.findIndex(
          (r) => r.id === action.guest.bookingRoomId,
        );

        if (
          roomUnassignIndex !== -1 &&
          Array.isArray(draft.booking.rooms?.[roomUnassignIndex]?.guests)
        ) {
          draft.booking.rooms[roomUnassignIndex].guests = draft.booking.rooms[
            roomUnassignIndex
          ].guests.filter((g) => g.id !== action.guest.guestId);
        }
        break;
      case SET_CURRENT_TAB:
        draft.currentTab = action.tabID;
        break;
    }
  });
}
