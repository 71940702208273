import React from 'react';

import { createSelector } from 'reselect';
import { DateTimeFormatter } from '@aha/ui';
import { initialState } from './reducer';
import { currencyFormater } from 'utils/currencyFormater';

const selectHourlyBooking = (state) => state.hourlyBooking || initialState;

export function makeSelectHourlyBooking() {
  return createSelector(selectHourlyBooking, ({ booking }) => {
    const charges = booking?.charges?.map((c) => {
      let serviceName = [c.room?.title, c.room?.roomType?.name]
        .filter(Boolean)
        .join(' - ');
      if (!c.room) {
        if (c.name) {
          serviceName = `${c.quantity}x ${c.name}`;
        } else if (c.amount < 0) {
          serviceName = 'Discount';
        } else {
          serviceName = 'Unknow';
        }
      }

      const fmtChargeAmount = currencyFormater(
        c.finalAmount || c.amount,
        c.currency,
      );

      return {
        ...c,
        serviceName,
        fmtChargeAmount,
        fmtChargeDate: (
          <DateTimeFormatter value={c.CreatedAt} format="HH:mm, DD MMM YYYY" />
        ),
      };
    });

    const payments = booking?.payments?.map((p) => {
      const paymentName = `${p?.paymentType?.name} - ${p?.paymentSubtype?.name}`;
      const fmtPaymentAmount = currencyFormater(p.amount, p.currency?.name);
      return {
        ...p,
        paymentName,
        fmtPaymentAmount,
        fmtPaidDate: (
          <DateTimeFormatter value={p.CreatedAt} format="HH:mm, DD MMM YYYY" />
        ),
      };
    });

    return {
      ...booking,
      charges,
      payments,
    };
  });
}

export function makeSelectHourlyBookingChangeLogs() {
  return createSelector(selectHourlyBooking, (state) => state.changeLogs);
}
export function makeIsLoadingHourlyBookingChangeLogs() {
  return createSelector(
    selectHourlyBooking,
    (state) => state.loadingChangeLogs,
  );
}
