import { takeLatest, put, call } from 'redux-saga/effects';

import { fetchPaymentTypesSuccess, fetchPaymentTypesFail } from '../actions';
import { coreAPI } from 'utils/request';
import { FETCH_PAYMENT_TYPES } from '../constants';
import NProgress from 'nprogress';
import { showErrorNotification } from '@aha/utils';

function* doFetch({ hid }) {
  yield call(NProgress.start);
  try {
    const { data } = yield call(coreAPI.get, `v1/pms/payment-types`);
    yield put(fetchPaymentTypesSuccess(data));
  } catch (err) {
    showErrorNotification('Fail to fetch', err);
    yield put(fetchPaymentTypesFail(err));
  }
  yield call(NProgress.done);
}

export function* watchFetchPaymentTypes() {
  yield takeLatest(FETCH_PAYMENT_TYPES, doFetch);
}
