import { put, takeEvery, call } from 'redux-saga/effects';
import { createCurrencySuccess } from '../actions';
import { showErrorNotification } from '@aha/utils';
import ActionTypes from '../constants';
import { coreAPI } from 'utils/request';
import NProgress from 'nprogress';
import { ExtractAction } from 'types/actions';
import { CurrencySettingsActions } from '../type';

export function* doCreateCurrency(
  action: ExtractAction<CurrencySettingsActions, ActionTypes.CREATE_CURRENCY>,
) {
  yield call(NProgress.start);
  const {
    payload: { resolve, reject, data },
  } = action;
  const { currency } = data || {
    hotelId: '',
    currency: {},
  };
  try {
    const { data } = yield call(
      coreAPI.post,
      'v1/operation/currencies',
      currency,
    );
    yield put(createCurrencySuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification('Fail to create', err);
    reject(err);
  }
  yield call(NProgress.done);
}

export default function* watchCreateCurrency() {
  yield takeEvery(ActionTypes.CREATE_CURRENCY, doCreateCurrency);
}
