import React from 'react';
import classnames from 'classnames';
import { ButtonGlobalStyle } from './styled';
import { CSSObject } from 'styled-components';

export interface ButtonProps extends React.DOMAttributes<HTMLButtonElement> {
  icon?: React.FunctionComponent;
  size?: 'large' | 'medium' | 'default' | 'small';
  type?: 'line' | 'normal' | 'icon';
  htmlType?: 'button' | 'submit' | 'reset';
  color?: 'red' | 'yellow' | 'primary' | 'secondary' | 'black' | 'grey';
  disabled?: boolean;
  loading?: boolean;
  block?: boolean;
  truncate?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: any;
  style?: CSSObject;
}

export function Button({
  icon: IconComponent,
  children,
  size = 'medium',
  type = 'normal',
  color = 'primary',
  block = false,
  loading = false,
  className = '',
  truncate = false,
  htmlType = 'button',
  onClick,
  ...rest
}: ButtonProps) {
  let renderIcon = null;

  if (loading) {
    renderIcon = <i className="btn__spinner" />;
  } else if (IconComponent) {
    renderIcon = (
      <i className={classnames('btn__icon', { 'mr-0': size === 'small' })}>
        <IconComponent />
      </i>
    );
  }
  return (
    <button
      type={htmlType}
      className={classnames(`btn btn--${color} btn--${size} btn--${type}`, {
        'btn--block': block,
        'btn--loading': loading,
        'pl-1': !!children && IconComponent,
        [className]: className,
      })}
      onClick={onClick}
      {...rest}
    >
      <ButtonGlobalStyle />
      {renderIcon}
      {children && (
        <span className={classnames(truncate && 'truncate leading-normal')}>
          {children}
        </span>
      )}
    </button>
  );
}

export default Button;
