import React from 'react';
import { connect } from 'react-redux';
import { makeSelectIsLogin } from './selectors';
import { createStructuredSelector } from 'reselect';
import { useInjectSaga } from 'utils/injectSaga';
import saga from './saga';
import { Redirect, useLocation } from '@reach/router';
import ROUTES from 'constants/routes';
import { logOut } from './actions';
import { useDispatch } from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
    doLogout: () => dispatch(logOut()),
  };
};

const StartPage = ({ isLogin, children }) => {
  useInjectSaga({ key: 'auth', saga });
  const dispatch = useDispatch();
  const location = useLocation();
  const { doLogout } = mapDispatchToProps(dispatch);

  if (location.pathname === ROUTES.RESET_PASSWORD) {
    doLogout();
  } else if (isLogin) {
    return <Redirect to={ROUTES.DASHBOARD} noThrow />;
  }

  return children;
};

const mapStateToProps = createStructuredSelector({
  isLogin: makeSelectIsLogin(),
});

const StartPageWithConnect = connect(mapStateToProps)(StartPage);

export default StartPageWithConnect;
