import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

import { coreAPI } from 'utils/request';
import { fetchRatePlansSuccess, fetchRatePlansFail } from '../actions';
import ActionTypes from '../constants';
import NProgress from 'nprogress';
import { showErrorNotification } from '@aha/utils';
import { ExtractAction } from 'types/actions';
import { RatePlansActions } from '../types';

function* doFetchRates(
  action: ExtractAction<RatePlansActions, ActionTypes.FETCH_RATE_PLANS>,
) {
  yield call(NProgress.start);
  try {
    const {
      payload: { start, end },
    } = action;

    const { data } = yield call(
      coreAPI.get,
      `/v1/pms/rate-plans?fromDate=${moment(
        start,
      ).toISOString()}&toDate=${moment(end).toISOString()}`,
    );
    yield put(fetchRatePlansSuccess(data));
  } catch (err) {
    showErrorNotification('Fail to fetch', err);
    yield put(fetchRatePlansFail(err));
  }
  yield call(NProgress.done);
}

export default function* watchFetchRates() {
  yield takeLatest(ActionTypes.FETCH_RATE_PLANS, doFetchRates);
}
