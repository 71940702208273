import { takeLatest, put, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { assignGuestBookingSuccess } from '../actions';
import { ASSIGN_GUEST_BOOKING } from '../constants';
import { showErrorNotification } from '@aha/utils';
import NProgress from 'nprogress';

function* doAssignGuestBooking({ bid, guest }) {
  yield call(NProgress.start);
  try {
    yield call(coreAPI.put, `v1/pms/bookings/${bid}/assign-guest`, guest);
    yield put(assignGuestBookingSuccess(guest));
  } catch (err) {
    showErrorNotification('Fail to assign', err);
  }
  yield call(NProgress.done);
}

export function* watchAssignGuestBooking() {
  yield takeLatest(ASSIGN_GUEST_BOOKING, doAssignGuestBooking);
}
