enum ActionTypes {
  FETCH_CHARGE_TEMPLATE_LIST = 'createBooking/FETCH_CHARGE_TEMPLATE_LIST',
  FETCH_CHARGE_TEMPLATE_LIST_SUCCESS = 'createBooking/FETCH_CHARGE_TEMPLATE_LIST_SUCCESS',

  FETCH_COUNTRIES = 'createBooking/FETCH_COUNTRIES',
  FETCH_COUNTRIES_SUCCESS = 'createBooking/FETCH_COUNTRIES_SUCCESS',

  SET_COMPANY_BOOKING_ID = 'createBooking/SET_COMPANY_BOOKING_ID',
}

export default ActionTypes;
