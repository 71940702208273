import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import tw from 'twin.macro';
import { ROOMS_STATUSES, RoomStatus } from 'utils/constants';
import { formatDiffTime } from 'utils/formatDiffTime';
import { fetchLogs } from 'containers/RoomPlanPage/actions';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { formatDatetime } from '@aha/utils';
import { IconTag } from '@aha/icons';
import { Room, Booking, Guest, HourlyBooking } from 'types/schema';

const OuterContainer = styled.div`
  ${tw`relative w-full leading-none`}
  padding-bottom: 71.5%;
`;

const InnerContainer = styled.div`
  ${tw`absolute inset-0 z-0 flex flex-col items-center justify-center w-full bg-white border rounded-sm hover:shadow-lg`}
  transition: all 0.2s ease;
  min-width: 140px;
  border-width: 0.072rem;
`;

const Button = styled.button`
  ${tw`w-full h-full p-2 overflow-hidden border-none`}
  box-sizing: border-box;
  outline: none !important;
  transition: all 0.2s;
`;

const Status = styled.span`
  ${tw`absolute flex items-center px-1 font-semibold leading-loose text-white uppercase rounded-sm cursor-pointer`};
  letter-spacing: 0.25px;
  font-size: 10px;
  height: 16px;
  top: 6px;
  left: 6px;
`;

const RoomTitle = styled.h6<{ paddingTop: boolean }>`
  ${tw`self-center block mb-0 text-base font-semibold text-black-lightest`}
  ${(props) => (props.paddingTop ? 'padding-top: 18px' : '')}
`;
const TimeInfo = styled.div`
  ${tw`flex items-center content-center justify-center w-full text-white capitalize text-black-lightest`}
  font-size: 11px;
  margin: 8px 0;
`;

type BookingGuest = Booking & {
  guests?: Guest[];
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doFetchLogs: (roomId: number) => dispatch(fetchLogs(roomId)),
});

export interface RoomBlockProps {
  onClick?: (room: Room) => void;
  room: Room;
  className?: string;
  currentTime?: string | Date | moment.Moment;
  disabled?: boolean;
  housekeepingStatus?: RoomStatus;
}

function RoomBlock({
  room = {},
  className = '',
  onClick,
  currentTime,
  disabled,
  housekeepingStatus,
}: RoomBlockProps) {
  const log = room?.roomAvailabilityLogs?.[0];
  const dispatch = useDispatch();
  const { doFetchLogs } = mapDispatchToProps(dispatch);

  useEffect(() => {
    if (!room.id) {
      return;
    }

    const now = moment(),
      upComingDelay = log ? moment(log.unAvailableFrom).diff(now, 's') : -1,
      expiredDelay = log ? moment(log.availableDate).diff(now, 's') : -1;

    let upComingTimer: number = 0,
      expiredTimer: number = 0;

    if (upComingDelay >= 0) {
      upComingTimer = setTimeout(() => {
        doFetchLogs(Number(room.id));
      }, upComingDelay);
    }

    if (expiredDelay >= 0) {
      expiredTimer = setTimeout(() => {
        doFetchLogs(Number(room.id));
      }, expiredDelay);
    }

    return () => {
      clearTimeout(upComingTimer);
      clearTimeout(expiredTimer);
    };
  }, [log]); // eslint-disable-line

  const booking = (room.bookings || []).reduce(
    (fin: Booking | null, bk: Booking) => {
      const bkRoom = (bk.rooms || []).find(
        (r) => r.status === 'checked_in' && r.roomId === room.id,
      );

      if (!fin && (bkRoom || bk.status === 'checked_in')) {
        return { ...bk, guests: bkRoom?.guests || bk.guests };
      }
      return fin;
    },
    null,
  );

  const hourlyBooking = room.hourlyBookings?.[0];
  let guest = (hourlyBooking as HourlyBooking)?.guests?.[0];
  if (booking) {
    const { guests } = booking;
    guest = guests?.[0];
  }
  return (
    <OuterContainer>
      <InnerContainer
        className={classnames({
          [className]: className,
          'shadow-none opacity-50': disabled,
          'border-primary': housekeepingStatus === 'clean',
          'border-orange': housekeepingStatus === 'dirty',
          'border-blue': housekeepingStatus === 'cleaning',
          'border-grey-darker': housekeepingStatus === 'out_of_order',
        })}
      >
        <Status
          className={classnames({
            'cursor-not-allowed': disabled,
            'bg-primary': housekeepingStatus === 'clean',
            'bg-orange': housekeepingStatus === 'dirty',
            'bg-blue': housekeepingStatus === 'cleaning',
            'bg-grey-darker': housekeepingStatus === 'out_of_order',
          })}
        >
          {ROOMS_STATUSES[housekeepingStatus || 'clean']}
        </Status>
        {guest && (
          <Tooltip title={`#${booking?.refCode || hourlyBooking?.refCode}`}>
            <IconTag className="absolute top-0 right-0 mt-2 mr-2" />
          </Tooltip>
        )}
        <Button
          className={classnames('', {
            'cursor-not-allowed': disabled,
          })}
          disabled={disabled}
          onClick={() => onClick?.(room)}
        >
          <RoomTitle paddingTop={!!(booking || hourlyBooking)}>
            {room.title}
          </RoomTitle>
          {guest && (
            <TimeInfo>
              <div className="uppercase font-xs text-black-lightest">
                {booking &&
                  [
                    formatDatetime(booking.arrival, 'DD MMM'),
                    formatDatetime(booking.departure, 'DD MMM'),
                  ].join(' - ')}
                {hourlyBooking && hourlyBooking.status === 'checked_in'
                  ? formatDiffTime(
                      hourlyBooking?.checkedInAt,
                      hourlyBooking?.checkedOutAt || currentTime,
                    )
                  : null}
              </div>
            </TimeInfo>
          )}
          {guest && (
            <div className="w-full px-2 mt-1 overflow-hidden truncate">
              <span className="text-sm capitalize text-grey-darker">
                {[guest.title, guest.firstName, guest.lastName]
                  .filter(Boolean)
                  .join(' ')}
              </span>
            </div>
          )}
        </Button>
      </InnerContainer>
    </OuterContainer>
  );
}

export default React.memo(RoomBlock, (prev, next) => {
  return (
    prev.housekeepingStatus === next.housekeepingStatus &&
    prev.room.bookings?.length === next.room.bookings?.length &&
    prev.room.hourlyBookings?.length === next.room.hourlyBookings?.length &&
    prev.currentTime === next.currentTime &&
    prev.disabled === next.disabled
  );
});
