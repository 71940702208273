if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
}

// @ts-ignore
if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
}

type Language = 'en' | 'vi';
type ObjType = { [k: string]: string };

const DEFAULT_LOCALE: Language = 'vi';
const appLocales = ['en', 'vi'];

export { appLocales, DEFAULT_LOCALE };

export async function getTranslationMessage(langCode: string = DEFAULT_LOCALE) {
  let langObj: ObjType = {};
  try {
    if (langCode === 'en') {
      langObj = await (await import('./translations/en.json')).default;
      if (!Intl.PluralRules) {
        require('@formatjs/intl-pluralrules/dist/locale-data/en'); // Add locale data for en
      }
    } else {
      // fallback to vietnamese
      langObj = await (await import('./translations/vi.json')).default;
      // @ts-ignore
      if (!Intl.RelativeTimeFormat) {
        require('@formatjs/intl-relativetimeformat/dist/locale-data/vi'); // Add locale data for vi
      }
    }
    return langObj;
  } catch (err) {
    console.error(err);
    return {};
  }
}
