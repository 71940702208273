import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import { Button, PopupConfirm, Select, Badge, NAFallback } from '@aha/ui';
import { FormattedMessage } from 'react-intl';
import { BookingBookingRoomGuestRequest } from 'types/v3-schema';
import { PermissionGate } from 'components/permission';
import { formatOrdinalNumber } from 'utils/formatOrdinalNumber';
import {
  BOOKING_STAUS_COLOR_MAP,
  BOOKING_STATUS_COLOR_MAP_TYPE,
} from 'utils/constants';
import { getMessage } from 'utils/messages/bookings';
import { BadgeColor } from '@aha/ui/src/components/Badge';
import styled from 'styled-components';
import tw from 'twin.macro';
import CopyToClipboard from 'components/CopyToClipboard';
import { isGroupFolio } from 'containers/BookingProvider/helpers/folios';
import { IconTrashAlt, IconPlus, IconEdit, IconRemoveCircle } from '@aha/icons';
import { BookingRoomDetail, Booking } from 'types/schema';

const Block = styled.div`
  ${tw`mr-4`};
  min-width: 3.875rem;
`;

export type BookingRoomProps = {
  className?: string;
  booking: Booking;
  room: BookingRoomDetail;
  roomsFolioBalance: Record<string, number>;
  index: number;

  // conditions
  isLateCheckout: boolean;
  isCheckoutAble: boolean;
  isFuncBtnDisabled: boolean;
  isModifying: boolean;

  //actions
  onSetAllocateRoom: (value: React.SetStateAction<any>) => void;
  onSetShowEditRoomType: (value: React.SetStateAction<any>) => void;
  onSetShowAddGuest: (value: React.SetStateAction<any>) => void;
  onSetEditGuest: (value: React.SetStateAction<any>) => void;
  onSetRoomToEdit: (value: React.SetStateAction<any>) => void;

  onRequestCheckin: (roomID?: string) => void;
  onRequestCheckout: (roomID?: string) => void;
  onRequestRemoveRoom: (roomID?: string) => void;
  onCancelChildBooking: (roomID?: string) => void;
  onAssignGuestBooking: (bkRoomGuest: BookingBookingRoomGuestRequest) => void;
  onUnassignGuestBooking: (bkRoomGuest: BookingBookingRoomGuestRequest) => void;
};

export const BookingRoomBlock: React.FC<BookingRoomProps> = ({
  booking,
  room,
  roomsFolioBalance,
  className = '',
  index,
  isCheckoutAble,
  isLateCheckout,
  isFuncBtnDisabled,
  isModifying,
  onSetAllocateRoom,
  onRequestCheckin,
  onRequestCheckout,
  onRequestRemoveRoom,
  onSetShowEditRoomType,
  onSetRoomToEdit,
  onCancelChildBooking,
  onSetShowAddGuest,
  onAssignGuestBooking,
  onSetEditGuest,
  onUnassignGuestBooking,
}) => {
  const roomID = room.id as number;
  const [assignGuest, setAssignGuest] = useState(undefined);

  const guestList = useMemo(() => {
    setAssignGuest(undefined);
    return (
      booking?.guests?.filter(
        (g) =>
          !booking.rooms
            ?.reduce(
              (guestIDs, r) => [
                ...guestIDs,
                ...(r.guests?.map((g) => Number(g.id)) || []),
              ],
              [] as number[],
            )
            .includes(Number(g.id)),
      ) || []
    );
  }, [booking]);

  const validRooms = (booking.rooms || []).filter(
    (r) => r.status !== 'cancelled',
  );

  const isRoomFuncDisabled = !['awaiting', 'checked_in'].includes(
    room.status as string,
  );

  const isRoomCIable =
    room.roomId && room.status === 'awaiting' && validRooms.length > 1;

  const isRoomCOable =
    !roomsFolioBalance[room.id?.toString() || ''] &&
    room.status === 'checked_in' &&
    validRooms.length > 1;

  const showCancelModal = booking?.folios?.some(
    (f) => f.bookingRoomId === room.id && isGroupFolio(f),
  );

  const isDisableBtn = isRoomFuncDisabled || isFuncBtnDisabled || isModifying;

  return (
    <div
      className={classnames(
        'bg-white rounded-sm overflow-hidden py-5 px-6 border-t border-grey-darker',
        {
          [className]: className,
          'bg-grey-lightest pointer-events-none': isRoomFuncDisabled,
        },
      )}
    >
      <section className="mb-6 flex flex-wrap justify-between">
        <div className="flex items-center">
          <Block className="text-base text-secondary font-medium">
            <FormattedMessage
              defaultMessage="{id} Room"
              id="bookings.labels.roomNumber"
              values={{
                id: formatOrdinalNumber(index + 1),
                number: index + 1,
              }}
            />
          </Block>
          <PermissionGate
            allow="pms.bookings.bookingrooms.changeRoomType"
            fallback={
              <Button size="small" color="secondary" disabled>
                <FormattedMessage
                  defaultMessage="Edit Room Type"
                  id="bookings.actions.editRoomType"
                />
              </Button>
            }
          >
            <Button
              size="small"
              color="secondary"
              onClick={() => onSetShowEditRoomType(room)}
              disabled={isDisableBtn}
            >
              <FormattedMessage
                defaultMessage="Edit Room Type"
                id="bookings.actions.editRoomType"
              />
            </Button>
          </PermissionGate>
        </div>
        <div className="flex items-center mt-4 md:mt-0">
          <span className="text-grey-darker">
            <FormattedMessage
              defaultMessage="Room ID: "
              id="bookings.labels.roomID"
            />
            <CopyToClipboard content={room.refCode as string}>
              {room.refCode}
            </CopyToClipboard>
          </span>
          <Badge
            color={
              BOOKING_STAUS_COLOR_MAP[
                room.status as BOOKING_STATUS_COLOR_MAP_TYPE
              ] as BadgeColor
            }
            className="mb-0 ml-3 rounded-xl"
          >
            <FormattedMessage
              {...getMessage(`bookings.status.${room.status}`)}
            />
          </Badge>
        </div>
      </section>
      <section className="mb-6">
        <header className="text-2xs uppercase font-medium leading-none">
          <FormattedMessage defaultMessage="Room" id="common.label.room" />
        </header>
        <div className="flex flex-wrap justify-between">
          <div className="flex items-center">
            <Block className="text-2xs text-purple font-semibold leading-none">
              <NAFallback value={room.roomName} />
            </Block>
            <PermissionGate
              allow="pms.bookings.allocateRoom"
              fallback={
                <Button
                  disabled
                  className="mr-3"
                  color={room.roomId ? 'secondary' : 'primary'}
                >
                  {room.roomId ? (
                    <FormattedMessage
                      defaultMessage="Change room"
                      id="bookings.actions.changeRoom"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Select Room"
                      id="bookings.actions.selectRoom"
                    />
                  )}
                </Button>
              }
            >
              <Button
                size="small"
                onClick={() =>
                  onSetAllocateRoom({
                    bookingId: room.id,
                    id: room.roomTypeId,
                    name: room.roomTypeName,
                    lockDoorRoomNo: room.lockDoorRoomNo,
                    status: room.status,
                  })
                }
                disabled={isDisableBtn}
                className="mr-3"
                color={room.roomId ? 'secondary' : 'primary'}
              >
                {room.roomId ? (
                  <FormattedMessage
                    defaultMessage="Change room"
                    id="bookings.actions.changeRoom"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Allocate"
                    id="bookings.actions.allocate"
                  />
                )}
              </Button>
            </PermissionGate>
            <PermissionGate
              allow="pms.bookings.removeRoom"
              fallback={
                <Button icon={IconTrashAlt} type="icon" size="small" disabled />
              }
            >
              <PopupConfirm
                title={
                  <FormattedMessage
                    defaultMessage="Are you sure remove room"
                    id="bookings.actions.removeRoom"
                  />
                }
                onConfirm={() => onRequestRemoveRoom(room.id?.toString())}
                okText={
                  <FormattedMessage
                    defaultMessage="Remove"
                    id="bookings.actions.remove"
                  />
                }
                cancelText={
                  <FormattedMessage
                    defaultMessage="Keep"
                    id="bookings.actions.keepRoom"
                  />
                }
                placement="topLeft"
                arrowPointAtCenter
              >
                <Button
                  icon={IconTrashAlt}
                  type="icon"
                  size="small"
                  disabled={
                    room.status !== 'awaiting' ||
                    isFuncBtnDisabled ||
                    !room.roomId // todo fixme for booking group
                  }
                />
              </PopupConfirm>
            </PermissionGate>
          </div>
          <div className="flex items-center mt-4 md:mt-0">
            {isRoomCIable && (
              <Button
                disabled={isFuncBtnDisabled || isModifying}
                onClick={() => onRequestCheckin(room.id?.toString())}
              >
                <FormattedMessage
                  defaultMessage="Check-in"
                  id="bookings.actions.checkin"
                />
              </Button>
            )}
            {isRoomCOable && (
              <PopupConfirm
                title={
                  <FormattedMessage
                    defaultMessage="Are you sure to checkout?"
                    id="bookings.label.checkout"
                  />
                }
                okText={
                  <FormattedMessage
                    defaultMessage="Check-out"
                    id="bookings.actions.checkout"
                  />
                }
                cancelText={
                  <FormattedMessage
                    defaultMessage="Cancel"
                    id="bookings.label.cancelBookingsDetail"
                  />
                }
                placement="topRight"
                arrowPointAtCenter
                onConfirm={() => onRequestCheckout(room.id?.toString())}
              >
                <Button
                  disabled={isLateCheckout || isFuncBtnDisabled || isModifying}
                >
                  <FormattedMessage
                    defaultMessage="Check-out"
                    id="bookings.actions.checkout"
                  />
                </Button>
              </PopupConfirm>
            )}
            {validRooms.length > 1 && room.status === 'awaiting' && (
              <PermissionGate
                allow="pms.bookings.bookingrooms.cancel"
                fallback={
                  <Button type="line" className="ml-4" disabled>
                    <FormattedMessage
                      defaultMessage="Cancel"
                      id="bookings.actions.cancel"
                    />
                  </Button>
                }
              >
                {showCancelModal ? (
                  <Button
                    type="line"
                    disabled={isDisableBtn}
                    className="ml-4"
                    onClick={() => onSetRoomToEdit(room)}
                  >
                    <FormattedMessage
                      defaultMessage="Cancel"
                      id="bookings.actions.cancel"
                    />
                  </Button>
                ) : (
                  <PopupConfirm
                    title={
                      <FormattedMessage
                        defaultMessage="Are you sure to cancel this booking?"
                        id="bookings.actions.cancelAChildBooking"
                      />
                    }
                    onConfirm={() => onCancelChildBooking(roomID.toString())}
                    arrowPointAtCenter
                    placement="topRight"
                  >
                    <Button
                      type="line"
                      disabled={isDisableBtn}
                      className="ml-4"
                    >
                      <FormattedMessage
                        defaultMessage="Cancel"
                        id="bookings.actions.cancel"
                      />
                    </Button>
                  </PopupConfirm>
                )}
              </PermissionGate>
            )}
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="flex flex-wrap items-center -mx-0 md:-mx-2">
          <div className="md:px-2 w-full md:w-1/3 uppercase font-semibold text-2xs text-secondary">
            <FormattedMessage
              defaultMessage="{total, plural, one {# guest} other {# guests}}: {adults, plural,=0 {# adult} one {# adult} other {# adults}} - {children, plural,=0 {# child} one {# child} other {# children}}"
              id="bookings.label.countAdultsChildren"
              values={{
                adults: room.adults,
                children: room.children,
                total: (room?.adults || 0) + (room?.children || 0),
              }}
            />
          </div>
          <div className="md:px-2 w-full md:w-1/3" />
          <div className="md:px-2 w-full md:w-1/3 mt-2 md:mt-0">
            <Select<string>
              disabled={isRoomFuncDisabled}
              showSearch
              value={assignGuest}
              className="w-full"
              placeholder={
                <FormattedMessage
                  defaultMessage="Add guest"
                  id="bookings.label.addGuest"
                />
              }
              onSelect={(guestId) => {
                onAssignGuestBooking({
                  bookingRoomId: roomID,
                  guestId: Number(guestId),
                });
              }}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Button
                    type="icon"
                    icon={IconPlus}
                    className="text-center text-sm font-medium w-full"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => onSetShowAddGuest(room)}
                  >
                    <FormattedMessage
                      defaultMessage="Add New Guest"
                      id="settings.label.addNewGuest"
                    />
                  </Button>
                </div>
              )}
              optionFilterProp="children"
            >
              {guestList.map((g) => (
                <Select.Option key={g.id} value={g.id}>
                  {g.fullName}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </section>
      <section>
        <div className="flex flex-wrap -mx-0 md:-mx-2">
          {room?.guests?.map((g, idx) => {
            const guestLen = room?.guests?.length || 0;
            return (
              <>
                <article key={g.id} className="md:px-2 w-full md:w-1/3 px-0">
                  <div
                    className={classnames('py-2', {
                      'border-b':
                        (guestLen % 2 === 0 && idx < guestLen - 2) ||
                        (guestLen % 2 === 1 && idx < guestLen - 1),
                    })}
                  >
                    <div className="mb-2 text-xs font-medium">
                      <FormattedMessage
                        defaultMessage="{ordinal} guests"
                        id="bookings.label.guestNumber"
                        values={{
                          count: idx + 1,
                          ordinal: formatOrdinalNumber(idx + 1),
                        }}
                      />
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="text-sm">{`${g.fullName}`}</div>
                      <div>
                        <Button
                          icon={IconEdit}
                          type="icon"
                          size="small"
                          className="text-base"
                          onClick={() => onSetEditGuest(g)}
                        />
                        <Button
                          icon={IconRemoveCircle}
                          type="icon"
                          className="text-base ml-2"
                          size="small"
                          onClick={() =>
                            onUnassignGuestBooking({
                              bookingRoomId: roomID,
                              guestId: Number(g?.id),
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </article>
                {idx % 2 === 0 && <div className="w-1/3 px-2" />}
              </>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default React.memo(BookingRoomBlock);
