import { put, takeLatest, call, all } from 'redux-saga/effects';
import { fetchCurrentHotelSuccess, fetchCurrentHotelFail } from '../actions';
import ActionTypes from '../constants';
import { coreAPI } from 'utils/request';
import { showErrorNotification } from '@aha/utils';
import { DashboardActions } from '../type';
import { ExtractAction } from 'types/actions';

export function* doFetchCurrentHotel(
  action: ExtractAction<DashboardActions, ActionTypes.FETCH_CURRENT_HOTEL>,
) {
  const { hotelId } = action.payload;

  try {
    const [{ data: hotel }, { data: currencies }] = yield all([
      call(coreAPI.get, `/v1/pms/hotels/${hotelId}`),
      call(coreAPI.get, `/v1/pms/hotels/${hotelId}/currencies`),
    ]);
    yield put(fetchCurrentHotelSuccess({ hotel, currencies }));
  } catch (err) {
    showErrorNotification('Fail to fetch', err);
    yield put(fetchCurrentHotelFail());
  }
}

export default function* watchFetchCurrentHotel() {
  yield takeLatest(ActionTypes.FETCH_CURRENT_HOTEL, doFetchCurrentHotel);
}
