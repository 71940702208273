import React, { useState, useMemo } from 'react';
import Avatar from '../Avatar';
import { Upload, Dropdown, Menu, Spin, Icon as AntIcon } from 'antd';
import { showErrorNotification } from '@aha/utils';
import styled from 'styled-components';
import tw from 'twin.macro';
import { ReactComponent as CameraIcon } from './camera.svg';
import { IconUserAlt } from '@aha/icons';

const Container = styled.div`
  display: inline-block;
  position: relative;
`;

const Overlay = styled.div<{ visible: boolean }>`
  ${tw`absolute w-full h-full rounded-full inset-0 inline-flex items-center justify-center`}
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: all 0.2s;
  &:hover {
    opacity: 1;
  }
`;

const UploadButton = styled.div<{ visible: boolean }>`
  ${tw`w-6 h-6 flex-none inline-flex items-center justify-center rounded-sm text-white`}
  background-color: rgba(0, 0, 0);
  opacity: ${(props) => (props.visible ? 1 : 0.3)};
  &:hover,
  &:focus {
    opacity: 1;
  }
`;

export interface ImageUploadProps {
  imgUrl?: string;
  onChange?: (file: any) => void;
  onRemove?: () => void;
  loading?: boolean;
  changeText?: string | React.ReactNode;
  removeText?: string | React.ReactNode;
  placeholder?: React.ReactNode;
  className?: string;
}

const DefaultPlaceholder = () => (
  <IconUserAlt className="text-5xl text-grey-darker" />
);

const antIcon = (
  <AntIcon type="loading" style={{ fontSize: 30, color: 'white' }} spin />
);

const ImageUpload = ({
  imgUrl,
  onChange,
  onRemove,
  loading = false,
  changeText = 'Change profile photo',
  removeText = 'Remove',
  placeholder = <DefaultPlaceholder />,
  className = '',
}: ImageUploadProps) => {
  const [menuVisible, setMenuVisible] = useState(false);

  function removeImage() {
    setMenuVisible(false);
    onRemove && onRemove();
  }

  const listMenu = useMemo(
    () => (
      <Menu className="mt-5 py-2 px-0 shadow">
        <Menu.Item
          className="hover:green-lighter px-3"
          key="change"
          disabled={loading}
          onClick={() => setMenuVisible(false)}
        >
          <div className="relative">
            <Upload
              accept="image/*"
              showUploadList={false}
              disabled={loading}
              //@ts-ignore
              customRequest={({ file, onSuccess }) => {
                if (file.size > 2000000) {
                  const err = new Error('Image size is not greater than 2 MB');
                  showErrorNotification('Can not upload image', err);
                  return false;
                }
                setTimeout(() => {
                  onSuccess('ok');
                }, 0);
              }}
              onChange={({ file }) => {
                if (file.status !== 'uploading') {
                  onChange && onChange(file.originFileObj);
                }
              }}
            >
              <div className="absolute inset-0 opacity-0"></div>
            </Upload>
            <span>{changeText}</span>
          </div>
        </Menu.Item>
        <Menu.Item
          className="hover:green-lighter px-3"
          key="remove"
          disabled={loading}
          onClick={removeImage}
        >
          {removeText}
        </Menu.Item>
      </Menu>
    ),
    [], // eslint-disable-line
  );

  return (
    <div className={className}>
      <Container>
        <Avatar imgUrl={imgUrl} placeholder={placeholder} />
        <Overlay visible={menuVisible || loading}>
          {loading ? (
            <Spin indicator={antIcon} />
          ) : (
            <Dropdown
              data-testid="img-upload-dropdow-btn"
              overlay={listMenu}
              className="avatar-upload"
              placement="bottomCenter"
              trigger={['click']}
              onVisibleChange={setMenuVisible}
            >
              <UploadButton visible={menuVisible}>
                <CameraIcon />
              </UploadButton>
            </Dropdown>
          )}
        </Overlay>
      </Container>
    </div>
  );
};

export default ImageUpload;
