import React from 'react';
import { Dispatch } from 'redux';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelectUser } from 'containers/Auth/selectors';
import { createStructuredSelector } from 'reselect';
import { logOut } from 'containers/Auth/actions';
import LanguageSelection from 'containers/LanguageProvider/LanguageSelection';
import ROUTES from 'constants/routes';
import { FormattedMessage } from 'react-intl';
import SwitchHotelDropdown from 'components/GlobalSidebar/SwitchHotelDropdown';
import { AvatarDropdown } from '@aha/ui';
import { AvatarDropdownProps } from '@aha/ui/src/components/AvatarDropdown';
import { ApplicationRootState } from 'types/app';
import CreateBookingButton from './components/CreateBookingButton';
import usePermission from 'components/permission/usePermission';
import { AuthMe } from 'types/schema';

interface ExtraHeaderProps {
  isOnMobile: boolean;
  collapsed: boolean;
  showSwitchHotel: boolean;
}

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    user: AuthMe | null;
  }
>({
  user: makeSelectUser(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logOut: () => dispatch(logOut()),
});

export function ExtraHeader({
  isOnMobile,
  collapsed,
  showSwitchHotel = true,
}: ExtraHeaderProps) {
  const dispatch = useDispatch();
  const { logOut } = mapDispatchToProps(dispatch);
  const { user } = useSelector(mapStateToProps);

  const avatarProps: AvatarDropdownProps = {
    avatar: user?.avatar,
    name: user?.name as string,
    email: user?.email as string,
    userAction: () => navigate(ROUTES.PROFILE),
    menu: [
      {
        text: (
          <FormattedMessage
            defaultMessage="Hotel List"
            id="common.label.hotelList"
          />
        ),
        action: () => navigate(ROUTES.HOTELS),
      },
      {
        text: (
          <FormattedMessage
            defaultMessage="My Profile"
            id="common.label.myProfile"
          />
        ),
        action: () => navigate(ROUTES.PROFILE),
      },
    ],
    logOut,
  };

  const displayCreateHotelMenuItem = usePermission('operation.hotels.create');
  if (displayCreateHotelMenuItem) {
    avatarProps.menu.unshift({
      text: (
        <FormattedMessage
          defaultMessage="Create Hotel"
          id="common.label.createHotel"
        />
      ),
      action: () => navigate(ROUTES.NEW_HOTEL),
    });
  }

  return (
    <div className="flex items-center">
      {showSwitchHotel && (
        <SwitchHotelDropdown isOnMobile={isOnMobile} collapsed={collapsed} />
      )}
      <CreateBookingButton isOnMobile={isOnMobile} />
      <LanguageSelection abbr className="mr-5" />
      <AvatarDropdown {...avatarProps} />
    </div>
  );
}

export default ExtraHeader;
