declare global {
  interface Window {
    AHA: any;
  }
}

export default function injectAppContext() {
  if (typeof window === 'object') {
    window.AHA = {
      name: process.env.REACT_APP_NAME,
      stage: process.env.REACT_APP_STAGE,
      version: process.env.REACT_APP_PACKAGE_CURRENT_VERSION,
    };

    if (process.env.REACT_APP_PR_LINK) {
      window.AHA.pr = process.env.REACT_APP_PR_LINK;
    } else if (process.env.STAGE !== 'development') {
      window.AHA.version = process.env.REACT_APP_TAG_CURRENT_VERSION;
    }
  }
}
