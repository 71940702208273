import { defineMessages } from 'react-intl';

const messages: Record<
  string,
  { id: string; defaultMessage: string }
> = defineMessages({
  'bookings.status.undefined': {
    id: 'bookings.status.undefined',
    defaultMessage: 'undefined',
  },

  'bookings.status.': {
    id: 'bookings.status.undefined',
    defaultMessage: 'undefined',
  },

  'bookings.status.null': {
    id: 'bookings.status.undefined',
    defaultMessage: 'undefined',
  },
  'bookings.status.arrival': {
    id: 'bookings.status.arrival',
    defaultMessage: 'Expected arrival',
  },
  'bookings.status.departure': {
    id: 'bookings.status.departure',
    defaultMessage: 'Expected departure',
  },
  'bookings.status.inhouse': {
    id: 'bookings.status.inhouse',
    defaultMessage: 'In house',
  },
  'bookings.status.checked_in': {
    id: 'bookings.status.checked_in',
    defaultMessage: 'Checked-in',
  },
  'bookings.status.expired': {
    id: 'bookings.status.expired',
    defaultMessage: 'Expired',
  },
  'bookings.status.expected_arrival': {
    id: 'bookings.status.expected_arrival',
    defaultMessage: 'Expected arrival',
  },
  'bookings.status.expected_departure': {
    id: 'bookings.status.expected_departure',
    defaultMessage: 'Expected departure',
  },

  'bookings.status.checkedout': {
    id: 'bookings.status.checkedout',
    defaultMessage: 'Checked-out',
  },

  'bookings.status.overstay': {
    id: 'bookings.status.overstay',
    defaultMessage: 'Overstay',
  },

  'bookings.status.checkedin': {
    id: 'bookings.status.checkedin',
    defaultMessage: 'Checked-in',
  },

  'bookings.status.awaiting_checkin': {
    id: 'bookings.status.awaiting_checkin',
    defaultMessage: 'Awaiting Check-in',
  },

  'bookings.status.pending_checkout': {
    id: 'bookings.status.pending_checkout',
    defaultMessage: 'Pending Check-out',
  },

  'bookings.status.checking_in': {
    id: 'bookings.status.checking_in',
    defaultMessage: 'Checking-in',
  },

  'bookings.status.awaiting': {
    id: 'bookings.status.awaiting',
    defaultMessage: 'Awaiting',
  },

  'bookings.status.checked_out': {
    id: 'bookings.status.checked_out',
    defaultMessage: 'Checked-out',
  },

  'bookings.status.voided': {
    id: 'bookings.status.voided',
    defaultMessage: 'Voided',
  },

  'bookings.status.no_show': {
    id: 'bookings.status.no_show',
    defaultMessage: 'No show',
  },

  'bookings.status.no_show_charge': {
    id: 'bookings.status.no_show_charge',
    defaultMessage: 'No show charge',
  },

  'bookings.status.cancelled': {
    id: 'bookings.status.cancelled',
    defaultMessage: 'Cancelled',
  },

  'bookings.status.bot_auto_cancelled': {
    id: 'bookings.status.bot_auto_cancelled',
    defaultMessage: 'Bot auto cancelled',
  },

  'bookings.status.payment.paid': {
    id: 'bookings.status.payment.paid',
    defaultMessage: 'Paid',
  },

  'bookings.label.bookingChangeLogs': {
    id: 'bookings.label.bookingChangeLogs',
    defaultMessage: 'Booking change logs',
  },

  'bookings.changeLogs.update_payment': {
    id: 'bookings.changeLogs.update_payment',
    defaultMessage: 'Update payment',
  },

  'bookings.changeLogs.create': {
    id: 'bookings.changeLogs.create',
    defaultMessage: 'New booking',
  },

  'bookings.changeLogs.add_folio': {
    id: 'bookings.changeLogs.add_folio',
    defaultMessage: 'Add folio',
  },

  'bookings.changeLogs.void_folio': {
    id: 'bookings.changeLogs.void_folio',
    defaultMessage: 'Void folios',
  },

  'bookings.changeLogs.update': {
    id: 'bookings.changeLogs.update',
    defaultMessage: 'Update booking',
  },

  'bookings.changeLogs.add_charge': {
    id: 'bookings.changeLogs.add_charge',
    defaultMessage: 'Add charge',
  },

  'bookings.changeLogs.update_charge': {
    id: 'bookings.changeLogs.update_charge',
    defaultMessage: 'Update charge',
  },

  'bookings.changeLogs.update_period': {
    id: 'bookings.changeLogs.update_period',
    defaultMessage: 'Update period',
  },

  'bookings.changeLogs.allocate_room': {
    id: 'bookings.changeLogs.allocate_room',
    defaultMessage: 'Update room',
  },

  'bookings.changeLogs.status_booking': {
    id: 'bookings.changeLogs.status_booking',
    defaultMessage: 'Update booking',
  },

  'bookings.changeLogs.add_payment': {
    id: 'bookings.changeLogs.add_payment',
    defaultMessage: 'Add payment',
  },

  'bookings.changeLogs.void_payment': {
    id: 'bookings.changeLogs.void_payment',
    defaultMessage: 'Void payment',
  },

  'bookings.changeLogs.void_charge': {
    id: 'bookings.changeLogs.void_charge',
    defaultMessage: 'Void charge',
  },

  'bookings.changeLogs.void_additional_service': {
    id: 'bookings.changeLogs.void_additional_service',
    defaultMessage: 'Void hourly booking',
  },

  'bookings.label.editCharge': {
    id: 'bookings.label.editCharge',
    defaultMessage: 'Edit charge',
  },

  'bookings.label.editDiscount': {
    id: 'bookings.label.editDiscount',
    defaultMessage: 'Edit discount',
  },
  'bookings.label.addDiscount': {
    id: 'bookings.label.addDiscount',
    defaultMessage: 'Add discount',
  },

  'bookings.label.addCharge': {
    id: 'bookings.label.addCharge',
    defaultMessage: 'Add charge',
  },
  'bookings.label.addRefund': {
    id: 'bookings.label.addRefund',
    defaultMessage: 'Add refund',
  },

  'bookings.label.addPayment': {
    id: 'bookings.label.addPayment',
    defaultMessage: 'Add payment',
  },

  'bookings.label.todayCheckedin': {
    id: 'bookings.label.todayCheckedin',
    defaultMessage: 'Today checked in',
  },

  'bookings.label.todayCheckedout': {
    id: 'bookings.label.todayCheckedout',
    defaultMessage: 'Today checked out',
  },
});

export default messages;

export function getMessage(id: string) {
  //@ts-ignore
  const m = messages[id];
  if (m) {
    return m;
  }
  return {
    id: 'bookings.status.undefined',
    defaultMessage: 'undefined',
  };
}
