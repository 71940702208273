import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Form, AutoComplete } from 'antd';
import { Option } from '../Select';
import styled from 'styled-components';

interface AntFieldProps<FieldValue> extends FieldRenderProps<FieldValue, any> {
  hasFeedback?: boolean;
  label: string;
  typing?: boolean;
  selectOptions?: { name: string; value: number | string }[];
  autoComplete?: boolean;
  required?: boolean;
  help?: React.ReactNode;
  dateFormat?: string;
  noTouch?: boolean;
}

export const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    font-size: 0.6875rem;
    padding-bottom: 4px !important;
    overflow: visible;
    label:after {
      display: none;
    }
  }
`;

export function createAntField<T, FieldValue>(
  AntComponent: React.Component<T, FieldValue>,
) {
  return ({
    hasFeedback = false,
    label,
    typing = false,
    selectOptions,
    meta,
    input,
    autoComplete = false,
    required = false,
    help,
    dateFormat,
    noTouch,
    ...props
  }: AntFieldProps<FieldValue>) => {
    const { onChange, value, type, name } = input;
    const showError = !!(meta.error && (meta.touched || noTouch));

    const onInputChange = ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => onChange(value);

    const handleChange = (value: string) => onChange(value);

    return (
      <FormItem
        label={label}
        required={required}
        help={help || (showError ? meta.error || meta.submitError : undefined)}
        validateStatus={showError ? 'error' : ''}
      >
        {
          // @ts-ignore
          <AntComponent
            {...props}
            type={type}
            name={name}
            format={dateFormat}
            value={selectOptions && !value ? undefined : value}
            onChange={typing ? onInputChange : handleChange}
          >
            {selectOptions &&
              selectOptions.map(({ name, value }) =>
                !autoComplete ? (
                  <Option key={value}>{name}</Option>
                ) : (
                  <AutoComplete.Option key={value}>{name}</AutoComplete.Option>
                ),
              )}
          </AntComponent>
        }
      </FormItem>
    );
  };
}

export default createAntField;
