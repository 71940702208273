import { takeLatest, put, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { updateBookingInfoSuccess, updateBookingInfoFail } from '../actions';
import { UPDATE_BOOKING_INFO } from '../constants';
import { showErrorNotification } from '@aha/utils';

function* doUpdateInfo({ bid, reqData, reject, resolve }) {
  try {
    // TODO update this with new API in backend
    const { data } = yield call(coreAPI.put, `v1/pms/bookings/${bid}`, reqData);
    yield put(updateBookingInfoSuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification('Update booking info failed', err);
    yield put(updateBookingInfoFail(err));
    reject(err);
  }
}

export function* watchUpdateBookingInfo() {
  yield takeLatest(UPDATE_BOOKING_INFO, doUpdateInfo);
}
