enum ActionTypes {
  LOCK_DOOR_KEY = 'lockDoor',
  LOCK_DOOR_SERVICE_STATUS = 'lockDoor/LOCK_DOOR_SERVICE_STATUS',
  LOCK_DOOR_SERVICE_IS_AVAILABLE = 'lockDoor/LOCK_DOOR_SERVICE_IS_AVAILABLE',
  LOCK_DOOR_SERVICE_IS_NOT_AVAILABLE = 'lockDoor/LOCK_DOOR_SERVICE_IS_NOT_AVAILABLE',

  CHANGE_LOCK_SYSTEM = 'lockDoor/CHANGE_LOCK_SYSTEM',

  START_SERVICE = 'lockDoor/START_SERVICE',
  START_SERVICE_SUCCESS = 'lockDoor/START_SERVICE_SUCCESS',
  START_SERVICE_ERROR = 'lockDoor/START_SERVICE_ERROR',

  STOP_SERVICE = 'lockDoor/STOP_SERVICE',
  STOP_SERVICE_SUCCESS = 'lockDoor/STOP_SERVICE_SUCCESS',
  STOP_SERVICE_ERROR = 'lockDoor/STOP_SERVICE_ERROR',

  CHECKIN_LOCK_DOOR = 'lockDoor/CHECKIN_LOCK_DOOR',
  CHECKIN_LOCK_DOOR_SUCCESS = 'lockDoor/CHECKIN_LOCK_DOOR_SUCCESS',
  CHECKIN_LOCK_DOOR_ERROR = 'lockDoor/CHECKIN_LOCK_DOOR_ERROR',

  DUPKEY_LOCK_DOOR = 'lockDoor/DUPKEY_LOCK_DOOR',
  DUPKEY_LOCK_DOOR_SUCCESS = 'lockDoor/DUPKEY_LOCK_DOOR_SUCCESS',
  DUPKEY_LOCK_DOOR_ERROR = 'lockDoor/DUPKEY_LOCK_DOOR_ERROR',

  CHECKOUT_LOCK_DOOR = 'lockDoor/CHECKOUT_LOCK_DOOR',
  CHECKOUT_LOCK_DOOR_SUCCESS = 'lockDoor/CHECKOUT_LOCK_DOOR_SUCCESS',
  CHECKOUT_LOCK_DOOR_ERROR = 'lockDoor/CHECKOUT_LOCK_DO',

  READ_CARD_LOCK_DOOR = 'lockDoor/READ_CARD_LOCK_DOOR',
  READ_CARD_LOCK_DOOR_SUCCESS = 'lockDoor/READ_CARD_LOCK_DOOR_SUCCESS',
  READ_CARD_LOCK_DOOR_ERROR = 'lockDoor/READ_CARD_LOCK_DOOR_ERROR',

  REPORT_LOST_CARD_LOCK_DOOR = 'lockDoor/REPORT_LOST_CARD_LOCK_DOOR',
  REPORT_LOST_CARD_LOCK_DOOR_SUCCESS = 'lockDoor/REPORT_LOST_CARD_LOCK_DOOR_SUCCESS',
  REPORT_LOST_CARD_LOCK_DOOR_ERROR = 'lockDoor/REPORT_LOST_CARD_LOCK_DOOR_ERROR',

  SET_LOCAL_START_SERVICE = 'lockDoor/SET_LOCAL_START_SERVICE',
  LOCK_DOOR_RESET_READ_STATE = 'lockDoor/LOCK_DOOR_RESET_READ_STATE',
}

export default ActionTypes;
