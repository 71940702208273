import React from 'react';
import { Drawer as AntDrawer } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';

export const Drawer: React.FC<DrawerProps> = ({
  children,
  bodyStyle,
  ...props
}) => {
  return (
    <AntDrawer
      {...props}
      bodyStyle={{
        ...bodyStyle,
        backgroundColor: 'var(--grey-lighter)',
      }}
    >
      {children}
    </AntDrawer>
  );
};

export default Drawer;
