import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { Dropdown, Menu, Divider, Button as ButtonAnt } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';

import { useInjectSaga } from 'utils/injectSaga';
import saga from './saga';
import hbkReducer from './reducer';
import { useInjectReducer } from 'utils/injectReducer';
import { makeSelectHourlyBooking } from './selector';
import {
  fetchHourlyBooking,
  voidHourlyBookingPayment,
  voidHourlyBookingCharge,
  voidHourlyBooking,
  checkoutHourlyBooking,
  changeHourlyBookingRoom,
  removeBookingDetail,
} from './actions';
import { currencyFormater } from 'utils/currencyFormater';
import { formatDiffTime } from 'utils/formatDiffTime';
import AddChargeModal from './components/AddChargeModal';
import AddPaymentModal from './components/AddPaymentModal';
import { HOURLY_BOOKING_KEY } from './constants';
import HourlyBookingChangeLogModal from './components/HourlyBookingChangeLogModal';
import { makeSelectCurrentHotel } from '../Dashboard/selectors';
import LockDoorAPI from '../BookingProvider/loockdoor';
import { PermissionGate } from 'components/permission';
import { BOOKING_STAUS_COLOR_MAP } from 'utils/constants';
import {
  Button,
  TextBlock,
  Card,
  Badge,
  Table,
  PopupConfirm,
  PageHeader,
  DateTimeFormatter,
} from '@aha/ui';
import styled from 'styled-components';
import messages from 'utils/messages/bookings';
import tw from 'twin.macro';
import AddDiscountModal from './components/AddDiscountModal';
import AddRefundModal from './components/AddRefundModal';
import AvailableRoomsModal from 'components/AvailableRoomsModal';
import { makeSelectRooms } from 'containers/RoomPlanPage/selector';
import { fetchRefreshRooms } from 'containers/RoomPlanPage/actions';
import roomPlanReducer from 'containers/RoomPlanPage/reducer';
import roomPlanSaga from 'containers/RoomPlanPage/saga';
import SkeletonTable from 'components/skeleton/TableSkeletonScreen';
import { calcTotalCharge } from './helpers/calcTotalCharge';
import { calcTotalPayment } from './helpers/calcTotalPayment';
import { useMedia } from '@aha/utils';
import LoadScreenSwitcher from 'containers/Dashboard/LoadScreenSwitcher';
import ROUTES from 'constants/routes';
import { VIP_MEMBER } from 'constants/membership';
import { CHECKED_OUT } from 'containers/BookingProvider/helpers/useTab';
import { coreAPI } from 'utils/request';
import { showErrorNotification } from '@aha/utils';
import LMembershipBadge from 'components/LMembershipBadge';
import { IconCoin, IconEllipsisV, IconHistory, IconPlus } from '@aha/icons';

const mapStateToProps = createStructuredSelector({
  booking: makeSelectHourlyBooking(),
  hotel: makeSelectCurrentHotel(),
  rooms: makeSelectRooms(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchHourlyBooking: (id) => dispatch(fetchHourlyBooking(id)),
    doVoidPayment: (bid, pid) =>
      new Promise((resolve, reject) =>
        dispatch(voidHourlyBookingPayment(bid, pid, resolve, reject)),
      ),
    doVoidCharge: (bid, cid) =>
      new Promise((resolve, reject) =>
        dispatch(voidHourlyBookingCharge(bid, cid, resolve, reject)),
      ),
    doVoidBooking: (booking, lockdoor) =>
      new Promise((resolve, reject) =>
        dispatch(voidHourlyBooking(booking, resolve, reject, lockdoor)),
      ),
    doCheckout: (bid, lockdoor) =>
      new Promise((resolve, reject) =>
        dispatch(checkoutHourlyBooking(bid, resolve, reject, lockdoor)),
      ),
    doChangeRoom: (booking, chargeID, roomID, lockdoorRoomNo) =>
      new Promise((resolve, reject) =>
        dispatch(
          changeHourlyBookingRoom(
            booking,
            chargeID,
            roomID,
            lockdoorRoomNo,
            resolve,
            reject,
          ),
        ),
      ),
    doRefreshRoomList: () => dispatch(fetchRefreshRooms()),
    doRemoveHourlyBooking: () => dispatch(removeBookingDetail()),
  };
};
const MenuItem = styled(Menu.Item)`
  padding: 0 !important;
`;

const ButtonStyled = styled(ButtonAnt)`
  ${tw`w-full text-left border-0 shadow-none`}
  outline: none !important;
  border: none !important;
`;

export function HourlyBooking(props) {
  const dispatch = useDispatch();
  const { booking, hotel, rooms } = useSelector(mapStateToProps);
  const {
    doCheckout,
    doVoidCharge,
    doFetchHourlyBooking,
    doVoidPayment,
    doVoidBooking,
    doChangeRoom,
    doRefreshRoomList,
    doRemoveHourlyBooking,
  } = mapDispatchToProps(dispatch);
  useInjectReducer({ key: HOURLY_BOOKING_KEY, reducer: hbkReducer });
  useInjectSaga({ key: HOURLY_BOOKING_KEY, saga });

  useInjectReducer({ key: 'roomPlan', reducer: roomPlanReducer });
  useInjectSaga({ key: 'roomPlan', saga: roomPlanSaga });

  const { bid, room: roomStatusComp } = props;

  const [isDirty, setIsDirty] = useState(false);
  const [showAddCharge, setShowAddCharge] = useState(false);
  const [showAddDiscount, setShowAddDiscount] = useState(false);
  const [showAddPayment, setShowAddPayment] = useState(false);
  const [showAddRefund, setShowAddRefund] = useState(false);
  const [showChangeLogs, setShowChangeLogs] = useState(false);
  const [isModifying, setIsModifying] = useState(false);
  const [allocateRoomCharge, setAllocateRoomCharge] = useState(null);
  const [earnedCoins, setEarnedCoins] = useState(0);
  const [currentTime, setCurrentTime] = useState(moment());

  const intervalRef = useRef(-1);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setCurrentTime(moment());
    }, 60000);
    intervalRef.current = intervalID;
    return () => clearInterval(intervalRef.current);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const intl = useIntl();
  const isOnMobile = useMedia('md');

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    const waiting = 300;

    const timer = setTimeout(() => {
      if (bid && (booking.id !== bid || booking.refCode !== bid)) {
        doFetchHourlyBooking(bid);
      }
    }, waiting);

    return () => clearTimeout(timer);
  }, [bid]); // eslint-disable-line

  useEffect(() => {
    if (!rooms) {
      doRefreshRoomList();
    }
  }, [rooms]); //eslint-disable-line

  useEffect(() => {
    if (isDirty) {
      doRefreshRoomList();
      setIsDirty(false);
    }
  }, [isDirty]); // eslint-disable-line

  useEffect(() => {
    return () => doRemoveHourlyBooking();
  }, []); //eslint-disable-line

  const roomCharge = useMemo(() => {
    const rc = (booking.charges || []).find(
      (c) => c.status === 'active' && c.room?.id,
    );
    if (rc) {
      return rc;
    }
    return (booking.charges || []).find((c) => c.room?.id);
  }, [booking.charges]);

  const showLoyalty = hotel?.isEnableLoyalty;

  const { coinValuation = 0 } = booking?.coinUsage || {};
  const { rank, bookingCount } = booking?.membership || {};
  const loyaltyBk =
    (bookingCount > 2 ||
      (bookingCount === 2 && booking?.status !== CHECKED_OUT)) &&
    showLoyalty;

  const { totalCharge, currency } = calcTotalCharge(booking.charges);
  const { totalPaid, paidCurrency } = calcTotalPayment(booking.payments);
  const actualTotalPaid = totalPaid - coinValuation;

  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    async function fetchCoinWillBeEarned(priceRaw) {
      try {
        const { totalCoin } = await coreAPI.post(
          `v1/pms/memberships/earning-preview`,
          {
            isHourlyBooking: true,
            roomCharge: priceRaw,
            serviceCharge: 0,
          },
        );
        setEarnedCoins(totalCoin);
      } catch (e) {
        showErrorNotification(e);
      }
    }

    if (loyaltyBk) {
      fetchCoinWillBeEarned(actualTotalPaid);
    }
  }, [actualTotalPaid, booking.id, loyaltyBk]);

  function onCloseModal(f5) {
    if (f5 === true) {
      doFetchHourlyBooking(bid);
    }
    if (showAddCharge) {
      setShowAddCharge(false);
    }
    if (showAddPayment) {
      setShowAddPayment(false);
    }
    if (showAddDiscount) {
      setShowAddDiscount(false);
    }
    if (showAddRefund) {
      setShowAddRefund(false);
    }
  }

  async function requestVoidCharge(cid) {
    try {
      setIsModifying(true);
      await doVoidCharge(booking.id, cid);
      doFetchHourlyBooking(bid);
    } catch (err) {
      console.error(err);
    } finally {
      setIsModifying(false);
    }
  }

  async function requestVoidPayment(pid) {
    try {
      setIsModifying(true);
      await doVoidPayment(booking.id, pid);
      doFetchHourlyBooking(bid);
    } catch (err) {
      console.error(err);
    } finally {
      setIsModifying(false);
    }
  }

  async function requestVoidBooking() {
    try {
      setIsModifying(true);
      await doVoidBooking(booking, hotel.isEnabledLockdoor);
      doFetchHourlyBooking(bid);
    } catch (err) {
      console.error(err);
    } finally {
      setIsModifying(false);
    }
  }

  function onRequestMakePayment() {
    setIsModifying(true);
    doFetchHourlyBooking(bid); // f5 data
    setShowAddPayment(true);
    setIsModifying(false);
  }

  async function requestCheckout() {
    try {
      setIsModifying(true);
      await doCheckout(booking, hotel.isEnabledLockdoor);
      setIsDirty(true);
      doFetchHourlyBooking(bid); // f5 data
    } catch (err) {
      console.error(err);
    } finally {
      setIsModifying(false);
    }
  }

  async function requestChangeRoom(roomId, roomypeId, room) {
    try {
      setIsModifying(true);
      await doChangeRoom(
        booking,
        roomCharge.id,
        room.id,
        hotel.isEnabledLockdoor && (room.lockDoorRoomNo || room.lockDoorRoomNo),
      );
      setIsDirty(true);
      setAllocateRoomCharge(null);
      doFetchHourlyBooking(bid); // f5 data
    } catch (err) {
      console.error(err);
    } finally {
      setIsModifying(false);
    }
  }

  async function onRequestDupKey() {
    try {
      setIsModifying(true);
      const reqBK = {
        ...booking,
        Arrival: booking?.serviceStartAt,
        Departure: moment(booking?.serviceStartAt).add(1, 'day'),
        Room: roomCharge?.room,
        Guest: {
          FirstName: booking.guests[0]?.fullName || 'Unknown',
        },
      };
      await LockDoorAPI.dupKey(reqBK, roomCharge?.room?.lockDoorRoomNo);
    } catch (err) {
      console.error(err);
    } finally {
      setIsModifying(false);
    }
  }

  async function onReportLostCard() {
    try {
      setIsModifying(true);
      const roomCharge = booking.charges.find(
        (c) => c.room && c.status === 'active',
      );
      await LockDoorAPI.lostCard(roomCharge?.room?.lockDoorRoomNo);
    } catch (err) {
      console.error(err);
    } finally {
      setIsModifying(false);
    }
  }

  const roomDisabled = useMemo(
    () =>
      (rooms || [])
        .filter((r) => {
          if (!r.roomType?.hourlySettings || r.housekeepingStatus !== 'clean') {
            return r.id;
          }

          const hourlyBk = (r?.hourlyBookings || []).find((bk) => {
            if (
              ['checked_in', 'checking_in', 'pending_checkout'].includes(
                bk.status,
              )
            ) {
              return !!bk.rooms.find(
                (br) => br.status === 'checked_in' && r.id === br.roomId,
              );
            }
            return false;
          });

          if (hourlyBk) {
            return r.id;
          }

          const dailyBk = (r.bookings || []).find((bk) => {
            if (
              ['checked_in', 'checking_in', 'pending_checkout'].includes(
                bk.status,
              )
            ) {
              return !!bk.rooms.find(
                (br) => br.status === 'checked_in' && r.id === br.roomId,
              );
            }
            return false;
          });
          if (dailyBk) {
            return r.id;
          }
          return null;
        })
        .map((r) => r.id),
    [rooms],
  );

  const isDisabledFunc = ['voided', 'checked_out', 'awaiting'].includes(
    booking?.status,
  );
  const isAddFuncDisabled = [
    'voided',
    'pending_checkout',
    'awaiting',
    'checked_out',
  ].includes(booking?.status);

  const chargeCols = [
    {
      title: 'Name',
      dataIndex: 'serviceName',
      width: '30%',
      render(txt, rec) {
        return <TextBlock title={txt} subTitle={rec.notes} />;
      },
    },
    {
      title: 'Date',
      width: '30%',
      dataIndex: 'fmtChargeDate',
    },
    {
      title: 'Amount',
      dataIndex: 'fmtChargeAmount',
    },
    {
      width: 150,
      key: 'chargeActions',
      render: (text, c) => (
        <div className="flex items-center content-center">
          {c.room?.id ? (
            <PermissionGate
              allow="pms.hourlyBookings.voidCharge"
              // allow="hourlyBookings.changeRoom"
              fallback={
                <Button type="line" block size="small" disabled>
                  <FormattedMessage
                    defaultMessage="Change room"
                    id="bookings.actions.changeRoom"
                  />
                </Button>
              }
            >
              <Button
                type="line"
                disabled={
                  isDisabledFunc ||
                  c.status !== 'active' ||
                  isModifying ||
                  booking.status === 'pending_checkout'
                }
                color="red"
                size="small"
                block
                onClick={() => setAllocateRoomCharge(c.room)}
              >
                <FormattedMessage
                  defaultMessage="Change room"
                  id="bookings.actions.changeRoom"
                />
              </Button>
            </PermissionGate>
          ) : (
            <PermissionGate
              allow="pms.hourlyBookings.voidCharge"
              fallback={
                <Button type="line" block size="small" disabled>
                  <FormattedMessage
                    defaultMessage="Void"
                    id="common.actions.void"
                  />
                </Button>
              }
            >
              <PopupConfirm
                title="Void this charge?"
                onConfirm={() => requestVoidCharge(c.id)}
                okButtonProps={{
                  disabled:
                    isDisabledFunc || c.room?.id || c.status !== 'active',
                }}
                placement="topRight"
                arrowPointAtCenter
              >
                <Button
                  type="line"
                  disabled={
                    isDisabledFunc ||
                    c.room?.id ||
                    c.status !== 'active' ||
                    isModifying
                  }
                  color="red"
                  size="small"
                  block
                >
                  <FormattedMessage
                    defaultMessage="Void"
                    id="common.actions.void"
                  />
                </Button>
              </PopupConfirm>
            </PermissionGate>
          )}
        </div>
      ),
    },
  ];
  const paymentCols = [
    {
      title: 'Type',
      dataIndex: 'paymentName',
      width: '30%',
      render(txt, rec) {
        return <TextBlock title={txt} subTitle={rec.notes} />;
      },
    },
    {
      title: 'Date',
      width: '30%',
      dataIndex: 'fmtPaidDate',
    },
    {
      title: 'Amount',
      dataIndex: 'fmtPaymentAmount',
    },
    {
      width: 150,
      key: 'paymentActions',
      render: (text, p) => (
        <PermissionGate
          allow="pms.hourlyBookings.voidPayment" // receptionist can void hourly booking payment
          fallback={
            <Button disabled block type="line" size="small">
              <FormattedMessage
                defaultMessage="Void"
                id="common.actions.void"
              />
            </Button>
          }
        >
          <PopupConfirm
            title="Void this payment?"
            onConfirm={() => requestVoidPayment(p.id)}
            placement="topRight"
            arrowPointAtCenter
            okButtonProps={{
              disabled: isDisabledFunc || p.status !== 'active',
            }}
          >
            <Button
              disabled={isDisabledFunc || p.status !== 'active' || isModifying}
              type="line"
              color="red"
              size="small"
              block
            >
              <FormattedMessage
                defaultMessage="Void"
                id="common.actions.void"
              />
            </Button>
          </PopupConfirm>
        </PermissionGate>
      ),
    },
  ];

  const moreActions = (
    <div
      className={classnames('flex items-center mb-2 mt-2 lg:mt-0', {
        'justify-end w-full': isOnMobile,
      })}
    >
      {booking.status === 'checked_in' && (
        <PermissionGate
          allow="pms.hourlyBookings.addPayment"
          fallback={
            <Button type="primary" disabled>
              <FormattedMessage
                defaultMessage="Make payment"
                id="hourlyBookings.actions.makePayment"
              />
            </Button>
          }
        >
          <Button type="primary" onClick={onRequestMakePayment}>
            <FormattedMessage
              defaultMessage="Make payment"
              id="hourlyBookings.actions.makePayment"
            />
          </Button>
        </PermissionGate>
      )}
      {booking.status === 'pending_checkout' && (
        <PermissionGate
          allow="pms.hourlyBookings.checkout"
          fallback={
            <Button type="primary" disabled>
              <FormattedMessage
                defaultMessage="Check-out"
                id="bookings.actions.checkout"
              />
            </Button>
          }
        >
          <PopupConfirm
            title={
              <FormattedMessage
                defaultMessage="Are you sure to checkout?"
                id="bookings.label.checkout"
              />
            }
            okText={
              <FormattedMessage
                defaultMessage="Check-out"
                id="bookings.actions.checkout"
              />
            }
            cancelText={
              <FormattedMessage
                defaultMessage="Cancel"
                id="bookings.label.cancelBookingsDetail"
              />
            }
            placement="topRight"
            arrowPointAtCenter
            onConfirm={requestCheckout}
          >
            <Button type="primary">
              <FormattedMessage
                defaultMessage="Check-out"
                id="bookings.actions.checkout"
              />
            </Button>
          </PopupConfirm>
        </PermissionGate>
      )}
      <Dropdown
        overlay={
          <Menu className="p-0">
            <MenuItem
              disabled={isDisabledFunc}
              className="px-4 py-2"
              onClick={requestVoidBooking}
            >
              <PermissionGate
                allow="pms.hourlyBookings.void"
                fallback={
                  <ButtonStyled block disabled>
                    <FormattedMessage
                      defaultMessage="Void"
                      id="common.actions.void"
                    />
                  </ButtonStyled>
                }
              >
                <ButtonStyled block disabled={isDisabledFunc}>
                  <FormattedMessage
                    defaultMessage="Void"
                    id="common.actions.void"
                  />
                </ButtonStyled>
              </PermissionGate>
            </MenuItem>
            <MenuItem>
              <PermissionGate
                allow="pms.hourlyBookings.create"
                fallback={
                  <ButtonStyled block disabled>
                    <FormattedMessage
                      defaultMessage="Duplicate key"
                      id="lockdoor.actions.dupkey"
                    />
                  </ButtonStyled>
                }
              >
                <ButtonStyled
                  block
                  disabled={!hotel.isEnabledLockdoor}
                  className="px-4 py-2"
                  onClick={onRequestDupKey}
                >
                  <FormattedMessage
                    defaultMessage="Duplicate key"
                    id="lockdoor.actions.dupkey"
                  />
                </ButtonStyled>
              </PermissionGate>
            </MenuItem>
            <MenuItem
              disabled={!hotel.isEnabledLockdoor}
              onClick={onReportLostCard}
            >
              <PermissionGate
                allow="pms.hourlyBookings.create"
                fallback={
                  <ButtonStyled block disabled>
                    <FormattedMessage
                      defaultMessage="Report lost card"
                      id="lockdoor.actions.lostCard"
                    />
                  </ButtonStyled>
                }
              >
                <ButtonStyled
                  block
                  disabled={!hotel.isEnabledLockdoor}
                  onClick={onReportLostCard}
                >
                  <FormattedMessage
                    defaultMessage="Report lost card"
                    id="lockdoor.actions.lostCard"
                  />
                </ButtonStyled>
              </PermissionGate>
            </MenuItem>
          </Menu>
        }
      >
        <Button icon={IconEllipsisV} type="icon" className="ml-4" />
      </Dropdown>
    </div>
  );

  if (!booking?.id) {
    return <LoadScreenSwitcher pageRoute={ROUTES.DETAIL_BOOKING} />;
  }

  const startTime =
    booking.status === 'checked_in' ? booking.checkedInAt : booking.arrival;
  const endTime =
    booking.status === 'checked_in' ? booking.checkedOutAt : booking.departure;

  return (
    <>
      <PageHeader
        className="mt-4 mb-2"
        title={
          <div>
            <div className="font-semibold">
              <span className="uppercase">
                <FormattedMessage
                  defaultMessage="Booking ID"
                  id="bookings.label.booking"
                />
              </span>
              <span className="ml-2 text-grey-darker font-normal capitalize text-sm">
                {booking?.refCode}
              </span>
            </div>
            <div className="mt-1 flex items-center w-full">
              <div className="flex items-center content-center">
                <Badge
                  color={BOOKING_STAUS_COLOR_MAP[booking?.status] || 'red'}
                  className="mb-0 h-6 mr-2"
                >
                  {booking?.status && (
                    <FormattedMessage
                      {...messages[`bookings.status.${booking?.status}`]}
                    />
                  )}
                </Badge>
              </div>
              <Button
                icon={IconHistory}
                type="line"
                size="small"
                onClick={() => setShowChangeLogs(true)}
                className="bg-white hover:bg-primary"
              >
                <FormattedMessage
                  defaultMessage="View history"
                  id="bookings.actions.viewHistory"
                />
              </Button>
            </div>
          </div>
        }
        extra={!roomStatusComp ? moreActions : null}
      />
      <div>
        <div className="mb-2 flex justify-between flex-wrap">
          {roomStatusComp ? (
            <>
              <div className={classnames('mb-2', { 'w-full': isOnMobile })}>
                {roomStatusComp}
              </div>
              {moreActions}
            </>
          ) : null}
        </div>
      </div>
      {bid !== booking.id && booking.refCode !== bid ? (
        <div className="mt-6">
          <SkeletonTable />
        </div>
      ) : (
        <>
          <div className="flex flex-wrap boxed bg-transparent -mx-2">
            <div className="w-full lg:flex-1 bg-white mx-2">
              <div className="w-full h-full bg-white px-4">
                <div className="flex justify-between px-2 py-4 h-16">
                  <div>
                    <div className="text-base font-medium capitalize text-secondary">
                      {booking.guests?.[0]?.fullName}
                    </div>
                    <div className="text-grey-darker capitalize text-xs">
                      {booking.guests?.[0]?.passport && (
                        <>
                          <span>
                            {`${intl.formatMessage({
                              defaultMessage: 'Passport',
                              id: 'bookings.label.passport',
                            })}: ${booking.guests?.[0].passport}`}
                          </span>
                          <Divider className="m-0 mx-1" type="vertical" />
                        </>
                      )}
                      {booking.guests?.[0]?.phone && (
                        <span>
                          {`${intl.formatMessage({
                            defaultMessage: 'Phone number',
                            id: 'newBooking.phoneNumber',
                          })}: ${booking.guests?.[0]?.phone}`}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="upppercase text-base font-medium text-right">
                      {booking.company?.name}
                    </div>
                    <div className="text-grey-darker font-medium uppercase truncate text-2xs">
                      {booking.reference}
                    </div>
                  </div>
                </div>
                {showLoyalty && rank && (
                  <div className="px-2 mb-1">
                    <LMembershipBadge type={rank} />
                    {rank === VIP_MEMBER && (
                      <>
                        <span className="font-medium ml-2 mr-1">
                          <FormattedMessage
                            defaultMessage="Available xu:"
                            id="bookings.label.availableCoins"
                          />
                        </span>
                        <span className="mr-1">
                          {booking?.membership?.balance || 0}
                        </span>
                        <FormattedMessage
                          defaultMessage="xu"
                          id="common.label.coins"
                        />
                      </>
                    )}
                  </div>
                )}
                <div className="flex justify-between items-center p-2 border-b border-grey-lighter">
                  <TextBlock
                    title={formatDiffTime(startTime, endTime || currentTime)}
                    titleClassName="text-base font-semibold uppercase text-secondary"
                    subTitle={
                      <>
                        <DateTimeFormatter
                          value={startTime}
                          format="HH:mm, DD MMM YYYY"
                        />
                        {' - '}
                        {endTime ? (
                          <DateTimeFormatter
                            value={endTime}
                            format="HH:mm, DD MMM YYYY"
                          />
                        ) : (
                          'Ongoing'
                        )}
                      </>
                    }
                  />
                </div>
                <div className="flex justify-between items-center p-2 border-b border-grey-lighter">
                  <TextBlock
                    title={roomCharge?.room?.Title}
                    titleClassName="text-base font-semibold uppercase text-secondary"
                    subTitle={roomCharge?.room?.ro?.name}
                  />
                </div>
              </div>
            </div>

            <div className="flex-col w-full xl:w-1/3 mt-4 xl:mt-0 px-6 lg:py-0 bg-white mx-2 text-base">
              <div className="flex justify-between pt-6 pb-3">
                <div className="font-semibold">
                  <FormattedMessage
                    defaultMessage="Charge"
                    id="bookings.label.Charge"
                  />
                </div>
                <div className="text-orange font-medium">
                  {currencyFormater(totalCharge, currency)}
                </div>
              </div>
              <div className="flex justify-between py-3">
                <div className="font-semibold">
                  <FormattedMessage
                    defaultMessage="Paid"
                    id="bookings.label.paid"
                  />
                </div>
                <div className="text-primary font-medium">
                  {currencyFormater(actualTotalPaid, paidCurrency)}
                </div>
              </div>
              {loyaltyBk && (
                <>
                  <div className="flex justify-between pt-3">
                    <div className="font-semibold">
                      <FormattedMessage
                        defaultMessage="Paid by Xu"
                        id="bookings.label.paidByXu"
                      />
                    </div>
                    <div className="text-primary font-medium">
                      {currencyFormater(coinValuation, currency)}
                    </div>
                  </div>
                  <div className="flex justify-end text-2xs text-grey-darker">
                    (
                    <span className="mr-1">
                      {coinValuation / (booking?.coinConfig?.exchangeRate || 1)}
                    </span>
                    <FormattedMessage
                      defaultMessage="xu"
                      id="bookings.label.xu"
                    />
                    )
                  </div>
                </>
              )}
              <div className="flex justify-between py-3">
                <div className="font-semibold">
                  <FormattedMessage
                    defaultMessage="Balance"
                    id="bookings.label.balance"
                  />
                </div>
                <div className="text-primary font-medium">
                  {currencyFormater(totalCharge - totalPaid, currency)}
                </div>
              </div>
              {loyaltyBk && (
                <div className="flex justify-between pt-3 pb-6">
                  <div className="inline-flex items-center">
                    <IconCoin className="text-lg mr-2" />
                    <span className="font-medium">
                      {booking?.status === CHECKED_OUT ? (
                        <FormattedMessage
                          defaultMessage="Xu earned:"
                          id="bookings.label.coinsEarned"
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage="Xu will be earned:"
                          id="bookings.label.coinsWillBeEarned"
                        />
                      )}
                    </span>
                  </div>
                  <div>
                    <span className="mr-1">{earnedCoins}</span>
                    <FormattedMessage
                      defaultMessage="xu"
                      id="common.label.coins"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <Card
            title={
              <FormattedMessage
                defaultMessage="Charges"
                id="common.label.charges"
              />
            }
            noContentPadding
            className="bg-white mt-4"
          >
            <Table
              rowClassName={(c) =>
                c.status !== 'active' ? 'opacity-50 cursor-not-allowed' : ''
              }
              rowKey="ID"
              pagination={false}
              scroll={booking.charges?.length > 5 ? { y: 280 } : {}}
              dataSource={booking.charges}
              columns={chargeCols}
            />
            <div className="h-14 flex items-center justify-center">
              <Button
                type="line"
                icon={IconPlus}
                className="border-none bg-transparent hover:text-secondary"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowAddCharge(true);
                }}
                disabled={isAddFuncDisabled}
              >
                <FormattedMessage
                  defaultMessage="Add charge"
                  id="bookings.actions.addCharge"
                />
              </Button>
              {' | '}
              <Button
                type="line"
                icon={IconPlus}
                className="border-none bg-transparent hover:text-secondary"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowAddDiscount(true);
                }}
                disabled={isAddFuncDisabled}
              >
                <FormattedMessage
                  defaultMessage="Add discount"
                  id="bookings.actions.addDiscount"
                />
              </Button>
            </div>
          </Card>
          <Card
            title={
              <FormattedMessage
                defaultMessage="Payments"
                id="common.label.payments"
              />
            }
            noContentPadding
            className="bg-white mt-4"
          >
            <Table
              rowClassName={(c) =>
                c.status !== 'active' ? 'opacity-50 cursor-not-allowed' : ''
              }
              rowKey="ID"
              pagination={false}
              scroll={booking.payments?.length > 5 ? { y: 280 } : {}}
              dataSource={booking.payments}
              columns={paymentCols}
            />
            <div className="h-14 flex items-center justify-center">
              <Button
                type="line"
                icon={IconPlus}
                className="border-none bg-transparent hover:text-secondary"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowAddRefund(true);
                }}
                disabled={totalCharge - totalPaid > 0 || isAddFuncDisabled}
              >
                <FormattedMessage
                  defaultMessage="Add refund"
                  id="bookings.actions.addRefund"
                />
              </Button>
            </div>
          </Card>
          <AddChargeModal
            bid={booking?.id}
            visible={showAddCharge}
            onCancel={onCloseModal}
          />
          <AddPaymentModal
            visible={showAddPayment}
            bid={booking?.id}
            booking={booking}
            balance={totalCharge - totalPaid}
            showLoyalty={showLoyalty}
            currency={currency}
            onCancel={onCloseModal}
          />
          <HourlyBookingChangeLogModal
            bid={booking?.id}
            bookingRefCode={booking?.refCode}
            visible={showChangeLogs}
            onCancel={() => setShowChangeLogs(false)}
            footer={false}
          />
          <AddDiscountModal
            bid={booking?.id}
            visible={showAddDiscount}
            balance={totalCharge - totalPaid}
            onCancel={onCloseModal}
          />
          <AddRefundModal
            visible={showAddRefund}
            bid={booking?.id}
            balance={totalCharge - totalPaid}
            currency={currency}
            onCancel={onCloseModal}
          />
          <AvailableRoomsModal
            visible={!!allocateRoomCharge}
            allocating={isModifying}
            onCancel={() => setAllocateRoomCharge(null)}
            destroyOnClose
            footer={false}
            onSelectRoom={requestChangeRoom}
            selectedRoomType={allocateRoomCharge?.roomType}
            arrival={moment()}
            departure={moment().add(1, 'day')}
            disabledRooms={roomDisabled}
          />
        </>
      )}
    </>
  );
}

export default HourlyBooking;
