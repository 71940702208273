import * as React from 'react';
import { Select as AntdSelect } from 'antd';
import styled, { keyframes } from 'styled-components';
import { NoDataBlock } from '../NoDataBlock';
import {
  SelectProps as AntSelectProps,
  OptionProps,
  OptGroupProps,
} from 'antd/lib/select';
import { IconErase, IconSortDown, IconFilter, IconCheck } from '@aha/icons';

type Size = 'small' | 'normal';

export interface SelectProps<T> extends AntSelectProps<T> {
  children?: React.ReactNode;
  noBorder?: boolean;
  noRotate?: boolean;
}
const StyledSelect = styled(AntdSelect)<any>`
  pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
  .ant-select-arrow,
  .ant-select-selection__clear {
    margin-top: 0;
    transition: transform 0.3s ease;
    transform-origin: center;
    display: flex;
    align-items: center;
    justify-content: center;
    right: ${(props) => (props.size === 'small' ? '0px' : '8px')} !important;
    width: 24px;
    height: 24px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.ant-select-open {
    .ant-select-arrow {
      transform: ${(props) =>
        (!props.mode || props.mode === 'default') && !props.noRotate
          ? 'translateY(-50%) rotate(180deg)'
          : ''};
    }
  }

  .ant-select-selection--multiple {
    .ant-select-selection__clear {
      top: 2px !important;
      transform: translateY(0);
    }
  }

  .ant-select-selection--multiple {
    .ant-select-selection__choice {
      padding-left: 4px !important;
    }

    .ant-select-selection__rendered {
      margin-left: 8px;

      > ul > li {
        height: 24px;
        margin-top: 5px;
        line-height: 23px !important;
      }
    }
  }

  .ant-select-selection__choice__remove {
    top: 50%;
    transform: scale(0.83333333) rotate(0deg) translateY(-50%) !important;
  }

  .ant-select-remove-icon {
    margin-right: 0;
  }

  .ant-select-selection {
    border: 1px solid var(--grey-dark);
  }

  .ant-select-selection__rendered {
    margin-left: 8px;
  }

  .ant-select-selection.ant-select-selection--single {
    ${(props) =>
      props.noBorder
        ? 'border: none!important; display: flex; align-items: center;'
        : ''};
  }

  .has-error .ant-select-selection {
    border-color: var(--red) !important  ;
  }

  .ant-select-selection__choice {
    padding: 0 29px 0 10px !important;
    background-color: var(--grey-light) !important;
    border: none !important;
    border-radius: 2px !important;
    color: var(--secondary) !important;
  }

  .ant-select-disabled .ant-select-selection {
    font-size: 11px !important;
    font-weight: 500 !important;
    text-transform: uppercase !important;
  }

  .ant-select-dropdown-menu-item-selected {
    font-weight: normal !important;
    color: var(--primary) !important;
  }

  .ant-select-dropdown-menu-item {
    color: var(--grey-darkest) !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border-radius: 2px !important;
  }

  .ant-select-selection__placeholder {
    opacity: 0.6 !important;
  }
`;
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.span`
  width: 12px;
  height: 12px;
  display: inline-block;
  border: 2px solid var(--grey-dark);
  opacity: 0.5;
  border-top-color: transparent !important;
  box-sizing: border-box;
  border-radius: 9999px;
  animation: ${spin} 1500ms linear infinite;
`;

const Select = function <T>({ children, ...props }: SelectProps<T>) {
  return (
    <StyledSelect
      removeIcon={
        <IconErase className="text-xl text-white anticon anticon-close ant-select-remove-icon" />
      }
      showArrow
      notFoundContent={<NoDataBlock className="py-6" width={70} />}
      suffixIcon={
        props.loading ? (
          <Spinner />
        ) : !props.mode || props.mode === 'default' ? (
          <IconSortDown className="text-xs text-secondary" />
        ) : (
          <IconFilter className="text-sm text-secondary" />
        )
      }
      menuItemSelectedIcon={
        <IconCheck className="text-sm anticon anticon-check ant-select-selected-icon" />
      }
      {...props}
    >
      {children}
    </StyledSelect>
  );
};

const { Option, OptGroup } = AntdSelect;
Select.Option = Option;
Select.OptGroup = OptGroup;

export { Option, OptGroup };

export default Select;
