import React, { useMemo } from 'react';
import { Dropdown, Menu } from 'antd';
import styled from 'styled-components';
import tw from 'twin.macro';
import { IconSortDown, IconGlobe } from '@aha/icons';

const StyledMenu = styled(Menu)`
  position: relative;
  min-width: 140px;
  &:before {
    ${tw`bg-white block absolute w-3 h-3`};
    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.025);
    content: '';
    z-index: -1;
    top: -6px;
    right: 10px;
    transform: rotate(45deg);
  }
  .ant-dropdown-menu-item-selected {
    pointer-events: none;
  }
`;

export interface LanguageOption {
  id: string;
  name: string;
  abbr: string;
}

export interface LanguageSelectionProps {
  options: LanguageOption[];
  abbr?: boolean;
  onSelect: (lan: LanguageOption) => void;
  selectedLan: LanguageOption;
}

const LanguageSelection = ({
  options,
  abbr = false,
  onSelect,
  selectedLan,
  ...rest
}: LanguageSelectionProps) => {
  const menu = useMemo(
    () => (
      <StyledMenu selectedKeys={[selectedLan.id]} className="shadow py-2 mt-1">
        {options.map((lan) => (
          <Menu.Item
            className="hover:bg-grey-light text-secondary pl-3 capitalize"
            key={lan.id}
            onClick={() => onSelect(lan)}
          >
            {lan.name}
          </Menu.Item>
        ))}
      </StyledMenu>
    ),
    [options, selectedLan], // eslint-disable-line
  );

  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      trigger={['click']}
      {...rest}
    >
      <span className="cursor-pointer items-center flex">
        {abbr ? (
          <>
            <span className="text-sm mr-3 w-8 inline-flex justify-end uppercase">
              {selectedLan.abbr}
            </span>
            <IconSortDown className="text-2xs" />
          </>
        ) : (
          <>
            <IconGlobe className="text-base" />
            <span className="text-sm ml-2 capitalize">{selectedLan.name}</span>
          </>
        )}
      </span>
    </Dropdown>
  );
};

export default LanguageSelection;
