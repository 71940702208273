import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Form, Radio } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { transferFolioBooking } from 'containers/BookingProvider/actions';
import { Modal, Select, DateRangePicker, Badge } from '@aha/ui';
import { BOOKING_STATUS_SHORT } from 'containers/CalendarPage/helpers';
import moment from 'moment';
import { coreAPI } from 'utils/request';
import NProgress from 'nprogress';
import { isGroupFolio } from 'containers/BookingProvider/helpers/folios';
import { DD_MMM_YYYY, NOT_AVAILABLE } from '@aha/constants';

export const InnerModal = ({
  onTransfer,
  setQuery,
  query,
  intl,
  getFieldDecorator,
  isUpdating,
  loading,
  bookings,
  bkID,
  bookingsMap,
  booking,
  folioID,
}) => {
  const activeFolios = bookingsMap[bkID]?.folios?.filter(
    (f) =>
      f.id !== folioID &&
      f.status === 'active' &&
      !bookingsMap[bkID]?.rooms?.some(
        (r) =>
          f.bookingRoomId &&
          r.id === f.bookingRoomId &&
          r.status === 'checked_out',
      ),
  );

  return (
    <Form layout="vertical" onSubmit={onTransfer}>
      <Form.Item>
        <span className="text-base text-secondary font-medium capitalize">
          <FormattedMessage
            defaultMessage="Select Destination"
            id="bookings.label.selectDestination"
          />
        </span>
      </Form.Item>
      <Form.Item>
        <Radio.Group
          onChange={(e) => setQuery((q) => ({ ...q, status: e.target.value }))}
          className="flex justify-between"
          value={query.status}
        >
          <Radio value={BOOKING_STATUS_SHORT.CHECKED_IN}>
            <span className="text-sm font-normal">
              <FormattedMessage
                defaultMessage="Booking checked in"
                id="bookings.label.bookingCheckIn"
              />
            </span>
          </Radio>
          <Radio value={BOOKING_STATUS_SHORT.AWAITING}>
            <span className="text-sm font-normal">
              <FormattedMessage
                defaultMessage="Booking expected"
                id="bookings.label.bookingExpected"
              />
            </span>
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item>
        <DateRangePicker
          className="w-full"
          format={DD_MMM_YYYY}
          onChange={([arrival, departure]) =>
            setQuery((q) => ({ ...q, arrival, departure }))
          }
          value={[moment(query.arrival), moment(query.departure)]}
          placeholder={[
            intl.formatMessage({
              defaultMessage: 'Start date',
              id: 'common.label.startDate',
            }),
            intl.formatMessage({
              defaultMessage: 'End date',
              id: 'common.label.endDate',
            }),
          ]}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage
            defaultMessage="Select Destination Folio"
            id="bookings.label.selectDestinationFolio"
          />
        }
      >
        {getFieldDecorator('destFolioId', {
          rules: [
            {
              required: true,
              message: (
                <FormattedMessage
                  id="common.message.required"
                  defaultMessage="Required"
                />
              ),
            },
          ],
        })(
          <Select
            disabled={isUpdating || loading}
            className="w-full"
            allowClear
            showSearch
            placeholder={
              <FormattedMessage
                defaultMessage="Folio ID | Room"
                id="bookings.label.folioID_Room"
              />
            }
            filterOption={(input, option) =>
              (option?.props?.label || '')
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            optionLabelProp="label"
          >
            {activeFolios?.map((f) => {
              const { roomName } = booking.rooms.find(
                (r) => r.id === f.bookingRoomId,
              );
              const value = `${f.refCode} | ${roomName || NOT_AVAILABLE}`;

              return (
                <Select.Option key={f.id} value={f.id} label={value}>
                  <div className="flex justify-between">
                    {value}
                    <div>
                      {(f.isMaster || isGroupFolio(f)) && (
                        <Badge
                          className="px-1 py-0 mb-0"
                          color={f.isMaster ? 'orange' : 'green'}
                        >
                          {f.isMaster ? (
                            <FormattedMessage
                              defaultMessage="Master"
                              id="common.label.master"
                            />
                          ) : (
                            <FormattedMessage
                              defaultMessage="Group"
                              id="bookings.label.group"
                            />
                          )}
                        </Badge>
                      )}
                    </div>
                  </div>
                </Select.Option>
              );
            })}
          </Select>,
        )}
      </Form.Item>
    </Form>
  );
};

function TransferFolioModal(props) {
  const {
    form,
    doTransferFolioBooking,
    charges,
    bid,
    folioID,
    loading,
    booking,
    onCancel,
    ...rest
  } = props;

  const { validateFields, getFieldDecorator, resetFields } = form;

  const [isUpdating, setIsUpdating] = useState(false);
  const [bookings, setBookings] = useState(null);

  const initialQuery = {
    status: booking.status,
    arrival: booking.arrival,
    departure: booking.departure,
  };

  const [query, setQuery] = useState(initialQuery);
  const intl = useIntl();

  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    async function fetchDailySearchBooking({ status, arrival, departure }) {
      NProgress.start();
      const q = [];
      if (status) {
        q.push(`status=${status}`);
      }
      if (arrival) {
        q.push(`start_date=${arrival}`);
      }
      if (departure) {
        q.push(`end_date=${departure}`);
      }

      try {
        setIsUpdating(true);
        const { data } = await coreAPI.get(
          `v1/pms/bookings?booking_type=daily&type=search&${q.join('&')}`,
        );
        setBookings(data);
      } catch (error) {
        setBookings(null);
      } finally {
        setIsUpdating(false);
      }
      NProgress.done();
    }

    if (query.arrival && query.departure) {
      const newQuery = {
        ...query,
        arrival: moment(query.arrival).toISOString(),
        departure: moment(query.departure).toISOString(),
        pageSize: 50,
      };

      fetchDailySearchBooking(newQuery);
    }
  }, [query]); // eslint-disable-line

  const bookingsMap = useMemo(
    () => (bookings || []).reduce((res, bk) => ({ ...res, [bk.id]: bk }), {}),
    [bookings],
  );

  function onTransfer(e) {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (err) {
        console.error(err);
      } else {
        try {
          setIsUpdating(true);
          const { destFolioId } = values;

          await doTransferFolioBooking(bid, folioID, {
            bookingId: bid,
            destFolioId,
          });

          onCancel(true);
          resetFields();
        } catch (err) {
          console.error(err);
        } finally {
          setIsUpdating(false);
        }
      }
    });
  }

  function cancel() {
    onCancel();
    setQuery(initialQuery);
    setBookings(null);
    resetFields();
  }

  return (
    <Modal
      {...rest}
      destroyOnClose
      submitting={isUpdating || loading}
      onSubmit={onTransfer}
      onCancel={cancel}
      title={
        <FormattedMessage
          defaultMessage="Transfer Folio"
          id="bookings.label.transferFolio"
        />
      }
      submitText={
        <FormattedMessage
          defaultMessage="Transfer"
          id="common.action.transfer"
        />
      }
      cancelText={
        <FormattedMessage defaultMessage="Cancel" id="common.action.cancel" />
      }
    >
      <InnerModal
        onTransfer={onTransfer}
        setQuery={setQuery}
        query={query}
        intl={intl}
        getFieldDecorator={getFieldDecorator}
        isUpdating={isUpdating}
        loading={loading}
        bookings={bookings}
        bkID={bid}
        booking={booking}
        bookingsMap={bookingsMap}
        folioID={folioID}
      />
    </Modal>
  );
}

function mapDispatch(dispatch) {
  return {
    doTransferFolioBooking: (bid, fid, body) =>
      new Promise((resolve, reject) =>
        dispatch(transferFolioBooking(bid, fid, body, resolve, reject)),
      ),
  };
}

export default connect(
  null,
  mapDispatch,
)(Form.create({ name: 'transfer_folio' })(TransferFolioModal));
