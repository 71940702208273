import React from 'react';
import useClipboard from '@uxui/use-clipboard';
import tw from 'twin.macro';
import styled, { keyframes } from 'styled-components';
import { IconCopy } from '@aha/icons';

const Container = styled.span`
  display: inline-flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  &:hover {
    > span:first-of-type {
      opacity: 1;
    }
  }
`;

const Placeholder = styled.span<{ copied: boolean }>`
  ${tw`absolute inset-y-0 left-0 bg-white border border-dashed border-grey text-black rounded-sm flex items-center`}
  height: 100%;
  box-sizing: content-box;
  padding: 1px 6px;
  padding-right: 20px;
  transform: translate(-7px, -2px);
  pointer-events: none;
  opacity: 0;
  visibility: ${(props) => (props.copied ? 'hidden' : 'visible')};

  span {
    opacity: 0;
  }

  svg {
    font-size: 14px;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const moveUp = keyframes`
    0% {
        opacity: 1;
        transform: translate(-50%, -5px);
    }

    100% {
        opacity: 0;
        transform: translate(-50%, -25px);
    }
`;

export const Tooltip = styled.div`
  ${tw`bg-secondary text-white absolute text-xs rounded-sm`};
  left: 50%;
  top: -10px;
  opacity: 0;
  transform: translate(-50%, -20px);
  padding: 2px 3px;
  animation: ${moveUp} 1s;
`;

export interface CopyToClipboard {
  content: string;
  displayPlaceHolder?: boolean;
}

const CopyToClipboard: React.FC<CopyToClipboard> = ({
  children,
  content,
  displayPlaceHolder = true,
}) => {
  const { copied, copy, turnOffCopied } = useClipboard();
  return (
    <Container
      data-testid="clipboard"
      onClick={(e) => {
        if (copied) {
          return;
        }
        copy(content);
        e.stopPropagation();
      }}
      onMouseLeave={() => {
        if (copied) {
          turnOffCopied();
        }
      }}
    >
      {displayPlaceHolder && (
        <Placeholder copied={copied}>
          {content} <IconCopy className="ml-1" />
        </Placeholder>
      )}
      {copied && <Tooltip data-testid="clipboard-tooltip">Copied!</Tooltip>}
      {children}
    </Container>
  );
};

export default CopyToClipboard;
