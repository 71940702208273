import React, { useRef, useState, useEffect } from 'react';
import { Form, Checkbox, Divider } from 'antd';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  makeSelectCurrentHotel,
  makeSelectCurrencies,
} from 'containers/Dashboard/selectors';
import { makeSelectPaymentTypes } from 'containers/BookingProvider/selector';
import { fetchPaymentTypes } from 'containers/BookingProvider/actions';
import { useInjectReducer } from 'utils/injectReducer';
import bookingsReducer from 'containers/BookingProvider/reducer';
import bookingsSaga from 'containers/BookingProvider/saga';
import { useInjectSaga } from 'utils/injectSaga';
import { addHourlyBookingPayment } from '../../actions';
import InputNumberFormat from 'components/InputNumberFormat';
import LMembershipBadge from 'components/LMembershipBadge';
import { Modal, Select } from '@aha/ui';
import classnames from 'classnames';
import currencyFormater from '@aha/utils/src/currencyFormatter';
import { makeSelectConversionRate } from 'containers/BookingProvider/selector';
import { VIP_MEMBER } from 'constants/membership';
import { IconCoin } from '@aha/icons';

export const InnerForm = ({
  paymentTypes,
  currency,
  currencies,
  balance,
  getFieldDecorator,
  setFieldsValue,
  onChangeCurrency,
  getFieldValue,
  booking,
  conversionRate,
  showLoyalty,
  setFinalBalance,
  expectBalance,
  actualBalance,
  intl,
}) => {
  const { coinConfig = {}, membership, coinUsage = {} } = booking || {};
  const isVip = membership?.rank === VIP_MEMBER;

  // get current coin info
  const availableCoins = membership?.balance || 0;
  const UsedCoin = Math.abs(coinUsage.UsedCoin) || 0;

  const { exchangeRate, hourlyBookingMaxExpense: maxCoin = 0 } = coinConfig;
  const balanceToXu = Math.abs(expectBalance) / exchangeRate;

  let finalCoins = maxCoin;
  if (UsedCoin < maxCoin) {
    finalCoins = maxCoin - UsedCoin;
  }

  if (availableCoins <= finalCoins) {
    finalCoins = availableCoins;
  }

  if (balanceToXu <= finalCoins) {
    finalCoins = Math.floor(balanceToXu);
  }

  const chargeRate =
    currencies.find((c) => c.name === currency)?.rate?.rate || 1;
  const paymentRate =
    currencies.find((c) => c.id === getFieldValue('currencyId'))?.rate?.rate ||
    1;

  //convert to VND
  const balanceToVnd = (actualBalance.current * paymentRate) / chargeRate;
  const xuToVnd = (getFieldValue('coinAmount') || 0) * exchangeRate;
  const sumPayment = balanceToVnd + xuToVnd;

  useEffect(() => {
    const maxAmount =
      xuToVnd > Math.abs(expectBalance) ? 0 : Math.abs(expectBalance) - xuToVnd;

    // convert amount adjusting to selected currency & save it
    actualBalance.current = (maxAmount * chargeRate) / paymentRate;
    setFinalBalance(actualBalance.current.toFixed(2));
  }, [xuToVnd]); // eslint-disable-line

  return (
    <Form layout="vertical">
      <div className="flex flex-wrap -mx-2">
        <div className="col w-full px-2 md:w-1/2">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="amount"
                id="bookings.label.amount"
              />
            }
          >
            <InputNumberFormat
              min={0}
              value={balance}
              onChange={(v) => setFinalBalance(v)}
              disabled
            />
          </Form.Item>
        </div>
        <div className="col w-full px-2 md:w-1/2">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="Currency"
                id="common.label.currency"
              />
            }
          >
            {getFieldDecorator('currencyId', {
              initialValue: currencies.find((c) => c.name === currency)?.id,
            })(
              <Select
                onSelect={onChangeCurrency}
                placeholder={
                  <FormattedMessage
                    defaultMessage="Select currency"
                    id="bookings.label.select_currency"
                  />
                }
              >
                {currencies.map((c) => (
                  <Select.Option key={c.id} value={c.id}>
                    {c.name}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </div>
      </div>
      <div className="flex flex-wrap -mx-2">
        <div className="w-full px-2 pt-3">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="Payment type"
                id="common.label.paymentType"
              />
            }
          >
            {getFieldDecorator('paymentType', {
              initialValue:
                paymentTypes?.[0] &&
                `${paymentTypes?.[0]?.id};;;${paymentTypes?.[0]?.subTypes?.[0]?.id}`,
              rules: [
                {
                  required: !isNaN(balance) && balance !== 0,
                  message: (
                    <FormattedMessage
                      id="common.message.required"
                      defaultMessage="Required"
                    />
                  ),
                },
              ],
            })(
              <Select
                showSearch
                placeholder={
                  <FormattedMessage
                    defaultMessage="Select payment type"
                    id="bookings.label.selectPaymentType"
                  />
                }
              >
                {paymentTypes.map((pt) => (
                  <Select.OptGroup key={pt.id} label={pt.name}>
                    {(pt.subTypes || []).map((p) => (
                      <Select.Option
                        key={p.id}
                        value={`${pt.id};;;${p.id}`}
                        title={`${pt.name} - ${p.name}`}
                      >
                        {p.name}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                ))}
              </Select>,
            )}
          </Form.Item>
        </div>
      </div>
      {showLoyalty && (
        <>
          <Divider className="mt-2" />
          <div className="w-full inline-flex items-center -mx-2">
            <div className="pl-2">
              <LMembershipBadge type={membership?.rank} />
            </div>
            {isVip && (
              <div className="flex-1 px-2">
                <span className="font-medium mr-1">
                  <FormattedMessage
                    defaultMessage="Available xu:"
                    id="bookings.label.availableCoins"
                  />
                </span>
                <span className="mr-1">{availableCoins}</span>
                <FormattedMessage defaultMessage="xu" id="common.label.coins" />
              </div>
            )}
          </div>
          {availableCoins <= 0 ? (
            <div className="mt-4">
              <FormattedMessage
                defaultMessage="Customers do not have enough xu to pay"
                id="bookings.label.notEnoughCoins"
              />
            </div>
          ) : isVip ? (
            <>
              <div className="inline-flex items-center flex-no-wrap mt-4">
                <IconCoin className="mr-2 text-xl" />
                <span className="font-medium">
                  <FormattedMessage
                    defaultMessage="Xu used / Max xu can use:"
                    id="bookings.label.usedAndMaxCoins"
                  />
                </span>
                <span className="ml-2 mr-1">{`${UsedCoin} / ${maxCoin}`}</span>
                <FormattedMessage defaultMessage="xu" id="common.label.coins" />
              </div>
              <div className="w-full inline-flex items-center flex-no-wrap -mx-2 mt-4">
                <div className="w-1/2 px-2">
                  {getFieldDecorator('coinAmount')(
                    <InputNumberFormat
                      min={0}
                      max={finalCoins}
                      disabled={UsedCoin >= maxCoin}
                    />,
                  )}
                </div>
                <div className="w-1/2 pr-2 text-grey-darker">
                  <span className="mr-1">=</span>
                  {currencyFormater(xuToVnd)}
                </div>
              </div>
              <div
                className={classnames('w-full inline-flex items-center mt-4', {
                  'cursor-not-allowed opacity-50': UsedCoin >= maxCoin,
                })}
              >
                <Checkbox
                  disabled={UsedCoin >= maxCoin}
                  onChange={(e) => {
                    const { checked } = e.target;
                    setFieldsValue({
                      coinAmount: !!checked ? finalCoins : 0,
                    });
                  }}
                />
                <span className="ml-2" />
                <FormattedMessage
                  defaultMessage="Use all remaining coins"
                  id="booking.message.useRemainCoins"
                />
              </div>
            </>
          ) : null}
          <Divider />
          <div className="w-full inline-flex items-center -mx-2">
            <div className="w-1/2 px-2">
              <div className="uppercase text-2xs font-medium">
                <FormattedMessage
                  defaultMessage="Total amount"
                  id="booking.label.totalAmount"
                />
              </div>
              <div>{currencyFormater(sumPayment)}</div>
            </div>
            <div className="w-1/2 px-2">
              <div className="uppercase text-2xs font-medium">
                <FormattedMessage
                  defaultMessage="Balance"
                  id="bookings.label.balance"
                />
              </div>
              <div>{currencyFormater(expectBalance)}</div>
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

const mapStateToProps = createStructuredSelector({
  hotel: makeSelectCurrentHotel(),
  currencies: makeSelectCurrencies(),
  paymentTypes: makeSelectPaymentTypes(),
  conversionRate: makeSelectConversionRate(),
});

function mapDispatchToProps(dispatch) {
  return {
    doFetchPaymentTypes: (hid) => dispatch(fetchPaymentTypes(hid)),
    doAddPayment: (refCode, reqData) =>
      new Promise((resolve, reject) =>
        dispatch(addHourlyBookingPayment(refCode, reqData, resolve, reject)),
      ),
  };
}
export function AddPaymentModal(props) {
  useInjectReducer({ key: 'bookings', reducer: bookingsReducer });
  useInjectSaga({ key: 'bookings', saga: bookingsSaga });

  const dispatch = useDispatch();
  const { doFetchPaymentTypes, doAddPayment } = mapDispatchToProps(dispatch);
  const { currencies, hotel, paymentTypes, conversionRate } = useSelector(
    mapStateToProps,
  );

  const {
    bid,
    balance,
    currency,
    booking,
    showLoyalty,
    visible,
    onOk,
    onCancel,
    form,
    ...rest
  } = props;

  const {
    validateFields,
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
  } = form;

  const [isCreating, setIsCreating] = useState(false);
  const [finalBalance, setFinalBalance] = useState(balance);
  const actualBalance = useRef(balance);
  const intl = useIntl();

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    if (visible) {
      doFetchPaymentTypes(hotel.id);
      setFinalBalance(balance);
    }
  }, [hotel.id, visible, balance]); // eslint-disable-line

  function onAddPayment(e) {
    e.preventDefault();

    validateFields(async (err, values) => {
      if (err) {
        console.error(err);
      } else {
        try {
          const { paymentType } = values;

          const reqData = {
            ...values,
            amount: +finalBalance || 0,
            paymentTypeId: +paymentType?.split(';;;')[0],
            paymentSubtypeId: +paymentType?.split(';;;')[1],
          };
          setIsCreating(true);
          await doAddPayment(booking?.refCode, reqData);
          onCancel(true);
        } catch (err) {
          console.error(err);
        } finally {
          setIsCreating(false);
        }
      }
    });
  }

  function onCanCel() {
    onCancel(true);
  }

  function onChangeCurrency(k) {
    const chargeRate =
      currencies.find((c) => c.name === currency)?.rate?.rate || 1;
    const paymentRate = currencies.find((c) => c.id === k)?.rate?.rate || 1;

    const xuToVnd =
      (getFieldValue('coinAmount') || 0) *
      (booking?.coinConfig?.exchangeRate || 1);

    const maxAmount =
      xuToVnd > Math.abs(balance) ? 0 : Math.abs(balance) - xuToVnd;

    // convert amount adjusting to currency
    actualBalance.current = (maxAmount * chargeRate) / paymentRate;
    setFinalBalance(actualBalance.current.toFixed(2));
  }

  return (
    <Modal
      {...rest}
      visible={visible}
      title={
        <FormattedMessage
          defaultMessage="Make payment"
          id="bookings.actions.makePayment"
        />
      }
      width={535}
      submitText={
        balance === 0 ? (
          <FormattedMessage
            defaultMessage="Finalize payment"
            id="bookings.actions.finalizePayment"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Add payment"
            id="bookings.actions.addPayment"
          />
        )
      }
      cancelText={
        <FormattedMessage defaultMessage="Cancel" id="common.action.cancel" />
      }
      submitting={isCreating}
      onCancel={onCanCel}
      onSubmit={onAddPayment}
    >
      <InnerForm
        paymentTypes={paymentTypes}
        currency={currency}
        currencies={currencies}
        balance={finalBalance}
        actualBalance={actualBalance}
        expectBalance={balance}
        getFieldDecorator={getFieldDecorator}
        setFieldsValue={setFieldsValue}
        getFieldValue={getFieldValue}
        onChangeCurrency={onChangeCurrency}
        booking={booking}
        showLoyalty={showLoyalty}
        conversionRate={conversionRate}
        setFinalBalance={setFinalBalance}
        intl={intl}
      />
    </Modal>
  );
}

export default Form.create({ name: 'add_payment_modal' })(AddPaymentModal);
