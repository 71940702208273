import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { ApplicationRootState } from 'types/app';
import { PushAction } from './types';

const selectRatePlans = (state: ApplicationRootState) =>
  state.ratePlans || initialState;

export function makeSelectLoading() {
  return createSelector(selectRatePlans, ({ loading }) => loading);
}

export function makeSelectRatePlans() {
  return createSelector(selectRatePlans, ({ rates }) => rates || []);
}

export function makeSelectPushHistory() {
  return createSelector(selectRatePlans, ({ pushHistory }) => {
    return pushHistory
      .reduce((fin: PushAction[], item) => {
        if (fin.length === 0) {
          return [{ pushDate: item.updatedAt, pushBy: item.user?.name }];
        }
        const lastDate = fin[fin.length - 1].pushDate;
        if (moment(lastDate).diff(item.updatedAt, 'hour') !== 0) {
          return [
            ...fin,
            {
              pushDate: item.updatedAt,
              pushBy: item.user?.name,
            },
          ];
        }
        return fin;
      }, [])
      .slice(0, 20)
      .sort((a, b) => moment(b.pushDate).diff(moment(a.pushDate)));
  });
}

export function makeSelectEditHistory() {
  return createSelector(selectRatePlans, ({ editHistory }) =>
    editHistory
      .map((item) => {
        return {
          editDate: item.updatedAt,
          currency: item.target?.currency?.name,
          roomType: item.target?.roomType?.name,
          editBy: item.user?.name,
          action:
            item.action === 'update_rate_plan_base_restriction'
              ? 'updateBase'
              : 'updateRestriction',
          price:
            item.action === 'update_rate_plan_restriction' &&
            Array.isArray(item?.detail)
              ? item?.detail?.[0]?.price
              : // @ts-ignore: the check for isArray would be valid
                // @ts-ignore
                item?.Detail?.price,
        };
      })
      .sort((a, b) => moment(b.editDate).diff(moment(a.editDate))),
  );
}
