import { takeLatest, call } from 'redux-saga/effects';
import ActionTypes from './constants';
import { ExtractAction } from 'types/actions';
import { LanguageProviderActions } from './type';

async function storeLanguage(locale: string) {
  try {
    await window.localStorage.setItem('lan', locale);
    if (locale === 'vi') {
      await import('moment/locale/vi' as string);
      const { default: moment } = await import('moment');
      moment.locale('vi');
    } else {
      // fallback to english
      const { default: moment } = await import('moment');
      moment.locale('en');
    }
  } catch (error) {
    console.error(error);
  }
}

export function* doChangeLocale(
  action: ExtractAction<LanguageProviderActions, ActionTypes.CHANGE_LOCALE>,
) {
  const {
    payload: { locale },
  } = action;
  yield call(storeLanguage, locale);
}

export default function* watchChangeLocale() {
  yield takeLatest(ActionTypes.CHANGE_LOCALE, doChangeLocale);
}
