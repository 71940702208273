export const HHMM_DD_MMM_YYYY = `HH:mm, DD MMM YYYY`;
export const HHMMSS_DD_MMM_YYYY = `HH:mm:ss, DD MMM, YYYY`;
export const DD_MMM_YYYY_HHMM = `DD MMM YYYY, HH:mm`;
export const DD_MMM_HMMA = `DD MMM H:mma`;
export const DD_MMM_YYYY = `DD MMM YYYY`;
export const MMM_YYYY = `MMM YYYY`;
export const MMMM_YYYYY = `MMMM YYYY`;
export const YYYY = 'YYYY';
export const MMM = 'MMM';
export const MM = 'MM';
