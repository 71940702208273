import { all } from 'redux-saga/effects';
import watchFetchCurrentHotel from './sagas/watchFetchCurrentHotel';
import watchSetCurrentHotelId from './sagas/watchSetCurrentHotelId';
import watchSwitchHotel from './sagas/watchSwitchHotel';
import watchFetchColors from './sagas/watchFetchColors';
import watchFetchDistricts from './sagas/watchFetchDistricts';
import watchFetchCities from './sagas/watchFetchCities';
import watchFetchFacilities from './sagas/watchFetchFacilities';
import watchFetchAmenities from './sagas/watchFetchAmenities';

export default function* watchAll() {
  yield all([
    watchFetchCurrentHotel(),
    watchSetCurrentHotelId(),
    watchSwitchHotel(),
    watchFetchColors(),
    watchFetchCities(),
    watchFetchDistricts(),
    watchFetchFacilities(),
    watchFetchAmenities(),
  ]);
}
