import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { ReactComponent as LogoCircle } from 'svg/logo-white.svg';
import { ReactComponent as LogoText } from 'svg/logo-text-white.svg';

const Container = styled.div`
  ${tw`items-center flex font-bold ml-12`};
  max-width: 160px;
  > svg:nth-child(2) {
    margin-left: 6px;
  }
`;

export default function Logo() {
  return (
    <Container>
      <LogoCircle width="37" height="37" className="flex-none" />
      <LogoText width="70" height="20">
        ROOMS
      </LogoText>
    </Container>
  );
}
