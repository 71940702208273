import React, { useState, useMemo } from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Modal, Select, Table, Button, NAFallback } from '@aha/ui';
import { Dispatch } from 'redux';
import {
  Booking,
  Folio,
  BookingRoomDetail,
  FolioGroupRequestBody,
} from 'types/schema';
import { useDispatch } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { currencyFormater } from 'utils/currencyFormater';
import { groupFolios } from 'containers/BookingProvider/actions';
import { PriceType } from 'utils/calcTotalAmount';
import { ColumnProps } from 'antd/lib/table';
import { calcTotalAmount, TableBadge } from 'containers/BookingDetailPage';
import styled from 'styled-components';
import { NOT_AVAILABLE } from '@aha/constants';

const FormItem = styled(Form.Item)`
  margin: 0 !important;
  padding: 0 !important;

  .ant-form-explain {
    display: none !important;
  }
`;

export type SubmitValues = {
  destFolioId: number;
};

export type GroupFoliosProps = {
  booking: Booking;
  folios?: Folio[];
  conversionRate: PriceType;
  visible?: boolean;
  onClose?: (...args: any[]) => any;
} & FormComponentProps;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doGroupFolios: (bid: number, fid: number, body: FolioGroupRequestBody) =>
    new Promise((resolve, reject) =>
      dispatch(groupFolios(bid, fid, body, resolve, reject)),
    ),
});

const GroupFoliosModal: React.SFC<GroupFoliosProps> = ({
  booking,
  folios,
  conversionRate,
  visible,
  onClose,
  form,
}) => {
  const dispatch = useDispatch();
  const { doGroupFolios } = mapDispatchToProps(dispatch);

  const [submitting, setSubmitting] = useState(false);
  const [folioIds, setFolioIds] = useState<number[]>([]);

  const {
    resetFields,
    validateFields,
    getFieldDecorator,
    getFieldError,
  } = form;

  const { rooms, id: bookingID } = booking || {};
  const activeFolios = folios?.filter(
    (f) =>
      f.status === 'active' &&
      !rooms?.some(
        (r) =>
          f.bookingRoomId &&
          r.id === f.bookingRoomId &&
          r.status === 'checked_out',
      ),
  );

  const roomIDs = useMemo(
    () =>
      (activeFolios || []).reduce(
        (res: Record<string, BookingRoomDetail | undefined>, f) => {
          const room = rooms?.find((r) => r.id === f.bookingRoomId);

          if (f.refCode) {
            return { ...res, [f.refCode]: room };
          }

          return res;
        },
        {},
      ),
    [folios, rooms], // eslint-disable-line
  );

  function onCancel() {
    if (submitting) {
      return;
    }

    onClose && onClose();
    resetFields();
  }

  function onAdd() {
    validateFields(async (err, values: SubmitValues) => {
      if (err) {
        return;
      } else if (!bookingID) {
        console.error('Booking not found!');
        return;
      }

      try {
        setSubmitting(true);
        const { destFolioId } = values;

        await doGroupFolios(bookingID, destFolioId, { folioIds });
        onClose && onClose(true);
        resetFields();
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    });
  }

  const columns: ColumnProps<Folio>[] = [
    {
      title: (
        <FormattedMessage
          defaultMessage="Folio ID "
          id="common.label.folioId"
        />
      ),
      dataIndex: 'refCode',
      className: 'relative',
      width: '17%',
      render: (v, record) => {
        const isGroupFolio = record?.charges?.some(
          (c) =>
            c.bookingRoomId &&
            c.bookingRoomId !== record.bookingRoomId &&
            c.status !== 'voided',
        );

        const showBadge =
          (!!isGroupFolio || record.isMaster) && record.status === 'active';

        return (
          <>
            {showBadge && (
              <TableBadge color={record.isMaster ? 'orange' : 'green'}>
                {record.isMaster ? (
                  <FormattedMessage
                    defaultMessage="Master"
                    id="common.label.master"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Group"
                    id="bookings.label.group"
                  />
                )}
              </TableBadge>
            )}

            {v}
          </>
        );
      },
    },
    {
      title: (
        <FormattedMessage defaultMessage="Room ID " id="common.label.roomId" />
      ),
      dataIndex: 'refCode',
      width: '17%',
      render: (v: string) => <NAFallback value={roomIDs[v]?.refCode} />,
    },
    {
      title: <FormattedMessage defaultMessage="Room" id="common.label.room" />,
      dataIndex: 'refCode',
      width: '12%',
      render: (v: string) => <NAFallback value={roomIDs[v]?.roomName} />,
    },
    {
      title: (
        <FormattedMessage defaultMessage="Charge" id="bookings.label.Charge" />
      ),
      width: '16%',
      dataIndex: 'totalCharge',
      render: (v) => {
        return (
          <div className="font-medium truncate">
            {currencyFormater(calcTotalAmount(v, conversionRate).total)}
          </div>
        );
      },
    },
    {
      title: (
        <FormattedMessage defaultMessage="Paid" id="bookings.label.paid" />
      ),
      width: '16%',
      dataIndex: 'totalPayment',
      render: (v) => (
        <div className="font-medium truncate">
          {currencyFormater(calcTotalAmount(v, conversionRate).total)}
        </div>
      ),
    },
    {
      title: (
        <FormattedMessage
          defaultMessage="Balance"
          id="bookings.label.balance"
        />
      ),
      dataIndex: 'balance',
      render: (v) => (
        <div className="font-medium truncate">
          {currencyFormater(calcTotalAmount(v, conversionRate).total)}
        </div>
      ),
    },
  ];

  return (
    <Modal
      visible={visible}
      title={
        <FormattedMessage
          defaultMessage="Group Folio"
          id="bookings.label.groupFolio"
        />
      }
      submitting={submitting}
      onCancel={onCancel}
      onSubmit={onAdd}
      width={1024}
      footer={
        <Form layout="vertical">
          <div className="h-full flex justify-between items-center px-2">
            <div className="flex items-center">
              <span className="mr-3">
                <FormattedMessage
                  defaultMessage="Select representative folio"
                  id="bookings.label.selectFolioToGroup"
                />
              </span>
              <FormItem
                validateStatus={getFieldError('destFolioId') && 'error'}
              >
                {getFieldDecorator('destFolioId', {
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="common.message.required"
                          defaultMessage="Required"
                        />
                      ),
                    },
                  ],
                })(
                  <Select
                    showSearch
                    optionFilterProp="label"
                    placeholder={
                      <FormattedMessage
                        id="bookings.label.selectFolioID"
                        defaultMessage="Folio ID - Room"
                      />
                    }
                    style={{ width: 180 }}
                    filterOption={(input, option) =>
                      (option?.props?.title || '')
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {activeFolios?.map((f) => (
                      <Select.Option
                        key={f.id}
                        value={f.id}
                        title={[
                          f.refCode,
                          roomIDs[f.refCode as string]?.roomName,
                        ]
                          .filter(Boolean)
                          .join(' - ')}
                      >
                        <div className="inline-flex">
                          <div className="mr-1 text-left">{f.refCode}</div>
                          <span className="mr-1">-</span>
                          {f.isMaster ? (
                            <span className="text-red">
                              <FormattedMessage
                                defaultMessage="Master"
                                id="common.label.master"
                              />
                            </span>
                          ) : (
                            `${
                              roomIDs[f.refCode as string]?.roomName ||
                              NOT_AVAILABLE
                            }`
                          )}
                        </div>
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </div>
            <div>
              <Button
                className="px-9/2 h-8 w-32 mr-4"
                onClick={onAdd}
                disabled={submitting || folioIds.length <= 1}
              >
                <FormattedMessage
                  defaultMessage="Submit"
                  id="common.label.submit"
                />
              </Button>
              <Button
                type="line"
                className="px-9/2 h-8 w-32"
                onClick={onCancel}
                disabled={submitting}
              >
                <FormattedMessage
                  defaultMessage="Cancel"
                  id="common.action.cancel"
                />
              </Button>
            </div>
          </div>
        </Form>
      }
    >
      <Table
        rowKey="id"
        dataSource={activeFolios}
        rowSelection={{
          onChange: (rowIDs) => {
            setFolioIds(rowIDs as number[]);
          },
        }}
        bordered
        borderBottom
        columns={columns}
        pagination={false}
        scroll={(folios?.length || 0) > 5 ? { y: 281 } : {}}
      />
    </Modal>
  );
};

export default Form.create<GroupFoliosProps>({
  name: 'group_folios_form',
})(GroupFoliosModal);
