import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { currencyFormater } from 'utils/currencyFormater';
import {
  makeIsLoadingHourlyBookingChangeLogs,
  makeSelectHourlyBookingChangeLogs,
} from '../../selector';
import { HOURLY_BOOKING_KEY } from '../../constants';
import hourlyBookingReducer from '../../reducer';
import saga from '../../saga';
import { fetchHourlyBookingChangeLogs } from '../../actions';
import { Modal, Table, DateTimeFormatter } from '@aha/ui';
import classnames from 'classnames';
import messages from 'utils/messages/bookings';

export const InnerTable = ({ loading, data }) => (
  <Table
    disabled={loading}
    dataSource={data}
    pagination={false}
    className="mt-4"
    rowKey={(r) => moment(r.d).unix()}
    scroll={{ y: '50vh' }}
    columns={[
      {
        title: (
          <FormattedMessage defaultMessage="Action" id="common.label.action" />
        ),
        dataIndex: 'action',
        width: '25%',
        render: (txt) => (
          <FormattedMessage
            {...(messages[`bookings.changeLogs.${txt}`] || {
              id: 'common.label.default',
              defaultMessage: txt,
            })}
          />
        ),
      },
      {
        title: (
          <FormattedMessage defaultMessage="Result" id="common.label.result" />
        ),
        width: '30%',
        dataIndex: 'result',
      },
      {
        title: (
          <FormattedMessage defaultMessage="Edit by" id="common.label.editBy" />
        ),
        dataIndex: 'editBy',
        render: (txt) => {
          return (
            <span
              className={classnames('text-2xs font-semibold uppercase', {
                'text-red': txt === 'Master',
              })}
            >
              {txt}
            </span>
          );
        },
      },
      {
        width: '25%',
        title: (
          <FormattedMessage defaultMessage="Date" id="common.label.date" />
        ),
        dataIndex: 'date',
        render: (d) => (
          <DateTimeFormatter value={d} format="HH:mm, DD MMM YYYY" />
        ),
      },
    ]}
  />
);
const mapStateToProps = createStructuredSelector({
  loading: makeIsLoadingHourlyBookingChangeLogs(),
  changeLogs: makeSelectHourlyBookingChangeLogs(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchChangeLogs: (bid) => dispatch(fetchHourlyBookingChangeLogs(bid)),
  };
};

export function HourlyBookingChangeLogModal(props) {
  useInjectReducer({ key: HOURLY_BOOKING_KEY, reducer: hourlyBookingReducer });
  useInjectSaga({ key: HOURLY_BOOKING_KEY, saga });
  const dispatch = useDispatch();
  const { loading, changeLogs } = useSelector(mapStateToProps);
  const { doFetchChangeLogs } = mapDispatchToProps(dispatch);
  const { bid, bookingRefCode, visible, ...rest } = props;

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    if (visible) {
      doFetchChangeLogs(bid);
    }
  }, [bid, visible]); // eslint-disable-line

  const data = useMemo(
    () =>
      changeLogs
        .map((item) => {
          let result = null;
          if (item.action === 'add_payment' || item.action === 'add_charge') {
            result = currencyFormater(item.newData);
          } else if (
            item.action === 'void_payment' ||
            item.action === 'void_charge'
          ) {
            result = (
              <FormattedMessage
                defaultMessage="voided"
                id="common.lable.voided"
              />
            );
          } else {
            result = [item.oldData, item.newData].filter(Boolean).join(' => ');
          }
          return {
            action: item.action,
            result,
            editBy: item.user?.name || 'SYSTEM',
            date: item.createdAt,
          };
        })
        .sort((a, b) => moment(b.date).diff(a.date)),
    [changeLogs],
  );

  return (
    <Modal
      visible={visible}
      destroyOnClose
      noContentPadding
      hiddenFooter
      width={1200}
      title={
        <>
          <FormattedMessage
            defaultMessage="Booking change logs"
            id="bookings.label.bookingChangeLogs"
          />
          <div className="text-sm text-grey-darker font-normal mt-1">
            ID: {bookingRefCode}
          </div>
        </>
      }
      {...rest}
    >
      <InnerTable loading={loading} data={data} />
    </Modal>
  );
}

export default HourlyBookingChangeLogModal;
