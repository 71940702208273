import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

const dates: Date[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export type Date =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';

const DateBlock = styled.button`
  width: 48px;
  height: 48px;
  outline: none !important;
`;

export interface MultipleDateSelectorProps {
  onChange?: (values: Date[]) => void;
  value?: Date[];
  className?: string;
}

export const MultipleDateSelector = ({
  className = '',
  value = [],
  onChange,
}: MultipleDateSelectorProps) => {
  return (
    <div
      className={classnames('flex items-center justify-center', {
        [className]: className,
      })}
    >
      {dates.map((date) => (
        <DateBlock
          className={classnames(
            'mx-2 inline-flex items-center justify-center border rounded-sm capitalize text-sm ',
            {
              'bg-secondary border-secondary text-white font-medium': value.includes(
                date,
              ),
              'border-grey-darker text-secondary font-medium': !value.includes(
                date,
              ),
            },
          )}
          onClick={(e) => {
            e.preventDefault();
            if (typeof onChange !== 'function') {
              return;
            }
            if (value.includes(date)) {
              onChange(value.filter((v) => v !== date));
            } else {
              onChange([...value, date]);
            }
          }}
          key={date}
        >
          {date.slice(0, 3)}
        </DateBlock>
      ))}
    </div>
  );
};

export default MultipleDateSelector;
