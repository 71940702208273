import React from 'react';
import { PageHeader } from '@aha/ui';
import { FormattedMessage } from 'react-intl';
import { Location } from '@reach/router';
import { Radio } from 'antd';
import { navigate } from '@reach/router';
import ROUTES from 'constants/routes';

const HourlyReportLayout: React.FC<any> = ({ children }) => {
  return (
    <>
      <Location>
        {(path) => (
          <PageHeader
            title={
              <FormattedMessage
                defaultMessage="Hourly Report"
                id="reports.label.hourlyReport"
              />
            }
            extra={
              <Radio.Group
                value={path.location.pathname}
                onChange={(e) => navigate(e.target.value)}
                buttonStyle="solid"
                className="text-center h-full"
              >
                <Radio.Button value={ROUTES.HOURLY_CHARGE_REPORT}>
                  <FormattedMessage
                    defaultMessage="Charges"
                    id="reports.label.charges"
                  />
                </Radio.Button>
                <Radio.Button value={ROUTES.HOURLY_PAYMENT_REPORT}>
                  <FormattedMessage
                    defaultMessage="Payment"
                    id="reports.label.payment"
                  />
                </Radio.Button>
              </Radio.Group>
            }
          />
        )}
      </Location>
      {children}
    </>
  );
};

export default HourlyReportLayout;
