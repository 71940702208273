enum ActionTypes {
  FETCH_GUESTS = 'guestSettings/FETCH_GUESTS',
  FETCH_GUESTS_SUCCESS = 'guestSettings/FETCH_GUESTS_SUCCESS',
  FETCH_GUESTS_FAIL = 'guestSettings/FETCH_GUESTS_FAIL',

  EDIT_GUEST = 'guestSettings/EDIT_GUEST',
  EDIT_GUEST_SUCCESS = 'guestSettings/EDIT_GUEST_SUCCESS',
  EDIT_GUEST_FAIL = 'guestSettings/EDIT_GUEST_FAIL',

  ADD_GUEST = 'guestSettings/ADD_GUEST_TO_BOOKING',
  ADD_GUEST_SUCCESS = 'guestSettings/ADD_GUEST_TO_BOOKING',
}

export default ActionTypes;
