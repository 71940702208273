export const HOURLY_CHARGE_REPORTS_KEY = 'hourlyChargeReport';
export const FETCH_HOURLY_CHARGE_REPORT =
  'hourlyChargeReport/FETCH_HOURLY_CHARGE_REPORT';
export const FETCH_HOURLY_CHARGE_REPORT_SUCCESS =
  'hourlyChargeReport/FETCH_HOURLY_CHARGE_REPORT_SUCCESS';
export const FETCH_HOURLY_CHARGE_REPORT_FAIL =
  'hourlyChargeReport/FETCH_HOURLY_CHARGE_REPORT_FAIL';

export const RESET_HOURLY_CHARGE_REPORT_RESULT =
  'hourlyChargeReport/RESET_HOURLY_CHARGE_REPORT_RESULT';
