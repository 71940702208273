export const HOURLY_PAYMENT_REPORTS_KEY = 'hourlyPaymentReports';

export const FETCH_HOURLY_PAYMENT_REPORT =
  'hourlyPaymentReports/FETCH_HOURLY_PAYMENT_REPORT';
export const FETCH_HOURLY_PAYMENT_REPORT_SUCCESS =
  'hourlyPaymentReports/FETCH_HOURLY_PAYMENT_REPORT_SUCCESS';
export const FETCH_HOURLY_PAYMENT_REPORT_FAIL =
  'hourlyPaymentReports/FETCH_HOURLY_PAYMENT_REPORT_FAIL';

export const RESET_HOURLY_PAYMENT_REPORT_RESULT =
  'hourlyPaymentReports/RESET_HOURLY_PAYMENT_REPORT_RESULT';
