import * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Trend from '../Trend';

export interface StatisticProps {
  down?: boolean;
  percent: number;
  className?: string;
  sub?: React.ReactNode;
}

const SubContainer = styled.div`
  ${tw`uppercase text-2xs text-grey-darker`}
  margin-top: 2px;
`;

const Statistic: React.FC<StatisticProps> = ({
  down = false,
  className = '',
  percent,
  sub,
  children,
}) => {
  return (
    <div className={className} data-testid="statistic">
      <div className="flex items-end leading-none">
        <span className="font-medium text-sm mr-2">{children}</span>
        <Trend down={down} percent={percent} />
      </div>
      {sub && <SubContainer>{sub}</SubContainer>}
    </div>
  );
};

export default Statistic;
