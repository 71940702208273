import React, { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  makeSelectCurrentHotel,
  makeSelectCurrencies,
} from 'containers/Dashboard/selectors';
import { makeSelectPaymentTypes } from 'containers/BookingProvider/selector';
import { fetchPaymentTypes } from 'containers/BookingProvider/actions';
import { useInjectReducer } from 'utils/injectReducer';
import { BOOKING_KEY } from 'containers/BookingProvider/constants';
import bookingsReducer from 'containers/BookingProvider/reducer';
import bookingsSaga from 'containers/BookingProvider/saga';
import { useInjectSaga } from 'utils/injectSaga';
import { addHourlyBookingRefund } from '../../actions';
import InputNumberFormat from 'components/InputNumberFormat';
import { Modal, Select } from '@aha/ui';

export const InnerForm = ({
  paymentTypes,
  currency,
  currencies,
  balance,
  getFieldDecorator,
  intl,
}) => (
  <Form layout="vertical">
    <div className="flex flex-wrap -mx-2">
      <div className="col w-full px-2 md:w-1/2">
        <Form.Item
          label={
            <FormattedMessage
              defaultMessage="Amount"
              id="common.label.amount"
            />
          }
        >
          {getFieldDecorator('amount', {
            rules: [
              {
                required: true,
                validator(rule, value, cb) {
                  if (isNaN(value)) {
                    return cb(
                      <FormattedMessage
                        defaultMessage="Invalid value"
                        id="common.label.invalidValue"
                      />,
                    );
                  }
                  if (Math.abs(+value) > Math.abs(balance)) {
                    return cb(
                      <FormattedMessage
                        id="common.message.maxValueIs"
                        defaultMessage="Maximum value is {max}"
                        values={{
                          max: new Intl.NumberFormat('en-US').format(
                            Math.abs(balance),
                          ),
                        }}
                      />,
                    );
                  }
                  return cb();
                },
              },
            ],
          })(<InputNumberFormat />)}
        </Form.Item>
      </div>
      <div className="col w-full px-2 md:w-1/2">
        <Form.Item
          label={
            <FormattedMessage
              defaultMessage="Currency"
              id="common.label.currency"
            />
          }
        >
          {getFieldDecorator('currencyId', {
            initialValue: currencies.find((c) => c.name === currency)?.id,
          })(
            <Select
              placeholder={
                <FormattedMessage
                  defaultMessage="Select currency"
                  id="bookings.label.select_currency"
                />
              }
            >
              {currencies.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
      </div>
    </div>
    <div className="flex flex-wrap -mx-2">
      <div className="w-full px-2 pt-3">
        <Form.Item
          label={
            <FormattedMessage
              defaultMessage="Payment type"
              id="common.label.paymentType"
            />
          }
        >
          {getFieldDecorator('paymentType', {
            rules: [
              {
                required: !isNaN(balance) && balance !== 0,
                message: (
                  <FormattedMessage
                    id="common.message.required"
                    defaultMessage="Required"
                  />
                ),
              },
            ],
          })(
            <Select
              showSearch
              placeholder={
                <FormattedMessage
                  defaultMessage="Select payment type"
                  id="bookings.label.selectPaymentType"
                />
              }
            >
              {paymentTypes.map((pt) => (
                <Select.OptGroup key={pt.id} label={pt.name}>
                  {(pt.subTypes || []).map((p) => (
                    <Select.Option
                      key={p.id}
                      value={`${pt.id};;;${p.id}`}
                      title={`${pt.name} - ${p.name}`}
                    >
                      {p.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>,
          )}
        </Form.Item>
      </div>
      <div className="px-2 w-full">
        <Form.Item
          label={
            <FormattedMessage defaultMessage="Notes" id="common.label.notes" />
          }
        >
          {getFieldDecorator('refundNote', { rules: [{ required: false }] })(
            <Input />,
          )}
        </Form.Item>
      </div>
    </div>
  </Form>
);
const mapStateToProps = createStructuredSelector({
  hotel: makeSelectCurrentHotel(),
  currencies: makeSelectCurrencies(),
  paymentTypes: makeSelectPaymentTypes(),
});

function mapDispatchToProps(dispatch) {
  return {
    doFetchPaymentTypes: (hid) => dispatch(fetchPaymentTypes(hid)),
    doAddRefund: (bid, reqData) =>
      new Promise((resolve, reject) =>
        dispatch(addHourlyBookingRefund(bid, reqData, resolve, reject)),
      ),
  };
}
function AddRefundModal(props) {
  const dispatch = useDispatch();
  const { currencies, hotel, paymentTypes } = useSelector(mapStateToProps);
  const { doFetchPaymentTypes, doAddRefund } = mapDispatchToProps(dispatch);
  useInjectReducer({ key: BOOKING_KEY, reducer: bookingsReducer });
  useInjectSaga({ key: BOOKING_KEY, saga: bookingsSaga });

  const {
    bid,
    balance,
    currency,
    visible,
    onOk,
    onCancel,
    form,
    ...rest
  } = props;
  const { validateFields, getFieldDecorator } = form;
  const [isCreating, setIsCreating] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    if (visible) {
      doFetchPaymentTypes(hotel.id);
    }
  }, [hotel.id, visible]); // eslint-disable-line

  async function onAddRefund(e) {
    try {
      e.preventDefault();
      const values = await validateFields();
      const { paymentType } = values;
      const reqData = {
        ...values,
        paymentTypeId: +paymentType?.split(';;;')[0],
        paymentSubtypeId: +paymentType?.split(';;;')[1],
      };
      setIsCreating(true);
      await doAddRefund(bid, reqData);
      onCancel(true);
    } catch (err) {
      console.error(err);
    } finally {
      setIsCreating(false);
    }
  }

  function onCanCel() {
    onCancel(true);
  }

  return (
    <Modal
      {...rest}
      visible={visible}
      title={
        <FormattedMessage
          defaultMessage="Refund payment"
          id="bookings.actions.refundPayment"
        />
      }
      width={535}
      submitText={
        <FormattedMessage
          defaultMessage="Add refund"
          id="bookings.actions.addRefund"
        />
      }
      submitting={isCreating}
      onCancel={onCanCel}
      onSubmit={onAddRefund}
    >
      <InnerForm
        paymentTypes={paymentTypes}
        currency={currency}
        currencies={currencies}
        balance={balance}
        getFieldDecorator={getFieldDecorator}
        intl={intl}
      />
    </Modal>
  );
}

export default Form.create({ name: 'add_refund_modal' })(AddRefundModal);
