import React from 'react';
import Exception from 'components/Exception';
import { FormattedMessage } from 'react-intl';

export default function NotFoundPage() {
  return (
    <Exception
      message={
        <FormattedMessage
          defaultMessage="Sorry...page not found"
          id="exception.404.message"
        />
      }
    />
  );
}
