enum ActionTypes {
  HOTELS_KEY = 'hotels',

  FETCH_HOTELS = 'hotels/FETCH_HOTELS',
  FETCH_HOTELS_SUCCESS = 'hotels/FETCH_HOTELS_SUCCESS',
  FETCH_HOTELS_FAIL = 'hotels/FETCH_HOTELS_FAIL',

  FETCH_CURRENT_HOTEL = 'hotels/FETCH_CURRENT_HOTEL',
  FETCH_CURRENT_HOTEL_SUCCESS = 'hotels/FETCH_CURRENT_HOTEL_SUCCESS',
  FETCH_CURRENT_HOTEL_FAIL = 'hotels/FETCH_CURRENT_HOTEL_FAIL',

  UPDATE_HOTEL_STATUS = 'hotels/UPDATE_HOTEL_STATUS',
  UPDATE_HOTEL_STATUS_SUCCESS = 'hotels/UPDATE_HOTEL_STATUS_SUCCESS',
  UPDATE_HOTEL_STATUS_FAIL = 'hotels/UPDATE_HOTEL_STATUS_FAIL',

  CREATE_HOTEL = 'hotels/CREATE_HOTEL',
  CREATE_HOTEL_SUCCESS = 'hotels/CREATE_HOTEL_SUCCESS',
  CREATE_HOTEL_FAIL = 'hotels/CREATE_HOTEL_FAIL',

  FETCH_HOTEL_DETAILS = 'hotels/FETCH_HOTEL_DETAILS',
  FETCH_HOTEL_DETAILS_SUCCESS = 'hotels/FETCH_HOTEL_DETAILS_SUCCESS',
  FETCH_HOTEL_DETAILS_FAIL = 'hotels/FETCH_HOTEL_DETAILS_FAIL',

  EDIT_HOTEL = 'hotels/EDIT_HOTEL',
  EDIT_HOTEL_SUCCESS = 'hotels/EDIT_HOTEL_SUCCESS',
  EDIT_HOTEL_FAIL = 'hotels/EDIT_HOTEL_FAIL',

  ADD_CMS = 'hotels/ADD_CMS',
  ADD_CMS_SUCCESS = 'hotels/ADD_CMS_SUCCESS',
  ADD_CMS_ERROR = 'hotels/ADD_CMS_ERROR',

  ADD_EXTRANET = 'hotels/ADD_EXTRANET',
  ADD_EXTRANET_SUCCESS = 'hotels/ADD_EXTRANET_SUCCESS',
  ADD_EXTRANET_ERROR = 'hotels/ADD_EXTRANET_ERROR',

  FETCH_FEATURES = 'hotels/FETCH_FEATURES',
  FETCH_FEATURES_SUCCESS = 'hotels/FETCH_FEATURES_SUCCESS',
  FETCH_FEATURES_ERROR = 'hotels/FETCH_FEATURES_ERROR',

  ACTIVE_FEATURE = 'hotels/ACTIVE_FEATURE',
  DEACTIVE_FEATURE = 'hotels/DEACTIVE_FEATURE',
  TOGGLE_FEATURE = 'hotels/TOGGLE_FEATURE',

  UPLOAD_IMAGE = 'hotels/UPLOAD_IMAGE',
  UPLOAD_IMAGE_SUCCESS = 'hotels/UPLOAD_IMAGE_SUCCESS',
}

export default ActionTypes;
