import produce from 'immer';
import ActionTypes from './constants';
import { DEFAULT_LOCALE, appLocales } from '../../i18n.async';
import { LanguageProviderState, LanguageProviderActions } from './type';
import { Language } from 'i18n';

const lan = window.localStorage.getItem('lan');

export const initialState = {
  locale: (lan && appLocales.includes(lan) ? lan : DEFAULT_LOCALE) as Language,
  global: true,
};

/* eslint-disable default-case, no-param-reassign */
const languageProviderReducer = (
  state: LanguageProviderState = initialState,
  action: LanguageProviderActions,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.CHANGE_LOCALE:
        draft.locale = action.payload.locale;
        break;
    }
  });

export default languageProviderReducer;
