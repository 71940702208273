// Lockdoor utils function
import moment from 'moment';
import { lockDoorAPI } from 'utils/request';
import { showErrorNotification, showSuccessNotification } from '@aha/utils';

const CHECKED_IN_ROOM = 'CHECKED_IN_ROOM';
const CHECKED_OUT_ROOM = 'CHECKED_OUT_ROOM';
const REPORTED_LOST_CARD = 'REPORTED_LOST_CARD';

function status() {
  return lockDoorAPI.get('/api/v1/status');
}

function removeCached() {
  localStorage.removeItem(CHECKED_IN_ROOM);
  localStorage.removeItem(CHECKED_OUT_ROOM);
  localStorage.removeItem(REPORTED_LOST_CARD);
}

async function checkin(booking, roomNumber) {
  try {
    const checkedInRoomNo = localStorage.getItem(CHECKED_IN_ROOM);
    if (checkedInRoomNo !== roomNumber) {
      await status();
      const { data } = await lockDoorAPI.post('/api/v1/checkin', {
        roomNumber,
        buildingNo: '01',
        guestName: booking?.guests?.[0]?.fullName || booking?.guest?.fullName,
        checkinTime: moment(booking.arrival).toISOString(),
        checkoutTime: moment(booking.departure)
          .set({ hour: 23, minute: 48 })
          .toISOString(),
        bookingID: `NOT_USE;;;${booking.id}`,
      });
      localStorage.setItem(CHECKED_IN_ROOM, roomNumber);
      showSuccessNotification('LockDoor: Checked in booking!');
      return data;
    }
  } catch (err) {
    showErrorNotification('LockDoor: checkin booking failed', err);
    throw err;
  }
}

async function checkout(roomNumber) {
  try {
    // skip group booking
    const checkedInRoomNo = localStorage.getItem(CHECKED_OUT_ROOM);
    const isLostCard = localStorage.getItem(REPORTED_LOST_CARD);
    if (checkedInRoomNo !== roomNumber && isLostCard !== roomNumber) {
      await status();
      // Note: checkout not erase card, so when checkout we need to erase
      // const { data } = await lockDoorAPI.get('/api/v1/checkout');
      const data = await eraseCard(roomNumber);
      localStorage.setItem(CHECKED_OUT_ROOM, roomNumber);
      showSuccessNotification('LockDoor: Checked out booking!');
      return data;
    }
  } catch (err) {
    showErrorNotification('LockDoor: checkout booking failed', err);
    throw err;
  }
}
async function dupKey(booking, roomNumber) {
  try {
    await status();
    const { data } = await lockDoorAPI.post('/api/v1/dupkey', {
      roomNumber,
      buildingNo: '01',
      guestName: booking?.guests?.[0]?.fullName || booking?.guest?.fullName,
      checkinTime: moment(booking.arrival).toISOString(),
      checkoutTime: moment(booking.departure)
        .set({ hour: 23, minute: 48 })
        .toISOString(),
      bookingID: `NOT_USE;;;${booking.id}`,
    });
    showSuccessNotification('LockDoor: Created new lockdoor key!');
    return data;
  } catch (err) {
    showErrorNotification('LockDoor: dubplicate key failed', err);
    throw err;
  }
}

async function readCard() {
  try {
    // For test only
    // return { roomNumber: '010102', cardno: 0 };
    await status();
    const {
      data: { data },
    } = await lockDoorAPI.get('/api/v1/read-card');
    console.log(data);
    return data;
  } catch (err) {
    showErrorNotification('LockDoor: read card failed', err);
    throw err;
  }
}

async function eraseCard(lockDoorRoomNo) {
  try {
    await status();
    // TODO check this
    const { cardno, roomNumber } = await readCard();
    if (roomNumber !== lockDoorRoomNo) {
      throw new Error('Invalid card. The card is not issued for this booking!');
    }

    const { data } = await lockDoorAPI.post('/api/v1/erase-card', {
      roomNumber: lockDoorRoomNo,
      cardNo: +cardno || 0,
    });

    showSuccessNotification('LockDoor: card was erased!');
    return data;
  } catch (err) {
    showErrorNotification('LockDoor: erase card failed', err);
    throw err;
  }
}
async function lostCard(roomNumber) {
  try {
    await status();
    const rn = localStorage.getItem(REPORTED_LOST_CARD);
    if (!rn && rn !== roomNumber) {
      await lockDoorAPI.post('/api/v1/lost-card', { roomNumber });
      localStorage.setItem(REPORTED_LOST_CARD, roomNumber);
    }
    showSuccessNotification('LockDoor: reported!');
  } catch (err) {
    showErrorNotification('LockDoor: report lost card failed', err);
    throw err;
  }
}

const LockDoorAPI = {
  lostCard,
  eraseCard,
  readCard,
  checkout,
  checkin,
  status,
  removeCached,
  dupKey,
};

export default LockDoorAPI;
