import React from 'react';
import { MembershipBadgeProps } from '@aha/ui/src/components/MembershipBadge';
import { MembershipBadge } from '@aha/ui';
import { FormattedMessage } from 'react-intl';

export const LMembershipBadge: React.FC<MembershipBadgeProps> = ({
  type = 'non-member',
}) => {
  let renderText: React.ReactNode;

  if (type === 'non-member') {
    renderText = (
      <FormattedMessage
        defaultMessage="Membership is not applicable"
        id="membership.label.notApplicable"
      />
    );
  } else if (type === 'member') {
    renderText = (
      <FormattedMessage defaultMessage="Member" id="membership.label.member" />
    );
  } else if (type === 'vip-member') {
    renderText = (
      <FormattedMessage defaultMessage="Vip" id="membership.label.vip" />
    );
  }

  return <MembershipBadge type={type} text={renderText} />;
};

export default LMembershipBadge;
