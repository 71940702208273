/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */
import produce from 'immer';
import ActionTypes from './constants';
import {
  SegmentCodeSettingsState,
  SegmentCodeSettingsActions,
} from 'containers/SegmentCodeSettings/types';

export const initialState: SegmentCodeSettingsState = {
  loading: false,
  error: null,
  items: null,
};

/* eslint-disable default-case, no-param-reassign */
const marketingSourceReducer = (
  state: SegmentCodeSettingsState = initialState,
  action: SegmentCodeSettingsActions,
): SegmentCodeSettingsState =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.FETCH_MARKETING_SOURCES:
        draft.loading = true;
        draft.error = null;
        draft.items = null;
        break;

      case ActionTypes.FETCH_MARKETING_SOURCES_SUCCESS:
        draft.loading = false;
        draft.items = action.payload.filter((curr) => curr.status === 'active');
        draft.error = null;
        break;

      case ActionTypes.FETCH_MARKETING_SOURCES_FAIL:
        draft.error = action.payload;
        break;

      case ActionTypes.RESET_MARKETING_SOURCES_STATE:
        draft.error = null;
        draft.loading = false;
        draft.items = null;
        break;

      case ActionTypes.DELETE_MARKETING_SOURCE:
        break;

      case ActionTypes.DELETE_MARKETING_SOURCE_SUCCESS:
        if (draft.items) {
          draft.items = draft.items.filter(({ id }) => id !== action.payload);
        }
        break;

      case ActionTypes.DELETE_MARKETING_SOURCE_FAIL:
        draft.error = action.payload;
        draft.loading = false;
        break;

      case ActionTypes.CREATE_MARKETING_SOURCE:
        break;

      case ActionTypes.CREATE_MARKETING_SOURCE_SUCCESS:
        if (draft.items) {
          draft.items.unshift(action.payload);
        } else {
          draft.items = [action.payload];
        }
        break;

      case ActionTypes.CREATE_MARKETING_SOURCE_FAIL:
        draft.error = action.payload;
        draft.loading = false;
        break;

      case ActionTypes.EDIT_MARKETING_SOURCE:
        break;

      case ActionTypes.EDIT_MARKETING_SOURCE_SUCCESS:
        if (draft.items) {
          const index = draft.items.findIndex(
            ({ id }) => id === action.payload.id,
          );
          draft.items[index] = action.payload;
        } else {
          draft.items = [action.payload];
        }

        break;

      case ActionTypes.EDIT_MARKETING_SOURCE_FAIL:
        draft.error = action.payload;
        draft.loading = false;
        break;
    }
  });

export default marketingSourceReducer;
