import React, { useMemo } from 'react';
import { Dropdown, Menu } from 'antd';
import styled from 'styled-components';
import tw from 'twin.macro';
import { ClickParam } from 'antd/lib/menu';
import Avatar from '../Avatar';
import { FormattedMessage } from 'react-intl';

const MenuStyled = styled(Menu)`
  position: relative;
  min-width: 270px;
  &:before {
    ${tw`bg-white block absolute`};
    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.025);
    content: '';
    top: -6px;
    right: 10px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
  }
`;

interface MenuItem {
  text: React.ReactNode;
  action?: (param: ClickParam) => void;
}

export interface AvatarDropdownProps {
  avatar?: string;
  name: string;
  email: string;
  menu: Array<MenuItem>;
  userAction?: () => void;
  logOut: () => void;
}

const AvatarDropdown = ({
  avatar = '',
  name,
  email,
  menu,
  logOut,
  userAction,
}: AvatarDropdownProps) => {
  const listMenu = useMemo(
    () => (
      <MenuStyled className="mt-5 pt-4 pb-3 shadow">
        <Menu.Item
          className="flex items-center content-center pl-6 py-0 hover:bg-white outline-none"
          onClick={() => userAction && userAction()}
        >
          <Avatar
            imgUrl={avatar}
            placeholder={<span className="font-medium text-xs">{name[0]}</span>}
            size={40}
            className="mr-2"
          />
          <div className="flex flex-col justify-around">
            <p className="text-sm text-secondary mb-0 no-trailing">{name}</p>
            <p className="text-xs text-grey-darker mb-0 leading-tight">
              {email}
            </p>
          </div>
        </Menu.Item>
        <Menu.Divider className="mx-6 mt-4" />
        {menu.map((item, index) => (
          <Menu.Item
            className="pl-6 hover:grey-lighter py-1 "
            key={index}
            onClick={item.action}
          >
            <span>{item.text}</span>
          </Menu.Item>
        ))}
        <Menu.Divider className="mx-6" />
        <Menu.Item
          className="pl-6 py-1 hover:grey-lighter"
          key="logout"
          onClick={logOut}
        >
          <FormattedMessage defaultMessage="Log out" id="common.label.logOut" />
        </Menu.Item>
      </MenuStyled>
    ),
    [], // eslint-disable-line
  );

  return (
    <Dropdown overlay={listMenu} placement="bottomRight">
      <div className="h-8 w-8 mr-0 flex items-center justify-center">
        <Avatar
          imgUrl={avatar}
          placeholder={<span className="font-medium text-xs">{name[0]}</span>}
          size={32}
        />
      </div>
    </Dropdown>
  );
};

export default AvatarDropdown;
