import React, { useState } from 'react';
import { Form, Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Modal, Button, NAFallback } from '@aha/ui';
import { Dispatch } from 'redux';
import { Folio, FolioSeparateRequestBody } from 'types/schema';
import { useDispatch } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { separateFolios } from 'containers/BookingProvider/actions';
import { validRooms } from 'containers/BookingProvider/helpers/folios';
import { uniqBy } from 'utils/array';
import { NOT_AVAILABLE } from '@aha/constants';
import { Booking, BookingRoomDetail } from 'types/schema';

export type SeparateFoliosProps = {
  booking: Booking;
  folio?: Folio;
  visible?: boolean;
  onClose?: (...args: any[]) => any;
} & FormComponentProps;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doSeparateFolios: (bid: number, fid: number, body: any) =>
    new Promise((resolve, reject) =>
      dispatch(separateFolios(bid, fid, body, resolve, reject)),
    ),
});

const SeparateFoliosModal: React.SFC<SeparateFoliosProps> = ({
  booking,
  folio,
  visible,
  onClose,
  form,
}) => {
  const dispatch = useDispatch();
  const { doSeparateFolios } = mapDispatchToProps(dispatch);

  const [submitting, setSubmitting] = useState(false);
  const { resetFields, validateFields, getFieldDecorator } = form;
  const { rooms, id: bookingID } = booking || {};

  const separateRooms: BookingRoomDetail[] = uniqBy(
    folio?.charges || [],
    'bookingRoomId',
  )?.reduce((res: Array<BookingRoomDetail>, c) => {
    if (
      c.bookingRoomId &&
      folio?.bookingRoomId !== c.bookingRoomId &&
      c.status === 'active'
    ) {
      const room = validRooms(rooms)?.find((r) => r.id === c.bookingRoomId);
      return room ? [...res, room] : res;
    }
    return res;
  }, []);

  function onCancel() {
    if (submitting) {
      return;
    }

    onClose && onClose();
    resetFields();
  }

  async function onAdd() {
    validateFields(
      async (err, { bookingRoomIds }: FolioSeparateRequestBody) => {
        if (err) {
          return;
        } else if (!bookingID || !folio?.id) {
          console.error('Booking not found!');
          return;
        }

        try {
          setSubmitting(true);

          await doSeparateFolios(bookingID, folio.id, {
            bookingRoomIds,
            sourceFolioId: folio.id,
          });
          onClose && onClose(true);
          resetFields();
        } catch (e) {
          console.error(e);
        } finally {
          setSubmitting(false);
        }
      },
    );
  }

  const room = (rooms?.find((r) => r.id === folio?.bookingRoomId) ||
    {}) as BookingRoomDetail;

  return (
    <Modal
      visible={visible}
      title={
        <FormattedMessage
          defaultMessage="Separate folio from: {title}"
          id="bookings.label.separateFolioFrom"
          values={{
            title: !folio?.isMaster ? (
              [room?.refCode, <NAFallback value={room?.roomName} />]
                .filter(Boolean)
                .join(' - ')
            ) : (
              <FormattedMessage
                defaultMessage="Master Folio"
                id="bookings.label.masterFolio"
              />
            ),
          }}
        />
      }
      submitting={submitting}
      onCancel={onCancel}
      onSubmit={onAdd}
      footer={
        <div className="h-full flex justify-end items-center px-2">
          <div>
            <Button
              className="px-9/2 h-8 w-32 mr-4"
              onClick={onAdd}
              disabled={submitting}
            >
              <FormattedMessage
                defaultMessage="Submit"
                id="common.label.submit"
              />
            </Button>
            <Button
              type="line"
              className="px-9/2 h-8 w-32"
              onClick={onCancel}
              disabled={submitting}
            >
              <FormattedMessage
                defaultMessage="Cancel"
                id="common.action.cancel"
              />
            </Button>
          </div>
        </div>
      }
    >
      <Form layout="vertical">
        <Form.Item
          label={
            <FormattedMessage
              defaultMessage="Select rooms that need to separate from {title}"
              id="bookings.label.selectTransferFolio"
              values={{
                title: !folio?.isMaster ? (
                  <FormattedMessage
                    defaultMessage="room {content}"
                    id="bookings.label.roomContent"
                    values={{
                      content: [room?.refCode, room?.roomName || NOT_AVAILABLE]
                        .filter(Boolean)
                        .join(' - '),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Master Folio"
                    id="bookings.label.masterFolio"
                  />
                ),
              }}
            />
          }
        >
          {getFieldDecorator('bookingRoomIds', {
            initialValue:
              separateRooms?.length === 1 ? [separateRooms[0].id] : undefined,
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="common.message.required"
                    defaultMessage="Required"
                  />
                ),
              },
            ],
          })(
            <Checkbox.Group className="mt-4 w-full">
              {separateRooms?.map((r) => (
                <Checkbox
                  className="block leading-normal font-normal h-8 ml-0"
                  key={r.id}
                  value={r.id}
                >
                  <span className="text-sm">
                    {[r.refCode, r.roomName].filter(Boolean).join(' - ')}
                  </span>
                </Checkbox>
              ))}
            </Checkbox.Group>,
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create<SeparateFoliosProps>({
  name: 'separate_folios_form',
})(SeparateFoliosModal);
