import { takeEvery, put, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { checkinBookingSuccess, checkinBookingFail } from '../actions';
import { CHECK_IN_BOOKING } from '../constants';
import { showErrorNotification } from '@aha/utils';
import LockDoorAPI from '../loockdoor';

function* doCheckin({ booking, bookingRoomId, reject, resolve, lockdoor }) {
  try {
    if (lockdoor) {
      yield call(
        LockDoorAPI.checkin,
        booking,
        booking?.rooms[0]?.lockDoorRoomNo,
      );
    }
    const req = {};
    if (bookingRoomId) {
      req.bookingRoomId = +bookingRoomId;
    }
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/bookings/${booking?.id}/checkin`,
      req,
    );
    yield put(checkinBookingSuccess(data, bookingRoomId));

    LockDoorAPI.removeCached();
    resolve(data);
  } catch (err) {
    yield put(checkinBookingFail(err));
    showErrorNotification('Checkin booking failed', err);
    reject(err);
  }
}

export function* watchCheckinBooking() {
  yield takeEvery(CHECK_IN_BOOKING, doCheckin);
}
