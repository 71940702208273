import { takeEvery, put, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { cancelBookingSuccess, cancelBookingFail } from '../actions';
import { CANCEL_BOOKING } from '../constants';
import { showErrorNotification } from '@aha/utils';
import LockDoorAPI from '../loockdoor';

function* doCancelBooking({ booking, reject, resolve, lockdoor }) {
  try {
    if (lockdoor && booking.status === 'checked_in') {
      yield call(LockDoorAPI.eraseCard, booking.rooms?.[0]?.lockDoorRoomNo);
    }
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/bookings/${booking.id}/cancel`,
    );
    yield put(cancelBookingSuccess(data));
    LockDoorAPI.removeCached();
    resolve(data);
  } catch (err) {
    yield put(cancelBookingFail(err));
    showErrorNotification('Cancel booking failed', err);
    reject(err);
  }
}

export function* watchCancelBooking() {
  yield takeEvery(CANCEL_BOOKING, doCancelBooking);
}
