import moment from 'moment';
export type DatetimeFormat =
  | 'HH:mm, DD MMM YYYY'
  | 'HH:mm:ss, DD MMM, YYYY'
  | 'DD MMM YYYY, HH:mm'
  | 'DD MMM H:mma'
  | 'DD MMMM YYYY'
  | 'DD MMM YYYY'
  | 'DD MM YYYY'
  | 'DD/MM/YYYY'
  | 'YYYY/MM/DD'
  | 'DD-MM-YYYY'
  | 'DD_MM_YYYY'
  | 'DD MMM'
  | 'DD/MMM'
  | 'MMMM YYYY'
  | 'MMM YYYY'
  | 'MM YYYY'
  | 'MMM Do, YYYY'
  | 'MMMM Do, YYYY'
  | 'MM'
  | 'M'
  | 'YYYY-MM-DD'
  | 'YYYY'
  | 'HH:mm'
  | 'ddd DD MMM'
  | 'ddd DD'
  | 'DD';

export default function formatDatetime(value: any, format: DatetimeFormat) {
  return moment(value).format(format);
}
