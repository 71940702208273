import React from 'react';
import { Popconfirm } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { GlobalStyles } from './styled';
import { IconExclamationCircle } from '@aha/icons';

export interface PopupConfirmProps extends PopconfirmProps {
  okText?: React.ReactNode | string;
  cancelText?: React.ReactNode | string;
}

export const PopupConfirm: React.FC<PopconfirmProps> = ({
  title,
  onConfirm,
  onCancel,
  okText,
  cancelText,
  placement,
  okButtonProps,
  children,
}) => {
  return (
    <>
      <GlobalStyles />
      <Popconfirm
        overlayClassName="confirmation-popup"
        icon={<IconExclamationCircle className="w-4 h-4 text-yellow" />}
        title={title}
        placement={placement}
        onConfirm={onConfirm}
        onCancel={onCancel}
        okText={okText}
        cancelText={cancelText}
        okButtonProps={okButtonProps}
      >
        {children}
      </Popconfirm>
    </>
  );
};

export default PopupConfirm;
