import {
  FETCH_HOURLY_BOOKING,
  FETCH_HOURLY_BOOKING_SUCCSESS,
  VOID_HOURLY_BOOKING_CHARGE,
  VOID_HOURLY_BOOKING_CHARGE_SUCCESS,
  VOID_HOURLY_BOOKING_CHARGE_FAIL,
  ADD_HOURLY_BOOKING_CHARGE,
  ADD_HOURLY_BOOKING_CHARGE_SUCCESS,
  ADD_HOURLY_BOOKING_CHARGE_FAIL,
  VOID_HOURLY_BOOKING_PAYMENT,
  VOID_HOURLY_BOOKING_PAYMENT_FAIL,
  VOID_HOURLY_BOOKING_PAYMENT_SUCCESS,
  CHANGE_HOURLY_BOOKING_ROOM,
  CHANGE_HOURLY_BOOKING_ROOM_SUCCESS,
  CHANGE_HOURLY_BOOKING_ROOM_FAIL,
  VOID_HOURLY_BOOKING,
  ADD_HOURLY_BOOKING_PAYMENT,
  CHECKOUT_HOURLY_BOOKING,
  FETCH_HOURLY_BOOKING_CHANGE_LOGS,
  FETCH_HOURLY_BOOKING_CHANGE_LOGS_SUCCSESS,
  FETCH_HOURLY_BOOKING_CHANGE_LOGS_FAIL,
  VOID_HOURLY_BOOKING_SUCCSESS,
  CHECKOUT_HOURLY_BOOKING_SUCCESS,
  FETCH_HOURLY_BOOKING_FAIL,
  ADD_HOURLY_BOOKING_DISCOUNT,
  ADD_HOURLY_BOOKING_REFUND,
  REMOVE_BOOKING_DETAIL,
} from './constants';

export function fetchHourlyBooking(bid) {
  return {
    type: FETCH_HOURLY_BOOKING,
    bid,
  };
}
export function fetchHourlyBookingSuccess(booking) {
  return {
    type: FETCH_HOURLY_BOOKING_SUCCSESS,
    booking,
  };
}
export function fetchHourlyBookingFail(error) {
  return {
    type: FETCH_HOURLY_BOOKING_FAIL,
    error,
  };
}

export function fetchHourlyBookingChangeLogs(bid) {
  return {
    type: FETCH_HOURLY_BOOKING_CHANGE_LOGS,
    bid,
  };
}
export function fetchHourlyBookingChangeLogsSuccess(changeLogs) {
  return {
    type: FETCH_HOURLY_BOOKING_CHANGE_LOGS_SUCCSESS,
    changeLogs,
  };
}
export function fetchHourlyBookingChangeLogsFail(error) {
  return {
    type: FETCH_HOURLY_BOOKING_CHANGE_LOGS_FAIL,
    error,
  };
}

export function voidHourlyBookingCharge(bid, chargeID, resolve, reject) {
  return {
    type: VOID_HOURLY_BOOKING_CHARGE,
    bid,
    chargeID,
    resolve,
    reject,
  };
}

export function voidHourlyBookingChargeSuccess(booking) {
  return {
    type: VOID_HOURLY_BOOKING_CHARGE_SUCCESS,
    booking,
  };
}

export function voidHourlyBookingChargeFail(error) {
  return {
    type: VOID_HOURLY_BOOKING_CHARGE_FAIL,
    error,
  };
}

export function addHourlyBookingCharges(bid, reqData, resolve, reject) {
  return {
    type: ADD_HOURLY_BOOKING_CHARGE,
    bid,
    reqData,
    resolve,
    reject,
  };
}

export function addHourlyBookingChargesSuccess(booking) {
  return {
    type: ADD_HOURLY_BOOKING_CHARGE_SUCCESS,
    booking,
  };
}

export function addHourlyBookingChargesFail(error) {
  return {
    type: ADD_HOURLY_BOOKING_CHARGE_FAIL,
    error,
  };
}

export function voidHourlyBookingPayment(bid, paymentId, resolve, reject) {
  return {
    type: VOID_HOURLY_BOOKING_PAYMENT,
    bid,
    paymentId,
    resolve,
    reject,
  };
}

export function voidHourlyBookingPaymentSuccess(booking) {
  return {
    type: VOID_HOURLY_BOOKING_PAYMENT_SUCCESS,
    booking,
  };
}

export function voidHourlyBookingPaymentFail(error) {
  return {
    type: VOID_HOURLY_BOOKING_PAYMENT_FAIL,
    error,
  };
}

export function updateHourlyBookingRoom(bid, reqData, resolve, reject) {
  return {
    type: CHANGE_HOURLY_BOOKING_ROOM,
    bid,
    reqData,
    resolve,
    reject,
  };
}

export function updateHourlyBookingRoomSuccess(booking) {
  return {
    type: CHANGE_HOURLY_BOOKING_ROOM_SUCCESS,
    booking,
  };
}

export function updateHourlyBookingRoomFail(error) {
  return {
    type: CHANGE_HOURLY_BOOKING_ROOM_FAIL,
    error,
  };
}

export function voidHourlyBooking(booking, resolve, reject, lockdoor) {
  return {
    type: VOID_HOURLY_BOOKING,
    booking,
    resolve,
    reject,
    lockdoor,
  };
}

export function addHourlyBookingPayment(refCode, reqData, resolve, reject) {
  return {
    type: ADD_HOURLY_BOOKING_PAYMENT,
    refCode,
    reqData,
    resolve,
    reject,
  };
}

export function checkoutHourlyBooking(booking, resolve, reject, lockdoor) {
  return {
    type: CHECKOUT_HOURLY_BOOKING,
    booking,
    resolve,
    reject,
    lockdoor,
  };
}

export function voidHourlyBookingSuccess(booking) {
  return {
    type: VOID_HOURLY_BOOKING_SUCCSESS,
    booking,
  };
}

export function checkOutHourlyBookingSuccess(booking) {
  return {
    type: CHECKOUT_HOURLY_BOOKING_SUCCESS,
    booking,
  };
}

export function addHourlyBookingDiscount(bid, reqData, resolve, reject) {
  return {
    type: ADD_HOURLY_BOOKING_DISCOUNT,
    bid,
    reqData,
    resolve,
    reject,
  };
}

export function addHourlyBookingRefund(bid, reqData, resolve, reject) {
  return {
    type: ADD_HOURLY_BOOKING_REFUND,
    bid,
    reqData,
    resolve,
    reject,
  };
}

export function changeHourlyBookingRoom(
  booking,
  chargeId,
  roomId,
  lockDoorRoomNo,
  resolve,
  reject,
) {
  return {
    type: CHANGE_HOURLY_BOOKING_ROOM,
    booking,
    chargeId,
    roomId,
    resolve,
    reject,
    lockDoorRoomNo,
  };
}

export function removeBookingDetail() {
  return {
    type: REMOVE_BOOKING_DETAIL,
  };
}
