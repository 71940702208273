import { action } from 'typesafe-actions';
import ActionTypes from './constants';
import { ModelPagination } from 'types/v3-schema';
import { InjectPromise } from 'types/actions';
import { Guest } from 'types/schema';

export const fetchGuests = (
  pageNumber?: number,
  pageSize?: number,
  type?: string,
  value?: string,
) => action(ActionTypes.FETCH_GUESTS, { pageNumber, pageSize, type, value });

export const fetchGuestsSuccess = (
  guests: Guest[],
  pagination: ModelPagination,
) => action(ActionTypes.FETCH_GUESTS_SUCCESS, { guests, pagination });

export const fetchGuestsFailure = (error: Error) =>
  action(ActionTypes.FETCH_GUESTS_FAIL, error);

export const editGuest = (
  params: Required<InjectPromise<Guest, { guestId: number; guest: Guest }>>,
) => action(ActionTypes.EDIT_GUEST, params);

export const editGuestSuccess = (guest: Guest) =>
  action(ActionTypes.EDIT_GUEST_SUCCESS, guest);

export const addGuestSuccess = (guest: Guest) =>
  action(ActionTypes.ADD_GUEST_SUCCESS, guest);
