import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { editGuest } from '../actions';
import { useInjectSaga } from 'utils/injectSaga';
import { Modal, Select } from '@aha/ui';
import saga from '../saga';
import useSelectCountries from 'containers/CreateBooking/useSelectCountries';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { Guest } from 'types/schema';

export type EditGuestModalProps = {
  data?: Guest;
  visible?: boolean;
  onClose?: (...args: any[]) => any;
} & FormComponentProps;

type Country = {
  code: string;
  name: string;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doEditGuest: (guestId: number, guest: Guest) =>
    new Promise((resolve, reject) =>
      dispatch(editGuest({ data: { guestId, guest }, resolve, reject })),
    ),
});

const EditGuestModal: React.SFC<EditGuestModalProps> = ({
  data,
  visible,
  onClose,
  form,
}) => {
  useInjectSaga({ key: 'guestSettings', saga });

  const dispatch = useDispatch();
  const { doEditGuest } = mapDispatchToProps(dispatch);
  const { getFieldDecorator, resetFields, validateFields } = form;
  const [submitting, setSubmitting] = useState(false);
  const { countries } = useSelectCountries();

  const {
    id,
    address,
    city,
    countryCode,
    email,
    firstName,
    passport,
    //@ts-ignore
    lastname,
    phone,
    state,
    title,
  } = data || {};

  function onCancel() {
    if (submitting) {
      return;
    }

    onClose && onClose();
    resetFields();
  }

  function onEdit() {
    validateFields(async (err, values) => {
      if (err) {
        console.log('Received values of form: ', values);
        return;
      }
      try {
        setSubmitting(true);
        await doEditGuest(Number(id), values);

        onClose && onClose(true);
        resetFields();
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    });
  }

  return (
    <Modal
      visible={visible}
      title={
        <FormattedMessage
          defaultMessage="Edit the guest"
          id="settings.label.edit_guest"
        />
      }
      submitText={
        <FormattedMessage defaultMessage="Edit" id="common.action.edit" />
      }
      cancelText={
        <FormattedMessage defaultMessage="Cancel" id="common.action.cancel" />
      }
      submitting={submitting}
      onCancel={onCancel}
      onSubmit={onEdit}
      width={942}
    >
      <Form layout="vertical">
        <div className="flex flex-wrap -mx-2">
          <div className="col px-2 w-full md:w-1/4">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Passport/ID"
                  id="settings.label.password_id"
                />
              }
            >
              {getFieldDecorator('passport', {
                initialValue: passport,
              })(<Input allowClear placeholder="Enter passport/id" />)}
            </Form.Item>
          </div>
          <div className="col px-2 w-full md:w-1/4">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Title"
                  id="settings.label.title"
                />
              }
            >
              {getFieldDecorator('title', {
                initialValue: title,
              })(
                <Select placeholder="Select title">
                  {[
                    { key: 'mr', label: 'Mr' },
                    { key: 'mrs', label: 'Mrs' },
                    { key: 'ms', label: 'Ms' },
                  ].map((c) => (
                    <Select.Option key={c.key} value={c.key}>
                      {c.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </div>
          <div className="col px-2 w-full md:w-1/4">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="First name"
                  id="settings.label.first_name"
                />
              }
            >
              {getFieldDecorator('firstName', {
                initialValue: firstName,
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
              })(<Input allowClear placeholder="Enter first name" />)}
            </Form.Item>
          </div>
          <div className="col px-2 w-full md:w-1/4">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Last name"
                  id="settings.label.last_name"
                />
              }
            >
              {getFieldDecorator('lastName', {
                initialValue: lastname,
              })(<Input allowClear placeholder="Enter last name" />)}
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-wrap -mx-2">
          <div className="col px-2 w-full md:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Address"
                  id="settings.label.address"
                />
              }
            >
              {getFieldDecorator('address', {
                initialValue: address,
              })(<Input allowClear placeholder="Enter address" />)}
            </Form.Item>
          </div>
          <div className="col px-2 w-full md:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Phone Number"
                  id="settings.label.phone"
                />
              }
            >
              {getFieldDecorator('phone', {
                initialValue: phone,
              })(<Input allowClear placeholder="Enter phone" />)}
            </Form.Item>
          </div>
          <div className="col px-2 w-full md:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Email"
                  id="settings.label.email"
                />
              }
            >
              {getFieldDecorator('email', {
                initialValue: email,
                rules: [
                  {
                    type: 'email',
                    message: (
                      <FormattedMessage
                        id="common.message.invalid"
                        defaultMessage="Invalid"
                      />
                    ),
                  },
                ],
              })(<Input allowClear placeholder="Enter email" />)}
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-wrap -mx-2">
          <div className="col px-2 w-full md:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Country"
                  id="settings.label.country"
                />
              }
            >
              {getFieldDecorator('countryCode', {
                initialValue: countryCode,
              })(
                <Select placeholder="Select country" disabled={!countries}>
                  {((countries as Country[]) || []).map((c) => (
                    <Select.Option key={c.code} value={c.code}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </div>
          <div className="col px-2 w-full md:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="City"
                  id="settings.label.city"
                />
              }
            >
              {getFieldDecorator('city', {
                initialValue: city,
              })(<Input allowClear placeholder="Enter city" />)}
            </Form.Item>
          </div>
          <div className="col px-2 w-full md:w-1/3">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="State"
                  id="settings.label.state"
                />
              }
            >
              {getFieldDecorator('state', {
                initialValue: state,
              })(<Input allowClear placeholder="Enter state" />)}
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default Form.create<EditGuestModalProps>({ name: 'edit_guest_form' })(
  EditGuestModal,
);
