import { takeEvery, put, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { removeRoomSuccess, removeRoomFail } from '../actions';
import { REMOVE_ROOM_FROM_BOOKING } from '../constants';
import { showErrorNotification } from '@aha/utils';

function* doRemoveRoom({ bid, bookingRoomId, reject, resolve }) {
  try {
    const { data } = yield call(
      coreAPI.put,
      `v1/pms/bookings/${bid}/free-room`,
      {
        bookingRoomId,
      },
    );
    yield put(removeRoomSuccess(data));
    resolve(data);
  } catch (err) {
    yield put(removeRoomFail(err));
    showErrorNotification('Remove room failed', err);
    reject(err);
  }
}

export function* watchRemoveRoomFromBooking() {
  yield takeEvery(REMOVE_ROOM_FROM_BOOKING, doRemoveRoom);
}
