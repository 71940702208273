import ActionTypes from 'containers/RoomPlanPage/constants';
import { action } from 'typesafe-actions';
import { InjectPromise } from 'types/actions';
import { Room, RoomAvailabilityLog } from 'types/schema';

export const fetchRooms = (hid?: number, withBooking?: boolean) =>
  action(ActionTypes.FETCH_ROOMS, {
    hid,
    withBooking,
  });

export const fetchRoomsSuccess = (rooms: Room[]) =>
  action(ActionTypes.FETCH_ROOMS_SUCCESS, rooms);

export const fetchRefreshRooms = (hid?: number, withBooking?: boolean) =>
  action(ActionTypes.FETCH_REFRESH_ROOMS, {
    hid,
    withBooking,
  });

export const fetchRefreshRoomsSuccess = (rooms: Room[]) =>
  action(ActionTypes.FETCH_REFRESH_ROOMS_SUCCESS, rooms);

export const setActionsRefreshRooms = (isRefreshRoom: boolean) =>
  action(ActionTypes.SET_IS_REFRESH_ROOMS, isRefreshRoom);

export const fetchRoomsFail = (error: Error) =>
  action(ActionTypes.FETCH_ROOMS_FAIL, error);

export const updateRoomHouseKeepingStatus = (rid: number, status: string) =>
  action(ActionTypes.UPDATE_ROOM_HK_STATUS, {
    rid,
    status,
  });

export const updateRoomHouseKeepingStatusSuccess = (room: Room) =>
  action(ActionTypes.UPDATE_ROOM_HK_STATUS_SUCCESS, room);

export const updateRoomHouseKeepingStatusFail = (error: Error) =>
  action(ActionTypes.UPDATE_ROOM_HK_STATUS_FAIL, error);

export const openCreateLog = (openCreateLog: boolean) =>
  action(ActionTypes.OPEN_CREATE_LOG, openCreateLog);

export const fetchLogs = (roomId: number) =>
  action(ActionTypes.FETCH_LOGS, roomId);

export const fetchLogsSuccess = (roomId: number, logs: RoomAvailabilityLog[]) =>
  action(ActionTypes.FETCH_LOGS_SUCCESS, {
    roomId,
    logs,
  });

export const createLog = (
  params: Required<
    InjectPromise<Room, { roomId: number; log: RoomAvailabilityLog }>
  >,
) => action(ActionTypes.CREATE_LOG, params);

export const createLogSuccess = (log: RoomAvailabilityLog) =>
  action(ActionTypes.CREATE_LOG_SUCCESS, log);

export const editLog = (
  params: Required<
    InjectPromise<Room, { roomId: number; log: RoomAvailabilityLog }>
  >,
) => action(ActionTypes.EDIT_LOG, params);

export const editLogSuccess = (log: RoomAvailabilityLog) =>
  action(ActionTypes.EDIT_LOG_SUCCESS, log);

export const deleteLog = (log: RoomAvailabilityLog) =>
  action(ActionTypes.DELETE_LOG, log);

export const deleteLogSuccess = (log: RoomAvailabilityLog) =>
  action(ActionTypes.DELETE_LOG_SUCCESS, log);
