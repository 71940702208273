import { takeEvery, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { UNDO_CHECKIN_BOOKING } from '../constants';
import { showErrorNotification } from '@aha/utils';

function* doUnCheckinBooking({ booking, reject, resolve }) {
  try {
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/bookings/${booking.id}/uncheckin`,
      {},
    );
    resolve(data);
  } catch (err) {
    showErrorNotification('Undo checkin booking failed', err);
    reject(err);
  }
}

export function* watchUnCheckinBooking() {
  yield takeEvery(UNDO_CHECKIN_BOOKING, doUnCheckinBooking);
}
