import { Redirect, Router as ReachRouter } from '@reach/router';
import CompaniesAndGuestsLayout from 'components/CompaniesAndGuestsLayout';
import ComponentRoute from 'components/ComponentRoute';
import HourlyReportLayout from 'components/HourlyReportLayout';
import { PrivateRoute } from 'components/permission';
import ROUTES from 'constants/routes';
import LoginPage from 'containers/Auth/LoginPage';
import StartPage from 'containers/Auth/StartPage';
import Dashboard from 'containers/Dashboard';
import ForgotPassword from 'containers/ForgotPassword';
import Printing from 'containers/Printing';
import ResetPassword from 'containers/ResetPassword';
import SearchBookingProvider from 'containers/SearchBookingProvider';
import SelectHotelPage from 'containers/SelectHotelPage';
import React, { lazy } from 'react';

const PrintingRegCardPage = lazy(() =>
  import('containers/PrintingRegCardPage'),
);
const PrintingInvoicePage = lazy(() =>
  import('containers/PrintingInvoicePage'),
);
const DashboardHome = lazy(() => import('containers/DashboardHomePage'));
const HotelsPage = lazy(() => import('containers/HotelsPage'));
const NotFoundPage = lazy(() => import('containers/404Page'));
const NotAuthorizePage = lazy(() => import('containers/403Page'));
const ServerErrorPage = lazy(() => import('containers/500Page'));
const RoomPlanPage = lazy(() => import('containers/RoomPlanPage'));
const SettingUsers = lazy(() => import('containers/SettingUsers'));
const SettingPaymentTypesPage = lazy(() =>
  import('containers/SettingPaymentTypesPage'),
);
const ServiceGroupsSettings = lazy(() => import('containers/ServiceGroups'));
const SettingRoomTypesPage = lazy(() =>
  import('containers/SettingRoomTypesPage'),
);
const SettingRoomTypeDetailPage = lazy(() =>
  import('containers/SettingRoomTypeDetailPage'),
);
const GuestInfoPage = lazy(() => import('containers/GuestInfo'));
const SettingHourlyServicesPage = lazy(() =>
  import('containers/SettingHourlyServicesPage'),
);
const CurrencySettings = lazy(() => import('containers/CurrencySettings'));
const SettingMarketSegmentsPage = lazy(() =>
  import('containers/SettingMarketSegmentsPage'),
);
const LockDoorPage = lazy(() => import('containers/LockDoorPage'));
const SettingChargeTemplatesPage = lazy(() =>
  import('containers/SettingChargeTemplatesPage'),
);
const SettingGuestsPage = lazy(() => import('containers/SettingGuestsPage'));
const SettingCompaniesPage = lazy(() =>
  import('containers/SettingCompaniesPage'),
);
const CalendarPage = lazy(() => import('containers/CalendarPage'));
const OccupancyAndChargePage = lazy(() =>
  import('containers/OccupancyAndChargePage'),
);
const RatePlansPage = lazy(() => import('containers/RatePlansPage'));
const CompaniesReportPage = lazy(() =>
  import('containers/CompaniesReportPage'),
);
const WarehouseReportPage = lazy(() =>
  import('containers/WarehouseReportPage'),
);
const InventoryPage = lazy(() => import('containers/RoomInventoryPage'));
const CreateHotelPage = lazy(() => import('containers/CreateHotelPage'));
const EditHotelPage = lazy(() => import('containers/EditHotelPage'));
const LoyaltyReportPage = lazy(() => import('containers/LoyaltyReportPage'));

const PaymentReportPage = lazy(() => import('containers/PaymentReportPage'));
const SegmentReportPage = lazy(() => import('containers/SegmentReportPage'));

const CreateBooking = lazy(() => import('containers/CreateBooking'));
const HourlyChargeReportPage = lazy(() =>
  import('containers/HourlyChargeReportPage'),
);
const HourlyPaymentReportPage = lazy(() =>
  import('containers/HourlyPaymentReportPage'),
);

const Booking = lazy(() => import('containers/BookingProvider'));
const BookingsArrivalPage = lazy(() =>
  import('containers/BookingProvider/pages/BookingsArrivalPage'),
);
const BookingsInhousePage = lazy(() =>
  import('containers/BookingProvider/pages/BookingsInhousePage'),
);
const BookingsDeparturePage = lazy(() =>
  import('containers/BookingProvider/pages/BookingsDeparturePage'),
);

const BookingDetailPage = lazy(() => import('containers/BookingDetailPage'));
const BookingFolioPage = lazy(() => import('containers/BookingFolioPage'));

const SearchDailyBookingsPage = lazy(() =>
  import('containers/SearchBookingProvider/pages/SearchDailyBookingsPage'),
);
const SearchHourlyBookingsPage = lazy(() =>
  import('containers/SearchBookingProvider/pages/SearchHourlyBookingsPage'),
);
const ProfilePage = lazy(() => import('containers/ProfilePage'));

export default function Router() {
  return (
    <ReachRouter>
      <StartPage path={ROUTES.START}>
        <Redirect from={ROUTES.START} to={ROUTES.LOGIN} noThrow />
        <ComponentRoute
          path={ROUTES.LOGIN.replace(ROUTES.START, '')}
          Component={LoginPage}
        />
        <ComponentRoute
          path={ROUTES.FORGOT_PASSWORD.replace(ROUTES.START, '')}
          Component={ForgotPassword}
        />
        <ComponentRoute
          path={ROUTES.RESET_PASSWORD.replace(ROUTES.START, '')}
          Component={ResetPassword}
        />
        <Redirect from={ROUTES.INVALID_PATH} to={ROUTES.LOGIN} noThrow />
      </StartPage>

      <SelectHotelPage path={ROUTES.SELECT_HOTEL} />
      <Dashboard path={ROUTES.DASHBOARD}>
        <ComponentRoute path={ROUTES.DASHBOARD} Component={DashboardHome} />
        <ComponentRoute path={ROUTES.HOTELS} Component={HotelsPage} />
        <PrivateRoute
          path={ROUTES.NEW_HOTEL}
          allow="operation.hotels.create"
          Component={CreateHotelPage}
        />
        <PrivateRoute
          path={ROUTES.EDIT_HOTEL}
          allow="operation.hotels.update"
          Component={EditHotelPage}
        />
        <PrivateRoute
          path={ROUTES.ROOMS}
          allow="pms.roomTypes.rooms.getList"
          Component={RoomPlanPage}
        />
        <PrivateRoute
          path={ROUTES.CALENDAR}
          allow="pms.bookings.getList"
          Component={CalendarPage}
        />
        <PrivateRoute
          path={ROUTES.RATES}
          allow="pms.ratePlans.getList"
          Component={RatePlansPage}
        />
        <PrivateRoute
          path={ROUTES.ROOM_INVENTORY}
          allow="pms.roomTypes.getList"
          Component={InventoryPage}
        />
        <ComponentRoute path={ROUTES.LOCK_DOOR} Component={LockDoorPage} />

        <HourlyReportLayout path={ROUTES.HOURLY_REPORT}>
          <PrivateRoute
            path={ROUTES.HOURLY_CHARGE_REPORT.replace(ROUTES.HOURLY_REPORT, '')}
            Component={HourlyChargeReportPage}
            allow="reports.hourlyBookingCharges.getList"
          />
          <PrivateRoute
            path={ROUTES.HOURLY_PAYMENT_REPORT.replace(
              ROUTES.HOURLY_REPORT,
              '',
            )}
            Component={HourlyPaymentReportPage}
            allow="reports.hourlyBookingPayments.getList"
          />
          <Redirect from={ROUTES.INVALID_PATH} to={ROUTES.NOT_FOUND} noThrow />
        </HourlyReportLayout>
        <PrivateRoute
          path={ROUTES.WAREHOUSE_REPORT}
          allow="reports.warehouse.getList"
          Component={WarehouseReportPage}
        />
        <PrivateRoute
          path={ROUTES.OCCUPANCY_AND_CHARGE_REPORT}
          allow="reports.occupancyAndCharges.getList"
          Component={OccupancyAndChargePage}
        />

        <PrivateRoute
          path={ROUTES.LOYALTY_REPORT}
          allow="pms.memberships.getMonthlyPayableExpenseReports"
          Component={LoyaltyReportPage}
        />
        <PrivateRoute
          path={ROUTES.PAYMENT_REPORT}
          allow="reports.payments.getList"
          Component={PaymentReportPage}
        />
        <PrivateRoute
          path={ROUTES.COMPANIES_REPORT}
          allow="reports.occupancyAndCharges.getList"
          Component={CompaniesReportPage}
        />
        <PrivateRoute
          path={ROUTES.SEGMENT_REPORT}
          allow="reports.segments.getList"
          Component={SegmentReportPage}
        />
        <PrivateRoute
          path={ROUTES.CURRENCY_SETTING}
          allow="operation.currencies.create"
          Component={CurrencySettings}
        />
        <PrivateRoute
          path={ROUTES.USER_SETTING}
          allow="operation.users.create"
          Component={SettingUsers}
        />
        <PrivateRoute
          path={ROUTES.PAYMENT_TYPES_SETTING}
          allow="operation.paymentTypes.create"
          Component={SettingPaymentTypesPage}
        />

        <PrivateRoute
          path={ROUTES.SERVICE_GROUPS_SETTING}
          allow="operation.revenueGroups.create"
          Component={ServiceGroupsSettings}
        />

        <PrivateRoute
          path={ROUTES.ROOM_TYPE_SETTING}
          allow="operation.roomTypes.create"
          Component={SettingRoomTypesPage}
        />
        <PrivateRoute
          allow="operation.roomTypes.update"
          path={ROUTES.ROOM_TYPE_DETAIL_SETTING}
          Component={SettingRoomTypeDetailPage}
        />
        <PrivateRoute
          path={ROUTES.HOURLY_SERVICES_SETTING}
          allow="operation.roomTypes.updateHourlySettings"
          Component={SettingHourlyServicesPage}
        />
        <PrivateRoute
          path={ROUTES.CHARGE_TEMPLATES_SETTING}
          allow="operation.chargeTemplates.create"
          Component={SettingChargeTemplatesPage}
        />
        <PrivateRoute
          path={ROUTES.MARKET_SEGMENTS_SETTING}
          allow="operation.marketingChannels.update"
          Component={SettingMarketSegmentsPage}
        />
        <ComponentRoute path={ROUTES.PROFILE} Component={ProfilePage} />

        <CompaniesAndGuestsLayout path={ROUTES.COMPANIES_AND_GUESTS}>
          <ComponentRoute
            path={ROUTES.COMPANIES_SETTING.replace(
              ROUTES.COMPANIES_AND_GUESTS,
              '',
            )}
            Component={SettingCompaniesPage}
          />

          <ComponentRoute
            path={ROUTES.GUESTS_SETTING.replace(
              ROUTES.COMPANIES_AND_GUESTS,
              '',
            )}
            Component={SettingGuestsPage}
          />

          <Redirect from={ROUTES.INVALID_PATH} to={ROUTES.NOT_FOUND} noThrow />
        </CompaniesAndGuestsLayout>

        <GuestInfoPage path={ROUTES.GUESTS_DETAIL} component={GuestInfoPage} />

        <SearchBookingProvider path={ROUTES.SEARCH}>
          <PrivateRoute
            path={ROUTES.DAILY_BOOKING_SEARCH.replace(ROUTES.SEARCH, '')}
            allow="pms.bookings.getList"
            Component={SearchDailyBookingsPage}
          />
          <PrivateRoute
            path={ROUTES.HOURLY_BOOKING_SEARCH.replace(ROUTES.SEARCH, '')}
            allow="pms.hourlyBookings.search"
            Component={SearchHourlyBookingsPage}
          />
          <Redirect from={ROUTES.INVALID_PATH} to={ROUTES.NOT_FOUND} noThrow />
        </SearchBookingProvider>

        <Booking path={ROUTES.BOOKINGS}>
          <PrivateRoute
            path={ROUTES.DEPARTURE_BOOKING.replace(ROUTES.BOOKINGS, '')}
            allow="pms.bookings.getList"
            Component={BookingsDeparturePage}
          />
          <PrivateRoute
            path={ROUTES.DEPARTURE_DAILY_BOOKING.replace(ROUTES.BOOKINGS, '')}
            allow="pms.bookings.getList"
            Component={BookingsDeparturePage}
          />
          <PrivateRoute
            path={ROUTES.DEPARTURE_NIGHTLY_BOOKING.replace(ROUTES.BOOKINGS, '')}
            allow="pms.bookings.getList"
            Component={BookingsDeparturePage}
          />
          <PrivateRoute
            path={ROUTES.INHOUSE_BOOKING.replace(ROUTES.BOOKINGS, '')}
            allow="pms.bookings.getList"
            Component={BookingsInhousePage}
          />
          <PrivateRoute
            path={ROUTES.INHOUSE_DAILY_BOOKING.replace(ROUTES.BOOKINGS, '')}
            allow="pms.bookings.getList"
            Component={BookingsInhousePage}
          />
          <PrivateRoute
            path={ROUTES.INHOUSE_NIGHTLY_BOOKING.replace(ROUTES.BOOKINGS, '')}
            allow="pms.bookings.getList"
            Component={BookingsInhousePage}
          />
          <PrivateRoute
            path={ROUTES.ARRIVAL_BOOKING.replace(ROUTES.BOOKINGS, '')}
            allow="pms.bookings.getList"
            Component={BookingsArrivalPage}
          />
          <PrivateRoute
            path={ROUTES.ARRIVAL_DAILY_BOOKING.replace(ROUTES.BOOKINGS, '')}
            allow="pms.bookings.getList"
            Component={BookingsArrivalPage}
          />
          <PrivateRoute
            path={ROUTES.ARRIVAL_NIGHT_BOOKING.replace(ROUTES.BOOKINGS, '')}
            allow="pms.bookings.getList"
            Component={BookingsArrivalPage}
          />
          <PrivateRoute
            path={ROUTES.ARRIVAL_HOURLY_BOOKING.replace(ROUTES.BOOKINGS, '')}
            allow="pms.bookings.getList"
            Component={BookingsArrivalPage}
          />
          <PrivateRoute
            path={ROUTES.DETAIL_BOOKING.replace(ROUTES.BOOKINGS, '')}
            allow="pms.bookings.getDetail"
            Component={BookingDetailPage}
          />
          <Redirect from={ROUTES.INVALID_PATH} to={ROUTES.NOT_FOUND} noThrow />
        </Booking>
        <PrivateRoute
          path={ROUTES.FOLIO_BOOKING}
          allow="pms.bookings.folios.getDetail"
          Component={BookingFolioPage}
        />
        <PrivateRoute
          path={ROUTES.CREATE_BOOKING}
          allow="bookings.create"
          Component={CreateBooking}
        />

        <ComponentRoute
          path={ROUTES.NOT_AUTHORIZE}
          Component={NotAuthorizePage}
        />
        <ComponentRoute
          path={ROUTES.SERVER_ERROR}
          Component={ServerErrorPage}
        />
        <ComponentRoute path={ROUTES.INVALID_PATH} Component={NotFoundPage} />
      </Dashboard>

      <Printing path={ROUTES.PRINTING}>
        <PrivateRoute
          path={ROUTES.REG_CARD_PRINTING.replace(ROUTES.PRINTING, '')}
          allow="pms.bookings.getDetail"
          Component={PrintingRegCardPage}
        />
        <PrivateRoute
          path={ROUTES.INVOICE_PRINTING.replace(ROUTES.PRINTING, '')}
          allow="pms.bookings.folios.getDetail"
          Component={PrintingInvoicePage}
        />
        <Redirect from={ROUTES.INVALID_PATH} to={ROUTES.DASHBOARD} noThrow />
      </Printing>
    </ReachRouter>
  );
}
