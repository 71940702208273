export type RoomStatusColor = 'green' | 'red' | 'yellow' | 'grey';
export type RoomStatus = 'vacant' | 'closed' | 'occupied' | 'out_of_order';
export type ReasonType = keyof typeof OOO_REASONS;

export const ROOM_COLOR_STATUS: { [k in RoomStatus]: RoomStatusColor } = {
  vacant: 'green',
  closed: 'red',
  occupied: 'yellow',
  out_of_order: 'grey',
};

export const OOO_REASONS = {
  t_regular_maintenance: 'Regular maintenance',
  t_repair: 'Repair',
  t_damage: 'Damage',
  t_upgrade_facilities: 'Upgrade facilities',
  t_deep_clean: 'Deep clean',
  t_technical_problem: 'Technical problem',
  t_reserved_room: 'Reserved room',
  t_smelly_room: 'Smelly room',
  t_process_insects: 'Process insects',
  t_other: 'Other',
};

export default {
  VACANT: 'vacant',
  CLOSED: 'closed',
  OCCUPIED: 'occupied',
  OUT_OF_ORDER: 'out_of_order',
};
