import { initialState } from './reducer';
import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';

const authState = (state: ApplicationRootState) => state.auth || initialState;

export const makeSelectAuth = () => createSelector(authState, (auth) => auth);

export const makeSelectIsLogin = () =>
  createSelector(authState, (auth) => auth.isLogin);

export const makeSelectUser = () =>
  createSelector(authState, (auth) => auth.user);

export const makeSelectUserIsLoading = () =>
  createSelector(authState, (auth) => auth.userIsLoading);

export const makeSelectUserError = () =>
  createSelector(authState, (auth) => auth.userLoadError);

export const makeSelectPermission = () =>
  createSelector(authState, (auth) => auth.permissions);

export const makeSelectResetSuccess = () =>
  createSelector(authState, (auth) => auth.resetSuccess);

export const makeSelectEmailResetPassword = () =>
  createSelector(authState, (auth) => auth.emailResetPassword);

export const makeSelectResetChangePasswordSuccess = () =>
  createSelector(authState, (auth) => auth.changePasswordSuccess);
