import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

export interface AvatarProps {
  imgUrl?: string;
  className?: string;
  size?: number;
  placeholder?: React.ReactNode;
}

const Image = styled.div<{ size: number }>`
  ${tw`rounded-full border border-grey`}
  width: ${(props) => (props.size > 0 ? props.size : 100) + 'px'};
  height: ${(props) => (props.size > 0 ? props.size : 100) + 'px'};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const ImgPlaceholder = styled.div<{ size: number }>`
  ${tw`bg-grey-light flex items-center justify-center rounded-full border border-grey`}
  width: ${(props) => (props.size > 0 ? props.size : 100) + 'px'};
  height: ${(props) => (props.size > 0 ? props.size : 100) + 'px'};
  padding: ${(props) => props.size * 0.15 + 'px'};
`;

const Avatar = ({
  imgUrl,
  size = 100,
  className = '',
  placeholder = '',
}: AvatarProps) => {
  return (
    <div className={className}>
      {imgUrl ? (
        <Image style={{ backgroundImage: `url(${imgUrl})` }} size={size} />
      ) : (
        <ImgPlaceholder size={size}>{placeholder}</ImgPlaceholder>
      )}
    </div>
  );
};

export default Avatar;
