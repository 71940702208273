import React from 'react';
import { Form, Alert } from 'antd';
import { connect } from 'react-redux';
import { logIn } from './actions';
import LoginLayout from 'components/LoginLayout';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button } from '@aha/ui';
import { Link } from '@reach/router';
import InputOutline from 'components/InputOutline';
import { IconEyeInvisible, IconEye } from '@aha/icons';
import ROUTES from 'constants/routes';

const LoginForm = Form.create({ name: 'login_form' })(
  class FormBody extends React.Component {
    state = {
      loading: false,
      error: null,
      visiblePassword: false,
    };
    handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        this.setState({ loading: true, error: null });
        this.props
          .logIn(values)
          .then(() => {
            // auto redirect - should do nothing here
          })
          .catch((error) => {
            this.setState({ error, loading: false });
          });
      });
    };

    toggleVisiblePassword = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ visiblePassword: !this.state.visiblePassword });
    };

    render() {
      const {
        intl,
        form: { getFieldDecorator, getFieldError },
      } = this.props;
      const { error, loading, visiblePassword } = this.state;

      return (
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: (
                    <FormattedMessage
                      id="common.message.invalid"
                      defaultMessage="Invalid"
                    />
                  ),
                },
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="common.message.required"
                      defaultMessage="Required"
                    />
                  ),
                },
              ],
            })(
              <InputOutline
                invalid={getFieldError('Email')}
                label={intl.formatMessage({
                  defaultMessage: 'Email',
                  id: 'form.label.mail',
                })}
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="common.message.required"
                      defaultMessage="Required"
                    />
                  ),
                },
              ],
            })(
              <InputOutline
                invalid={getFieldError('Password')}
                type={visiblePassword ? 'text' : 'password'}
                label={intl.formatMessage({
                  defaultMessage: 'Password',
                  id: 'form.label.password',
                })}
                suffix={
                  visiblePassword ? (
                    <Button
                      className="-mr-1"
                      type="icon"
                      icon={IconEyeInvisible}
                      onClick={this.toggleVisiblePassword}
                    />
                  ) : (
                    <Button
                      className="-mr-1"
                      type="icon"
                      icon={IconEye}
                      onClick={this.toggleVisiblePassword}
                    />
                  )
                }
              />,
            )}
          </Form.Item>
          <div className="mt-2 flex justify-end">
            <Link
              to={ROUTES.FORGOT_PASSWORD}
              className="text-xs text-blue"
              data-testid="login-form__pass"
            >
              <FormattedMessage
                defaultMessage="Forgot password?"
                id="auth.forgotPassword"
              />
            </Link>
          </div>
          <div className="mt-8">
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="login-form-button block w-full font-medium text-base"
              disabled={loading}
            >
              <FormattedMessage defaultMessage="Sign In" id="auth.signin" />
            </Button>
          </div>
          {error && (
            <div className="relative">
              <Alert
                message={error.message}
                type="error"
                closable
                className="mt-4 absolute w-full"
                afterClose={() => this.setState({ error: null })}
              />
            </div>
          )}
        </Form>
      );
    }
  },
);

const LoginPage = ({ logIn, intl }) => {
  return (
    <LoginLayout>
      <div className="pt-32 w-full mt-8">
        <div className="text-lg text-secondary leading-none font-semibold">
          <FormattedMessage defaultMessage="Log in" id="auth.login" />
        </div>
        <div className="text-2xl mt-8">
          {<LoginForm logIn={logIn} intl={intl} />}
        </div>
      </div>
    </LoginLayout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logIn: (data) =>
      new Promise((resolve, reject) => {
        dispatch(logIn({ data, resolve, reject }));
      }),
  };
};

const LoginPageWithConnect = connect(
  null,
  mapDispatchToProps,
)(injectIntl(LoginPage));

export default LoginPageWithConnect;
