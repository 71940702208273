import { put, call, takeLatest } from 'redux-saga/effects';
import ActionTypes from '../constants';
import { logInSuccess } from '../actions';
import { coreAPI, setToken } from 'utils/request';
import { ExtractAction } from 'types/actions';
import { AuthActions } from '../types';
import { LoginResponse } from 'types/schema';

export function* doLogin(
  action: ExtractAction<AuthActions, ActionTypes.LOG_IN>,
) {
  const {
    payload: { resolve, reject, data },
  } = action;

  try {
    const resp: LoginResponse = yield call(
      coreAPI.post,
      '/v1/pms/auth/login',
      data,
    );
    const { token } = resp;

    if (token) {
      yield call(setToken, token);
      yield put(logInSuccess());
      // @ts-ignore
      resolve(resp);
    }
  } catch (err) {
    reject(err);
  }
}

export default function* watchLogin() {
  yield takeLatest(ActionTypes.LOG_IN, doLogin);
}
