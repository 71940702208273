import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import ErrorBoundary from 'components/ErrorBoundary';
import { extractComponentFromRoute } from 'containers/Dashboard/LoadScreenSwitcher';
import { useIntl } from 'react-intl';
import * as Sentry from '@sentry/browser';

export interface ComponentRouteProps extends RouteComponentProps {
  Component: React.FunctionComponent<RouteComponentProps>;
}

if (process.env.REACT_APP_STAGE === 'production') {
  Sentry.init({
    release: process.env.REACT_APP_TAG_CURRENT_VERSION,
    environment: process.env.REACT_APP_STAGE,
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });
}

const ComponentRoute = ({
  Component,
  uri = '',
  ...rest
}: ComponentRouteProps) => {
  const intl = useIntl();

  useEffect(() => {
    const defaultTitle = 'AHA PMS';
    const pages = extractComponentFromRoute(uri);

    let title = pages?.[uri]?.secondTitle || pages?.[uri]?.title;
    if (Array.isArray(title)) {
      title = title?.[0].title;
    }

    try {
      document.title = title
        ? `${intl.formatMessage(title.props)} | ${defaultTitle}`
        : defaultTitle;
    } catch (e) {
      document.title = defaultTitle;
    }
  }, [uri, intl]); // eslint-disable-line

  return (
    <ErrorBoundary>
      <Component {...rest} />
    </ErrorBoundary>
  );
};

export default ComponentRoute;
