import { all } from 'redux-saga/effects';
import {
  watchFetchBooking,
  watchFetchExpectedBookings,
  watchFetchCheckedInBookings,
  watchFetchCheckedOutBookings,
  watchInHouseBookings,
  watchFetchBookingChangeLogs,
  watchFetchArrivalBooking,
  watchFetchDepartureBooking,
  watchFetchExpiredBookings,
} from './sagas/watchFetchBookings';
import { watchFetchAvailableRooms } from './sagas/watchFetchAvailableRooms';
import { watchAllocateRoomForBooking } from './sagas/watchAllocateRoom';
import { watchAllocateRoomForHourlyBooking } from './sagas/watchAllocateRoomHourly';
import { watchCancelBooking } from './sagas/watchCancelBooking';
import { watchNoShowBooking } from './sagas/watchNoShowBooking';
import { watchCheckinBooking } from './sagas/watchCheckinBooking';
import { watchCheckoutBooking } from './sagas/watchCheckoutBooking';
import { watchFetctFolioDetail } from './sagas/watchFetchFolioDetails';
import { watchFetchChargeTemplates } from './sagas/watchFetchChargeTemplates';
import { watchFetchPaymentTypes } from './sagas/watchFetchPaymentTypes';
import {
  watchAddFolioCharge,
  watchAddFolioPayment,
  watchVoidFolioCharges,
  watchVoidFolioPayment,
  watchUpdateFolioCharge,
  watchUpdateFolioPayment,
  watchAddFolio,
  watchVoidFolio,
} from './sagas/watchModifyFolio';
import { watchFetchRevGroups } from './sagas/watchFetchRevGroups';
import { watchUpdateBookingPeriod } from './sagas/watchUpdateBookingPeriod';
import { watchVoidBookingCharges } from './sagas/watchVoidBookingCharges';
import { watchCreateHourlyBooking } from './sagas/watchCreateHourlyBooking';
import { watchUpdateBookingInfo } from './sagas/watchUpdateBookingInfo';
import { watchRemoveRoomFromBooking } from './sagas/watchRemoveRoomFromBooking';
import { watchFetchConpanies } from './sagas/watchFetchConpanies';
import { watchUnCheckinBooking } from './sagas/watchUndoCheckinBooking';
import { watchUnassignGuestBooking } from './sagas/watchUnassignGuestBooking';
import { watchAssignGuestBooking } from './sagas/watchAssignGuestBooking';
import { watchTransferCharges } from './sagas/watchTransferCharges';
import { watchTransferFolio } from './sagas/watchTransferFolio';
import { watchAddGuestToBooking } from './sagas/watchAddGuestBooking';
import { watchEditBookingRoomType } from './sagas/watchEditBookingRoomType';
import { watchVoidMultipleBookingCharges } from './sagas/watchVoidMultipleCharges';
import { watchCancelChildBooking } from './sagas/watchCancelChildBooking';
import { watchAddChildBooking } from './sagas/watchAddChildBooking';
import { watchGroupFolios } from './sagas/watchGroupFolios';
import { watchSeparateFolios } from './sagas/watchSeparateFolios';

export default function* watchAll() {
  yield all([
    watchFetchBooking(),
    watchFetchExpectedBookings(),
    watchFetchCheckedInBookings(),
    watchFetchExpiredBookings(),
    watchFetchCheckedOutBookings(),
    watchFetchArrivalBooking(),
    watchFetchDepartureBooking(),
    watchFetchAvailableRooms(),
    watchAllocateRoomForBooking(),
    watchAllocateRoomForHourlyBooking(),
    watchCancelBooking(),
    watchNoShowBooking(),
    watchCheckinBooking(),
    watchCheckoutBooking(),
    watchInHouseBookings(),
    watchFetctFolioDetail(),
    watchFetchPaymentTypes(),
    watchFetchChargeTemplates(),
    watchFetchRevGroups(),
    watchAddFolioCharge(),
    watchAddFolioPayment(),
    watchVoidFolioCharges(),
    watchVoidFolioPayment(),
    watchUpdateFolioCharge(),
    watchUpdateFolioPayment(),
    watchAddFolio(),
    watchVoidFolio(),
    watchUpdateBookingPeriod(),
    watchVoidBookingCharges(),
    watchFetchBookingChangeLogs(),
    watchCreateHourlyBooking(),
    watchUpdateBookingInfo(),
    watchRemoveRoomFromBooking(),
    watchFetchConpanies(),
    watchUnCheckinBooking(),
    watchUnassignGuestBooking(),
    watchAssignGuestBooking(),
    watchTransferCharges(),
    watchTransferFolio(),
    watchAddGuestToBooking(),
    watchEditBookingRoomType(),
    watchVoidMultipleBookingCharges(),
    watchCancelChildBooking(),
    watchAddChildBooking(),
    watchGroupFolios(),
    watchSeparateFolios(),
  ]);
}
