import ActionTypes from './constants';
import { action } from 'typesafe-actions';
import { CityProps, DistrictProps } from './type';
import { Hotel, HotelCurrency, Color, Facility, Amenity } from 'types/schema';

export const toggleCollapsed = () => {
  return action(ActionTypes.TOGGLE_COLLAPSE);
};

export const fetchCurrentHotel = (hotelId: number) => {
  return action(ActionTypes.FETCH_CURRENT_HOTEL, { hotelId });
};

export const fetchCurrentHotelSuccess = ({
  hotel,
  currencies,
}: {
  hotel: Hotel;
  currencies: HotelCurrency[];
}) => {
  return action(ActionTypes.FETCH_CURRENT_HOTEL_SUCCESS, { hotel, currencies });
};

export const fetchCurrentHotelFail = () => {
  return action(ActionTypes.FETCH_CURRENT_HOTEL_FAIL);
};

export const fetchColors = () => {
  return action(ActionTypes.FETCH_COLORS);
};

export const fetchColorsSuccess = (colors: Color[]) => {
  return action(ActionTypes.FETCH_COLORS_SUCCESS, { colors });
};

export const switchCurrentHotel = (hotelId: number | null) => {
  return action(ActionTypes.SWITCH_CURRENT_HOTEL, { hotelId });
};

export const switchCurrentHotelSuccess = ({
  hotel,
  currencies,
  hotelId = null,
}: {
  hotel: Hotel;
  currencies: HotelCurrency[];
  hotelId: number | null;
}) => {
  return action(ActionTypes.SWITCH_CURRENT_HOTEL_SUCCESS, {
    hotel,
    currencies,
    hotelId,
  });
};

export const switchCurrentHotelFail = () => {
  return action(ActionTypes.SWITCH_CURRENT_HOTEL_FAIL);
};

export const setCurrentHotelId = (hotelId: number) => {
  return action(ActionTypes.SET_CURRENT_HOTEL_ID, { hotelId });
};

export const setCurrentHotelIdSuccess = (hotelId: number) => {
  return action(ActionTypes.SET_CURRENT_HOTEL_ID_SUCCESS, { hotelId });
};
export const setCurrentHotelIdFail = (error: Error) => {
  return action(ActionTypes.SET_CURRENT_HOTEL_ID_FAIL, { error });
};

export const fetchCities = () => {
  return action(ActionTypes.FETCH_CITIES);
};

export const fetchCitiesSuccess = (cities: CityProps[]) => {
  return action(ActionTypes.FETCH_CITIES_SUCCESS, { cities });
};

export const fetchCitiesFail = (error: Error) => {
  return action(ActionTypes.FETCH_CITIES_FAIL, { error });
};

export const fetchDistricts = (cityId: string) => {
  return action(ActionTypes.FETCH_DISTRICTS, { cityId });
};

export const fetchDistrictsSuccess = (districts: DistrictProps[]) => {
  return action(ActionTypes.FETCH_DISTRICTS_SUCCESS, { districts });
};

export const fetchDistrictsFail = (error: Error) => {
  return action(ActionTypes.FETCH_DISTRICTS_FAIL, { error });
};

export const fetchFacilities = () => {
  return action(ActionTypes.FETCH_FACILITIES);
};

export const fetchFacilitiesSuccess = (facilities: Facility[]) => {
  return action(ActionTypes.FETCH_FACILITIES_SUCCESS, { facilities });
};

export const fetchFacilitiesFail = (error: Error) => {
  return action(ActionTypes.FETCH_FACILITIES_FAIL, { error });
};

export const fetchAmenities = () => {
  return action(ActionTypes.FETCH_AMENITIES);
};

export const fetchAmenitiesSuccess = (amenities: Amenity[]) => {
  return action(ActionTypes.FETCH_AMENITIES_SUCCESS, { amenities });
};

export const fetchAmenitiesFail = (error: Error) => {
  return action(ActionTypes.FETCH_AMENITIES_FAIL, { error });
};
