import React from 'react';
import Modal from '../Modal';
import PasswordInput from '../PasswordInput';
import { Form } from 'react-final-form';
import styled from 'styled-components';

export interface ChangePasswordValues {
  confirmPassword: string;
  newPassword: string;
  oldPassword: string;
}
export interface InnerModalProps {
  onSubmit: (value: Partial<ChangePasswordValues>) => Promise<unknown>;
  onCancel: () => void;
}
export const InnerModal = ({ onSubmit }: InnerModalProps) => (
  <Form
    onSubmit={(values) => {
      onSubmit(values);
    }}
    validate={validate}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit} id="change-password-form">
        <div className="my-5">
          <PasswordInput
            inputTestId="Change-password-form__pass"
            buttonTestId="Change-password-form__eye-bt"
            name="oldPassword"
            label="Current Password"
            placeholder="Password"
            required
          />
        </div>
        <div className="mt-4 mb-5">
          <PasswordInput
            name="newPassword"
            label="New Password"
            placeholder="(At least 8 characters)"
            required
          />
        </div>

        <div className="mt-5 mb-3">
          <PasswordInput
            name="confirmPassword"
            label="Re-password"
            placeholder="Retype new password to confirm"
            required
          />
        </div>
      </form>
    )}
  />
);

export interface ChangePasswordModalProps {
  visible: boolean;
  onSubmit: (value: Partial<ChangePasswordValues>) => Promise<unknown>;
  onCancel: () => void;
  changeText?: React.ReactNode;
  cancelText?: React.ReactNode;
  title?: React.ReactNode;
  submiting?: boolean;
}

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-top: 0;
  }
`;

export function validate(value: Partial<ChangePasswordValues>) {
  const errors: {
    newPassword?: string;
    oldPassword?: string;
    confirmPassword?: string;
  } = {};

  if (!value.oldPassword) {
    errors.oldPassword = 'Required';
  }
  if (!value.newPassword) {
    errors.newPassword = 'Required';
  } else if (value.newPassword.length < 6) {
    errors.newPassword = 'Must have at least 6 characters';
  } else if (value.newPassword === value.oldPassword) {
    errors.newPassword = 'New password must be different from old';
  }
  if (!value.confirmPassword) {
    errors.confirmPassword = 'Required';
  } else if (value.newPassword !== value.confirmPassword) {
    errors.confirmPassword = 'Password does not match';
  }
  return errors;
}

const ChangePasswordModal = ({
  visible = false,
  title = 'Change Password',
  changeText = 'Change',
  cancelText = 'Cancel',
  onSubmit,
  onCancel,
  submiting,
}: ChangePasswordModalProps) => {
  return (
    <StyledModal
      visible={visible}
      submitting={submiting}
      submitText={changeText}
      cancelText={cancelText}
      onCancel={onCancel}
      onSubmit={() => {
        if (!document || !document.getElementById) {
          return;
        }
        const node = document.getElementById('change-password-form');
        if (node === null) return;
        node.dispatchEvent(new Event('submit', { cancelable: true }));
      }}
      title={title}
    >
      <InnerModal onSubmit={onSubmit} onCancel={onCancel} />
    </StyledModal>
  );
};

export default ChangePasswordModal;
