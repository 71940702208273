import { call, put, takeLatest } from 'redux-saga/effects';
import { coreAPI } from 'utils/request';
import ActionTypes from '../constants';
import { createRatePlanSuccess, createRatePlanFail } from '../actions';
import { showErrorNotification } from '@aha/utils';
import { RatePlansActions } from '../types';
import { ExtractAction } from 'types/actions';

function* doCreateRate(
  action: ExtractAction<RatePlansActions, ActionTypes.CREATE_RATE_PLAN>,
) {
  const {
    payload: {
      data: { formData },
      resolve,
      reject,
    },
  } = action;

  try {
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/operation/rate-plans`,
      formData,
    );
    yield put(createRatePlanSuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification('Create rate fail', err);
    yield put(createRatePlanFail(err));
    reject(err);
  }
}

export default function* watchCreateRate() {
  yield takeLatest(ActionTypes.CREATE_RATE_PLAN, doCreateRate);
}
