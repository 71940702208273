import React from 'react';
import { Radio } from 'antd';
import { navigate } from '@reach/router';
import { FormattedMessage } from 'react-intl';
import ROUTES from 'constants/routes';
import { PermissionGate } from 'components/permission';

export interface SearchNavBarProps {
  pageID: string;
}

export default function SearchNavBar({ pageID }: SearchNavBarProps) {
  return (
    <Radio.Group
      value={pageID}
      onChange={(e) => navigate(e.target.value)}
      buttonStyle="solid"
      className="text-center h-full"
    >
      <PermissionGate
        allow="pms.bookings.getList"
        fallback={
          <Radio.Button disabled>
            <FormattedMessage
              defaultMessage="Daily bookings"
              id="search.label.dailyBooking"
            />
          </Radio.Button>
        }
      >
        <Radio.Button value={ROUTES.DAILY_BOOKING_SEARCH}>
          <FormattedMessage
            defaultMessage="Daily bookings"
            id="search.label.dailyBooking"
          />
        </Radio.Button>
      </PermissionGate>
      <PermissionGate
        allow="pms.hourlyBookings.search"
        fallback={
          <Radio.Button disabled>
            <FormattedMessage
              defaultMessage="Hourly bookings"
              id="search.label.hourlyBooking"
            />
          </Radio.Button>
        }
      >
        <Radio.Button value={ROUTES.HOURLY_BOOKING_SEARCH}>
          <FormattedMessage
            defaultMessage="Hourly bookings"
            id="search.label.hourlyBooking"
          />
        </Radio.Button>
      </PermissionGate>
    </Radio.Group>
  );
}
