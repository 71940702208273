import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelectLocale } from './selectors';
import { changeLocale } from './actions';
import { createStructuredSelector } from 'reselect';
import LanguageSelection, {
  LanguageOption,
} from '@aha/ui/src/components/LanguageSelection';
import { Dispatch } from 'redux';
import { ApplicationRootState } from 'types/app';
import { Language } from 'i18n';

const languages: LanguageOption[] = [
  {
    id: 'en',
    abbr: 'en',
    name: 'English',
  },
  {
    id: 'vi',
    abbr: 'vi',
    name: 'Tiếng Việt',
  },
];

export interface LocaleSelectionProps {
  abbr?: boolean;
  className?: string;
}

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    locale: string;
  }
>({
  locale: makeSelectLocale(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeLocale: (lan: Language) => dispatch(changeLocale(lan)),
});

function LocaleSelection({ abbr = false, ...rest }: LocaleSelectionProps) {
  const dispatch = useDispatch();
  const { changeLocale } = mapDispatchToProps(dispatch);
  const { locale } = useSelector(mapStateToProps);

  const selectedLan =
    languages.find((lan) => lan.id === locale) || languages[1];

  return (
    <LanguageSelection
      options={languages}
      abbr={abbr}
      selectedLan={selectedLan}
      onSelect={(lan) => changeLocale(lan.id as Language)}
      {...rest}
    />
  );
}

export default LocaleSelection;
