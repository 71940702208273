import { LayoutSidebarMenuItem } from 'components/Layout/Sidebar';
import { PermissionType } from 'components/permission/type';
import ROUTES from 'constants/routes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  IconChartLine,
  IconCalendarCheck,
  IconSearch,
  IconDoorOpen,
  IconCalendarAlt,
  IconWarehouseAlt,
  IconRulerVertical,
  IconFileInvoice,
  IconUserTag,
  IconLockAlt,
  IconMessengerSupport,
  IconCog,
} from '@aha/icons';

export const navItems: LayoutSidebarMenuItem<{ allow?: PermissionType }>[] = [
  {
    icon: IconChartLine,
    id: 'sidebar.menu.dashboard',
    name: (
      <FormattedMessage
        defaultMessage="Dashboard"
        id="sidebar.menu.dashboard"
      />
    ),
    link: ROUTES.DASHBOARD,
  },
  {
    icon: IconCalendarCheck,
    id: 'sidebar.menu.bookings',
    name: (
      <FormattedMessage defaultMessage="Bookings" id="sidebar.menu.bookings" />
    ),
    link: ROUTES.ARRIVAL_BOOKING,
    allow: 'pms.bookings.getList',
    submenu: [
      {
        id: 'sidebar.menu.daily_bookings',
        name: (
          <FormattedMessage
            defaultMessage="Bookings"
            id="sidebar.menu.daily_bookings"
          />
        ),
        link: ROUTES.ARRIVAL_DAILY_BOOKING,
        allow: 'pms.bookings.getList',
      },
      {
        id: 'sidebar.menu.night_bookings',
        name: (
          <FormattedMessage
            defaultMessage="Bookings"
            id="sidebar.menu.night_bookings"
          />
        ),
        link: ROUTES.ARRIVAL_NIGHT_BOOKING,
        allow: 'pms.bookings.getList',
      },
      {
        id: 'sidebar.menu.hourly_bookings',
        name: (
          <FormattedMessage
            defaultMessage="Bookings"
            id="sidebar.menu.hourly_bookings"
          />
        ),
        link: ROUTES.ARRIVAL_HOURLY_BOOKING,
        allow: 'pms.bookings.getList',
      },
    ],
  },
  {
    icon: IconSearch,
    id: 'sidebar.menu.search',
    name: <FormattedMessage defaultMessage="Search" id="sidebar.menu.search" />,
    link: ROUTES.DAILY_BOOKING_SEARCH,
    allow: 'pms.bookings.getList',
  },
  {
    icon: IconDoorOpen,
    id: 'sidebar.menu.rooms',
    name: <FormattedMessage defaultMessage="Rooms" id="sidebar.menu.rooms" />,
    link: ROUTES.ROOMS,
    allow: 'pms.roomTypes.rooms.getList',
  },
  {
    icon: IconCalendarAlt,
    id: 'sidebar.menu.calendar',
    name: (
      <FormattedMessage defaultMessage="Calendar" id="sidebar.menu.calendar" />
    ),
    link: ROUTES.CALENDAR,
    allow: 'pms.bookings.getList',
  },
  {
    icon: IconWarehouseAlt,
    id: 'sidebar.menu.roomInventory',
    name: (
      <FormattedMessage
        defaultMessage="Inventory"
        id="sidebar.menu.roomInventory"
      />
    ),
    link: ROUTES.ROOM_INVENTORY,
    allow: 'pms.roomTypes.getOccupancyForecast',
  },
  {
    icon: IconRulerVertical,
    id: 'sidebar.menu.rates',
    name: <FormattedMessage defaultMessage="Rates" id="sidebar.menu.rates" />,
    link: ROUTES.RATES,
    allow: 'pms.ratePlans.getList',
  },
  {
    icon: IconFileInvoice,
    id: 'sidebar.menu.reports',
    name: (
      <FormattedMessage defaultMessage="Reports" id="sidebar.menu.reports" />
    ),
    link: ROUTES.REPORTS,
    submenu: [
      {
        id: 'reports.label.occupancyAndChargeShort',
        name: (
          <FormattedMessage
            defaultMessage="Occupancy & Charge"
            id="reports.label.occupancyAndChargeShort"
          />
        ),
        link: ROUTES.OCCUPANCY_AND_CHARGE_REPORT,
        allow: 'reports.occupancyAndCharges.getList',
      },
      // {
      //   id: 'reports.label.segmentReport',
      //   name: (
      //     <FormattedMessage
      //       defaultMessage="Segment Report"
      //       id="reports.label.segmentReport"
      //     />
      //   ),
      //   link: ROUTES.SEGMENT_REPORT,
      //   allow: 'reports.segments.getList',
      // },
      // {
      //   id: 'reports.label.warehouseReport',
      //   name: (
      //     <FormattedMessage
      //       defaultMessage="Warehouse Report"
      //       id="reports.label.warehouseReport"
      //     />
      //   ),
      //   link: ROUTES.WAREHOUSE_REPORT,
      //   allow: 'reports.warehouse.getList',
      // },
      // {
      //   id: 'reports.label.companies',
      //   name: (
      //     <FormattedMessage
      //       defaultMessage="Companies"
      //       id="reports.label.companies"
      //     />
      //   ),
      //   link: ROUTES.COMPANIES_REPORT,
      //   allow: 'reports.occupancyAndCharges.getList',
      // },
      // {
      //   id: 'reports.label.payments',
      //   name: (
      //     <FormattedMessage
      //       defaultMessage="Payments"
      //       id="reports.label.payments"
      //     />
      //   ),
      //   link: ROUTES.PAYMENT_REPORT,
      //   allow: 'reports.payments.getList',
      // },
      // {
      //   id: 'reports.label.loyalty',
      //   name: (
      //     <FormattedMessage
      //       defaultMessage="loyalty"
      //       id="reports.label.loyalty"
      //     />
      //   ),
      //   link: ROUTES.LOYALTY_REPORT,
      //   allow: 'pms.memberships.getMonthlyPayableExpenseReports',
      // },
      // {
      //   id: 'reports.label.hourlyBooking',
      //   name: (
      //     <FormattedMessage
      //       defaultMessage="Hourly booking"
      //       id="reports.label.hourlyBooking"
      //     />
      //   ),
      //   link: ROUTES.HOURLY_CHARGE_REPORT,
      //   allow: 'reports.occupancyAndCharges.getList',
      // },
      // TODO: temporarily hidden, waiting backend implementation
      // {
      //   id: 'reports.label.budget',
      //   name:< FormattedMessage defaultMessage= 'Budget'  id='reports.label.budget' />,
      //   link: ROUTES.BUDGET_REPORT,
      //   allow: 'hotels.budgets.create',
      // },
    ],
  },
  {
    icon: IconUserTag,
    id: 'sidebar.menu.companyGuests',
    name: (
      <FormattedMessage
        defaultMessage="Companies/Guests"
        id="sidebar.menu.companyGuests"
      />
    ),
    link: ROUTES.COMPANIES_SETTING,
  },
  {
    icon: IconCog,
    id: 'sidebar.menu.settings',
    name: (
      <FormattedMessage defaultMessage="Settings" id="sidebar.menu.settings" />
    ),
    link: ROUTES.SETTINGS,
    submenu: [
      {
        id: 'settings.label.currencies',
        name: (
          <FormattedMessage
            defaultMessage="Currencies"
            id="settings.label.currencies"
          />
        ),
        link: ROUTES.CURRENCY_SETTING,
        allow: 'operation.currencies.updateRate',
      },
      // {
      //   id: 'settings.label.users',
      //   name: (
      //     <FormattedMessage defaultMessage="Users" id="settings.label.users" />
      //   ),
      //   link: ROUTES.USER_SETTING,
      //   allow: 'operation.users.create',
      // },
      {
        id: 'settings.label.payment-types',
        name: (
          <FormattedMessage
            defaultMessage="Payment types"
            id="settings.label.payment-types"
          />
        ),
        link: ROUTES.PAYMENT_TYPES_SETTING,
        allow: 'operation.paymentTypes.create',
      },
      // {
      //   id: 'settings.label.service-groups',
      //   name: (
      //     <FormattedMessage
      //       defaultMessage="Service groups"
      //       id="settings.label.service-groups"
      //     />
      //   ),
      //   link: ROUTES.SERVICE_GROUPS_SETTING,
      //   allow: 'operation.revenueGroups.create',
      // },
      {
        id: 'settings.label.rooms',
        name: (
          <FormattedMessage defaultMessage="Rooms" id="settings.label.rooms" />
        ),
        link: ROUTES.ROOM_TYPE_SETTING,
        allow: 'operation.roomTypes.create',
      },
      {
        id: 'settings.label.hourly-services',
        name: (
          <FormattedMessage
            defaultMessage="Hourly services"
            id="settings.label.hourly-services"
          />
        ),
        link: ROUTES.HOURLY_SERVICES_SETTING,
        allow: 'operation.roomTypes.updateHourlySettings',
      },
      {
        id: 'settings.label.charge-templates',
        name: (
          <FormattedMessage
            defaultMessage="Charge templates"
            id="settings.label.charge-templates"
          />
        ),
        link: ROUTES.CHARGE_TEMPLATES_SETTING,
        allow: 'operation.chargeTemplates.create',
      },
      // {
      //   id: 'settings.label.market-segments',
      //   name: (
      //     <FormattedMessage
      //       defaultMessage="Market segment"
      //       id="settings.label.market-segments"
      //     />
      //   ),
      //   link: ROUTES.MARKET_SEGMENTS_SETTING,
      //   allow: 'operation.marketingSources.create',
      // },
      // {
      //   id: 'settings.label.budget',
      //   name:<FormattedMessage defaultMessage='Budget' id='settings.label.budget' />,
      //   link: ROUTES.BUDGET_SETTING,
      //   allow: 'hotels.budgets.create.create',
      // },
    ],
  },
  {
    icon: IconLockAlt,
    id: 'sidebar.menu.lockDoor',
    name: <FormattedMessage id="sidebar.menu.lockDoor" />,
    link: ROUTES.LOCK_DOOR,
  },
  {
    icon: IconMessengerSupport,
    id: 'sidebar.menu.messengerSupport',
    name: (
      <FormattedMessage
        id="sidebar.menu.messengerSupport"
        defaultMessage="Support"
      />
    ),
    link: 'https://www.facebook.com/AHARooms-PMS-Support-115537940354295',
    external: true,
  },
];

export default navItems;
