import React from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import { createAntField } from '../CreateAntField';
import { Field } from 'react-final-form';

// @ts-ignore
const FormInputAreaWrapper = createAntField<InputProps>(Input.TextArea);

export interface FormInputAreaProps extends TextAreaProps {
  name: string;
  label?: string | React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

const FormInputArea = (props: FormInputAreaProps) => {
  return <Field {...props} component={FormInputAreaWrapper} typing />;
};

export default FormInputArea;
