import { ApplicationRootState } from 'types/app';

const initialStateHolder = (function () {
  let instance: Partial<ApplicationRootState>;

  function createInstance() {
    const object = {};
    return object;
  }

  return {
    getInstance() {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
    setState<T>(key: keyof ApplicationRootState, state: T): T {
      const instance = this.getInstance();

      if (!instance[key]) {
        instance[key] = state;
        return instance[key];
      }
      throw new Error(`The key "${key}" was used!!!`);
    },
  };
})();

export default initialStateHolder;
