import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_HOURLY_BOOKING,
  ADD_HOURLY_BOOKING_CHARGE,
  VOID_HOURLY_BOOKING_CHARGE,
  VOID_HOURLY_BOOKING_PAYMENT,
  VOID_HOURLY_BOOKING,
  ADD_HOURLY_BOOKING_PAYMENT,
  CHECKOUT_HOURLY_BOOKING,
  FETCH_HOURLY_BOOKING_CHANGE_LOGS,
  ADD_HOURLY_BOOKING_DISCOUNT,
  ADD_HOURLY_BOOKING_REFUND,
  CHANGE_HOURLY_BOOKING_ROOM,
} from './constants';
import { coreAPI } from 'utils/request';
import {
  fetchHourlyBookingSuccess,
  fetchHourlyBookingFail,
  addHourlyBookingChargesSuccess,
  addHourlyBookingChargesFail,
  fetchHourlyBookingChangeLogsSuccess,
  fetchHourlyBookingChangeLogsFail,
  voidHourlyBookingSuccess,
  checkOutHourlyBookingSuccess,
} from './actions';
import { showErrorNotification } from '@aha/utils';
import LockDoorAPI from '../BookingProvider/loockdoor';
import moment from 'moment';

export function* doFetchHourlyBooking({ bid }) {
  try {
    const { data } = yield call(coreAPI.get, `v1/pms/hourly-bookings/${bid}`);
    yield put(fetchHourlyBookingSuccess(data));
  } catch (err) {
    yield put(fetchHourlyBookingFail(err));
  }
}

export function* watchHourlyBooking() {
  yield takeLatest(FETCH_HOURLY_BOOKING, doFetchHourlyBooking);
}

export function* doAddCharges({ bid, reqData, resolve, reject }) {
  try {
    const resp = yield all(
      reqData.map((d) =>
        call(coreAPI.post, `v1/pms/hourly-bookings/${bid}/charges`, d),
      ),
    );
    const charges = resp.map((rp) => rp.data);
    yield put(addHourlyBookingChargesSuccess(charges));
    resolve(charges);
  } catch (err) {
    yield put(addHourlyBookingChargesFail(err));
    showErrorNotification('Fail to add charge', err);
    reject(err);
  }
}

export function* watchAddHourlyCharges() {
  yield takeLatest(ADD_HOURLY_BOOKING_CHARGE, doAddCharges);
}

export function* doVoidCharge({ bid, chargeID, resolve, reject }) {
  try {
    const { data } = yield call(
      coreAPI.put,
      `v1/pms/hourly-bookings/${bid}/charges/${chargeID}/void`,
    );
    resolve(data);
  } catch (err) {
    showErrorNotification('Void charge error!', err);
    reject(err);
  }
}

export function* watchVoidCharge() {
  yield takeLatest(VOID_HOURLY_BOOKING_CHARGE, doVoidCharge);
}

export function* doVoidPayment({ bid, paymentId, resolve, reject }) {
  try {
    const { data } = yield call(
      coreAPI.put,
      `v1/pms/hourly-bookings/${bid}/payments/${paymentId}/void`,
    );
    resolve(data);
  } catch (err) {
    showErrorNotification('Void payment error!', err);
    reject(err);
  }
}

export function* watchVoidPayment() {
  yield takeLatest(VOID_HOURLY_BOOKING_PAYMENT, doVoidPayment);
}

export function* doVoidBooking({ booking, resolve, reject, lockdoor }) {
  try {
    if (lockdoor) {
      const roomCharge = booking.charges.find(
        (c) => c.room && c.status === 'active',
      );
      yield call(LockDoorAPI.checkout, roomCharge.room?.lockDoorRoomNo);
    }

    const data = yield call(
      coreAPI.put,
      `v1/pms/hourly-bookings/${booking.id}/void`,
    );

    yield put(voidHourlyBookingSuccess(data));
    LockDoorAPI.removeCached();
    resolve(data);
  } catch (err) {
    showErrorNotification('Void booking error!', err);
    reject(err);
  }
}

export function* watchVoidBooking() {
  yield takeLatest(VOID_HOURLY_BOOKING, doVoidBooking);
}

export function* doAddPayment({ refCode, reqData, resolve, reject }) {
  try {
    yield call(
      coreAPI.post,
      `v1/pms/hourly-bookings/${refCode}/payments`,
      reqData,
    );
    resolve();
  } catch (err) {
    showErrorNotification('Make payment error!', err);
    reject(err);
  }
}

export function* watchAddPayment() {
  yield takeLatest(ADD_HOURLY_BOOKING_PAYMENT, doAddPayment);
}

export function* doCheckout({ booking, resolve, reject, lockdoor }) {
  try {
    if (lockdoor) {
      const roomCharge = booking.charges.find(
        (c) => c.room && c.status === 'active',
      );
      yield call(LockDoorAPI.checkout, roomCharge.room.lockDoorRoomNo);
    }
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/hourly-bookings/${booking.id}/check-out`,
    );
    yield put(checkOutHourlyBookingSuccess(data));
    LockDoorAPI.removeCached();
    resolve(data);
  } catch (err) {
    showErrorNotification('Checkout error!', err);
    reject(err);
  }
}

export function* watchCheckoutBooking() {
  yield takeLatest(CHECKOUT_HOURLY_BOOKING, doCheckout);
}

export function* doFetchChangeLogs({ bid }) {
  try {
    const { data } = yield call(
      coreAPI.get,
      `v1/pms/hourly-bookings/${bid}/change-logs`,
    );
    yield put(fetchHourlyBookingChangeLogsSuccess(data));
  } catch (err) {
    yield put(fetchHourlyBookingChangeLogsFail(err));
  }
}

export function* watchFetchChangeLogs() {
  yield takeLatest(FETCH_HOURLY_BOOKING_CHANGE_LOGS, doFetchChangeLogs);
}

export function* doAddDiscount({ bid, reqData, resolve, reject }) {
  try {
    yield call(coreAPI.post, `v1/pms/hourly-bookings/${bid}/discount`, reqData);
    resolve();
  } catch (err) {
    showErrorNotification('Fail to add charge', err);
    reject(err);
  }
}

export function* watchAddHourlyDiscount() {
  yield takeLatest(ADD_HOURLY_BOOKING_DISCOUNT, doAddDiscount);
}

export function* doAddRefund({ bid, reqData, resolve, reject }) {
  try {
    yield call(coreAPI.post, `v1/pms/hourly-bookings/${bid}/refund`, reqData);
    resolve();
  } catch (err) {
    showErrorNotification('Fail to add charge', err);
    reject(err);
  }
}

export function* watchAddHourlyRefund() {
  yield takeLatest(ADD_HOURLY_BOOKING_REFUND, doAddRefund);
}

export function* doChangeRoom({
  booking,
  chargeId,
  roomId,
  lockDoorRoomNo,
  resolve,
  reject,
}) {
  try {
    if (lockDoorRoomNo) {
      const roomCharge = booking.charges.find(
        (c) => c.room && c.status === 'active',
      );
      const lockdoorData = yield call(LockDoorAPI.readCard);
      const { roomNumber } = lockdoorData;
      // Do we need checkout it first
      if (roomCharge.room.lockDoorRoomNo !== roomNumber) {
        throw new Error('Invalid card! Card not belong to this booking');
      }
      // checkout old room
      yield call(LockDoorAPI.checkout, roomNumber);
      // checkin new room
      yield call(
        LockDoorAPI.checkin,
        {
          ...booking,
          arrival: moment(),
          departure: moment().add(1, 'day'),
        },
        lockDoorRoomNo,
      );
    }
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/hourly-bookings/${booking.id}/change-room`,
      { chargeId, roomId },
    );
    LockDoorAPI.removeCached();
    resolve(data);
  } catch (err) {
    showErrorNotification('Change room error!', err);
    reject(err);
  }
}

export function* watchChangeRoom() {
  yield takeLatest(CHANGE_HOURLY_BOOKING_ROOM, doChangeRoom);
}

export default function* watchAll() {
  yield all([
    watchHourlyBooking(),
    watchAddHourlyCharges(),
    watchVoidCharge(),
    watchVoidPayment(),
    watchVoidBooking(),
    watchAddPayment(),
    watchCheckoutBooking(),
    watchFetchChangeLogs(),
    watchAddHourlyDiscount(),
    watchAddHourlyRefund(),
    watchChangeRoom(),
  ]);
}
