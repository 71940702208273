import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { Link } from '@reach/router';
import { Alert } from 'antd';
import { defaultExpiredDates } from 'containers/BookingProvider/helpers/useTab';

export const DEFAULT_ALERT_HEIGHT = 55;

export type AlertProps = {
  expiredBks: number;
  setExpiredBks: React.Dispatch<React.SetStateAction<number | undefined>>;
  onSize: (w: number, h: number) => void;
};
export const AlertExpiredBookings: React.FC<AlertProps> = ({
  expiredBks,
  setExpiredBks,
  onSize,
}) => {
  const { ref, width, height = DEFAULT_ALERT_HEIGHT } = useResizeObserver<
    HTMLDivElement
  >();
  const [expiredArr, expiredDep] = defaultExpiredDates;

  useEffect(() => {
    if (width && height) {
      onSize(width, height);
    }
  }, [width, height]); // eslint-disable-line

  return (
    <div ref={ref}>
      <Alert
        closable
        className="px-10 py-2 text-center"
        type="warning"
        onClose={() => setExpiredBks(0)}
        message={
          <span className="text-yellow font-medium">
            <FormattedMessage
              defaultMessage="There are {title} needed to be checked out, please update them as soon as possible!"
              id="common.message.warningExpiredBooking"
              values={{
                title: (
                  <Link
                    className="underline text-yellow"
                    to={`bookings/arrival?initSelectedTab=expired_arrival&startDate=${expiredArr}&endDate=${expiredDep}`}
                  >
                    <FormattedMessage
                      defaultMessage="{n, plural, =0 {no expired bookings} =1 {# expired booking} other {# expired bookings}}"
                      id="common.message.numberOfExpiredBookings"
                      values={{ n: expiredBks }}
                    />
                  </Link>
                ),
              }}
            />
          </span>
        }
      />
    </div>
  );
};

export default AlertExpiredBookings;
