import { takeLatest, put, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import {
  updateBookingPeriodSuccess,
  updateBookingPeriodFail,
} from '../actions';
import { UPDATE_BOOKING_PERIOD } from '../constants';
import { showErrorNotification } from '@aha/utils';

function* doExtendPeriod({ bid, dates, reject, resolve }) {
  try {
    const { data } = yield call(
      coreAPI.put,
      `v1/pms/bookings/${bid}/extend`,
      dates,
    );
    yield put(updateBookingPeriodSuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification('Update booking period failed', err);
    yield put(updateBookingPeriodFail(err));
    reject(err);
  }
}

export function* watchUpdateBookingPeriod() {
  yield takeLatest(UPDATE_BOOKING_PERIOD, doExtendPeriod);
}
