import React from 'react';
import { Skeleton } from '@uxui/skeleton-loader';
import tw from 'twin.macro';
import styled from 'styled-components';

const StyledHeader = styled.header`
  ${tw`flex items-center justify-between bg-white px-4`}
  height: 56px;
`;

const ContentHeader = styled.div`
  height: 68px;
  ${tw`flex items-center justify-between`}
`;

const Layout = styled.div`
  ${tw`min-h-screen flex flex-col bg-grey-lighter`}
`;

const Sider = styled.div`
  ${tw`p-4 bg-white flex-none hidden lg:block`}
  width: 220px;
`;

const Content = styled.div`
  ${tw`px-4 pb-6 flex-grow`}
`;

export const HeaderSkeleton = () => (
  <StyledHeader>
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center">
        <Skeleton width="24px" height="24px" className="mr-5" />
        <Skeleton width="28px" height="28px" radius="999px" />
        <Skeleton width="40px" height="16px" className="ml-2" />
      </div>
      <div className="flex items-center">
        <Skeleton width="60px" height="16px" className="mr-5 hidden sm:block" />
        <Skeleton width="26px" height="26px" radius="999px" />
      </div>
    </div>
  </StyledHeader>
);

export const SiderSkeleton = () => (
  <Sider>
    <Skeleton width="100%" height="16px" className="mb-1" />
    <Skeleton width="80%" height="16px" className="mb-1" />
    <Skeleton width="70%" height="16px" className="mb-1" />
  </Sider>
);

export const ContentHeaderSkeleton = () => (
  <ContentHeader>
    <Skeleton
      width="160px"
      height="24px"
      baseColor="#ddd"
      highlightColor="#e4e4e4"
    />
    <Skeleton
      width="120px"
      height="30px"
      baseColor="#ddd"
      highlightColor="#e4e4e4"
      className="hidden sm:block"
    />
  </ContentHeader>
);

export const ContentSkeleton = () => (
  <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
    <Skeleton width="180px" height="16px" />
    <div className="my-2" />
    <Skeleton width="220px" height="16px" />
    <div className="my-2" />
    <Skeleton width="200px" height="16px" />
    <div className="my-2" />
    <Skeleton width="120px" height="16px" />
  </div>
);

export default function SkeletonScreen() {
  return (
    <Layout>
      <HeaderSkeleton />
      <div className="flex flex-grow">
        <SiderSkeleton />
        <Content>
          <ContentHeaderSkeleton />
          <ContentSkeleton />
        </Content>
      </div>
    </Layout>
  );
}
