import { all, put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { showErrorNotification, showSuccessNotification } from '@aha/utils';
import { coreAPI } from 'utils/request';
import { SegmentCodeSettingsActions } from './types';
import { ExtractAction } from 'types/actions';
import ActionTypes from './constants';

import {
  fetchMarketingSourcesSuccess,
  fetchMarketingSourcesFail,
  createMarketingSourceSuccess,
  editMarketingSourceSuccess,
  deleteMarketingSourceSuccess,
} from './actions';
import { MarketChannelListResponse } from 'types/schema';

function* doFetchMarketingSources(
  action: ExtractAction<
    SegmentCodeSettingsActions,
    ActionTypes.FETCH_MARKETING_SOURCES
  >,
) {
  try {
    const { data }: MarketChannelListResponse = yield call(
      coreAPI.get,
      '/v1/pms/market-segments',
    );
    yield put(fetchMarketingSourcesSuccess(data || []));
  } catch (err) {
    yield put(fetchMarketingSourcesFail(err));
  }
}

function* watchFetchMarketingSources() {
  yield takeLatest(
    ActionTypes.FETCH_MARKETING_SOURCES,
    doFetchMarketingSources,
  );
}

function* doCreateMarketingSource(
  action: ExtractAction<
    SegmentCodeSettingsActions,
    ActionTypes.CREATE_MARKETING_SOURCE
  >,
) {
  const {
    payload: { resolve, reject, data },
  } = action;
  const { hotelId, marketingSource } = data || {
    hotelId: '',
    marketingSource: {},
  };
  try {
    const { data } = yield call(
      coreAPI.post,
      'v1/operation/market-segments',
      marketingSource,
    );
    yield put(createMarketingSourceSuccess(data));
    resolve(data);
    showSuccessNotification(
      'The market segment code has been created successfully ',
    );
  } catch (err) {
    showErrorNotification(
      'Fail to create',
      `Cannot create the marketing source in hotel id #${hotelId} at the moment, please try it later.`,
    );
    reject(err);
  }
}

function* watchCreateMarketingSource() {
  yield takeEvery(ActionTypes.CREATE_MARKETING_SOURCE, doCreateMarketingSource);
}

function* doEditMarketingSource(
  action: ExtractAction<
    SegmentCodeSettingsActions,
    ActionTypes.EDIT_MARKETING_SOURCE
  >,
) {
  const {
    payload: { resolve, reject, data },
  } = action;
  const { marketingSourceId, marketingSource } = data || {
    marketingSourceId: '',
    marketingSource: {},
  };

  try {
    const { data } = yield call(
      coreAPI.put,
      `v1/operation/market-segments/${marketingSourceId}`,
      marketingSource,
    );
    yield put(editMarketingSourceSuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification(
      'Fail to edit',
      `Cannot edit the marketing source id #${marketingSourceId} at the moment, please try it later.`,
    );
    reject(err);
  }
}

function* watchEditMarketingSource() {
  yield takeEvery(ActionTypes.EDIT_MARKETING_SOURCE, doEditMarketingSource);
}

function* doDeleteMarketingSource(
  action: ExtractAction<
    SegmentCodeSettingsActions,
    ActionTypes.DELETE_MARKETING_SOURCE
  >,
) {
  const { payload: marketingSourceId } = action;
  try {
    yield call(
      coreAPI.put,
      `v1/operation/market-segments/${marketingSourceId}/archive`,
    );
    yield put(deleteMarketingSourceSuccess(marketingSourceId));
  } catch (err) {
    showErrorNotification(
      'Fail to delete',
      `Cannot delete the marketing source id #${marketingSourceId} at the moment, please try it later.`,
    );
  }
}

function* watchDeleteMarketingSource() {
  yield takeEvery(ActionTypes.DELETE_MARKETING_SOURCE, doDeleteMarketingSource);
}

export default function* watchAll() {
  yield all([
    watchFetchMarketingSources(),
    watchCreateMarketingSource(),
    watchEditMarketingSource(),
    watchDeleteMarketingSource(),
  ]);
}
