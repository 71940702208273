import { all, put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { showErrorNotification } from '@aha/utils';
import { coreAPI } from 'utils/request';
import ActionTypes from './constants';
import { CompanyActions } from './types';

import {
  fetchCompaniesSuccess,
  fetchCompaniesFail,
  createCompanySuccess,
  editCompanySuccess,
  deleteCompanySuccess,
} from './actions';
import { ExtractAction } from 'types/actions';
import { createCompanySuccess as createCompanyBookingSuccess } from 'containers/BookingProvider/actions';
import { setCompanyBookingID } from 'containers/CreateBooking/actions';

function* doFetchCompanies() {
  try {
    const { data } = yield call(
      coreAPI.get,
      `/v1/pms/companies?loadSegment=true`,
    );
    yield put(fetchCompaniesSuccess(data));
  } catch (err) {
    showErrorNotification('Fail to fetch', err);
    yield put(fetchCompaniesFail(err));
  }
}

function* watchFetchCompanies() {
  yield takeLatest(ActionTypes.FETCH_COMPANIES, doFetchCompanies);
}

function* doCreateCompany(
  action: ExtractAction<CompanyActions, ActionTypes.CREATE_COMPANY>,
) {
  const {
    payload: {
      data: { company },
      resolve,
      reject,
    },
  } = action;
  try {
    const { data } = yield call(
      coreAPI.post,
      'v1/pms/operation/companies',
      company,
    );
    yield put(createCompanySuccess(data));
    yield put(createCompanyBookingSuccess(data));
    yield put(setCompanyBookingID(data?.ID));
    resolve(data);
  } catch (err) {
    showErrorNotification('Fail to create the company', err);
    reject(err);
  }
}

function* watchCreateCompany() {
  yield takeEvery(ActionTypes.CREATE_COMPANY, doCreateCompany);
}

function* doEditCompany(
  action: ExtractAction<CompanyActions, ActionTypes.EDIT_COMPANY>,
) {
  const {
    payload: {
      data: { companyId, company },
      resolve,
      reject,
    },
  } = action;
  try {
    const { data } = yield call(
      coreAPI.put,
      `v1/pms/operation/companies/${companyId}`,
      company,
    );
    yield put(editCompanySuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification(
      'Fail to edit',
      `Cannot edit the company id #${companyId} at the moment, please try it later.`,
    );
    reject(err);
  }
}

function* watchEditCompany() {
  yield takeEvery(ActionTypes.EDIT_COMPANY, doEditCompany);
}

function* doDeleteCompany(
  action: ExtractAction<CompanyActions, ActionTypes.DELETE_COMPANY>,
) {
  const { payload: companyId } = action;
  try {
    yield call(coreAPI.put, `v1/pms/operation/companies/${companyId}/archive`);
    yield put(deleteCompanySuccess(companyId));
  } catch (err) {
    showErrorNotification(
      'Fail to delete',
      `Cannot delete the company id #${companyId} at the moment, please try it later.`,
    );
  }
}

function* watchDeleteCompany() {
  yield takeEvery(ActionTypes.DELETE_COMPANY, doDeleteCompany);
}

export default function* watchAll() {
  yield all([
    watchFetchCompanies(),
    watchCreateCompany(),
    watchEditCompany(),
    watchDeleteCompany(),
  ]);
}
