import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { ApplicationRootState } from 'types/app';

const selectHotel = (state: ApplicationRootState) =>
  state.hotels || initialState;

export const makeSelectLoading = () =>
  createSelector(selectHotel, (state) => state.loading);

export const makeSelectHotelSubmitting = () =>
  createSelector(selectHotel, (state) => state.submitting);

export const makeSelectError = () =>
  createSelector(selectHotel, (state) => state.error);

export const makeSelectHotels = () =>
  createSelector(selectHotel, (state) => state.list);

export const makeSelectUpdatingHotels = () =>
  createSelector(selectHotel, (state) => state.processingList);

export const makeSelectHotelLogo = () =>
  createSelector(selectHotel, (state) => state.logo);

export const makeSelectEditingHotel = () =>
  createSelector(selectHotel, (state) => state.editHotel);

export const makeSelectFeaturesHotel = () =>
  createSelector(selectHotel, (state) => state.featuresHotel);

export const makeSelectEditingCurrencies = () =>
  createSelector(selectHotel, (state) => state.editCurrencies);

export function makeSelectActiveHotels() {
  return createSelector(selectHotel, (state) =>
    state.list.filter(({ status }) => status === 'active'),
  );
}
