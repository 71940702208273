import * as React from 'react';
import { Modal as AntModal } from 'antd';
import { Button } from '../Button';
import { ModalProps as AntdModalProps } from 'antd/lib/modal';
import styled from 'styled-components';

export interface ModalProps extends AntdModalProps {
  onSubmit?: (e: React.MouseEvent<HTMLElement>) => void;
  onCancel?: (e?: React.MouseEvent<HTMLElement>) => void;
  submitText?: React.ReactNode | string;
  cancelText?: React.ReactNode | string;
  submitting?: boolean;
  hiddenFooter?: boolean;
  noContentPadding?: boolean;
  title?: React.ReactNode | string;
}

const ModalContainer = styled(AntModal)<ModalProps>`
  width: calc(100% - 48px) !important;
  max-width: ${(props) =>
    props.width
      ? Number.isNaN(+props.width)
        ? props.width
        : `${props.width}px`
      : '520px'};

  .ant-modal-body {
    padding: ${(props) => (props.noContentPadding ? '0px' : '14px 24px 16px')};
  }
`;

export const Modal: React.FC<ModalProps> = ({
  title,
  onSubmit,
  onCancel,
  submitting = false,
  submitText,
  cancelText = 'Cancel',
  hiddenFooter = false,
  children,
  ...rest
}): JSX.Element => (
  <ModalContainer
    title={
      <div className="text-left text-base text-secondary font-semibold uppercase">
        {title}
      </div>
    }
    onOk={onSubmit}
    onCancel={onCancel}
    zIndex={1001}
    destroyOnClose
    keyboard
    footer={
      !hiddenFooter ? (
        <div className="h-full flex justify-end items-center px-2">
          <Button
            className="px-9/2 h-8 w-32"
            onClick={onSubmit}
            disabled={submitting}
          >
            {submitText}
          </Button>
          {cancelText && onCancel && (
            <button
              className="text-secondary font-medium h-8 flex items-center ml-4 px-2 outline-none"
              onClick={(e) => {
                if (!e) return;
                e.preventDefault();
                onCancel(e);
              }}
            >
              {cancelText}
            </button>
          )}
        </div>
      ) : null
    }
    {...rest}
  >
    {children}
  </ModalContainer>
);

export default Modal;
