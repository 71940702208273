import React from 'react';
import { createSelector } from 'reselect';
import loginIllustration from 'img/login-illustration.jpg';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { connect } from 'react-redux';
import AhaSupportButton from 'components/AhaSupportButton';
import LanguageSelection from 'containers/LanguageProvider/LanguageSelection';
import { useMedia } from '@aha/utils';
import Logo from './Logo';
import { FormattedMessage } from 'react-intl';

export function LoginLayout({ children, locale = 'vi' }) {
  const isOnMobile = useMedia();

  return !isOnMobile ? (
    <div className="min-h-screen flex items-center justify-center bg-grey-lightest">
      <div
        className="boxed pr-12"
        style={{ maxWidth: '1000px', height: '595px' }}
        css={`
          max-width: 1000px;
          height: 595px;
        `}
      >
        <div className="relative flex h-full">
          <div
            className="h-full mr-12 bg-center bg-no-repeat bg-cover h-full"
            style={{ width: 595, backgroundImage: `url(${loginIllustration})` }}
          >
            <div
              className=" absolute h-full bg-cyan-darkest inset-0 z-10 opacity-75 h-full"
              style={{ width: 595 }}
            />
            <div
              className="absolute flex flex-col mt-10 z-20"
              style={{ maxWidth: 360 }}
            >
              <Logo />
              <div className="text-3xl text-white font-semibold leading-tight md:ml-12 sm:ml-8 mt-20">
                <FormattedMessage
                  defaultMessage="Boost your hotel bookings and get more revenue"
                  id="common.label.title_login"
                />
              </div>
              <div className="text-sm text-white pt-4 ml-12">
                <FormattedMessage
                  defaultMessage="AHAROOMS is an independent hospitality software developer. AHAROOMS partners with many brands, but makes no claims upon their trademarks. All trademarks contained herein belong to their repective owners and registrants."
                  id="common.label.decription_login"
                />
              </div>
            </div>
          </div>

          <div
            className="flex flex-col justify-between flex-none"
            style={{ width: 294 }}
          >
            <div>{children}</div>
            <div className="flex justify-end mb-12">
              <AhaSupportButton className="mr-6" />
              <LanguageSelection placement="bottomRight" />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="min-h-screen flex items-center justify-center bg-grey-lightest px-8">
      <div className="boxed flex items-center justify-center">
        <div
          className="flex flex-col justify-between flex-none"
          style={{ width: 382, height: 595 }}
        >
          <div className="mx-8">{children}</div>
          <div className="flex justify-end mb-8 mr-10">
            <AhaSupportButton className="mr-8" />
            <LanguageSelection placement="bottomRight" />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = createSelector(makeSelectLocale(), (locale) => ({
  locale,
}));

export default connect(mapStateToProps, null)(LoginLayout);
