import moment from 'moment';

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const FILTER_REPORT = {
  USD: 'USD',
  VND: 'VND',

  BY_DAY: 'date',
  BY_MONTH: 'month',
  BY_ROOM_TYPE: 'room_type',

  CHARGED_AMOUNT: 'CHARGED_AMOUNT',
  NET_VALUE: 'NET_VALUE',
  GROSS_VALUE: 'GROSS_VALUE',
};

export const WEEKEND_DATES = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const OPERATION_SEGMENT = {
  DETAIL: 'DETAIL',
  BY_DATE: 'date',
  BY_SEGMENT: 'marketing_source',
  SAME_TIME_LAST_YEAR: 'SAME_TIME_LAST_YEAR',
};

export const OPERATION_CHARGE_TEMPLATE = {
  EXPORT: 'operation_export',
  IMPORT: 'operation_import',
  ADJUST_QUANTITY: 'operation_adjust_quantity',
  INVENTORY: 'operation_inventory',
};

export const COLORS = [
  'var(--primary)',
  'var(--yellow)',
  'var(--blue)',
  'var(--red)',
  'var(--grey-dark)',
];

export type RoomStatus = 'clean' | 'dirty' | 'cleaning' | 'out_of_order';

export const ROOMS_STATUSES = {
  clean: 'Clean',
  dirty: 'Dirty',
  cleaning: 'Cleaning',
  out_of_order: 'OOO',
};

export type BOOKING_STATUS_COLOR_MAP_TYPE = keyof typeof BOOKING_STAUS_COLOR_MAP;

export const BOOKING_STAUS_COLOR_MAP = {
  checked_in: 'primary',
  checked_out: 'grey',
  awaiting: 'yellow',
  cancelled: 'red',
  voided: 'red',
  no_show: 'red',
  no_show_charge: 'red',
  pending_checkout: 'green',
};

export const CHARGE_TYPE_KEYS = {
  ROOM_CHARGE: 't_room_charge',
  SERVICE_CHARGE: 't_service_charge',
  DISCOUNT: 't_discount',
};

export const DEFAULT_CHARGE_TYPES = [
  { label: 'Room charge', key: 't_room_charge' },
  { label: 'Service charge', key: 't_service_charge' },
  { label: 'Discount', key: 't_discount' },
];

export const DEFAULT_DATE =
  process.env.NODE_ENV === 'test'
    ? moment('2019-10-20T07:25:50.124Z')
    : moment();

export const mapDateToNumber: { [key: string]: number } = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};
