import ActionTypes from './constants';
import { action } from 'typesafe-actions';
import { InjectPromise } from 'types/actions';
import {
  HttpLoginResponse,
  HttpUserDetailWithPemission,
  HttpLoginRequest,
  UserUserDataDetail,
} from 'types/v3-schema';

import { AuthMe, Permission } from 'types/schema';

export const logIn = (
  params: InjectPromise<HttpLoginResponse, HttpLoginRequest>,
) => action(ActionTypes.LOG_IN, params);

export const logInSuccess = () => action(ActionTypes.LOG_IN_SUCCESS);

export const logOut = () => action(ActionTypes.LOG_OUT);

export const fetchUser = () => action(ActionTypes.FETCH_CURRENT_USER);

export const fetchUserFail = (error: Error) =>
  action(ActionTypes.FETCH_CURRENT_USER_FAIL, error);

export const fetchUserSuccess = (
  user: AuthMe,
  permissions: Permission[] = [],
) => action(ActionTypes.FETCH_CURRENT_USER_SUCCESS, { user, permissions });

export const editUser = (
  params: InjectPromise<
    UserUserDataDetail,
    {
      avatar: string;
      name: string;
    }
  >,
) => action(ActionTypes.EDIT_USER, params);

export const editUserSuccess = (user: HttpUserDetailWithPemission) =>
  action(ActionTypes.EDIT_USER_SUCCESS, { user });

export const resetPasswordSuccess = (success: boolean, email: string) =>
  action(ActionTypes.RESET_PASSWORD_SUCCESS, { success, email });

export const resetChangePasswordSuccess = (success: boolean) =>
  action(ActionTypes.RESET_CHANGE_PASSWORD_SUCCESS, { success });
