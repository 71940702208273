import { reduce } from 'lodash';
import { ModelHourlyBookingCharge } from 'types/v3-schema';

export function calcTotalCharge(charges: ModelHourlyBookingCharge[]) {
  let cCode: string = '';
  const totalCharge = reduce(
    charges,
    (fin, c) => {
      const cRate = c?.currency?.Rate?.Rate || 1;
      if (cRate === 1 && !cCode) {
        cCode = c.currency?.Name || '';
      }

      if (c.status !== 'active') {
        return fin;
      }
      if (!c.room) {
        return fin + (c.amount || 0) * cRate;
      }
      return fin + (c.finalAmount || c.amount || 0) * cRate;
    },
    0,
  );
  return { totalCharge, currency: cCode || 'VND' };
}
