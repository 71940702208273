import moment, { Moment } from 'moment';

type Time = string | Date | Moment | undefined;

export function formatDiffTime(start: Time, end: Time) {
  const currentTime = moment(end || undefined);
  const checkInTime = moment(start);
  const timeDifferenceMinutes = currentTime.diff(checkInTime, 'minutes');
  const timeDifferenceHours = currentTime.diff(checkInTime, 'hours');

  let finalTime = '';
  if (timeDifferenceHours > 0) {
    let remainingMinutes: string | number =
      timeDifferenceMinutes - 60 * timeDifferenceHours;

    if (String(remainingMinutes).length === 1) {
      remainingMinutes = `0${remainingMinutes}`;
    }
    finalTime = `${timeDifferenceHours}:${remainingMinutes}`;
  } else if (String(timeDifferenceMinutes).length === 1) {
    finalTime = `0:0${timeDifferenceMinutes}`;
  } else {
    finalTime = `0:${timeDifferenceMinutes}`;
  }

  return finalTime;
}

export default formatDiffTime;
