export enum ActionTypes {
  FETCH_ROOMS = 'roomPlan/FETCH_ROOMS',
  FETCH_ROOMS_SUCCESS = 'roomPlan/FETCH_ROOMS_SUCCESS',
  FETCH_ROOMS_FAIL = 'roomPlan/FETCH_ROOMS_FAIL',

  FETCH_REFRESH_ROOMS = 'roomPlan/FETCH_REFRESH_ROOMS',
  FETCH_REFRESH_ROOMS_SUCCESS = 'roomPlan/FETCH_REFRESH_ROOMS_SUCCESS',
  SET_IS_REFRESH_ROOMS = 'roomPlan/SET_IS_REFRESH_ROOMS',

  UPDATE_ROOM_HK_STATUS = 'roomPlan/UPDATE_ROOM_HK_STATUS',
  UPDATE_ROOM_HK_STATUS_SUCCESS = 'roomPlan/UPDATE_ROOM_HK_STATUS_SUCCESS',
  UPDATE_ROOM_HK_STATUS_FAIL = 'roomPlan/UPDATE_ROOM_HK_STATUS_FAIL',

  CREATE_LOG = 'roomPlan/CREATE_LOG',
  CREATE_LOG_SUCCESS = 'roomPlan/CREATE_LOG_SUCCESS',

  EDIT_LOG = 'roomPlan/EDIT_LOG',
  EDIT_LOG_SUCCESS = 'roomPlan/EDIT_LOG_SUCCESS',

  DELETE_LOG = 'roomPlan/DELETE_LOG',
  DELETE_LOG_SUCCESS = 'roomPlan/DELETE_LOG_SUCCESS',

  FETCH_LOGS = 'roomPlan/FETCH_LOGS',
  FETCH_LOGS_SUCCESS = 'roomPlan/FETCH_LOGS_SUCCESS',

  OPEN_CREATE_LOG = 'roomPlan/OPEN_CREATE_LOG',
}

export default ActionTypes;
