import { takeLatest, put, call } from 'redux-saga/effects';

import { fetchFolioDetailSuccess, fetchFolioDetailFail } from '../actions';
import { coreAPI } from 'utils/request';
import { FETCH_FOLIO_DETAIL } from '../constants';
import { showErrorNotification } from '@aha/utils';
import NProgress from 'nprogress';

function* doFetch({ bid, fid }) {
  yield call(NProgress.start);
  try {
    const { data: folio } = yield call(
      coreAPI.get,
      `v1/pms/bookings/${bid}/folios/${fid}`,
    );

    yield put(fetchFolioDetailSuccess(folio));
  } catch (err) {
    showErrorNotification('Fetch folio failed', err);
    yield put(fetchFolioDetailFail(err));
  }
  yield call(NProgress.done);
}

export function* watchFetctFolioDetail() {
  yield takeLatest(FETCH_FOLIO_DETAIL, doFetch);
}
