import _ from 'lodash';
import moment, { Moment } from 'moment';
import { currencyFormater } from 'utils/currencyFormater';
import { calcTotalBalance } from 'containers/BookingProvider/helpers/balance-calculator';
import {
  TimelineEvent,
  TimelineGroup,
} from 'components/TimelineScheduler/type';
import { formatDatetime } from '@aha/utils';
import {
  HotelCurrency,
  Room,
  Booking,
  Occupancy,
  OccupancyForecasting,
} from 'types/schema';

export type BookingStatusShortKeyType = keyof typeof BOOKING_STATUS_SHORT;

export type SearchStatusKeyType = keyof typeof SEARCH_STATUS;

export const BOOKING_STATUS_SHORT = {
  AWAITING: 'awaiting',
  CHECKED_IN: 'checked_in',
  CHECKED_OUT: 'checked_out',
  CANCELLED: 'cancelled',
  PENDING_CHECKOUT: 'pending_checkout',
  VOIDED: 'voided',
  NO_SHOW: 'no_show',
  NO_SHOW_CHARGE: 'no_show_charge',
};

export const SEARCH_STATUS = {
  ARRIVAL: 'arrival',
  DEPARTURE: 'departure',
  CHECKED_IN: 'checked_in',
  CHECKED_OUT: 'checked_out',
  IN_HOUSE: 'inhouse',
  CANCELLED: 'cancelled',
  NO_SHOW_CHARGE: 'no_show_charge',
  EXPIRED: 'expired',
};

export const BOOKING_STATUS = {
  ...BOOKING_STATUS_SHORT,
  EXPECT_CHECK_IN: 'awaiting_checkin',
  EXPECT_CHECK_OUT: 'awaiting_checkout',
  EXPIRED_CHECKIN: 'expired_checkin',
  EXPIRED_CHECKOUT: 'expired_checkout',
};

interface NumberMap {
  [key: string]: number;
}

export function makeCalendarItems(items: Booking[], cList: HotelCurrency[]) {
  // map booking to calendar cell
  const bookingRooms = _.reduce(
    items,
    (fin, bk: Booking) => {
      // unwind booking group to 1 - 1 booking - room
      const bkList = _.map(bk.rooms, (r) => {
        const startDate = moment(bk.arrival);
        let endDate = moment(r.checkedOutAt || bk.checkedOutAt || bk.departure);
        const now = moment().startOf('day');
        const bkStatus = r.status || bk.status;
        let bookingType = 'expired';
        if (bkStatus === BOOKING_STATUS.CHECKED_OUT) {
          bookingType = 'checkedout';
        } else if (
          now.isAfter(moment(bk.departure), 'day') &&
          bkStatus === BOOKING_STATUS.CHECKED_IN
        ) {
          bookingType = 'overstay';
        } else if (bkStatus === BOOKING_STATUS.CHECKED_IN) {
          bookingType = 'checkedin';
        } else if (bkStatus === BOOKING_STATUS.AWAITING && endDate > now) {
          bookingType = 'awaiting_checkin';
        } else if (bkStatus === BOOKING_STATUS.PENDING_CHECKOUT) {
          bookingType = 'pending_checkout';
        }
        if (
          moment(endDate)
            .startOf('day')
            .diff(moment(startDate).startOf('day'), 'day') === 0
        ) {
          endDate = moment(endDate).add(1, 'day');
        }

        const { total: totalBalance, currency } = calcTotalBalance(
          bk.balance,
          cList,
        );

        // const { totalCharge: tco, totj
        const guest = r.guests?.[0] || bk.guest || { fullName: '' };
        return {
          id: r.id || 'invalid_id',
          room: r,
          status: r.status,
          groupID: r.roomId,
          context: `${bk.company || 'WI'} - ${guest.fullName || ''}`,
          booking: bk,
          bookingType,
          isCheckoutAble: totalBalance === 0,
          formatedBalance: currencyFormater(totalBalance, currency),
          startTime: moment(startDate).set({
            hour: 14,
            minute: 30,
            second: 0,
            millisecond: 0,
          }),
          endTime: moment(endDate).set({
            hour: 11,
            minute: 30,
            second: 0,
            millisecond: 0,
          }),
        } as TimelineEvent;
      });
      return _.concat(fin, bkList);
    },
    [] as TimelineEvent[],
  );
  return bookingRooms as TimelineEvent[];
}

export function makeCalendarGroup(
  rooms: Room[] = [],
  openGroups: { [key: string]: boolean } = {},
) {
  const defaultShowAll = !_.size(openGroups);
  const groups = _.reduce(
    _.groupBy(
      rooms,
      (r) => `${_.get(r, 'roomType.id')};;;${_.get(r, 'roomType.name')}`,
    ),
    (res, v: Room[], k: string) => {
      const filtedRooms = _.filter(
        v,
        (r) => defaultShowAll || openGroups[`rt_${_.get(r, 'roomType.id')}`],
      );
      return _.concat(res, [
        {
          id: `rt_${_.split(k, ';;;')[0]}`,
          context: _.truncate(_.split(k, ';;;')[1]),
          root: true,
        } as TimelineGroup,
        ..._.map(
          filtedRooms,
          (r) =>
            ({
              id: r.id,
              context: _.truncate(r.title),
              parentID: `rt_${_.split(k, ';;;')[0]}`,
            } as TimelineGroup),
        ),
      ]);
    },
    [] as TimelineGroup[],
  );
  return groups;
}

export function makeCalendarOccForecast(
  occForecasts: Occupancy[],
  start: Date | Moment,
  end: Date | Moment,
) {
  const blankDates = [
    ...Array(
      moment(end).startOf('day').diff(moment(start).startOf('day'), 'days') +
        1 || 7,
    ).fill(0),
  ].map((e, i) => moment(start).startOf('day').add(i, 'day'));

  const items = _.map(occForecasts, (forecast: Occupancy) => {
    const baseItem = {
      groupID: `rt_${forecast.roomTypeId}`,
      isOcc: true,
    };
    const {
      capacity = 0,
      outOfServiceCount = 0,
      occupancyForecastings = [],
      outOfOrderRules = [],
    } = forecast;
    const bkCounts = _.reduce(
      occupancyForecastings,
      (counts: OccupancyForecasting, bk) => {
        const clone = { ...counts };
        let arv = moment(bk.fromDate);
        let dep = moment(bk.toDate);
        if (arv.diff(moment(start), 'day') < 0) {
          arv = moment(start);
        }
        if (dep.diff(moment(end), 'day') > 0) {
          dep = moment(end);
        }
        for (let i = 0; i <= dep.diff(arv, 'day'); i += 1) {
          const date = moment(arv).add(i, 'day');
          if (moment(date).isBetween(bk.fromDate, bk.toDate, 'day', '[)')) {
            //FIXME: v4-migration update swagger
            // @ts-ignore
            if (clone[date.format('DD-MM-YYYY')]) {
              //FIXME: v4-migration update swagger
              // @ts-ignore
              clone[date.format('DD-MM-YYYY')] += 1;
            } else {
              //FIXME: v4-migration update swagger
              // @ts-ignore
              clone[date.format('DD-MM-YYYY')] = 1;
            }
          }
        }
        return clone;
      },
      {},
    );

    const oooCounts = outOfOrderRules.reduce(
      (res: Record<string, number>, ooo) =>
        ooo.serviceDate
          ? {
              ...res,
              [ooo.serviceDate]: ooo.quantity || 0,
            }
          : res,
      {},
    );

    return _.map(blankDates, (date) => {
      //FIXME: v4-migration update swagger
      // @ts-ignore
      const bkCount = bkCounts[formatDatetime(date, 'DD-MM-YYYY')] || 0;
      //FIXME: v4-migration update swagger
      // @ts-ignore
      const oooCount = oooCounts[formatDatetime(date, 'YYYY/MM/DD')] || 0;

      const freeCount = capacity - bkCount - oooCount;
      return {
        ...baseItem,
        id: `${baseItem.groupID}-${formatDatetime(date, 'DD-MM-YYYY')}`,
        context: `${freeCount}/${capacity - oooCount}`,
        startTime: moment(date).startOf('day'),
        endTime: moment(date).endOf('day'),
        freeCount,
        bookingCount: bkCount,
        roomCap: capacity - oooCount,
        outOfService: outOfServiceCount,
        oooCount,
      };
    });
  });

  const occForecastItems = _.flatten(items);
  const groupByStartTime = _.groupBy(occForecastItems, 'startTime');
  const processTotal = _.reduce(
    groupByStartTime,
    (fin, items, key) => {
      return {
        ...fin,
        [formatDatetime(key, 'DD-MM-YYYY')]: items.reduce(
          ({ inventory, capacity, bookings }, i) => {
            return {
              inventory: inventory + i.freeCount,
              capacity: capacity + (i.roomCap || 0),
              bookings: bookings + i.bookingCount,
            };
          },
          { inventory: 0, capacity: 0, bookings: 0 },
        ),
      };
    },
    {} as { [key: string]: any },
  );
  return {
    occForecastItems,
    totalOcc: _.reduce(
      processTotal,
      (fin, item, k) => ({
        ...fin,
        [k]: {
          ...item,
          occ: ((item.bookings / (item.capacity || 1)) * 100).toFixed(2),
        },
      }),
      {} as { [key: string]: { [key: string]: any } },
    ),
  };
}
