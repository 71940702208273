import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'antd';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectIsLoadingAvailableRooms,
  makeSelectMapAvailableRooms,
} from 'containers/BookingProvider/selector';
import {
  fetchAvailableRooms,
  resetAvailableRooms,
} from 'containers/BookingProvider/actions';
import { makeSelectCurrentHotel } from 'containers/Dashboard/selectors';
import { FormattedMessage } from 'react-intl';
import reducer from 'containers/BookingProvider/reducer';
import saga from 'containers/BookingProvider/saga';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { Modal, Select, NoDataBlock, NAFallback } from '@aha/ui';
import AvaillableRoomsModalSkeletonLoader from 'components/skeleton/pages/AvaillableRoomsModalSkeletonLoader';
import RoomBlock from 'components/RoomBlock';
import { Moment } from 'moment';
import { Hotel, Room } from 'types/schema';
import { Dispatch } from 'redux';
import { ApplicationRootState } from 'types/app';
import { ModalProps } from '@aha/ui/src/components/Modal';
import { AllocateRoom } from 'containers/CreateBooking/components/RoomFormBlock';
import useSelectRoomTypes from 'containers/SettingRoomTypesPage/useSelectRoomTypes';
import useSelectCurrentHotel from 'containers/Dashboard/useSelectCurrentHotel';
import { RoomStatus } from 'utils/constants';
import moment from 'moment';

export type RoomDate = Date | Moment | string;

export interface AvailableRoomsModalProps extends ModalProps {
  onSelectRoom: (
    roomID: any,
    roomTypeID: any,
    room: Room,
    privateId?: number,
  ) => any;
  selectedRoomType?: AllocateRoom | null;
  arrival?: RoomDate;
  departure?: RoomDate;
  allocating?: boolean;
  disabledRooms?: Array<number>;
  loading?: boolean;
  hotel?: Hotel;
  bookingRoomId?: string;
  disabledRoomType?: boolean;
  hideRoomTypes?: boolean;
}

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    loading: boolean;
    hotel: Hotel | null;
    roomsMap: Record<string, Room[]>;
  }
>({
  loading: makeSelectIsLoadingAvailableRooms(),
  hotel: makeSelectCurrentHotel(),
  roomsMap: makeSelectMapAvailableRooms(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doFetchAvailableRooms: (
    hid: number,
    start: RoomDate,
    end: RoomDate,
    roomTypeID?: number,
  ) => dispatch(fetchAvailableRooms(hid, start, end, roomTypeID)),
  doResetAvailableRooms: () => dispatch(resetAvailableRooms()),
});

export function AvailableRoomsModal(props: AvailableRoomsModalProps) {
  useInjectReducer({ key: 'bookings', reducer });
  useInjectSaga({ key: 'bookings', saga });

  const hotel = useSelectCurrentHotel();
  const { roomTypes } = useSelectRoomTypes();
  const {
    allocating,
    arrival,
    departure,
    bookingRoomId,
    selectedRoomType,
    onSelectRoom,
    visible,
    hideRoomTypes = false,
    disabledRoomType,
    disabledRooms = [],
    ...rest
  } = props;

  const dispatch = useDispatch();
  const { loading, roomsMap } = useSelector(mapStateToProps);
  const { doFetchAvailableRooms, doResetAvailableRooms } = mapDispatchToProps(
    dispatch,
  );
  const [curRoomType, setCurRoomType] = useState<null | number>(null);

  useEffect(() => {
    return () => {
      doResetAvailableRooms();
    };
  }, [hotel?.id, arrival, departure]); // eslint-disable-line

  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    if (
      arrival &&
      departure &&
      hotel?.id &&
      selectedRoomType?.id &&
      !roomsMap[selectedRoomType?.id]
    ) {
      doFetchAvailableRooms(hotel.id, arrival, departure, selectedRoomType?.id);
    }
  }, [hotel?.id, arrival, departure, selectedRoomType?.id, roomsMap]); // eslint-disable-line

  useEffect(() => {
    if (selectedRoomType?.id) {
      setCurRoomType(selectedRoomType?.id);
    }
  }, [selectedRoomType?.id, visible]); // eslint-disable-line

  const filteredRooms = useMemo(
    () =>
      curRoomType
        ? roomsMap[curRoomType]?.filter((r) => r.status !== 'out_of_order')
        : [],
    [curRoomType, roomsMap],
  );

  function selectRoom(room: Room) {
    if (allocating) {
      return;
    }
    onSelectRoom(room?.id, room?.roomTypeId, room, selectedRoomType?.privateId);
  }

  const content = (
    <Fragment>
      {!hideRoomTypes && (
        <Form layout="vertical">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="Room type"
                id="common.label.roomType"
              />
            }
          >
            <Select
              className="w-full lg:w-2/5"
              onChange={(rtID) => setCurRoomType(rtID)}
              onSelect={(rtID) => {
                if (
                  !hotel.id ||
                  !arrival ||
                  !departure ||
                  !rtID ||
                  roomsMap[rtID]
                ) {
                  return;
                }
                doFetchAvailableRooms(hotel.id, arrival, departure, rtID);
              }}
              value={curRoomType}
              disabled={disabledRoomType}
              placeholder={
                <FormattedMessage defaultMessage="All" id="common.label.all" />
              }
            >
              <Select.Option key="all" value="all">
                <FormattedMessage defaultMessage="All" id="common.label.all" />
              </Select.Option>
              {roomTypes?.map((rt) => (
                <Select.Option key={rt?.id} value={rt?.id}>
                  {rt?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      )}
      {loading ? (
        <AvaillableRoomsModalSkeletonLoader hideRoomTypes />
      ) : !filteredRooms?.length ? (
        <NoDataBlock className="py-10" />
      ) : (
        <div className="flex flex-wrap -mx-2 -mt-2">
          {(filteredRooms || []).map((room, index) => {
            const log = room?.roomAvailabilityLogs?.[0];
            const housekeepingStatus = (log &&
            moment().isBetween(
              moment(log.unAvailableFrom),
              moment(log.availableDate),
              'minutes',
              '[]',
            )
              ? 'out_of_order'
              : room.housekeepingStatus) as RoomStatus;

            return (
              <div
                key={index}
                className="w-full p-2 col sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5"
              >
                <RoomBlock
                  housekeepingStatus={housekeepingStatus}
                  room={{
                    id: room.id,
                    title: room.title,
                    housekeepingStatus: room.housekeepingStatus,
                  }}
                  disabled={
                    allocating || disabledRooms.includes(Number(room?.id))
                  }
                  onClick={() => selectRoom(room)}
                />
              </div>
            );
          })}
        </div>
      )}
    </Fragment>
  );

  return (
    <Modal
      width={957}
      visible={visible}
      title={<NAFallback value={selectedRoomType?.name} />}
      hiddenFooter
      {...rest}
    >
      {content}
    </Modal>
  );
}

export default AvailableRoomsModal;
