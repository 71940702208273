import React, { useState } from 'react';
import LoginLayout from 'components/LoginLayout';
import { Button } from '@aha/ui';
import { Form } from 'react-final-form';
import { PasswordInput } from '@aha/ui';
import { showErrorNotification } from '@aha/utils';
import { coreAPI } from 'utils/request';
import { HttpVerifyRequest } from 'types/v3-schema';
import { RouteComponentProps, Redirect, navigate } from '@reach/router';
import ROUTES from 'constants/routes';
import { FormattedMessage, useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { makeSelectResetChangePasswordSuccess } from 'containers/Auth/selectors';
import { resetChangePasswordSuccess } from 'containers/Auth/actions';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    changePasswordSuccess: boolean;
  }
>({
  changePasswordSuccess: makeSelectResetChangePasswordSuccess(),
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    doResetChangeSuccess: (success: boolean) =>
      dispatch(resetChangePasswordSuccess(success)),
  };
};

export default function ResetPassword({ location }: RouteComponentProps) {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const token = location?.href?.split('?token=')[1];
  const { changePasswordSuccess } = useSelector(mapStateToProps);
  const { doResetChangeSuccess } = mapDispatchToProps(dispatch);

  if (!token) {
    return <Redirect to={ROUTES.LOGIN} noThrow />;
  }
  async function submitResetForm(values: Partial<HttpVerifyRequest>) {
    setSubmitting(true);
    try {
      await coreAPI.put('/v1/pms/auth/reset-password', values, {
        headers: { Authorization: `Bearer ${token}` },
      });
      doResetChangeSuccess(true);
    } catch (err) {
      showErrorNotification('Can not reset password', err);
    } finally {
      setSubmitting(false);
    }
  }
  const validate = (values: Partial<HttpVerifyRequest>) => {
    const errors: {
      newPassword?: string;
      confirmPassword?: string;
    } = {};

    if (!values.newPassword) {
      errors.newPassword = intl.formatMessage({
        defaultMessage: 'Required',
        id: 'common.message.required',
      });
    } else if (values.newPassword.length < 8) {
      errors.newPassword = intl.formatMessage({
        defaultMessage: 'Must have at least 8 characters',
        id: 'auth.message.validate_password_length',
      });
    } else if (!RegExp(/^(?=.*?[A-Z])/).test(values.newPassword)) {
      errors.newPassword = intl.formatMessage({
        defaultMessage: 'Including at least one capital letter',
        id: 'auth.message.validate_password_capital_letter',
      });
    } else if (!RegExp(/^(?=.*?[0-9])(?=.*?[a-z])/).test(values.newPassword)) {
      errors.newPassword = intl.formatMessage({
        defaultMessage:
          'Including at least one lowercase letter and one number',
        id: 'auth.message.validate_password_letter_number',
      });
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = intl.formatMessage({
        defaultMessage: 'Required',
        id: 'common.message.required',
      });
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = intl.formatMessage({
        defaultMessage: 'Password does not match',
        id: 'auth.message.validate_password_not_match',
      });
    }
    return errors;
  };

  return (
    <LoginLayout>
      {changePasswordSuccess ? (
        <>
          <p className="text-center text-sm text-secondary mb-4 font-medium mx-5">
            <FormattedMessage
              defaultMessage="We have successfully reset your password"
              id="auth.message.resetPasswordSuccess"
            />
          </p>
          <div className="flex justify-center mt-6">
            <Button
              color="primary"
              size="large"
              className="block w-full font-medium text-base normal-case"
              onClick={() => {
                doResetChangeSuccess(false);
                navigate(ROUTES.LOGIN);
              }}
            >
              <FormattedMessage
                defaultMessage="Back to login"
                id="auth.actions.back_to_login"
              />
            </Button>
          </div>
        </>
      ) : (
        <Form
          validate={validate}
          onSubmit={submitResetForm}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="mt-10">
              <h3 className="text-2xl text-secondary leading-none font-semibold mb-5">
                <FormattedMessage
                  defaultMessage="Reset password"
                  id="auth.resetPassword"
                />
              </h3>
              <p className="mb-8 text-grey-darker text-medium">
                <FormattedMessage
                  defaultMessage="Please choose new password to finish signing in"
                  id="auth.message.enterNewPasswordRequired"
                />
              </p>

              <PasswordInput
                name="newPassword"
                placeholder={intl.formatMessage({
                  defaultMessage: 'New password',
                  id: 'auth.newPassword',
                })}
              />
              <PasswordInput
                name="confirmPassword"
                placeholder={intl.formatMessage({
                  defaultMessage: 'Re-enter new password',
                  id: 'auth.reNewPassword',
                })}
              />
              <div className="flex justify-center mt-6">
                <Button
                  color="primary"
                  htmlType="submit"
                  size="large"
                  className="block w-full font-medium text-base normal-case"
                  disabled={submitting}
                >
                  <FormattedMessage
                    defaultMessage="Change password"
                    id="auth.actions.change_password"
                  />
                </Button>
              </div>
            </form>
          )}
        />
      )}
    </LoginLayout>
  );
}
