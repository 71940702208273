import React, { Suspense } from 'react';
import { PageHeader } from '@aha/ui';
import SkeletonTable from 'components/skeleton/TableSkeletonScreen';
import { FormattedMessage } from 'react-intl';
import { Location } from '@reach/router';
import SearchNavBar from 'components/SearchNavBar';

export const Search: React.FC<any> = ({ children }) => {
  return (
    <>
      <Location>
        {(path) => (
          <PageHeader
            title={
              <FormattedMessage
                defaultMessage="Search"
                id="common.label.search"
              />
            }
            extra={<SearchNavBar pageID={path.location.pathname} />}
          />
        )}
      </Location>
      <Suspense fallback={<SkeletonTable />}>{children}</Suspense>
    </>
  );
};

export default Search;
