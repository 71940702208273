import React from 'react';
import { notification } from 'antd';
import { IconCheckCircle } from '@aha/icons';

export default function showSuccessNotification(
  message: React.ReactNode,
  description?: React.ReactNode,
) {
  notification.open({
    className: 'bg-green-lightest border border-green',
    message,
    description,
    icon: <IconCheckCircle className="text-green" />,
  });
}
