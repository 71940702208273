import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { SubHeading } from '../..';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  height: 56px;
`;

const ExtraWrapper = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 16px;
`;

export interface CardProps {
  title?: any;
  extra?: any;
  className?: string;
  contentClassName?: string;
  headerClassName?: string;
  noContentPadding?: boolean;
  hasBorder?: boolean;
}

export const Card: React.FC<CardProps> = ({
  title,
  children,
  extra,
  noContentPadding = false,
  contentClassName,
  className,
  headerClassName,
  hasBorder = false,
}) => {
  if (!title) {
    return (
      <div
        className={classnames('bg-white rounded-sm p-6', {
          [className]: className,
        })}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={classnames('bg-white rounded-sm overflow-hidden', {
        [className]: className,
      })}
    >
      <Header
        className={classnames({
          [headerClassName]: headerClassName,
          'border-b border-grey-lighter': hasBorder,
        })}
      >
        <SubHeading className="font-medium">{title}</SubHeading>
        {extra && <ExtraWrapper>{extra}</ExtraWrapper>}
      </Header>
      <div
        className={classnames({
          [contentClassName]: contentClassName,
          'p-6': !noContentPadding,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Card;
