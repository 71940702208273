import React, { Suspense, useEffect } from 'react';
import { Layout } from 'antd';
import { Redirect } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled, { createGlobalStyle } from 'styled-components';

import {
  makeSelectCurrentHotelId,
  makeSelectHotelLoading,
  makeSelectCurrentHotel,
  ModelHotelWithFeatures,
} from 'containers/Dashboard/selectors';
import {
  makeSelectIsLogin,
  makeSelectUser,
  makeSelectUserIsLoading,
} from 'containers/Auth/selectors';
import authSaga from 'containers/Auth/saga';
import { fetchUser } from 'containers/Auth/actions';
import { fetchCurrentHotel } from 'containers/Dashboard/actions';
import { fetchCurrencies } from 'containers/CurrencySettings/actions';
import { useInjectSaga } from 'utils/injectSaga';
import curreniesSaga from 'containers/CurrencySettings/saga';
import dashboardSaga from 'containers/Dashboard/saga';
import ROUTES from 'constants/routes';
import tw from 'twin.macro';
import PrintingLayout from 'components/PrintingLayout';
import TableSkeletonScreen from 'components/skeleton/TableSkeletonScreen';
import { ApplicationRootState } from 'types/app';
import { Dispatch } from 'redux';
import { AuthMe } from 'types/schema';

const GlobalStyles = createGlobalStyle`
    html, body {
      ${tw`bg-grey-lighter`};
    }

    @media print {
      html, body {
        height:100%;
        overflow: hidden;
        background: #fff;
        font-size: 6.5pt;
      }
    }
`;

export const PrintingSkeleton = styled(TableSkeletonScreen)`
  height: calc(100vh - 10rem);
`;

const { Content } = Layout;

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    isLogin: boolean;
    currentHotelId: number | null;
    currentHotel: ModelHotelWithFeatures | null;
    user: AuthMe | null;
    userIsLoading: boolean;
    hotelIsLoading: boolean;
  }
>({
  isLogin: makeSelectIsLogin(),
  currentHotelId: makeSelectCurrentHotelId(),
  currentHotel: makeSelectCurrentHotel(),
  user: makeSelectUser(),
  userIsLoading: makeSelectUserIsLoading(),
  hotelIsLoading: makeSelectHotelLoading(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCurrentUser: () => dispatch(fetchUser()),
  fetchCurrentHotel: (hotelId: number) => dispatch(fetchCurrentHotel(hotelId)),
  doFetchCurrencies: (hotelId: number) => dispatch(fetchCurrencies(hotelId)),
});

export const Printing: React.FC<any> = ({ children }) => {
  useInjectSaga({ key: 'auth', saga: authSaga });
  useInjectSaga({
    key: 'dashboard',
    saga: dashboardSaga,
  });
  useInjectSaga({ key: 'currencies', saga: curreniesSaga });

  const {
    isLogin,
    currentHotelId,
    user,
    userIsLoading,
    currentHotel,
    hotelIsLoading,
  } = useSelector(mapStateToProps);

  const dispatch = useDispatch();
  const { fetchCurrentHotel, fetchCurrentUser } = mapDispatchToProps(dispatch);

  useEffect(() => {
    // Prevent useEffect call when running test
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    if (!isLogin || !currentHotelId) {
      return;
    }

    if (!user) {
      fetchCurrentUser();
    }

    if (!currentHotel) {
      fetchCurrentHotel(currentHotelId);
    }
  }, [user]); // eslint-disable-line

  if (!isLogin) {
    return <Redirect to={ROUTES.LOGIN} noThrow />;
  }

  if (!currentHotelId) {
    return <Redirect to={ROUTES.SELECT_HOTEL} noThrow />;
  }

  if (hotelIsLoading || userIsLoading || !user || !currentHotel) {
    return (
      <div className="bg-grey-lighter">
        <GlobalStyles />
        <Content className="my-4 mr-6 ml-0">
          <PrintingLayout type="invoice">
            <PrintingSkeleton />
          </PrintingLayout>
        </Content>
      </div>
    );
  }

  return (
    <div className="bg-grey-lighter">
      <GlobalStyles />
      <Suspense
        fallback={
          <PrintingLayout type="invoice">
            <PrintingSkeleton />
          </PrintingLayout>
        }
      >
        <Content className="my-4 mr-6 ml-0">{children}</Content>
      </Suspense>
    </div>
  );
};

export default Printing;
