import { takeLatest, all, call, put } from 'redux-saga/effects';
import ActionTypes from '../constants';
import {
  setSelectedHotel,
  removeSelectedHotel,
  coreAPI,
  setToken,
} from 'utils/request';
import { setCurrentHotelIdSuccess, setCurrentHotelIdFail } from '../actions';
import { DashboardActions } from '../type';
import { ExtractAction } from 'types/actions';

export function* doSetCurrentHotelId(
  action: ExtractAction<DashboardActions, ActionTypes.SET_CURRENT_HOTEL_ID>,
) {
  const { hotelId } = action.payload;

  try {
    const { token } = yield call(coreAPI.post, '/v1/pms/auth/select-hotel', {
      hotelId,
    });
    yield call(setToken, token);
    yield call(setSelectedHotel, hotelId);
    yield put(setCurrentHotelIdSuccess(hotelId));
  } catch (err) {
    yield put(setCurrentHotelIdFail(err));
  }
}

export function* doRemoveCurrentHotelId() {
  yield call(removeSelectedHotel);
}

export default function* watchSetHotelId() {
  yield all([
    takeLatest(ActionTypes.SET_CURRENT_HOTEL_ID, doSetCurrentHotelId),
    takeLatest(ActionTypes.FETCH_CURRENT_HOTEL_FAIL, doRemoveCurrentHotelId),
  ]);
}
