import produce from 'immer';
import { HotelsState, HotelsActions } from './type';
import ActionTypes from './constants';

export const initialState = {
  loading: false,
  submitting: false,
  error: false,
  list: [],
  processingList: [],
  hotel: {},
  editHotel: null,
  editCurrencies: null,
  featuresHotel: [],
  CMS: null,
  logo: null,
};

/* eslint-disable default-case, no-param-reassign */
const hotelsReducer = (
  state: HotelsState = initialState,
  action: HotelsActions,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.FETCH_HOTELS:
        draft.loading = true;
        draft.error = false;
        break;
      case ActionTypes.FETCH_HOTELS_SUCCESS:
        draft.loading = false;
        draft.list = action.payload.hotels;
        break;
      case ActionTypes.FETCH_HOTELS_FAIL:
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      case ActionTypes.UPDATE_HOTEL_STATUS:
        if (action.payload.hotel.id) {
          draft.processingList.push(action.payload.hotel.id);
          draft.submitting = true;
          draft.error = false;
        }
        break;
      case ActionTypes.UPDATE_HOTEL_STATUS_SUCCESS:
        draft.processingList = draft.processingList.filter(
          (id: number) => id !== action.payload.hotel.id,
        );
        draft.list = draft.list.map((h) =>
          h.id === action.payload.hotel.id ? action.payload.hotel : h,
        );
        draft.submitting = false;
        draft.error = false;
        break;
      case ActionTypes.UPDATE_HOTEL_STATUS_FAIL:
        draft.processingList = draft.processingList.filter(
          (id) => id !== action.payload.hotel.id,
        );
        draft.submitting = false;
        draft.error = action.payload.error;
        break;

      case ActionTypes.FETCH_CURRENT_HOTEL_SUCCESS:
        draft.hotel = action.payload.hotel;
        draft.error = null;
        break;

      case ActionTypes.FETCH_CURRENT_HOTEL_FAIL:
        draft.error = action.payload.error;
        break;

      case ActionTypes.FETCH_HOTEL_DETAILS:
        draft.loading = true;
        break;

      case ActionTypes.FETCH_HOTEL_DETAILS_SUCCESS:
        draft.loading = false;
        draft.editHotel = action.payload.editHotel;
        draft.editCurrencies = action.payload.editCurrencies;
        break;

      case ActionTypes.FETCH_HOTEL_DETAILS_FAIL:
        draft.loading = false;
        draft.error = action.payload.error;
        break;

      case ActionTypes.FETCH_FEATURES_SUCCESS:
        draft.featuresHotel = action.payload.features;
        break;

      case ActionTypes.CREATE_HOTEL:
        draft.submitting = true;
        break;

      case ActionTypes.CREATE_HOTEL_SUCCESS:
        draft.submitting = false;
        draft.list.push(action.payload.hotel);
        break;

      case ActionTypes.CREATE_HOTEL_FAIL:
        draft.submitting = false;
        draft.error = action.payload.error;
        break;

      case ActionTypes.EDIT_HOTEL:
        draft.submitting = true;
        break;

      case ActionTypes.EDIT_HOTEL_SUCCESS:
        const index = draft.list.findIndex(
          ({ id }) => id === action.payload.hotel.id,
        );
        draft.list[index] = action.payload.hotel;
        draft.editHotel = action.payload.hotel;
        draft.submitting = false;
        break;

      case ActionTypes.EDIT_HOTEL_FAIL:
        draft.submitting = false;
        draft.error = action.payload.error;
        break;

      case ActionTypes.ADD_CMS_SUCCESS:
        draft.CMS = action.payload.CMS;
        break;

      case ActionTypes.UPLOAD_IMAGE:
        break;

      case ActionTypes.UPLOAD_IMAGE_SUCCESS:
        draft.logo = action.payload.image;
        break;
    }
  });

export default hotelsReducer;
