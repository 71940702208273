import ActionTypes from './constants';
import { action } from 'typesafe-actions';
import { InjectPromise } from 'types/actions';
import { HotelCurrency } from 'types/schema';

export const fetchCurrencies = (hotelId: number) => {
  return action(ActionTypes.FETCH_CURRENCIES, hotelId);
};

export const fetchCurrenciesSuccess = (items: HotelCurrency) => {
  return action(ActionTypes.FETCH_CURRENCIES_SUCCESS, items);
};

export const fetchCurrenciesFail = (error: Error) => {
  return action(ActionTypes.FETCH_CURRENCIES_FAIL, error);
};

export const resetCurrenciestate = () => {
  return action(ActionTypes.RESET_CURRENCIES_STATE);
};

export const deleteCurrency = (id: number) => {
  return action(ActionTypes.DELETE_CURRENCY, id);
};

export const deleteCurrencySuccess = (id: number) => {
  return action(ActionTypes.DELETE_CURRENCY_SUCCESS, id);
};

export const deleteCurrencyFail = (error: Error) => {
  return action(ActionTypes.DELETE_CURRENCY_FAIL, error);
};

export const createCurrency = (
  params: InjectPromise<
    any,
    {
      hotelId: number;
      currency: {
        name: string;
        rate: Number;
      };
    }
  >,
) => {
  return action(ActionTypes.CREATE_CURRENCY, params);
};

export const createCurrencySuccess = (currency: HotelCurrency) => {
  return action(ActionTypes.CREATE_CURRENCY_SUCCESS, currency);
};

export const createCurrencyFail = (error: Error) => {
  return action(ActionTypes.CREATE_CURRENCY_FAIL, error);
};

export const editCurrency = (
  params: InjectPromise<
    any,
    {
      currencyId: number;
      currency: { name: string; rate: number };
    }
  >,
) => {
  return action(ActionTypes.EDIT_CURRENCY, params);
};

export const editCurrencySuccess = (currency: HotelCurrency) => {
  return action(ActionTypes.EDIT_CURRENCY_SUCCESS, currency);
};

export const editCurrencyFail = (error: Error) => {
  return action(ActionTypes.EDIT_CURRENCY_FAIL, error);
};
