import React, { useRef } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Comment as AntdComment, Input } from 'antd';
import { useIntl } from 'react-intl';
import tw from 'twin.macro';
import { Button, PopupConfirm } from '@aha/ui';
import { IconEdit, IconTrashAlt } from '@aha/icons';

const StyledComment = styled(AntdComment)`
  .ant-comment-content-author-name {
    ${tw`w-full mb-1`};
  }

  .ant-comment-inner {
    ${tw`px-0 pt-0 pb-2`};
  }

  .ant-comment-content-author > span {
    ${tw`pr-0`};
  }
`;

const Content = styled.div`
  ${tw`flex items-center leading-loose`};
  min-height: 2.25rem;
`;

export type CommentProps = {
  title?: React.ReactNode;
  isEdit?: boolean;
  className?: string;
  note?: string;
  time?: string;
  content?: React.ReactNode;
  disabled?: boolean;

  onEdit: () => void;
  onSave: () => void;
  onRemove?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCancel: () => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Comment: React.FC<CommentProps> = ({
  title,
  isEdit,
  note,
  time,
  className,
  content,
  disabled,
  onEdit,
  onSave,
  onRemove,
  onCancel,
  onInputChange,
}) => {
  const intl = useIntl();
  const input = useRef<Input>(null);
  return (
    <StyledComment
      className="px-6 -ml-3"
      author={
        <div className={classnames(['flex justify-between', className])}>
          <div className="text-grey-darker text-2xs font-medium flex items-center">
            <span className="uppercase mr-2">{title}</span>
            <span className="text-3xs">{time}</span>
          </div>
          {isEdit ? (
            <div className="flex items-center">
              <button
                className="text-primary font-medium flex items-center outline-none"
                onClick={onSave}
              >
                <FormattedMessage
                  defaultMessage="Save"
                  id="common.action.save"
                />
              </button>
              <button
                className="text-secondary font-medium flex items-center ml-3 outline-none"
                onClick={onCancel}
              >
                <FormattedMessage
                  defaultMessage="Cancel"
                  id="common.action.cancel"
                />
              </button>
            </div>
          ) : (
            <div>
              <Button
                icon={IconEdit}
                type="icon"
                size="small"
                onClick={() => {
                  onEdit();
                  setTimeout(() => input.current?.focus());
                }}
              />
              {onRemove && (
                <PopupConfirm
                  title={
                    <FormattedMessage
                      defaultMessage="Are you sure to remove this note?"
                      id="common.action.sureRemoveNote"
                    />
                  }
                  placement="topRight"
                  arrowPointAtCenter
                  onConfirm={onRemove}
                  okText={
                    <FormattedMessage
                      defaultMessage="Remove"
                      id="common.action.remove"
                    />
                  }
                  cancelText={
                    <FormattedMessage
                      defaultMessage="Keep"
                      id="common.action.keep"
                    />
                  }
                >
                  <Button
                    icon={IconTrashAlt}
                    className="ml-1"
                    size="small"
                    type="icon"
                    disabled={disabled}
                  />
                </PopupConfirm>
              )}
            </div>
          )}
        </div>
      }
      content={
        <div className="text-sm text-secondary">
          {isEdit ? (
            <Input
              ref={input}
              disabled={disabled}
              placeholder={intl.formatMessage({
                id: 'common.label.enterNote',
                defaultMessage: 'Enter note',
              })}
              value={note}
              onChange={onInputChange}
            />
          ) : (
            content && <Content>{content}</Content>
          )}
        </div>
      }
    />
  );
};

export default React.memo(
  Comment,
  (prev, next) =>
    prev.title === next.title &&
    prev.time === next.time &&
    prev.isEdit === next.isEdit &&
    prev.disabled === next.disabled &&
    prev.note === next.note &&
    prev.content === next.content,
);
