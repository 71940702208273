import { all, call, put, takeLatest } from 'redux-saga/effects';
import { showErrorNotification } from '@aha/utils';
import { coreAPI } from 'utils/request';
import { fetchChargeTemplatesSuccess, fetchCountriesSuccess } from './actions';
import ActionTypes from 'containers/CreateBooking/constants';

export function* doFetchChargeTemplates() {
  try {
    const { data } = yield call(coreAPI.get, `v1/pms/charge-templates`);
    yield put(fetchChargeTemplatesSuccess(data));
  } catch (err) {
    showErrorNotification('Fail to fetch', err);
  }
}

export function* doFetchCountries() {
  try {
    const { data } = yield call(coreAPI.get, `v1/pms/countries`);
    yield put(fetchCountriesSuccess(data));
  } catch (err) {
    showErrorNotification('Fail to fetch', err);
  }
}

export function* watchFetchCountries() {
  yield takeLatest(ActionTypes.FETCH_COUNTRIES, doFetchCountries);
}

export function* watchFetchChargeTemplates() {
  yield takeLatest(
    ActionTypes.FETCH_CHARGE_TEMPLATE_LIST,
    doFetchChargeTemplates,
  );
}

export default function* watchAll() {
  yield all([watchFetchChargeTemplates(), watchFetchCountries()]);
}
