import React, { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Row, Col, DatePicker, Form, Divider, AutoComplete } from 'antd';
import { FormattedMessage } from 'react-intl';

import { useInjectReducer } from 'utils/injectReducer';
import bookingsReducer from 'containers/BookingProvider/reducer';
import saga from 'containers/BookingProvider/saga';
import { BOOKING_KEY } from 'containers/BookingProvider/constants';
import { useInjectSaga } from 'utils/injectSaga';
import { createStructuredSelector } from 'reselect';
import {
  fetchRevGroups,
  updateBookingPeriod,
  addFolioCharge,
} from 'containers/BookingProvider/actions';
import {
  makeSelectCurrentHotel,
  makeSelectCurrencies,
} from 'containers/Dashboard/selectors';
import { makeSelectRevGroups } from 'containers/BookingProvider/selector';
import InputNumberFormat from 'components/InputNumberFormat';
import { Modal, NAFallback, Select } from '@aha/ui';
import currencyFormater from '@aha/utils/src/currencyFormatter';
import { makeSelectRatePlans } from 'containers/RatePlansPage/selector';
import { fetchRatePlans } from 'containers/RatePlansPage/actions';
import { CHARGE_TYPE_KEYS } from 'utils/constants';
import { IconCalendarAlt } from '@aha/icons';

function ratePlanOptions(rates = []) {
  return rates.map((r) => ({
    text: `${r.name} - ${currencyFormater(r.price, r.currency?.name || 'VND')}`,
    value: r.id,
    rules: r.rules,
    isRatePlan: true,
  }));
}

function filterRates(rates = [], rateFilter) {
  const sortRates = rates.sort(
    (a, b) => Number(a?.roomType?.name) - Number(b?.roomType?.name),
  );

  return (sortRates || []).filter((r) =>
    rateFilter ? r.roomType?.id === rateFilter : false,
  );
}

export const InnerModal = ({
  onExtendBookings,
  onSelectRoomRates,
  getFieldDecorator,
  arrival,
  setArrivalDate,
  isArrExtendable,
  newDepartureDate,
  departure,
  isDepExtendable,
  setDepartureDate,
  newArrivalDate,
  hotel,
  diffArr,
  diffDep,
  rooms,
  currencies,
  booking,
  rates,
  setPrice,
  rateIds,
  folioIDs,
  prices,
  setRateIds,
  validFolios,
  bkType,
  setFieldsValue,
}) => {
  return (
    <Form layout="vertical" onSubmit={onExtendBookings}>
      <section>
        <Row gutter={24}>
          <Col xs={24} md={8}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Arrival"
                  id="common.label.arrivalDate"
                />
              }
            >
              {getFieldDecorator('arrival', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
                initialValue: moment(arrival),
              })(
                <DatePicker
                  allowClear={false}
                  suffixIcon={
                    <IconCalendarAlt className="text-xs text-black" />
                  }
                  className="w-full"
                  onChange={(date) => {
                    setArrivalDate(date);
                    if (bkType === 'nightly') {
                      setDepartureDate(date.clone().add(1, 'd'));
                      setFieldsValue({ departure: date.clone().add(1, 'd') });
                    }
                  }}
                  disabled={!isArrExtendable}
                  disabledDate={(d) => {
                    if (moment(d).isBefore(moment(), 'day')) {
                      return true;
                    }

                    let dep = moment(newDepartureDate || departure);
                    return moment(d).isAfter(dep, 'day');
                  }}
                />,
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Departure"
                  id="common.label.departureDate"
                />
              }
            >
              {getFieldDecorator('departure', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
                initialValue: moment(departure),
              })(
                <DatePicker
                  allowClear={false}
                  suffixIcon={
                    <IconCalendarAlt className="text-xs text-black" />
                  }
                  className="w-full"
                  disabled={!isDepExtendable}
                  onChange={(date) => {
                    setDepartureDate(date);
                    if (bkType === 'nightly') {
                      setArrivalDate(date.clone().add(-1, 'd'));
                      setFieldsValue({ arrival: date.clone().add(-1, 'd') });
                    }
                  }}
                  disabledDate={(d) => {
                    //allow to update depature date after arrival date,
                    let arr = moment(newArrivalDate || arrival).startOf('day');
                    const cdate = moment(d).startOf('day');
                    return (
                      cdate.diff(arr, 'days') < 0 ||
                      moment(d).isBefore(moment(), 'day')
                    );
                  }}
                />,
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Tax"
                  id="common.label.taxPercent"
                />
              }
            >
              {getFieldDecorator('taxPercent', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
                initialValue: hotel.taxPercent,
              })(<InputNumberFormat min={0} />)}
            </Form.Item>
          </Col>
        </Row>
      </section>
      {(diffArr > 0 || diffDep > 0) && (
        <div className="mb-4">
          <span className="text-red">* </span>
          <FormattedMessage
            defaultMessage="Please check the charges to match the actual dates of guests."
            id="booking.message.extendPeriodDateWarning"
          />
        </div>
      )}

      {(diffArr > 0 || diffDep > 0) &&
        (rooms || []).map((r, idx) => {
          const rateSources = ratePlanOptions(filterRates(rates, r.roomTypeId));
          const initialRoomRate = rateIds[r.id]
            ? rateSources
                .find((rate) => rate.value === rateIds[r.id])
                ?.value.toString()
            : prices[r.id];

          return (
            <>
              <section>
                <header className="flex text-2xs font-semibold uppercase text-secondary mt-2 mb-3">
                  <div className="px-1">
                    <FormattedMessage
                      defaultMessage="Room type"
                      id="common.label.roomType"
                    />
                    :<span className="ml-1 text-purple">{r.roomTypeName}</span>
                  </div>
                  <div className="px-1 font-normal"> | </div>
                  <div className="px-1">
                    <FormattedMessage
                      defaultMessage="Room"
                      id="common.label.room"
                    />
                    :
                    <NAFallback
                      value={r.roomName}
                      as="span"
                      className="ml-1 text-purple"
                    />
                  </div>
                </header>
                <Row gutter={24}>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label={
                        <FormattedMessage
                          defaultMessage="Room Rate"
                          id="common.label.roomRate"
                        />
                      }
                    >
                      {getFieldDecorator(`price#${r.id}`, {
                        initialValue: initialRoomRate
                          ? initialRoomRate
                          : undefined,
                        rules: [
                          {
                            validator: (rule, value, cb) =>
                              value &&
                              isNaN(value) &&
                              rateSources?.findIndex(
                                (i) => i.value === value,
                              ) === -1
                                ? cb(
                                    <FormattedMessage
                                      id="common.message.invalid"
                                      defaultMessage="Invalid"
                                    />,
                                  )
                                : cb(),
                          },
                        ],
                      })(
                        <AutoComplete
                          placeholder={
                            <FormattedMessage
                              defaultMessage="Enter price"
                              id="common.label.enter_price"
                            />
                          }
                          dataSource={rateSources}
                          onSelect={(v) => onSelectRoomRates(v, r.id)}
                          onSearch={(v) => {
                            setRateIds((p) => ({ ...p, [r.id]: undefined }));
                            setPrice((p) => ({ ...p, [r.id]: v }));
                          }}
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label={
                        <FormattedMessage
                          defaultMessage="Currency"
                          id="common.label.currency"
                        />
                      }
                    >
                      {getFieldDecorator(`currency#${r.id}`, {
                        rules: [
                          {
                            required: true,
                            message: (
                              <FormattedMessage
                                id="common.message.required"
                                defaultMessage="Required"
                              />
                            ),
                          },
                        ],
                        initialValue: hotel.currency,
                      })(
                        <Select
                          placeholder="Select currency"
                          disabled={
                            !!rateIds[r.id] &&
                            rateSources.find(
                              (rate) => (rate.value = +rateIds[r.id]),
                            )
                          }
                        >
                          {(currencies || []).map((c) => (
                            <Select.Option key={c.name} value={c.name}>
                              {c.name}
                            </Select.Option>
                          ))}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label={
                        <FormattedMessage
                          defaultMessage="Folio"
                          id="common.label.folio"
                        />
                      }
                    >
                      {getFieldDecorator(`folioID#${r.id}`, {
                        initialValue:
                          folioIDs[r.id]?.fid || folioIDs[rooms[0]?.id]?.fid,
                        rules: [
                          {
                            required: true,
                            message: (
                              <FormattedMessage
                                id="common.message.required"
                                defaultMessage="Required"
                              />
                            ),
                          },
                        ],
                      })(
                        <Select
                          showSearch
                          placeholder="Select folio"
                          filterOption={(input, option) =>
                            (option?.props?.title || '')
                              .toString()
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {validFolios.map((f) => (
                            <Select.Option
                              key={f.id}
                              value={f.id}
                              title={[
                                f.isMaster ? 'master' : f.roomType,
                                f.roomTitle,
                                f.refCode,
                              ]
                                .filter(Boolean)
                                .join(' - ')}
                            >
                              <div className="inline-flex">
                                <div style={{ minWidth: 80 }}>
                                  {f.isMaster ? (
                                    <span className="text-red">
                                      <FormattedMessage
                                        defaultMessage="Master"
                                        id="common.label.master"
                                      />
                                    </span>
                                  ) : (
                                    f.roomType
                                  )}
                                </div>
                                <span className="mx-1">-</span>
                                <>
                                  <NAFallback value={f.roomTitle} />
                                  {' - '} {f.refCode}
                                </>
                              </div>
                            </Select.Option>
                          ))}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </section>
              {idx < rooms?.length - 1 && <Divider className="mt-0 mb-4" />}
            </>
          );
        })}
    </Form>
  );
};

function ExtendBookingPeriod(props) {
  useInjectReducer({ key: BOOKING_KEY, reducer: bookingsReducer });
  useInjectSaga({ key: BOOKING_KEY, saga });

  const {
    form,
    booking,
    hotel,
    currencies,
    revGroups,
    rates,
    doFetchRevGroups,
    doUpdateBookingPeriod,
    doAddCharge,
    doFetchRates,
    onCancel,
    bkType,
    ...rest
  } = props;
  const { validateFields, getFieldDecorator, setFieldsValue } = form;

  const { status, arrival, departure, rooms, folios } = booking;

  const [newArrivalDate, setArrivalDate] = useState(null);
  const [newDepartureDate, setDepartureDate] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const diffArr =
    bkType === 'hourly'
      ? moment(arrival).diff(newArrivalDate || arrival, 'h')
      : moment(arrival).diff(newArrivalDate || arrival, 'days');
  const diffDep =
    bkType === 'hourly'
      ? moment(newDepartureDate || departure).diff(departure, 'h')
      : moment(newDepartureDate || departure).diff(departure, 'days');

  const isArrExtendable = status === 'awaiting';
  const isDepExtendable = ['awaiting', 'checked_in'].includes(status);

  const [rateIds, setRateIds] = useState({});
  const [prices, setPrice] = useState({});
  const validRooms = rooms?.filter((room) => room.status !== 'cancelled') || [];
  const validFolios = folios
    ?.filter(
      (f) =>
        f.status === 'active' &&
        !rooms?.some(
          (r) =>
            f.bookingRoomId &&
            r.id === f.bookingRoomId &&
            r.status === 'checked_out',
        ),
    )
    ?.map((f) => {
      const room = validRooms.find((r) => r.id === f.bookingRoomId) || {};
      return { ...f, roomTitle: room.roomTitle, roomType: room.roomTypeName };
    });

  const folioIDs = useMemo(() => {
    const isActiveRoomCharge = (c) =>
      c.chargeType === CHARGE_TYPE_KEYS.ROOM_CHARGE && c.status === 'active';

    function exactCharges(folio) {
      // sort old to new days
      const chargeSorter = folio.charges.sort((f1, f2) =>
        moment(f1.serviceDate).diff(f2.serviceDate),
      );

      return chargeSorter.reduce((res, c) => {
        if (isActiveRoomCharge(c)) {
          return {
            ...res,
            [c.bookingRoomId]: {
              fid: validFolios.some((f) => f.id === folio.id)
                ? folio.id
                : undefined,
              amount: c?.amount,
              rateId: c?.ratePlanId,
              group: true,
            },
          };
        }

        return res;
      }, {});
    }

    const roomAmountIDs = folios.reduce((res, f) => {
      const isGroupFolio = f?.charges?.some(
        (c) =>
          c.bookingRoomId &&
          c.bookingRoomId !== f.bookingRoomId &&
          c.status !== 'voided',
      );

      if (isGroupFolio || f.isMaster) {
        return { ...res, ...exactCharges(f) };
      }

      // one folio can be lots of room charges then we just need the one which is newest.
      // sort new to old days
      const roomCharges = f?.charges
        ?.filter((c) => isActiveRoomCharge(c))
        ?.sort((f1, f2) => moment(f2.serviceDate).diff(f1.serviceDate));

      const { amount, stayRuleId, bookingRoomId } = roomCharges?.[0] || {};
      const bkRoomId = bookingRoomId || f.bookingRoomId;

      if (bkRoomId && !res[bkRoomId]?.serviceGroup) {
        return {
          ...res,
          [bkRoomId]: {
            fid: validFolios.some((fo) => fo.id === f.id) ? f.id : undefined,
            amount,
            rateId: stayRuleId,
          },
        };
      }

      return res;
    }, {});

    return roomAmountIDs;
  }, [folios]); // eslint-disable-line

  useEffect(() => {
    if (diffArr > 0 || diffDep > 0) {
      validRooms.forEach((r) => {
        const rateSources = ratePlanOptions(filterRates(rates, r.roomTypeId));
        const { amount, rateId } = folioIDs[r.id] || {};

        const roomRate = rateSources.find(
          (rate) => rate.value === Number(rateId),
        );
        const initialRoomRate = roomRate?.text || amount;

        if (typeof initialRoomRate === 'string') {
          setRateIds((rts) => ({ ...rts, [r.id]: roomRate.value }));
        } else {
          setPrice((p) => ({ ...p, [r.id]: initialRoomRate }));
        }
      });
    }
  }, [rooms, diffArr, diffDep, rates, folioIDs]); // eslint-disable-line

  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    const arr = moment(newArrivalDate || arrival);
    const dep = moment(newDepartureDate || departure);

    doFetchRevGroups(hotel.id);
    doFetchRates(hotel.id, arr, dep);
  }, [hotel.id]); // eslint-disable-line

  function onExtendBookings(e) {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (err) {
        console.error(err);
      } else {
        try {
          setIsUpdating(true);
          const arr = moment(newArrivalDate || arrival);
          const dep = moment(newDepartureDate || departure);

          const charges = (validRooms || []).reduce((fin, r, index) => {
            // const price = values[`price#${r.id}`];
            const folioId =
              values[`folioID#${r.id}`] || booking.folios[index].id;
            const currency =
              values[`currency#${r.id}`] ||
              booking.folios[index].totalCharge.currency;
            const rateId = rateIds[r.id];
            const price = prices[r.id];

            return [
              ...fin,
              {
                folioId,
                amount: +price,
                stayRuleId: +rateId,
                quantity: 1,
                currency,
                taxPercent: +values.taxPercent,
                bookingRoomId: r.id,
              },
            ];
          }, []);

          await doUpdateBookingPeriod(booking.id, {
            arrival: arr,
            departure: dep,
            charges,
          });
          onCancel(true);
        } catch (err) {
          console.error(err);
        } finally {
          setIsUpdating(false);
        }
      }
    });
  }

  function onSelectRoomRates(rateId, roomID) {
    const curRate = rates?.find((r) => r.id === +rateId);

    if (curRate) {
      setRateIds((rts) => ({ ...rts, [roomID]: rateId }));
    }

    setFieldsValue({
      [`currency#${roomID}`]:
        curRate?.currency?.name || hotel?.currency || 'VND',
    });
  }

  function cancel() {
    onCancel(false);
    setArrivalDate(booking.arrival);
    setDepartureDate(booking.departure);
    setRateIds({});
  }
  return (
    <Modal
      {...rest}
      destroyOnClose
      submitting={isUpdating || (diffArr === 0 && diffDep === 0)}
      onSubmit={onExtendBookings}
      onCancel={cancel}
      width={932}
      title={
        <FormattedMessage
          defaultMessage="Edit booking"
          id="bookings.actions.editBooking"
        />
      }
      submitText={
        <FormattedMessage defaultMessage="Edit" id="common.action.edit" />
      }
      cancelText={
        <FormattedMessage defaultMessage="Edit" id="common.action.cancel" />
      }
    >
      <InnerModal
        bkType={bkType}
        setFieldsValue={setFieldsValue}
        onExtendBookings={onExtendBookings}
        getFieldDecorator={getFieldDecorator}
        arrival={arrival}
        setArrivalDate={setArrivalDate}
        isArrExtendable={isArrExtendable}
        newDepartureDate={newDepartureDate}
        departure={departure}
        isDepExtendable={isDepExtendable}
        setDepartureDate={setDepartureDate}
        newArrivalDate={newArrivalDate}
        hotel={hotel}
        diffArr={diffArr}
        diffDep={diffDep}
        rooms={validRooms}
        onSelectRoomRates={onSelectRoomRates}
        currencies={currencies}
        booking={booking}
        rates={rates}
        setPrice={setPrice}
        setRateIds={setRateIds}
        prices={prices}
        rateIds={rateIds}
        folioIDs={folioIDs}
        validFolios={validFolios}
      />
    </Modal>
  );
}

const mapState = createStructuredSelector({
  hotel: makeSelectCurrentHotel(),
  currencies: makeSelectCurrencies(),
  revGroups: makeSelectRevGroups(),
  rates: makeSelectRatePlans(),
});

function mapDispatch(dispatch) {
  return {
    doFetchRevGroups: (hid) => dispatch(fetchRevGroups(hid)),
    doUpdateBookingPeriod: (bid, data) =>
      new Promise((resolve, reject) =>
        dispatch(updateBookingPeriod(bid, data, resolve, reject)),
      ),
    doAddCharge: (fid, data) =>
      new Promise((resolve, reject) =>
        dispatch(addFolioCharge(fid, data, 'add', resolve, reject)),
      ),
    doFetchRates: (hid, start, end) =>
      dispatch(fetchRatePlans(hid, start, end)),
  };
}

export default connect(
  mapState,
  mapDispatch,
)(Form.create({ name: 'extend_booking_period' })(ExtendBookingPeriod));
