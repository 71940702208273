import { call, put, takeLatest } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { fetchPushHistorySuccess, fetchPushHistoryFail } from '../actions';
import ActionTypes from '../constants';

function* doFetchHistory() {
  try {
    const { data } = yield call(
      coreAPI.get,
      `v1/pms/operation/logs/rate-plans/push-history`,
    );
    yield put(fetchPushHistorySuccess(data));
  } catch (err) {
    yield put(fetchPushHistoryFail(err));
  }
}

export default function* watchFetchPushHistory() {
  yield takeLatest(ActionTypes.FETCH_PUSH_HISTORY, doFetchHistory);
}
