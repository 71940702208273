import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import {
  makeSelectCurrentHotel,
  makeSelectCurrencies,
} from 'containers/Dashboard/selectors';
import { makeSelectChargeTemplates } from 'containers/BookingProvider/selector';
import { fetchChargeTemplates } from 'containers/BookingProvider/actions';
import { useInjectReducer } from 'utils/injectReducer';
import { BOOKING_KEY } from 'containers/BookingProvider/constants';
import bookingsReducer from 'containers/BookingProvider/reducer';
import bookingsSaga from 'containers/BookingProvider/saga';
import { useInjectSaga } from 'utils/injectSaga';
import { currencyFormater } from 'utils/currencyFormater';
import { addHourlyBookingCharges } from '../../actions';
import { Button, Modal, Select, InputQuantity } from '@aha/ui';
import { IconRemoveCircle, IconPlusCircle } from '@aha/icons';

export const InnerModal = ({
  removeAdditionalChargeItem,
  additionalCharges,
  setAdditionalCharges,
  chargeTemplates,
}) => (
  <div className="flex flex-col">
    {additionalCharges.map((c, idx) => (
      <div className="inline-flex items-center mb-3">
        <Select
          showSearch
          className="w-64 mr-2"
          placeholder={
            <FormattedMessage
              defaultMessage="Select charge"
              id="bookings.label.select_charge"
            />
          }
          onChange={(k, v) =>
            setAdditionalCharges(
              additionalCharges.map((ch, cidx) =>
                cidx === idx ? { ...ch, id: k } : ch,
              ),
            )
          }
          optionFilterProp="children"
        >
          {chargeTemplates.map((ct) => (
            <Select.Option value={ct.id} key={ct.id}>{`${
              ct.name
            } - ${currencyFormater(
              ct.amount,
              ct.currency?.name,
            )}`}</Select.Option>
          ))}
        </Select>
        <div className="w-40 mr-4">
          <InputQuantity
            value={c.quantity}
            onClickSuffix={() =>
              setAdditionalCharges(
                additionalCharges.map((ch, cidx) =>
                  cidx === idx ? { ...ch, quantity: ch.quantity + 1 } : ch,
                ),
              )
            }
            onClickPrefix={() =>
              setAdditionalCharges(
                additionalCharges.map((ch, cidx) =>
                  cidx === idx
                    ? {
                        ...ch,
                        quantity:
                          ch.quantity > 1 ? ch.quantity - 1 : ch.quantity,
                      }
                    : ch,
                ),
              )
            }
            onChange={(k) =>
              setAdditionalCharges(
                additionalCharges.map((ch, cidx) =>
                  cidx === idx ? { ...ch, quantity: k > 0 ? k : 1 } : ch,
                ),
              )
            }
          />
        </div>
        <Button
          type="icon"
          size="small"
          icon={IconRemoveCircle}
          className="text-lg"
          onClick={() => removeAdditionalChargeItem(idx)}
        />
      </div>
    ))}
    <div className="inline-flex items-center mb-3">
      <Select
        className="w-64 mr-2  opacity-50"
        placeholder={
          <FormattedMessage
            defaultMessage="Select charge"
            id="bookings.label.select_charge"
          />
        }
        disabled
      />
      <div className="w-40 mr-4 opacity-50">
        <InputQuantity disabled />
      </div>
      <Button
        type="icon"
        size="small"
        icon={IconPlusCircle}
        className="text-lg text-primary"
        onClick={(e) => {
          e.stopPropagation();
          setAdditionalCharges([
            ...additionalCharges,
            { id: null, quantity: 1 },
          ]);
        }}
      />
    </div>
  </div>
);
const mapStateToProps = createStructuredSelector({
  hotel: makeSelectCurrentHotel(),
  currencies: makeSelectCurrencies(),
  chargeTemplates: makeSelectChargeTemplates(),
});

function mapDispatchToProps(dispatch) {
  return {
    doFetchChargeTemplates: (hid) => dispatch(fetchChargeTemplates(hid)),
    doAddCharges: (bid, reqData) =>
      new Promise((resolve, reject) =>
        dispatch(addHourlyBookingCharges(bid, reqData, resolve, reject)),
      ),
  };
}
export function AddChargeModal(props) {
  useInjectReducer({ key: BOOKING_KEY, reducer: bookingsReducer });
  useInjectSaga({ key: BOOKING_KEY, saga: bookingsSaga });
  const dispatch = useDispatch();
  const { hotel, chargeTemplates } = useSelector(mapStateToProps);
  const { doFetchChargeTemplates, doAddCharges } = mapDispatchToProps(dispatch);

  const { bid, visible, onOk, onCancel, ...rest } = props;

  const [additionalCharges, setAdditionalCharges] = useState([
    { id: null, quantity: 1 },
  ]);
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    if (visible) {
      doFetchChargeTemplates(hotel.id);
    }
  }, [hotel.id, visible]); // eslint-disable-line

  function removeAdditionalChargeItem(idx) {
    setAdditionalCharges(additionalCharges.filter((v, adx) => adx !== idx));
  }

  async function onAddCharge() {
    try {
      setIsCreating(true);
      const reducedForm = additionalCharges
        .filter((c) => c.id)
        .reduce(
          (fin, c) => ({
            ...fin,
            [c.id]: (fin[c.id] || 0) + (+c.quantity || 0),
          }),
          {},
        );
      const reqForm = _.map(reducedForm, (q, id) => ({
        warehousingId: id,
        quantity: q,
      }));
      await doAddCharges(bid, reqForm);
      onCancel(true);
      setAdditionalCharges([{ id: null, quantity: 1 }]);
    } catch (err) {
      console.error(err);
    } finally {
      setIsCreating(false);
    }
  }

  return (
    <Modal
      {...rest}
      visible={visible}
      title={
        <FormattedMessage
          defaultMessage="Add charge"
          id="bookings.actions.addCharge"
        />
      }
      onCancel={() => {
        onCancel();
        setAdditionalCharges([{ id: null, quantity: 1 }]);
      }}
      onSubmit={onAddCharge}
      submitting={isCreating}
      submitText={
        <FormattedMessage defaultMessage="Add" id="common.action.add" />
      }
    >
      <InnerModal
        removeAdditionalChargeItem={removeAdditionalChargeItem}
        additionalCharges={additionalCharges}
        setAdditionalCharges={setAdditionalCharges}
        chargeTemplates={chargeTemplates}
      />
    </Modal>
  );
}

export default AddChargeModal;
