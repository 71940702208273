import { useState } from 'react';
import moment from 'moment';

export const TODAY_ARRIVAL = 'today_arrival';
export const TODAY_DEPARTURE = 'today_departure';
export const ARRIVAL_LIST = 'arrival_list';
export const DEPARTURE_LIST = 'departure_list';
export const CHECKED_IN = 'checked_in';
export const CHECKED_OUT = 'checked_out';
export const EXPIRED_ARRIVAL = 'expired_arrival';
export const EXPIRED_DEPARTURE = 'expired_departure';
export const UPCOMING_ARRIVAL = 'upcoming_arrival';
export const UPCOMING_DEPARTURE = 'upcoming_departure';

export const defaultExpiredDates = [
  moment().subtract(30, 'days').toISOString(),
  moment().subtract(1, 'days').toISOString(),
];

export const initialTabData = {
  bookings: [],
  pagination: undefined,
  canMultiCheckIn: false,
  canMultiCheckOut: false,
};

export default function useTab(currentTab) {
  const [todayExpectedTab, setTodayExpectedTab] = useState(initialTabData);
  const [expiredTab, setExpiredTab] = useState(initialTabData);
  const [upcommingTab, setUpcommingTab] = useState(initialTabData);
  const [checkInCheckOutTab, setCheckInCheckOutTab] = useState(initialTabData);

  let selectedTab, setSelectedTab;
  switch (currentTab) {
    case TODAY_ARRIVAL:
    case TODAY_DEPARTURE:
      selectedTab = todayExpectedTab;
      setSelectedTab = setTodayExpectedTab;
      break;

    case EXPIRED_ARRIVAL:
    case EXPIRED_DEPARTURE:
      selectedTab = expiredTab;
      setSelectedTab = setExpiredTab;
      break;

    case UPCOMING_ARRIVAL:
    case UPCOMING_DEPARTURE:
      selectedTab = upcommingTab;
      setSelectedTab = setUpcommingTab;
      break;

    case CHECKED_IN:
    case CHECKED_OUT:
      selectedTab = checkInCheckOutTab;
      setSelectedTab = setCheckInCheckOutTab;
      break;

    default:
      selectedTab = initialTabData;
      setSelectedTab = setCheckInCheckOutTab;
      break;
  }

  return { selectedTab, setSelectedTab };
}
