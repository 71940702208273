import { put, takeLatest, call } from 'redux-saga/effects';
import { fetchCitiesSuccess } from '../actions';
import ActionTypes from '../constants';
import { coreAPI } from 'utils/request';
import { showErrorNotification } from '@aha/utils';
import { DashboardActions } from '../type';
import { ExtractAction } from 'types/actions';

export function* doFetchCities(
  action: ExtractAction<DashboardActions, ActionTypes.FETCH_CITIES>,
) {
  try {
    const { data } = yield call(coreAPI.get, '/v1/pms/shared/cities');

    yield put(fetchCitiesSuccess(data));
  } catch (err) {
    showErrorNotification('Fail to fetch', err);
  }
}

export default function* watchFetchCities() {
  yield takeLatest(ActionTypes.FETCH_CITIES, doFetchCities);
}
