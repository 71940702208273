import { takeEvery, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { VOID_MULTIPLE_FOLIO_CHARGES } from '../constants';
import { showErrorNotification } from '@aha/utils';

function* doVoidMultipleCharges({ bid, fid, body, reject, resolve }) {
  try {
    const { data } = yield call(
      coreAPI.put,
      `v1/pms/bookings/${bid}/folios/${fid}/void-charges`,
      body,
    );
    resolve(data);
  } catch (err) {
    showErrorNotification('Void multiple booking charges failed', err);
    reject(err);
  }
}

export function* watchVoidMultipleBookingCharges() {
  yield takeEvery(VOID_MULTIPLE_FOLIO_CHARGES, doVoidMultipleCharges);
}
