import React, { memo } from 'react';
import { Skeleton } from '@uxui/skeleton-loader';

export interface SkeletonTableColumn {
  width: string;
  loaderWidth: string;
}

export interface SkeletonTableProps {
  columms?: SkeletonTableColumn[];
  numOfRow?: number;
}

const SkeletonTable = memo(
  ({
    columms = [
      { loaderWidth: '100px', width: '20%' },
      { loaderWidth: '100px', width: '40%' },
      { loaderWidth: '100px', width: '40%' },
    ],
    numOfRow = 10,
  }: SkeletonTableProps) => {
    return (
      <div style={{ fontSize: 0 }}>
        <div className="px-2">
          <div className="-mx-2 flex">
            {columms.map(({ loaderWidth, width }, index) => (
              <div className="px-2 py-4" style={{ width }} key={index}>
                <Skeleton
                  width={loaderWidth}
                  customStyle={{ maxWidth: '100%' }}
                  height="15px"
                />
              </div>
            ))}
          </div>
        </div>
        {Array.from({ length: numOfRow }, (k, i) => i).map((i) => (
          <div
            className="border-t border-grey-lighter px-2"
            style={{ opacity: 1 - 0.05 * i }}
            key={i}
          >
            <div className="-mx-2 flex">
              {columms.map(({ loaderWidth, width }, index) => (
                <div className="px-2 py-4" style={{ width }} key={index}>
                  <Skeleton
                    width={loaderWidth}
                    customStyle={{ maxWidth: '100%' }}
                    height="15px"
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  },
  () => true,
);

export default SkeletonTable;
