import axios from 'axios';
import NProgress from 'nprogress';
import { store } from 'App';
import ActionTypes from 'containers/Auth/constants';
import { showErrorNotification } from '@aha/utils';

const coreAPI = axios.create({
  baseURL: process.env.REACT_APP_CORE_API || 'https://dev.api.aharooms.net/api',
  timeout: 120000, // 2',
});

export const lockDoorAPI = axios.create({
  baseURL: 'http://localhost:51316', // fixed url
  timeout: 60000, // 1'
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

coreAPI.interceptors.request.use(
  function (config) {
    if (config.method !== 'get') {
      NProgress.start();
    }
    return config;
  },
  function (error) {
    NProgress.done();
    return Promise.reject(error);
  },
);

coreAPI.interceptors.response.use(
  function (response) {
    NProgress.done();
    return response.data;
  },
  function (error) {
    NProgress.done();
    if (error?.response?.status === 401) {
      showErrorNotification('No Permission', error);
      store.dispatch({ type: ActionTypes.LOG_OUT });
    }
    return Promise.reject(error);
  },
);

const token = window.localStorage.getItem('token');
const hid = window.localStorage.getItem('hid');

function setToken(token: string) {
  window.localStorage.setItem('token', token);
  coreAPI.defaults.headers.common.Authorization = `Bearer ${token}`;
}

function removeToken() {
  window.localStorage.removeItem('token');
}

function setSelectedHotel(hotelId: number) {
  window.localStorage.setItem('hid', hotelId.toString());
}

function removeSelectedHotel() {
  window.localStorage.removeItem('hid');
}

if (token) {
  setToken(token);
}

if (hid) {
  setSelectedHotel(Number(hid));
}

export {
  coreAPI,
  setToken,
  removeToken,
  setSelectedHotel,
  removeSelectedHotel,
};
