import React, { useRef } from 'react';
import { FormSpy } from 'react-final-form';
import { useEffect } from 'react';
import { FormState } from 'final-form';

export interface FormAutoSaveProps<FormValues> {
  debounce?: number;
  callback: (values: FormValues) => void;
}

export type FormAutoSaveInnerProps<FormValue> = FormAutoSaveProps<FormValue> &
  FormState<FormValue>;

function FormAutoSaveInner<FormValue>({
  debounce = 1000,
  callback,
  values,
}: FormAutoSaveInnerProps<FormValue>) {
  const timeout = useRef(-1);
  const firstRender = useRef(true);
  const promise = useRef<Promise<any>>(null);

  const save = async () => {
    if (promise.current) {
      await promise.current;
    }
    callback(values);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(save, debounce);
  });

  return <></>;
}

function FormAutoSave<FormValue>(props: FormAutoSaveProps<FormValue>) {
  return (
    <FormSpy<FormValue>
      {...props}
      subscription={{ values: true }}
      // @ts-ignore
      component={FormAutoSaveInner}
    />
  );
}

export default FormAutoSave;
