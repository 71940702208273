import React from 'react';
import InputNumber, { InputNumberProps } from 'antd/lib/input-number';
import 'antd/lib/input-number/style';
import { numberFormatter, numberFormatterBack } from '@aha/utils';

export const InputNumberFormat = React.forwardRef<InputNumberProps, any>(
  (props, ref) => (
    <InputNumber
      ref={ref}
      className="w-full"
      formatter={numberFormatter}
      parser={numberFormatterBack}
      {...props}
    />
  ),
);

export default InputNumberFormat;
