import { reduce } from 'lodash';
import { ModelHourlyBookingPayment } from 'types/v3-schema';

export function calcTotalPayment(payments: ModelHourlyBookingPayment[]) {
  let cCode: string = '';
  const totalPaid = reduce(
    payments,
    (fin, p) => {
      const cRate = p?.currency?.Rate?.Rate || 1;
      if (cRate === 1 && !cCode) {
        cCode = p.currency?.Name || '';
      }

      if (p.status !== 'active') {
        return fin;
      }
      return fin + (p.amount || 0) * cRate;
    },
    0,
  );
  return { totalPaid, currency: cCode || 'VND' };
}
