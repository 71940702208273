import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, Table, TextBlock, DateTimeFormatter } from '@aha/ui';
import { connect } from 'react-redux';
import moment from 'moment';
import { fetchBookingChangeLogs } from 'containers/BookingProvider/actions';
import {
  makeSelectBookingChangeLogs,
  makeSelectIsFetchingChangeLogs,
} from 'containers/BookingProvider/selector';
import { useInjectReducer } from 'utils/injectReducer';
import { BOOKING_KEY } from 'containers/BookingProvider/constants';
import { useInjectSaga } from 'utils/injectSaga';
import bookingsReducer from 'containers/BookingProvider/reducer';
import saga from 'containers/BookingProvider/saga';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { currencyFormater } from 'utils/currencyFormater';
import styled from 'styled-components';
import classnames from 'classnames';
import messages from 'utils/messages/bookings';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-bottom: 0px !important;
  }
`;

const StyledTable = styled(Table)`
  .ant-table-tbody > tr:last-child > td {
    border-bottom: 0;
  }
`;

export const InnerModal = ({ loading, data }) => {
  return (
    <StyledTable
      disabled={loading}
      dataSource={data}
      pagination={false}
      className="mt-4"
      rowKey={(r) => moment(r.d).unix()}
      scroll={{ y: '50vh' }}
      columns={[
        {
          title: (
            <FormattedMessage
              defaultMessage="Action"
              id="common.label.action"
            />
          ),
          dataIndex: 'action',
          width: '20%',
          render: (txt) => (
            <FormattedMessage
              {...(messages[`bookings.changeLogs.${txt}`] || {
                id: 'common.label.default',
                defaultMessage: txt,
              })}
            />
          ),
        },
        {
          title: (
            <FormattedMessage
              defaultMessage="Result"
              id="common.label.result"
            />
          ),
          // width: '40%',
          dataIndex: 'result',
          render: (txt) => <p className="whitespace-normal m-0">{txt}</p>,
        },
        {
          title: (
            <FormattedMessage
              defaultMessage="Edit by"
              id="common.label.editBy"
            />
          ),
          width: '20%',
          dataIndex: 'editBy',
          render: (txt, r) => (
            <TextBlock
              title={txt}
              titleClassName={classnames('text-2xs font-semibold uppercase', {
                'text-red': txt === 'SYSTEM',
              })}
              subTitle={r.userEmail}
            />
          ),
        },
        {
          title: (
            <FormattedMessage defaultMessage="Date" id="common.label.date" />
          ),
          width: '15%',
          dataIndex: 'date',
          render: (d) => (
            <DateTimeFormatter
              as="p"
              className="whitespace-normal m-0 sm:truncate"
              format="HH:mm, DD MMM YYYY"
              value={d}
            />
          ),
        },
      ]}
    />
  );
};

function BookingChangeLogModal(props) {
  useInjectReducer({ key: BOOKING_KEY, reducer: bookingsReducer });
  useInjectSaga({ key: BOOKING_KEY, saga });

  const {
    bid,
    refCode,
    loading,
    changeLogs = [],
    visible,
    doFetchChangeLogs,
    ...rest
  } = props;

  useEffect(() => {
    if (visible) {
      doFetchChangeLogs(bid);
    }
  }, [bid, visible]); // eslint-disable-line

  const data = useMemo(
    () =>
      changeLogs
        .map((item) => {
          let result = null;
          const {
            destAmount = 0,
            destCurrency = 'VND',
            totalAmount = 0,
            currencyCode = 'VND',
            name = 'Unknown',
            quantity = 1,
          } = item.detail[0] || {};
          switch (item.detailObjectType) {
            case 'booking':
              result = [item.oldData, item.newData]
                .filter(Boolean)
                .join(' => ');
              break;
            case 'booking_room':
              result = [item.oldData, item.newData]
                .filter(Boolean)
                .join(' => ');
              break;
            case 'payment':
              result = currencyFormater(destAmount, destCurrency);
              break;
            case 'charge':
              result = currencyFormater(totalAmount, currencyCode);
              result = `${quantity} x ${name} - ${result}`;
              break;
            default:
              result = `${item.oldData} => ${item.newData}`;
              break;
          }
          return {
            action: item.action,
            result,
            editBy: item.user?.name || 'SYSTEM',
            date: item.createdAt,
            userEmail: item.user?.email,
            userRole: item.user?.role,
          };
        })
        .sort((a, b) => moment(b.date).diff(a.date)),
    [changeLogs],
  );

  return (
    <StyledModal
      visible={visible}
      destroyOnClose
      hiddenFooter
      noContentPadding
      width={1200}
      title={
        <>
          <FormattedMessage
            defaultMessage="Booking change logs"
            id="bookings.label.bookingChangeLogs"
          />
          <div className="text-sm text-grey-darker font-normal mt-1">
            ID: {refCode}
          </div>
        </>
      }
      {...rest}
    >
      <InnerModal loading={loading} data={data} />
    </StyledModal>
  );
}

BookingChangeLogModal.propTypes = {
  visible: PropTypes.bool,
};

const mapState = createStructuredSelector({
  loading: makeSelectIsFetchingChangeLogs(),
  changeLogs: makeSelectBookingChangeLogs(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchChangeLogs: (bid) => dispatch(fetchBookingChangeLogs(bid)),
  };
};

export default connect(mapState, mapDispatchToProps)(BookingChangeLogModal);
