import { takeLatest, call, put } from 'redux-saga/effects';

import { createHourlyBookingSuccess } from '../actions';
import { coreAPI } from 'utils/request';
import { CREATE_HOURLY_BOOKING } from '../constants';
import { showErrorNotification } from '@aha/utils';
import LockDoorAPI from '../loockdoor';
import moment from 'moment';

function* doCreateHourlyBooking({ hid, reqData, resolve, reject, lockdoor }) {
  try {
    const lockDoorBk = {
      arrival: reqData?.checkinTime,
      departure: moment(reqData?.checkinTime).add(1, 'day'),
      room: reqData?.room,
      guest: {
        firstName: reqData?.guest?.firstName || 'Unknown',
        lastName: reqData?.guest?.passport || 'Unknown',
        fullName: reqData?.guest?.fullName,
      },
    };

    if (lockdoor) {
      yield call(
        LockDoorAPI.checkin,
        lockDoorBk,
        reqData?.room?.lockDoorRoomNo || reqData.room?.lockDoorRoomNo,
      );
    }
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/hourly-bookings`,
      reqData,
    );
    yield put(createHourlyBookingSuccess(data));

    LockDoorAPI.removeCached();
    resolve(data);
  } catch (err) {
    showErrorNotification('Create hourly booking failed!', err);
    reject(err);
  }
}

export function* watchCreateHourlyBooking() {
  yield takeLatest(CREATE_HOURLY_BOOKING, doCreateHourlyBooking);
}
