import { takeLatest, put, call } from 'redux-saga/effects';

import { coreAPI } from 'utils/request';
import { unAssignGuestBookingSuccess } from '../actions';
import { UNASSIGN_GUEST_BOOKING } from '../constants';
import { showErrorNotification } from '@aha/utils';
import NProgress from 'nprogress';

function* doUnassignGuestBooking({ bid, guest }) {
  yield call(NProgress.start);
  try {
    yield call(coreAPI.put, `v1/pms/bookings/${bid}/unassign-guest`, guest);
    yield put(unAssignGuestBookingSuccess(guest));
  } catch (err) {
    showErrorNotification('Fail to delete', err);
  }
  yield call(NProgress.done);
}

export function* watchUnassignGuestBooking() {
  yield takeLatest(UNASSIGN_GUEST_BOOKING, doUnassignGuestBooking);
}
