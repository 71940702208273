import { all, put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { showErrorNotification, showSuccessNotification } from '@aha/utils';
import { coreAPI } from 'utils/request';
import ActionTypes from './constants';

import {
  fetchRoomTypesSuccess,
  fetchRoomTypesFail,
  createSyncCmsSuccess,
  createSyncCmsFail,
  createRoomTypeSuccess,
  editRoomTypeSuccess,
  deleteRoomTypeSuccess,
  editRoomTypeHourlySettingSuccess,
} from './actions';

import NProgress from 'nprogress';
import { ExtractAction } from 'types/actions';
import { RoomTypesActions } from './type';

export function* doFetchRoomTypes() {
  yield call(NProgress.start);
  try {
    const { data } = yield call(coreAPI.get, `/v1/pms/room-types`);
    yield put(fetchRoomTypesSuccess(data));
  } catch (err) {
    yield put(fetchRoomTypesFail(err));
  }
  yield call(NProgress.done);
}

export function* watchFetchRoomTypes() {
  yield takeLatest(ActionTypes.FETCH_ROOM_TYPES, doFetchRoomTypes);
}

export function* doCreateRoomType(
  action: ExtractAction<RoomTypesActions, ActionTypes.CREATE_ROOM_TYPE>,
) {
  const {
    payload: {
      data: { roomType },
      resolve,
      reject,
    },
  } = action;

  yield call(NProgress.start);

  try {
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/operation/room-types`,
      roomType,
    );
    yield put(createRoomTypeSuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification('Fail to create', err);
    reject(err);
  }
  yield call(NProgress.done);
}

export function* watchCreateRoomType() {
  yield takeEvery(ActionTypes.CREATE_ROOM_TYPE, doCreateRoomType);
}

export function* doEditRoomType(
  action: ExtractAction<RoomTypesActions, ActionTypes.EDIT_ROOM_TYPE>,
) {
  const {
    payload: {
      data: { roomTypeId, roomType },
      resolve,
      reject,
    },
  } = action;

  yield call(NProgress.start);
  try {
    const { data } = yield call(
      coreAPI.put,
      `v1/pms/operation/room-types/${roomTypeId}`,
      roomType,
    );
    yield put(editRoomTypeSuccess(data));
    resolve(data);
  } catch (err) {
    showErrorNotification('Fail to edit', err);
    reject(err);
  }
  yield call(NProgress.done);
}

export function* watchEditRoomType() {
  yield takeEvery(ActionTypes.EDIT_ROOM_TYPE, doEditRoomType);
}

export function* doDeleteRoomType(
  action: ExtractAction<RoomTypesActions, ActionTypes.DELETE_ROOM_TYPE>,
) {
  yield call(NProgress.start);

  try {
    const { payload: roomTypeId } = action;

    yield call(
      coreAPI.put,
      `v1/pms/operation/room-types/${roomTypeId}/deactivate`,
    );
    yield put(deleteRoomTypeSuccess(roomTypeId));
  } catch (err) {
    showErrorNotification('Fail to delete', err);
  }
  yield call(NProgress.done);
}

export function* watchDeleteRoomType() {
  yield takeEvery(ActionTypes.DELETE_ROOM_TYPE, doDeleteRoomType);
}

export function* doSyncCms(
  action: ExtractAction<RoomTypesActions, ActionTypes.CREATE_SYNC_PMS>,
) {
  yield call(NProgress.start);

  try {
    const { payload: hotelId } = action;

    yield call(coreAPI.post, 'v1/pms/operation/cms/sync', {
      PMSHotelID: hotelId,
    });
    const { data } = yield call(coreAPI.get, `v1/pms/room-types`);
    yield put(createSyncCmsSuccess(data));
    showSuccessNotification('Successfully', 'Sync rooms and rates ');
  } catch (err) {
    showErrorNotification('Fail to sync', err);
    yield put(createSyncCmsFail());
  }
  yield call(NProgress.done);
}

export function* watchSyncCms() {
  yield takeEvery(ActionTypes.CREATE_SYNC_PMS, doSyncCms);
}

export function* doRoomTypeHourlySetting(
  action: ExtractAction<
    RoomTypesActions,
    ActionTypes.EDIT_ROOM_TYPE_HOURLY_SETTING
  >,
) {
  yield call(NProgress.start);

  const {
    payload: {
      data: { roomTypeId, hourlyService },
      resolve,
      reject,
    },
  } = action;

  try {
    const { data } = yield call(
      coreAPI.post,
      `v1/pms/operation/room-types/${roomTypeId}/hourly-settings`,
      hourlyService,
    );
    yield put(editRoomTypeHourlySettingSuccess(data));
    resolve(data);
    const { data: roomTypeData } = yield call(
      coreAPI.get,
      `/v1/pms/room-types`,
    );
    yield put(fetchRoomTypesSuccess(roomTypeData));
  } catch (err) {
    showErrorNotification('Fail to edit', err);
    reject(err);
  }
  yield call(NProgress.done);
}

export function* watchRoomTypeHourlySetting() {
  yield takeEvery(
    ActionTypes.EDIT_ROOM_TYPE_HOURLY_SETTING,
    doRoomTypeHourlySetting,
  );
}

export default function* watchAll() {
  yield all([
    watchFetchRoomTypes(),
    watchCreateRoomType(),
    watchEditRoomType(),
    watchDeleteRoomType(),
    watchSyncCms(),
    watchRoomTypeHourlySetting(),
  ]);
}
