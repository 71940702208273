import { put, takeEvery, call } from 'redux-saga/effects';
import { fetchCurrenciesSuccess, fetchCurrenciesFail } from '../actions';
import ActionTypes from '../constants';
import { coreAPI } from 'utils/request';
import NProgress from 'nprogress';
import { showErrorNotification } from '@aha/utils';
import { ExtractAction } from 'types/actions';
import { CurrencySettingsActions } from '../type';

export function* doFetchCurrencies(
  action: ExtractAction<CurrencySettingsActions, ActionTypes.FETCH_CURRENCIES>,
) {
  yield call(NProgress.start);
  const hotelId = action.payload;

  try {
    const { data } = yield call(
      coreAPI.get,
      `v1/pms/hotel/${hotelId}/currencies`,
    );
    yield put(fetchCurrenciesSuccess(data));
  } catch (err) {
    showErrorNotification('Fail to fetch', err);
    yield put(fetchCurrenciesFail(err));
  }
  yield call(NProgress.done);
}

export default function* watchFetchCurrency() {
  yield takeEvery(ActionTypes.FETCH_CURRENCIES, doFetchCurrencies);
}
