import produce from 'immer';
import ActionTypes from './constants';
import initialStateHolder from 'utils/initialStateHolder';
import { RoomPlanActions, RoomPlanState } from './types';

export const initialState = initialStateHolder.setState('roomPlan', {
  loading: false,
  loadingActions: false,
  loadingRefresh: false,
  isRefreshRoom: false,
  error: null,
  rooms: null,
  openCreateLog: false,
});

export default function roomPlanReducer(
  state: RoomPlanState = initialState,
  action: RoomPlanActions,
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.FETCH_ROOMS:
        draft.loading = true;
        draft.error = null;
        break;
      case ActionTypes.FETCH_ROOMS_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.rooms = (action.payload || []).filter((r) =>
          ['vacant', 'occupied'].includes(r.status || ''),
        );
        break;

      case ActionTypes.FETCH_REFRESH_ROOMS:
        draft.loadingRefresh = true;
        draft.error = null;
        break;

      case ActionTypes.FETCH_REFRESH_ROOMS_SUCCESS:
        draft.loadingRefresh = false;
        draft.rooms = (action.payload || []).filter((r) =>
          ['vacant', 'occupied'].includes(r.status || ''),
        );
        draft.error = null;
        break;

      case ActionTypes.FETCH_ROOMS_FAIL:
        draft.loading = false;
        draft.loadingRefresh = false;
        draft.error = action.payload;
        break;

      case ActionTypes.SET_IS_REFRESH_ROOMS:
        draft.isRefreshRoom = action.payload;
        break;

      case ActionTypes.UPDATE_ROOM_HK_STATUS:
        draft.loadingActions = true;
        break;
      case ActionTypes.UPDATE_ROOM_HK_STATUS_SUCCESS:
        draft.loadingActions = false;
        draft.rooms = (draft.rooms || []).map((r) =>
          r.id === action.payload.id
            ? { ...r, housekeepingStatus: action.payload.housekeepingStatus }
            : r,
        );
        break;
      case ActionTypes.UPDATE_ROOM_HK_STATUS_FAIL:
        draft.loadingActions = false;
        draft.error = action.payload;
        break;

      case ActionTypes.CREATE_LOG_SUCCESS: {
        const log = action.payload;
        const roomIdx = (draft.rooms || []).findIndex(
          (r) => r.id === log.roomId,
        );
        if (roomIdx === -1 || !Array.isArray(draft.rooms)) {
          break;
        }

        if (Array.isArray(draft.rooms[roomIdx]?.roomAvailabilityLogs)) {
          draft.rooms[roomIdx].roomAvailabilityLogs!.unshift(log);
        } else {
          draft.rooms[roomIdx].roomAvailabilityLogs = [log];
        }
        break;
      }

      case ActionTypes.EDIT_LOG_SUCCESS: {
        const log = action.payload;
        const roomIdx = (draft.rooms || []).findIndex(
          (r) => r.id === log.roomId,
        );
        if (roomIdx === -1 || !Array.isArray(draft.rooms)) {
          break;
        }

        const logIdx = (
          draft.rooms[roomIdx].roomAvailabilityLogs || []
        ).findIndex(({ id }) => id === log.id);

        if (
          logIdx !== -1 &&
          Array.isArray(draft.rooms[roomIdx].roomAvailabilityLogs)
        ) {
          draft.rooms[roomIdx].roomAvailabilityLogs![logIdx] = log;
        }
        break;
      }

      case ActionTypes.DELETE_LOG_SUCCESS: {
        const roomIdx = (draft.rooms || []).findIndex(
          (r) => r.id === action.payload.roomId,
        );
        if (roomIdx === -1 || !Array.isArray(draft.rooms)) {
          break;
        }

        draft.rooms[roomIdx].roomAvailabilityLogs = (
          draft.rooms[roomIdx].roomAvailabilityLogs || []
        ).filter(({ id }) => id !== action.payload.id);

        break;
      }

      case ActionTypes.OPEN_CREATE_LOG:
        draft.openCreateLog = action.payload;
        break;

      case ActionTypes.FETCH_LOGS_SUCCESS:
        const roomIdx = (draft.rooms || []).findIndex(
          (r) => r.id === action.payload.roomId,
        );
        if (roomIdx === -1 || !Array.isArray(draft.rooms)) {
          break;
        }
        if (action.payload.logs && action.payload.logs.length) {
          draft.rooms[roomIdx].roomAvailabilityLogs = action.payload.logs;
          break;
        }
    }
  });
}
