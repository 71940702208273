enum ActionTypes {
  DASHBOARD_KEY = 'dashboard',
  TOGGLE_COLLAPSE = 'dashboard/TOGGLE_COLLAPSE',
  FETCH_CURRENT_HOTEL = 'dashboard/FETCH_CURRENT_HOTEL',
  FETCH_CURRENT_HOTEL_SUCCESS = 'dashboard/FETCH_CURRENT_HOTEL_SUCCESS',
  FETCH_CURRENT_HOTEL_FAIL = 'dashboard/FETCH_CURRENT_HOTEL_FAIL',
  FETCH_COLORS = 'dashboard/FETCH_COLORS',
  FETCH_COLORS_SUCCESS = 'dashboard/FETCH_COLORS_SUCCESS',

  SWITCH_CURRENT_HOTEL = 'dashboard/SWITCH_CURRENT_HOTEL',
  SWITCH_CURRENT_HOTEL_SUCCESS = 'dashboard/SWITCH_CURRENT_HOTEL_SUCCESS',
  SWITCH_CURRENT_HOTEL_FAIL = 'dashboard/SWITCH_CURRENT_HOTEL_FAIL',

  SET_CURRENT_HOTEL_ID = 'dashboard/SET_CURRENT_HOTEL_ID',
  SET_CURRENT_HOTEL_ID_SUCCESS = 'dashboard/SET_CURRENT_HOTEL_ID_SUCCESS',
  SET_CURRENT_HOTEL_ID_FAIL = 'dashboard/SET_CURRENT_HOTEL_ID_FAIL',

  FETCH_CITIES = 'dashboard/FETCH_CITIES',
  FETCH_CITIES_SUCCESS = 'dashboard/FETCH_CITIES_SUCCESS',
  FETCH_CITIES_FAIL = 'dashboard/FETCH_CITIES_FAIL',

  FETCH_DISTRICTS = 'dashboard/FETCH_DISTRICTS',
  FETCH_DISTRICTS_SUCCESS = 'dashboard/FETCH_DISTRICTS_SUCCESS',
  FETCH_DISTRICTS_FAIL = 'dashboard/FETCH_DISTRICTS_FAIL',

  FETCH_FACILITIES = 'dashboard/FETCH_FACILITIES',
  FETCH_FACILITIES_SUCCESS = 'dashboard/FETCH_FACILITIES_SUCCESS',
  FETCH_FACILITIES_FAIL = 'dashboard/FETCH_FACILITIES_FAIL',

  FETCH_AMENITIES = 'dashboard/FETCH_AMENITIES',
  FETCH_AMENITIES_SUCCESS = 'dashboard/FETCH_AMENITIES_SUCCESS',
  FETCH_AMENITIES_FAIL = 'dashboard/FETCH_AMENITIES_FAIL',
}

export default ActionTypes;
