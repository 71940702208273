import React, { useState } from 'react';
import { Form, DatePicker } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Select } from '@aha/ui';
import { FormComponentProps } from 'antd/lib/form';
import { range } from 'lodash';
import moment from 'moment';
import { Dispatch } from 'redux';
import { editLog } from '../actions';
import { useDispatch } from 'react-redux';
import { OOO_REASONS, ReasonType } from 'constants/rooms';
import { translate } from 'utils/translate';
import { removeAccents } from '@aha/utils';
import messages from 'utils/messages/roomPlan';
import { HHMM_DD_MMM_YYYY } from '@aha/constants';
import {
  RoomAvailabilityLog,
  UpdateRoomAvailabilityLogResquest,
} from 'types/schema';

export type EditOOOModalProps = {
  roomTitle: string;
  log: RoomAvailabilityLog;
  visible?: boolean;
  onClose: (...args: any[]) => any;
} & FormComponentProps;

type SubmitValues = Omit<UpdateRoomAvailabilityLogResquest, 'reason'> & {
  reason: string;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doEditLog: (roomId: number, log: UpdateRoomAvailabilityLogResquest) =>
    new Promise((resolve, reject) =>
      dispatch(editLog({ data: { roomId, log }, resolve, reject })),
    ),
});

const EditOOOModal: React.SFC<EditOOOModalProps> = ({
  log,
  roomTitle,
  visible,
  onClose,
  form,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { doEditLog } = mapDispatchToProps(dispatch);
  const [submitting, setSubmitting] = useState(false);
  const {
    getFieldDecorator,
    getFieldValue,
    resetFields,
    validateFields,
  } = form;

  const unAvailableFrom =
    getFieldValue('unAvailableFrom') || moment(log?.unAvailableFrom);

  function onCancel() {
    if (submitting) {
      return;
    }

    onClose();
    resetFields();
  }

  function onAdd() {
    validateFields(async (err, values: SubmitValues) => {
      const { reason, availableDate, unAvailableFrom } = values;
      const desc = OOO_REASONS[reason as ReasonType];
      if (err || !log.id || !log.roomId) {
        return;
      }

      try {
        setSubmitting(true);

        const body = {
          roomAvailabilityLogId: log.id,
          availableDate: moment(availableDate).set('second', 0).toISOString(),
          unAvailableFrom: moment(unAvailableFrom)
            .set('second', 0)
            .toISOString(),
          reason: {
            code: reason,
            description: desc,
          },
        };

        await doEditLog(log.roomId, body);

        onClose();
        resetFields();
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    });
  }

  return (
    <Modal
      visible={visible}
      title={
        <FormattedMessage
          defaultMessage="OOO {title} room"
          id="bookings.label.createInterval"
          values={{ title: roomTitle }}
        />
      }
      submitText={
        <FormattedMessage defaultMessage="Edit" id="common.action.edit" />
      }
      cancelText={
        <FormattedMessage defaultMessage="Cancel" id="common.action.cancel" />
      }
      submitting={submitting}
      onCancel={onCancel}
      onSubmit={onAdd}
      width={742}
    >
      <Form layout="vertical">
        <div className="flex flex-wrap -mx-2">
          <div className="w-full px-2 md:w-1/2">
            <Form.Item
              label={
                <FormattedMessage
                  defaultMessage="Out of order time"
                  id="common.label.oooTime"
                />
              }
            >
              {getFieldDecorator('unAvailableFrom', {
                initialValue: log?.unAvailableFrom
                  ? moment(log?.unAvailableFrom)
                  : undefined,
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                ],
              })(
                <DatePicker
                  disabled={moment(unAvailableFrom).isSameOrBefore(
                    moment(),
                    'minutes',
                  )}
                  disabledDate={(cur) => !!cur && cur < moment().startOf('day')}
                  disabledTime={(cur) => {
                    const isSameDate = moment().isSame(cur, 'dates');
                    const isSameHour = moment().isSame(cur, 'hours');

                    return {
                      disabledHours: () =>
                        isSameDate ? range(0, moment()?.hours()) : [],
                      disabledMinutes: () =>
                        isSameDate && isSameHour
                          ? range(0, moment()?.minutes() || 0)
                          : [],
                    };
                  }}
                  showTime={{ format: 'HH:mm' }}
                  className="w-full"
                  format={HHMM_DD_MMM_YYYY}
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Start Date & Time',
                    id: 'common.label.startDateAndTime',
                  })}
                />,
              )}
            </Form.Item>
          </div>
          <div className="w-full px-2 md:w-1/2">
            <Form.Item
              required={false}
              label={<span className="opacity-0">x</span>}
            >
              {getFieldDecorator('availableDate', {
                initialValue: log?.availableDate
                  ? moment(log?.availableDate)
                  : undefined,
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="common.message.required"
                        defaultMessage="Required"
                      />
                    ),
                  },
                  {
                    required: true,
                    validator: (_, v, cb) => {
                      return moment(unAvailableFrom).isSameOrAfter(v, 'minutes')
                        ? cb(
                            <FormattedMessage
                              id="common.message.invalid"
                              defaultMessage="Invalid"
                            />,
                          )
                        : cb();
                    },
                  },
                ],
              })(
                <DatePicker
                  disabledDate={(cur) =>
                    !!cur && cur < moment(unAvailableFrom).startOf('day')
                  }
                  showTime={{ format: 'HH:mm' }}
                  disabledTime={(cur) => {
                    const isSameDate = moment(unAvailableFrom).isSame(
                      cur,
                      'dates',
                    );
                    const isSameHour = moment(unAvailableFrom).isSame(
                      cur,
                      'hours',
                    );

                    return {
                      disabledHours: () =>
                        isSameDate
                          ? range(0, moment(unAvailableFrom)?.hours())
                          : [],
                      disabledMinutes: () =>
                        isSameDate && isSameHour
                          ? range(
                              0,
                              (moment(unAvailableFrom)?.minutes() || 0) + 1,
                            )
                          : [],
                    };
                  }}
                  format={HHMM_DD_MMM_YYYY}
                  className="w-full"
                  placeholder={intl.formatMessage({
                    defaultMessage: 'End Date & Time',
                    id: 'common.label.endDateAndTime',
                  })}
                />,
              )}
            </Form.Item>
          </div>
        </div>
        <Form.Item
          label={
            <FormattedMessage
              defaultMessage="Select reason"
              id="common.label.oooReason"
            />
          }
        >
          {getFieldDecorator('reason', {
            initialValue: log?.reason?.code,
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="common.message.required"
                    defaultMessage="Required"
                  />
                ),
              },
            ],
          })(
            <Select
              allowClear
              showSearch
              className="w-full"
              placeholder={
                <FormattedMessage
                  defaultMessage="Select reason"
                  id="common.label.selectReason"
                />
              }
              filterOption={(input, option) =>
                removeAccents((option.props.children || '').toString()).indexOf(
                  removeAccents(input.toString()),
                ) !== -1
              }
            >
              {Object.entries(OOO_REASONS).map(([key, value]) => (
                <Select.Option key={key} value={key}>
                  {translate(value, messages)}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create<EditOOOModalProps>({
  name: 'edit_ooo',
})(EditOOOModal);
