import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { IconMessengerSupport } from '@aha/icons';

export interface AhaSupportButtonProps {
  className?: string;
}

export default function AhaSupportButton({
  className = '',
}: AhaSupportButtonProps) {
  return (
    <a
      className={classnames('inline-flex items-center text-secondary', {
        [className]: className,
      })}
      href="https://www.facebook.com/AHARooms-PMS-Support-115537940354295"
      target="_blank"
      rel="noopener noreferrer"
    >
      <IconMessengerSupport className="text-base" />
      <span className="ml-2">
        <FormattedMessage id="common.label.support" defaultMessage="Support" />
      </span>
    </a>
  );
}
