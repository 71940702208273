import {
  FETCH_BOOKING,
  FETCH_BOOKING_FAIL,
  FETCH_BOOKING_SUCCSESS,
  FETCH_EXPECTING_BOOKING_LIST,
  FETCH_EXPECTING_BOOKING_LIST_SUCCESS,
  FETCH_EXPECTING_BOOKING_LIST_FAIL,
  FETCH_INHOUSE_GUEST_LIST,
  FETCH_INHOUSE_GUEST_LIST_SUCCESS,
  FETCH_INHOUSE_GUEST_LIST_FAIL,
  FETCH_CHECKED_IN_BOOKINGS,
  FETCH_CHECKED_IN_BOOKINGS_SUCCESS,
  FETCH_CHECKED_IN_BOOKINGS_FAIL,
  FETCH_CHECKED_OUT_BOOKINGS,
  FETCH_CHECKED_OUT_BOOKINGS_SUCCESS,
  FETCH_CHECKED_OUT_BOOKINGS_FAIL,
  FETCH_AVAILABLE_ROOMS,
  FETCH_AVAILABLE_ROOMS_SUCCESS,
  FETCH_AVAILABLE_ROOMS_FAIL,
  FETCH_ARRIVAL_BOOKINGS,
  FETCH_ARRIVAL_BOOKINGS_FAIL,
  FETCH_ARRIVAL_BOOKINGS_SUCCESS,
  FETCH_DEPARTURE_BOOKINGS,
  FETCH_DEPARTURE_BOOKINGS_SUCCESS,
  FETCH_DEPARTURE_BOOKINGS_FAIL,
  ALLOCATE_ROOM_FOR_BOOKING_FAIL,
  ALLOCATE_ROOM_FOR_BOOKING_SUCCESS,
  ALLOCATE_ROOM_FOR_BOOKING,
  CHECK_IN_BOOKING,
  CHECK_IN_BOOKING_SUCCESS,
  CHECK_IN_BOOKING_FAIL,
  CHECK_OUT_BOOKING,
  CHECK_OUT_BOOKING_SUCCESS,
  CHECK_OUT_BOOKING_FAIL,
  NO_SHOW_BOOKING,
  NO_SHOW_BOOKING_FAIL,
  CANCEL_BOOKING,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_FAIL,
  NO_SHOW_BOOKING_SUCCESS,
  FETCH_FOLIO_DETAIL,
  FETCH_FOLIO_DETAIL_SUCCESS,
  FETCH_FOLIO_DETAIL_FAIL,
  FETCH_PAYMENT_TYPES,
  FETCH_PAYMENT_TYPES_SUCCESS,
  FETCH_PAYMENT_TYPES_FAIL,
  FETCH_CHARGE_TEMPLATES,
  FETCH_CHARGE_TEMPLATES_SUCCESS,
  FETCH_CHARGE_TEMPLATES_FAIL,
  ADD_FOLIO_PAYMENT,
  ADD_FOLIO_PAYMENT_SUCCESS,
  ADD_FOLIO_PAYMENT_FAIL,
  FETCH_REVENUE_GROUPS,
  FETCH_REVENUE_GROUPS_SUCCESS,
  FETCH_REVENUE_GROUPS_FAIL,
  ADD_FOLIO_CHARGE,
  ADD_FOLIO_CHARGE_SUCCESS,
  ADD_FOLIO_CHARGE_FAIL,
  VOID_FOLIO_CHARGES,
  VOID_FOLIO_CHARGES_SUCCESS,
  VOID_FOLIO_CHARGES_FAIL,
  UPDATE_FOLIO_PAYMENT,
  UPDATE_FOLIO_PAYMENT_SUCCESS,
  UPDATE_FOLIO_PAYMENT_FAIL,
  UPDARE_FOLIO_CHARGE,
  UPDARE_FOLIO_CHARGE_SUCCESS,
  UPDARE_FOLIO_CHARGE_FAIL,
  VOID_FOLIO,
  VOID_FOLIO_SUCCESS,
  VOID_FOLIO_FAIL,
  ADD_FOLIO,
  ADD_FOLIO_SUCCESS,
  ADD_FOLIO_FAIL,
  VOID_FOLIO_PAYMENT,
  VOID_FOLIO_PAYMENT_SUCCESS,
  VOID_FOLIO_PAYMENT_FAIL,
  UPDATE_BOOKING_PERIOD,
  UPDATE_BOOKING_PERIOD_SUCCESS,
  UPDATE_BOOKING_PERIOD_FAIL,
  VOID_CHARGES_OF_BOOKING,
  VOID_CHARGES_OF_BOOKING_SUCCESS,
  VOID_CHARGES_OF_BOOKING_FAIL,
  FETCH_BOOKING_HISTORY,
  FETCH_BOOKING_HISTORY_SUCCESS,
  FETCH_BOOKING_HISTORY_FAIL,
  CREATE_HOURLY_BOOKING,
  UPDATE_BOOKING_INFO,
  UPDATE_BOOKING_INFO_SUCCESS,
  UPDATE_BOOKING_INFO_FAIL,
  REMOVE_ROOM_FROM_BOOKING,
  REMOVE_ROOM_FROM_BOOKING_SUCCESS,
  REMOVE_ROOM_FROM_BOOKING_FAIL,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES,
  FETCH_COMPANIES_FAIL,
  CREATE_HOURLY_BOOKING_SUCCESS,
  UNDO_CHECKIN_BOOKING,
  UNASSIGN_GUEST_BOOKING,
  UNASSIGN_GUEST_BOOKING_SUCCESS,
  ASSIGN_GUEST_BOOKING,
  ASSIGN_GUEST_BOOKING_SUCCESS,
  TRANSFER_CHARGES_BOOKING,
  TRANSFER_CHARGES_BOOKING_SUCCESS,
  TRANSFER_FOLIO_BOOKING,
  TRANSFER_FOLIO_BOOKING_SUCCESS,
  SET_CURRENT_TAB,
  ADD_GUEST_TO_BOOKING,
  ADD_GUEST_TO_BOOKING_SUCCESS,
  EDIT_BOOKING_ROOM_TYPE,
  VOID_MULTIPLE_FOLIO_CHARGES,
  CANCEL_A_CHILD_BOOKING,
  ADD_CHILD_BOOKING,
  CREATE_COMPANY_SUCCESS,
  GROUP_FOLIOS,
  GROUP_FOLIOS_SUCCESS,
  SEPARATE_FOLIOS,
  SEPARATE_FOLIOS_SUCCESS,
  RESET_AVAILABLE_ROOMS,
  FETCH_EXPIRED_BOOKINGS,
  FETCH_EXPIRED_BOOKINGS_SUCCESS,
  ALLOCATE_ROOM_FOR_HOURLY_BOOKING,
  ALLOCATE_ROOM_FOR_HOURLY_BOOKING_SUCCESS,
  ALLOCATE_ROOM_FOR_HOURLY_BOOKING_FAIL,
} from './constants';

export function fetchBooking(bid) {
  return {
    type: FETCH_BOOKING,
    bid,
  };
}

export function fetchBookingSuccess(booking) {
  return {
    type: FETCH_BOOKING_SUCCSESS,
    booking,
  };
}

export function fetchBookingFail(error) {
  return {
    type: FETCH_BOOKING_FAIL,
    error,
  };
}

export function createHourlyBookingSuccess(booking) {
  return {
    type: CREATE_HOURLY_BOOKING_SUCCESS,
    booking,
  };
}

export function fetchBookingList(hid, time, bkType) {
  return {
    type: FETCH_EXPECTING_BOOKING_LIST,
    hid,
    time,
    bkType,
  };
}
export function fetchBookingListSuccess(bookings) {
  return {
    type: FETCH_EXPECTING_BOOKING_LIST_SUCCESS,
    bookings,
  };
}
export function fetchBookingListFail(error) {
  return {
    type: FETCH_EXPECTING_BOOKING_LIST_FAIL,
    error,
  };
}

export function fetchInHouseGuestList(hid, bkType, pageNumber) {
  return {
    type: FETCH_INHOUSE_GUEST_LIST,
    hid,
    bkType,
    pageNumber,
  };
}
export function fetchInHouseGuestListSuccess(bookings, inHousePagination) {
  return {
    type: FETCH_INHOUSE_GUEST_LIST_SUCCESS,
    bookings,
    inHousePagination,
  };
}
export function fetchInHouseGuestListFail(error) {
  return {
    type: FETCH_INHOUSE_GUEST_LIST_FAIL,
    error,
  };
}

export function fetchCheckedInBookings(hid, time) {
  return {
    type: FETCH_CHECKED_IN_BOOKINGS,
    hid,
    time,
  };
}
export function fetchCheckedInBookingsSuccess(bookings) {
  return {
    type: FETCH_CHECKED_IN_BOOKINGS_SUCCESS,
    bookings,
  };
}
export function fetchCheckedInBookingsFail(error) {
  return {
    type: FETCH_CHECKED_IN_BOOKINGS_FAIL,
    error,
  };
}

export function fetchCheckedOutBookings(hid, time) {
  return {
    type: FETCH_CHECKED_OUT_BOOKINGS,
    hid,
    time,
  };
}
export function fetchCheckedOutBookingsSuccess(bookings) {
  return {
    type: FETCH_CHECKED_OUT_BOOKINGS_SUCCESS,
    bookings,
  };
}
export function fetchCheckedOutBookingsFail(error) {
  return {
    type: FETCH_CHECKED_OUT_BOOKINGS_FAIL,
    error,
  };
}

export function fetchAvailableRooms(hid, start, end, roomTypeID) {
  return {
    type: FETCH_AVAILABLE_ROOMS,
    hid,
    start,
    end,
    roomTypeID,
  };
}
export function fetchAvailableRoomsSuccess(rooms, roomTypeID) {
  return {
    type: FETCH_AVAILABLE_ROOMS_SUCCESS,
    rooms,
    roomTypeID,
  };
}

export function fetchAvailableRoomsFail(error) {
  return {
    type: FETCH_AVAILABLE_ROOMS_FAIL,
    error,
  };
}

export function resetAvailableRooms() {
  return {
    type: RESET_AVAILABLE_ROOMS,
  };
}

export function fetchArrivalBookings(
  hid,
  expDate,
  upDate,
  pageNumber,
  pageSize,
  bkType,
) {
  return {
    type: FETCH_ARRIVAL_BOOKINGS,
    hid,
    expDate,
    upDate,
    pageNumber,
    pageSize,
    bkType,
  };
}

export function fetchArrivalBookingsSuccess(
  checkinBookings,
  checkinPagination,
  todayCheckinBookings,
  todayCheckinPagination,
  expiredBks,
  expiredPagination,
  upcomingBks,
  upcomingPagination,
) {
  return {
    type: FETCH_ARRIVAL_BOOKINGS_SUCCESS,
    checkinBookings,
    checkinPagination,
    todayCheckinBookings,
    todayCheckinPagination,
    expiredBks,
    expiredPagination,
    upcomingBks,
    upcomingPagination,
  };
}

export function fetchArrivalBookingsFail(error) {
  return {
    type: FETCH_ARRIVAL_BOOKINGS_FAIL,
    error,
  };
}

export function fetchDepartureBookings(
  hid,
  bkType,
  expDate,
  upDate,
  pageNumber,
  pageSize,
) {
  return {
    type: FETCH_DEPARTURE_BOOKINGS,
    hid,
    bkType,
    expDate,
    upDate,
    pageNumber,
    pageSize,
  };
}

export function fetchDepartureBookingsSuccess(
  checkoutBookings,
  checkoutPagination,
  todayCheckoutBookings,
  todayCheckoutPagination,
  expiredBks,
  expiredPagination,
  upcomingBks,
  upcomingPagination,
) {
  return {
    type: FETCH_DEPARTURE_BOOKINGS_SUCCESS,
    checkoutBookings,
    checkoutPagination,
    todayCheckoutBookings,
    todayCheckoutPagination,
    expiredBks,
    expiredPagination,
    upcomingBks,
    upcomingPagination,
  };
}

export function fetchDepartureBookingsFail(error) {
  return {
    type: FETCH_DEPARTURE_BOOKINGS_FAIL,
    error,
  };
}

export function allocateRoomForBooking(
  booking,
  bookingRoomId,
  roomID,
  resolve,
  reject,
  lockdoor,
  oldLockDoorRoomNo,
  newLockDoorRoomNo,
) {
  return {
    type: ALLOCATE_ROOM_FOR_BOOKING,
    booking,
    bookingRoomId,
    roomID,
    resolve,
    reject,
    lockdoor,
    oldLockDoorRoomNo,
    newLockDoorRoomNo,
  };
}
export function allocateRoomForBookingSuccess(bookingRoom) {
  return {
    type: ALLOCATE_ROOM_FOR_BOOKING_SUCCESS,
    bookingRoom,
  };
}

export function allocateRoomForBookingFail(error) {
  return {
    type: ALLOCATE_ROOM_FOR_BOOKING_FAIL,
    error,
  };
}

export function allocateRoomForHourlyBooking(
  booking,
  bookingRoomId,
  roomID,
  resolve,
  reject,
  lockdoor,
  oldLockDoorRoomNo,
  newLockDoorRoomNo,
) {
  return {
    type: ALLOCATE_ROOM_FOR_HOURLY_BOOKING,
    booking,
    bookingRoomId,
    roomID,
    resolve,
    reject,
    lockdoor,
    oldLockDoorRoomNo,
    newLockDoorRoomNo,
  };
}
export function allocateRoomForHourlyBookingSuccess(bookingRoom) {
  return {
    type: ALLOCATE_ROOM_FOR_HOURLY_BOOKING_SUCCESS,
    bookingRoom,
  };
}

export function allocateRoomForHourlyBookingFail(error) {
  return {
    type: ALLOCATE_ROOM_FOR_HOURLY_BOOKING_FAIL,
    error,
  };
}

export function checkinBooking(
  booking,
  bookingRoomId,
  resolve,
  reject,
  lockdoor,
) {
  return {
    type: CHECK_IN_BOOKING,
    booking,
    resolve,
    reject,
    lockdoor,
    bookingRoomId,
  };
}
export function checkinBookingSuccess(booking, bookingRoomId) {
  return {
    type: CHECK_IN_BOOKING_SUCCESS,
    booking,
    bookingRoomId,
  };
}
export function checkinBookingFail(error) {
  return {
    type: CHECK_IN_BOOKING_FAIL,
    error,
  };
}

export function checkoutBooking(
  booking,
  bookingRoomId,
  resolve,
  reject,
  lockdoor,
) {
  return {
    type: CHECK_OUT_BOOKING,
    booking,
    resolve,
    reject,
    lockdoor,
    bookingRoomId,
  };
}
export function checkoutBookingSuccess(booking, bookingRoomId) {
  return {
    type: CHECK_OUT_BOOKING_SUCCESS,
    booking,
    bookingRoomId,
  };
}
export function checkoutBookingFail(error) {
  return {
    type: CHECK_OUT_BOOKING_FAIL,
    error,
  };
}

export function noShowBooking(booking, resolve, reject) {
  return {
    type: NO_SHOW_BOOKING,
    booking,
    resolve,
    reject,
  };
}
export function noShowBookingSuccess(booking) {
  return {
    type: NO_SHOW_BOOKING_SUCCESS,
    booking,
  };
}
export function noShowBookingFail(error) {
  return {
    type: NO_SHOW_BOOKING_FAIL,
    error,
  };
}

export function cancelBooking(booking, resolve, reject, lockdoor) {
  return {
    type: CANCEL_BOOKING,
    booking,
    resolve,
    reject,
    lockdoor,
  };
}
export function cancelBookingSuccess(booking) {
  return {
    type: CANCEL_BOOKING_SUCCESS,
    booking,
  };
}
export function cancelBookingFail(error) {
  return {
    type: CANCEL_BOOKING_FAIL,
    error,
  };
}

export function fetchFolioDetail({ bid, fid }) {
  return {
    type: FETCH_FOLIO_DETAIL,
    bid,
    fid,
  };
}
export function fetchFolioDetailSuccess(folio) {
  return {
    type: FETCH_FOLIO_DETAIL_SUCCESS,
    folio,
  };
}
export function fetchFolioDetailFail(error) {
  return {
    type: FETCH_FOLIO_DETAIL_FAIL,
    error,
  };
}

export function fetchPaymentTypes(hid) {
  return {
    type: FETCH_PAYMENT_TYPES,
    hid,
  };
}
export function fetchPaymentTypesSuccess(paymentTypes) {
  return {
    type: FETCH_PAYMENT_TYPES_SUCCESS,
    paymentTypes,
  };
}
export function fetchPaymentTypesFail(error) {
  return {
    type: FETCH_PAYMENT_TYPES_FAIL,
    error,
  };
}

export function fetchChargeTemplates(hid) {
  return {
    type: FETCH_CHARGE_TEMPLATES,
    hid,
  };
}
export function fetchChargeTemplatesSuccess(chargeTemplates) {
  return {
    type: FETCH_CHARGE_TEMPLATES_SUCCESS,
    chargeTemplates,
  };
}
export function fetchChargeTemplatesFail(error) {
  return {
    type: FETCH_CHARGE_TEMPLATES_FAIL,
    error,
  };
}

export function fetchRevGroups(hid) {
  return {
    type: FETCH_REVENUE_GROUPS,
    hid,
  };
}
export function fetchRevGroupsSuccess(revGroups) {
  return {
    type: FETCH_REVENUE_GROUPS_SUCCESS,
    revGroups,
  };
}
export function fetchRevGroupsFail(error) {
  return {
    type: FETCH_REVENUE_GROUPS_FAIL,
    error,
  };
}

export function addFolioPayment(fid, reqData, reqType, resolve, reject) {
  return {
    type: ADD_FOLIO_PAYMENT,
    fid,
    reqType,
    reqData,
    resolve,
    reject,
  };
}

export function addFolioPaymentSuccess(payment) {
  return {
    type: ADD_FOLIO_PAYMENT_SUCCESS,
    payment,
  };
}
export function addFolioPaymentFail(error) {
  return {
    type: ADD_FOLIO_PAYMENT_FAIL,
    error,
  };
}

export function addFolioCharge(fid, reqData, reqType, resolve, reject) {
  return {
    type: ADD_FOLIO_CHARGE,
    fid,
    reqType,
    reqData,
    resolve,
    reject,
  };
}

export function addFolioChargeSuccess(charge) {
  return {
    type: ADD_FOLIO_CHARGE_SUCCESS,
    charge,
  };
}
export function addFolioChargeFail(error) {
  return {
    type: ADD_FOLIO_CHARGE_FAIL,
    error,
  };
}

export function voidFolioCharges(chargeIds, resolve, reject) {
  return {
    type: VOID_FOLIO_CHARGES,
    chargeIds,
    resolve,
    reject,
  };
}

export function voidFolioChargesSuccess(charges) {
  return {
    type: VOID_FOLIO_CHARGES_SUCCESS,
    charges,
  };
}
export function voidFolioChargesFail(error) {
  return {
    type: VOID_FOLIO_CHARGES_FAIL,
    error,
  };
}

export function updateFolioPayment(
  paymentId,
  bid,
  fid,
  reqData,
  resolve,
  reject,
) {
  return {
    type: UPDATE_FOLIO_PAYMENT,
    bid,
    fid,
    paymentId,
    reqData,
    resolve,
    reject,
  };
}

export function updateFolioPaymentSuccess(payment) {
  return {
    type: UPDATE_FOLIO_PAYMENT_SUCCESS,
    payment,
  };
}
export function updateFolioPaymentFail(error) {
  return {
    type: UPDATE_FOLIO_PAYMENT_FAIL,
    error,
  };
}

export function updateFolioCharge(
  bookingID,
  folioID,
  chargeID,
  reqData,
  resolve,
  reject,
) {
  return {
    type: UPDARE_FOLIO_CHARGE,
    bookingID,
    folioID,
    chargeID,
    reqData,
    resolve,
    reject,
  };
}

export function updateFolioChargeSuccess(charge) {
  return {
    type: UPDARE_FOLIO_CHARGE_SUCCESS,
    charge,
  };
}
export function updateFolioChargeFail(error) {
  return {
    type: UPDARE_FOLIO_CHARGE_FAIL,
    error,
  };
}

export function voidFolio(fid, resolve, reject) {
  return {
    type: VOID_FOLIO,
    fid,
    resolve,
    reject,
  };
}

export function voidFolioSuccess(folio) {
  return {
    type: VOID_FOLIO_SUCCESS,
    folio,
  };
}
export function voidFolioFail(error) {
  return {
    type: VOID_FOLIO_FAIL,
    error,
  };
}

export function addFolio(bid, body, resolve, reject) {
  return {
    type: ADD_FOLIO,
    bid,
    body,
    resolve,
    reject,
  };
}

export function addFolioSuccess(folio) {
  return {
    type: ADD_FOLIO_SUCCESS,
    folio,
  };
}
export function addFolioFail(error) {
  return {
    type: ADD_FOLIO_FAIL,
    error,
  };
}

export function voidFolioPayment(paymentId, resolve, reject) {
  return {
    type: VOID_FOLIO_PAYMENT,
    paymentId,
    resolve,
    reject,
  };
}

export function voidFolioPaymentSuccess(payment) {
  return {
    type: VOID_FOLIO_PAYMENT_SUCCESS,
    payment,
  };
}
export function voidFolioPaymentFail(error) {
  return {
    type: VOID_FOLIO_PAYMENT_FAIL,
    error,
  };
}

export function updateBookingPeriod(bid, dates, resolve, reject) {
  return {
    type: UPDATE_BOOKING_PERIOD,
    bid,
    dates,
    resolve,
    reject,
  };
}

export function updateBookingPeriodSuccess(booking) {
  return {
    type: UPDATE_BOOKING_PERIOD_SUCCESS,
    booking,
  };
}
export function updateBookingPeriodFail(error) {
  return {
    type: UPDATE_BOOKING_PERIOD_FAIL,
    error,
  };
}

export function voidBookingCharges(bid, resolve, reject) {
  return {
    type: VOID_CHARGES_OF_BOOKING,
    bid,
    resolve,
    reject,
  };
}

export function voidBookingChargesSuccess(booking) {
  return {
    type: VOID_CHARGES_OF_BOOKING_SUCCESS,
    booking,
  };
}
export function voidBookingChargesFail(error) {
  return {
    type: VOID_CHARGES_OF_BOOKING_FAIL,
    error,
  };
}

export function fetchBookingChangeLogs(bid) {
  return {
    type: FETCH_BOOKING_HISTORY,
    bid,
  };
}

export function fetchBookingChangeLogsSuccess(history) {
  return {
    type: FETCH_BOOKING_HISTORY_SUCCESS,
    history,
  };
}
export function fetchBookingChangeLogsFail(error) {
  return {
    type: FETCH_BOOKING_HISTORY_FAIL,
    error,
  };
}

export function createHourlyBooking(hid, reqData, resolve, reject, lockdoor) {
  return {
    type: CREATE_HOURLY_BOOKING,
    hid,
    reqData,
    resolve,
    reject,
    lockdoor,
  };
}

export function updateBookingInfo(bid, reqData, resolve, reject) {
  return {
    type: UPDATE_BOOKING_INFO,
    bid,
    reqData,
    resolve,
    reject,
  };
}

export function updateBookingInfoSuccess(booking) {
  return {
    type: UPDATE_BOOKING_INFO_SUCCESS,
    booking,
  };
}
export function updateBookingInfoFail(error) {
  return {
    type: UPDATE_BOOKING_INFO_FAIL,
    error,
  };
}

export function removeRoom(bid, bookingRoomId, resolve, reject) {
  return {
    type: REMOVE_ROOM_FROM_BOOKING,
    bid,
    bookingRoomId,
    resolve,
    reject,
  };
}

export function removeRoomSuccess(bookingRoom) {
  return {
    type: REMOVE_ROOM_FROM_BOOKING_SUCCESS,
    bookingRoom,
  };
}
export function removeRoomFail(error) {
  return {
    type: REMOVE_ROOM_FROM_BOOKING_FAIL,
    error,
  };
}

export function fetchCompanies(hid) {
  return {
    type: FETCH_COMPANIES,
    hid,
  };
}

export function fetchCompaniesSuccess(companies) {
  return {
    type: FETCH_COMPANIES_SUCCESS,
    companies,
  };
}
export function fetchCompaniesFail(error) {
  return {
    type: FETCH_COMPANIES_FAIL,
    error,
  };
}

export function unCheckinBooking(booking, resolve, reject, lockdoor) {
  return {
    type: UNDO_CHECKIN_BOOKING,
    booking,
    resolve,
    reject,
    lockdoor,
  };
}

export function assignGuestBooking(bid, guest) {
  return {
    type: ASSIGN_GUEST_BOOKING,
    bid,
    guest,
  };
}

export function assignGuestBookingSuccess(guest) {
  return {
    type: ASSIGN_GUEST_BOOKING_SUCCESS,
    guest,
  };
}

export function unAssignGuestBooking(bid, guest) {
  return {
    type: UNASSIGN_GUEST_BOOKING,
    bid,
    guest,
  };
}

export function unAssignGuestBookingSuccess(guest) {
  return {
    type: UNASSIGN_GUEST_BOOKING_SUCCESS,
    guest,
  };
}

export function transferChargesBooking(bid, fid, transfer, resolve, reject) {
  return {
    type: TRANSFER_CHARGES_BOOKING,
    bid,
    fid,
    transfer,
    resolve,
    reject,
  };
}

export function transferChargesBookingSuccess(transfer) {
  return {
    type: TRANSFER_CHARGES_BOOKING_SUCCESS,
    transfer,
  };
}

export function transferFolioBooking(bid, fid, folio, resolve, reject) {
  return {
    type: TRANSFER_FOLIO_BOOKING,
    bid,
    fid,
    folio,
    resolve,
    reject,
  };
}

export function transferFolioBookingSuccess(folio) {
  return {
    type: TRANSFER_FOLIO_BOOKING_SUCCESS,
    folio,
  };
}

export function setCurrentTab(tabID) {
  return {
    type: SET_CURRENT_TAB,
    tabID,
  };
}

export function addGuestToBooking(refCode, bkRoomGuest, resolve, reject) {
  return {
    type: ADD_GUEST_TO_BOOKING,
    refCode,
    bkRoomGuest,
    resolve,
    reject,
  };
}

export function addGuestToBookingSuccess(guest) {
  return {
    type: ADD_GUEST_TO_BOOKING_SUCCESS,
    guest,
  };
}

export function editBookingRoomType(refCode, bkrid, body, resolve, reject) {
  return {
    type: EDIT_BOOKING_ROOM_TYPE,
    refCode,
    bkrid,
    body,
    resolve,
    reject,
  };
}

export function voidMultipleFolioCharges(bid, fid, body, resolve, reject) {
  return {
    type: VOID_MULTIPLE_FOLIO_CHARGES,
    bid,
    fid,
    body,
    resolve,
    reject,
  };
}

export function cancelAChildBooking(bid, bkrid, body, resolve, reject) {
  return {
    type: CANCEL_A_CHILD_BOOKING,
    bid,
    bkrid,
    body,
    resolve,
    reject,
  };
}

export function addChildBooking(bid, body, resolve, reject) {
  return {
    type: ADD_CHILD_BOOKING,
    bid,
    body,
    resolve,
    reject,
  };
}

export function createCompanySuccess(payload) {
  return {
    type: CREATE_COMPANY_SUCCESS,
    payload,
  };
}

export function groupFolios(bid, fid, body, resolve, reject) {
  return {
    type: GROUP_FOLIOS,
    resolve,
    reject,
    bid,
    fid,
    body,
  };
}
export function groupFoliosSuccess(payload) {
  return {
    type: GROUP_FOLIOS_SUCCESS,
    payload,
  };
}

export function separateFolios(bid, fid, body, resolve, reject) {
  return {
    type: SEPARATE_FOLIOS,
    resolve,
    reject,
    bid,
    fid,
    body,
  };
}
export function separateFoliosSuccess(payload) {
  return {
    type: SEPARATE_FOLIOS_SUCCESS,
    payload,
  };
}

export function fetchExpiredBookings(arrival, departure) {
  return {
    type: FETCH_EXPIRED_BOOKINGS,
    arrival,
    departure,
  };
}
export function fetchExpiredBookingsSuccess(bookings, pagination) {
  return {
    type: FETCH_EXPIRED_BOOKINGS_SUCCESS,
    bookings,
    pagination,
  };
}
