import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Form, Divider } from 'antd';
import { connect } from 'react-redux';
import {
  makeSelectCurrentHotel,
  makeSelectCurrencies,
} from 'containers/Dashboard/selectors';
import { createStructuredSelector } from 'reselect';
import {
  fetchPaymentTypes,
  addFolioPayment,
} from 'containers/BookingProvider/actions';
import { makeSelectConversionRate } from 'containers/BookingProvider/selector';

import { makeSelectPaymentTypes } from 'containers/BookingProvider/selector';
import { useInjectReducer } from 'utils/injectReducer';
import { BOOKING_KEY } from 'containers/BookingProvider/constants';
import { useInjectSaga } from 'utils/injectSaga';
import bookingsReducer from 'containers/BookingProvider/reducer';
import saga from 'containers/BookingProvider/saga';
import { FormattedMessage } from 'react-intl';
import { Modal, Select } from '@aha/ui';
import InputNumberFormat from 'components/InputNumberFormat';
import messages from 'utils/messages/bookings';
import stMessages from 'utils/messages/settings';
import { translate } from 'utils/translate';
import currencyFormater from '@aha/utils/src/currencyFormatter';
import classnames from 'classnames';
import { VIP_MEMBER } from 'constants/membership';
import LMembershipBadge from 'components/LMembershipBadge';
import { calcTotalAmount } from 'utils/calcTotalAmount';
import { showErrorNotification } from '@aha/utils';
import { IconCoin } from '@aha/icons';

export const InnerModal = ({
  onAddPayment,
  getFieldDecorator,
  setFieldsValue,
  getFieldValue,
  balance,
  currencies,
  paymentTypes,
  booking,
  membership,
  conversionRate,
  showLoyalty,
  mode,
  currencyCode,
  actualAmount,
  setActualAmount,
}) => {
  const saveCurrency = useRef(currencyCode);
  const prevCurrency = useRef(currencyCode);
  const preventAmount = useRef(false);

  const baseBalance = Math.abs(balance.total);
  const isVip = membership?.rank === VIP_MEMBER;

  // coin info
  const availableCoins = membership?.balance || 0;
  let { maxCoin = 0, usedCoin = 0 } = booking?.coinUsage || {};
  usedCoin = Math.abs(usedCoin);

  const redeemptionRate = booking?.coinConfig?.redeemptionRate || 1;
  const balanceToXu = baseBalance / redeemptionRate;

  let finalCoins = maxCoin;
  if (usedCoin < maxCoin) {
    finalCoins = maxCoin - usedCoin;
  }

  if (availableCoins <= finalCoins) {
    finalCoins = availableCoins;
  }

  if (finalCoins >= balanceToXu) {
    finalCoins = Math.floor(balanceToXu);
  }

  // get rate
  const chargeRate =
    currencies.find((c) => c.name === currencyCode)?.Rate?.Rate || 1;
  const paymentRate =
    currencies.find((c) => c.name === getFieldValue('currency'))?.Rate?.Rate ||
    1;

  // get data field
  const amount = getFieldValue('amount');
  const currency = getFieldValue('currency');
  const coin = getFieldValue('coin');

  // convert currency to VND
  const xuToMoney = (coin || 0) * redeemptionRate;

  const amountToMoney = calcTotalAmount(
    { [currency || 'VND']: actualAmount || 0 },
    conversionRate,
    false,
  ).total;

  // calc max amount can pay
  let maxAmount = xuToMoney > baseBalance ? 0 : baseBalance - xuToMoney;
  const accMaxAmount = (maxAmount * chargeRate) / paymentRate;
  maxAmount = accMaxAmount.toFixed(2);

  // sum payment
  const sumPayment = Math.floor(amountToMoney + xuToMoney);

  useEffect(() => {
    if (typeof amount === 'undefined') {
      return;
    }

    // Prevent useEffect call when changing coin
    if (preventAmount.current) {
      preventAmount.current = false;
      return;
    }

    setActualAmount(amount);
  }, [amount]); // eslint-disable-line

  useEffect(() => {
    if (typeof amount === 'undefined') {
      return;
    }

    prevCurrency.current = saveCurrency.current;

    // change to VND first then changing to currency later
    const tempAmountVnd = calcTotalAmount(
      { currency: [prevCurrency.current || 'VND'], amount: actualAmount || 0 },
      conversionRate,
      false,
    ).total;

    const actualAmountCurrency = (+tempAmountVnd * chargeRate) / paymentRate;

    setActualAmount(actualAmountCurrency);

    // save previous currency
    saveCurrency.current = currency;
    preventAmount.current = true;

    setFieldsValue({
      amount: actualAmountCurrency.toFixed(2) || 0,
    });
  }, [currency]); // eslint-disable-line

  useEffect(() => {
    if (typeof amount === 'undefined') {
      return;
    }

    setActualAmount(accMaxAmount);
    preventAmount.current = true;

    setFieldsValue({
      amount: maxAmount || 0,
    });
  }, [xuToMoney]); // eslint-disable-line

  return (
    <Form layout="vertical" onSubmit={onAddPayment}>
      <div className="flex flex-wrap -mx-2">
        <div className="w-full px-2 col md:w-1/2">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="Amount"
                id="bookings.label.amount"
              />
            }
          >
            {getFieldDecorator('amount', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="common.message.required"
                      defaultMessage="Required"
                    />
                  ),
                },
                {
                  validator: (rule, value, cb) => {
                    return typeof value === 'number' &&
                      !(value >= 0 && value <= maxAmount)
                      ? cb(
                          <FormattedMessage
                            defaultMessage="Invalid"
                            id="common.label.invalid"
                          />,
                        )
                      : cb();
                  },
                },
              ],
              initialValue: baseBalance,
            })(
              <InputNumberFormat
                max={maxAmount}
                className="w-full"
                formatter={(v) => `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(v) => v.replace(/\$\s?|(,*)/g, '')}
              />,
            )}
          </Form.Item>
        </div>
        <div className="w-full px-2 col md:w-1/2">
          <Form.Item
            label={
              <FormattedMessage
                id="common.label.currency"
                defaultMessage="Currency"
              />
            }
          >
            {getFieldDecorator('currency', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="common.message.required"
                      defaultMessage="Required"
                    />
                  ),
                },
              ],
              initialValue: balance?.currency,
            })(
              <Select
                placeholder={
                  <FormattedMessage
                    defaultMessage="Select currency"
                    id="bookings.label.select_currency"
                  />
                }
              >
                {(currencies || []).map((c) => (
                  <Select.Option key={c.name} value={c.name}>
                    {c.name}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </div>
      </div>
      <div className="flex flex-wrap -mx-2">
        <div className="w-full px-2 pt-3">
          <Form.Item
            label={
              <FormattedMessage
                defaultMessage="Payment type"
                id="common.label.paymentType"
              />
            }
          >
            {getFieldDecorator('paymentType', {
              initialValue:
                paymentTypes?.[0] &&
                `${paymentTypes?.[0]?.id};;;${paymentTypes?.[0]?.subTypes?.[0]?.id}`,
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="common.message.required"
                      defaultMessage="Required"
                    />
                  ),
                },
              ],
            })(
              <Select
                showSearch
                placeholder={
                  <FormattedMessage
                    defaultMessage="Select payment type"
                    id="bookings.label.selectPaymentType"
                  />
                }
              >
                {paymentTypes.map((pt) => (
                  <Select.OptGroup
                    key={pt.id}
                    label={translate(pt.name, stMessages)}
                  >
                    {(pt.subTypes || []).map((p) => (
                      <Select.Option
                        key={p.id}
                        value={`${pt.id};;;${p.id}`}
                        title={`${pt.name} - ${p.name}`}
                      >
                        {translate(p.name, stMessages)}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                ))}
              </Select>,
            )}
          </Form.Item>
        </div>
      </div>
      {showLoyalty && mode !== 'refund' && (
        <>
          <Divider className="mt-2" />
          <div className="w-full inline-flex items-center -mx-2">
            <div className="pl-2">
              <LMembershipBadge type={membership?.rank} />
            </div>
            {isVip && (
              <div className="flex-1 px-2">
                <span className="font-medium mr-1">
                  <FormattedMessage
                    defaultMessage="Available xu:"
                    id="bookings.label.availableCoins"
                  />
                </span>
                <span className="mr-1">{availableCoins}</span>
                <FormattedMessage defaultMessage="xu" id="common.label.coins" />
              </div>
            )}
          </div>
          {availableCoins <= 0 ? (
            <div className="mt-4">
              <FormattedMessage
                defaultMessage="Customers do not have enough xu to pay"
                id="bookings.label.notEnoughCoins"
              />
            </div>
          ) : isVip ? (
            <>
              <div className="inline-flex items-center flex-no-wrap mt-4">
                <IconCoin className="mr-2 text-xl" />
                <span className="font-medium">
                  <FormattedMessage
                    defaultMessage="Xu used / Max xu can use:"
                    id="bookings.label.usedAndMaxCoins"
                  />
                </span>
                <span className="ml-2 mr-1">{`${usedCoin} / ${maxCoin}`}</span>
                <FormattedMessage defaultMessage="xu" id="common.label.coins" />
              </div>
              <div className="w-full inline-flex items-center flex-no-wrap -mx-2 mt-4">
                <div className="w-1/2 px-2">
                  {getFieldDecorator('coin')(
                    <InputNumberFormat
                      min={0}
                      max={finalCoins}
                      disabled={usedCoin >= maxCoin}
                    />,
                  )}
                </div>
                <div className="w-1/2 pr-2 text-grey-darker">
                  <span className="mr-1">=</span>
                  {currencyFormater(xuToMoney)}
                </div>
              </div>
              <div
                className={classnames('w-full inline-flex items-center mt-4', {
                  'cursor-not-allowed opacity-50': usedCoin >= maxCoin,
                })}
              >
                <Checkbox
                  disabled={usedCoin >= maxCoin}
                  onChange={(e) => {
                    const { checked } = e.target;
                    setFieldsValue({
                      coin: !!checked ? finalCoins : 0,
                    });
                  }}
                />
                <span className="ml-2" />
                <FormattedMessage
                  defaultMessage="Use all remaining coins"
                  id="booking.message.useRemainCoins"
                />
              </div>
            </>
          ) : null}
          <Divider />
          <div className="w-full inline-flex items-center -mx-2">
            <div className="w-1/2 px-2">
              <div className="uppercase text-2xs font-medium">
                <FormattedMessage
                  defaultMessage="Total amount"
                  id="booking.label.totalAmount"
                />
              </div>
              <div>{currencyFormater(sumPayment, currencyCode)}</div>
            </div>
            <div className="w-1/2 px-2">
              <div className="uppercase text-2xs font-medium">
                <FormattedMessage
                  defaultMessage="Balance"
                  id="bookings.label.balance"
                />
              </div>
              <div>
                {currencyFormater(baseBalance - sumPayment, currencyCode)}
              </div>
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

const AddPaymentModal = Form.create({ name: 'add_payment_form' })(function (
  props,
) {
  useInjectReducer({ key: BOOKING_KEY, reducer: bookingsReducer });
  useInjectSaga({ key: BOOKING_KEY, saga });

  const {
    booking,
    membership,
    folioID,
    mode,
    hotel,
    form,
    visible,
    onCancel,
    currencies,
    balance,
    paymentTypes,
    conversionRate,
    showLoyalty,
    currencyCode = 'VND',
    doFetchPaymentTypes,
    doAddPayment,
  } = props;

  const {
    setFieldsValue,
    validateFields,
    getFieldDecorator,
    resetFields,
    getFieldValue,
  } = form;

  const [isCreating, setIsCreating] = useState(false);

  // we want to get all numbers after '.' in decimal number so we got actualAmount
  const [actualAmount, setActualAmount] = useState(Math.abs(balance.total));

  useEffect(() => {
    // tell jest to ingore the call inside useEffect
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    doFetchPaymentTypes(hotel.id);
  }, [hotel.id]); // eslint-disable-line

  useEffect(() => {
    setActualAmount(Math.abs(balance.total));
  }, [balance.total]); // eslint-disable-line

  function onClose() {
    if (isCreating) {
      return;
    }

    onCancel(false);
    resetFields();
  }

  function onAddPayment(e) {
    e.preventDefault();

    if (actualAmount < 0) {
      showErrorNotification(
        'Fail to add payment',
        'Balance must be more than 0',
      );
      return;
    }

    validateFields(async (err, value) => {
      if (err || !booking?.id) {
        console.error(err);
      } else {
        try {
          setIsCreating(true);
          const { currency, paymentType, coin } = value;
          let pAmount = +actualAmount || 0;
          let pCurrency = currency;

          if (pAmount >= 0) {
            const reqData = {
              coin,
              amount: pAmount,
              currency: pCurrency,
              paymentTypeId: +paymentType?.split(';;;')[0],
              paymentSubtypeId: +paymentType?.split(';;;')[1],
            };

            await doAddPayment(folioID, reqData, mode);
          }

          onCancel(true);
          resetFields();
        } catch (e) {
          console.error(e);
        } finally {
          setIsCreating(false);
        }
      }
    });
  }
  return (
    <Modal
      destroyOnClose
      width={535}
      visible={visible}
      title={
        <FormattedMessage
          {...messages[
            mode === 'refund'
              ? 'bookings.label.addRefund'
              : 'bookings.label.addPayment'
          ]}
        />
      }
      submitText={
        <FormattedMessage defaultMessage="Pay" id="common.action.pay" />
      }
      cancelText={
        <FormattedMessage defaultMessage="Cancel" id="common.action.cancel" />
      }
      submitting={isCreating}
      onCancel={onClose}
      onSubmit={onAddPayment}
    >
      <InnerModal
        booking={booking}
        membership={membership}
        onAddPayment={onAddPayment}
        getFieldValue={getFieldValue}
        getFieldDecorator={getFieldDecorator}
        setFieldsValue={setFieldsValue}
        balance={balance}
        currencies={currencies}
        paymentTypes={paymentTypes}
        conversionRate={conversionRate}
        showLoyalty={showLoyalty}
        mode={mode}
        currencyCode={currencyCode}
        actualAmount={actualAmount}
        setActualAmount={setActualAmount}
      />
    </Modal>
  );
});

AddPaymentModal.propTypes = {
  folioID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mode: PropTypes.oneOf(['add', 'refund']),
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  currencyCode: PropTypes.string,
};

const mapState = createStructuredSelector({
  currencies: makeSelectCurrencies(),
  hotel: makeSelectCurrentHotel(),
  paymentTypes: makeSelectPaymentTypes(),
  conversionRate: makeSelectConversionRate(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchPaymentTypes: (hid) => dispatch(fetchPaymentTypes(hid)),
    doAddPayment: (fid, reqData, type) =>
      new Promise((resolve, reject) =>
        dispatch(addFolioPayment(fid, reqData, type, resolve, reject)),
      ),
  };
};

export default connect(mapState, mapDispatchToProps)(AddPaymentModal);
