import { action } from 'typesafe-actions';
import ActionTypes from './constants';
import { ModelRatePublishingConfig } from 'types/v3-schema';
import { RatePlan } from 'types/schema';
import { InjectPromise } from 'types/actions';
import { UserAction } from './types';
import moment from 'moment';

export const fetchRatePlans = (
  hid: number,
  start?: moment.Moment | string,
  end?: moment.Moment | string,
) =>
  action(ActionTypes.FETCH_RATE_PLANS, {
    hid,
    start,
    end,
  });

export const fetchRatePlansSuccess = (rates: RatePlan[]) =>
  action(ActionTypes.FETCH_RATE_PLANS_SUCCESS, rates);

export const fetchRatePlansFail = (error: Error) =>
  action(ActionTypes.FETCH_RATE_PLANS_FAIL, error);

export const createRatePlan = (
  params: Required<
    InjectPromise<RatePlan, { hid: number; formData: RatePlan }>
  >,
) => action(ActionTypes.CREATE_RATE_PLAN, params);

export const createRatePlanSuccess = (ratePlan: RatePlan) =>
  action(ActionTypes.CREATE_RATE_PLAN_SUCCESS, ratePlan);

export const createRatePlanFail = (error: Error) =>
  action(ActionTypes.CREATE_RATE_PLAN_FAIL, error);

export const updateRatePlan = (
  params: Required<
    InjectPromise<RatePlan, { rateId: number; formData: RatePlan }>
  >,
) => action(ActionTypes.UPDATE_RATE_PLAN, params);

export const updateRatePlanSuccess = (ratePlan: RatePlan) =>
  action(ActionTypes.UPDATE_RATE_PLAN_SUCCESS, ratePlan);

export const updateRatePlanFail = (error: Error) =>
  action(ActionTypes.UPDATE_RATE_PLAN_FAIL, error);

export const updateClosedRatePlan = (
  params: Required<
    InjectPromise<RatePlan, { rateId: number; formData: boolean }>
  >,
) => action(ActionTypes.UPDATE_CLOSED_RATE_PLAN, params);

export const updateClosedRatePlanSuccess = (ratePlan: RatePlan) =>
  action(ActionTypes.UPDATE_CLOSED_RATE_PLAN_SUCCESS, ratePlan);

export const updateClosedRatePlanFail = (error: Error) =>
  action(ActionTypes.UPDATE_CLOSED_RATE_PLAN_FAIL, error);

export const updateRateRestriction = (
  params: Required<
    InjectPromise<RatePlan, { rateId: number; formData: RatePlan }>
  >,
) => action(ActionTypes.UPDATE_RATE_RESTRICTION, params);

export const updateRateRestrictionSuccess = (ratePlan: RatePlan) =>
  action(ActionTypes.UPDATE_RATE_RESTRICTION_SUCCESS, ratePlan);

export const updateRateRestrictionFail = (error: Error) =>
  action(ActionTypes.UPDATE_RATE_RESTRICTION_FAIL, error);

export const fetchEditHistory = (hid: number) =>
  action(ActionTypes.FETCH_EDIT_HISTORY, hid);

export const fetchEditHistorySuccess = (editHistory: UserAction[]) =>
  action(ActionTypes.FETCH_EDIT_HISTORY_SUCCESS, editHistory);

export const fetchEditHistoryFail = (error: Error) =>
  action(ActionTypes.FETCH_EDIT_HISTORY_FAIL, error);

export const fetchPushHistory = (hid: number) =>
  action(ActionTypes.FETCH_PUSH_HISTORY, hid);

export const fetchPushHistorySuccess = (pushHistory: UserAction[]) =>
  action(ActionTypes.FETCH_PUSH_HISTORY_SUCCESS, pushHistory);

export const fetchPushHistoryFail = (error: Error) =>
  action(ActionTypes.FETCH_PUSH_HISTORY_FAIL, error);

export const pushStahh = (start?: moment.Moment, end?: moment.Moment) =>
  action(ActionTypes.SYNC_RATE_PLANS_WITH_STAHH, {
    start,
    end,
  });

export const pushAHA = (
  hid: number,
  start?: moment.Moment,
  end?: moment.Moment,
) =>
  action(ActionTypes.SYNC_RATE_PLANS_WITH_AHA, {
    hid,
    start,
    end,
  });

export const createPromotion = (
  params: Required<
    InjectPromise<ModelRatePublishingConfig, ModelRatePublishingConfig>
  >,
) => action(ActionTypes.CREATE_PROMOTION, params);

export const createPromotionSuccess = (promotion: ModelRatePublishingConfig) =>
  action(ActionTypes.CREATE_PROMOTION_SUCCESS, promotion);
